import {
  GET_ROLE_PERMISSION_LIST,
  IS_GET_ROLE_PERMISSION_LIST_FAILURE,
  IS_GET_ROLE_PERMISSION_LIST_LOADING
} from 'constants/store/RolesAndActivity';

import { ROLES_PERMISSION } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';

/** Action get roles details */
const rolePermissionListHasError = (bool, error) => ({
  type: IS_GET_ROLE_PERMISSION_LIST_FAILURE,
  hasError: bool,
  errorMessage: error
});

const rolePermissionListIsLoading = bool => ({
  type: IS_GET_ROLE_PERMISSION_LIST_LOADING,
  isLoading: bool
});

export const rolePermissionListSuccess = data => ({
  type: GET_ROLE_PERMISSION_LIST,
  data
});

/**
 * to get roles details
 */

export const fetchRolePermistionList = farmId => async dispatch => {
  dispatch(rolePermissionListIsLoading(true));
  await dispatch(
    apiRequest({
      url: ROLES_PERMISSION,
      method: 'get'
    })
  )
    .then(response => {
      dispatch(rolePermissionListSuccess(response.data));
    })
    .catch(error => {
      dispatch(rolePermissionListHasError(true, error));
    });
};
