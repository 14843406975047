import {
  UPLOAD_SOIL_SAMPLE_PROGRESS,
  UPLOAD_SOIL_SAMPLE_ERROR,
  UPLOAD_SOIL_SAMPLE_LOADING,
  UPLOAD_SOIL_SAMPLE_SUCESS,
  SOIL_SAMPLE_LIST_ERROR,
  SOIL_SAMPLE_LIST,
  SOIL_SAMPLE_LOADING,
  SOIL_SAMPLE_CLEAR,
  CLEAR_SOIL_UPLOAD,
  SOIL_SAMPLE_DETAILS,
  SOIL_SAMPLE_DETAILS_ERROR,
  SOIL_SAMPLE_UPDATE,
  SOIL_SAMPLE_UPDATE_ERROR
} from 'constants/store/Fertilization';

const initialState = {
  isLoading: false,
  sampleList: null,
  uploadSucess: null,
  hasUploadError: false,
  isUploading: false,
  hasError: false,
  errorMsg: null,
  sampleDetails: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_SOIL_SAMPLE_LOADING:
      return {
        ...state,
        isUploading: true,
        hasUploadError: false,
        uploadSucess: false
      };
    case SOIL_SAMPLE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false
      };
    case SOIL_SAMPLE_CLEAR:
      return { initialState };
    case SOIL_SAMPLE_LIST:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        sampleList: action.payload
      };
    case SOIL_SAMPLE_LIST_ERROR:
      return {
        ...state,
        hasError: true,
        isListLoading: false,
        sampleList: null,
        errorMsg: action.payload
      };
    case UPLOAD_SOIL_SAMPLE_SUCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        uploadSucess: true,
        uploadMsg: action.payload
      };
    case UPLOAD_SOIL_SAMPLE_ERROR:
      return {
        ...state,
        hasUploadError: true,
        isUploading: false,
        uploadMsg: action.payload
      };
    case UPLOAD_SOIL_SAMPLE_PROGRESS:
      return {
        ...state,
        uploadPercentage: action.payload
      };
    case CLEAR_SOIL_UPLOAD:
      return {
        ...state,
        isUploading: false,
        hasUploadError: false,
        uploadSucess: false
      };
    case SOIL_SAMPLE_DETAILS:
      return {
        ...state,
        isLoading: false,
        sampleDetails: action.payload
      };
    case SOIL_SAMPLE_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
        hasError: true
      };
    case SOIL_SAMPLE_UPDATE:
      return {
        ...state,
        isLoading: false
      };
    case SOIL_SAMPLE_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        hasUpdateErr: true,
        errorMsg: action.payload
      };
    default:
      return state;
  }
};
