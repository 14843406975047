/**
 * Component: Create Field
 * It contains help text static content
 */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Paragraph from '@tine/lib-frontend-components/components/text-content/paragraph';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import Numeral from '@tine/lib-frontend-components/elements/typography/numeral';

import YTVideo from 'components/YTVideo';

export default () => {
  return (
    <div className="help-text__content">
      <div className="help-text__wrapper">
        <h2 className="help-text__title">
          <FormattedMessage id="CFH_TITLE" />
        </h2>
        <Paragraph>
          <Text>
            <FormattedMessage id="CFH_DESC" />
          </Text>
        </Paragraph>
        <YTVideo
          youtubeId="k5ByD34pQmA"
          allowFullScreen
          height="300px"
          width="100%"
          title={<FormattedMessage id="CFH_VCAPTION" />}
          caption={<FormattedMessage id="CFH_VCAPTION" />}
        />
      </div>
      <div className="help-text__points-wrap">
        <Paragraph>
          <EmphasisLight size="normal">
            <FormattedMessage id="CFH_B1_TITLE" />
          </EmphasisLight>
        </Paragraph>
        <ul className="help-text__list">
          <li>
            <Numeral>1</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B1_STEP_1" />
            </Text>
          </li>
          <li>
            <Numeral>2</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B1_STEP_2" /> <img src="./images/polygon.svg" alt="draw" />
            </Text>
          </li>
          <li>
            <Numeral>3</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B1_STEP_3" />
            </Text>
          </li>
          <li>
            <Numeral>4</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B1_STEP_4" />
            </Text>
          </li>
          <li>
            <Numeral>5</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B1_STEP_5" />
            </Text>
          </li>
          <li>
            <Numeral>6</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B1_STEP_6" />
            </Text>
          </li>
        </ul>
      </div>
      <div className="help-text__points-wrap">
        <Paragraph>
          <EmphasisLight size="normal">
            <FormattedMessage id="CFH_B2_TITLE" />
          </EmphasisLight>
        </Paragraph>
        <ul className="help-text__list">
          <li>
            <Numeral>1</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B2_STEP_1" />
            </Text>
          </li>
          <li>
            <Numeral>2</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B2_STEP_2" />
            </Text>
          </li>
          <li>
            <Numeral>3</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B2_STEP_3" />
            </Text>
          </li>
        </ul>
      </div>
      <div className="help-text__points-wrap">
        <Paragraph>
          <EmphasisLight size="normal">
            <FormattedMessage id="CFH_B3_TITLE" />
          </EmphasisLight>
        </Paragraph>
        <ul className="help-text__list">
          <li>
            <Numeral>1</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B3_STEP_1" />
            </Text>
          </li>
          <li>
            <Numeral>2</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B3_STEP_2" />
            </Text>
          </li>
        </ul>
      </div>
      <div className="help-text__points-wrap">
        <Paragraph>
          <EmphasisLight size="normal">
            <FormattedMessage id="CFH_B4_TITLE" />
          </EmphasisLight>
        </Paragraph>
        <ul className="help-text__list">
          <li>
            <Numeral>1</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B4_STEP_2" />
            </Text>
          </li>
          <li>
            <Numeral>2</Numeral>
            <Text size="small">
              <FormattedMessage id="CFH_B4_STEP_1" />
            </Text>
          </li>
        </ul>
      </div>
      <div className="help-text__points-wrap">
        <Paragraph>
          <EmphasisLight size="normal">
            <FormattedMessage id="CFH_B5_TITLE" />
          </EmphasisLight>
        </Paragraph>
      </div>
    </div>
  );
};
