// Activity List
export const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
export const IS_GET_ACTIVITY_LIST_FAILURE = 'IS_GET_ACTIVITY_LIST_FAILURE';
export const IS_GET_ACTIVITY_LIST_LOADING = 'IS_GET_ACTIVITY_LIST_LOADING';

// Roles permission list
export const GET_ROLE_PERMISSION_LIST = 'GET_ROLE_PERMISSION_LIST';
export const IS_GET_ROLE_PERMISSION_LIST_FAILURE = 'IS_GET_ROLE_PERMISSION_LIST_FAILURE';
export const IS_GET_ROLE_PERMISSION_LIST_LOADING = 'IS_GET_ROLE_PERMISSION_LIST_LOADING';

// Roles permission list
export const IS_MASTER_LIST_FAILURE = 'IS_MASTER_LIST_FAILURE';
export const IS_MASTER_LIST_LOADING = 'IS_MASTER_LIST_LOADING';
export const GET_MASTER_LIST = 'GET_MASTER_LIST';

// Support Admin Role
export const SUPPORT_ADMIN_ROLE_SUCCESS = 'SUPPORT_ADMIN_ROLE_SUCCESS';
export const SUPPORT_ADMIN_ROLE_FAILURE = 'SUPPORT_ADMIN_ROLE_FAILURE';
