export const getClientId = () => {
  if (window.location.hostname === "localhost") {
      return "c5fffbe0-eb00-4830-a1dd-fe67f02fc27e";
  }
  if (window.location.hostname.includes("dev")) {
      return "c5fffbe0-eb00-4830-a1dd-fe67f02fc27e"
  }
  return "7a8c36e7-1862-4dcc-a22f-d32a1dd4bfcf";
}

export const getRedirectUri = () => {
  if (window.location.hostname === "localhost") {
      return "http://localhost";
  }
  if (window.location.hostname.includes("dev")) {
      return "https://field-assistant-web.openfarm-dev.io"
  }
  return "https://skifte.eana.no";
}

export const getAuthUrl = () => {
  if (window.location.hostname === "localhost") {
      return "https://auth.dev.mimiro.io";
  }
  if (window.location.hostname.includes("dev")) {
      return "https://auth.dev.mimiro.io"
  }
  return "https://auth.mimiro.io";
}

export const getAudienceUrl = () => {
  if (window.location.hostname === "localhost") {
      return "https://auth.dev.mimiro.io";
  }
  if (window.location.hostname.includes("dev")) {
      return "https://auth.dev.mimiro.io"
  }
  return "https://auth.mimiro.io";
}

export const getReturnToUrl = () => {
  if (window.location.hostname === "localhost") {
      return "https://field-assistant-web.openfarm-dev.io";
  }
  if (window.location.hostname.includes("dev")) {
      return "https://field-assistant-web.openfarm-dev.io"
  }
  return "https://skifte.eana.no";
}

export const getnewLogin = () => {
  if (window.location.hostname === "localhost") {
      return "development";
  }
  if (window.location.hostname.includes("dev")) {
      return "development"
  }
  return "production";
}

export const getEanaLogin = () => {
  if (window.location.hostname === "localhost") {
      return "http://eana360-frontends.openfarm-dev.io";
  }
  if (window.location.hostname.includes("dev")) {
      return "http://eana360-frontends.openfarm-dev.io"
  }
  return "https://360.eana.no";
}

export const OAUTH_CLIENT_ID = getClientId();
export const OAUTH_REDIRECT_URI = getRedirectUri();
export const OAUTH_AUTH_URL = getAuthUrl();
export const OAUTH_AUDIENCE_URL = getAudienceUrl();
export const OAUTH_RETURNTO_URL = getReturnToUrl();
export const NEW_LOGIN_CHECK = getnewLogin();
export const EANA_360 = getEanaLogin();