import { HISTORICAL_LIST_URL } from 'constants/ApiUrl';
import { 
  HISTORICAL_LIST_LOADING, 
  HISTORICAL_LIST_SUCCESS, 
  HISTORICAL_LIST_ERROR
} from 'constants/store/Fields';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';

/**
 * Get the historical list
 */
export const getHistoricalList = (farmId,fieldId) => async (dispatch) => {
  try {
    dispatch({ type: HISTORICAL_LIST_LOADING, payload: true });
    await dispatch(
      apiRequest({
        url: `${HISTORICAL_LIST_URL}/${farmId}/tasks/fields/${fieldId}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: HISTORICAL_LIST_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: HISTORICAL_LIST_SUCCESS, payload: error });
      });
    } catch (error) {
      dispatch({ type: HISTORICAL_LIST_ERROR, payload: error });
    }
};