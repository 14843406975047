import {
  GET_PROFILE_IMAGE_IS_LOADING,
  GET_PROFILE_IMAGE_SUCCESS,
  GET_PROFILE_IMAGE_FAILURE,
  UPLOAD_PROFILE_IMAGE_IS_LOADING,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_FAILURE,
  CLEAR_PROFILE_UPDATE
} from 'constants/store/Auth';
import { GET_TASK_USER_PIC } from 'constants/index';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  userImage: [],
  profileImgUpdate: {
    isImgUpdating: false,
    hasImgUpdateErr: false,
    isUpdated: false
  }
};

export default function profileImageReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_PROFILE_IMAGE_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
        errorMessage: null
      };
    case GET_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case UPLOAD_PROFILE_IMAGE_IS_LOADING:
      return {
        ...state,
        profileImgUpdate: {
          isImgUpdating: action.payload,
          hasImgUpdateErr: false,
          isUpdated: false
        }
      };
    case UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        profileImgUpdate: {
          isImgUpdating: false,
          hasImgUpdateErr: false,
          isUpdated: true
        }
      };
    case UPLOAD_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        profileImgUpdate: {
          isImgUpdating: false,
          hasImgUpdateErr: true,
          isUpdated: false
        }
      };
    case GET_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.data
      };
    case GET_TASK_USER_PIC:
      return {
        ...state,
        userImage: [...state.userImage, action.payload]
      };
    case CLEAR_PROFILE_UPDATE:
      return {
        ...state,
        profileImgUpdate: {
          isImgUpdating: false,
          hasImgUpdateErr: false,
          isUpdated: false
        }
      };
    default:
      return state;
  }
}
