import * as React from 'react';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import SelectedHarvestCard from 'mui-components/storages/balestorage/storagecard/SelectedHarvestCard';
import SelectedBaleStorageCard from 'mui-components/storages/balestorage/storagecard/SelectedBaleStorageCard';
import SelectedBaleCard from 'mui-components/storages/balestorage/storagecard/SelectedBaleCard';
import SelectedAnalysisCard from 'mui-components/storages/balestorage/storagecard/SelectedAnalysisCard';
import AnalysisBaleModal from 'mui-components/storages/balestorage/balemodals/AnalysisBaleModal';

export default ({
  baleStorageList,
  column,
  intl,
  selectedBale,
  onSelectedBale,
  paramId,
  selectedBaleList,
  selectedAnalysis,
  onSelectedAnalysis,
  selectedAnalysisList,
  highlightStorage,
  highlightHarvest,
  highlightAnalysis,
  getFeedEventListData,
  getAnalysisStorageListData,
  onUpdateAnalysis
}) => (
  <FlexGridItem>
    <div className={`storage-box storage-box--users`}>
      <div className="storage-box__title">
        <div className='floatLeft'>
          {column === "harvest" && baleStorageList[0].balesByStorage.length > 0 &&
            <Emphasis size="normal">{baleStorageList[0].harvest}. {intl.formatMessage({ id: 'SELECTED_HARVEST_TITLE' })}</Emphasis>
          }
          {column === "bale" &&
            <Emphasis size="normal">{baleStorageList[0] && baleStorageList[0].quantity > 0 ? baleStorageList[0].quantity : 0} {intl.formatMessage({ id: 'SELECTED_STORAGE_TITLE_BALE' })} {baleStorageList[0].name} {intl.formatMessage({ id: 'SELECTED_STORAGE_TITLE_COUNT' })}</Emphasis>
          }
          {column === "selectedbale" && selectedBale && selectedBaleList && 
            <Emphasis size="normal">{intl.formatMessage({ id: 'SELECTED_BALE_HEADING' })}</Emphasis>
          }
          {column === "selectedanalysis" && selectedAnalysis && selectedAnalysisList && 
            <Emphasis size="normal">{intl.formatMessage({ id: 'SELECTED_ANALYSIS_HEADING' })}</Emphasis>
          }
        </div>
        {column === "selectedanalysis" && selectedAnalysis && selectedAnalysisList && 
        <div>        
          {getFeedEventListData && selectedAnalysisList && 
          <AnalysisBaleModal
            getAnalysisStorageListData={getAnalysisStorageListData}
            getFeedEventListData={getFeedEventListData}
            paramId="edit"
            selectedAnalysisList = {selectedAnalysisList}
            onUpdateAnalysis = {onUpdateAnalysis}
          />}
        </div>}
      </div>
      <div className="vehicle-list-flex-container full-width">
        <div className="storage-list-details">
          <ul className="storage-list-wrapper">
            {column === "harvest" &&
              baleStorageList[0].balesByStorage.map((baleStorage, index) => (
              <SelectedBaleStorageCard
                key={index+1}
                storage = {baleStorage}
                index = {index}
                storageLength = {baleStorageList[0].balesByStorage.length}
                intl = {intl}
                hasBottom = {false}
                onSelectedBale = {onSelectedBale}
                paramId = {paramId}
                highlightStorage = {highlightStorage}
              />
            ))}
            {column === "bale" &&
              baleStorageList[0].balesByHarvest.map((baleStorage, index) => (
              <SelectedHarvestCard
                key={index+1}
                harvest = {baleStorage}
                index = {index}
                harvestLength = {baleStorageList[0].balesByHarvest.length}
                intl = {intl}
                onSelectedBale = {onSelectedBale}
                paramId = {paramId}
                selectedBaleList = {selectedBaleList}
                highlightHarvest = {highlightHarvest}
              />
            ))}
            {column === "selectedbale" && selectedBale && selectedBaleList &&
              selectedBaleList.map((selectedBaleList, index) => (
              <SelectedBaleCard
                key={index+1}
                index={index}
                intl = {intl}
                selectedBaleList = {selectedBaleList}
                selectedAnalysis = {selectedAnalysis}
                onSelectedAnalysis = {onSelectedAnalysis}
                highlightAnalysis = {highlightAnalysis}
              />
            ))}
            {column === "selectedanalysis" && selectedAnalysis && selectedAnalysisList &&
              <SelectedAnalysisCard
                key={1}
                intl = {intl}
                selectedAnalysisList = {selectedAnalysisList}
              />}
          </ul>
        </div>
      </div>
    </div>
  </FlexGridItem>
);