import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SoilSample from 'containers/fertilization/soil/SoilSamples';
import PageNotFound from 'components/PageNotFound';
import SampleDetails from 'containers/fertilization/soil/SampleDetails';

const SoilSamples = () => (
  <Switch>
    <Route exact path="/fertilization/soil-samples/:date/:year" component={SampleDetails} />
    <Route exact path="/fertilization/soil-samples" component={SoilSample} />
    <Route exact path="/fertilization" component={SoilSample} />
    <Route component={PageNotFound} />
  </Switch>
);

export default SoilSamples;
