import {
  GET_BALE_ANALYSIS_STORAGE_SUCCESS,
  GET_BALE_ANALYSIS_STORAGE_ERROR,
  GET_BALE_ANALYSIS_STORAGE_LOADING,
  ADD_ANALYSIS_STORAGE_SUCCESS,
  ADD_ANALYSIS_STORAGE_ERROR,
  ADD_ANALYSIS_STORAGE_LOADING,
  GET_SELECTED_BALE_SUCCESS,
  GET_SELECTED_BALE_ERROR,
  GET_SELECTED_BALE_LOADING,
  GET_SELECTED_ANALYSIS_SUCCESS,
  GET_SELECTED_ANALYSIS_ERROR,
  GET_SELECTED_ANALYSIS_LOADING,
  UPDATE_ANALYSIS_STORAGE_SUCCESS,
  UPDATE_ANALYSIS_STORAGE_ERROR,
  UPDATE_ANALYSIS_STORAGE_LOADING
} from 'constants/store/Bale';
import { POST, GET, PUT } from 'constants/index';
import { FARM_V1 } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

export const getAllStorages = (farmId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_BALE_ANALYSIS_STORAGE_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/balestorages?type=ALL`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_BALE_ANALYSIS_STORAGE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_BALE_ANALYSIS_STORAGE_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_BALE_ANALYSIS_STORAGE_ERROR, payload: error });
  }
};

export const saveAnalysis = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_ANALYSIS_STORAGE_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/analysis-events`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: POST,
        data
      })
    )
    .then(response => {
      dispatch({ type: ADD_ANALYSIS_STORAGE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: ADD_ANALYSIS_STORAGE_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: ADD_ANALYSIS_STORAGE_ERROR, payload: error });
  }
};

export const updateAnalysis = (farmId, analysisId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_ANALYSIS_STORAGE_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/analysis-events/${analysisId}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: PUT,
        data
      })
    )
    .then(response => {
      dispatch({ type: UPDATE_ANALYSIS_STORAGE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: UPDATE_ANALYSIS_STORAGE_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: UPDATE_ANALYSIS_STORAGE_ERROR, payload: error });
  }
};

export const getSelectedBale = (farmId, storageId, harvest) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SELECTED_BALE_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/details?storageId=${storageId}&harvest=${harvest}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_SELECTED_BALE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_SELECTED_BALE_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_SELECTED_BALE_ERROR, payload: error });
  }
};

export const getSelectedAnalysis = (farmId, analysisId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SELECTED_ANALYSIS_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/analysis-events/${analysisId}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_SELECTED_ANALYSIS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_SELECTED_ANALYSIS_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_SELECTED_ANALYSIS_ERROR, payload: error });
  }
};