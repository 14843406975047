import * as React from 'react';
import { Grid, Typography } from '@mui/material'
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';

const textStyle = { fontFamily: "Inter", fontWeight: "500", fontSize: "14px", color: "#191C1C" }
const textNumberStyle = { ...textStyle, fontWeight: "400", fontSize: "16px", textAlign: "left" }
const textUnitStyle = { ...textStyle, fontWeight: "400", color: "#3F4949", textAlign: "left" }

const MineralFertilizerHeader = ({ mineralFertilizerItem, mineralFertilizerList, intl, updateMineralFertilizerMenu }) => (
  <Grid item lg={4} md={4} sm={12} xs={12} rowSpacing={2} columnSpacing={2}>
    <Grid container rowSpacing={2} sx={{padding: '10px'}}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Typography style={textNumberStyle}>
          {Math.round(mineralFertilizerItem.quantity)}&nbsp;
          {mineralFertilizerItem.quantityUnits}
        </Typography>
        <Typography style={textUnitStyle}>{intl.formatMessage({ id: 'STORAGE' })}:&nbsp;
        {mineralFertilizerItem.storageQuantity !== null && mineralFertilizerItem.storageQuantity}&nbsp;
        {mineralFertilizerItem.storageQuantity !== null && mineralFertilizerItem.quantityUnits}</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ backgroundColor: "#ffffff"}}>
        <MuiSelectBox 
          name="spring_manureMethod"
          id="spring_manureMethod"
          label=""
          value={mineralFertilizerItem.customName === null
            ? mineralFertilizerItem.originalName
            : mineralFertilizerItem.customName}
          options={mineralFertilizerList}
          onChange={e => updateMineralFertilizerMenu(e, mineralFertilizerItem.customName === null
            ? mineralFertilizerItem.originalName
            : mineralFertilizerItem.customName)}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default MineralFertilizerHeader;