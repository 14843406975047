/* eslint-disable func-names */

const translationStrings = {
    English: {
        drawMarker: 'Place a pin',
        drawPolygon: 'Draw boundary',
        markerTooltip: 'Click on map to place a pin',
        polygonTooltipStart: 'Click to start drawing a boundary',
        polygonTooltipCont: 'Click to continue drawing a boundary',
        polygonTooltipEnd: 'Click on the first point to close this boundary',
        drawTbActionsTitle: 'Cancel drawing',
        drawTbActionsText: 'Cancel',
        drawTbFinishTitle: 'Finish drawing',
        drawTbFinishText: 'Finish',
        drawTbUndoTitle: 'Delete last point drawn',
        drawTbUndoText: 'Delete last point',
        editTbActionsSaveTitle: 'Save changes',
        editTbActionsSaveText: 'Save changes',
        editTbActionsCancelTitle: 'Cancel editing, discards all changes',
        editTbActionsCancelText: 'Cancel',
        editTbActionsClearallTitle: 'Clear all boundaries',
        editTbActionsClearallText: 'Clear All',
        editTbButtonsEdit: 'Edit boundaries',
        editTbButtonsEditDisabled: 'No boundaries to edit',
        editTbButtonsRemove: 'Delete boundaries',
        editTbButtonsRemoveDisabled: 'No boundaries to delete',
        editHandlersEditTooltipText: 'Drag points to edit boundaries',
        editHandlersEditTooltipSubText: 'Click cancel to undo changes',
        editHandlersRemoveTooltipText: 'Click on the drawn area you want to remove'
    },
    Nynorsk: {
        drawMarker: 'Plasser knappenål',
        drawPolygon: 'Teikn grenselinje',
        markerTooltip: 'Klikk på kartet for å plassere ei knappenål',
        polygonTooltipStart: 'Klikk på kartet for begynne å teikne ei grenselinje',
        polygonTooltipCont: 'Klikk på ein ny stad for å fortsetje å teikne inn ei grenselinje',
        polygonTooltipEnd: 'Klikk på det første punktet for å fullføre grenselinja',
        drawTbActionsTitle: 'Avbryt teikning',
        drawTbActionsText: 'Avbryt',
        drawTbFinishTitle: 'Fullfør teikning',
        drawTbFinishText: 'Fullfør',
        drawTbUndoTitle: 'Slett det siste punktet du teikna inn',
        drawTbUndoText: 'Slett siste punkt',
        editTbActionsSaveTitle: 'Lagre endringar',
        editTbActionsSaveText: 'Lagre endringar',
        editTbActionsCancelTitle: 'Avbryt redigeringa og forkast alle endringar',
        editTbActionsCancelText: 'Avbryt',
        editTbActionsClearallTitle: ' Fjern alle grenselinjer',
        editTbActionsClearallText: 'Tøm alt',
        editTbButtonsEdit: 'Rediger grenselinjer',
        editTbButtonsEditDisabled: 'Her er ingen grenselinjer å redigere',
        editTbButtonsRemove: 'Slett grenselinjer',
        editTbButtonsRemoveDisabled: 'Her er ingen grenselinjer å slette',
        editHandlersEditTooltipText: 'Dra i punkta for å redigere grenselinjene',
        editHandlersEditTooltipSubText: 'Klikk avbryt for å angre endringane',
        editHandlersRemoveTooltipText: 'Klikk på den innteikna flata du ønskjer å fjerne'
    },
    Bokmål: {
        drawMarker: 'Plasser knappenål',
        drawPolygon: 'Tegn grenselinje',
        markerTooltip: 'Klikk på kartet for å plassere en knappenål',
        polygonTooltipStart: 'Klikk på kartet for å begynne å tegne en grenselinje',
        polygonTooltipCont: 'Klikk på et nytt sted for å fortsette å tegne inn en grenselinje',
        polygonTooltipEnd: 'Klikk på det første punket for å fullføre grenselinjen',
        drawTbActionsTitle: 'Avbryt tegning',
        drawTbActionsText: 'Avbryt',
        drawTbFinishTitle: 'Fullfør tegning',
        drawTbFinishText: 'Fullfør',
        drawTbUndoTitle: 'Slett det siste punktet du tegnet inn',
        drawTbUndoText: 'Slett siste punkt',
        editTbActionsSaveTitle: 'Lagre endringer',
        editTbActionsSaveText: 'Lagre endringer',
        editTbActionsCancelTitle: 'Avbryt redigeringen og forkast alle endringer',
        editTbActionsCancelText: 'Avbryt',
        editTbActionsClearallTitle: 'Fjern alle grenselinjer',
        editTbActionsClearallText: 'Tøm alt',
        editTbButtonsEdit: 'Rediger grenselinjer',
        editTbButtonsEditDisabled: 'Her er ingen grenselinjer å redigere',
        editTbButtonsRemove: 'Slett grenselinjer',
        editTbButtonsRemoveDisabled: 'Her er ingen grenselinjer å slette',
        editHandlersEditTooltipText: 'Dra i punktene for å redigere grenselinjene',
        editHandlersEditTooltipSubText: 'Klikk avbryt for å angre endringene',
        editHandlersRemoveTooltipText: 'Klikk på den inntegnede flaten du ønsker å fjerne'
    }
};

export const EanaCreateMap = function () {
    let map;
    let polygonPoints;
    let marker;
    let drawnItems;
    let isEditable = false;
    let polyLineDraw;
    let locMarker;
    let lang;
    let polygon;
    let fieldBoundaryGeoJson;
    window.L.mapbox.accessToken = 'pk.eyJ1Ijoia291c2hpa3NhaSIsImEiOiJjazBua3FodDAwMG93M2NraTAzNXc3NTd2In0.lhWyF7XvC_bAEW0ZY9KcXQ';
    // polygon style
    const polygonStyle = {
        color: 'rgb(255, 255, 255)',
        fillColor: 'rgb(107, 231, 136)',
        fillOpacity: 0.7,
        stroke: '5',
        weight: 2,
        smoothFactor: 0.5,
        strokeOpacity: 1
    };

    /**
     * To not plot a point while dragging on map when on drawing mode
     */
    if (!navigator.platform.match(/iPhone|iPod|iPad/)) {
        (() => {
            const originalOnTouch = window.L.Draw.Polyline.prototype._onTouch;
            window.L.Draw.Polyline.prototype._onTouch = (e) => {
                if (e.originalEvent.pointerType !== 'mouse' && e.originalEvent.pointerType !== 'touch') {
                    return originalOnTouch.call(this, e);
                }
                return null;
            };
        })();
    }

    /**
     * To update language for localization
     * @param {string} val
     */
    const updateLanguage = (val) => {
        lang = val;
        window.L.drawLocal = {
            draw: {
                toolbar: {
                    actions: {
                        title: translationStrings[lang].drawTbActionsTitle,
                        text: translationStrings[lang].drawTbActionsText
                    },
                    finish: {
                        title: translationStrings[lang].drawTbFinishTitle,
                        text: translationStrings[lang].drawTbFinishText
                    },
                    undo: {
                        title: translationStrings[lang].drawTbUndoTitle,
                        text: translationStrings[lang].drawTbUndoText
                    },
                    buttons: {
                        polyline: '- your text-',
                        polygon: translationStrings[lang].drawPolygon,
                        rectangle: '- your text-',
                        circle: '- your text-',
                        marker: translationStrings[lang].drawMarker,
                        circlemarker: '- your text-'
                    }
                },
                handlers: {
                    circle: {
                        tooltip: {
                            start: '- your text-'
                        },
                        radius: '- your text-'
                    },
                    circlemarker: {
                        tooltip: {
                            start: '- your text-.'
                        }
                    },
                    marker: {
                        tooltip: {
                            start: translationStrings[lang].markerTooltip
                        }
                    },
                    polygon: {
                        tooltip: {
                            start: translationStrings[lang].polygonTooltipStart,
                            cont: translationStrings[lang].polygonTooltipCont,
                            end: translationStrings[lang].polygonTooltipEnd,
                        }
                    },
                    polyline: {
                        error: '<strong>Error:</strong> shape edges cannot cross!',
                        tooltip: {
                            start: 'Click to start drawing line.',
                            cont: 'Click to continue drawing line.',
                            end: 'Click last point to finish line.'
                        }
                    },
                    rectangle: {
                        tooltip: {
                            start: '- your text-.'
                        }
                    },
                    simpleshape: {
                        tooltip: {
                            end: 'Release mouse to finish drawing.'
                        }
                    }
                }
            },
            edit: {
                toolbar: {
                    actions: {
                        save: {
                            title: translationStrings[lang].editTbActionsSaveTitle,
                            text: translationStrings[lang].editTbActionsSaveText,
                        },
                        cancel: {
                            title: translationStrings[lang].editTbActionsCancelTitle,
                            text: translationStrings[lang].editTbActionsCancelText,
                        },
                        clearAll: {
                            title: translationStrings[lang].editTbActionsClearallTitle,
                            text: translationStrings[lang].editTbActionsClearallText,
                        }
                    },
                    buttons: {
                        edit: translationStrings[lang].editTbButtonsEdit,
                        editDisabled: translationStrings[lang].editTbButtonsEditDisabled,
                        remove: translationStrings[lang].editTbButtonsRemove,
                        removeDisabled: translationStrings[lang].editTbButtonsRemoveDisabled,
                    }
                },
                handlers: {
                    edit: {
                        tooltip: {
                            text: translationStrings[lang].editHandlersEditTooltipText,
                            subtext: translationStrings[lang].editHandlersEditTooltipSubText
                        }
                    },
                    remove: {
                        tooltip: {
                            text: translationStrings[lang].editHandlersRemoveTooltipText
                        }
                    }
                }
            }
        };
    };

    const GetTransMessage = () => {
        if (lang === 'Nynorsk') {
            return 'Beklagar, du kan ikkje teikne dette';
        }
        if (lang === 'Bokmål') {
            return 'Beklager, du kan ikke tegne dette';
        }
        return 'Sorry, you can\'t draw that!';
    };

    // Append tile layer

    const appendTileLayer = (mapId) => {
        window.L.tileLayer('https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?&gkt={TOKEN}&layer=Nibcache_UTM33_EUREF89&style=default&tilematrixset=default028mm&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix={z}&TileCol={x}&TileRow={y}'.replace('{TOKEN}', window.eanaMapToken), {
            attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.'
            }).addTo(mapId);
    };
    /**
     * To create map on the DOM element
     * @param {string} id
     * @param {*} lat
     * @param {*} lng
     */
    const createMap = (id, lat, lng) => {
      const mapObj = window.L.mapbox.map(id)
            .setView([lat, lng], 17)
            .addLayer(window.L.mapbox.styleLayer('mapbox://styles/mapbox/satellite-streets-v11'));
            appendTileLayer(mapObj);
        window.L.control.scale({imperial: false}).addTo(mapObj);
        return mapObj;
    };

    /**
     * To create draw control respective the map pass the mab object & feature group object
     * @param {object} toMap
     * @param {object} toDrawItems
    */
    const drawControl = (toMap, toDrawItems, tool) =>
            new window.L.Control.Draw({
            edit: {
                featureGroup: toDrawItems,
                remove: true,
                edit: {
                     selectedPathOptions: polygonStyle
                }
            },
            draw: {
                polygon: tool === 'polygon' ? {
                    allowIntersection: false, // Restricts shapes to simple polygons
                    drawError: {
                        color: '#E1E100', // Color the shape will turn when intersects
                        message: GetTransMessage() // Message that will show when intersect
                    },
                    icon: new window.L.DivIcon({
                        iconSize: new window.L.Point(10, 10),
                        className: 'leaflet-div-icon leaflet-editing-icon leaflet-vertex'
                    }),
                    shapeOptions: polygonStyle
                } : false,
                polyline: false,
                rectangle: false,
                circle: false,
                marker: tool === 'marker'
            }
    }).addTo(toMap);

    /**
     * Custom icon for marker gps location
     */
    const customIcon = window.L.icon({
        iconUrl: 'images/userLocation.svg',
        iconSize: [30, 42]
    });

    /**
     * TO get the polygon points after edit or create
     */
    const getPolygonCoord = () => {
        if (polygonPoints && polygonPoints.geometry
            && polygonPoints.geometry.coordinates.length > 0) {
            return polygonPoints.geometry.coordinates[0]
            .map(coordinate => [coordinate[1], coordinate[0]]);
        }
        return null;
    };

    /**
     * to form field GeoJson
     * @param {object} fields
     */
    const storkeUrl = ['url(/images/field-stroke-45-tilt.png)', 'url(/images/field-stroke-45.png)'];
    const fieldLayerGeoJson = fields => fields.map((field) => {
            let points;
            if (field.gpsPoints.length > 2) {
                points = field.gpsPoints.map(gps => [gps.longitude, gps.lattitude]);
            }
            return {
                type: 'Feature',
                properties: {
                  fill: storkeUrl[Math.floor(Math.random() * storkeUrl.length)],
                  fillOpacity: 1,
                  name: field.fieldName,
                  stroke: true,
                  fillColor: 'rgba(60, 187, 215, 0.3)',
                  color: 'rgb(12, 103, 133)',
                  weight: 2,
                  className: 'other-field-layers'
                },
                geometry: {
                  type: 'Polygon',
                  coordinates: [points]
                }
              };
        });

    /**
     *
     * @param {object} mapObject
     * @param {object} fieldsObject
     *
     */
    const appedOtherFieldLayer = (fieldmap, fields) => {
        const mapObj = fieldmap || map;
        if (mapObj && mapObj.hasLayer(fieldBoundaryGeoJson)) {
            mapObj.removeLayer(fieldBoundaryGeoJson);
        }
        const fieldsObj = JSON.parse(fields);
        if (fieldsObj.length > 0) {
            const filteredFields = fieldsObj.filter(
                field => field.gpsPoints && field.gpsPoints.length > 2
                );

            const geoJsonLayer = {
                type: 'FeatureCollection',
                features: fieldLayerGeoJson(filteredFields)
            };
            fieldBoundaryGeoJson = window.L.geoJson(geoJsonLayer, {
                style(feature) { return feature.properties; },
                onEachFeature(feature, layer) {
                    layer.bindTooltip(feature.properties.name, {
                        sticky: true,
                        permanent: true,
                        className: 'field-name-map ellipsis-text',
                        direction: 'center'
                        });
                }
            }).addTo(mapObj);
        }
    };

    /**
     * @param {string} fieldPolygon
    */
    const updateBoundary = (fieldPolygon) => {
        const polygonCords = fieldPolygon ? JSON.parse(fieldPolygon) : null;
        if (polygonCords && polygonCords.length > 0) {
            polygon = window.L.polygon(polygonCords, polygonStyle);
            map.fitBounds(polygon.getBounds(), { maxZoom: 17 });
            drawnItems.addLayer(polygon);
        }
        map.addLayer(drawnItems);
    };

    const getFieldSize = (fieldPolygon) => {
        const polygonCords = fieldPolygon ? JSON.parse(fieldPolygon) : null;
        if (polygonCords && polygonCords.length > 0) {
            polygon = window.L.polygon(polygonCords, polygonStyle);
            return (window.LGeo.area(polygon)/1000).toFixed(2);
        } else {
            return 0;
        }
    }
    /**
     * To create polyon pass DOM id and farm / Field location and if filed polygon exist
     * @param {string} id
     * @param {float / string / int} lat
     * @param {float / string / int} lng
     * @param {Array stringify} fieldPolygon
     * @param {func} callback return coordinates & area
     */
    const polygonDraw = (id, lat, lng, fieldPolygon, otherFieldsData, callback) => {
        map = createMap(id, lat, lng);
        drawnItems = new window.L.FeatureGroup().addTo(map);
        updateBoundary(fieldPolygon);
        map.addLayer(drawnItems);
        appedOtherFieldLayer(map, otherFieldsData);
        drawControl(map, drawnItems, 'polygon');
        // disabling polygon icon in toolbar
        const disablePolygonIcon = () => {
            const currentPolygonElem = document.getElementsByClassName('leaflet-draw-draw-polygon')[0];
            currentPolygonElem.className += ' disable-polygon';
        };

        /**
         * To show polygon after draw
         * @param {object} e layer object
         */
        const showPolygonArea = (e) => {
            drawnItems.clearLayers();
            drawnItems.addLayer(e.layer);
            polygonPoints = e.layer.toGeoJSON();
            if (callback) {
                const fieldSize = window.LGeo.area(e.layer);
                if (fieldSize > 0) {
                    callback(JSON.stringify(getPolygonCoord()),
                        (fieldSize / 1000000));
                  } else {
                      drawnItems.removeLayer(e.layer);
                  }
            }
            disablePolygonIcon();
        };

        /**
         * To disable polygon icon in toolbar based on current farm
        if (map) {
            if (document.getElementsByClassName('leaflet-interactive').length > 0) {
                const polygonElems = document.body.getElementsByClassName('leaflet-interactive');
                Array.from(polygonElems).forEach((item) => {
                    if (Array.from(item.classList).includes('leaflet-interactive')
                        && Array.from(item.classList).indexOf('other-field-layers') === -1) {
                        console.log("in map");
                        disablePolygonIcon();
                    }
                });
            }
        }
        */

        /**
         * To edit polygon after draw / existing
         * @param {object} e layer object
         */
        const showPolygonAreaEdited = (e) => {
            e.layers.eachLayer((layer) => {
                showPolygonArea({ layer });
            });
        };

        const saveDelete = (e) => {
            const { layers } = e;
            layers.eachLayer((layer) => {
                if (layer instanceof window.L.Polygon) {
                    callback(null, null, true);
                }
            });
            // enabling polygon icon in toolbar
            const currentPolygonElem = document.getElementsByClassName('leaflet-draw-draw-polygon')[0];
            currentPolygonElem.classList.remove('disable-polygon');
        };

        map.on('draw:created', showPolygonArea);
        map.on('draw:edited', showPolygonAreaEdited);
        map.on('draw:drawvertex', (e) => {
            if (Object.keys(e.layers._layers).length > 2) {
                const tempClass = document.getElementsByClassName('leaflet-marker-icon leaflet-div-icon leaflet-editing-icon leaflet-vertex leaflet-zoom-animated leaflet-interactive')[0];
                tempClass.className += ' animate-class';
                // remove classes if number of vertexes drawn > 2
                const element = document.getElementsByClassName('leaflet-draw-actions leaflet-draw-actions-top leaflet-draw-actions-bottom')[0].getElementsByTagName('li')[0];
                element.classList.remove('hide-finish');
                const parentElement = document.getElementsByClassName('leaflet-draw-actions leaflet-draw-actions-top leaflet-draw-actions-bottom')[0];
                parentElement.classList.remove('hide-finish-parent');
            } else {
                // add classes if number of vertexes drawn < 2
                const hideFinishDiv = document.getElementsByClassName('leaflet-draw-actions leaflet-draw-actions-top leaflet-draw-actions-bottom')[0].getElementsByTagName('li')[0];
                hideFinishDiv.classList.add('hide-finish');
                const hideFinishDivParent = document.getElementsByClassName('leaflet-draw-actions leaflet-draw-actions-top leaflet-draw-actions-bottom')[0];
                hideFinishDivParent.classList.add('hide-finish-parent');
            }
        });
        map.on('draw:deleted', saveDelete);
    };

    /**
     * Update polygon boundary
     * @param {String} fieldPolygon
     */

    // Clear layers
    const clearLayers = (type) => {
        let removedLayer;
        if (map) {
            switch (type) {
                case 'marker':
                    removedLayer = marker ? map.removeLayer(marker) : null;
                     break;
                case 'fieldBoundary':
                    removedLayer = fieldBoundaryGeoJson
                    ? map.removeLayer(fieldBoundaryGeoJson) : null;
                     break;
                case 'drawnboundary':
                     map.removeLayer(polygon);
                     removedLayer = polygon ? map.removeLayer(polygon) : null;
                     break;
                default:
                    removedLayer = drawnItems ? drawnItems.clearLayers() : null;
            }
        }
        return removedLayer;
    };

    const checkLayer = (type) => {
        if (map) {
            switch (type) {
                case 'marker':
                    return map.hasLayer(marker);
                case 'fieldBoundary':
                    return map.hasLayer(fieldBoundaryGeoJson);
                case 'drawnboundary':
                    return map.hasLayer(polygon);
                default:
                    return false;
            }
        }
        return false;
    };

    /**
     * @param {Boolean} value
     */
    const editableOption = (value) => {
        isEditable = value;
    };

    /**
     * Update marker based on current location
     * @param {string / int} lat
     * @param {string / int} lng
     * @param {Boolean} isLocationAdded
     */
    const updateMarker = (lat, lng) => {
            const newLatLng = new window.L.LatLng(lat, lng);
            if (marker && marker._icon) {
                marker.setLatLng(newLatLng);
            } else {
                if (marker) {
                    map.removeLayer(marker);
                }
                marker = window.L.marker([lat, lng]).addTo(map);
                drawnItems.addLayer(marker);
            }
            map.setView(marker.getLatLng(), 17);
    };

   /**
    *  To place the marker with given points and edit marker position
    * @param {string} id
    * @param {float / string / int} lat
    * @param {float / string / int} lng
    */
    const drawMarker = (id, lat, lng, callback) => {
        map = map || createMap(id, lat, lng);
        drawnItems = new window.L.FeatureGroup().addTo(map);
        marker = window.L.marker([lat, lng]).addTo(map);
        drawnItems.addLayer(marker);
        map.addLayer(drawnItems);
        drawControl(map, drawnItems, 'marker');
        const onMapClick = (e) => {
            if (isEditable) {
                updateMarker(e.latlng.lat, e.latlng.lng);
                if (callback) {
                    callback(e.latlng.lat, e.latlng.lng);
                }
            }
        };
        const redrawMarker = (e) => {
            updateMarker(e.layer._latlng.lat, e.layer._latlng.lng);
            if (callback) {
                callback(e.layer._latlng.lat, e.layer._latlng.lng);
            }
        };
        const saveMarker = (e) => {
            e.layers.eachLayer((layer) => {
                redrawMarker({ layer });
            });
        };
        const deleteMarker = () => {
            if (callback) {
                callback(null, null);
            }
        };
        map.on('click', onMapClick);
        map.on('draw:created', redrawMarker);
        map.on('draw:edited', saveMarker);
        map.on('draw:deleted', deleteMarker);
    };

    /**
     * To show the field area and disabling pan/drag/scroll/zoom
     *
    */
    const snapshot = (id, lat, lng) => {
        try {
        const mapSnap = createMap(id, lat, lng);
        mapSnap.removeControl(mapSnap.zoomControl);
        mapSnap.scrollWheelZoom.disable();
        mapSnap.dragging.disable();
        mapSnap.doubleClickZoom.disable();
        } catch (error) {
            return null
        }
    };

    /**
     * To set the map view based on current location
     *
    */
    const viewToCurrentLocation = (lat, lng) => {
        map.setView([lat, lng], 17);
        map.panTo(new window.L.LatLng(lat, lng));

        if (locMarker) {
            if (locMarker !== null) {
                map.removeLayer(locMarker);
            }
            locMarker = window.L.marker([lat, lng], { icon: customIcon }).addTo(map);
        }
        if (!locMarker) {
            locMarker = window.L.marker([lat, lng], { icon: customIcon }).addTo(map);
        }
    };

    /**
     *
     * @param {lat, lang, zoom} int=
     */
   const changeMapViewLocation = (lat, lng, zoom) => {
        map.setView([lat, lng], zoom);
    };

    /**
     * To create polyon pass DOM id and polyline points
     * @param {string} id
     * @param {Array} polylinePoints
     */
    const drawPolyine = (id, polylinePoints) => {
        if (!map) {
            map = createMap(id, polylinePoints[0][0], polylinePoints[0][1]);
        }
        map.setView([polylinePoints[0][0], polylinePoints[0][1]], 17);
        const polylineOptions = {
            color: '#209fd5',
            fill: false
        };
        if (polyLineDraw instanceof window.L.Polyline) {
            map.removeLayer(polyLineDraw);
        }
        polyLineDraw = window.L.polyline(polylinePoints, polylineOptions).addTo(map);
    };

    const onLocationFound = (e) => {
        locMarker = window.L.marker([e.latlng.lat, e.latlng.lng], { icon: customIcon }).addTo(map);
        locMarker.setIcon(customIcon);
        map.setView(locMarker.getLatLng(), 17);
    };

    const onLocationError = (e) => {
        console.log(e.message);
    };

    /**
     * To locate user's position
     *
     */
    const getUserPosition = () => {
        if (map !== undefined) {
            map.locate({ watch: true, setView: true, maxZoom: 17 });
            map.on('locationfound', onLocationFound);
            map.on('locationerror', onLocationError);
        }
    };

    return {
      drawPolygon: polygonDraw,
      getPolygonPoints: getPolygonCoord,
      getCreateMap: createMap,
      showFieldLayers: appedOtherFieldLayer,
      drawMarker,
      snapshot,
      isEditable: editableOption,
      updateMarker,
      viewToCurrentLocation,
      changeMapViewLocation,
      drawPolyine,
      updateLanguage,
      updateBoundary,
      getUserPosition,
      clearLayers,
      checkLayer,
      getFieldSize
    };
  };

export const EanaMap = new EanaCreateMap();
