import { 
  REGULATION_LIST_LOADING, 
  REGULATION_LIST_FAILURE, 
  REGULATION_LIST_SUCCESS,
  REGULATION_LIST_SAVE_LOADING, 
  REGULATION_LIST_SAVE_FAILURE, 
  REGULATION_LIST_SAVE_SUCCESS
} from 'constants/store/Regulations';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  regulationList: null,
  hasRegulationSaveError: false,
  isRegulationSaveLoading: false,
  regulationErrorMessage: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case REGULATION_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case REGULATION_LIST_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case REGULATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        regulationList: action.payload
      };
    case REGULATION_LIST_SAVE_LOADING:
      return {
        ...state,
        hasRegulationSaveError: false,
        isRegulationSaveLoading: true,
        regulationErrorMessage: null
      };
    case REGULATION_LIST_SAVE_FAILURE:
      return {
        ...state,
        hasRegulationSaveError: true,
        isRegulationSaveLoading: false,
        regulationErrorMessage: action.payload
      };
    case REGULATION_LIST_SAVE_SUCCESS:
      return {
        ...state,
        hasRegulationSaveError: false,
        isRegulationSaveLoading: false,
        regulationErrorMessage: null,
        regulationList: null,
      };
    default:
      return state;
  }
}
