import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as Bale from 'actions/Bale';
import _ from 'lodash';

import AutoplacementGridSection from '@tine/lib-frontend-components/components/alignment/autoplacement-grid-section';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import SelectedStorageColumn from 'mui-components/storages/balestorage/storagecard/SelectedStorageColumn';
import AddBaleLink from 'mui-components/storages/balestorage/storagecard/AddBaleLink';
import * as BaleAnalysisActions from 'actions/BaleAnalysis';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import mixpanel from 'mixpanel-browser';
import { ENTER_HARVEST_VIEW, ENTER_FIELD_VIEW, VIEW_ANALYSIS } from 'constants/MixPanel';
import Loader from 'components/Loader';

class SelectedHarvestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      selectedBale: false,
      selectedBaleList : null,
      setSelectedBaleList: false,
      selectedAnalysis: false,
      selectedAnalysisList : null,
      setSelectedAnalysisList: false,
      highlightHarvest: null,
      highlightStorage: null,
      highlightAnalysis: null
    };
    this.onSelectedBale = this.onSelectedBale.bind(this);
    this.onSelectedAnalysis = this.onSelectedAnalysis.bind(this);
    this.onUpdateAnalysis = this.onUpdateAnalysis.bind(this);
  }

  componentDidMount = () => {    
    const { history, activeFarm, baleList, getAnalysisStorage, getFeedEventList, userDetails } = this.props;
    const currentYear = new Date().getFullYear();
    if (!activeFarm) {
      history.push('/settings');
    }
    mixpanel.identify(userDetails.id);
    mixpanel.track(ENTER_HARVEST_VIEW);
    baleList(activeFarm.farm.id, currentYear);
    getAnalysisStorage(activeFarm.farm.id);
    getFeedEventList(activeFarm.farm.id);
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, baleList, getSelectedBaleList, getSelectedAnalysisList, getAnalysisStorage, getFeedEventList } = this.props;
    const { setSelectedBaleList, setSelectedAnalysisList } = this.state;
    const currentYear = new Date().getFullYear();
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storage');
      baleList(activeFarm.farm.id, currentYear);
      getAnalysisStorage(activeFarm.farm.id);
      getFeedEventList(activeFarm.farm.id);
    }
    if(!setSelectedBaleList && getSelectedBaleList && getSelectedBaleList.getSelectedBaleData) {
      this.setState({
        selectedBaleList : getSelectedBaleList.getSelectedBaleData,
        setSelectedBaleList: true
      });
    }
    if(!setSelectedAnalysisList && getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      this.setState({
        selectedAnalysisList : getSelectedAnalysisList.getSelectedAnalysisData,
        setSelectedAnalysisList: true
      });
    }
  }

  onUpdateAnalysis = (selectedAnalysisId) => {
    const { activeFarm, getSelectedAnalysis, getSelectedAnalysisList } = this.props;
    if(getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      getSelectedAnalysisList.getSelectedAnalysisData = null;
    }
    this.setState({
      selectedAnalysisList: null,
      setSelectedAnalysisList: false
    });
    getSelectedAnalysis(activeFarm.farm.id, selectedAnalysisId);
  }

  onSelectedBale = (storageId, harvestId) => {
    const { activeFarm, getSelectedBale, getSelectedBaleList, getSelectedAnalysisList, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ENTER_FIELD_VIEW);
    if(getSelectedBaleList && getSelectedBaleList.getSelectedBaleData) {
      getSelectedBaleList.getSelectedBaleData = null;
    }
    if(getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      getSelectedAnalysisList.getSelectedAnalysisData = null;
    }
    this.setState({
      selectedBaleList: null,
      setSelectedBaleList: false,
      selectedAnalysisList: null,
      setSelectedAnalysisList: false,
      highlightHarvest: null,
      highlightStorage: null,
      highlightAnalysis: null
    });
    getSelectedBale(activeFarm.farm.id, storageId, harvestId);
    this.setState({
      selectedBale: true,
      selectedAnalysis: false,
      highlightHarvest: harvestId,
      highlightStorage: storageId
    });
  }

  onSelectedAnalysis = (analysisId, index) => {
    const { activeFarm, getSelectedAnalysis, getSelectedAnalysisList, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(VIEW_ANALYSIS);
    if(getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      getSelectedAnalysisList.getSelectedAnalysisData = null;
    }
    this.setState({
      selectedAnalysisList: null,
      setSelectedAnalysisList: false,
      highlightAnalysis: null
    });
    getSelectedAnalysis(activeFarm.farm.id, analysisId);
    this.setState({
      selectedAnalysis: true,
      highlightAnalysis: index
    });
  }

  render() {
    const { bales, intl, getAnalysisStorageList, getFeedEventListData } = this.props;
    const { paramId, selectedBale, selectedBaleList, selectedAnalysis, selectedAnalysisList,
      highlightStorage, highlightHarvest, highlightAnalysis, setSelectedAnalysisList } = this.state;
    const { getStorageLoading } = getAnalysisStorageList;
    let baleStorageList = null;
    if(bales.data && bales.data.quantity > 0) {
      baleStorageList = _.filter(bales.data.harvests, ['harvest', parseInt(paramId)]);
    }
    return (
      <SectionContainer
        styling={{
          paddingTop: 'low'
        }}
      >
        {getStorageLoading && <Loader styleName="fixed-loader" />}
        <div className="stats-activity balesColumnFlexiGrid">
          <div className='text-header'>
            <span className='text-header-left'>
              <span className='breadCrumb-span'>
                <a href="#/storage">{intl.formatMessage({ id: 'BALE_STORAGE_HEADER' })}</a>
              </span>
              {baleStorageList && 
                <span className='breadCrumb-text'>
                  {baleStorageList && baleStorageList[0].harvest}. {intl.formatMessage({ id: 'SELECTED_HARVEST_HEADING' })} ({baleStorageList && baleStorageList[0].quantity > 0 ? baleStorageList[0].quantity : 0} {intl.formatMessage({ id: 'BALE_OVERVIEW_TOTAL' })})
                </span>
              }
            </span>
            <span className='text-header-right'>
              <AddBaleLink 
                getAnalysisStorageListData = {getAnalysisStorageList.getStorageData}
              />
            </span>
          </div>
          <AutoplacementGridSection className="stats-activity__grid">
            {baleStorageList && 
            <SelectedStorageColumn
              baleStorageList = {baleStorageList}
              column = "harvest"
              intl = {intl}
              selectedBale = {selectedBale}
              onSelectedBale = {this.onSelectedBale}
              paramId = {paramId}
              selectedBaleList = {selectedBaleList}
              selectedAnalysis = {selectedAnalysis}
              onSelectedAnalysis = {this.onSelectedAnalysis}
              selectedAnalysisList = {selectedAnalysisList}
              highlightStorage = {highlightStorage}
              highlightHarvest = {highlightHarvest}
              highlightAnalysis = {highlightAnalysis}
            />}
            {baleStorageList && 
            <SelectedStorageColumn
              baleStorageList = {baleStorageList}
              column = "selectedbale"
              intl = {intl}
              selectedBale = {selectedBale}
              onSelectedBale = {this.onSelectedBale}
              paramId = {paramId}
              selectedBaleList = {selectedBaleList}
              selectedAnalysis = {selectedAnalysis}
              onSelectedAnalysis = {this.onSelectedAnalysis}
              selectedAnalysisList = {selectedAnalysisList}
              highlightStorage = {highlightStorage}
              highlightHarvest = {highlightHarvest}
              highlightAnalysis = {highlightAnalysis}
            />}
            {baleStorageList && getFeedEventListData &&
            <SelectedStorageColumn
              baleStorageList = {baleStorageList}
              column = "selectedanalysis"
              intl = {intl}
              selectedBale = {selectedBale}
              onSelectedBale = {this.onSelectedBale}
              paramId = {paramId}
              selectedBaleList = {selectedBaleList}
              selectedAnalysis = {selectedAnalysis}
              onSelectedAnalysis = {this.onSelectedAnalysis}
              selectedAnalysisList = {selectedAnalysisList}
              highlightStorage = {highlightStorage}
              highlightHarvest = {highlightHarvest}
              highlightAnalysis = {highlightAnalysis}
              getFeedEventListData = {getFeedEventListData}
              getAnalysisStorageListData = {getAnalysisStorageList.getStorageData}
              setSelectedAnalysisList = {setSelectedAnalysisList}
              onUpdateAnalysis = {this.onUpdateAnalysis}
            />}
          </AutoplacementGridSection>
        </div>
      </SectionContainer>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  bales: state.bales,
  getAnalysisStorageList: state.baleAnalysis,
  getSelectedBaleList: state.baleAnalysis,
  getSelectedAnalysisList: state.baleAnalysis,
  getFeedEventListData: state.baleFeedEvent,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  baleList: (farmId, year) => dispatch(Bale.getBaleList(farmId, year)),
  getAnalysisStorage: (farmId) => dispatch(BaleAnalysisActions.getAllStorages(farmId)),
  getSelectedBale: (farmId, storageId, harvest) => dispatch(BaleAnalysisActions.getSelectedBale(farmId, storageId, harvest)),
  getSelectedAnalysis: (farmId, analysisId) => dispatch(BaleAnalysisActions.getSelectedAnalysis(farmId, analysisId)),
  getFeedEventList: (farmId) => dispatch(baleFeedEventActions.getFeedEventList(farmId))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectedHarvestList)));
