import React from 'react';

const HarvestColumnCard = ({
    harvest,
    index,
    harvestLength,
    intl,
    onSelectedBale,
    paramId,
    highlightHarvest
  }) => (
  <li className={`storage-list-item full-width`} key={index+1}>
    <div
      className={`storage-list-item-link ${index === harvestLength-1 ? 'border-bottom-style' : ''}
      ${highlightHarvest === harvest.harvest ? 'set-background-color' : ''}`}  
      onClick={() => onSelectedBale(paramId, harvest.harvest)}
    >
      <div className="storage-details">
        <div className="icon-width">
          <span>
            <img src={`./images/h${harvest.harvest}.png`} className="bale-icon-size" alt="draw" />
          </span>
        </div>
        <div className="storage-name medium-width">
          <span title="slatt">
            <span className='medium-font-text vertical-align-sub number-padding-left'>
              {harvest.harvest}. {intl.formatMessage({ id: 'HARVEST_MENU_TITLE' })}
            </span>
          </span>
        </div>
        <div className="storage-number minimum-width">
          <span className="label-number">
            {harvest.quantity}
          </span>
        </div>
        <div className="harvest-number-column">          
          <span className="icon-arrow-up-accordion add-storage-arrow" />
          <span className="bale-storage-bale">
            <img src={`./images/bale_blue.png`} className="bale-blue-icon-size" alt="draw" />
          </span>
        </div>
      </div>
    </div>
  </li>
);

export default HarvestColumnCard;
