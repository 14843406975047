import { 
  GET_BALES_SUCCESS, 
  GET_BALES_ERROR,
  GET_BALES_LOADING,
  GET_BALE_MARKER_SUCCESS,
  GET_BALE_MARKER_ERROR,
  GET_BALE_MARKER_LOADING,
  ADD_BALE_MARKER_SUCCESS,
  ADD_BALE_MARKER_ERROR,
  ADD_BALE_MARKER_LOADING,
  UPDATE_BALE_MARKER_SUCCESS,
  UPDATE_BALE_MARKER_ERROR,
  UPDATE_BALE_MARKER_LOADING,
  DELETE_BALE_MARKER_SUCCESS,
  DELETE_BALE_MARKER_ERROR,
  DELETE_BALE_MARKER_LOADING,
  SAVE_BALE_MARKER_COORDINATES
} from 'constants/store/Bale';
import { POST, PUT, GET, DELETE } from 'constants/index';
import { FIELD_V1, FARM_V1 } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';


/**
 * Get the bale storage list
 * @param {string} farmId
 */
 export const getBaleList = (farmId, year) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_BALES_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/overview?year=${year}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_BALES_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_BALES_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_BALES_ERROR, payload: error });
  }
};

export const getBaleMarker = (farmId, baleStorageId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_BALE_MARKER_LOADING, payload: true });
    const apiUrl = `${FIELD_V1}${farmId}/balestorages/${baleStorageId}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_BALE_MARKER_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_BALE_MARKER_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_BALE_MARKER_ERROR, payload: error });
  }
};

export const addBaleMarker = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_BALE_MARKER_LOADING, payload: true });
    const apiUrl = `${FIELD_V1}${farmId}/balestorages`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: POST
      })
    )
    .then(response => {
      dispatch({ type: ADD_BALE_MARKER_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: ADD_BALE_MARKER_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: ADD_BALE_MARKER_ERROR, payload: error });
  }
};

export const saveBaleMarkerCoordinates = mapObj => ({
  type: SAVE_BALE_MARKER_COORDINATES,
  payload: mapObj
});

export const updateBaleMarker = (farmId, baleStorageId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_BALE_MARKER_LOADING, payload: true });
    const apiUrl = `${FIELD_V1}${farmId}/balestorages/${baleStorageId}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: PUT
      })
    )
    .then(response => {
      dispatch({ type: UPDATE_BALE_MARKER_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: UPDATE_BALE_MARKER_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: UPDATE_BALE_MARKER_ERROR, payload: error });
  }
};

export const deleteBaleMarker = (farmId, baleStorageId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_BALE_MARKER_LOADING, payload: true });
    const apiUrl = `${FIELD_V1}${farmId}/balestorages/${baleStorageId}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: DELETE
      })
    )
    .then(response => {
      dispatch({ type: DELETE_BALE_MARKER_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: DELETE_BALE_MARKER_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: DELETE_BALE_MARKER_ERROR, payload: error });
  }
};
