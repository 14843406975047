import {
  SUPPORT_ADMIN_ROLE_SUCCESS,
  SUPPORT_ADMIN_ROLE_FAILURE
} from 'constants/store/RolesAndActivity';

const initialState = {
  hasError: false,
  errorMessage: null,
  data: null
};

export default function supportAdminReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SUPPORT_ADMIN_ROLE_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage
      };
    case SUPPORT_ADMIN_ROLE_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        hasError: false,
        data: action.data
      };
    default:
      return state;
  }
}
