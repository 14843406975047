import React from 'react';
import { TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';

const selectStyles = {
  '&:before': {
    borderColor: 'red', // Outline color
  },
  '&:after': {
    borderColor: 'green', // Outline color when focused
  },
};
const MuiSelectBox = ({
  name,
  label,
  variant,
  id,
  options,
  onChange,
  value,
  disabled,
  setState,
  InputLabelProps,
  defaultValue,
  required
}) => {
  return (
    <TextField
      variant={variant}
      label={label}
      name={name}
      select
      id={id}
      value={undefined || value}
      style={selectStyles}
      disabled={disabled}
      defaultValue={defaultValue}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#00696D"
          }
        },
        '& label.Mui-focused': {
          color: '#00696D',
          fontFamily: 'Inter'
        },
        display: 'flex',
        "& .MuiSelect-select": {
          maxHeight: 400,
          overflow: 'auto',
        }
      }}
      onChange={onChange}
      setState={setState}
      required={required ? required : false}
      InputLabelProps={InputLabelProps}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 400, // Max height for dropdown
              overflow: 'auto',
              border: 'none'
            },
          },
        },
      }}
    >
    {options !== null && options !== undefined && options.map((option) => (
      <MenuItem key={option.value} value={option.value} sx={{ border: 'none', backgroundColor: 'transparent', maxHeight: 200 }} disabled={option.disabled ? option.disabled : false}>
        {option.label}
      </MenuItem>
    ))}
    </TextField>
  );
};

export default MuiSelectBox;
