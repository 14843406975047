import * as React from 'react';

import HistoricalList from 'mui-components/fields/fieldtab/historical/HistoricalList';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
 
class Historical extends React.Component {

  //render method
  render() {
    return (
    <div className={`full-width ${isMobile ? 'main-content-mobile' : 'main-content'}`}>
      <HistoricalList />
    </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});

export default injectIntl(withRouter(connect(mapStateToProps)(Historical)));