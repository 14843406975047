import {
  IS_TERMS_CONDITION_LOADING,
  TERMS_CONDITION_FAILURE,
  TERMS_CONDITION_SUCCESS,
  TERMS_CHECK_SUCCESS,
  TERMS_ACCEPT_SUCCESS
} from 'constants/store/Auth';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  termCheck: null
};

export default function termsAndCondition(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case IS_TERMS_CONDITION_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
        errorMessage: null
      };
    case TERMS_CONDITION_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.data
      };
    case TERMS_CHECK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        termCheck: action.data
      };
    }
    case TERMS_ACCEPT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          versionId: action.data.versionId,
          id: action.data.id,
          language: action.data.language
        },
        termCheck: true
      };
    }
    default:
      return state;
  }
}
