import {
  GET_MASTER_LIST,
  IS_MASTER_LIST_FAILURE,
  IS_MASTER_LIST_LOADING
} from 'constants/store/RolesAndActivity';
import { MASTER_DATA } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * to get activity list details
 */
export default () => async (dispatch, getState) => {
  dispatch({ type: IS_MASTER_LIST_LOADING, isLoading: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${MASTER_DATA}?language=${GetLanguageCode(preferredLanguage)}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch({ type: GET_MASTER_LIST, data: response.data });
    })
    .catch(error => {
      dispatch({ type: IS_MASTER_LIST_FAILURE, ErrMsg: error });
    });
};
