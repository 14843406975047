import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StorageList from 'mui-components/storages/balestorage/StorageList';
import SelectedHarvestList from 'mui-components/storages/balestorage/SelectedHarvestList';
import SelectedBaleList from 'mui-components/storages/balestorage/SelectedBaleList';
import PageNotFound from 'components/PageNotFound';

const SoilSamples = () => (
  <Switch>    
    <Route exact path="/storage/harvest/:id" component={SelectedHarvestList} />
    <Route exact path="/storage/bale/:id" component={SelectedBaleList} />
    <Route exact path="/storage" component={StorageList} />
    <Route component={PageNotFound} />
  </Switch>
);

export default SoilSamples;
