import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import EditBaleStorageMapModal from 'mui-components/storages/balestorage/balestorage/EditBaleStorageMapModal';
import { CrossIcon } from '@tine/lib-frontend-components/elements/icons';
import TextBoxControl from 'components/forms/TextBoxControl';
import * as SchedulerBaleActions from 'actions/SchedulerBale';
import * as baleActions from 'actions/Bale';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import mixpanel from 'mixpanel-browser';
import { ADD_FEED_SCHEDULER, FEED_ANALYSIS_CREATED, FEED_ANALYSIS_UPDATED } from 'constants/MixPanel';
import Loader from 'components/Loader';
import _ from 'lodash';

class SchedulerColumn extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      showSchedulerList: false,
      showAddScheduler: false,
      addSchedulerFirstScreen: false,
      addSchedulerSecondScreen: false,
      addSchedulerThirdScreen: false,
      selectedHarvest: null,
      selectedQuantity: null,
      noQuantityError: false,
      storageValues: null,
      selectedStoragesError: false,
      selectedStorageCheckbox: [],
      selectedFieldCheckbox: [],
      schedulerFieldsUpdated: false,
      selectedFieldsError: false,
      showEditBaleStorage: false,
      selectedStorageId: null,
      getSchedulerById: false,
      selectedScheduleId: null
    };
  }

  componentDidMount = () => {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    this.setState({
      showSchedulerList: false
    });
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, getAllScheduledBales, schedulerBaleDetails, schedulerBale } = this.props;
    const { schedulerFieldsUpdated, selectedFieldCheckbox, getSchedulerById } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storage');
      getAllScheduledBales(activeFarm.farm.id);
      if(selectedFieldCheckbox) {
        this.setState({
          selectedFieldCheckbox: []
        });
      }
    }
    if(!schedulerFieldsUpdated && schedulerBaleDetails && schedulerBaleDetails.storageBaleDetails) {
      let data = [];
      let keyPair = {};
      let schedulerData = null;
      let filterStorageData = null;
      let filterFieldData = null;
      let finalFilterData = null;
      let noFieldsLength = 0;
      if(schedulerBale && schedulerBale.getBaleSchedulerData) {
        schedulerData = schedulerBale.getBaleSchedulerData.storages.length > 0 ? schedulerBale.getBaleSchedulerData.storages : null;
      }
      Object.keys(schedulerBaleDetails.storageBaleDetails).map(storage => (
        //eslint-disable-next-line
        noFieldsLength = 0,
        filterStorageData = schedulerData ? _.filter(schedulerData, ['id', storage]) : null,
        schedulerBaleDetails.storageBaleDetails[storage].map(fields => (
          fields.fieldId === "no-fields" ? noFieldsLength++ : ''
        )),
        schedulerBaleDetails.storageBaleDetails[storage].map((fields,index) => (
          //eslint-disable-next-line
          filterFieldData = filterStorageData && filterStorageData.length > 0 && filterStorageData[0].fieldDetails && filterStorageData[0].fieldDetails.length > 0 ? _.filter(filterStorageData[0].fieldDetails, ['id', fields.fieldId]) : null,
          finalFilterData = _.filter(filterFieldData, ['year', fields.year]),
          keyPair = {},
          keyPair['id'] = index+'_'+storage+'_'+fields.fieldId+'_'+fields.year,
          keyPair['show'] = (finalFilterData !== null && finalFilterData.length > 0) ? true : ((fields.fieldId === "no-fields" && noFieldsLength === 1) ? true : false),
          data.push(keyPair)
        ))
      ));
      this.setState({
        selectedFieldCheckbox: data,
        schedulerFieldsUpdated: true
      });
    }
    if(!getSchedulerById && schedulerBale && schedulerBale.getBaleSchedulerData) {
      this.setSchedulerInitialize();
      this.setState({
        getSchedulerById: true
      });
    }
  }

  selectScheduler = () => {
    this.setState({
      showSchedulerList: true
    })
  }

  selectHarvest = (harvest, scheduleId) => {
    const { userDetails, activeFarm, getScheduledBaleById, schedulerBale } = this.props;
    schedulerBale.getBaleSchedulerData = null;
    if(scheduleId !== null) {
      getScheduledBaleById(activeFarm.farm.id, scheduleId);
      this.setState({
        getSchedulerById: false,
        selectedScheduleId: scheduleId
      })
    }
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_FEED_SCHEDULER);
    this.setState({
      showAddScheduler: true,
      addSchedulerFirstScreen: true,
      addSchedulerSecondScreen: false,
      addSchedulerThirdScreen: false,
      selectedHarvest: harvest.harvest
    });
  }

  handleSubmit = values => {
    const { bales, activeFarm, getScheduledBaleDetails, schedulerBaleDetails, saveScheduledBaleDetails, userDetails, 
      updateScheduledBaleDetails } = this.props;
    const { addSchedulerFirstScreen, addSchedulerSecondScreen, addSchedulerThirdScreen, selectedHarvest, 
      selectedStorageCheckbox, selectedFieldCheckbox, selectedScheduleId } = this.state;
    if(addSchedulerFirstScreen) {
      let baleQuantityValue = values.baleQuantity+"";
      let baleQuan = baleQuantityValue ? baleQuantityValue.replace(',','.') : '';
      if (!baleQuan || !baleQuan.trim()) {
        this.setState({
          noQuantityError: true
        });
        return;
      }
      if (baleQuan && isNaN(baleQuan)) {
        this.setState({
          noQuantityError: true
        });
        return;
      }
      let baleStorageList = null;
      if(bales && bales.data && bales.data.quantity > 0) {
        baleStorageList = _.filter(bales.data.harvests, ['harvest', parseInt(selectedHarvest)]);
      }
      let data = [];
      let keyPair = {};
      baleStorageList && baleStorageList.length > 0 &&  baleStorageList[0].balesByStorage.map(storage => (
        //eslint-disable-next-line
        keyPair = {},
        keyPair['id'] = storage.id,
        keyPair['show'] = false,
        data.push(keyPair)
      ));
      if(selectedStorageCheckbox.length <= 0){
        this.setState({
          selectedStorageCheckbox: data
        });
      }
      this.setState({
        addSchedulerFirstScreen: false,
        addSchedulerSecondScreen: true,
        addSchedulerThirdScreen: false,
        noQuantityError: null,
        selectedQuantity: baleQuan,
        storageValues: baleStorageList && baleStorageList.length > 0 ? baleStorageList[0].balesByStorage : null
      });
    }
    if(addSchedulerSecondScreen) {
      const year = new Date().getFullYear();
      let storages = [];
      let checkBoxSelected = 0;
      selectedStorageCheckbox.length > 0 &&  selectedStorageCheckbox.map(storage => (
        storage.show ? checkBoxSelected = 1 : ''
      ));
      if(checkBoxSelected === 0) {
        this.setState({
          selectedStoragesError: true
        });
        return;
      }
      if(schedulerBaleDetails && schedulerBaleDetails.storageBaleDetails) {
        schedulerBaleDetails.storageBaleDetails = null;
      }
      this.setState({
        selectedFieldCheckbox: [],
        schedulerFieldsUpdated: false
      })
      selectedStorageCheckbox.length > 0 &&  selectedStorageCheckbox.map(storage => (
        storage.show ? storages.push(storage.id) : ''
      ));
      getScheduledBaleDetails(activeFarm.farm.id, year, selectedHarvest, storages);
      this.setState({
        addSchedulerFirstScreen: false,
        addSchedulerSecondScreen: false,
        addSchedulerThirdScreen: true,
        noQuantityError: false,
        selectedStoragesError: false,
        selectedFieldsError: false
      });
    }
    if(addSchedulerThirdScreen) {
      const { selectedHarvest, selectedQuantity } = this.state;
      const year = new Date().getFullYear();
      let keyPair = {};
      let data = [];
      let fieldData = [];
      let fieldKeyPair = {};
      let splitValue = null;
      selectedStorageCheckbox.map(storage => (
        //eslint-disable-next-line
        fieldData = [],
        storage.show ?
        selectedFieldCheckbox.map(field => (
          //eslint-disable-next-line
          fieldKeyPair = {},
          splitValue = field.id.split("_"),
          (splitValue[1] === storage.id && field.show) ? 
          (fieldKeyPair['id'] = splitValue[2],
          fieldKeyPair['year'] = splitValue[3],
          fieldData.push(fieldKeyPair))
          : ''
        )) : '',
        keyPair = {},
        keyPair['storageId'] = storage.id,
        fieldData.length > 0 ? keyPair['fieldDetails'] = fieldData : '',
        storage.show ? data.push(keyPair) : ''
      ));
      let formData = {};
      formData["farmId"] = activeFarm.farm.id;
      formData["harvest"] = selectedHarvest;
      formData["quantity"] = selectedQuantity;
      formData["storages"] = data;
      if(selectedScheduleId !== null) {
        formData["id"] = selectedScheduleId;
      }
      mixpanel.identify(userDetails.id);
      if(selectedScheduleId !== null) {
        mixpanel.track(FEED_ANALYSIS_UPDATED);
        updateScheduledBaleDetails(activeFarm.farm.id, year, formData, selectedScheduleId);
      } else {
        mixpanel.track(FEED_ANALYSIS_CREATED);
        saveScheduledBaleDetails(activeFarm.farm.id, year, formData);
      }
    }
  }

  checkBoxChange = e => {
    const { selectedStorageCheckbox } = this.state;
    let data = [];
    let keyPair = {};
    selectedStorageCheckbox.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.id,
      keyPair['show'] = (e === field.id) ? (field.show === true ? false : true) : field.show,
      data.push(keyPair)
    ))
    this.setState({
      selectedStorageCheckbox: data
    });
  }

  fieldCheckBoxChange = e => {
    const { selectedFieldCheckbox } = this.state;
    let data = [];
    let keyPair = {};
    selectedFieldCheckbox.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.id,
      keyPair['show'] = (e === field.id) ? (field.show === true ? false : true) : field.show,
      data.push(keyPair)
    ))
    this.setState({
      selectedFieldCheckbox: data
    });
  }

  setSchedulerInitialize = () => {
    const { initialize, schedulerBale, bales } = this.props;
    const { selectedHarvest } = this.state;
    if(schedulerBale.getBaleSchedulerData) {
      const formValues = {
        baleQuantity: schedulerBale.getBaleSchedulerData.quantity
      };
      initialize(formValues);
    }
    let baleStorageList = null;
    let selectedStorage = null;
    if(bales && bales.data && bales.data.quantity > 0) {
      baleStorageList = _.filter(bales.data.harvests, ['harvest', parseInt(selectedHarvest)]);
    }
    let data = [];
    let keyPair = {};
    baleStorageList.length > 0 &&  baleStorageList[0].balesByStorage.map(storage => (
      //eslint-disable-next-line
      selectedStorage = schedulerBale.getBaleSchedulerData.storages && _.filter(schedulerBale.getBaleSchedulerData.storages, ['id', storage.id]),      
      keyPair = {},
      keyPair['id'] = storage.id,
      keyPair['show'] = selectedStorage.length > 0 ? true : false,
      data.push(keyPair),
      selectedStorage = null
    ));
    this.setState({
      selectedStorageCheckbox: data
    });
  }

  getSchedulerInitialize = () => {
    const { initialize, schedulerBale } = this.props;
    schedulerBale.getBaleSchedulerData = null;
    const formValues = {
      baleQuantity: ''
    };
    this.setState({
      showAddScheduler: false,
      addSchedulerFirstScreen: false,
      addSchedulerSecondScreen: false,
      addSchedulerThirdScreen: false,
      selectedHarvest: null,
      selectedQuantity: null,
      noQuantityError: false,
      selectedStoragesError: false,
      selectedFieldsError: false,
      selectedStorageCheckbox: [],
      selectedFieldCheckbox: []
    });
    initialize(formValues);
  }

  goBackFirstScreen = () => {
    this.setState({
      addSchedulerFirstScreen: true,
      addSchedulerSecondScreen: false,
      addSchedulerThirdScreen: false
    });
  }

  goBackSecondScreen = () => {
    this.setState({
      addSchedulerFirstScreen: false,
      addSchedulerSecondScreen: true,
      addSchedulerThirdScreen: false
    });
  }

  closeFirstScreen = () => {
    this.getSchedulerInitialize();
  }

  closeEditStoragePlan() {
    this.setState({
      showEditBaleStorage: false
    });
  }

  setSavedCoordinates = (storageId) => {
    const { bales } = this.props;
    const baleStorageId = storageId;
    const { saveBaleMarkerCoordinates } = this.props;
    const storageItem = _.filter(bales.data.storages, ['id', baleStorageId])[0];
    if (storageItem) {
      saveBaleMarkerCoordinates({
        latitude: storageItem.gpsPoint.latitude,
        longitude: storageItem.gpsPoint.longitude
      });
      this.setState({
        setSaveCoordinates: true
      });
    }
  };

  editBaleStorage = (storageId) => {
    this.getSchedulerInitialize();
    this.setSavedCoordinates(storageId);
    this.setState({
      addSchedulerFirstScreen: false,
      addSchedulerSecondScreen: false,
      addSchedulerThirdScreen: false,
      showEditBaleStorage: true,
      selectedStorageId: storageId
    });
    return;
  }

  render() {
    const { intl, schedulerBaleList, handleSubmit, schedulerBale, schedulerBaleDetails, bales, activeFarm, hasSupportAdminRole } = this.props;
    const { showSchedulerList, showAddScheduler, addSchedulerFirstScreen, addSchedulerSecondScreen, addSchedulerThirdScreen, 
      selectedHarvest, noQuantityError, storageValues, selectedStorageCheckbox, selectedStoragesError, selectedFieldCheckbox,
      selectedFieldsError, selectedStorageId, showEditBaleStorage } = this.state;
    const harvestLength = [1, 2, 3, 4];
    const enableCreateButton = activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    let errorMessageDisplay = "";
    if(schedulerBale.saveSchedulerError){
      errorMessageDisplay = schedulerBale.saveSchedulerErrorMessage;
    }
    storageValues && storageValues.map(storageVal => (
      errorMessageDisplay = errorMessageDisplay.replace(storageVal.id, " - "+storageVal.name)
    ))      
    return (
      <FlexGridItem>
        <div className={`storage-box storage-box--users`}>
          <div className="storage-box__title">
            <div className='floatLeft'>
              <Emphasis size="normal">{intl.formatMessage({ id: 'SCHEDULER_TITLE' })}</Emphasis>
            </div>
            <div className="text-align-end">
              <img src={`./images/feeding_icon.png`} alt="Feeding" />
            </div>
          </div>
          {schedulerBaleList && Object.entries(schedulerBaleList.feedSchedules).length === 0 && !showSchedulerList &&
          <div className="vehicle-list-flex-container full-width">
            <div className="storage-list-details">
              <ul className="storage-list-wrapper">
                <li className={`storage-list-item full-width`} key="1">
                  <div className={`storage-list-item-scheduler`} onClick={() => this.selectScheduler()}>
                    <div className="storage-details">
                      <div className="scheduler-title">
                        {intl.formatMessage({ id: 'SCHEDULER_FIRST_CONTENT' })}
                      </div>
                      <div className="storage-arrow paddingRight-less">
                        <span className="icon-arrow-up-accordion add-storage-arrow-label" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>}
          {schedulerBaleList && Object.entries(schedulerBaleList.feedSchedules).length === 0 && showSchedulerList &&
          <div className="vehicle-list-flex-container full-width">
            <div className="storage-list-details">
              <ul className="storage-list-wrapper">
                {harvestLength.map((harvest, index) => (
                <li className={`storage-list-item full-width`} key={index}>
                  <div className={`storage-list-item-link ${harvest === 4 ? 'border-bottom-style' : ''}`} onClick={() => this.selectHarvest({harvest}, null)}>
                    <div className="storage-details">
                      <div className="icon-width">
                        <span>
                          <img src={`./images/h${harvest}.png`} className="bale-icon-size" alt="harvest" />
                        </span>
                      </div>
                      <div className="storage-name medium-width">
                        <span className="label-number number-padding-left">
                          0
                        </span>
                        <span className='medium-font-text number-padding-left'>
                          {intl.formatMessage({ id: 'SCHEDULER_HARVEST_COUNT' })}
                        </span>
                      </div>
                      <div className="harvest-number-column">          
                        <span className="icon-arrow-up-accordion scheduled-storage-arrow" />
                      </div>
                    </div>
                  </div>
                </li>))}
              </ul>
            </div>
          </div>}
          {schedulerBaleList && Object.entries(schedulerBaleList.feedSchedules).length > 0 &&
          <div className="vehicle-list-flex-container full-width">
            <div className="storage-list-details">
              <ul className="storage-list-wrapper">
                {Object.keys(schedulerBaleList.feedSchedules).map((harvest, index) => (
                <li className={`storage-list-item full-width`} key={index}>
                  {schedulerBaleList.feedSchedules[harvest] === null &&
                  <div className={`storage-list-item-link ${index === 3 ? 'border-bottom-style' : ''}`} onClick={() => this.selectHarvest({harvest}, null)}>
                    <div className="storage-details">
                      <div className="icon-width">
                        <span>
                          <img src={`./images/h${index+1}.png`} className="bale-icon-size" alt="harvest" />
                        </span>
                      </div>
                      <div className="storage-name large-width">
                        <span className="label-number number-padding-left">
                          0
                        </span>
                        <span className='medium-font-text number-padding-left'>
                          {intl.formatMessage({ id: 'SCHEDULER_HARVEST_COUNT' })}
                        </span>
                      </div>
                      <div className="harvest-number-column">          
                        <span className="icon-arrow-up-accordion scheduled-storage-arrow" />
                      </div>
                    </div>
                  </div>}
                  {schedulerBaleList.feedSchedules[harvest] !== null &&
                  <div className={`storage-list-item-link ${index === 3 ? 'border-bottom-style' : ''}`} onClick={() => this.selectHarvest({harvest}, schedulerBaleList.feedSchedules[harvest].scheduleId)}>
                    <div className="storage-details">
                      <div className="icon-width">
                        <span>
                          <img src={`./images/h${index+1}.png`} className="bale-icon-size" alt="harvest" />
                        </span>
                      </div>
                      <div className="storage-name scheduler-large-width">
                        <span className="label-number number-padding-left">
                          {schedulerBaleList.feedSchedules[harvest].quantity}
                        </span>
                        {(schedulerBaleList.feedSchedules[harvest].storageName !== null &&
                        <span className='small-font-span number-padding-left'>
                          {intl.formatMessage({ id: 'SCHEDULER_TEXT_1' })} {schedulerBaleList.feedSchedules[harvest].storageName}
                        </span>)}
                        {(schedulerBaleList.feedSchedules[harvest].storageName === null &&
                        <span className='small-font-span number-padding-left'>
                          {intl.formatMessage({ id: 'SCHEDULER_TEXT_1' })} {schedulerBaleList.feedSchedules[harvest].storageCount} {intl.formatMessage({ id: 'SCHEDULER_TEXT_2' })}
                        </span>)}
                      </div>
                      <div className="scheduler-harvest-number-column">          
                        <span className="icon-arrow-up-accordion scheduled-storage-arrow" />
                      </div>
                    </div>
                    <div className="storage-details">
                      <div className="icon-width"></div>
                      <div className="storage-name scheduler-extra-large-width">
                        {(schedulerBaleList.feedSchedules[harvest].unSortedStorage !== null &&
                        <span className="small-font-span scheduler-box-display">
                          <span className="small-font-span-bold-padding">{schedulerBaleList.feedSchedules[harvest].unSortedStorage}</span> ({schedulerBaleList.feedSchedules[harvest].availableQuantity} {intl.formatMessage({ id: 'SCHEDULER_TEXT_5' })})
                        </span>)}
                        {(schedulerBaleList.feedSchedules[harvest].unSortedStorage === null &&
                        <span className="small-font-span scheduler-box-display">
                          {intl.formatMessage({ id: 'SCHEDULER_TEXT_3' })} <span className="small-font-span-bold-padding">{schedulerBaleList.feedSchedules[harvest].fieldCount} {schedulerBaleList.feedSchedules[harvest].fieldName !== null ? schedulerBaleList.feedSchedules[harvest].fieldName : intl.formatMessage({ id: 'SCHEDULER_TEXT_4' })}</span> ({schedulerBaleList.feedSchedules[harvest].availableQuantity} {intl.formatMessage({ id: 'SCHEDULER_TEXT_5' })})
                        </span>)}
                      </div>
                    </div>
                  </div>}
                </li>))}
              </ul>
            </div>
          </div>}
        </div>
        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '24%'}}
          width={{mobile: '90%', tablet: '55%', web: `${addSchedulerFirstScreen ? '24%' : '28%'}`}}
          height={{mobile: '90%', tablet: '55%', web: `${addSchedulerFirstScreen ? '56%' : '60%'}`}}
          show={showAddScheduler}
          closeIcon=""
          onClose={() => {}}>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              {schedulerBale.getBaleSchedulerLoading && <Loader styleName="fixed-loader" />}
              {addSchedulerFirstScreen && 
              <div>
                <div className='text-align-center'>
                  <Emphasis size="extralarge">{intl.formatMessage({ id: 'SCHEDULER_FIRSTSCREEN_TEXT_1' })} {selectedHarvest}.{intl.formatMessage({ id: 'ADD_ANALYSIS_SLATT' })}</Emphasis>
                </div>
                <div>
                  <div className='main-bale-storage-form-style'>
                    <div className="align-center-span">
                      <Emphasis size="normal">{intl.formatMessage({ id: 'SCHEDULER_FIRSTSCREEN_TEXT_2' })} {selectedHarvest}.{intl.formatMessage({ id: 'SELECTED_HARVEST_HEADING' })}</Emphasis>
                    </div>
                    <div className="align-center-div">
                      <span className="padding-row-small"><span className="circle-icon"></span></span>
                      <span className="padding-row-small"><span className="circle-icon-disable"></span></span>
                      <span><span className="circle-icon-disable"></span></span>
                    </div>
                    <div>
                      <div>
                        <div className="padding-top-div-large full-width">
                          <Field
                            name="baleQuantity"
                            label={intl.formatMessage({ id: 'SCHEDULER_FIRSTSCREEN_TEXT_3' })}
                            component={TextBoxControl}
                            controlId="baleQuantity"
                            type="text"
                            units={`${intl.formatMessage({ id: 'ADD_BALE_QUANTITY_UNITS' })}`}
                          />
                        </div>
                        <div className="lightbox-field-padding-top-large"><span className="paddingRight"></span></div>
                        <div className="lightbox-field-padding-top-large">
                          <span className="paddingRight">
                            {noQuantityError && <ErrorText>{intl.formatMessage({ id: 'SCHEDULER_FIRSTSCREEN_TEXT_4' })}</ErrorText>}
                          </span>
                        </div>
                        <div className="bale-button-style">
                          <span className="paddingRight">
                            <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                              {intl.formatMessage({ id: 'ADD_ANALYSIS_NEXT_BUTTON' })}
                            </ActionButton>
                          </span>
                          <span className="bale-list-cancel-button" onClick={() => this.closeFirstScreen()}>
                            {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              {addSchedulerSecondScreen && 
              <div>
                <div className='text-align-center'>
                  <span className="breadCrumb-scheduler-span-modal" onClick={() => this.goBackFirstScreen()}>{intl.formatMessage({ id: 'BACK' })}</span>
                  <span>
                    <Emphasis size="extralarge">{intl.formatMessage({ id: 'SCHEDULER_FIRSTSCREEN_TEXT_1' })} {selectedHarvest}.{intl.formatMessage({ id: 'ADD_ANALYSIS_SLATT' })}</Emphasis>
                  </span>
                </div>
                <div>
                  <div className='main-bale-storage-form-style'>
                    <div className='text-align-center'>
                      <Emphasis size="normal">{intl.formatMessage({ id: 'SCHEDULER_SECONDSCREEN_TEXT_1' })}</Emphasis>
                    </div>
                    <div className="align-center-div">
                      <span className="padding-row-small"><span className="circle-icon"></span></span>
                      <span className="padding-row-small"><span className="circle-icon"></span></span>
                      <span><span className="circle-icon-disable"></span></span>
                    </div>
                    <div className="text-align-left padding-top-div-large padding-bottom-div">
                      <Emphasis size="normal">
                        {intl.formatMessage({ id: 'SCHEDULER_SECONDSCREEN_TEXT_2' })}
                      </Emphasis>
                    </div>
                    {!storageValues && 
                    <div className="text-align-left padding-top-div-large padding-bottom-div">
                        {intl.formatMessage({ id: 'SCHEDULER_SECONDSCREEN_TEXT_3' })}
                    </div>}
                    <div>
                      <div>
                        {storageValues && 
                        <div className="modal-storage-list-details">
                          <ul className="storage-list-wrapper">
                            {storageValues.map((storage, index) => (
                              <li className={`modal-storage-list-item full-width lightbox-padding-bottom-div-small style-cursor`} key={index+1} onClick={e => this.checkBoxChange(storage.id)}>
                                <div className="storage-details">
                                  <div className="storage-name large-width">
                                    <span title="slatt">
                                      <span className=''>
                                        <img src={`./images/bale_storage.png`} className="bale-icon-size" alt="storage" />
                                      </span>
                                      <span className='medium-font-text vertical-align-super number-padding-left'>{storage.name}</span>
                                    </span>
                                  </div>
                                  <div className="storage-number schedulerMediumWidth">
                                    <span className="label-number">
                                      {storage.quantity}
                                    </span>
                                  </div>
                                  <div className="storage-arrow low-padding-top schedulerContainer">
                                    <span className="location-type-checkbox">
                                      <input
                                        type="checkbox"
                                        id={`${storage.id}`}
                                        checked={selectedStorageCheckbox.length > 0 ? (selectedStorageCheckbox.length > 0 && _.filter(selectedStorageCheckbox, ['id', storage.id])[0]).show : false}
                                        onChange={e => this.checkBoxChange(storage.id)}
                                        className="custom-checkbox"
                                      />
                                      <span className="checkmark"></span>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>}
                        <div className="lightbox-field-padding-top-large"><span className="paddingRight"></span></div>
                        <div className="lightbox-field-padding-top-large">
                          <span className="paddingRight">
                            {selectedStoragesError && <ErrorText>{intl.formatMessage({ id: 'SCHEDULER_SECONDSCREEN_TEXT_4' })}</ErrorText>}
                          </span>
                        </div>
                        <div className="bale-button-style">
                          {storageValues &&
                          <span className="paddingRight">
                            <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                              {intl.formatMessage({ id: 'ADD_ANALYSIS_NEXT_BUTTON' })}
                            </ActionButton>
                          </span>}
                          <span className="bale-list-cancel-button" onClick={() => this.closeFirstScreen()}>
                            {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              {addSchedulerThirdScreen && 
              <div>
                {schedulerBale.saveSchedulerSuccess && window.location.reload()}
                <div className='text-align-center'>
                  <span className="breadCrumb-scheduler-span-modal" onClick={() => this.goBackSecondScreen()}>{intl.formatMessage({ id: 'BACK' })}</span>
                  <span>
                    <Emphasis size="extralarge">{intl.formatMessage({ id: 'SCHEDULER_FIRSTSCREEN_TEXT_1' })} {selectedHarvest}.{intl.formatMessage({ id: 'ADD_ANALYSIS_SLATT' })}</Emphasis>
                  </span>
                </div>
                <div>
                  <div className='main-bale-storage-form-style'>
                    <div className='text-align-center'>
                      <Emphasis size="normal">{intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_1' })}</Emphasis>
                    </div>
                    <div className="align-center-div">
                      <span className="padding-row-small"><span className="circle-icon"></span></span>
                      <span className="padding-row-small"><span className="circle-icon"></span></span>
                      <span><span className="circle-icon"></span></span>
                    </div>
                    <div className="text-align-left padding-top-div-large padding-bottom-div">
                      <Emphasis size="normal">
                        {intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_2' })}
                      </Emphasis>
                    </div>
                    {!storageValues && 
                    <div className="text-align-left padding-top-div-large padding-bottom-div">
                        {intl.formatMessage({ id: 'SCHEDULER_SECONDSCREEN_TEXT_3' })}
                    </div>}
                    <div>
                      <div>
                        {storageValues && 
                        <div className="modal-storage-list-details">
                          <ul className="storage-list-wrapper">
                            {storageValues.map((storage, index) => (
                              selectedStorageCheckbox.length > 0 ? (selectedStorageCheckbox.length > 0 && _.filter(selectedStorageCheckbox, ['id', storage.id])[0]).show &&
                              <li className={`full-width padding-bottom-div`} key={index+1}>
                                <div className="scheduler-list-border">
                                  <div className="storage-details padding-bottom-div">
                                    <div>
                                      <div className="storage-name">
                                        <span title="slatt">
                                          <span className=''>
                                            <img src={`./images/bale_storage.png`} className="bale-icon-size" alt="storage" />
                                          </span>
                                          <span className='medium-font-text vertical-align-super number-padding-left'>{storage.name}, {selectedHarvest}.{intl.formatMessage({ id: 'ADD_ANALYSIS_SLATT' })} <span className="label-number">({storage.quantity})</span></span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <ul>
                                    {schedulerBale.baleDetailsSchedulerLoading && <Loader styleName="fixed-loader" />}
                                      {schedulerBaleDetails && schedulerBaleDetails.storageBaleDetails &&
                                      schedulerBaleDetails.storageBaleDetails[storage.id].map((storageDetail, index) => (
                                      <li className={`storage-list-item full-width style-cursor lightbox-padding-bottom-div`} key={index} onClick={() => this.fieldCheckBoxChange(index+'_'+storage.id+'_'+storageDetail.fieldId+'_'+storageDetail.year)}>
                                        <div className={`storage-list-item-selected-link`}>
                                          <div className="schedulerMediumWidth padding-top-div-small">
                                            <span className="label-number">{storageDetail.quantity}</span>
                                          </div>
                                          <div className="schedulerWidth">
                                            <span>
                                              {storageDetail.fieldId === 'no-fields' && storageDetail.fieldName === null ?
                                                <div>
                                                  <span className="medium-scheduler-font-span">{intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_8' })}</span>
                                                </div> :
                                                <div>
                                                  <span className="medium-scheduler-font-span">{intl.formatMessage({ id: 'SELECTED_BALE_TITLE' })}</span>
                                                  <span className="medium-font-span-bold">{storageDetail.fieldName}</span>
                                                </div>
                                              }
                                              <div className="padding-top-div-extra-small">
                                                <span>
                                                  <span className="small-font-span">{intl.formatMessage({ id: 'YEAR' })}:</span>
                                                  <span className="small-font-span-bold">{storageDetail.year}</span>
                                                </span>
                                                {storageDetail.dryMatter &&
                                                <span>
                                                  <span className="small-font-span">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_2' })}:</span>
                                                  <span className="small-font-span-bold">{storageDetail.dryMatter}%</span>
                                                </span>}
                                                {storageDetail.rawProtein &&
                                                <span>
                                                  <span className="small-font-span">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_3' })}:</span>
                                                  <span className="small-font-span-bold">{storageDetail.rawProtein}g/kg TS</span>
                                                </span>}
                                              </div>
                                            </span>
                                          </div>
                                          <div className="smallWidth padding-top-div-extra-small">
                                            <div className="storage-arrow low-padding-top schedulerContainer">
                                              <span className="location-type-checkbox custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  id={`${storage.id}_${storageDetail.fieldId}`}
                                                  checked={selectedFieldCheckbox.length > 0 ? (selectedFieldCheckbox.length > 0 && _.filter(selectedFieldCheckbox, ['id',index+'_'+storage.id+'_'+storageDetail.fieldId+'_'+storageDetail.year])[0]).show : false}
                                                  onChange={() => {}} 
                                                  className="custom-checkbox"
                                                />
                                                <span className="checkmark"></span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>))}
                                    </ul>
                                  </div>
                                  <div className="number-padding-left paddingRight-less display-inline-block">
                                    {storage.id !== 'all-fields' ?
                                    <div>
                                      {intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_3' })} 
                                      {bales.data && bales.data.storages && _.filter(bales.data.storages, ['id', storage.id])[0].type === "EXTERNAL" && !_.filter(bales.data.storages, ['id', storage.id])[0].sortByField ?
                                      <span className="label-number-small">{intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_5' })}</span> :
                                      <span className="label-number-small">{intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_4' })}</span>}
                                      {intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_6' })} <span className="blue-color-link" onClick={() => this.editBaleStorage(storage.id)}>{intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_7' })}</span>
                                    </div> : ''}
                                  </div>
                                </div>
                              </li> : ''
                            ))}
                          </ul>
                        </div>}
                        <div className="padding-top-div">
                          <span className="paddingRight">
                            {schedulerBale.saveSchedulerError && !schedulerBale.baleDetailsSchedulerLoading && <ErrorText>{errorMessageDisplay}</ErrorText>}
                            {selectedFieldsError && <ErrorText>{intl.formatMessage({ id: 'SCHEDULER_THIRDSCREEN_TEXT_9' })}</ErrorText>}
                          </span>
                        </div>
                        <div className="bale-button-style">
                          {storageValues && enableCreateButton &&
                          <span className="paddingRight">
                            <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                              {intl.formatMessage({ id: 'ADD_ANALYSIS_BUTTON' })}
                            </ActionButton>
                          </span>}
                          <span className="bale-list-cancel-button" onClick={() => this.closeFirstScreen()}>
                            {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </form>
        </ModalDialog>
        <ModalDialog
            minWidth={{mobile: '90%', tablet: '70%', web: '70%'}}
            width={{mobile: '90%', tablet: '70%', web: '70%'}}
            height={{mobile: '90%', tablet: '65%', web: '80%'}}
            show={showEditBaleStorage}
            closeIcon={
              <span className="close-modal">
                <CrossIcon scale={0.5} />
              </span>
            }
            onClose={() => this.closeEditStoragePlan()}
          >
          <EditBaleStorageMapModal
            getBalesListData={bales.data}
            editStoragePlan={this.state.showEditBaleStorage}
            selectedStorageId={selectedStorageId}
          />
        </ModalDialog>
      </FlexGridItem>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  locations: state.baleFeedEvent.locationData,
  schedulerBaleList: state.schedulerBale.baleSchedulerData,
  schedulerBaleDetails: state.schedulerBale.baleDetailsSchedulerData,
  schedulerBale: state.schedulerBale,
  fieldList: state.fieldDetails.data,
  bales: state.bales,
  userDetails: state.userDetails.data,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getAllScheduledBales: (farmId) => dispatch(SchedulerBaleActions.getAllScheduledBales(farmId)),
  getScheduledBaleDetails: (farmId, year, harvest, storages) => dispatch(SchedulerBaleActions.getScheduledBaleDetails(farmId, year, harvest, storages)),
  saveScheduledBaleDetails: (farmId, year, data) => dispatch(SchedulerBaleActions.saveScheduledBaleDetails(farmId, year, data)),
  updateScheduledBaleDetails: (farmId, year, data, scheduleId) => dispatch(SchedulerBaleActions.saveScheduledBaleDetails(farmId, year, data, scheduleId)),
  saveBaleMarkerCoordinates: obj => dispatch(baleActions.saveBaleMarkerCoordinates(obj)),
  getScheduledBaleById: (farmId, scheduleId) => dispatch(SchedulerBaleActions.getScheduledBaleById(farmId, scheduleId)),
});

export default injectIntl(
  reduxForm({
    form: 'SchedulerColumnForm',
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SchedulerColumn)))
);
