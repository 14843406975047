import _ from 'lodash';
import { CREATE } from 'constants/index';
import {
  IS_FARM_LIST_FAILURE,
  IS_FARM_LIST_LOADING,
  GET_FARM_LIST,
  ADD_FARM_FAILURE,
  ADD_FARM_SUCCESS,
  UPDATE_FARM_SUCCESS,
  DELETE_FARM_SUCCESS,
  DELETE_FARM_FAILURE,
  CLEAR_FARM_MESSAGES
} from 'constants/store/Farms';
import { FARM_V2, FARM_LIST, FARM_V1 } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_ADD_FARM,
  GA_ADD_FARM_ERR,
  GA_UPDATE_FARM,
  GA_DELETE_FARM,
  GA_CAT_ERR,
  GA_CAT_ADD,
  GA_FARM_LBL,
  GA_CAT_UPDATE,
  GA_CAT_DETELE
} from 'constants/GoogleAnalytics';

/**
 *  Action get farm details
 * @param {bool} bool
 * @param {object} error
 * */
const farmListHasError = (bool, error) => ({
  type: IS_FARM_LIST_FAILURE,
  hasError: bool,
  errorMessage: error
});

/**
 * set loading status for farm list
 * @param {bool} bool
 */
const farmListIsLoading = bool => ({
  type: IS_FARM_LIST_LOADING,
  isLoading: bool
});

/**
 * action to set the farm list and active farm
 * @param {Array} data
 * @param {object} activeFarm
 */
export const farmListSuccess = (data, activeFarm) => ({
  type: GET_FARM_LIST,
  data,
  activeFarm
});

/**
 * action to add farm success
 * @param {object} data
 */
export const updateFarm = data => ({
  type: UPDATE_FARM_SUCCESS,
  data
});

/**
 * action to add farm success
 * @param {object} data
 */
export const addFarmError = error => ({
  type: ADD_FARM_FAILURE,
  error
});

/**
 * action to delete farm success
 * @param {string} id
 */
export const deleteFarmSuccess = id => ({
  type: DELETE_FARM_SUCCESS,
  id
});

/**
 * action to delete farm success
 * @param {object / string} error
 */
export const deleteFarmError = error => ({
  type: DELETE_FARM_FAILURE,
  error
});

/**
 * Clear farm action messages
 */
export const clearMessages = () => ({
  type: CLEAR_FARM_MESSAGES
});

/**
 * fetch Farm List dispatch farm list api details
 * @param {string} activeFarm
 */
export const fetchFarmList = (activeFarm, version) => async dispatch => {
  /*if(GetLocalData(LOCAL_FARM_LIST) !== null) {
    if (activeFarm && GetLocalData(LOCAL_FARM_LIST).length > 0) {
      const selecteItem = _.filter(GetLocalData(LOCAL_FARM_LIST), ['farm.id', activeFarm]);
      dispatch(farmListSuccess(GetLocalData(LOCAL_FARM_LIST), selecteItem.length > 0 ? selecteItem[0] : GetLocalData(LOCAL_FARM_LIST)[0]));
    } else {
      dispatch(farmListSuccess(GetLocalData(LOCAL_FARM_LIST), GetLocalData(LOCAL_FARM_LIST).length > 0 ? GetLocalData(LOCAL_FARM_LIST)[0] : null));
    }
    return;
  }*/
  dispatch(farmListIsLoading(true));
  let url_link = FARM_LIST;
  if(version && version === 'v2') {
    url_link = FARM_V2;
  }
  await dispatch(
    apiRequest({
      url: url_link,
      method: 'get'
    })
  )
    .then(response => {
      let keyPair = {};
      let farmListData = [];
      if(version && version === 'v2') {
        response.data.map(data => (
          //eslint-disable-next-line
          keyPair = {},
          keyPair["farm"] = data,
          keyPair["superAdmin"] = true,
          farmListData.push(keyPair)
        ));
      }
      let farmList = response.data;
      if(version && version === 'v2') {
        farmList = farmListData;
      }
      if (farmList.length > 1) {
        farmList = _.orderBy(farmList, [data => data.farm.name.toLowerCase()], ['asc']);
      }
      if (activeFarm && farmList.length > 0) {
        const selecteItem = _.filter(farmList, ['farm.id', activeFarm]);
        //SetLocalData(LOCAL_FARM_LIST, farmList);
        dispatch(farmListSuccess(farmList, selecteItem.length > 0 ? selecteItem[0] : farmList[0]));
      } else {
        //SetLocalData(LOCAL_FARM_LIST, null);
        dispatch(farmListSuccess(farmList, farmList.length > 0 ? farmList[0] : null));
      }
    })
    .catch(error => {
      //SetLocalData(LOCAL_FARM_LIST, null);
      dispatch(farmListHasError(true, error));
    });
};

/**
 * Add or update farm details
 * @param {object} data
 * @param {string} method
 */
export const addNewFarm = (data, method, hasSupportAdminRole) => async dispatch => {
  dispatch(farmListIsLoading(true));
  await dispatch(
    apiRequest({
      url: method === CREATE ? FARM_V2 : `${FARM_V2}/${data.id}`,
      method: method === CREATE ? 'POST' : 'PUT',
      data
    })
  )
    .then(response => {
      if (method === CREATE) {
        //SetLocalData(LOCAL_FARM_LIST, null);
        dispatch(fetchFarmList(response.data?.id, hasSupportAdminRole ? 'v2' : 'v1'));
        GAEvent(GA_ADD_FARM, GA_CAT_ADD, GA_FARM_LBL);
        dispatch({ type: ADD_FARM_SUCCESS });
      } else {
        const farmResult = response.data;
        if (response.data?.lattitude && response.data?.longitude) {
          farmResult.location = {
            lattitude: farmResult.lattitude,
            longitude: farmResult.longitude
          };
        }
        //SetLocalData(LOCAL_FARM_LIST, null);
        GAEvent(GA_UPDATE_FARM, GA_CAT_UPDATE, GA_FARM_LBL);
        dispatch(fetchFarmList(response.data?.id, hasSupportAdminRole ? 'v2' : 'v1'));
        dispatch(updateFarm(farmResult));
      }
    })
    .catch(error => {
      if (method === CREATE) {
        GAEvent(GA_ADD_FARM_ERR, GA_CAT_ERR, GA_FARM_LBL);
      }
      dispatch(addFarmError(error));
    });
};

/**
 * delete farm request
 * @param {string} id
 */
export const deleteFarm = id => async dispatch => {
  dispatch(farmListIsLoading(true));
  await dispatch(
    apiRequest({
      url: `${FARM_V1}/${id}`,
      method: 'DELETE'
    })
  )
    .then(response => {
      //SetLocalData(LOCAL_FARM_LIST, null);
      dispatch(deleteFarmSuccess(id));
      GAEvent(GA_DELETE_FARM, GA_CAT_DETELE, GA_FARM_LBL);
    })
    .catch(error => {
      dispatch(deleteFarmError(error));
    });
};
