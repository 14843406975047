// Manage Users
export const ADD_USER = 'ADD_USER';
export const IS_ADD_USER_LOADING = 'IS_ADD_USER_LOADING';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const IS_GET_ROLES_LOADING = 'IS_GET_ROLES_LOADING';
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';
export const IS_USERS_LOADING = 'IS_USERS_LOADING';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const IS_UPDATE_USER_LOADING = 'IS_UPDATE_USER_LOADING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const NULLFIY_USER_MESSAGES = 'NULLFIY_USER_MESSAGES';
