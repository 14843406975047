import React from 'react';
import { FormattedMessage } from 'react-intl';

import Weather from 'containers/tasks/tasklist/TaskWeather';
import Calendar from 'containers/tasks/tasklist/TaskDurationSetter';
import SliderComponent from 'containers/tasks/tasklist/TaskSlider';
import { ACT19 } from 'constants/index';

const TimeRecorded = props => {
  const { chosenActId, taskDetails } = props;
  const { taskInfo } = taskDetails;
  return (
    <div className="create-task-time-recorded">
      <div className="create-task-time-recorded-item calendar ">
        <div className="time-recorded-heading">
          <span className="black-dot" />
          <span>
            <FormattedMessage id="TASKS_TR" />
          </span>
        </div>
        <div className="calendar-wrapper">
          <Calendar {...props} />
          {taskInfo && taskInfo.sDate && taskInfo.eDate && taskInfo.sDate > taskInfo.eDate && (
            <div className="error-text">
              <FormattedMessage id="invalid" />
            </div>
          )}
        </div>
      </div>
      <div className="vertical-line" />
      <div className="create-task-time-recorded-item weather">
        <div className="weather-container">
          <Weather {...props} />
        </div>
      </div>
      {chosenActId === ACT19 && (
        <>
          <div className="vertical-line" />
          <div className="create-task-time-recorded-item wind">
            <div className="wind-container">
              <SliderComponent heading="WIND" name="TRW" {...props} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TimeRecorded;
