import { FIELD_METHOD } from 'constants/ApiUrl';
import { 
  FIELD_METHOD_LOADING, 
  FIELD_METHOD_SUCCESS, 
  FIELD_METHOD_ERROR
} from 'constants/store/Fields';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Get all the field methods
 */
export const fieldMethodList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FIELD_METHOD_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FIELD_METHOD}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: FIELD_METHOD_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FIELD_METHOD_ERROR, payload: error });
      });
    } catch (error) {
      dispatch({ type: FIELD_METHOD_ERROR, payload: error });
    }
};