import React from 'react';
import _ from 'lodash';
import { AmountValidation } from 'utils/index';
import { FormattedMessage } from 'react-intl';

export default values => {
  const errors = {};
  const pestAmountValidationArr = [];
  if (!values.field || values.field?.length < 1) {
    errors.field = <FormattedMessage id="CT_VAL_MSG_1" />;
  }
  if (!values.activity || !values.activity.label) {
    errors.activity = <FormattedMessage id="CT_VAL_MSG_2" />;
  }
  Object.keys(values).forEach(item => {
    if (item.startsWith('pesticideamount_')) {
      pestAmountValidationArr.push({ [item]: values[item] });
    }
    return null;
  });
  _.map(pestAmountValidationArr, item => {
    if (!AmountValidation(Object.values(item)[0])) {
      errors[Object.keys(item)[0]] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
  });
  if (
    values.cutamount ||
    values.wateramount ||
    values.sowingamount ||
    values.ensilationamount ||
    values.waterpercentage ||
    values.totalharvest ||
    values.fertilizeramount ||
    values.fertilizerplanamount ||
    values.drymatter ||
    values.weight ||
    values.distanceToWaterBody ||
    values.distanceToWaterWell || 
    values.driftReduction ||
    values.maxDrivingSpeed ||
    values.pressure ||
    values.nozzleHeight ||
    values.distanceToWaterBody2 ||
    values.distanceToWaterWell2 ||
    values.cultureBySpray ||
    values.fieldAge ||
    values.driftReduction2 ||
    values.maxDrivingSpeed2 ||
    values.pressure2 ||
    values.amount ||
    values.ptoSpeed
  ) {
    if (values.cutamount && !AmountValidation(values.cutamount)) {
      errors.cutamount = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.wateramount && !AmountValidation(values.wateramount)) {
      errors.wateramount = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.sowingamount && !AmountValidation(values.sowingamount)) {
      errors.sowingamount = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.ensilationamount && !AmountValidation(values.ensilationamount)) {
      errors.ensilationamount = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.waterpercentage && !AmountValidation(values.waterpercentage)) {
      errors.waterpercentage = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.totalharvest && !AmountValidation(values.totalharvest)) {
      errors.totalharvest = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.fertilizeramount && !AmountValidation(values.fertilizeramount)) {
      errors.fertilizeramount = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.fertilizerplanamount && !AmountValidation(values.fertilizerplanamount)) {
      errors.fertilizerplanamount = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.distanceToWaterBody && !AmountValidation(values.distanceToWaterBody)) {
      errors.distanceToWaterBody = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.distanceToWaterWell && !AmountValidation(values.distanceToWaterWell)) {
      errors.distanceToWaterWell = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if(values.driftReduction && !AmountValidation(values.driftReduction)) {
      errors.driftReduction = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.maxDrivingSpeed && !AmountValidation(values.maxDrivingSpeed)) {
      errors.maxDrivingSpeed = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if(values.pressure && !AmountValidation(values.pressure)) {
      errors.pressure = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if(values.nozzleHeight && !AmountValidation(values.nozzleHeight)) {
      errors.nozzleHeight = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }

    if (values.distanceToWaterBody2 && !AmountValidation(values.distanceToWaterBody2)) {
      errors.distanceToWaterBody2 = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.distanceToWaterWell2 && !AmountValidation(values.distanceToWaterWell2)) {
      errors.distanceToWaterWell2 = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.cultureBySpray && !AmountValidation(values.cultureBySpray)) {
      errors.cultureBySpray = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if(values.fieldAge && !AmountValidation(values.fieldAge)) {
      errors.fieldAge = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if(values.driftReduction2 && !AmountValidation(values.driftReduction2)) {
      errors.driftReduction2 = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.maxDrivingSpeed2 && !AmountValidation(values.maxDrivingSpeed2)) {
      errors.maxDrivingSpeed2 = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if(values.pressure2 && !AmountValidation(values.pressure2)) {
      errors.pressure2 = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.amount && !AmountValidation(values.amount)) {
      errors.amount = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if(values.ptoSpeed && !AmountValidation(values.ptoSpeed)) {
      errors.ptoSpeed = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
    }
    if (values.drymatter) {
      if(!AmountValidation(values.drymatter)) {
        errors.drymatter = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
      }
      if(values.drymatter < 1 || values.drymatter > 100) {
        errors.drymatter = <FormattedMessage id="DRY_MATTER_ERROR_MSG" />;
      }
    }
    if (values.weight) {
      if(!AmountValidation(values.weight)) {
        errors.weight = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" />;
      }
      if(values.weight < 1) {
        errors.weight = <FormattedMessage id="WEIGHT_ERROR_MSG" />;
      }
    }
  }
  return errors;
};
