import _ from 'lodash';
import {
  GET_TASK_LIST,
  IS_GET_TASK_LIST_FAILURE,
  IS_GET_TASK_LIST_LOADING,
  UPDATE_TASK_SUCCESS,
  TASK_UPDATE_FAILURE,
  DELETE_TASK_FAILURE,
  TASK_SORT_BY_OPTION,
  DELETE_TASK_SUCCESS,
  IS_DELETE_TASK_LOADING,
  TASK_LIST_POSITION,
  TASK_LIST_DATA_RESET,
  TASK_LIST_DATA_CLEAR
} from 'constants/index';
import { TaskSortingOptions } from 'constants/DropdownAndTranslationOptions';
import {
  GET_TASK_GPS,
  GET_TASK_GPS_ERROR,
  GET_TASK_GPS_LOADING,
  TASK_PERIOD_VALUE,
  TASK_COMPLETED_LOADING,
  TASK_COMPLETED_SUCCESS,
  TASK_COMPLETED_FAILURE
} from 'constants/store/Tasks';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  updateError: false,
  deleteError: false,
  isDeleteLoading: false,
  deleteSuccess: false,
  sortBy: { value: TaskSortingOptions[0].value, label: TaskSortingOptions[0].transLbl },
  taskPeriod: null,
  taskListPositionInfo: {},
  gpsData: {
    tasksGps: [],
    isGpsDataLoading: false,
    hasGpsDataError: false
  },
  isCompletedFailure: false,
  isCompletedSuccess: false
};

export default function taskListReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case IS_GET_TASK_LIST_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
        errorMessage: null,
        deleteInfo: '',
        deleteError: false,
        isDeleteLoading: false,
        updateError: false,
        deleteSuccess: false,
        gpsData: {
          tasksGps: [],
          isGpsDataLoading: false,
          hasGpsDataError: false
        }
      };
    case IS_GET_TASK_LIST_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case GET_TASK_LIST:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data:
          action.data.offset === '0'
            ? action.data.taskBeans
            : _.concat(state.data, action.data.taskBeans),
        paginationDetails: {
          sortFieldMap: action.data.sortFieldMap,
          offset: action.data.offset,
          pageSize: action.data.pageSize,
          fields: action.data.fields,
          statuses: action.data.statuses,
          activities: action.data.activities,
          totalTaskCount: action.data.taskCount,
          listTaskCount: action.data.totalTaskCount
        }
      };
    case UPDATE_TASK_SUCCESS:
      // Removing the existing / updated items to concat the payload
      _.forEach(action.data, item => {
        _.remove(state.data, { id: item.id });
      });
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        deleteError: false,
        deleteSuccess: false,
        updateError: false,
        data: _.concat(state.data, action.data)
      };
    case TASK_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        deleteError: false,
        updateError: true,
        data: state.data
      };
    case DELETE_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        isDeleteLoading: false,
        deleteError: true,
        updateError: false,
        deleteInfo: action.data
      };
    case DELETE_TASK_SUCCESS:
      // to remove deleted task in the list
      _.forEach(action.deletedTasks, item => {
        _.remove(state.data, { id: item });
      });
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        isDeleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
        updateError: false,
        deleteInfo: action.data,
        /*data: state.data,
        paginationDetails: {
          sortFieldMap: {
            ...state.paginationDetails.sortFieldMap,
            totalCount: state.paginationDetails.sortFieldMap.totalCount - action.deletedTasks.length
          },
          offset: state.paginationDetails.offset - 1,
          pageSize: state.paginationDetails.pageSize,
          fields: state.data.fields,
          statuses: state.data.statuses,
          activities: state.data.activities,
          totalTaskCount: state.data.taskCount
        }*/
      };
    case IS_DELETE_TASK_LOADING:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        isDeleteLoading: true,
        deleteError: false,
        updateError: false,
        deleteInfo: null,
        deleteSuccess: false
      };
    case TASK_SORT_BY_OPTION:
      return {
        ...state,
        sortBy: action.option
      };
    case TASK_PERIOD_VALUE:
      return {
        ...state,
        taskPeriod: action.taskPeriod
      };
    case TASK_LIST_POSITION: {
      return {
        ...state,
        taskListPositionInfo: {
          fromTask: action.option.fromTask
        }
      };
    }
    case TASK_LIST_DATA_RESET: {
      return {
        ...state,
        deleteSuccess: action.isDeleted
      };
    }
    case TASK_LIST_DATA_CLEAR: {
      return {
        ...state,
        data: action.data
      };
    }
    case GET_TASK_GPS:
      return {
        ...state,
        gpsData: {
          tasksGps: action.payload,
          isGpsDataLoading: false,
          hasGpsDataError: false
        }
      };
    case GET_TASK_GPS_ERROR:
      return {
        ...state,
        gpsData: {
          tasksGps: [],
          isGpsDataLoading: false,
          hasGpsDataError: true
        }
      };
    case GET_TASK_GPS_LOADING:
      return {
        ...state,
        gpsData: {
          tasksGps: [],
          isGpsDataLoading: action.payload,
          hasGpsDataError: false
        }
      };
    case TASK_COMPLETED_LOADING:
      return {
        ...state,
        isLoading: true,
        isCompletedFailure: false,
        isCompletedSuccess: false
      };
    case TASK_COMPLETED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCompletedFailure: false,
        isCompletedSuccess: true
      };
    case TASK_COMPLETED_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCompletedFailure: true,
        isCompletedSuccess: false
      };
    default:
      return state;
  }
}
