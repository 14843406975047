import React from 'react';
import { TextField } from '@mui/material'

const MuiTextBox = ({
  input,
  name,
  label,
  variant,
  id,
  value,
  onChange,
  showFullWidth,
  disabled,
  controlledValue,
  helperText,
  error,
  defaultValue,
  required
}) => {
  const inputProps = {};
  if (controlledValue) {
    inputProps.value = controlledValue;
  }
  if(showFullWidth === "true") {
    return (
      <TextField 
        {...input}
        name={name}
        id={id}
        label={label}
        variant={variant}
        value={value}
        onChange={onChange}
        disabled={disabled}
        helperText={helperText}
        defaultValue={defaultValue}
        error={error}
        required={required ? required : false}
        sx={{
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#00696D",
              color: "#ffffff"
            },
          },
          '& label.Mui-focused': {
            color: '#00696D',
            fontFamily: 'Inter'
          }
        }}
        {...inputProps}
      />
    );
  } else {
    return (
      <TextField 
        {...input}
        name={name}
        id={id}
        label={label}
        variant={variant}
        value={value}
        onChange={onChange}
        disabled={disabled}
        helperText={helperText}
        defaultValue={defaultValue}
        error={error}
        required={required ? required : false}
        sx={{
          "& .MuiOutlinedInput-root": {
            width: '50%',
            "&.Mui-focused fieldset": {
              borderColor: "#00696D",
              color: "orange"
            },
          },
          '& label.Mui-focused': {
            color: '#00696D',
          },
        }}
        {...inputProps}
      />
    );
  }
};
export default MuiTextBox;
