import * as React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import UserAgreement from 'components/UserAgreement';
import { checkPermissions } from 'utils/index';
import { UPDATE_TASK, PLANNED } from 'constants/index';

class TaskSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pesticideAgreed: true,
      completed: false
    };
  }

  onChangeCreateComplete = () => {
    this.setState(prevState => ({
      completed: !prevState.completed
    }));
  };

  render() {
    const {
      props: { updateAgreementError, taskDetails, setTaskComplete, intl, editTaskDetails, taskId, rolesDetails, activeFarm, chosenActId },
      state: { completed, pesticideAgreed }
    } = this;
    const { isLoading, isSuccess, isFailure, errorMessage } = taskDetails;
    const disableTaskButton =
      (taskId !== 'create' && !checkPermissions(UPDATE_TASK, activeFarm, rolesDetails)) ||
      isLoading ||
      !pesticideAgreed;
    return (
      <div className="full-width task-sumbit">
        {chosenActId === 'act19' ? (<div className="task-pesticide-check-agreement">
          <UserAgreement
            display={true}
            updateAgreementError={updateAgreementError}
          />
        </div>) : null}
        {isSuccess ? (
          <p className="full-width task-message success">
            <FormattedMessage
              id={`${taskId === 'create' ? 'TASK_CREATED_SUCCESS' : 'TASK_UPDATED_SUCCESS'}`}
            />
          </p>
        ) : null}
        {isFailure ? (
          <p className="full-width task-message error">
            {errorMessage.data === null ? (
              <FormattedMessage id="COMMON_ERR_MSG" />
            ) : errorMessage.data
            }
          </p>
        ) : null}
        {taskId === 'create' && (
          <div className="full-width complete-check">
            <CheckboxButton
              id="task-completed"
              onChange={() => {
                this.setState(prevState => ({
                  completed: !prevState.completed
                }));
              }}
              checked={completed}
              labelText={intl.formatMessage({ id: 'SET_TASK_COMPLETE' })}
              labelTextHidden={false}
              disabled={false}
            />
          </div>
        )}

        <div className="full-width task-save-complete">
          {taskId === 'create' && (
            <ActionButton
              disabled={disableTaskButton}
              onClick={() => (completed ? setTaskComplete(completed) : {})}
            >
              {intl.formatMessage({
                id: completed ? 'CT_CCT' : 'CT_CT'
              })}
            </ActionButton>
          )}
          {taskId !== 'create' && (
            <>
              {(editTaskDetails.isGetTaskData) && (editTaskDetails.isGetTaskData.status === PLANNED) && (
                <ActionButton disabled={disableTaskButton} onClick={() => setTaskComplete(true)}>
                  {intl.formatMessage({ id: 'SAVE_COMPLETE_TASK' })}
                </ActionButton>
              )}
              <FormattedMessage id="SAVE_CHANGES">
                {text => (
                  <ActionButton disabled={disableTaskButton} multi onClick={() => {}}>
                    {text}
                  </ActionButton>
                )}
              </FormattedMessage>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  taskDetails: state.taskDetails,
  formValues: getFormValues('CreateTaskForm')(state),
  editTaskObj: state.taskDetails.editTaskObj,
  rolesDetails: state.RolesDetails
});

export default injectIntl(connect(mapStateToProps)(TaskSubmit));
