import { NAV_MENU_TOGGLE } from 'constants/index';

const initialState = {
  isOpen: false
};

export default function loginReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case NAV_MENU_TOGGLE:
      return {
        ...state,
        isOpen: action.isOpen
      };
    default:
      return state;
  }
}
