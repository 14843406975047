import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import { FormattedMessage } from 'react-intl';
import { termsCheck, getTermsCondition, acceptTermsCondition } from 'actions/TermsAndCondition';
import { doLogOut } from 'actions/Login';
import { GetLanguageCode } from 'utils/index';
import Loader from 'components/Loader';

export default ({showTermsAndCondition, closeTermsAndCondition}) => {
  const [showTerms, showHideTerms] = useState(false);
  // app state
  const { loginDetails, termsDetails } = useSelector(
    state => ({
      loginDetails: state.userDetails,
      termsDetails: state.termsDetails
    }),
    shallowEqual
  );

  // dispatch action
  const dispatch = useDispatch();

  useEffect(() => {
    if (termsDetails && !termsDetails.isLoading && !termsDetails.hasError) {
      if (termsDetails.termCheck === null) {
        dispatch(termsCheck(loginDetails.data.idToken));
      }
      if (termsDetails.termCheck !== null && !termsDetails.data) {
        dispatch(
          getTermsCondition(
            loginDetails.data.idToken,
            GetLanguageCode(loginDetails.data.preferredLanguage)
          )
        );
      }
      if (termsDetails.data) {
        if (termsDetails.termCheck) {
          showHideTerms(false);
        } else {
          showHideTerms(true);
        }
      }
    }
  }, [dispatch, loginDetails, termsDetails]);

  if (loginDetails.data && termsDetails.data) {
    return (
      <div className="toc_wrapper">
        <ModalDialog show={showTerms || showTermsAndCondition} onClose={() => {}}>
          <div className="tc-content">
            <div dangerouslySetInnerHTML={{ __html: termsDetails.data.description }} />
            <div className="btn-wapper">
              {termsDetails.data.isLoading ? <Loader styleName="tc-loader" /> : null}
              {showTermsAndCondition &&
              <FormattedMessage id="OK">
                {text => (
                  <ActionButton
                    multi
                    onClick={() => closeTermsAndCondition()}
                  >
                    {text}
                  </ActionButton>
                )}
              </FormattedMessage>}
              {!showTermsAndCondition &&
              <FormattedMessage id="NO_THANK">
                {text => (
                  <ActionButton
                    multi
                    disabled={termsDetails.data.isLoading}
                    onClick={() => dispatch(doLogOut(loginDetails.data.accessToken))}
                  >
                    {text}
                  </ActionButton>
                )}
              </FormattedMessage>}
              {!showTermsAndCondition &&
              <FormattedMessage id="YES_AGREE">
                {text => (
                  <ActionButton
                    multi
                    disabled={termsDetails.data.isLoading}
                    onClick={() => {
                      dispatch(
                        acceptTermsCondition(
                          loginDetails.data.idToken,
                          GetLanguageCode(loginDetails.data.preferredLanguage),
                          termsDetails.data.versionId
                        )
                      )
                    }}
                  >
                    {text}
                  </ActionButton>
                )}
              </FormattedMessage>}
            </div>
          </div>
        </ModalDialog>
      </div>
    );
  }
  return <> </>;
};
