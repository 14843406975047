import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Stack, Box, Typography, Button, Dialog, List, ListItemButton, Collapse, DialogActions, Chip } from '@mui/material'
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiTextArea from 'components/mui-forms/MuiTextArea';
import mixpanel from 'mixpanel-browser';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import { format } from 'date-fns';
import { ADD_FIELD, EDIT_FIELD, FIELD_CREATED, FIELD_UPDATED } from 'constants/MixPanel';
import { ADMIN, OWNER, ADVISOR, NEW } from 'constants/index';
import * as fieldActions from 'actions/Fields';
import * as Seasons from 'actions/Seasons';
import * as FieldMethod from 'actions/FieldMethod';
import * as FieldMasterData from 'actions/FieldMasterData';
import fetchManureNames from 'actions/ManureNames';
import * as YieldPlan from 'actions/YieldPlan';
import * as HistoricalData from 'actions/HistoricalData';
import * as ManureAction from 'actions/ManureStorage';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import Loader from 'components/Loader';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import CropComponent from 'mui-components/fields/fertilization/InsufficientFieldStorage/CropComponent';
import YieldGoalComponent from 'mui-components/fields/fertilization/InsufficientFieldStorage/YieldGoalComponent';
import CutUnitComponent from 'mui-components/fields/fertilization/InsufficientFieldStorage/CutUnitComponent';
import StorageComponent from 'mui-components/fields/fertilization/InsufficientFieldStorage/StorageComponent';
import {
  getYears
} from 'utils/index';
 
class Manure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open1: true,
      open2: true,
      open3: true,
      open4: true,
      open5: true,
      fieldCrop: 0,
      previousCrop: '',
      seedingYear: '',
      clover: '',
      notes: '',
      showEditFieldSizePrompt: false,
      sizeChangeWarningAcepted: false,
      isShowFullScreen: false,
      manureApplied: false,
      springAdd: false,
      summerAdd: false,
      fallAdd: false,
      manureDataUpdated: false,
      showSoilSample: false,
      yieldGoalValue: "",
      harvestValue: "",
      showHarvest: false,
      defaultYieldValue: null,
      changedYieldValue: null,
      yieldSumValue: 0,
      yieldPercentValue: 0,
      showYieldPopup: false,
      showHarvestPopup: false,
      showYieldGoalPopup: false,
      showYieldGoalNullPopup: false,
      showHarvestNullPopup: false,
      acceptToSave: false,
      notAcceptToSave: false,
      yieldCalculation: true,
      acceptToSaveNull: false,
      manureError: false,
      manureAppliedError: false,
      fieldCalculationOnUpdate: false,
      cut1Date: '',
      cut2Date: '',
      cut3Date: '',
      cut4Date: '',
      setHistoricalData: false,
      fieldOptionApplied: false,
      saveButtonClick: false,
      selectedFieldId: null,
      showPrevNextPopup: false,
      disableGrain: false,
      stravRemovedApplied: false,
      initializeFormValue : false,
      manureMethod: '',
      manureStorage: '',
      spring_manureMethod: '',
      spring_manureStorage: '',
      spring_manureAmount: '',
      summer_manureMethod: '',
      summer_manureStorage: '',
      summer_manureAmount: '',
      fall_manureMethod: '',
      fall_manureStorage: '',
      fall_manureAmount: '',
      dialogOpen: false,
      dialog_manureName: '',
      dialog_manureType: '',
      dialog_storageCapacity: '',
      dialog_yearlyProduction: '',
      dialog_dryMatter: '',
      previousIndex: null,
      nextIndex: null,
      currentFieldValue: '',
      cut1show: false,
      cut2show: false,
      cut3show: false,
      cut4show: false
    };
  }
 
  componentDidMount = () => {
    const { activeFarm, getManureNames, userDetails, selectedField, seasonsList, fieldMethodList, getFieldCrops,
      fetchManureType, getPreviousFieldCrops, manureStorage } = this.props;
    getManureNames(activeFarm.farm.id);
    seasonsList();
    fieldMethodList();
    getFieldCrops();
    getPreviousFieldCrops();
    if(selectedField.fieldId === NEW) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(ADD_FIELD);
    } else {
      mixpanel.identify(userDetails.id);
      mixpanel.track(EDIT_FIELD);
    }
    if(selectedField.fieldId !== NEW) {
      this.setYieldValues();
    }
    const { manureType } = manureStorage;
    if (!manureType?.length > 0) {
      fetchManureType();
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedField, manureNames, yieldPlan, historicalData, seasons, fieldMethod, fieldMasterData, fieldDetails } = this.props;
    const { showHarvest, setHistoricalData, initializeFormValue } = this.state;
    if (prevProps.selectedField !== selectedField) {
      if(selectedField && seasons.seasonsList !== null && fieldMethod.fieldMethodList && fieldMasterData 
      && fieldMasterData.cropList !== null && fieldMasterData.previousCropList !== null && fieldDetails.data !== undefined) {
        this.initializeValues();
        this.setYieldValues();
      }
    }
    if(!initializeFormValue) {
      if(selectedField && seasons.seasonsList !== null && fieldMethod.fieldMethodList && fieldMasterData 
      && fieldMasterData.cropList !== null && fieldMasterData.previousCropList !== null && fieldDetails.data !== undefined) {
        this.initializeValues();
        this.setYieldValues();
      }
    }
    if (
      !this.state.manureDataUpdated && manureNames &&
      manureNames.data !== undefined &&
      manureNames.data.length > 0 && fieldMasterData 
      && fieldMasterData.cropList !== null && fieldMasterData.previousCropList !== null && fieldDetails.data !== undefined
    ) {
      this.initializeValues();
      this.setState({ manureDataUpdated: true });
    }
    if(!showHarvest && yieldPlan && yieldPlan.yieldCalculation !== null) {
      let sumValue = 0;
      let percentValue = 0;
      let defaultVal = [];
      let arrayValue = [];
      let defaultYieldValue = [];
      let keyPair1 = {};
      let keyPair2 = {};
      arrayValue.push(yieldPlan.yieldCalculation);
      if(yieldPlan.yieldCalculation.plannedYields?.length > 0) {
        yieldPlan.yieldCalculation.plannedYields.map((plannedYield) => (
          //eslint-disable-next-line
          sumValue = sumValue + plannedYield.yield,
          percentValue = percentValue + plannedYield.yieldPercent
        ))
        arrayValue.map((type) => (
          //eslint-disable-next-line
          keyPair1 = {},
          keyPair1["defaultHarvests"] = type.harvests,
          keyPair1["defaultPlannedYield"] = type.plannedYield,
          keyPair1["defaultTotalYield"] = type.totalYield,
          keyPair1["defaultTotalYieldPercent"] = type.totalYieldPercent,
          keyPair1["defaultYieldGoal"] = type.yieldGoal,
          keyPair1["defaultYieldResponse"] = type.yieldResponse,
          keyPair1 ? defaultYieldValue.push(keyPair1) : ''
        ))
        yieldPlan.yieldCalculation.plannedYields.map(yieldRes => (
          //eslint-disable-next-line
          keyPair2 = {},
          keyPair2["defaultYield"] = yieldRes.yield,
          keyPair2["defaultYieldPercent"] = yieldRes.yieldPercent,
          keyPair2 ? defaultVal.push(keyPair2): ''
        ));
      }
      if(defaultVal) {
        defaultYieldValue.push(defaultVal);
      }
      this.setState({ 
        showHarvest: true,
        changedYieldValue: yieldPlan.yieldCalculation,
        defaultYieldValue: defaultYieldValue,
        yieldSumValue: this.roundToTwoDecimal(sumValue),
        yieldPercentValue: this.roundToTwoDecimal(percentValue)
      });
    }
    if(historicalData && historicalData.historicalDataValue && !setHistoricalData) {
      if(historicalData.historicalDataValue.length > 0) {
        let cut1DateValue = null;
        let cut2DateValue = null;
        let cut3DateValue = null;
        let cut4DateValue = null;
        if(historicalData.historicalDataValue[0] && historicalData.historicalDataValue[0] !== null) {
          let cutDateArray = historicalData.historicalDataValue[0].split('-');
          cut1DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
        }
        if(historicalData.historicalDataValue[1] && historicalData.historicalDataValue[1] !== null) {
          let cutDateArray = historicalData.historicalDataValue[1].split('-');
          cut2DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
        }
        if(historicalData.historicalDataValue[2] && historicalData.historicalDataValue[2] !== null) {
          let cutDateArray = historicalData.historicalDataValue[2].split('-');
          cut3DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
        }
        if(historicalData.historicalDataValue[3] && historicalData.historicalDataValue[3] !== null) {
          let cutDateArray = historicalData.historicalDataValue[3].split('-');
          cut4DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
        }
        this.setState({
          cut1Date: historicalData.historicalDataValue[0] && historicalData.historicalDataValue[0] !== null ? new Date(cut1DateValue) : null,
          cut2Date: historicalData.historicalDataValue[1] && historicalData.historicalDataValue[1] !== null ? new Date(cut2DateValue) : null,
          cut3Date: historicalData.historicalDataValue[2] && historicalData.historicalDataValue[2] !== null ? new Date(cut3DateValue) : null,
          cut4Date: historicalData.historicalDataValue[3] && historicalData.historicalDataValue[3] !== null ? new Date(cut4DateValue) : null,
          setHistoricalData: true
        });
      } else {
        this.setState({
          cut1Date: null,
          cut2Date: null,
          cut3Date: null,
          cut4Date: null,
          setHistoricalData: true
        });
      }
    }
  }

  setStateOfParent = (changeValue) => {
    const { onCloseOpenInsufficientItemsLink } = this.props;
    onCloseOpenInsufficientItemsLink();
    if(changeValue !== null) {
      this.setState({currentFieldValue: {...changeValue}});
    } else {
      this.setState({selectedField: ''});
    }
  }

  onFieldOptionChange = e => {
    const { change } = this.props;
    this.setState({
      fieldOptionApplied: e
    });
    change('fieldOptionApplied', e);
  };

  onStravRemovedChange = e => {
    const { change } = this.props;
    this.setState({
      stravRemovedApplied: e
    });
    change('stravRemovedApplied', e);
  };

  onCropChange = e => {
    const { fieldMasterData, selectedField } = this.props;
    this.setState({
      manureMethod: '',
      manureStorage: ''
    })
    this.setState({
      fieldCrop: e.target.value
    });
    let [fieldPreviousCropName] = "";
    let masterData = fieldMasterData.cropList;
    if(e.target.value === 5 || e.target.value === 14 || e.target.value === 17 || e.target.value === 18 || e.target.value === 19 || e.target.value === 23) {
      masterData = fieldMasterData.previousCropList;
      this.setState({
        disableGrain: true
      });
      [fieldPreviousCropName] = (selectedField.grainPreviousCropId !== undefined && selectedField.grainPreviousCropId !== null) ? 
      (masterData ? _.filter(masterData, ['id', selectedField.grainPreviousCropId]) : "") : "";
    } else {
      masterData = fieldMasterData.cropList;
      this.setState({
        disableGrain: false
      });
      [fieldPreviousCropName] = (selectedField.previousCropId !== undefined && selectedField.previousCropId !== null) ? 
      (masterData ? _.filter(masterData, ['id', selectedField.previousCropId]) : "") : "";
    }
    if(fieldPreviousCropName === undefined) {
      this.setState({
        previousCrop: ""
      });
    } else {
      this.setState({
        previousCrop: fieldPreviousCropName.id
      });
    }
    this.setState({ showHarvest: false });
    const { yieldCalculation, yieldPlan } = this.props;
    var data = {
      "yieldGoal": this.state.yieldGoalValue,
      "harvests": this.state.harvestValue
    }
    yieldPlan.yieldCalculation = null;
    yieldCalculation(data);
  }

  cut1DateChange = (date) => {
    this.setState({ cut1Date: date !== undefined ? date : null });
  };

  cut2DateChange = (date) => {
    this.setState({ cut2Date: date });
  }

  cut3DateChange = (date) => {
    this.setState({ cut3Date: date });
  }

  cut4DateChange = (date) => {
    this.setState({ cut4Date: date });
  }

  roundToTwoDecimal = num => {    
    return Number(Math.round(num+'e2')+'e-2');
  }

  setYieldValues = () => {
    const { selectedField } = this.props;
    let harvestVal = 0;
    let yieldGoalVal = 0;
    if(selectedField.harvests !== null && selectedField.harvests !== '' && selectedField.harvests !== 0) {
      harvestVal = selectedField.harvests;
    }
    if(selectedField.yieldGoal !== null && selectedField.yieldGoal !== '' && selectedField.yieldGoal !== 0) {
      yieldGoalVal = selectedField.yieldGoal;
    }
    if(harvestVal > 0 && yieldGoalVal > 0) {
      let sumValue = 0;
      let percentValue = 0;
      let defaultVal = [];
      let arrayValue = [];
      let defaultYieldValue = [];
      let keyPair1 = {};
      let keyPair2 = {};
      arrayValue.push(selectedField);
      if(selectedField.plannedYields?.length > 0) {
        selectedField.plannedYields.map((plannedYield) => (
          //eslint-disable-next-line
          sumValue = sumValue + plannedYield.yield,
          percentValue = percentValue + plannedYield.yieldPercent
        ))
        arrayValue.map((type) => (
          //eslint-disable-next-line
          keyPair1 = {},
          keyPair1["defaultHarvests"] = type.harvests,
          keyPair1["defaultPlannedYield"] = type.plannedYield,
          keyPair1["defaultTotalYield"] = type.totalYield,
          keyPair1["defaultTotalYieldPercent"] = type.totalYieldPercent,
          keyPair1["defaultYieldGoal"] = type.yieldGoal,
          keyPair1["defaultYieldResponse"] = type.yieldResponse,
          keyPair1 ? defaultYieldValue.push(keyPair1) : ''
        ))
        selectedField.plannedYields.map(yieldRes => (
          //eslint-disable-next-line
          keyPair2 = {},
          keyPair2["defaultYield"] = yieldRes.yield,
          keyPair2["defaultYieldPercent"] = yieldRes.yieldPercent,
          keyPair2 ? defaultVal.push(keyPair2) : ''
        ));
        defaultYieldValue.push(defaultVal);
      }
      this.setState({ 
        showHarvest: true,
        changedYieldValue: selectedField,
        defaultYieldValue: defaultYieldValue.length > 0 ? defaultYieldValue : null,
        yieldSumValue: this.roundToTwoDecimal(sumValue),
        yieldPercentValue: this.roundToTwoDecimal(percentValue)
      });
    }
  }

  /**
   * To initialize values in form
   */
  initializeValues = () => {
    const { selectedField, manureNames, fieldMasterData, fieldDetails } = this.props;
    const currentField = _.filter(fieldDetails.data, ['fieldId', selectedField.fieldId]);
    let fieldDetailsSorted = _.orderBy(fieldDetails.data, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']);
    const currentIndex = fieldDetailsSorted.findIndex( fieldDetail => fieldDetail.fieldId === selectedField.fieldId );
    if (currentField.length > 0) {
      this.setState({
        selectedField: currentField[0],
        currentFieldValue: {...currentField[0]}
      });
      if(currentIndex === 0 && currentIndex === fieldDetailsSorted.length-1) {
        this.setState({ 
          previousIndex: null,
          nextIndex: null
        });
      }
      if(currentIndex === 0 && currentIndex !== fieldDetailsSorted.length-1) {
        this.setState({ 
          previousIndex: null,
          nextIndex: fieldDetailsSorted[currentIndex+1].fieldId
        });
      }
      if(currentIndex !== 0 && currentIndex === fieldDetailsSorted.length-1) {
        this.setState({ 
          previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
          nextIndex: null
        });
      }
      if(currentIndex !== 0 && currentIndex !== fieldDetailsSorted.length-1) {
        this.setState({ 
          previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
          nextIndex: fieldDetailsSorted[currentIndex+1].fieldId
        });
      }
    }
    let manureDataNames = null;
    if(selectedField.cropId === 5 || selectedField.cropId === 14 || selectedField.cropId=== 17 || 
      selectedField.cropId === 18 || selectedField.cropId === 19 || selectedField.cropId === 23) {
      this.setState({
        disableGrain: true
      });
    } else {
      this.setState({
        disableGrain: false
      });
    }
    if (manureNames && manureNames.data !== undefined && manureNames.data.length > 0) {
      manureDataNames = manureNames.data;
    }
    if (selectedField.manureApplied) {
      this.setState({ manureApplied: true });
    } else {
      this.setState({ manureApplied: false });
    }
    if((!selectedField.yieldGoal && !selectedField.harvests) || selectedField.yieldGoal <= 0 || selectedField.harvests <= 0
     || selectedField.yieldGoal === "" || selectedField.harvests === "" || selectedField.fieldId === NEW) {
      this.setState({ 
        showHarvest: false,
        changedYieldValue: null,
        defaultYieldValue: null,
        yieldSumValue: null,
        yieldPercentValue: null,
      });
      this.props.yieldPlan.yieldCalculation = null;
    }
    
    this.springManureMethodValue='';
    this.springManureStorageValue='';
    this.springManureAmount='';
    this.summerManureMethodValue='';
    this.summerManureStorageValue='';
    this.summerManureAmount='';
    this.fallManureMethodValue='';
    this.fallManureStorageValue='';
    this.fallManureAmount='';
    if (selectedField.manurePlans && selectedField.manurePlans.length > 0) {
      selectedField.manurePlans.forEach(item => {
        if (item.seasonId === 1) {
          this.setState({
            springAdd: true
          });
          this.springManureAmount = item.manureQuantity;
          this.springManureStorageValue = item.manureStorageId;
          this.springManureMethodValue = item.method;
          this.springManureStorageLabel = null;
          if (manureDataNames !== null) {
            const [manName] = _.filter(manureDataNames, ['id', item.manureStorageId]);
            this.springManureStorageLabel = (manName !== undefined) && manName.name;
          }
        }
        if (item.seasonId === 2) {
          this.setState({
            summerAdd: true
          });
          this.summerManureAmount = item.manureQuantity;
          this.summerManureStorageValue = item.manureStorageId;
          this.summerManureMethodValue = item.method;
          this.summerManureStorageLabel = null;
          if (manureDataNames !== null) {
            const [manName] = _.filter(manureDataNames, ['id', item.manureStorageId]);
            this.summerManureStorageLabel = (manName !== undefined) && manName.name;
          }
        }
        if (item.seasonId === 3) {
          this.setState({
            fallAdd: true
          });
          this.fallManureAmount = item.manureQuantity;
          this.fallManureStorageValue = item.manureStorageId;
          this.fallManureMethodValue = item.method;
          this.fallManureStorageLabel = null;
          if (manureDataNames !== null) {
            const [manName] = _.filter(manureDataNames, ['id', item.manureStorageId]);
            this.fallManureStorageLabel = (manName !== undefined) && manName.name;
          }
        }
      });
    } else {
      this.onManureDataChange('spring', false);
      this.onManureDataChange('summer', false);
      this.onManureDataChange('fall', false);
    }
    let masterData = fieldMasterData.cropList;
    let [fieldPreviousCropName] = "";
    if(selectedField.cropId === 5 || selectedField.cropId === 14 || selectedField.cropId=== 17 || 
      selectedField.cropId === 18 || selectedField.cropId === 19 || selectedField.cropId === 23) {
      masterData = fieldMasterData.previousCropList;
      [fieldPreviousCropName] = (selectedField.grainPreviousCropId !== undefined && selectedField.grainPreviousCropId !== null) ? 
      (masterData ? _.filter(masterData, ['id', selectedField.grainPreviousCropId]) : '') : (masterData ? _.filter(masterData, ['id', 0]) : '');
    } else {
      masterData = fieldMasterData.cropList;
      [fieldPreviousCropName] = (selectedField.previousCropId !== undefined && selectedField.previousCropId !== null) ? 
      (masterData ? _.filter(masterData, ['id', selectedField.previousCropId]) : '') : (masterData ? _.filter(masterData, ['id', 0]) : '');
    }
    const [fieldCropName] = (selectedField.cropId !== undefined && selectedField.cropId !== null) ? 
      (fieldMasterData.cropList ? _.filter(fieldMasterData.cropList, ['id', selectedField.cropId]) : '') : (fieldMasterData.cropList ? _.filter(fieldMasterData.cropList, ['id', 0]) : '') ;
    let yieldGoalVal = (selectedField.yieldGoal && selectedField.yieldGoal > 0) ? selectedField.yieldGoal : '';
    this.setState({yieldGoalValue: yieldGoalVal});
    let harvestVal = selectedField.harvests ? selectedField.harvests : '';
    this.setState({harvestValue: harvestVal});
    this.setState({fieldCalculationOnUpdate: selectedField.goOrganic});
    if(selectedField && selectedField.goOrganic !== null && selectedField.goOrganic !== undefined) {
      this.setState({fieldOptionApplied: selectedField.goOrganic});
    }
    if(selectedField && selectedField.stravRemoved !== null && selectedField.stravRemoved !== undefined) {
      this.setState({stravRemovedApplied: selectedField.stravRemoved});
    }
    this.setState({
      fieldCrop: fieldCropName && selectedField.fieldId !== NEW ? fieldCropName.id : '',
      previousCrop: fieldPreviousCropName && selectedField.fieldId !== NEW ? fieldPreviousCropName.id : '',
      seedingYear: selectedField.seedingYear ? selectedField.seedingYear : '',
      clover: selectedField.clover && selectedField.clover > 0 ? selectedField.clover : '',
      yieldGoalValue: selectedField.yieldGoal && selectedField.yieldGoal > 0 ? selectedField.yieldGoal : '',
      harvestValue: selectedField.harvests ? selectedField.harvests : '',
      notes: (selectedField.fpNote && selectedField.fpNote !== null) ? selectedField.fpNote : '',
      spring_manureMethod: this.springManureMethodValue ? this.springManureMethodValue : null,
      spring_manureStorage: this.springManureStorageValue ? this.springManureStorageValue : null,
      spring_manureAmount: this.springManureAmount,
      summer_manureMethod: this.summerManureMethodValue ? this.summerManureMethodValue : null,
      summer_manureStorage: this.summerManureStorageValue ? this.summerManureStorageValue : null,
      summer_manureAmount: this.summerManureAmount,
      fall_manureMethod: this.fallManureMethodValue ? this.fallManureMethodValue : null,
      fall_manureStorage: this.fallManureStorageValue ? this.fallManureStorageValue : null,
      fall_manureAmount: this.fallManureAmount,
    });
    if(selectedField.fieldId !== NEW && selectedField.cutDates && selectedField.cutDates.length > 0) {
      let cut1DateValue = null;
      let cut2DateValue = null;
      let cut3DateValue = null;
      let cut4DateValue = null;
      if(selectedField.cutDates[0] && selectedField.cutDates[0] !== null) {
        let cutDateArray = selectedField.cutDates[0].split('-');
        cut1DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
      }
      if(selectedField.cutDates[1] && selectedField.cutDates[1] !== null) {
        let cutDateArray = selectedField.cutDates[1].split('-');
        cut2DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
      }
      if(selectedField.cutDates[2] && selectedField.cutDates[2] !== null) {
        let cutDateArray = selectedField.cutDates[2].split('-');
        cut3DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
      }
      if(selectedField.cutDates[3] && selectedField.cutDates[3] !== null) {
        let cutDateArray = selectedField.cutDates[3].split('-');
        cut4DateValue = new Date().getFullYear()+'-'+cutDateArray[1]+'-'+cutDateArray[2];
      }
      this.setState({
        cut1Date: selectedField.cutDates[0] && selectedField.cutDates[0] !== null ? new Date(cut1DateValue) : null,
        cut2Date: selectedField.cutDates[1] && selectedField.cutDates[1] !== null ? new Date(cut2DateValue) : null,
        cut3Date: selectedField.cutDates[2] && selectedField.cutDates[2] !== null ? new Date(cut3DateValue) : null,
        cut4Date: selectedField.cutDates[3] && selectedField.cutDates[3] !== null ? new Date(cut4DateValue) : null
      });
    } else {
      this.setState({
        cut1Date: null,
        cut2Date: null,
        cut3Date: null,
        cut4Date: null
      });
    }
    if(selectedField.harvests === 1) {
      this.setState({ 
        cut1show: true,
        cut2show: false,
        cut3show: false,
        cut4show: false
      });
    } else if(selectedField.harvests === 2) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: false,
        cut4show: false
      });
    } else if(selectedField.harvests === 3) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: true,
        cut4show: false
      });
    } else if(selectedField.harvests === 4) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: true,
        cut4show: true
      });
    } else {
      this.setState({ 
        cut1show: false,
        cut2show: false,
        cut3show: false,
        cut4show: false
      });
    }
    this.setState({
      initializeFormValue: true
    })
  }

  clearDrawError = () => {
    const {
      mapCoords: { coOrds }
    } = this.props;
    if (!coOrds || coOrds?.length < 3) {
      this.setState({ mapError: true });
    } else {
      this.setState({ mapError: false });
    }
  };

  handleSubmit = values => {
    const { fieldDetails } = this.props;
    fieldDetails.errorMessage = null;
    fieldDetails.isSuccess = false;
    fieldDetails.hasAddUpdateError = false;
    if(this.state.saveButtonClick) {
      const { addUpdateManureField, activeFarm, selectedField, userDetails, historicalData } = this.props;
      const { changedYieldValue, defaultYieldValue, acceptToSave, notAcceptToSave, acceptToSaveNull, 
        cut1Date, cut2Date, cut3Date, cut4Date, fieldOptionApplied, disableGrain, stravRemovedApplied } = this.state;
      const fieldValues = selectedField;
      this.setState({ 
        showHarvestPopup: false, 
        showYieldGoalPopup: false, 
        showYieldPopup: false,
        showYieldGoalNullPopup: false,
        showHarvestNullPopup: false,
        manureError: false,
        manureAppliedError: false,
        showPrevNextPopup: false
      });
      fieldValues.cropId = this.state.fieldCrop;
      fieldValues.goOrganic = fieldOptionApplied;
      if(disableGrain === true) {
        fieldValues.stravRemoved = stravRemovedApplied;
        fieldValues.grainPreviousCropId = this.state.previousCrop || null;
        fieldValues.previousCropId = null;
      } else {
        fieldValues.previousCropId = this.state.previousCrop || 0;
        fieldValues.grainPreviousCropId = null;
        fieldValues.stravRemoved = null;
      }
      fieldValues.seedingYear = this.state.seedingYear;
      fieldValues.clover = this.state.clover;
      fieldValues.fpNote = this.state.notes;
 
      const manureDetails = [];
      if (
        ((this.state.spring_manureStorage !== null && this.state.spring_manureStorage !== '') ||
        (this.state.spring_manureMethod !== null && this.state.spring_manureMethod !== '') ||
        (this.state.spring_manureAmount !== null && this.state.spring_manureAmount !== '')) && 
        this.state.harvestValue >= 1) {
        manureDetails.push({
          fieldId: null,
          seasonId: 1,
          manureStorageId: this.state.spring_manureStorage === '' ? null : this.state.spring_manureStorage,
          manureQuantity: this.state.spring_manureAmount === '' ? 0 : this.state.spring_manureAmount,
          method: this.state.spring_manureMethod === '' ? null : this.state.spring_manureMethod,
          manurePlanId: _.filter(selectedField.manurePlans, ['seasonId', 1]).length > 0 ? 
            _.filter(selectedField.manurePlans, ['seasonId', 1])[0].manurePlanId : null
        });
        this.setState({
          spring_manureAmount: this.state.spring_manureAmount === '' ? 0 : this.state.spring_manureAmount
        });
      }
      if(disableGrain === false) {
        if (
          ((this.state.summer_manureStorage !== null && this.state.summer_manureStorage !== '') ||
          (this.state.summer_manureMethod !== null && this.state.summer_manureMethod !== '') ||
          (this.state.summer_manureAmount !== null && this.state.summer_manureAmount !== '')) && 
          this.state.harvestValue >= 1) {
          manureDetails.push({
            fieldId: null,
            seasonId: 2,
            manureStorageId: this.state.summer_manureStorage === '' ? null : this.state.summer_manureStorage,
            manureQuantity: this.state.summer_manureAmount === '' ? 0 : this.state.summer_manureAmount,
            method: this.state.summer_manureMethod === '' ? null : this.state.summer_manureMethod,
            manurePlanId: _.filter(selectedField.manurePlans, ['seasonId', 2]).length > 0 ?
            _.filter(selectedField.manurePlans, ['seasonId', 2])[0].manurePlanId : null
          });
          this.setState({
            summer_manureAmount: this.state.summer_manureAmount === '' ? 0 : this.state.summer_manureAmount
          });
        }
        if (
          ((this.state.fall_manureStorage !== null && this.state.fall_manureStorage !== '') ||
          (this.state.fall_manureMethod !== null && this.state.fall_manureMethod !== '') ||
          (this.state.fall_manureAmount !== null && this.state.fall_manureAmount !== '')) && 
          this.state.harvestValue >= 2) {
          manureDetails.push({
            fieldId: null,
            seasonId: 3,
            manureStorageId: this.state.fall_manureStorage === '' ? null : this.state.fall_manureStorage,
            manureQuantity: this.state.fall_manureAmount === '' ? 0 : this.state.fall_manureAmount,
            method: this.state.fall_manureMethod === '' ? null : this.state.fall_manureMethod,
            manurePlanId: _.filter(selectedField.manurePlans, ['seasonId', 3]).length > 0 ?
            _.filter(selectedField.manurePlans, ['seasonId', 3])[0].manurePlanId : null
          });
          this.setState({
            fall_manureAmount: this.state.fall_manureAmount === '' ? 0 : this.state.fall_manureAmount
          });
        }
      }
      fieldValues.manurePlans = manureDetails;
      /*if(manureDetails.length === 0) {
        this.setState({ manureAppliedError: true });
        return;
      }*/

      /*if(disableGrain === false) {
        if(manureDetails.length > this.state.harvestValue) {
          this.setState({ manureError: true });
          return;
        }
      }*/

      if(defaultYieldValue === null){
        fieldValues.yieldResponse = this.state.yieldResponse;
        fieldValues.plannedYields =  [];
      }

      /*if(defaultYieldValue && defaultYieldValue[0].defaultTotalYield !== yieldSumValue && !acceptToSave && !notAcceptToSave) {
        this.setState({showYieldPopup: true});
        return;
      }*/
      if(!notAcceptToSave && !acceptToSave && !acceptToSaveNull) {
        if(disableGrain === false) {
          fieldValues.harvests = this.state.harvestValue;
        } else {
          fieldValues.harvests = 1;
        }
        fieldValues.yieldGoal = this.state.yieldGoalValue ? parseFloat(this.roundToTwoDecimal(this.state.yieldGoalValue)) : '';
        if(disableGrain === false && defaultYieldValue && defaultYieldValue !== null) {
          fieldValues.yieldResponse = parseFloat(this.roundToTwoDecimal(changedYieldValue.yieldResponse));
          let plannedYields = [];
          let keyPair = {};
          changedYieldValue.plannedYields && changedYieldValue.plannedYields.map(planYield => (
            //eslint-disable-next-line
            keyPair = {},
            keyPair["yield"] = parseFloat(this.roundToTwoDecimal(planYield.yield)),
            keyPair["yieldPercent"] = parseFloat(this.roundToTwoDecimal(planYield.yieldPercent)),
            plannedYields.push(keyPair)
          ))
          fieldValues.plannedYields = plannedYields;
        }
      }

      if(acceptToSaveNull) {
        if(disableGrain === false) {
          fieldValues.harvests = this.state.harvestValue;
        } else {
          fieldValues.harvests = 1;
        }
        fieldValues.yieldGoal = this.state.yieldGoalValue ? parseFloat(this.roundToTwoDecimal(this.state.yieldGoalValue)) : '';
        fieldValues.yieldResponse = null;
        fieldValues.plannedYields = [];
      }

      if(notAcceptToSave) {
        if(disableGrain === false && defaultYieldValue && defaultYieldValue !== null) {
          fieldValues.yieldResponse = parseFloat(this.roundToTwoDecimal(defaultYieldValue[0].defaultYieldResponse));
          let plannedYields = [];
          let keyPair = {};
          defaultYieldValue[1].map(planYield => (
            //eslint-disable-next-line
            keyPair = {},
            keyPair["yield"] = parseFloat(this.roundToTwoDecimal(planYield.defaultYield)),
            keyPair["yieldPercent"] = parseFloat(this.roundToTwoDecimal(planYield.defaultYieldPercent)),
            plannedYields.push(keyPair)
          ))
          fieldValues.plannedYields = plannedYields;
          fieldValues.harvests = defaultYieldValue[0].defaultHarvests;
          fieldValues.yieldGoal = parseFloat(this.roundToTwoDecimal(defaultYieldValue[0].defaultYieldGoal));
        }
      }

      if(acceptToSave) {
        if(disableGrain === false && changedYieldValue && changedYieldValue !== null) {
          fieldValues.yieldResponse = parseFloat(this.roundToTwoDecimal(changedYieldValue.yieldResponse));
          let plannedYields = [];
          let keyPair = {};
          changedYieldValue.plannedYields.map(planYield => (
            //eslint-disable-next-line
            keyPair = {},
            keyPair["yield"] = parseFloat(this.roundToTwoDecimal(planYield.yield)),
            keyPair["yieldPercent"] = parseFloat(this.roundToTwoDecimal(planYield.yieldPercent)),
            plannedYields.push(keyPair)
          ))
          if(disableGrain === false) {
            fieldValues.plannedYields = plannedYields;
          }
        }
        if(disableGrain === false) {
          fieldValues.harvests = this.state.harvestValue;
        } else {
          fieldValues.harvests = 1;
        }
        fieldValues.yieldGoal = this.state.yieldGoalValue ? parseFloat(this.roundToTwoDecimal(this.state.yieldGoalValue)) : '';
      }
      if(fieldValues.clover === '' || fieldValues.clover === undefined) {
        fieldValues.clover = 0;
      }
      if(fieldValues.harvests === '' || fieldValues.harvests === undefined) {
        fieldValues.harvests = 0;
      }
      if(disableGrain === true) {
        fieldValues.harvests = 1;
        fieldValues.plannedYields = null;
      }
      /*if(fieldValues.method === '' || fieldValues.method === undefined) {
        fieldValues.method = null;
      }*/
      if(fieldValues.plannedYield === '' || fieldValues.plannedYield === undefined) {
        fieldValues.plannedYield = null;
      }
      if(fieldValues.seedingYear === '' || fieldValues.seedingYear === undefined) {
        fieldValues.seedingYear = null;
      }
      if(fieldValues.yieldGoal === '' || fieldValues.yieldGoal === undefined) {
        fieldValues.yieldGoal = 0;
      }
      if(fieldValues.yieldResponse === '' || fieldValues.yieldResponse === undefined) {
        fieldValues.yieldResponse = null;
      }      
      let cut1DateValue = null;
      let cut2DateValue = null;
      let cut3DateValue = null;
      let cut4DateValue = null;      
      cut1DateValue = cut1Date !== null && this.state.cut1show ? format(cut1Date, 'yyyy-MM-dd') : null;
      if(disableGrain === false) {
        cut2DateValue = cut2Date !== null && this.state.cut2show ? format(cut2Date, 'yyyy-MM-dd') : null;
        cut3DateValue = cut3Date !== null && this.state.cut3show ? format(cut3Date, 'yyyy-MM-dd') : null;
        cut4DateValue = cut4Date !== null && this.state.cut4show ? format(cut4Date, 'yyyy-MM-dd') : null;
      }
      let cutDatesValue = [];
      if(cut4DateValue !== null) {
        cutDatesValue = [cut1DateValue,cut2DateValue,cut3DateValue,cut4DateValue];
      } else {
        if(cut3DateValue !== null) {
          cutDatesValue = [cut1DateValue,cut2DateValue,cut3DateValue];
        } else {
          if(cut2DateValue !== null) {
            cutDatesValue = [cut1DateValue,cut2DateValue];
          } else {
            if(cut1DateValue !== null) {
              cutDatesValue = [cut1DateValue];
            }
          }
        }
      }
      fieldValues.cutDates = cutDatesValue;

      this.setState({acceptToSave: false, notAcceptToSave: false});
      this.setState({ sizeChangeWarningAcepted: false });
      if(selectedField.fieldId === NEW) {
        mixpanel.identify(userDetails.id);
        mixpanel.track(FIELD_CREATED);
      } else {
        mixpanel.identify(userDetails.id);
        mixpanel.track(FIELD_UPDATED);
      }
      if(historicalData) {
        historicalData.historicalDataValue = null;
      }
      addUpdateManureField(fieldValues, activeFarm.farm.id);
      this.setStateOfParent(fieldValues); 
    } else {
      const { selectedField, paramId } = this.props;
      const { changedYieldValue, defaultYieldValue, cut1Date, cut2Date, cut3Date, cut4Date, 
        fieldOptionApplied, selectedFieldId, disableGrain, stravRemovedApplied, currentFieldValue } = this.state;
      const fieldValues = selectedField;
      this.setState({ 
        showHarvestPopup: false, 
        showYieldGoalPopup: false, 
        showYieldPopup: false,
        showYieldGoalNullPopup: false,
        showHarvestNullPopup: false,
        manureError: false,
        manureAppliedError: false
      });

      fieldValues.cropId = this.state.fieldCrop;
      fieldValues.goOrganic = fieldOptionApplied;
      if(disableGrain === true) {
        fieldValues.stravRemoved = stravRemovedApplied;
        fieldValues.grainPreviousCropId = this.state.previousCrop || null;
      } else {
        fieldValues.previousCropId = this.state.previousCrop || 0;
        fieldValues.stravRemoved = null;
      }
      fieldValues.seedingYear = this.state.seedingYear;
      fieldValues.clover = this.state.clover;
      fieldValues.fpNote = this.state.notes;
      if(disableGrain === false) {
        fieldValues.harvests = this.state.harvestValue;
      } else {
        fieldValues.harvests = 1;
        fieldValues.plannedYields = null;
      }
      const manureDetails = [];
      if (
        ((this.state.spring_manureStorage !== null && this.state.spring_manureStorage !== '') ||
        (this.state.spring_manureMethod !== null && this.state.spring_manureMethod !== '') ||
        (this.state.spring_manureAmount !== null && this.state.spring_manureAmount !== '')) && 
        this.state.harvestValue >= 1) {
        manureDetails.push({
          fieldId: null,
          seasonId: 1,
          manurePlanId: _.filter(selectedField.manurePlans, ['seasonId', 1]).length > 0 ? 
            _.filter(selectedField.manurePlans, ['seasonId', 1])[0].manurePlanId : null,
          method: this.state.spring_manureMethod === '' ? null : this.state.spring_manureMethod,
          manureStorageId: this.state.spring_manureStorage === '' ? null : this.state.spring_manureStorage,
          manureQuantity: this.state.spring_manureAmount === '' ? 0 : this.state.spring_manureAmount         
        });
        this.setState({
          spring_manureAmount: this.state.spring_manureAmount === '' ? 0 : this.state.spring_manureAmount
        });
      }
      if(disableGrain === false) {
        if (
          ((this.state.summer_manureStorage !== null && this.state.summer_manureStorage !== '') ||
          (this.state.summer_manureMethod !== null && this.state.summer_manureMethod !== '') ||
          (this.state.summer_manureAmount !== null && this.state.summer_manureAmount !== '')) && 
          this.state.harvestValue >= 1) {
          manureDetails.push({
            fieldId: null,
            seasonId: 2,
            manurePlanId: _.filter(selectedField.manurePlans, ['seasonId', 2]).length > 0 ?
            _.filter(selectedField.manurePlans, ['seasonId', 2])[0].manurePlanId : null,
            method: this.state.summer_manureMethod === '' ? null : this.state.summer_manureMethod,
            manureStorageId: this.state.summer_manureStorage === '' ? null : this.state.summer_manureStorage,
            manureQuantity: this.state.summer_manureAmount === '' ? 0 : this.state.summer_manureAmount         
          });
          this.setState({
            summer_manureAmount: this.state.summer_manureAmount === '' ? 0 : this.state.summer_manureAmount
          });
        }
        if (
          ((this.state.fall_manureStorage !== null && this.state.fall_manureStorage !== '') ||
          (this.state.fall_manureMethod !== null && this.state.fall_manureMethod !== '') ||
          (this.state.fall_manureAmount !== null && this.state.fall_manureAmount !== '')) && 
          this.state.harvestValue >= 2) {
          manureDetails.push({
            fieldId: null,
            seasonId: 3,
            manurePlanId: _.filter(selectedField.manurePlans, ['seasonId', 3]).length > 0 ?
            _.filter(selectedField.manurePlans, ['seasonId', 3])[0].manurePlanId : null,
            method: this.state.fall_manureMethod === '' ? null : this.state.fall_manureMethod,
            manureStorageId: this.state.fall_manureStorage === '' ? null : this.state.fall_manureStorage,
            manureQuantity: this.state.fall_manureAmount === '' ? 0 : this.state.fall_manureAmount         
          });
          this.setState({
            fall_manureAmount: this.state.fall_manureAmount === '' ? 0 : this.state.fall_manureAmount
          });
        }
      }
      fieldValues.manurePlans = manureDetails;

      if(defaultYieldValue === null){
        fieldValues.yieldResponse = this.state.yieldResponse;
        fieldValues.plannedYields =  null;
      }
     
      fieldValues.yieldGoal = this.state.yieldGoalValue ? parseFloat(this.roundToTwoDecimal(this.state.yieldGoalValue)) : '';
      if(disableGrain === false && defaultYieldValue && defaultYieldValue !== null) {
        fieldValues.yieldResponse = parseFloat(this.roundToTwoDecimal(changedYieldValue.yieldResponse));
        let plannedYields = [];
        let keyPair = {};
        changedYieldValue.plannedYields.map(planYield => (
          //eslint-disable-next-line
          keyPair = {},
          keyPair["yield"] = parseFloat(this.roundToTwoDecimal(planYield.yield)),
          keyPair["yieldPercent"] = parseFloat(this.roundToTwoDecimal(planYield.yieldPercent)),
          plannedYields.push(keyPair)
        ))
        fieldValues.plannedYields = plannedYields;
      }

      if(fieldValues.clover === '' || fieldValues.clover === undefined) {
        fieldValues.clover = 0;
      }
      if(disableGrain === false) {
        if(fieldValues.harvests === '' || fieldValues.harvests === undefined) {
          fieldValues.harvests = 0;
        }
      } else {
        fieldValues.harvests = 1;
      }
      /*if(fieldValues.method === '' || fieldValues.method === undefined) {
        fieldValues.method = null;
      }*/
      if(disableGrain === false) {
        if(fieldValues.plannedYield === '' || fieldValues.plannedYield === undefined) {
          fieldValues.plannedYield = null;
        }
        if(fieldValues.yieldResponse === ''|| fieldValues.yieldResponse === undefined) {
          fieldValues.yieldResponse = null;
        }
      }
      if(fieldValues.yieldResponse === ''|| fieldValues.yieldResponse === undefined) {
        fieldValues.yieldResponse = null;
      }
      if(fieldValues.seedingYear === '' || fieldValues.seedingYear === undefined) {
        fieldValues.seedingYear = null;
      }
      if(fieldValues.yieldGoal === '' || fieldValues.yieldGoal === undefined) {
        fieldValues.yieldGoal = 0;
      }
      
      let cut1DateValue = null;
      let cut2DateValue = null;
      let cut3DateValue = null;
      let cut4DateValue = null;
      cut1DateValue = cut1Date !== null ? format(cut1Date, 'yyyy-MM-dd') : null;
      if(disableGrain === false) {
        cut2DateValue = cut2Date !== null ? format(cut2Date, 'yyyy-MM-dd') : null;
        cut3DateValue = cut3Date !== null ? format(cut3Date, 'yyyy-MM-dd') : null;
        cut4DateValue = cut4Date !== null ? format(cut4Date, 'yyyy-MM-dd') : null;
      }
      let cutDatesValue = [];
      if(cut4DateValue !== null) {
        cutDatesValue = [cut1DateValue,cut2DateValue,cut3DateValue,cut4DateValue];
      } else {
        if(cut3DateValue !== null) {
          cutDatesValue = [cut1DateValue,cut2DateValue,cut3DateValue];
        } else {
          if(cut2DateValue !== null) {
            cutDatesValue = [cut1DateValue,cut2DateValue];
          } else {
            if(cut1DateValue !== null) {
              cutDatesValue = [cut1DateValue];
            }
          }
        }
      }
      fieldValues.cutDates = cutDatesValue;
      if(selectedFieldId !== null && selectedFieldId !== paramId) {
        // eslint-disable-next-line
        currentFieldValue.manurePlans = currentFieldValue.manurePlans.sort((a, b) => {
          if (a.seasonId < b.seasonId) {
            return -1;
          }
        });
        if(currentFieldValue.plannedYields !== null && currentFieldValue.plannedYields.length === 0) {
          currentFieldValue.plannedYields = null;
        }
        if(fieldValues.plannedYields !== null && fieldValues.plannedYields.length === 0) {
          fieldValues.plannedYields = null;
        }
        if(currentFieldValue.harvests === 0) {
          currentFieldValue.yieldResponse = null;
          currentFieldValue.plannedYields = null;
        }
        if(currentFieldValue.manurePlans.length > 0){
          let currentManureDetails = [];
          currentFieldValue.manurePlans.map(manurePlan => (
            currentManureDetails.push({
              fieldId: null,
              seasonId: manurePlan.seasonId,
              manurePlanId: manurePlan.manurePlanId,
              method: manurePlan.method,
              manureStorageId: manurePlan.manureStorageId,
              manureQuantity: manurePlan.manureQuantity      
            })
          ));
          currentFieldValue.manurePlans = currentManureDetails;
        }
        if(currentFieldValue.fpNote === null) {
          currentFieldValue.fpNote = '';
        }
        if(JSON.stringify(currentFieldValue) === JSON.stringify(fieldValues)) {
          this.setStateOfParent(null);
          //window.location.replace(`/#/managefields/${selectedFieldId}/manure`);
          return;
        } else {
          this.setState({
            showPrevNextPopup: true
          });
          return;
        }
      }
    }
  };

  /**
   * change handler for size input
   *
   */
  onSizeFieldChange = () => {
    const {
      props: {
        mapCoords: { coOrds },
        activeFarm,
        hasSupportAdminRole
      },
      state: { sizeChangeWarningAcepted }
    } = this;

    if (
      coOrds &&
      coOrds.length > 0 &&
      !sizeChangeWarningAcepted &&
      (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data)
    ) {
      this.setState({ showEditFieldSizePrompt: true, sizeChangeWarningAcepted: true });
    }
  };

  /**
   * To show/hide prompt on editing size field
   *
   */
  showDialogFieldSize = action => {
    const { change, mapCoords, fieldFormValues } = this.props;
    const { area } = mapCoords;
    if (action) {
      change('fieldSize', fieldFormValues?.values?.fieldSize);
      this.setState({
        showEditFieldSizePrompt: false,
        sizeChangeWarningAcepted: true
      });
    } else {
      change('fieldSize', area);
      this.setState({
        showEditFieldSizePrompt: false,
        sizeChangeWarningAcepted: false
      });
    }
  };

  onManureOptionChange = e => {
    const { change } = this.props;
    this.setState({
      manureApplied: e
    });
    change('manureApplied', e);
  };

  calculateHistoricalData = () => {
    const { historicalDataCalculation, historicalData, activeFarm, selectedField } = this.props;
    historicalData.historicalDataValue = null;
    this.setState({ setHistoricalData: false });
    historicalDataCalculation(activeFarm.farm.id, selectedField.fieldId);
  }

  onManureDataChange = (manureData, event) => {
    const { change } = this.props;
    if (manureData === 'spring') {
      change('spring_manureStorage', null);
      change('spring_manureAmount', null);
      this.setState({
        springAdd: event,
        spring_manureStorage: '',
        spring_manureMethod: '',
        spring_manureAmount: '',
      });
    }
    if (manureData === 'summer') {
      change('summer_manureStorage', null);
      change('summer_manureAmount', null);
      this.setState({
        summerAdd: event,
        summer_manureStorage: '',
        summer_manureMethod: '',
        summer_manureAmount: '',
      });
    }
    if (manureData === 'fall') {
      change('fall_manureStorage', null);
      change('fall_manureAmount', null);
      this.setState({
        fallAdd: event,
        fall_manureStorage: '',
        fall_manureMethod: '',
        fall_manureAmount: '',
      });
    }
  };

  // To toggle full screen boundary drawing
  toggleScreen = () => {
    this.setState(prevState => ({
      isShowFullScreen: !prevState.isShowFullScreen
    }));
  };

  setYieldGoal = (value) => {
    const { yieldCalculation, yieldPlan } = this.props;
    const { harvestValue } = this.state;
    this.setState({yieldGoalValue: value.target.value, manureError: false, manureAppliedError: false});
    this.setState({ showHarvest: false });
    var data = {
      "yieldGoal": value.target.value,
      "harvests": harvestValue
    }
    yieldPlan.yieldCalculation = null;
    yieldCalculation(data);
  };

  setHarvest = (value) => {
    const { yieldCalculation, yieldPlan } = this.props;
    const { yieldGoalValue } = this.state;
    this.setState({harvestValue: value.target.value,  manureError: false, manureAppliedError: false});
    this.setState({ showHarvest: false });
    var data = {
      "yieldGoal": yieldGoalValue,
      "harvests": value.target.value
    }
    if(value.target.value === 1) {
      this.setState({ 
        cut1show: true,
        cut2show: false,
        cut3show: false,
        cut4show: false
      });
    } else if(value.target.value === 2) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: false,
        cut4show: false
      });
    } else if(value.target.value === 3) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: true,
        cut4show: false
      });
    } else if(value.target.value === 4) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: true,
        cut4show: true
      });
    } else {
      this.setState({ 
        cut1show: false,
        cut2show: false,
        cut3show: false,
        cut4show: false
      });
    }
    yieldPlan.yieldCalculation = null;
    yieldCalculation(data);
  };

  changeYieldValueByCalculation = (event) => {
    let { defaultYieldValue } = this.state;
    const items = {
      ...this.state.changedYieldValue
    };
    const splitArray = event.target.name.split('_');
    if(splitArray[0] === "yieldResponse") {
      items["yieldResponse"] = event.target.value;
    }
    if(splitArray[0] === "yiedHarvest") {
      let eventValue = 0;
      if(event.target.value === null || event.target.value === "" ) {
        eventValue = 0;
      } else {
        eventValue = event.target.value;
      }
      items["plannedYields"][splitArray[1]]["yield"] = event.target.value;
      if(!isNaN(eventValue)) {
        let percentValue = (eventValue * defaultYieldValue[1][splitArray[1]]["defaultYieldPercent"])/defaultYieldValue[1][splitArray[1]]["defaultYield"];      
        items["plannedYields"][splitArray[1]]["yieldPercent"] = this.roundToTwoDecimal(percentValue);
        this.calculateYieldTotal();
      }
    }
    if(splitArray[0] === "yiedHarvestPercent") {
      let eventValue = 0;
      if(event.target.value === null || event.target.value === "" ) {
        eventValue = 0;
      } else {
        eventValue = event.target.value;
      }
      items["plannedYields"][splitArray[1]]["yieldPercent"] = event.target.value;
      if(!isNaN(eventValue)) {
        let harvestValue = (eventValue * defaultYieldValue[1][splitArray[1]]["defaultYield"])/defaultYieldValue[1][splitArray[1]]["defaultYieldPercent"];
        items["plannedYields"][splitArray[1]]["yield"] = this.roundToTwoDecimal(harvestValue);
        this.calculateYieldTotal();
      }
    }
    this.setState({ changedYieldValue: items,  manureError: false, manureAppliedError: false });
  }

  calculateYieldTotal = () => {
    let { changedYieldValue } = this.state;
    let sumValue = 0;
    let percentValue = 0;
    let yieldVal = 0;
    let yieldPercentVal = 0;
    changedYieldValue.plannedYields.map(changeValue => (
      //eslint-disable-next-line
      yieldVal = 0,
      yieldPercentVal = 0,
      (isNaN(changeValue.yield) || changeValue.yield === "") ? yieldVal = 0 : yieldVal = changeValue.yield,
      (isNaN(changeValue.yieldPercent) || changeValue.yieldPercent === "") ? yieldPercentVal = 0 : yieldPercentVal = changeValue.yieldPercent,
      sumValue = parseFloat(sumValue) + parseFloat(yieldVal),
      percentValue = parseFloat(percentValue) + parseFloat(yieldPercentVal)
    ));
    this.setState({ yieldSumValue: this.roundToTwoDecimal(sumValue), yieldPercentValue: this.roundToTwoDecimal(percentValue), 
      manureError: false, manureAppliedError: false });
  }
  
  closeHarvestPopUp = () => {
    this.setState({ 
      showYieldPopup: false, 
      showHarvestPopup: false,
      showYieldGoalPopup: false,
      showHarvestNullPopup: false,
      showYieldGoalNullPopup: false,
      manureError: false,
      manureAppliedError: false
    });
  }

  closeYieldGoalPopUp = () => {
    this.setState({ 
      showYieldPopup: false, 
      showHarvestPopup: false,
      showYieldGoalPopup: false,
      showHarvestNullPopup: false,
      showYieldGoalNullPopup: false,
      manureError: false,
      manureAppliedError: false
    }); 
  }

  saveYieldPopUp = () => {
    const { handleSubmit } = this.props;
    this.setState({ acceptToSave: true,  manureError: false, manureAppliedError: false });
    handleSubmit(this.handleSubmit);
  }

  saveNullPopUp = () => {
    const { handleSubmit } = this.props;
    this.setState({ acceptToSaveNull: true,  manureError: false, manureAppliedError: false });
    handleSubmit(this.handleSubmit);
  }

  closeYieldPopUp = () => {
    const { handleSubmit } = this.props;
    this.setState({ notAcceptToSave: true,  manureError: false, manureAppliedError: false });
    handleSubmit(this.handleSubmit);
  }

  toggleFieldCalculationOnUpdate() {
    const { fieldCalculationOnUpdate } = this.state;
    this.setState({
      fieldCalculationOnUpdate: !fieldCalculationOnUpdate
    });
  }

  changeField = (fieldId) => {
    const { fieldDetails } = this.props;
    this.setState({
      selectedFieldId: fieldId
    });
    fieldDetails.errorMessage = null;
    fieldDetails.isSuccess = false;
    fieldDetails.hasAddUpdateError = false;
  }

  saveChangeField = () => {
    const { selectedFieldId } = this.state;
    const { fieldDetails } = this.props;
    if(selectedFieldId !== null) {
      this.setStateOfParent(null);
      //window.location.replace(`/#/managefields/${selectedFieldId}/manure`);
    }
    fieldDetails.errorMessage = null;
    fieldDetails.isSuccess = false;
    fieldDetails.hasAddUpdateError = false;
    this.setState({
      saveButtonClick: false
    });
  }

  handleClick = (event, selected) => {
    const { open1, open2, open3, open4,open5 } = this.state;
    if(selected === 1) {
      this.setState({ open1: !open1 });
    } else if(selected === 2) {
    this.setState({ open2: !open2 });      
    } else if(selected === 3) {
      this.setState({ open3: !open3 });      
    } else if(selected === 4) {
    this.setState({ open4: !open4 });
    } else {
      this.setState({ open5: !open5 });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  manureMethodChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      summer_manureMethod: event.target.value,
      spring_manureMethod: event.target.value,
      fall_manureMethod: event.target.value
    });
  };

  manureStorageChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      summer_manureStorage: event.target.value,
      spring_manureStorage: event.target.value,
      fall_manureStorage: event.target.value
    });
  };  

  openDialogWindow = () => {
    this.setState({
      dialogOpen: true
    })
  }

  closeDialogWindow = () => {
    this.setState({
      dialogOpen: false
    });
  }

  saveDialogWindow = () => {
    const { activeFarm, createManure } = this.props;
    const manureValues = {
      "name": this.state.dialog_manureName,
      "type": this.state.dialog_manureType,
      "storageCapacity": this.state.dialog_storageCapacity,
      "yearlyProduction": this.state.dialog_yearlyProduction,
      "dryMatter": this.state.dialog_dryMatter
    };
    createManure(activeFarm.farm.id, manureValues);
  }

  getManureOption = manureType => {
    if (manureType?.length > 0) {
      return manureType.map(item => {
        return { label: item.type, value: item.id };
      });
    }
    return [];
  };

  resetSeason = season => {
    if(season === 1){
      this.setState({
        spring_manureMethod: '',
        spring_manureStorage: '',
        spring_manureAmount: ''
      });
    }
    if(season === 2){
      this.setState({
        summer_manureMethod: '',
        summer_manureStorage: '',
        summer_manureAmount: ''
      });
    }
    if(season === 3){
      this.setState({
        fall_manureMethod: '',
        fall_manureStorage: '',
        fall_manureAmount: ''
      });
    } 
  }

  render() {
    const { fieldMasterData, pageContent, handleSubmit, selectedField, seasons, fieldMethod, intl, manureNames, 
      fieldDetails, yieldPlan, onCloseOpenInsufficientItemsLink } = this.props;
    const { open1, open2, open3, open4, open5, disableGrain, showHarvest, yieldCalculation, changedYieldValue, yieldSumValue,
       yieldPercentValue,fieldOptionApplied, stravRemovedApplied, dialogOpen, showYieldGoalPopup, showHarvestPopup, showHarvestNullPopup,
       showPrevNextPopup, showYieldPopup, showYieldGoalNullPopup, manureError, manureAppliedError, summer_manureMethod } = this.state;
    const { manureType, submitSuccess, hasError, errorMsg } = this.props.manureStorage;
    const { errorMessage, isSuccess, hasAddUpdateError, hasDeleteError, isLoading } = fieldDetails;
    const { yieldCalculationError } = yieldPlan;
    const lblPage = pageContent[0];
    const titleStyle = { fontFamily: 'Inter', fontSize: '24px', fontWeight: 400, paddingBottom: '20px' };
    const subTitleStyle = { ...titleStyle, fontSize: '14px' };
    const customButtonStyle = {
      backgroundColor: '#00696D', color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00696D'
      }, borderRadius: '100px', paddingLeft: 20, paddingRight: 20, textTransform: 'none', fontSize: '14px', fontFamily: 'Inter'
    };
    const customCancelButtonStyle = {
      color: '#00696D',
      '&:hover': {
        color: '#00696D'
      }, fontSize: '14px', fontFamily: 'Inter', textTransform: 'none'
    };
    const centerAlignStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
      zIndex: 1
    };
    const buttonStyle = {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      color: '#00696D',
      padding: '8px',
      background: 'transparent'
    };
    if(submitSuccess) {
      //window.location.replace(`/#/managefields/${selectedField.fieldId}/manure`);
      //window.location.reload();
    }
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
      {selectedField && selectedField.fieldId === undefined ? <div>
        <Typography level="body-sm" sx={{ paddingLeft: '10px', fontSize: '14px', fontFamily: 'Inter', paddingTop: '25px' }}>
          <FormattedMessage id="NO_MANURE_DETAILS_TAB" />
        </Typography>
      </div> : <div>
      {selectedField && seasons.seasonsList !== null && fieldMethod.fieldMethodList && fieldMasterData && fieldMasterData.cropList !== null && fieldMasterData.previousCropList !== null && (
      <div>
      <Box sx={{ width: '100%', flexGrow: 1, padding: '0' }}>
        <CropComponent
          lblPage={lblPage}
          stravRemovedApplied={stravRemovedApplied}
          intl={intl}
          disableGrain={disableGrain}
          fieldOptionApplied={fieldOptionApplied}
          getYears={getYears}
          fieldMasterData={fieldMasterData}
          open1={open1}
          fieldCrop={this.state.fieldCrop}
          previousCrop={this.state.previousCrop}
          seedingYear={this.state.seedingYear}
          clover={this.state.clover}
          handleClickCrop={e=>this.handleClick(e, 1)}
          handleChange={this.handleChange}
          onCropChange={e => this.onCropChange(e)}
          onFieldOptionChangeTrue={() => this.onFieldOptionChange(true)}
          onFieldOptionChangeFalse={() => this.onFieldOptionChange(false)}
          onStravRemovedChangeTrue={() => this.onStravRemovedChange(true)}
          onStravRemovedChangeFalse={() => this.onStravRemovedChange(false)}
        />
        <YieldGoalComponent
          handleClickYieldGoal={e=>this.handleClick(e, 2)}
          lblPage={lblPage}
          intl={intl}
          disableGrain={disableGrain}
          open2={open2}
          yieldCalculation={yieldCalculation}
          yieldGoalValue={this.state.yieldGoalValue}
          setYieldGoal={value => this.setYieldGoal(value)}
          selectedField={selectedField}
          harvestValue={this.state.harvestValue}
          setHarvest={value => this.setHarvest(value)}
          showHarvest={showHarvest}
          changedYieldValue={changedYieldValue}
          yieldSumValue={yieldSumValue}
          yieldPercentValue={yieldPercentValue}
          changeYieldValueByCalculation={event => this.changeYieldValueByCalculation(event)}
        />
        <CutUnitComponent
          handleClickCutUnit={e=>this.handleClick(e, 3)}
          intl={intl}
          disableGrain={disableGrain}
          open3={open3}
          cut1Date={this.state.cut1Date}
          cut1DateChange={this.cut1DateChange}
          cut2Date={this.state.cut2Date}
          cut2DateChange={this.cut2DateChange}
          cut3Date={this.state.cut3Date}
          cut3DateChange={this.cut3DateChange}
          cut4Date={this.state.cut4Date}
          cut4DateChange={this.cut4DateChange}
          calculateHistoricalData={() => this.calculateHistoricalData()}
          cut1show={this.state.cut1show}
          cut2show={this.state.cut2show}
          cut3show={this.state.cut3show}
          cut4show={this.state.cut4show}
        />
        <StorageComponent
          handleClickStorage={e=>this.handleClick(e, 4)}
          lblPage={lblPage}
          intl={intl}
          open4={open4}
          openDialogWindow={this.openDialogWindow}
          manureMethod={this.state.manureMethod}
          manureMethodChange={this.manureMethodChange}
          manureStorage={this.state.manureStorage}
          manureStorageChange={this.manureStorageChange}
          summer_manureMethod={summer_manureMethod}
          summer_manureStorage={this.state.summer_manureStorage}
          summer_manureAmount={this.state.summer_manureAmount}
          spring_manureMethod={this.state.spring_manureMethod}
          spring_manureStorage={this.state.spring_manureStorage}
          spring_manureAmount={this.state.spring_manureAmount}
          fall_manureMethod={this.state.fall_manureMethod}
          fall_manureStorage={this.state.fall_manureStorage}
          fall_manureAmount={this.state.fall_manureAmount}
          handleChange={this.handleChange}
          manureNames={manureNames}
          fieldMethod={fieldMethod}
          harvestValue={this.state.harvestValue}
          disableGrain={disableGrain}
          seasons={seasons}
          resetSeason={this.resetSeason}
        />

        <List
          sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton disableRipple sx={{ '&:hover': {backgroundColor: 'transparent'} }} onClick={e=>this.handleClick(e, 5)}>
            <span><ListItemText primary={intl.formatMessage({ id: 'NOTE' })} /></span>
            <span>{open5 ? <ExpandLess /> : <ExpandMore />}</span>
          </ListItemButton>
          <Collapse in={open5} timeout="auto" unmountOnExit>
            <List component="div" className='list-grid-box'>
              <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>  
                <Stack className='text-with-padding' sx={{ width: '100%' }}>
                  <MuiTextArea 
                    name="notes"
                    id="notes"
                    label={intl.formatMessage({ id: 'NOTE' })}
                    variant="outlined"
                    rows={12}
                    value={this.state.notes}
                    onChange={this.handleChange}
                    showFullWidth="true"
                  />
                </Stack>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Box></div>)}
      <Dialog onClose={() => {}} open={dialogOpen} fullWidth maxWidth="sm">
        <Box sx={{padding: '40px'}}>
            <Typography style={titleStyle}>{intl.formatMessage({ id: 'MANURE_STORAGE_DIALOG_HEADING' })}</Typography>
            <Typography style={subTitleStyle}>{intl.formatMessage({ id: 'MANURE_STORAGE_DIALOG_SUBHEADING' })}</Typography>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="dialog_manureName"
                id="dialog_manureName"
                label={intl.formatMessage({ id: 'NAME' })}
                variant="outlined"
                value={this.state.dialog_manureName}
                onChange={this.handleChange}
                showFullWidth="true"
              />
            </Stack>
            <Stack className='text-with-padding'>
              <MuiSelectBox 
                name="dialog_manureType"
                id="dialog_manureType"
                label={intl.formatMessage({ id: 'CT_FERTILIZERTYPE' })}
                variant="outlined"
                value={this.state.dialog_manureType}
                options={this.getManureOption(manureType)}
                onChange={this.handleChange}
              />
            </Stack>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="dialog_yearlyProduction"
                id="dialog_yearlyProduction"
                label={intl.formatMessage({ id: 'MANURE_STORAGE_YEARLY_PRODUCTION' })}
                variant="outlined"
                value={this.state.dialog_yearlyProduction}
                onChange={this.handleChange}
                showFullWidth="true"
              />
            </Stack>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="dialog_storageCapacity"
                id="dialog_storageCapacity"
                label={`${intl.formatMessage({ id: 'STORAGE_CAPACITY' })} m^3`}
                variant="outlined"
                value={this.state.dialog_storageCapacity}
                onChange={this.handleChange}
                showFullWidth="true"
              />
            </Stack>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="dialog_dryMatter"
                id="dialog_dryMatter"
                label={intl.formatMessage({ id: 'DRY_MATTER' })}
                variant="outlined"
                value={this.state.dialog_dryMatter}
                onChange={this.handleChange}
                showFullWidth="true"
              />
            </Stack>
            <DialogActions>
              <div className="manure-error">{hasError && <ErrorText>{errorMsg.data}</ErrorText>}</div>
              <Button style={customCancelButtonStyle} onClick={this.closeDialogWindow}>
                {intl.formatMessage({ id: 'CANCEL' })}
              </Button>
              <Button style={customButtonStyle} variant="contained" onClick={this.saveDialogWindow}>
                {intl.formatMessage({ id: 'AEF_SB' })}
              </Button>
            </DialogActions>
          </Box>
      </Dialog>
      {isSuccess ? (
        <div className="updated-div padding-top-20-mui">
          <i className="success-indicator-mui" />
          <span className='padding-left-8-mui'>
            <FormattedMessage id="updated" />
          </span>
        </div>
      ) : null}
      {isSuccess && this.saveChangeField()}
      {isLoading && <Loader styleName="fixed-loader" />}
      {selectedField && seasons.seasonsList !== null && fieldMethod.fieldMethodList && fieldMasterData && fieldMasterData.cropList !== null && fieldMasterData.previousCropList !== null && (
        <div style={centerAlignStyle} className="fixed-button-mui-field">
          {hasAddUpdateError || hasDeleteError ? (
            <div className="updated-div padding-right-20-mui">
              <span>
                <ErrorText>
                  {errorMessage?.status === 400
                    ? errorMessage?.data
                    : errorMessage?.status === 409
                    ? intl.formatMessage({ id: 'FIAE' })
                    : intl.formatMessage({ id: 'COMMON_ERR_TXT' })}
                </ErrorText>
              </span>
            </div>
          ) : null}

          {hasError && yieldCalculationError !== null ? (
            <div className="updated-div padding-right-20-mui">
              <span>
                <ErrorText>
                  {yieldCalculationError}
                </ErrorText>
              </span>
            </div>
          ) : null}

          {manureError ? (
            <div className="updated-div padding-right-20-mui">
              <span>
                <ErrorText>
                  {intl.formatMessage({ id: 'MANURE_STORAGE_ERROR' })}
                </ErrorText>
              </span>
            </div>
          ) : null}

          {manureAppliedError ? (
            <div className="updated-div padding-right-20-mui">
              <span>
                <ErrorText>
                  {intl.formatMessage({ id: 'MANURE_APPLIED_ERROR' })}
                </ErrorText>
              </span>
            </div>
          ) : null}
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={onCloseOpenInsufficientItemsLink}
          />
          <Button style={customButtonStyle} disabled={isLoading} type="submit" 
            onClick={() => {
              this.setState({
                saveButtonClick: true
              });
              handleSubmit(this.handleSubmit)
            }}
          >
            {intl.formatMessage({ id: 'AEF_SB' })}
          </Button>
        </div>)}
          {isLoading ? <Loader styleName={isLoading ? 'field-loader' : ''} /> : null}
          {showYieldPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showYieldPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_SUM_MODAL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span className="button-style-padding-right">
                      <ActionButton onClick={() => this.saveYieldPopUp()}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                    </span>
                    <span>
                      <ActionButton multi onClick={() => this.closeYieldPopUp()}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showPrevNextPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '25%', tablet: '25%', web: '25%'}}
              show={showPrevNextPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'FIELD_NEXT_PAGE' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span className="button-style-padding-right">
                      <ActionButton 
                      onClick={() => {
                        this.setState({
                          saveButtonClick: true
                        });
                      }}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                    </span>
                    <span>
                      <ActionButton multi 
                      onClick={() => {
                        this.setState({
                          showPrevNextPopup: false
                        });
                        //window.location.replace(`/#/managefields/${selectedFieldId}/manure`);
                        //window.location.reload();
                      }}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showYieldGoalNullPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showYieldGoalNullPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_GOAL_NULL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span className="button-style-padding-right">
                      <ActionButton onClick={() => this.saveNullPopUp()}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                    </span>
                    <span>
                      <ActionButton multi onClick={() => this.closeYieldGoalPopUp()}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showHarvestNullPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showHarvestNullPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'HARVEST_NULL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span className="button-style-padding-right">
                      <ActionButton onClick={() => this.saveNullPopUp()}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                    </span>
                    <span>
                      <ActionButton multi onClick={() => this.closeYieldGoalPopUp()}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showHarvestPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showHarvestPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_HARVEST_MODAL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span>
                      <ActionButton multi onClick={() => this.closeHarvestPopUp()}>{intl.formatMessage({ id: 'OK' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showYieldGoalPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showYieldGoalPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_GOAL_MODAL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span>
                      <ActionButton multi onClick={() => this.closeYieldGoalPopUp()}>{intl.formatMessage({ id: 'OK' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}</div>}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  fieldDetails: state.fieldDetails,
  activeFarm: state.farmList.activeFarm,
  mapCoords: state.fieldDetails.savedFieldCoordinates,
  fieldFormValues: state.form.FieldsForm,
  lblCommon: state.masterData.data[0]?.commonLabels,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'fieldPage']),
  userDetails: state.userDetails,
  manureNames: state.manureNames,
  fieldCoords: state.fieldDetails.savedFieldCoordinates,
  hasSupportAdminRole: state.SupportAdminRole,
  fieldDetailsData: state.fieldDetails,
  seasons: state.seasons,
  fieldMethod: state.fieldMethod,
  fieldMasterData: state.fieldMasterData,
  yieldPlan: state.yieldPlan,
  historicalData: state.historicalData,
  manureStorage: state.manureStorage,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
    seasonsList: () => dispatch(Seasons.seasonsList()),
    fieldMethodList: () => dispatch(FieldMethod.fieldMethodList()),
    getFieldCrops: () => dispatch(FieldMasterData.getFieldCrops()),
    getPreviousFieldCrops: () => dispatch(FieldMasterData.getPreviousFieldCrops()),
    addUpdateManureField: (data, farmId) => dispatch(fieldActions.addUpdateManureField(data, farmId)),
    getManureNames: farmId => dispatch(fetchManureNames(farmId)),
    yieldCalculation: (data) => dispatch(YieldPlan.YieldCalculation(data)),
    historicalDataCalculation: (farmId, fieldId) => dispatch(HistoricalData.getHistoricalData(farmId, fieldId)),
    clearMessages: () => dispatch(fieldActions.clearMessages()),
    fetchManureType: () => dispatch(ManureAction.fetchManureType()),
    createManure: (farmId, values) => dispatch(ManureAction.createManure(farmId, values))
  };
}

export default injectIntl(
  reduxForm({
    form: 'ManureForm'
  })(connect(mapStateToProps, mapDispatchToProps)(Manure))
);
 