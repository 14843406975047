import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material'

const boxStyle = { backgroundColor: "#ffffff", padding: "10px", borderRadius: "8px" }
const textStyle = { fontFamily: "Inter", fontWeight: "500", fontSize: "14px", color: "#191C1C" }
const textNumberStyle = { ...textStyle, fontWeight: "400", fontSize: "16px", textAlign: "center" }
const textUnitStyle = { ...textStyle, fontWeight: "400", color: "#3F4949", textAlign: "center" }

const FertilizerManure = ({ fertilizationPlan, intl }) => (
  <div className="fertilization-blue-header-mui">
    <Box style={boxStyle}>
      <Grid container justifyContent="flex-end" sx={{ padding: "20px" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography style={textStyle}>{intl.formatMessage({ id: 'MANURE' })}</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" sx={{ paddingBottom: "30px" }}>
        <Grid item lg={3} md={3} sm={0} xs={12}>&nbsp;</Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Typography style={textNumberStyle}>
            {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && Math.round(fertilizationPlan.data.manurePlanSummary.manureQuantity)}{' '}
            {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && fertilizationPlan.data.manurePlanSummary.manureUnits}
          </Typography>
          <Typography style={textUnitStyle}>{intl.formatMessage({ id: 'PLANNED' })}</Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Typography style={textNumberStyle}>
            {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && Math.round(fertilizationPlan.data.manurePlanSummary.storageCapacity)}{' '}
            {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && fertilizationPlan.data.manurePlanSummary.storageCapacityUnits}
          </Typography>
          <Typography style={textUnitStyle}>{intl.formatMessage({ id: 'MANURE_STORAGE_YEARLY_PRODUCTION_NO_UNIT' })}</Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Typography style={textNumberStyle}>
            {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && Math.round(fertilizationPlan.data.manurePlanSummary.capacityDifference)}{' '}
            {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && fertilizationPlan.data.manurePlanSummary.capacityDifferenceUnits}
          </Typography>
          <Typography style={textUnitStyle}>{intl.formatMessage({ id: 'DIFFERENCE' })}</Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={0} xs={12}>&nbsp;</Grid>
      </Grid>
    </Box>
  </div>
);

export default FertilizerManure;