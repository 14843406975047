import {
  SEASONS_LOADING,
  SEASONS_SUCCESS,
  SEASONS_FAILURE
} from 'constants/store/Tasks';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  seasonsList: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SEASONS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case SEASONS_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case SEASONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        seasonsList: action.payload
      };
    default:
      return state;
  }
}
