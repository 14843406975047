export const insufficientTextTitle = {
  paddingTop: "50px", 
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: "500",
  color: "#191C1C"
};
export const insufficientTextTitleLarge = {
  fontFamily: "Inter",
  fontSize: "32px",
  fontWeight: "400",
  color: "#191C1C"
};
export const insufficientTextSubTitle = {
  paddingTop: "20px",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  color: "#3F4949"
};
export const insufficientTextSubTitleLarge = {
  paddingTop: "20px",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "400",
  color: "#191C1C"
};
export const buttonStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#00696D',
  padding: '10px',
  background: 'transparent'
};
export const buttonRoundStyle = {
  fontFamily: 'Inter',
  height: '40px',
  padding: '10px 24px 10px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '100px',
  border: '1px solid var(--M3-sys-light-outline, #6F7979)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px' 
};
export const buttonRedStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#C85F5E',
  padding: '10px',
  background: 'transparent'
};
export const buttonGreenStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#00696D',
  padding: '10px',
  background: 'transparent',
  cursor: 'pointer'
};
export const buttonFilledStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#ffffff',
  padding: '10px',
  backgroundColor: '#00696D'
};
export const insufficientIcon = {
  paddingTop: "20px"
};
export const insufficientLink = {
  paddingTop: "25px",
  paddingBottom: "25px"
};
export const noFertilizationLink = {
  paddingTop: "15px",
  paddingBottom: "25px"
};