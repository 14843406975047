import * as React from 'react';
import Numeral from '@tine/lib-frontend-components/elements/typography/numeral';

const MineralFertilizerHeader = ({ mineralFertilizerItem, viewFertilizerPlan }) => (
  <div className="mineral-fertilizer-box">
    <div className="mineral-fertilizer-grid-height">
      <div>
        <span className="mineral-fertilizer-text-bold">
          {mineralFertilizerItem.customName === null
            ? mineralFertilizerItem.originalName
            : mineralFertilizerItem.customName}
        </span>
        {/*{!viewFertilizerPlan && (
          <span className="mineral-fertilizer-text-right">
            <i className="icon-edit mineral-fertilizer-edit-border"></i>
          </span>
        )}*/}
      </div>
    </div>
    <div className="mineral-fertilizer-grid mineral-fertilizer-grid-height">
      <div className="mineral-fertilizer-grid-div">
        <Numeral size="normal">
          {Math.round(mineralFertilizerItem.quantity)} {mineralFertilizerItem.quantityUnits}
        </Numeral>
      </div>
    </div>
  </div>
);

export default MineralFertilizerHeader;