/* eslint-disable react/jsx-props-no-spreading */
/**
 * Textbox Control
 * require @props {label, placeholdertext, errorMessage, controlId, type}
 */
import React from 'react';

import Input from '@tine/lib-frontend-components/components/inputs/input';

const TextBoxControl = ({
  input,
  label,
  pattern,
  controlId,
  placeholdertext,
  stlyeClass,
  disabled,
  required,
  type,
  units,
  controlledValue,
  meta: { touched, error },
  disableValid
}) => {
  const valid = touched ? !(touched && error) : null;
  const inputProps = {};
  if (controlledValue) {
    inputProps.value = controlledValue;
  }
  return (
    <div className={`form-group ${disabled && 'disable-input'}`}>
      <Input
        {...input}
        type={type}
        className={stlyeClass}
        id={controlId}
        placeholder={placeholdertext}
        disabled={disabled}
        unit={units}
        required={required}
        labelText={label}
        pattern={pattern}
        errorMessage={error}
        valid={disableValid ? null : valid}
        {...inputProps}
      />
    </div>
  );
};

export default TextBoxControl;
