import React from 'react';
import { Chart } from "react-google-charts";

var data = [
  [
    { type: 'string', label: 'Dato' },
    'Temperature sum',
    'Temperature day',
  ],
  ['4.30.2017', 0, 4.0], ['5.1.2017', 50, 4.5], ['5.2.2017', 70, 5.0], ['5.3.2017', 90, 5.2], ['5.4.2017', 100, 6.5],
  ['5.5.2017', 120, 7.0], ['5.6.2017', 140, 7.5], ['5.7.2017', 150, 8.0],  ['5.8.2017', 175, 7.5], ['5.9.2017', 190, 7.2],
  ['5.10.2017', 210, 6.9], ['5.11.2017', 250, 6.5], ['5.12.2017', 270, 6.1],  ['5.13.2017', 290, 5.9], ['5.14.2017', 310, 5.5],
  ['5.15.2017', 330, 5.2], ['5.16.2017', 350, 5.0], ['5.17.2017', 370, 4.7],  ['5.18.2017', 410, 4.5], ['5.19.2017', 430, 4.1],
  ['5.20.2017', 445, 3.5], ['5.21.2017', 465, 4.0], ['5.22.2017', 480, 4.2],  ['5.23.2017', 520, 4.5], ['5.24.2017', 550, 4.8],
  ['5.25.2017', 610, 5.2], ['5.26.2017', 650, 5.5], ['5.27.2017', 690, 5.2],  ['5.28.2017', 720, 4.8], ['5.29.2017', 780, 4.5],
  ['5.30.2017', 810, 4.4], ['5.31.2017', 840, 4.2], ['6.1.2017', 860, 3.9],  ['6.2.2017', 920, 3.4], ['6.3.2017', 980, 3.0],
  ['6.4.2017', 1030, 2.8],  ['6.5.2017', 1100, 3.1], ['6.6.2017', 1140, 3.3], ['6.7.2017', 1200, 3.6],  ['6.8.2017', 1240, 3.9],
  ['6.9.2017', 1310, 4.3],  ['6.10.2017', 1350, 4.7], ['6.11.2017', 1380, 4.9], ['6.12.2017', 1410, 5.2],  ['6.13.2017', 1455, 5.4],
  ['6.14.2017', 1690, 5.1],  ['6.15.2017', 1720, 5.5], ['6.16.2017', 1765, 5.8], ['6.17.2017', 1795, 6.3],  ['6.18.2017', 1810, 6.5],
  ['6.19.2017', 1820, 6.9],  ['6.20.2017', 1840, 7.2], ['6.21.2017', 1870, 7.6], ['6.22.2017', 1890, 7.9],  ['6.23.2017', 1910, 8.2],
  ['6.24.2017', 1915, 8.5],  ['6.25.2017', 1945, 8.8], ['6.26.2017', 1975, 8.4], ['6.27.2017', 2000, 8.1],  ['6.28.2017', 2030, 7.7],
  ['6.29.2017', 2075, 7.4],  ['6.30.2017', 2105, 7.2], ['7.1.2017', 2140, 6.9], ['7.2.2017', 2175, 6.6],  ['7.3.2017', 2200, 6.3]
];
var options = {
  chart: {
    title:
      '2017. Temperature and feed quality. Lodviken',
  },
  width: 1000,
  height: 500,
  series: {
    // Gives each series an axis name that matches the Y-axis below.
    0: { axis: 'Temperature', color: '#0356fc' },
    1: { axis: 'AverageTemperature', color: '#fc9403' },
  },
  axes: {
    // Adds labels to each axis; they don't have to match the axis names.
    y: {
      Temperature: { label: 'Temperature sum' },
      AverageTemperature: { label: 'Average daily Temperature' },
    },
  },
  gridlines: {
    color: 'none'
}
};

const ReactGraph = () => (
  <Chart
    width={'100%'}
    height={'500'}
    chartType="Line"
    loader={<div>Loading Chart</div>}
    data={data}
    options={options}
    rootProps={{ 'data-testid': '1' }}
  />
);

export default ReactGraph;
