import * as React from 'react';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';

export default ({ labels, values, typeName, intl }) => (
  <SectionContainer
    styling={{
      backgroundColor: 'history'
    }}
  >
    <div className="manure-storage-calc__data --list-content">
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.manureType}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>{typeName}</EmphasisLight>
        </p>
      </div>
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{intl.formatMessage({ id: 'MANURE_STORAGE_YEARLY_PRODUCTION' })}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.yearlyProduction} {labels.storageUnit}
          </EmphasisLight>
        </p>
      </div>
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.storageCapacity}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.storageCapacity} {labels.storageUnit}
          </EmphasisLight>
        </p>
      </div>
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.dryMatter}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.dryMatter} {labels.dryMatterUnit}
          </EmphasisLight>
        </p>
      </div>
    </div>
    <div className="divider" />

    <div className="manure-storage-calc__data --list-content">
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.nitrogen} </MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.nitrogen} {labels.kgTonUnit}
          </EmphasisLight>
        </p>
      </div>
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.ammmonium}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.ammonium} {labels.kgTonUnit}
          </EmphasisLight>
        </p>
      </div>
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.phosphorus}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.phosphorus} {labels.kgTonUnit}
          </EmphasisLight>
        </p>
      </div>
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.potassium}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.potassium} {labels.kgTonUnit}
          </EmphasisLight>
        </p>
      </div>
      <div className="manure-storage__calculated-value">
        <p className="manure-storage_calc-label">
          <MetaInformation size="small">{labels.sulphur}</MetaInformation>
        </p>
        <p>
          <EmphasisLight>
            {values.sulfur} {labels.kgTonUnit}
          </EmphasisLight>
        </p>
      </div>
    </div>
  </SectionContainer>
);
