import {
  GET_SOUND_FILES_IS_LOADING,
  GET_SOUND_FILES_SUCCESS,
  GET_SOUND_FILES_FAILURE,
  SOUND_FOLDER
} from 'constants/index';

const getSoundFilesSuccess = data => ({
  type: GET_SOUND_FILES_SUCCESS,
  data
});

const getSoundFilesFaliure = (bool, error) => ({
  type: GET_SOUND_FILES_FAILURE,
  hasError: bool,
  errorMessage: error
});

const getSoundFilesIsLoading = bool => ({
  type: GET_SOUND_FILES_IS_LOADING,
  isLoading: bool
});

/**
 * To get sound files list from AWS
 * @param {awsLogin, taskId}
 */
export default (awsLogin, taskId) => async dispatch => {
  try {
    const { AWS } = window;
    AWS.config.region = awsLogin.s3Region;
    AWS.config.update({
      accessKeyId: awsLogin.accessKey,
      secretAccessKey: awsLogin.secretKey,
      sessionToken: awsLogin.sessionToken
    });
    const bucket = new AWS.S3({ params: { Bucket: awsLogin.bucketName } });
    dispatch(getSoundFilesIsLoading(true));
    const params = {
      Bucket: awsLogin.bucketName,
      Prefix: `${SOUND_FOLDER}${taskId}`
    };
    await bucket.listObjects(params, (err, data) => {
      if (err) {
        dispatch(getSoundFilesFaliure(true, err));
      } else {
        dispatch(getSoundFilesSuccess(data));
      }
    });
  } catch (error) {
    dispatch(getSoundFilesFaliure(true, error));
  }
};
