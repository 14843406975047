/**
 * Data validation index component has tab module to switch between the settings componenets
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import getSoundFiles from 'actions/SoundFiles';
import DataInfo from 'containers/datavalidation/DataInfo';
import Loader from 'components/Loader';
import { GetInbetweenString, FormatBytes, GetDayTimeDiff } from 'utils/index';

class SoundData extends Component {
  state = {
    expectedCount: 0,
    task: null
  };

  // on farm change
  componentDidMount = () => {
    this.getDetails();
  };

  componentDidUpdate(prevProps) {
    const { selectedTask } = this.props;
    if (prevProps.selectedTask !== selectedTask && selectedTask) {
      this.getDetails();
    }
  }

  getDetails = () => {
    const { requestSoundFiles, AWSLoginDetails, tasks, selectedTask } = this.props;
    this.setState({
      expectedCount: 0
    });
    if (tasks.length > 0 && selectedTask) {
      const [infoData] = _.filter(tasks, ['task.id', selectedTask.value]);
      if (infoData.task.startTime && infoData.task.endTime) {
        const duration = GetDayTimeDiff(
          new Date(infoData.task.startTime),
          new Date(infoData.task.endTime)
        );
        if (duration.minutes < 7000 * 60) {
          this.setState({
            expectedCount: this.getExpectedFileCount(duration.minutes)
          });
        } else {
          this.setState({
            expectedCount: this.getExpectedFileCount(7000 * 60)
          });
        }
      }
      this.setState({
        task: infoData
      });
      requestSoundFiles(AWSLoginDetails, infoData.task.id);
    }
  };

  // to get expected file count
  getExpectedFileCount = time => {
    let fileCount = 0;
    const duration = time * 60;
    const initTime = 45 + 5.4613;
    const getCount = (x, d) => {
      let y = x;
      let delayDuration = d;
      if (y >= duration) {
        return fileCount;
      }
      delayDuration += 2 * Math.log10(45);
      y = y + delayDuration + 5.4613;
      fileCount += 1;
      return getCount(y, delayDuration);
    };
    if (duration > initTime) {
      getCount(initTime, initTime);
    } else {
      fileCount = 0;
    }
    return fileCount;
  };

  // to get sound file list
  getSoundFileList = () => {
    const { soundFiles } = this.props;
    let list;
    if (soundFiles.data && soundFiles.data.Contents.length > 0) {
      list = soundFiles.data.Contents.map(data => {
        const lastModified = JSON.stringify(data.LastModified);
        return (
          <tr className="soud-file-list" key={`${data.Key}`}>
            <td>
              <span className="dv-value">{GetInbetweenString(data.Key, '/', '"')}</span>
            </td>
            <td>
              <span className="dv-value">{FormatBytes(data.Size)}</span>
            </td>
            <td>
              <span className="dv-value">
                {`${lastModified.substr(1, 10)} ${lastModified.substr(12, 8)}`}
              </span>
            </td>
          </tr>
        );
      });
      return list;
    }
    return (
      <tr className="soud-file-list">
        <td colSpan="3">
          <span className="dv-value">
            <EmphasisLight>No files</EmphasisLight>
          </span>
        </td>
      </tr>
    );
  };

  render() {
    const { soundFiles, selectedTask } = this.props;
    const { expectedCount, task } = this.state;
    const actualCount =
      soundFiles.data && soundFiles.data.Contents ? soundFiles.data.Contents.length : 0;
    return (
      <SectionContainer
        styling={{
          paddingAllSides: 'none'
        }}
      >
        <SectionContainer
          styling={{
            paddingAllSides: 'none',
            paddingLeft: 'low',
            paddingRight: 'low'
          }}
        >
          <DataInfo
            taskDetails={task}
            selectedTask={selectedTask}
            expectedCount={expectedCount}
            actualCount={actualCount}
          />
        </SectionContainer>
        <div className="map-container">
          <SectionContainer
            styling={{
              paddingAllSides: 'none',
              paddingLeft: 'low',
              paddingRight: 'low'
            }}
          >
            <div className="sound-data">
              {!soundFiles || soundFiles.isLoading ? <Loader styleName="center-align" /> : null}
              <table className="filellsit">
                <thead>
                  <tr>
                    <th>
                      <EmphasisLight>File name</EmphasisLight>
                    </th>
                    <th>
                      <EmphasisLight>size</EmphasisLight>
                    </th>
                    <th>
                      <EmphasisLight>Last modified</EmphasisLight>
                    </th>
                  </tr>
                </thead>
                <tbody>{soundFiles && soundFiles.data ? this.getSoundFileList() : null}</tbody>
              </table>
            </div>
          </SectionContainer>
        </div>
      </SectionContainer>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails.data,
  AWSLoginDetails: state.AWSLogin.data,
  soundFiles: state.SoundFiles
});

const mapDispatchToProps = dispatch => ({
  requestSoundFiles: (awsLogin, taskId) => dispatch(getSoundFiles(awsLogin, taskId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SoundData);
