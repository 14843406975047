import { TASK } from 'constants/ApiUrl';
import { 
  SEASONS_LOADING, 
  SEASONS_SUCCESS, 
  SEASONS_FAILURE
} from 'constants/store/Tasks';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Get all the mineral fertilizer
 */
export const seasonsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SEASONS_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${TASK}/seasons?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: SEASONS_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: SEASONS_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: SEASONS_FAILURE, payload: error });
    }
};