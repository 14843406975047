import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Box, Grid, Typography, Chip, Menu, MenuItem, Select, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getDropDownMineralFertilizers, getDropDownManureStorages } from 'utils/index';
import generatePdfDocument from 'mui-components/fields/fertilization/FertilizerPlanPdf';
import generateSeasonPdfDocument1 from 'mui-components/fields/fertilization/FertilizerPlanSeasonPdf1';
import generateSeasonPdfDocument2 from 'mui-components/fields/fertilization/FertilizerPlanSeasonPdf2';
import generateSeasonPdfDocument3 from 'mui-components/fields/fertilization/FertilizerPlanSeasonPdf3';
import generateSeasonPdfDocument4 from 'mui-components/fields/fertilization/FertilizerPlanSeasonPdf4';
import LoaderDynamic from 'components/LoaderDynamic';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import CreateFertilizationNewPlan from 'mui-components/fields/fertilization/CreateFertilizationNewPlan';

import FertilizerSaveModal from 'mui-components/fields/fertilization/FertilizerSaveModal';
import * as FertilizerAction from 'actions/FertilizationPlan';
import * as MineralFertilizer from 'actions/MineralFertilizer';
import FertilizerManure from 'mui-components/fields/fertilization/FertilizerManure';
import MineralFertilizerHeader from 'mui-components/fields/fertilization/MineralFertilizerHeader';
import FertilizerPlanListHeader from 'mui-components/fields/fertilization/FertilizerPlanListHeader';
import FertilizerPlanForField from 'mui-components/fields/fertilization/FertilizerPlanForField';
import FertilizerPlanForSeason from 'mui-components/fields/fertilization/FertilizerPlanForSeason';
import Loader from 'components/Loader';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import MineralFertilizerUpdateModal from 'mui-components/fields/fertilization/MineralFertilizerUpdateModal';
import FertilizerModal from 'mui-components/fields/fertilization/FertilizerModal';
import mixpanel from 'mixpanel-browser';
import { SAVE_PLAN, CHANGE_MANURE_AMOUNT, CHANGE_MINERAL_AMOUNT, CHANGE_MINERAL_TYPE_COMPLETE,
  UPDATE_FP_PLANNING, UPDATE_FP_RECALCULATE, EDIT_PLAN, EXPORT_PLAN, CHANGE_MF_FARM, 
  DELETE_MINERAL_TYPE_COMPLETE, CHANGE_MANURE } from 'constants/MixPanel';
import {
  FILTER_TASK_STATUS,
  FILTER_TASK_ACTIVITY,
  FILTER_TASK_FIELD
} from 'constants/index';
import { SetSessionData } from 'utils';
import {
  taskListSortByOption
} from 'actions/Tasks';
import { TASK_PERIOD_VALUE } from 'constants/store/Tasks';

import { DeleteIcon, ErrorIcon } from 'components/mui-forms/MuiIcons';
import {
  insufficientTextTitle, insufficientTextSubTitle, buttonStyle, buttonFilledStyle, insufficientIcon, insufficientLink, noFertilizationLink
} from 'mui-components/fields/fertilization/services/fertilizationStyles';

class FertilizationIndex extends React.Component {
  constructor(props) {
    super(props);
    this.updateSeasonManure = this.updateSeasonManure.bind(this);
    this.updateSeasonFertilizer = this.updateSeasonFertilizer.bind(this);
    this.updateMineralFertilizer = this.updateMineralFertilizer.bind(this);
    this.addMineralFertilizer = this.addMineralFertilizer.bind(this);
    this.deleteMineralFertilizer = this.deleteMineralFertilizer.bind(this);
    this.closeEditMineralFertilizer = this.closeEditMineralFertilizer.bind(this);
    this.setMineralFertilizerState = this.setMineralFertilizerState.bind(this);
    this.saveMineralFertilizer = this.saveMineralFertilizer.bind(this);
    this.addNewMineralFertilizer = this.addNewMineralFertilizer.bind(this);
    this.closeCreateTaskModel=this.closeCreateTaskModel.bind(this);
    this.closeCreateTaskSaveModel=this.closeCreateTaskSaveModel.bind(this);
    this.createTaskModel=this.createTaskModel.bind(this);
    this.updateCompleted=this.updateCompleted.bind(this);
    this.goToTaskPage=this.goToTaskPage.bind(this);
    this.handleExpandNeed=this.handleExpandNeed.bind(this);
    this.handleExpandActual=this.handleExpandActual.bind(this);
    this.handleExpandAdded=this.handleExpandAdded.bind(this);
    this.handleExpandSeasonNeed=this.handleExpandSeasonNeed.bind(this);
    this.handleExpandSeasonAdded=this.handleExpandSeasonAdded.bind(this);
    this.handleExpandActualAdded=this.handleExpandActualAdded.bind(this);
    this.handleMenuClick=this.handleMenuClick.bind(this);
    this.handleMenuClose=this.handleMenuClose.bind(this);
    this.updateMineralFertilizerMenu=this.updateMineralFertilizerMenu.bind(this);
    this.updateManureStorageMenu=this.updateManureStorageMenu.bind(this);
    this.cancelFertilizationUpdate=this.cancelFertilizationUpdate.bind(this);
    this.saveFertilizationUpdate=this.saveFertilizationUpdate.bind(this);
    this.onChangeMineralizedFertilizer=this.onChangeMineralizedFertilizer.bind(this);
    this.handleListFieldsClick=this.handleListFieldsClick.bind(this);
    this.handleCheckboxAll=this.handleCheckboxAll.bind(this);
    this.handleCheckbox=this.handleCheckbox.bind(this);
    this.closeDisplayErrorFields=this.closeDisplayErrorFields.bind(this);
    this.saveChangeName=this.saveChangeName.bind(this);
    this.cancelChangeName=this.cancelChangeName.bind(this);
    this.onChangingPlanName=this.onChangingPlanName.bind(this);
    this.noVersionDelete=this.noVersionDelete.bind(this);
    this.yesVersionDelete=this.yesVersionDelete.bind(this);
    this.noPlanDelete=this.noPlanDelete.bind(this);
    this.yesPlanDelete=this.yesPlanDelete.bind(this);
    this.closeVersionDialog=this.closeVersionDialog.bind(this);
    this.onClickVersionChange=this.onClickVersionChange.bind(this);
    this.onCloseOpenInsufficientItemsLink=this.onCloseOpenInsufficientItemsLink.bind(this);
    this.onClickOpenInsufficientItemsLink=this.onClickOpenInsufficientItemsLink.bind(this);
    this.redirectFieldLink=this.redirectFieldLink.bind(this);
    this.closeExportModal=this.closeExportModal.bind(this);
    this.onChangeExportHarvest=this.onChangeExportHarvest.bind(this);
    this.generateSeasonPdfFertilizerPlan=this.generateSeasonPdfFertilizerPlan.bind(this);
    this.state= {
      anchorEl: false,
      anchorE2: false,
      open: true,
      editMineralFertilizer: false,
      viewFertilizerPlan: false,
      mineralFertilizerValues: null,
      selectedMineralFertilizer: null,
      editMineralFertilizerValue: 'add',
      mineralFertilizerList: [],
      addingFertilizer: [],
      addingFertilizerLeft: [],
      addMineral: false,
      fertilizerCount: 0,
      totalFertilizerCount: 0,
      createTaskSave: false,
      createTaskSaveConfirm: false,
      createTaskPopup: false,
      taskPage: false,
      openItems: [],
      openFieldItems: [],
      openInsufficientItems: [],
      expandedColumnNeed: [],
      expandedColumnActual: [],
      expandedColumnAdded: [],
      expandedSeasonColumnNeed: [],
      expandedSeasonColumnAdded: [],
      expandedActualColumnAdded: [],
      editFertilizationAction: false,
      editFertilizationActionData: false,
      defaultYearValue: null,
      yearMenuOptions: [],
      updateFertilizationPlanDialogShow: false,
      dropDownChange: false,
      mineralizedFertilizer: false,
      listFields: [],
      selectAllFields: false,
      selectedTotalFieldLength: 0,
      checkedItems: new Map(),
      generatePdf: false,
      generateSeasonPdf: false,
      openGeneratePdf: false,
      confirmDialog: false,
      processLoading: false,
      keepExisting: false,
      timerCount: 0,
      timerErrorValue: false,
      displayErrorFields: false,
      draftedVersion: null,
      draftedPlanName: null,
      draftedYear: null,
      disablePlanUpdateButton: true,
      createFertilizer: false,
      createTask: false,
      updateTask: false,
      createTaskConfirm: false,
      createNewFertilizer: false,
      createValue: false,
      confirmErrorMessage: null,
      viewFertilizer: false,
      selectedYear: null,
      updateDataValue: null,
      mineralCalculationOn: false,
      mineralCalculationOnUpdate: false,
      updateYearOptions: false,
      showChangeNameDialog: false,
      disableChangeName: true,
      versionNameNew: "",
      versionDeleteDialog: false,
      planDeleteDialog: false,
      versionNameNewDisplay: "",
      openVersionDialog: false,
      selectedYearJson: null,
      openInsufficientItemsLink: false,
      selectedField: '',
      selectedMenuFieldId:null,
      stateFertilizationHarvest: false,
      stateFirstHarvest: false,
      stateSecondHarvest: false,
      stateThirdHarvest: false,
      showReportError: false
    }
    this.seasonTimer = null;
    this.manureTimer = null;
  }

  componentDidMount = () => {
    const { fertilizationPlan, match, fertilizationPlanList, activeFarm, mineralFertilizerList } = this.props;
    fertilizationPlan.planList = null;
    fertilizationPlan.isSuccess = false;
    fertilizationPlan.data = null;
    fertilizationPlan.hasError = false;
    fertilizationPlanList(activeFarm.farm.id);
    mineralFertilizerList(activeFarm.farm.id, new Date().getFullYear());

    const newOpenItems = [...this.state.openItems];
    newOpenItems[0] = true;
    this.setState({ openItems: newOpenItems });
    if (match.params.id === 'view') {
      this.setState({ viewFertilizerPlan: true });
    }    
  };

  componentDidUpdate(prevProps, prevState) {
    const { history, activeFarm, fertilizationPlan, editFertilizationPlan, mineralFertilizerList, userDetails, getCreatedFertilizationPlan,
      intl } = this.props;
    const { editFertilizationAction, editFertilizationActionData, generatePdf, generateSeasonPdf, confirmDialog, displayErrorFields, processLoading,
      keepExisting, timerCount, timerErrorValue, updateYearOptions, createTaskConfirm, createTaskPopup } = this.state;
    const {
      fertilizerPlanAvailable,
      data,
      hasError,
      errorMessage,
      isSuccess,
      isCreateSuccess,
      creatingData,
      hasReportError,
      isReportLoading,
      isReportSuccess,
      isCreatePlanSuccess,
      reportData,
    } = fertilizationPlan;
    if (!activeFarm) {
      history.push('/settings');
    }
    if(fertilizationPlan.createTaskData && fertilizationPlan.createTaskData !== null && !createTaskConfirm && createTaskPopup) {
      this.setState({
        createTaskSave: false,
        createTaskConfirm: true
      });
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/managefields/fertilization');
      window.location.reload();
    }
    if(fertilizationPlan.planList !== null && !editFertilizationAction && fertilizationPlan.planList.fertilizerPlanMap !== null) {
      const orderList = _.orderBy(Object.entries(fertilizationPlan.planList.fertilizerPlanMap), [fertilizerPlanMapList => fertilizerPlanMapList], ['desc']);
      let yearDropdownArray = [];
      let yearValue = "";
      orderList.map((yearList, index) => (
        //eslint-disable-next-line
        yearValue === "" ? yearValue = yearList[0] : '', 
        yearDropdownArray.push({value: yearList[1][0].planName+"~"+yearList[0]+"~"+yearList[1][0].version, label: yearList[0]})
      ));
      mixpanel.identify(userDetails.id);
      mixpanel.track(EDIT_PLAN);
      this.setState({
        editFertilizationAction: true,
        defaultYearValue: yearDropdownArray[0].value,
        yearMenuOptions: yearDropdownArray
      });
      mineralFertilizerList(activeFarm.farm.id, yearValue);
      editFertilizationPlan(activeFarm.farm.id, orderList[0][1][0].planName, orderList[0][1][0].year, orderList[0][1][0].version);
    }
    if(fertilizationPlan.data && !updateYearOptions && isCreatePlanSuccess) {
      this.setState ({
        updateYearOptions: true
      });
    }    
    if(fertilizationPlan.data && fertilizationPlan.data !== null && fertilizationPlan.data.year !== null && updateYearOptions) {
      if(fertilizationPlan.planList !== null && fertilizationPlan.planList.fertilizerPlanMap !== null) {
        const orderList = _.orderBy(Object.entries(fertilizationPlan.planList.fertilizerPlanMap), [fertilizerPlanMapList => fertilizerPlanMapList], ['desc']);
        if(fertilizationPlan.data.year !== null && (parseInt(fertilizationPlan.data.year) !== parseInt(orderList[0][0]))) {
          let yearDropdownArray = [];
          yearDropdownArray.push({value: fertilizationPlan.data.year, label: fertilizationPlan.data.year});
          orderList.map((yearList, index) => (
            yearDropdownArray.push({value: yearList[1][0].planName+"~"+yearList[0]+"~"+yearList[1][0].version, label: yearList[0]})
          ));
          this.setState ({
            updateYearOptions: false,
            yearMenuOptions: yearDropdownArray,
            defaultYearValue: fertilizationPlan.data.year
          });
          fertilizationPlan.isCreatePlanSuccess = false;
        }
      } else {
        let yearDropdownArray = [];
        yearDropdownArray.push({value: fertilizationPlan.data.year, label: fertilizationPlan.data.year});
        this.setState ({
          updateYearOptions: false,
          yearMenuOptions: yearDropdownArray,
          defaultYearValue: fertilizationPlan.data.year
        });
        fertilizationPlan.isCreatePlanSuccess = false;
      }
    }
    if(fertilizationPlan.data && !editFertilizationActionData) {
      let keyPair = {};
      let keyPair1 = {};
      let totalCount = 0;
      _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
        fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
          mineralFertilizerItem.consolidatedMineralFertilizersForSeason ? mineralFertilizerItem.consolidatedMineralFertilizersForSeason.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            totalCount = totalCount + 1,
            keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.plannedQuantity === null ? 0 : seasonFertilizer.plannedQuantity.toFixed(1)
          )) :  mineralFertilizerItem.mineralFertilizersForSeason.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            totalCount = totalCount + 1,
            keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
          ))
        ))
      ));
      _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
        fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
          mineralFertilizerItem.mineralFertilizersForSeasonLeft && mineralFertilizerItem.mineralFertilizersForSeasonLeft.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            keyPair1[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
          ))
        ))
      ));
      this.setState({
        addingFertilizer: keyPair,
        addingFertilizerLeft: keyPair1,
        fertilizerCount: totalCount,
        mineralizedFertilizer: fertilizationPlan.data.mineralized,
        editFertilizationActionData: true
      });
      if(this.state.draftedVersion === null) {
        this.setState({
          draftedVersion: fertilizationPlan.data.version,
          draftedPlanName: fertilizationPlan.data.planName,
          draftedYear: fertilizationPlan.data.year
        });
      }
    }
    if(fertilizationPlan.data && (prevState.addingFertilizer.length !== 0)) {
      let keyPair = {};
      let totalCount = 0;
      _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
        fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
          mineralFertilizerItem.consolidatedMineralFertilizersForSeason ? mineralFertilizerItem.consolidatedMineralFertilizersForSeason.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            totalCount = totalCount + 1,
            keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.plannedQuantity === null ? 0 : seasonFertilizer.plannedQuantity.toFixed(1)
          )) :  mineralFertilizerItem.mineralFertilizersForSeason.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            totalCount = totalCount + 1,
            keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
          ))
        ))
      ));
      if (JSON.stringify(keyPair) !== JSON.stringify(this.state.addingFertilizer) && this.state.dropDownChange) {
        this.setState({
          addingFertilizer: keyPair,
          fertilizerCount: totalCount
        })
      }
    }
    if(fertilizationPlan.data && (prevState.addingFertilizerLeft.length !== 0)) {
      let keyPair1 = {};
      _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
        fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
          mineralFertilizerItem.mineralFertilizersForSeasonLeft && mineralFertilizerItem.mineralFertilizersForSeasonLeft.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            keyPair1[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
          ))
        ))
      ));
      if (JSON.stringify(keyPair1) !== JSON.stringify(this.state.addingFertilizerLeft) && this.state.dropDownChange) {
        this.setState({
          addingFertilizerLeft: keyPair1
        })
      }
    }
    if (!hasError && fertilizerPlanAvailable && data === 1) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: true,
        confirmDialog: false,
        processLoading: false,
        displayErrorFields: false
      });
    }
    if (!hasError && fertilizerPlanAvailable && data === 2) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        processLoading: false,
        displayErrorFields: false
      });
      this.getInitialize();
    }
    if (!confirmDialog && hasError && errorMessage?.status === 409) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: true,
        processLoading: false,
        confirmErrorMessage: errorMessage?.data,
        displayErrorFields: false
      });
    }
    if (!displayErrorFields && hasError && errorMessage?.status === 422) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        processLoading: false,
        displayErrorFields: true
      });
    }
    if (
      !hasError &&
      isCreateSuccess &&
      !processLoading &&
      creatingData.farmId === null &&
      !keepExisting && 
      timerCount === 0 &&
      data !== "timerError"
    ) {
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        processLoading: true,
        displayErrorFields: false,
        timerCount: new Date().getTime()+180000
      });
    }
    if (isSuccess && data !== '' && data !== null && data !== "timerError" && data.farmId != null && !keepExisting) {
      fertilizationPlan.isCreateSuccess=false;
      fertilizationPlan.isSuccess=false;
      this.setState({
        processLoading: false,
        timerCount: 0,
        editFertilizationActionData: false
      });
      //fertilizationPlanList(activeFarm.farm.id);
      clearTimeout(this.interval);
    }
    if (hasError && errorMessage?.status === 404 && processLoading) {
      this.setState({
        processLoading: false,
        updateTask: false,
        timerCount: 0
      });
      clearTimeout(this.interval);
      this.getInitialize();
    }
    if (isSuccess && data !== null && data === "timerError" && !timerErrorValue) {
      this.setState({
        processLoading: false,
        timerErrorValue: true,
        updateTask: false
      });
    }
    if(processLoading && hasError && fertilizationPlan && fertilizationPlan.errorMessage !== null && fertilizationPlan.errorMessage.data?.length > 0) {
      this.setState({
        processLoading: false,
        timerCount: 0
      });
      clearTimeout(this.interval);
      this.getInitialize();
    }
    if (processLoading && !keepExisting && !displayErrorFields) {
      //getCreatedFertilizationPlan(activeFarm.farm.id, creatingData.transactionId, null);
      this.interval = setTimeout(() => {
        clearTimeout(this.interval);
        if(timerCount < new Date().getTime()+10000) {
          if(!isSuccess) {
            getCreatedFertilizationPlan(activeFarm.farm.id, creatingData.transactionId, "timerErrorMessage");
          }
        } else {
          if(!isSuccess) {
            if(fertilizationPlan && fertilizationPlan.errorMessage !== null && fertilizationPlan.errorMessage.data?.length > 0) {
            } else {
              getCreatedFertilizationPlan(activeFarm.farm.id, creatingData.transactionId, null);
            }
          }
        }
      }, 10000);
    }
    if(processLoading && timerCount > 0 && timerCount < new Date().getTime()+10000) {
      this.setState({
        processLoading: false,
        updateTask: false
      });
      clearTimeout(this.interval);
      getCreatedFertilizationPlan(activeFarm.farm.id, creatingData.transactionId, "timerErrorMessage"); 
    }
    if (isReportSuccess && !isReportLoading && !hasReportError && reportData != null && generatePdf) {
      generatePdfDocument(reportData,intl);
      this.setState({generatePdf: false});
    }

    if (isReportSuccess && !isReportLoading && !hasReportError && reportData != null && generateSeasonPdf) {
      if(this.state.stateFertilizationHarvest) {
        generateSeasonPdfDocument1(reportData, intl);
      }
      if(this.state.stateFirstHarvest) {
        generateSeasonPdfDocument2(reportData, intl);
      }
      if(this.state.stateSecondHarvest) {
        generateSeasonPdfDocument3(reportData, intl);
      }
      if(this.state.stateThirdHarvest) {
        generateSeasonPdfDocument4(reportData, intl);
      }
      this.setState({generateSeasonPdf: false});
    }
  }

  getInitialize = () => {
    const { initialize } = this.props;
    const formValues = {
      planName: '',
      year: []
    };
    initialize(formValues);
  };

  cancelFertilizationUpdate = () => {
    const { fertilizationPlan }= this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      updateFertilizationPlanDialogShow: false,
      mineralizedFertilizer: fertilizationPlan.data.mineralized,
      selectAllFields: false,
      selectedTotalFieldLength: 0,
      disablePlanUpdateButton: true,
      checkedItems: new Map(),
    })
  }

  closeDisplayErrorFields() {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      displayErrorFields: false,
      timerCount: 0
    });
    fertilizationPlan.hasError = false;
    fertilizationPlan.errorMessage = null;
    fertilizationPlan.creatingData = null;
    fertilizationPlan.isCreateSuccess = false;
    clearTimeout(this.interval);
    this.getInitialize();
  }

  getSelectedFields = values => new Map([...values].filter(([k, v]) => v === true));

  saveFertilizationUpdate = () => {
    let actual = false;
    const { recalculateFertilizationPlan, activeFarm, userDetails, fertilizationPlan } = this.props;
    const { selectAllFields } = this.state;
    fertilizationPlan.hasReportError = false;
    const mineralCalculationOnValue = this.state.mineralizedFertilizer;
    const selectedItem = this.getSelectedFields(this.state.checkedItems);
    const fields = [];
    selectedItem.forEach((v, k) => fields.push(k));
    let formDataObject = {};
    formDataObject["planName"] = fertilizationPlan.data.planName;
    formDataObject["year"] = fertilizationPlan.data.year;
    formDataObject["version"] = fertilizationPlan.data.version;
    formDataObject["mineralization"] = mineralCalculationOnValue;
    if(!selectAllFields && fields.length > 0) {
      formDataObject["fieldIds"] = fields;
    }
    if(fertilizationPlan.data.versionName !== "" && fertilizationPlan.data.versionName !== null) {
      formDataObject["versionName"] = fertilizationPlan.data.versionName;
    }
    mixpanel.identify(userDetails.id);
    if(actual) {
      mixpanel.track(UPDATE_FP_RECALCULATE);
    } else {
      mixpanel.track(UPDATE_FP_PLANNING);
    }    
    recalculateFertilizationPlan(activeFarm.farm.id, formDataObject, actual);
    this.cancelFertilizationUpdate();
  }

  onChangeMineralizedFertilizer = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState ({
      mineralizedFertilizer: !this.state.mineralizedFertilizer
    })
  }

  updateSeasonManure = (event) => {
    const { updateFertilizationPlan, fertilizationPlan, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
    const targetValue = event.target.name.split('_');
    let floatValue = event.target.value.replace(',', '.');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      manureQuantity: parseFloat(floatValue),
      manureStorageId: targetValue[3],
      seasonId: parseFloat(targetValue[0]),
      manurePlanId: targetValue[2],
      fieldId: targetValue[1]
    };
    this.setState({
      addMineral: false
    }); 
    if (floatValue && parseFloat(floatValue) > 0) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(CHANGE_MANURE_AMOUNT);
      clearTimeout(this.manureTimer);
      this.manureTimer = setTimeout(() => updateFertilizationPlan('manure', activeFarm.farm.id, formDataObject), 1500);
      //return () => clearTimeout(timeOut);
    }
  };

  updateMineralFertilizer = (event, editValueName) => {
    const { fertilizationPlan, updateMineralFertilizer, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
    let dataValue = editValueName.split('~~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      fieldId: dataValue[1],
      previousMF: dataValue[2],
      customMF: event.target.value
    };
    updateMineralFertilizer(activeFarm.farm.id, formDataObject);
    let trackValue = dataValue[2] + " is changed to " + event.target.value;
    mixpanel.identify(userDetails.id);
    mixpanel.track(CHANGE_MINERAL_TYPE_COMPLETE,{"MineralFertilizerTypeChange": trackValue});
    this.setState({
      editMineralFertilizer: false,
      addMineral: false,
      dropDownChange: true,
      updateFertilizationPlanDialogShow: false
    });
  };

  updateMineralFertilizerMenu = (event, editValueName) => {
    const { fertilizationPlan, updateMineralFertilizerMenu, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
     const formDataObject = {
      farmId: activeFarm.farm.id,
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      previousMF: editValueName,
      customMF: event.target.value
    };
    let trackValue = editValueName + " is changed to " + event.target.value;
    mixpanel.identify(userDetails.id);
    mixpanel.track(CHANGE_MF_FARM,{"MineralFertilizerTypeChange": trackValue});
    updateMineralFertilizerMenu(activeFarm.farm.id, formDataObject);
  };

  updateManureStorageMenu = (event, editManureValue) => {
    const { fertilizationPlan, updateManureStorageMenu, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
    let dataValue = editManureValue.split('~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      manureQuantity: parseFloat(dataValue[1]),
      manurePlanId: dataValue[2],
      manureStorageId: event.target.value,
      fieldId : dataValue[4]
    };
    let trackValue = dataValue[5] + " is changed";
    mixpanel.identify(userDetails.id);
    mixpanel.track(CHANGE_MANURE,{"MineralFertilizerTypeChange": trackValue});
    updateManureStorageMenu(activeFarm.farm.id, formDataObject);
  };

  updateCompleted = event => {
    const { fertilizationPlan, clickOnCompleted, activeFarm } = this.props;
    fertilizationPlan.hasReportError = false;
    let dataValue = event.target.attributes.id.nodeValue.split('~~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      fieldId: dataValue[1],
      completed: dataValue[2] === "true" ? true : false,
    };
    clickOnCompleted(activeFarm.farm.id, formDataObject);
    this.setState({
      editMineralFertilizer: false,
      addMineral: false,
      updateFertilizationPlanDialogShow: false
    });
  };

  updateCompletedAll = event => {
    const { fertilizationPlan, clickOnCompleted, activeFarm } = this.props;
    fertilizationPlan.hasReportError = false;
    let dataValue = event.target.attributes.id.nodeValue.split('~~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      completed: dataValue[1] === "true" ? true : false,
    };
    clickOnCompleted(activeFarm.farm.id, formDataObject);
    this.setState({
      editMineralFertilizer: false,
      addMineral: false
    });
  };

  addMineralFertilizer(event) {
    const { initialize, fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const formValues = {
      updateMineralFertilizer: { label: '', value: '' }
    };
    initialize(formValues);
    let dataValue = event.split('~~');
    this.setState({
      editMineralFertilizer: true,
      editMineralFertilizerValue: 'add',
      mineralFertilizerValues: dataValue,
      addMineral: false,
      fertilizerCount: this.state.totalFertilizerCount
    })
  }

  setMineralFertilizerState = event => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      selectedMineralFertilizer: event.target.value,
      addMineral: false
    });
  };

  deleteMineralFertilizer = (event, editValueName) => {
    const { fertilizationPlan, deleteMineralFertilizer, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
    let dataValue = editValueName.split('~~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      fieldId: dataValue[1],
      mf: dataValue[2]
    };
    let trackValue = dataValue[2] + " is deleted ";
    mixpanel.identify(userDetails.id);
    mixpanel.track(DELETE_MINERAL_TYPE_COMPLETE,{"MineralFertilizerTypeDelete": trackValue});
    deleteMineralFertilizer(activeFarm.farm.id, formDataObject);
    if(this.state.fertilizerCount !== this.state.totalFertilizerCount){
      this.setState({
        addMineral: true,
        fertilizerCount: this.state.totalFertilizerCount !== 0 ? this.state.totalFertilizerCount - 1 : 0
      }); 
    } else {
      this.setState({
        addMineral: true,
        fertilizerCount: this.state.fertilizerCount !== 0 ? this.state.fertilizerCount - 1 : 0
      }); 
    }
  };

  addNewMineralFertilizer = () => {
    const { fertilizationPlan, addMineralFertilizer, activeFarm } = this.props;
    fertilizationPlan.hasReportError = false;
    const { selectedMineralFertilizer, mineralFertilizerValues } = this.state;
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(mineralFertilizerValues[0]),
      fieldId: mineralFertilizerValues[1],
      mf: selectedMineralFertilizer,
      quantity: 0.0
    };
    addMineralFertilizer(activeFarm.farm.id, formDataObject);
    this.setState({
      editMineralFertilizer: false,
      addMineral: true,
      fertilizerCount: this.state.fertilizerCount + 1
    });
  };

  saveMineralFertilizer = () => {
    const { fertilizationPlan, updateMineralFertilizer, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
    const { selectedMineralFertilizer, mineralFertilizerValues } = this.state;
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(mineralFertilizerValues[0]),
      fieldId: mineralFertilizerValues[1],
      previousMF: mineralFertilizerValues[2],
      customMF: selectedMineralFertilizer
    };
    updateMineralFertilizer(activeFarm.farm.id, formDataObject);
    let trackValue = mineralFertilizerValues[2] + " is changed to " + selectedMineralFertilizer;
    mixpanel.identify(userDetails.id);
    mixpanel.track(CHANGE_MINERAL_TYPE_COMPLETE,{"MineralFertilizerTypeChange": trackValue});
    this.setState({
      editMineralFertilizer: false,
      addMineral: false
    });
  };

  closeEditMineralFertilizer = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      editMineralFertilizer: false,
      addMineral: false
    });
  };

  handleListClick = (event, index) => {
    event.preventDefault();
    //this.setState({ [`open${index+1}`]: !this.state[`open${index+1}`] });
    const newOpenItems = [...this.state.openItems];
    // Toggle the state for the clicked item
    newOpenItems[index] = !newOpenItems[index];
    this.setState({ openItems: newOpenItems });
  };

  handleInsufficientListClick = (event, index) => {
    event.preventDefault();
    const newOpenInsufficientItems = [...this.state.openInsufficientItems];
    // Toggle the state for the clicked item
    newOpenInsufficientItems[index] = !newOpenInsufficientItems[index];
    this.setState({ openInsufficientItems: newOpenInsufficientItems });
  };

  handleListFieldsClick = (event) => {
    event.preventDefault();
    this.handleCheckbox(event);
  };

  handleListTitleClick = (event, index) => {
    event.preventDefault();
    //this.setState({ [`open${index+1}`]: !this.state[`open${index+1}`] });
    const newOpenFieldItems = [...this.state.openFieldItems];
    // Toggle the state for the clicked item
    newOpenFieldItems[index] = !newOpenFieldItems[index];
    this.setState({ openFieldItems: newOpenFieldItems });
  };

  updateSeasonFertilizer = event => {
    const { updateFertilizationPlan, fertilizationPlan, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
    let { id, value }= event.target;
    this.setState(prevState => ({
      dropDownChange: false
    }));
    this.setState(prevState => ({
      addingFertilizer: {
        ...prevState.addingFertilizer,
        [id]: value        
      },
      addMineral: false
    }));
    const targetValue = event.target.name.split('~~');
    let floatValue = event.target.value.replace(',', '.');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseFloat(targetValue[0]),
      fieldId: targetValue[1],
      mf: targetValue[2],
      quantity: parseFloat(floatValue)
    };
    if (floatValue && parseFloat(floatValue) > 0) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(CHANGE_MINERAL_AMOUNT);
      clearTimeout(this.seasonTimer);
      this.seasonTimer = setTimeout(() => updateFertilizationPlan('season', activeFarm.farm.id, formDataObject), 1500);
    }
  };

  getMineralFertilizer = () => {
    const { fertilizationPlan, mineralFertilizer, intl } = this.props;
    const { viewFertilizerPlan } = this.state;
    if (fertilizationPlan.data && fertilizationPlan.data.mineralFertilizers && fertilizationPlan.data.mineralFertilizers?.length > 0) {
      return fertilizationPlan.data.mineralFertilizers.map((mineralFertilizerItem, index) => (
        <MineralFertilizerHeader
          mineralFertilizerItem={mineralFertilizerItem}
          viewFertilizerPlan={viewFertilizerPlan}
          mineralFertilizerList={getDropDownMineralFertilizers(mineralFertilizer.getMineralFertilizerList)}
          updateMineralFertilizerMenu={this.updateMineralFertilizerMenu}
          intl={intl}
                  />
      ));
    }
  };

  handleExpandNeed = (event, index) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const newExpandedColumnNeed = [...this.state.expandedColumnNeed];
    // Toggle the state for the clicked item
    newExpandedColumnNeed[index] = !newExpandedColumnNeed[index];
    this.setState({ expandedColumnNeed: newExpandedColumnNeed });
  };

  handleExpandActual = (event, index) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const newExpandedColumnActual= [...this.state.expandedColumnActual];
    // Toggle the state for the clicked item
    newExpandedColumnActual[index] = !newExpandedColumnActual[index];
    this.setState({ expandedColumnActual: newExpandedColumnActual });
  };

  handleExpandAdded = (event, index) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const newExpandedColumnAdded = [...this.state.expandedColumnAdded];
    // Toggle the state for the clicked item
    newExpandedColumnAdded[index] = !newExpandedColumnAdded[index];
    this.setState({ expandedColumnAdded: newExpandedColumnAdded });
  };

  handleExpandActualAdded = (event, index) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const newExpandedActualColumnAdded = [...this.state.expandedActualColumnAdded];
    // Toggle the state for the clicked item
    newExpandedActualColumnAdded[index] = !newExpandedActualColumnAdded[index];
    this.setState({ expandedActualColumnAdded: newExpandedActualColumnAdded });
  };

  handleExpandSeasonNeed = (event, index) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const newExpandedSeasonColumnNeed = [...this.state.expandedSeasonColumnNeed];
    // Toggle the state for the clicked item
    newExpandedSeasonColumnNeed[index] = !newExpandedSeasonColumnNeed[index];
    this.setState({ expandedSeasonColumnNeed: newExpandedSeasonColumnNeed });
  };

  handleExpandSeasonAdded = (event, index) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const newExpandedSeasonColumnAdded = [...this.state.expandedSeasonColumnAdded];
    // Toggle the state for the clicked item
    newExpandedSeasonColumnAdded[index] = !newExpandedSeasonColumnAdded[index];
    this.setState({ expandedSeasonColumnAdded: newExpandedSeasonColumnAdded });
  };

  getEmptyFieldFertilizationError = () => {
    const { fertilizationPlan, intl } = this.props;
    fertilizationPlan.hasReportError = false;
    if (fertilizationPlan.errorMessage != null && fertilizationPlan.errorMessage.data?.length > 0) {
      return fertilizationPlan.errorMessage.data.map((fieldFertilizationItem,index) => (
          <div
            key={fertilizationPlan.errorMessage.data.fieldId + '_' + index}
            className="padding-bottom-alignment"
          >
            <div className="empty-field-fertilizer">
              <div className="empty-field-grid">
                <div className="empty-field-title">{fieldFertilizationItem.fieldName}</div>
                <div className="empty-field-content">{fieldFertilizationItem.label}</div>
                <div className="empty-field-sub-content">
                  <ul>
                  {fieldFertilizationItem.missingAttributes && fieldFertilizationItem.missingAttributes.map((missingAttribute) => (
                    <li key={missingAttribute}>{missingAttribute}</li>
                  ))}
                  </ul>
                </div>
                {<div className="empty-field-button">
                  <Link to={`/managefields/${fieldFertilizationItem.fieldId}`} target="_blank">
                    <ActionButton secondary>
                      {intl.formatMessage({ id: 'GO_TO_FIELD_AND_ADD' })}
                    </ActionButton>
                  </Link>
                </div>}
              </div>
            </div>
          </div>
        )
      );
    }
  };

  getFieldFertilization = () => {
    const { fertilizationPlan, intl, mineralFertilizer } = this.props;
    const { isLoading } = fertilizationPlan;
    const { viewFertilizerPlan, addingFertilizer, addingFertilizerLeft } = this.state;
    if (fertilizationPlan.data && fertilizationPlan.data.fieldFertilizations && fertilizationPlan.data.fieldFertilizations?.length > 0) {
      return _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem,index) => (
        <div
          key={fertilizationPlan.data.fieldFertilizations.fieldId + '_' + index}
          className="padding-bottom-alignment-mui"
        >
          <Box sx={{ width: '100%', flexGrow: 1, paddingBottom: '25px' }}>
            <List
              sx={{ width: '100%', bgcolor: 'background.paper', border: 1, borderRadius: '5px', borderColor: '#dddddd', paddingTop: '0', paddingBottom: '0', display: 'block' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton disableRipple onClick={(e) => this.handleListClick(e, index)}  key={index} 
                sx={{ bgcolor: '#f1f1f1', borderBottom: 1, borderColor: '#dddddd', borderTopRadius: '5px' }}>
                <FertilizerPlanListHeader 
                  fieldFertilizationItem={fieldFertilizationItem}
                  anchorEl={this.state.anchorE2}
                  handleMenuClick={this.handleMenuClick}
                  handleMenuClose={this.handleMenuClose}
                  intl={intl}
                  displayGrid={true}
                  onClickOpenInsufficientItemsLink={this.onClickOpenInsufficientItemsLink}
                  redirectFieldLink={this.redirectFieldLink}
                  fieldId={fieldFertilizationItem.fieldId}
                  selectedMenuFieldId={this.state.selectedMenuFieldId}
                />
                {this.state.openItems[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={this.state.openItems[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ paddingTop: '20px', paddingBottom: '20px'}}>
                  <ListItemButton disableRipple sx= {{ '&:hover': { backgroundColor: 'transparent' }}}>
                    <Grid container sx={{ background: "transparent", display: "block" }}>
                      <Grid item >
                        <div style = {{ background: "transparent" }}>
                          <FertilizerPlanForField 
                            fieldFertilizationItem={fieldFertilizationItem}
                            intl={intl}
                            expandedColumnNeed={this.state.expandedColumnNeed}
                            expandedColumnActual={this.state.expandedColumnActual}
                            expandedColumnAdded={this.state.expandedColumnAdded}
                            handleExpandNeed={this.handleExpandNeed}
                            handleExpandActual={this.handleExpandActual}
                            handleExpandAdded={this.handleExpandAdded}
                            index={index}
                            manureStorageList={fertilizationPlan.data.manureStorages}
                          />
                          <FertilizerPlanForSeason
                            fieldFertilizationItem={fieldFertilizationItem}
                            updateSeasonManure={this.updateSeasonManure}
                            updateMineralFertilizer={this.updateMineralFertilizer}
                            addMineralFertilizer={this.addMineralFertilizer}
                            deleteMineralFertilizer={this.deleteMineralFertilizer}
                            updateSeasonFertilizer={this.updateSeasonFertilizer}
                            expandedSeasonColumnNeed={this.state.expandedSeasonColumnNeed}
                            expandedSeasonColumnAdded={this.state.expandedSeasonColumnAdded}
                            expandedActualColumnAdded={this.state.expandedActualColumnAdded}
                            handleExpandSeasonNeed={this.handleExpandSeasonNeed}
                            handleExpandSeasonAdded={this.handleExpandSeasonAdded}
                            handleExpandActualAdded={this.handleExpandActualAdded}
                            viewFertilizerPlan={viewFertilizerPlan}
                            isLoading={isLoading}
                            intl={intl}
                            addingFertilizer={addingFertilizer}
                            addingFertilizerLeft={addingFertilizerLeft}
                            updateCompleted={this.updateCompleted}
                            manureStorageList={getDropDownManureStorages(fertilizationPlan.data && fertilizationPlan.data.manureStorages)}
                            mineralFertilizerList={getDropDownMineralFertilizers(mineralFertilizer.getMineralFertilizerList)}
                            updateManureStorageMenu={this.updateManureStorageMenu}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </Box>
        </div>
      ));
    }
  };

  getEmptyFieldFertilization = () => {
    const { fertilizationPlan, intl } = this.props;
    if (fertilizationPlan.data && fertilizationPlan.data.fieldsWithInsufficientData && fertilizationPlan.data.fieldsWithInsufficientData?.length > 0) {
      return _.orderBy(fertilizationPlan.data.fieldsWithInsufficientData, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem,index) => (
          <div
            key={fertilizationPlan.data.fieldsWithInsufficientData.fieldId + '_' + index}
            className="padding-bottom-alignment-mui"
          >
            <Box sx={{ width: '100%', flexGrow: 1, paddingBottom: '25px' }}>
              <List
                sx={{ width: '100%', bgcolor: 'background.paper', border: 1, borderRadius: '5px', borderColor: '#dddddd', paddingTop: '0', paddingBottom: '0', display: 'block' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton disableRipple onClick={(e) => this.handleInsufficientListClick(e, index)}  key={index} 
                  sx={{ bgcolor: '#f1f1f1', borderBottom: 1, borderColor: '#dddddd', borderTopRadius: '5px' }}>
                  <FertilizerPlanListHeader 
                    fieldFertilizationItem={fieldFertilizationItem}
                    anchorE2={this.state.anchorE2}
                    handleMenuClick={this.handleMenuClick}
                    handleMenuClose={this.handleMenuClose}
                    intl={intl}
                    displayGrid={false}
                    onClickOpenInsufficientItemsLink={this.onClickOpenInsufficientItemsLink}
                    redirectFieldLink={this.redirectFieldLink}
                    fieldId={fieldFertilizationItem.fieldId}
                    selectedMenuFieldId={this.state.selectedMenuFieldId}
                  />
                  {this.state.openInsufficientItems[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={this.state.openInsufficientItems[index]} timeout="auto" unmountOnExit>
                  <Grid container sx={{ background: "transparent", display: "block", textAlign: "center" }}>
                      <Grid item >
                        <Typography style={insufficientTextTitle}>{intl.formatMessage({ id: 'INSUFFICIENT_DATA' })}</Typography>
                        <Typography style={insufficientIcon}><ErrorIcon/></Typography>
                        <Typography style={insufficientTextSubTitle}>
                          {fieldFertilizationItem.missingAttributes.map((missingAttribute) => (
                            <Box component="span" sx={{ paddingRight: '10px', paddingLeft: '10px' }}>
                              {missingAttribute}
                            </Box>
                          ))}
                        </Typography>
                        <Typography style={insufficientLink}>
                          <Chip
                            label={intl.formatMessage({ id: 'MISSING_ATTRIBUTES_TEXT' })}
                            color="primary"
                            style={buttonStyle}
                            sx={{ backgroundColor: 'transparent' }}
                            onClick={() => this.onClickOpenInsufficientItemsLink(fieldFertilizationItem.fieldId)}
                          />
                        </Typography>
                      </Grid>
                  </Grid>
                </Collapse>
              </List>
            </Box>
          </div>
        )
      );
    }
  };

  updateFertilizationPlanDialog = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      updateFertilizationPlanDialogShow: true
    })
  }

  getSaveButton() {
    const { fertilizationPlan, intl } = this.props;
    const { hasError, updateErrorMessage, errorMessage, hasSaveError, saveErrorMessage, versionNameChangeFailure, deleteVersionPlanFailure, hasReportError } = fertilizationPlan;
    let errorData = '';
    if (fertilizationPlan.data && fertilizationPlan.data.fieldFertilizations?.length > 0) {
      if (hasError && updateErrorMessage?.data) {
        errorData = (
          <span style={{ fontFamily: 'Inter'}}>
            <ErrorText>
              {updateErrorMessage?.status === 500
                ? intl.formatMessage({ id: 'UNEXPECTED_ERR_MSG' })
                : updateErrorMessage?.data}
            </ErrorText>
          </span>
        );
      }
      if (hasError && errorMessage?.data) {
        errorData = (
          <span style={{ fontFamily: 'Inter'}}>
            <ErrorText>
              {errorMessage?.status !== 422 && errorMessage?.status !== 409 && errorMessage?.data}
            </ErrorText>
          </span>
        );
      }
      if (hasSaveError) {
        errorData = (
          <span style={{ fontFamily: 'Inter'}}>
            <ErrorText>
              {saveErrorMessage?.data}
            </ErrorText>
          </span>
        );
      }
      if (hasReportError) {
        errorData = (
          <span style={{ fontFamily: 'Inter'}}>
            <ErrorText>
              {intl.formatMessage({ id: 'FERTILIZATION_REPORT_ERROR' })}
            </ErrorText>
          </span>
        );
      }
      if(versionNameChangeFailure) {
        errorData = (
          <span style={{ fontFamily: 'Inter'}}>
            <ErrorText>
              {versionNameChangeFailure?.data}
            </ErrorText>
          </span>
        );
      }
      if(deleteVersionPlanFailure) {
        errorData = (
          <span style={{ fontFamily: 'Inter'}}>
            <ErrorText>
              {deleteVersionPlanFailure?.data}
            </ErrorText>
          </span>
        );
      }
      let updateButton = (
        <Chip
          label={intl.formatMessage({ id: 'UPDATE_FERTILIZATION_PLAN' })}
          variant="outlined"
          clickable
          style={buttonStyle}
          onClick={() => this.updateFertilizationPlanDialog()}
        />
      );
      let saveButton = (
        <Chip
          label={intl.formatMessage({ id: 'SAVE' })}
          clickable
          style={buttonFilledStyle}
          onClick={() => this.createTaskSave()}
        />
      );
      return (
        <div className="fixed-button-fertilization">
          <div style={{ textAlign: "center" }}>
            <span>{errorData}</span><br/>
          </div>
          <div className="mineral-fertilizer-text-left">
            <span className="padding-right-heavy">{updateButton}</span>
            <span className="padding-right-heavy">{saveButton}</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  createTaskSave = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    /*this.setState({
      createTaskSave: true
    });*/
    this.saveFertilizationPlan(false);
  }

  closeCreateTaskModel() {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      createTaskConfirm: false,
      createTaskSave: false,
      createTaskSaveConfirm: false,
      createTaskPopup: false
    });
  }

  closeCreateTaskSaveModel() {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      createTaskSave: false,
      createTaskSaveConfirm: false,
      createTaskPopup: false
    });
    this.saveFertilizationPlan(false);
  }

  createTaskModel() {
    const { createTaskFertilizationPlan, fertilizationPlan, activeFarm } = this.props;
    fertilizationPlan.hasReportError = false;
    createTaskFertilizationPlan(activeFarm.farm.id, fertilizationPlan.data.year);
    this.setState(prevState => ({ anchorEl: false, createTaskPopup: true }));
  }

  goToTaskPage() {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const { periodOption, sortyByOption } = this.props;
    SetSessionData(FILTER_TASK_STATUS, [{value: 'PLANNED', label: 'Planned'}]);
    SetSessionData(FILTER_TASK_ACTIVITY, [{value: 'act7', label: 'Fertilization'}]);
    sessionStorage.removeItem(FILTER_TASK_FIELD);
    this.setState({
      taskPage: true
    });
    periodOption(null);
    sortyByOption(
      {
        value: 'Date created',
        label: <FormattedMessage id="DATE_CREATED" />
      }
    );
  }

  handleCheckbox = e => {
    const { fieldDetails } = this.props;
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const item = e.target.name;
    const isChecked = e.target.checked;
    let totalFieldLength = this.state.selectedTotalFieldLength;
    if(e.target.checked) {
      totalFieldLength = parseInt(totalFieldLength) + 1;
    } else {
      totalFieldLength = parseInt(totalFieldLength) - 1;
    }
    if(totalFieldLength === fieldDetails.data.length) {
      this.setState({
        selectAllFields: true
      });
    } else {
      this.setState({
        selectAllFields: false
      });
    }
    this.setState({
      selectedTotalFieldLength: parseInt(totalFieldLength)
    });
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    if(parseInt(totalFieldLength) > 0) {
      this.setState({
        disablePlanUpdateButton: false
      });
    } else {
      this.setState({
        disablePlanUpdateButton: true
      });
    }
  };

  // Check or un-check the field list item
  handleCheckboxAll = () => {
    const { fieldDetails } = this.props;
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const { selectAllFields } = this.state;
    if(!selectAllFields) {
      // eslint-disable-next-line
      fieldDetails.data.map(data => {
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(data.fieldId, true) }));
      });
      this.setState({
        selectAllFields: true,
        selectedTotalFieldLength: parseInt(fieldDetails.data.length),
        disablePlanUpdateButton: false
      });
    } else {
      this.clearSelectionMap(true);
    }
  };

  clearSelectionMap = clear => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      selectedTotalFieldLength: 0,
      checkedItems: new Map(),
      selectAllFields: false,
      disablePlanUpdateButton: true
    });
  };

  saveFertilizationPlan = (createTask) => {
    const { fertilizationPlan, saveFertilizationPlan, activeFarm, userDetails } = this.props;
    fertilizationPlan.hasReportError = false;
    const formDataObject = {
      planName: fertilizationPlan.data.planName,
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      versionName: fertilizationPlan.data.versionName
    };
    mixpanel.identify(userDetails.id);
    mixpanel.track(SAVE_PLAN);
    saveFertilizationPlan(activeFarm.farm.id, formDataObject, createTask);
  };

  handleClick = (event) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({ anchorEl: event.currentTarget });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleClose = (event) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    event.stopPropagation();
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleMenuClick = (event, fieldId) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({ anchorE2: event.currentTarget });
    this.setState({ selectedMenuFieldId: fieldId });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleMenuClose = (event) => {
    event.preventDefault();
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    event.stopPropagation();
    this.setState({ anchorE2: false });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (event) => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    if (this.state.anchorEl && !this.state.anchorEl.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  handleOutsideMenuClick = (event) => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    if (this.state.anchorE2 && !this.state.anchorE2.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  onChangeYear = (event) => {
    const { fertilizationPlan, activeFarm, editFertilizationPlan, mineralFertilizerList} = this.props;
    fertilizationPlan.hasReportError = false;
    if(event.target !== null && event.target !== undefined) {
      fertilizationPlan.data = null;
      fertilizationPlan.reportData = null;
      this.setState({
        addingFertilizer: [],
        addingFertilizerLeft: []
      });
      let targetValue = event.target.value.split("~");
      this.setState( {
        editFertilizationActionData: false,
        defaultYearValue: event.target.value,
        dropDownChange: false,
        draftedVersion: targetValue[2],
        draftedPlanName: targetValue[0],
        draftedYear: targetValue[1]
      });
      if(fertilizationPlan.planList !== null && fertilizationPlan.planList.fertilizerPlanMap !== null) {
        const orderList = _.orderBy(Object.entries(fertilizationPlan.planList.fertilizerPlanMap), [fertilizerPlanMapList => fertilizerPlanMapList], ['desc']);
        let yearDropdownArray = [];
        orderList.map((yearList, index) => (
          yearDropdownArray.push({value: yearList[1][0].planName+"~"+yearList[0]+"~"+yearList[1][0].version, label: yearList[0]})
        ));
        this.setState ({
          updateYearOptions: false,
          yearMenuOptions: yearDropdownArray
        });
        fertilizationPlan.isCreatePlanSuccess = false;
      }
      mineralFertilizerList(activeFarm.farm.id, targetValue[1]);
      editFertilizationPlan(activeFarm.farm.id, targetValue[0], targetValue[1], targetValue[2]);
    }
  }

  changePlanName = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState(prevState => ({ anchorEl: false,  showChangeNameDialog: true }));
  }

  onChangingPlanName = (event) => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const versionName = event.target.value;
    this.setState({
      versionNameNew: versionName
    })
    if(versionName.trim() !== "" && versionName.trim() !== null) {
      this.setState({
        disableChangeName: false
      });
    } else {
      this.setState({
        disableChangeName: true
      });
    }
  }

  saveChangeName = () => {
    const { activeFarm, fertilizationPlan, updateVersionName } = this.props;
    fertilizationPlan.hasReportError = false;
    const formDataObject = {
      farmId: activeFarm.farm.id,
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      versionName: this.state.versionNameNew
    };
    updateVersionName(activeFarm.farm.id, formDataObject);
    this.setState({
      showChangeNameDialog: false,
      disableChangeName: true,
      versionNameNewDisplay: this.state.versionNameNew
    });
  }

  cancelChangeName = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      showChangeNameDialog: false,
      disableChangeName: true
    });
  }

  noVersionDelete = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      versionDeleteDialog: false
    });
  }

  yesVersionDelete = () => {
    const { fertilizationPlan, deleteVersionPlan, activeFarm } = this.props;
    fertilizationPlan.hasReportError = false;
    deleteVersionPlan(activeFarm.farm.id, fertilizationPlan.data.year, fertilizationPlan.data.version);
    this.setState({
      versionDeleteDialog: false
    });
  }

  noPlanDelete = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      planDeleteDialog: false
    });
  }

  yesPlanDelete = () => {
    const { fertilizationPlan, deleteVersionPlan, activeFarm } = this.props;
    fertilizationPlan.hasReportError = false;
    deleteVersionPlan(activeFarm.farm.id, fertilizationPlan.data.year, null);
    this.setState({
      planDeleteDialog: false
    });
  }

  generatePdfFertilizerPlan = () => {
    const { fertilizationPlanReport, activeFarm, userDetails, fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState(prevState => ({ anchorEl: false }));
    this.setState({ generatePdf:true });
    mixpanel.identify(userDetails.id);
    mixpanel.track(EXPORT_PLAN);
    fertilizationPlanReport(activeFarm.farm.id, fertilizationPlan.data.planName, fertilizationPlan.data.year, fertilizationPlan.data.version);
  };

  generateSeasonPdfFertilizerPlan = () => {
    const { fertilizationPlanReport, activeFarm, userDetails } = this.props;
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      openGeneratePdf: false,
      generateSeasonPdf:true
    });
    mixpanel.identify(userDetails.id);
    mixpanel.track(EXPORT_PLAN);
    fertilizationPlanReport(activeFarm.farm.id, this.state.draftedPlanName, this.state.draftedYear, this.state.draftedVersion);
  };

  openGeneratePdfFertilizerPlan = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState(prevState => ({ anchorEl: false }));
    this.setState({      
      stateFertilizationHarvest: false,
      stateFirstHarvest: false,
      stateSecondHarvest: false,
      stateThirdHarvest: false,
      openGeneratePdf:true
    });
  };

  closeExportModal = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      openGeneratePdf: false,
      stateFertilizationHarvest: false,
      stateFirstHarvest: false,
      stateSecondHarvest: false,
      stateThirdHarvest: false
    });
  }

  onChangeExportHarvest = (selectedHarvest, state) => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    if(selectedHarvest === "fertilization") {
      this.setState({
        stateFertilizationHarvest: !state
      });
    }
    if(selectedHarvest === "first") {
      this.setState({
        stateFirstHarvest: !state
      });
    }
    if(selectedHarvest === "second") {
      this.setState({
        stateSecondHarvest: !state
      });
    }
    if(selectedHarvest === "third") {
      this.setState({
        stateThirdHarvest: !state
      });
    }
  }

  closeVersionDialog = () =>{
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      openVersionDialog: false
    })
  }

  onOpenVersionDialog = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const { defaultYearValue } = this.state;
    let targetValue = defaultYearValue.toString();
    if(targetValue.includes("~")) {
      targetValue = defaultYearValue.split("~")[1];
    }
    let selectedItem = null;
    if(fertilizationPlan.planList !== null && fertilizationPlan.planList.fertilizerPlanMap !== null) {
      selectedItem = fertilizationPlan.planList.fertilizerPlanMap[targetValue];
    }
    this.setState(prevState => ({ anchorEl: false }));
    this.setState({
      selectedYearFiltered: targetValue,
      selectedYearJson: selectedItem
    });
    this.setState({
      openVersionDialog: true
    });
  };

  onClickVersionChange = (selectedYear) => {
    const { activeFarm, editFertilizationPlan, fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    fertilizationPlan.data = null;
    this.setState({
      addingFertilizer: [],
      addingFertilizerLeft: [],
      editFertilizationActionData: false
    });
    editFertilizationPlan(activeFarm.farm.id, selectedYear.planName, selectedYear.year, selectedYear.version);
    this.setState({
      openVersionDialog: false
    });
  }
  
  onClickOpenInsufficientItemsLink = (fieldId) => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    const { fieldDetails } = this.props;
    const currentField = _.filter(fieldDetails.data, ['fieldId', fieldId]);
    this.setState({
      selectedField: currentField[0]
    });
    this.setState(prevState => ({ anchorE2: false }));
    this.setState({
      openInsufficientItemsLink: true
    })
  }

  redirectFieldLink = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState(prevState => ({ anchorE2: false }));
    window.open(`/#/managefields/${this.state.selectedMenuFieldId}`, '_blank');
    this.setState({
      selectedMenuFieldId: null
    });
  }

  onCloseOpenInsufficientItemsLink = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasReportError = false;
    this.setState({
      openInsufficientItemsLink: false
    })
  }

  render() {
    const { fertilizationPlan, mineralFertilizer, fieldDetails, intl } = this.props;
    const {
      hasError,
      creatingData,
      isLoading,
      isListLoading,
      isReportLoading,
      isCreateSuccess,
      hasReportError,
      errorMessage,
      reportErrorMessage,
      versionNameChangeLoading,
      versionNameChangeSuccess,
      deleteVersionPlanLoading,
      deleteVersionPlanSuccess,
      createTaskLoading
    } = fertilizationPlan;
    const { editMineralFertilizer, editMineralFertilizerValue, yearMenuOptions, processLoading, keepExisting, displayErrorFields, taskPage } = this.state;
    const headerBackground = {
      backgroundColor: "#BEC8C9",
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
      borderTop: "2px #6F7979",
      padding: "0px"
    }
    const headingPadding = {
      paddingTop: "15px",
      paddingBottom: "10px",
      paddingLeft: "15px",
      paddingRight: "15px" 
    }
    const headingPaddingLarge = {
      padding: "5%" 
    }
    const menuItemStyle = {
      backgroundColor: 'transparent',
      padding: '8px 16px',
      fontFamily: 'Inter',
      fontSize: '14px'
    };
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    const menuItemIconStyle = { ...menuItemStyle, padding: '4px 16px' }
    let updatedDate = "";
    if(fertilizationPlan.data && fertilizationPlan.data.createdTime !== null  && fertilizationPlan.data.createdTime !== undefined) {
      let splitDate = fertilizationPlan.data && fertilizationPlan.data.createdTime.split("T");
      updatedDate = splitDate[0];
    }
    if(fertilizationPlan.data && fertilizationPlan.data.lastUpdatedTime !== null  && fertilizationPlan.data.lastUpdatedTime !== undefined) {
      let splitDate = fertilizationPlan.data && fertilizationPlan.data.lastUpdatedTime.split("T");
      updatedDate = splitDate[0];
    }
    let displayVersionName = fertilizationPlan.data && fertilizationPlan.data.versionName;
    if(fertilizationPlan.data && versionNameChangeSuccess && this.state.versionNameNewDisplay !== "") {
      displayVersionName = this.state.versionNameNewDisplay;
    }
    return !hasError &&
      isCreateSuccess &&
      processLoading &&
      creatingData !== null && 
      !keepExisting ? (
      <div style={{backgroundColor: "#ECEEEE"}}>
        <LoaderDynamic styleName="fixed-loader" loadingText={creatingData.message} />
      </div>
    ) : (
      <div>
      {(fertilizationPlan.data !== null && fertilizationPlan.data.fieldFertilizations) ?
      (<div style={{backgroundColor: "#ECEEEE"}}>
        {taskPage && <Redirect to="/tasks" />}
        {createTaskLoading && <Loader styleName="fixed-loader" />}
        {deleteVersionPlanLoading && <Loader styleName="fixed-loader" />}
        {versionNameChangeLoading && <Loader styleName="fixed-loader" />}
        {isLoading && <Loader styleName="fixed-loader" />}
        {isListLoading && <Loader styleName="fixed-loader" />}
        {isReportLoading && <Loader styleName="fixed-loader" />}
        {/*saveSuccess && window.location.reload() */}
        {deleteVersionPlanSuccess && window.location.reload() }
        <div style={headerBackground}>
          <Grid container justifyContent="flex-end" style={headingPadding}>
            <Grid item lg={4} md={4} sm={4} xs={12} sx={{ xs: {textAlign: "center"}}}>
              <Typography className='field-list-font-title'>{displayVersionName}</Typography>
              <Typography className='field-list-font-subtitle'>{intl.formatMessage({ id: 'FIELD_FERTILIZATION_UPDATED_DATE' })}:&nbsp;{updatedDate}</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={6} sx={{ textAlign: "center" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.defaultYearValue}
                onChange={e => this.onChangeYear(e)}
                label=""
                sx={{
                  color: '#00696D',
                  "& .MuiSelect-select": {
                    borderStyle: 'none',
                    color: '#00696D',
                    fontFamily: 'Inter',
                    '&:hover': {
                      backgroundColor: 'transparent', // Change mouseover color
                    }
                  },
                  '& .MuiSelect-icon': {
                    color: '#00696D'
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                    color: '#00696D',
                  }
                }}
              >
                {yearMenuOptions.map((yearMenu, index) => (
                  <MenuItem value={yearMenu.value}>{yearMenu.label}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={6} style={{ textAlign: "end", paddingTop: "14px", xs: {textAlign: "center"}}}>
              <Box component='span' className='grid-padding-dot'>
                <MoreVertIcon onClick={(e)=>this.handleClick(e)} sx={{ cursor: 'pointer' }} />
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={(e)=>this.handleClose(e)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => {this.setState(prevState => ({ anchorEl: false, showChangeNameDialog: true }));}}
                    style={menuItemStyle}
                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                  >
                    {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU1' })}
                  </MenuItem>
                  <MenuItem onClick={() => {this.onOpenVersionDialog()}}
                    style={menuItemStyle}
                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                  >
                    {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU2' })}
                  </MenuItem>
                  <MenuItem onClick={() => {this.createTaskModel()}}
                    style={menuItemStyle}
                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                  >
                    {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU3' })}
                  </MenuItem>
                  <MenuItem onClick={() => {this.generatePdfFertilizerPlan()}}
                    style={menuItemStyle}
                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                  >
                    {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU4' })}
                  </MenuItem>
                  <MenuItem onClick={() => {this.openGeneratePdfFertilizerPlan()}}
                    style={menuItemStyle}
                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                  >
                    {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU4_1' })}
                  </MenuItem>
                  <MenuItem onClick={() => {this.setState(prevState => ({ anchorEl: false, versionDeleteDialog: true }));}}
                    style={menuItemIconStyle}
                    sx={{color: "#BA1A1A" }}
                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                  >
                    <div style={{ width: "100%" }}>
                      <span style={{ width: "96%" }}>{intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU5' })}</span>
                      <span style={{ textAlign: "end", float: "right" }}><DeleteIcon /></span>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={() => {this.setState(prevState => ({ anchorEl: false, planDeleteDialog: true }));}}
                    style={menuItemIconStyle}
                    sx={{color: "#BA1A1A" }}
                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                  >
                    <div style={{ width: "100%" }}>
                      <span style={{ width: "96%" }}>{intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU6' })}</span>
                      <span style={{ textAlign: "end", float: "right" }}><DeleteIcon /></span>
                    </div>
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
          <div>
            <FertilizerManure 
              fertilizationPlan={fertilizationPlan}
              intl={intl}
            />
          </div>
          <div className="fertilization-blue-mineral-header-mui">
            <Box sx={{ backgroundColor: "#ffffff", padding: "10px", borderRadius: "8px" }}>
              <Grid container justifyContent="flex-end" sx={{ padding: "20px" }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={{ fontFamily: "Inter", fontWeight: "500", fontSize: "14px", color: "#191C1C" }}>
                    {intl.formatMessage({ id: 'MINERAL_FERTILIZER' })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" columnSpacing={{ xs: 1, sm: 2, md: 4 }} sx={{ paddingBottom: "30px", paddingLeft: "20px", paddingRight: "20px" }}>
                {this.getMineralFertilizer()}
              </Grid>
            </Box>
          </div>
        </div>
        <div className='padding-top-mui'>
          {this.getFieldFertilization()}
          {this.getEmptyFieldFertilization()}
          {this.getSaveButton()}
        </div>
      </div>) :
      <div>
        {isLoading && <Loader styleName="fixed-loader" />}
        <div>
          <Grid container justifyContent="flex-center" style={headingPaddingLarge}>
            <Grid item md={3}>&nbsp;</Grid>
            <Grid item md={6}>
            {fertilizationPlan.planList && fertilizationPlan.planList.fertilizerPlanMap === null &&
              <Grid container sx={{ background: "transparent", display: "block", textAlign: "center", verticalAlign:"middle" }}>
                <Grid item>
                  <div className="manageFarmsLayout">
                    <div className="manageFarmsLayoutDivLayout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                      <div className="manageFarmsLayoutDiv">
                        <div><img src="images/NoFertilization.png" alt="No Fertilization" width="120" /></div>
                        <Box style={inputStyle}>
                          <Stack className="text-with-padding">
                            <div style={titleFont}>
                              {intl.formatMessage({ id: 'FIELD_FERTILIZATION' })}
                            </div>
                          </Stack>
                          <Stack className="text-with-padding">
                            <div style={subTitleFont}>
                              {intl.formatMessage({ id: 'NO_FERTILIZATION_CONTENT' })}
                            </div>
                          </Stack>
                          <div style={{ paddingRight: '0px !important' }}>
                            <Typography style={noFertilizationLink}>
                              <CreateFertilizationNewPlan
                                createNew={2}
                              />
                            </Typography>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </div>
                  {hasError ? (
                    <div style={{ padding: '18px' }}>
                      <span>
                        <ErrorText>
                          {errorMessage?.status === 500 ? intl.formatMessage({ id: 'UNEXPECTED_ERR_MSG' })
                            : (errorMessage?.status !== 409 && errorMessage?.status !== 422
                            ? errorMessage?.data
                            : '')}
                        </ErrorText>
                      </span>
                    </div>
                  ) : null}
                  {hasReportError ? (
                    <div style={{ padding: '18px' }}>
                      <span>
                        <ErrorText>
                          {reportErrorMessage?.status === 400 ? reportErrorMessage?.data : ''}
                        </ErrorText>
                      </span>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            }
            </Grid>
            <Grid item md={3}>&nbsp;</Grid>
          </Grid>
        </div>
      </div>}
      <MineralFertilizerUpdateModal
          editMineralFertilizer={editMineralFertilizer}
          editMineralFertilizerValue={editMineralFertilizerValue}
          closeEditMineralFertilizer={this.closeEditMineralFertilizer}
          mineralFertilizer={mineralFertilizer}
          setMineralFertilizerState={this.setMineralFertilizerState}
          saveMineralFertilizer={this.saveMineralFertilizer}
          addNewMineralFertilizer={this.addNewMineralFertilizer}
          selectedMineralFertilizer={this.state.selectedMineralFertilizer}
          intl={intl}
        />
        <FertilizerSaveModal
          intl={intl}
          updateFertilizationPlanDialogShow={this.state.updateFertilizationPlanDialogShow}
          cancelFertilizationUpdate={this.cancelFertilizationUpdate}
          saveFertilizationUpdate={this.saveFertilizationUpdate}
          onChangeMineralizedFertilizer={this.onChangeMineralizedFertilizer}
          mineralizedFertilizer={this.state.mineralizedFertilizer}
          checkedItems={this.state.checkedItems}
          handleCheckboxAll={this.handleCheckboxAll}
          handleCheckbox={this.handleCheckbox}
          selectAllFields={this.state.selectAllFields}
          disablePlanUpdateButton={this.state.disablePlanUpdateButton}
          fieldDetails={fieldDetails}
        />
        <FertilizerModal
          intl={intl}
          fertilizationPlan={fertilizationPlan}
          displayErrorFields={displayErrorFields}
          onCloseDisplayErrorFields={this.closeDisplayErrorFields}
          cancelChangeName={this.cancelChangeName}
          saveChangeName={this.saveChangeName}
          showChangeNameDialog={this.state.showChangeNameDialog}
          disableChangeName={this.state.disableChangeName}
          onChangingPlanName={this.onChangingPlanName}
          versionDeleteDialog={this.state.versionDeleteDialog}
          planDeleteDialog={this.state.planDeleteDialog}
          noVersionDelete={this.noVersionDelete}
          yesVersionDelete={this.yesVersionDelete}
          noPlanDelete={this.noPlanDelete}
          yesPlanDelete={this.yesPlanDelete}
          createTaskConfirm={this.state.createTaskConfirm}
          goToTaskPage={this.goToTaskPage}
          closeCreateTaskModel={this.closeCreateTaskModel}
          openVersionDialog={this.state.openVersionDialog}
          closeVersionDialog={this.closeVersionDialog}
          selectedYearJson={this.state.selectedYearJson}
          selectedYearFiltered={this.state.selectedYearFiltered}
          onClickVersionChange={this.onClickVersionChange}
          openInsufficientItemsLink={this.state.openInsufficientItemsLink}
          selectedField={this.state.selectedField}
          onCloseOpenInsufficientItemsLink={this.onCloseOpenInsufficientItemsLink}
          onClickOpenInsufficientItemsLink={this.onClickOpenInsufficientItemsLink}
          openGeneratePdf={this.state.openGeneratePdf}
          closeExportModal={this.closeExportModal}
          stateFertilizationHarvest={this.state.stateFertilizationHarvest}
          stateFirstHarvest={this.state.stateFirstHarvest}
          stateSecondHarvest={this.state.stateSecondHarvest}
          stateThirdHarvest={this.state.stateThirdHarvest}
          onChangeExportHarvest={this.onChangeExportHarvest}
          generateSeasonPdfFertilizerPlan={this.generateSeasonPdfFertilizerPlan}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fertilizationPlan: state.fertilizationPlan,
  mineralFertilizer: state.mineralFertilizer,
  userDetails: state.userDetails.data,
  fieldDetails: state.fieldDetails
});

function mapDispatchToProps(dispatch) {
  return {
    updateFertilizationPlan: (updateField, farmId, data) => dispatch(FertilizerAction.updateFertilizationPlan(updateField, farmId, data)),
    saveFertilizationPlan: (farmId, data, createTask) => dispatch(FertilizerAction.saveFertilizationPlan(farmId, data, createTask)),
    mineralFertilizerList: (farmId,year) => dispatch(MineralFertilizer.getMineralFertilizerList(farmId,year)),
    updateMineralFertilizer: (farmId, data) => dispatch(FertilizerAction.updateMineralFertilizer(farmId, data)),
    deleteMineralFertilizer: (farmId, data) => dispatch(FertilizerAction.deleteMineralFertilizer(farmId, data)),
    addMineralFertilizer: (farmId, data) => dispatch(FertilizerAction.addMineralFertilizer(farmId, data)),
    sortyByOption: option => dispatch(taskListSortByOption(option)),
    periodOption: days => dispatch({ type: TASK_PERIOD_VALUE, taskPeriod: days }),
    clickOnCompleted: (farmId, data) => dispatch(FertilizerAction.clickOnCompleted(farmId, data)),
    editFertilizationPlan: (farmId, planName, year, version) => dispatch(FertilizerAction.editFertilizationPlan(farmId, planName, year, version)),
    fertilizationPlanList: farmId => dispatch(FertilizerAction.fertilizationPlanList(farmId)),
    updateMineralFertilizerMenu: (farmId, data) => dispatch(FertilizerAction.updateMineralFertilizerMenu(farmId, data)),
    updateManureStorageMenu: (farmId, data) => dispatch(FertilizerAction.updateManureStorageMenu(farmId, data)),
    updateVersionName: (farmId, data) => dispatch(FertilizerAction.updateVersionName(farmId, data)),
    recalculateFertilizationPlan: (farmId, data, actual) => dispatch(FertilizerAction.recalculateFertilizationPlan(farmId, data, actual)),
    getCreatedFertilizationPlan: (farmId, transactionId, timerValue) => dispatch(FertilizerAction.getCreatedFertilizationPlan(farmId, transactionId, timerValue)),
    fertilizationPlanReport: (farmId, planName, year, version) => dispatch(FertilizerAction.fertilizationPlanReport(farmId, planName, year, version)),
    deleteVersionPlan: (farmId, year, version) => dispatch(FertilizerAction.deleteVersionPlan(farmId, year, version)),
    createTaskFertilizationPlan: (farmId, year) => dispatch(FertilizerAction.createTaskFertilizationPlan(farmId, year))
  };
}

export default injectIntl(
  reduxForm({
    form: 'FertilizationIndex',
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FertilizationIndex)))
);
