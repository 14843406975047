import { GET_GEO_LOCATION } from 'constants/index';

export const getLocation = coOrds => ({
  type: GET_GEO_LOCATION,
  payload: coOrds
});

export const getGeoLocation = () => dispatch => {
  try {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          dispatch(
            getLocation([
              {
                lattitude: position.coords.latitude,
                longitude: position.coords.longitude
              }
            ])
          );
        },
        error => {
          console.log(`User denies location ${error.message}`);
        },
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
      );
    }
  } catch (error) {
    console.log(error.message);
  }
};
