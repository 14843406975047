import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import CustomCalendar from 'containers/tasks/tasklist/CustomCalendar';
import {
  SecondsTohoursMinutes,
  GetDateObj,
  GetValuesFromDate,
  ParseISOString,
  PadString,
  formatTaskDateValue,
  formatTaskTimeValue,
  getValueByDateAndTime
} from 'utils';
import { COMPLETED, ONGOING, PAUSED } from 'constants/index';
import * as taskActions from 'actions/Tasks';
class TaskDurationSetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      startDate: props.startDate || '',
      endDate: props.endDate || '',
      startTime: props.startTime || '00:00',
      endTime: props.endTime || '00:00',
      paramId: props.match.params.id,
      updateDuration: false
    };
  }

  componentDidMount() {
    const { editTaskDetails } = this.props;
    const { paramId } = this.state;
    if (editTaskDetails.isGetTaskData && paramId !== 'create') {
      this.fetchDateTime();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, startTime, endTime } = this.state;
    const { savetaskInfo, editTaskDetails } = this.props;
    const obj = {};
    if (
      prevState.startDate !== startDate ||
      prevState.endDate !== endDate ||
      prevState.startTime !== startTime ||
      prevState.endTime !== endTime
    ) {
      const sDate = GetDateObj(startDate, startTime);
      const eDate = GetDateObj(endDate, endTime);
      obj.sDate = sDate ? sDate.toISOString() : '';
      obj.eDate = eDate ? eDate.toISOString() : '';
      savetaskInfo(obj);
      if(this.state.updateDuration) {
        this.updateTaskNetDuration(editTaskDetails, sDate, eDate, startDate, prevState);
      }
    }
    if (!_.isEqual(prevProps.editTaskDetails, editTaskDetails)) {
      if (editTaskDetails && editTaskDetails.isGetTaskData) {
        this.fetchDateTime();
      }
    }
  }

  updateTaskNetDuration(editTaskDetails, sDate, eDate, startDate, prevState) {
    const { updateTaskDuration } = this.props;
    const formValues = {};
    if (editTaskDetails.isGetTaskData && editTaskDetails.isGetTaskData.status && editTaskDetails.isGetTaskData.status === COMPLETED) {
      if(sDate && eDate && eDate >= sDate) {
        const diffInMs = Math.abs(eDate - sDate);
        formValues["id"] = editTaskDetails.isGetTaskData.id;
        formValues["farmId"] = editTaskDetails.isGetTaskData.farmId;
        formValues["startTime"] = sDate;
        formValues["endTime"] = eDate;
        formValues["netDuration"] = diffInMs / (1000);
        updateTaskDuration(editTaskDetails.isGetTaskData.farmId, editTaskDetails.isGetTaskData.id, formValues);
      }
    }
    if (editTaskDetails.isGetTaskData && editTaskDetails.isGetTaskData.status && 
      (editTaskDetails.isGetTaskData.status === ONGOING || editTaskDetails.isGetTaskData.status === PAUSED)) {
      if(prevState.startDate !== startDate) {
        formValues["id"] = editTaskDetails.isGetTaskData.id;
        formValues["farmId"] = editTaskDetails.isGetTaskData.farmId;
        formValues["startTime"] = sDate;
        updateTaskDuration(editTaskDetails.isGetTaskData.farmId, editTaskDetails.isGetTaskData.id, formValues);
      }
    }
  }

  /* To populate date and time on edit task */
  fetchDateTime = () => {
    const { editTaskDetails } = this.props;
    const { startTime, endTime } = editTaskDetails.isGetTaskData;
    if (startTime || endTime) {
      const tempStartDateTime = startTime
        ? GetValuesFromDate(new Date(ParseISOString(startTime)))
        : { date: '', time: '' };
      const tempEndDateTime = endTime
        ? GetValuesFromDate(new Date(ParseISOString(endTime)))
        : { date: '', time: '' };
      this.setState({
        startDate: tempStartDateTime.date,
        endDate: tempEndDateTime.date,
        startTime: tempStartDateTime.time,
        endTime: tempEndDateTime.time
      });
    }
  };

  /* To show calendar  */
  showCalendar = action => {
    this.setState({ show: true, action, updateDuration: false });
  };

  /* To hide calendar  */
  toggleCalendar = () => {
    this.setState({ show: false, updateDuration: true });
  };
  

  /** To change format of start time from entered values
   * @param {string, string, string} hour, min, prevStartTime
   * @returns {string}
   *
   */
  fetchStartTime = (hour, min, prevStartTime) => {
    const { action } = this.state;
    if (action === 'Start') {
      return `${hour < 10 && hour.length === 1 ? 0 + hour : hour}:${
        min < 10 && min.length === 1 ? 0 + min : min
      }`;
    }
    return prevStartTime || '00:00';
  };

  /** To change format of end time from entered values
   * @param {string, string, string} hour, min, prevEndTime
   * @returns {string}
   *
   */
  fetchEndTime = (hour, min, prevEndTime) => {
    const { action } = this.state;
    if (action === 'End') {
      return `${hour < 10 && hour.length === 1 ? 0 + hour : hour}:${
        min < 10 && min.length === 1 ? 0 + min : min
      }`;
    }
    return prevEndTime || '00:00';
  };

  /* callback function to recieve dates and times entered */
  chosenDetails = (startedDate, endedDate, hour, min) => {
    this.setState(prevState => ({
      startDate:
        startedDate && startedDate !== prevState.startDate ? startedDate : prevState.startDate,
      endDate: endedDate && endedDate !== prevState.endDate ? endedDate : prevState.endDate,
      startTime: this.fetchStartTime(hour, min, prevState.startTime),
      endTime: this.fetchEndTime(hour, min, prevState.endTime)
    }));
  };

  /** To calculate duration between startdatetime and enddatetime
   * @param {}
   * @returns {string}
   *
   */
  calculateDuration = () => {
    const { startDate, endDate, startTime, endTime, updateDuration } = this.state;
    const {
      props: { editTaskDetails }
    } = this;
    //if (editTaskDetails.isGetTaskData && editTaskDetails.isGetTaskData.status && editTaskDetails.isGetTaskData.status === COMPLETED) {
      if(updateDuration) {
        const sDate = GetDateObj(startDate, startTime);
        const eDate = GetDateObj(endDate, endTime);
        if(sDate && eDate && eDate >= sDate) {
          const diffInMs = Math.abs(eDate - sDate);
          const hrsMin = SecondsTohoursMinutes(diffInMs / (1000));
          return `${PadString(hrsMin.hours)} : ${PadString(hrsMin.minutes)}`;
        }
      } else {
        if(editTaskDetails.isGetTaskData && editTaskDetails.isGetTaskData.netDuration !== null && editTaskDetails.isGetTaskData.netDuration !== 0) {
          const hrsMin = SecondsTohoursMinutes(editTaskDetails.isGetTaskData.netDuration);
          return `${PadString(hrsMin.hours)} : ${PadString(hrsMin.minutes)}`;
        } else if(editTaskDetails.isGetTaskData && editTaskDetails.isGetTaskData.netDuration === 0) {
          return '00 : 00';
        } else {
          return '-- : --';
        }
      }      
    //}
    return '-- : --';
  };

  handleStartChange(date) {
    this.setState({  
      startDate: formatTaskDateValue(date).toString(),
      startTime: formatTaskTimeValue(date).toString(),
      updateDuration: true
    });
  }

  handleEndChange(date) {
    this.setState({  
      endDate: formatTaskDateValue(date),
      endTime: formatTaskTimeValue(date),
      updateDuration: true
    }); 
  }

  render() {
    const { show, action, startDate, endDate, startTime, endTime } = this.state;
    const { userInfo } = this.props;
    let newStartDate = '';
    let newEndDate = '';
    if(startDate) {
      newStartDate = getValueByDateAndTime(startDate, startTime);
    }
    if(endDate) {
      newEndDate = getValueByDateAndTime(endDate, endTime);
    }
    return (
      <>
        <div className="calendar-box">
          <div className="calendar-top">
            <div className="">
              <h5 className="startEnd">
                <FormattedMessage id="START" />
              </h5>
              <DatePicker  
                selected={newStartDate}
                className="task-date-picker"
                onChange={(date) => this.handleStartChange(date)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select" 
                name="startDate"  
                dateFormat="dd.MM.yyyy HH:mm"
                fixedHeight
                showTimeInput
                timeFormat="HH:mm"
              />
            </div>
            <div className="">
              <h5>
                <FormattedMessage id="DURATION" />
              </h5>
              <p className="duration">{this.calculateDuration()}</p>
            </div>
            <div className="">
              <h5 className="startEnd startEndRight">
                <FormattedMessage id="END" />
              </h5>
              <DatePicker  
                selected={newEndDate}
                className="task-date-picker"
                onChange={(date) => this.handleEndChange(date)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select" 
                name="endDate"  
                dateFormat="dd.MM.yyyy HH:mm"
                fixedHeight
                showTimeInput
                timeFormat="HH:mm"
              />
            </div>
          </div>
          <div className="calendar-bottom">
            <div className="">&nbsp;</div>
            <div className="icon-time calendar-icon" />
            <div className="endTimeDate">&nbsp;</div>
          </div>
        </div>
        {show && (
          <CustomCalendar
            action={action}
            toggleCalendar={this.toggleCalendar}
            sendDetails={this.chosenDetails}
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            lang={userInfo.data.preferredLanguage}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  //editTaskObj: state.taskDetails.editTaskObj,
  userInfo: state.userDetails,
  taskDetails: state.taskDetails,
});

const mapDispatchToProps = dispatch => ({
  savetaskInfo: obj => dispatch(taskActions.savetaskInfo(obj)),
  updateTaskDuration: (farmId, taskId, data) =>
      dispatch(taskActions.taskUpdateDuration(farmId, taskId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDurationSetter);
