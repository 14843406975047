import axios from 'axios';
import { API_ENDPOINT } from 'constants/index';

import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_FORGOT_PWD,
  GA_FORGOT_PWD_RESET,
  GA_CAT_UPDATE,
  GA_LOGIN_LBL,
  GA_PWD_CHANGED,
  GA_PROFILE_LBL
} from 'constants/GoogleAnalytics';

/**
 *
 * @param {email, callback}
 */
export const requestPassword = (emailId, callback) => async () => {
  try {
    await axios(`${API_ENDPOINT}users/forgotPassword`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { emailId }
    })
      .then(response => {
        GAEvent(GA_FORGOT_PWD, GA_LOGIN_LBL, GA_LOGIN_LBL);
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  } catch (error) {
    callback(error);
  }
};

/**
 *
 * @param {temPassword, passwordNew, callback}
 */
export const changePassword = (emailId, tempPassword, newPassword, callback) => async () => {
  try {
    await axios(`${API_ENDPOINT}users/forgotPassword/updatePassword`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { emailId, tempPassword, newPassword }
    })
      .then(response => {
        GAEvent(GA_FORGOT_PWD_RESET, GA_CAT_UPDATE, GA_LOGIN_LBL);
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  } catch (error) {
    callback(error);
  }
};

/**
 *
 * @param {passwordOld, passwordNew, callback}
 */
export const updatePassword = (
  emailId,
  oldPassword,
  newPassword,
  accessToken,
  callback
) => async () => {
  try {
    await axios(`${API_ENDPOINT}users/updatePassword`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        accessToken
      },
      data: { emailId, oldPassword, newPassword }
    })
      .then(response => {
        GAEvent(GA_PWD_CHANGED, GA_CAT_UPDATE, GA_PROFILE_LBL);
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  } catch (error) {
    callback(error);
  }
};
