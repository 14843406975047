/**
 * API request wrapper except login module.
 */
import axios from 'axios';
import { API_URI } from 'constants/index';

const apiRequest = options => {
  const onSuccess = response => {
    return response;
  };
  const onError = error => {
    return Promise.reject(error.response || error.message);
  };
  return (dispatch, getState) => {
    try {
      const { idToken } = getState().userDetails?.data;
      return axios({
        baseURL: API_URI,
        ...options,
        headers: {
          Authorization: idToken,
          ...options.headers
        }
      })
        .then(onSuccess)
        .catch(onError);
    } catch (error) {
      return error.message;
    }
  };
};
export default apiRequest;
