import { IS_EXPORT_LOADING, EXPORT_SUCCESS, EXPORT_ERR } from '../constants/store/Reports';

const initialState = {
  isError: false,
  successMsg: null,
  errorMsg: null,
  reportIsLoading: true,
  isReportLoading: false
};

export const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_EXPORT_LOADING:
      return {
        ...state,
        isReportLoading: true,
        reportErr: false,
        reportSuccess: false
      };
    case EXPORT_SUCCESS:
      return {
        ...state,
        isReportLoading: false,
        reportSuccess: true
      };
    case EXPORT_ERR:
      return {
        ...state,
        reportErr: true,
        errorMsg: action.errorMsg,
        isReportLoading: false
      };
    default:
      return state;
  }
};

export default ReportsReducer;
