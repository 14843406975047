import { 
  MANURE_NAMES_LOADING, 
  MANURE_NAMES_SUCCESS, 
  MANURE_NAMES_FAILURE 
} from 'constants/store/Fertilization';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null
};

export default function manureNamesReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case MANURE_NAMES_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case MANURE_NAMES_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case MANURE_NAMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.payload
      };
    default:
      return state;
  }
}
