export const GA_CAT_DETELE = 'delete';
export const GA_CAT_UPDATE = 'update';
export const GA_CAT_ADD = 'create';
export const GA_CAT_ERR = 'error';
export const GA_CAT_CLICK = 'click';
export const GA_FARM_LBL = 'farm_management';
export const GA_FIELD_LBL = 'field_management';
export const GA_USER_LBL = 'user_management';
export const GA_ASSET_LBL = 'asset_management';
export const GA_TASK_LBL = 'task_management';
export const GA_REPORTS_LBL = 'reports';
export const GA_PROFILE_LBL = 'profile';
export const GA_LOGIN_LBL = 'login';
export const GA_ADD_FARM = 'farm_create';
export const GA_ADD_FARM_ERR = 'error_add_farm';
export const GA_DELETE_FARM = 'farm_delete';
export const GA_UPDATE_FARM = 'farm_update';
export const GA_ADD_FIELD = 'field_create';
export const GA_ADD_FIELD_ERR = 'error_create_field';
export const GA_DELETE_FIELD = 'field_delete';
export const GA_UPDATE_FIELD = 'field_update';
export const GA_ADD_USER = 'user_add';
export const GA_ADD_USER_ERR = 'error_add_user';
export const GA_DELETE_USER = 'user_delete';
export const GA_UPDATE_USER = 'user_role_update';
export const GA_ADD_ASSET = 'asset_add';
export const GA_ADD_ASSET_ERR = 'error_add_asset';
export const GA_DELETE_ASSET = 'asset_delete';
export const GA_UPDATE_ASSET = 'asset_update';
export const GA_ADD_TASK = 'task_create';
export const GA_ADD_TASK_ERR = 'error_create_task';
export const GA_DELETE_TASK = 'task_delete';
export const GA_UPDATE_TASK = 'task_update';
export const GA_COMPLETE_TASK = 'task_complete';
export const GA_SIGN_UP = 'sign_up';
export const GA_SIGN_UP_ERR = 'error_sign_up';
export const GA_LOGIN = 'login';
export const GA_LOGIN_ERR = 'error_login';
export const GA_FORGOT_PWD = 'forgot_password_request';
export const GA_FORGOT_PWD_RESET = 'forgot_password_change';
export const GA_PROFILE_UPDATE = 'profile_update';
export const GA_PWD_CHANGED = 'profile_password_change';
export const GA_PROFILE_IMG = 'profile_image_update';
export const GA_PROFILE_IMG_ERR = 'error_profile_image_update';
export const GA_PROFILE_UPDATE_ERR = 'error_profile_update';
export const GA_REPORT_DOWNLOAD = 'report_download';
export const GA_REPORT_DOWNLOAD_ERR = 'error_report_download';
export const GA_FIELD_HELP_TEXT_CLK = 'click_field_help_text';
export const GA_EANA_CLK = 'click_eana_link';
