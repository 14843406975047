import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as taskActions from 'actions/Tasks';

class TaskWeather extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIcon: '',
      paramId: props.match.params.id,
      weatherAssigned: false
    };
  }

  componentDidMount() {
    const { editTaskDetails } = this.props;
    const { paramId } = this.state;
    if (editTaskDetails && paramId !== 'create') {
      if (editTaskDetails.isGetTaskData) {
        this.setState({ activeIcon: editTaskDetails.isGetTaskData.weather?.toLowerCase(), weatherAssigned: true });
      }
    }
  }

  componentDidUpdate() {
    const { editTaskDetails } = this.props;
    const { paramId, weatherAssigned } = this.state;
    if (editTaskDetails && paramId !== 'create') {
      if (editTaskDetails.isGetTaskData && !weatherAssigned) {
        this.setState({ activeIcon: editTaskDetails.isGetTaskData.weather?.toLowerCase(), weatherAssigned: true });
      }
    }
  }

  toggleClass = index => {
    const { savetaskInfo } = this.props;
    const { activeIcon } = this.state;
    this.setState(prevState => ({
      activeIcon: prevState.activeIcon === index ? '' : index
    }));
    savetaskInfo({ weather: activeIcon === index ? '' : index });
  };

  render() {
    const { activeIcon } = this.state;
    return (
      <div className="weather-wrapper">
        <div
          className={`weather-icon ${activeIcon === 'sunny' ? 'activeClass' : ''}`}
          onClick={() => this.toggleClass('sunny')}
          onKeyPress={() => this.toggleClass('sunny')}
          role="button"
          tabIndex="-10"
        >
          <span className="icon-sun icon" />
          <p>
            <FormattedMessage id="SUNNY" />
          </p>
        </div>
        <div
          className={`weather-icon ${activeIcon === 'cloudy' ? 'activeClass' : ''}`}
          onClick={() => this.toggleClass('cloudy')}
          onKeyPress={() => this.toggleClass('cloudy')}
          role="button"
          tabIndex="-11"
        >
          <span className="icon-cloud icon" />
          <p>
            <FormattedMessage id="CLOUDY" />
          </p>
        </div>
        <div
          className={`weather-icon ${activeIcon === 'sun_and_rain' ? 'activeClass' : ''}`}
          onClick={() => this.toggleClass('sun_and_rain')}
          onKeyPress={() => this.toggleClass('sun_and_rain')}
          role="button"
          tabIndex="-12"
        >
          <span className="icon-sun-rain icon" />
          <p>
            <FormattedMessage id="SUNANDRAIN" />
          </p>
        </div>
        <div
          className={`weather-icon ${activeIcon === 'overcast' ? 'activeClass' : ''}`}
          onClick={() => this.toggleClass('overcast')}
          onKeyPress={() => this.toggleClass('overcast')}
          role="button"
          tabIndex="-13"
        >
          <span className="icon-overcast icon" />
          <p>
            <FormattedMessage id="OVERCAST" />
          </p>
        </div>
        <div
          className={`weather-icon ${activeIcon === 'rain' ? 'activeClass' : ''}`}
          onClick={() => this.toggleClass('rain')}
          onKeyPress={() => this.toggleClass('rain')}
          role="button"
          tabIndex="-14"
        >
          <span className="icon-rain icon" />
          <p>
            <FormattedMessage id="RAIN" />
          </p>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  savetaskInfo: obj => dispatch(taskActions.savetaskInfo(obj))
});

const mapStateToProps = state => ({
  //editTaskObj: state.taskDetails.editTaskObj
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskWeather);
