import {
  MINERAL_STORAGE_LIST_LOADING,
  MINERAL_STORAGE_LIST_SUCCESS,
  MINERAL_STORAGE_LIST_ERROR,
  MINERAL_STORAGE_CREATE_LOADING,
  MINERAL_STORAGE_CREATE_SUCCESS,
  MINERAL_STORAGE_CREATE_ERROR,
  MINERAL_STORAGE_DELETE_LOADING,
  MINERAL_STORAGE_DELETE_SUCCESS,
  MINERAL_STORAGE_DELETE_ERROR
} from 'constants/store/Storages';

const initialState = {
  isLoading: false,
  mineralStorageList: null,
  hasError: false,
  hasDeleteError: false,
  errorMsg: null,
  isSuccess: false,
  isDeleteSuccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MINERAL_STORAGE_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        mineralStorageList: null,
        hasError: false,
        hasDeleteError: false,
        errorMsg: null
      };
    case MINERAL_STORAGE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mineralStorageList: action.payload,
        hasError: false,
        hasDeleteError: false,
        errorMsg: null,
        isSuccess: false,
        isDeleteSuccess: false
      };
    case MINERAL_STORAGE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hasDeleteError: false,
        errorMsg: action.payload,
        isSuccess: false,
        isDeleteSuccess: false
      };
    case MINERAL_STORAGE_CREATE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        hasDeleteError: false,
        errorMsg: null,
        isSuccess: false
      };
    case MINERAL_STORAGE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hasDeleteError: false,
        errorMsg: null,
        isSuccess: true
      };
    case MINERAL_STORAGE_CREATE_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hasDeleteError: false,
        errorMsg: action.payload,
        isSuccess: false
      };
    case MINERAL_STORAGE_DELETE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        hasDeleteError: false,
        errorMsg: null,
        isDeleteSuccess: false
      };
    case MINERAL_STORAGE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hasDeleteError: false,
        errorMsg: null,
        isDeleteSuccess: true
      };
    case MINERAL_STORAGE_DELETE_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hasDeleteError: true,
        errorMsg: action.payload,
        isDeleteSuccess: false
      };
    default:
      return state;
  }
};
