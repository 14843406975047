import React from 'react';
import { connect } from 'react-redux';
import { EanaCreateBaleMap } from 'utils/baleStorageMap';
import * as baleActions from 'actions/Bale';
import * as locationActions from 'actions/Geolocation';

let FieldMap = new EanaCreateBaleMap();
class BaleMap extends React.Component {
  constructor(props) {
    super(props);
    this.fieldMapRef = React.createRef();
  }

  componentDidMount() {
    FieldMap = new EanaCreateBaleMap();
    const { getGeoLocation, paramId, mapData } = this.props;
    let pointsToLocate = null;
    let fieldValues = null;
    if (paramId === 'edit') {
      if(mapData && mapData.gpsPoint) {
        pointsToLocate = this.computeCoOrds();
        fieldValues = pointsToLocate;
      }
    } else {
      pointsToLocate = this.computeCoOrds()[0]
    }
    if(pointsToLocate) {
      FieldMap.drawMarker(
        this.fieldMapRef.current.id,
        pointsToLocate.lattitude,
        pointsToLocate.longitude,
        fieldValues,
        (lat, long) => this.getDrawnCoords(lat, long)
      );
    }
    getGeoLocation();
  }

  computeCoOrds = () => {
    const { geoLocDetails, activeFarm, paramId, mapData } = this.props;
    if (geoLocDetails.length > 0) {
      if (paramId === 'new') {
        if (activeFarm.farm.location === null || activeFarm.farm.location === undefined) {
          return geoLocDetails;
        }
        return [activeFarm.farm.location];
      }
      if (paramId === 'edit') {
        if(mapData && mapData.gpsPoint) {
          let data = {
            "lattitude": mapData.gpsPoint.latitude,
            "longitude": mapData.gpsPoint.longitude
          }
          return data;
        }
      }
    }
    return false;
  };

  recenter = () => {
    const { geoLocDetails } = this.props;
    if (geoLocDetails) {
      const pointsToLocate = geoLocDetails[0];
      FieldMap.viewToCurrentLocation(pointsToLocate.lattitude, pointsToLocate.longitude);
    }
  };

  getDrawnCoords = (lat, long) => {
    const { saveBaleMarkerCoordinates } = this.props;
    saveBaleMarkerCoordinates({
      latitude: lat,
      longitude: long
    });
  };

  render() {
    const { toggleScreen, mapId, fullScreen, title } = this.props;
    return (
      <div className={fullScreen ? 'full-screen-map-wrapper' : 'field-map'}>
        <div id={mapId} ref={this.fieldMapRef} style={{ height: '100%', width: '100%' }} />
        {mapId !== "off-screen-map-bale-view" &&
        <div
          title="Reset Location"
          className="gps icon-directions"
          onClick={this.recenter}
          onKeyPress={this.recenter}
          role="button"
          tabIndex="-1"
          aria-label="locate"
        />}
        {mapId === "off-screen-map-bale-view" &&
        <div
          title={title}
          className={`fullscreen icon-full-screen${fullScreen ? '-close' : ''}`}
          onClick={() => toggleScreen()}
          onKeyPress={() => toggleScreen()}
          role="button"
          tabIndex="-1"
          aria-label={title}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  geoLocDetails: state.geoLocDetails.data,
  baleCoords: state.bales.savedBaleMarkerCoordinates,
  activeFarm: state.farmList.activeFarm
});

function mapDispatchToProps(dispatch) {
  return {
    getGeoLocation: () => dispatch(locationActions.getGeoLocation()),
    saveBaleMarkerCoordinates: obj => dispatch(baleActions.saveBaleMarkerCoordinates(obj))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BaleMap);