import * as React from 'react';
import { List, ListItemButton, Collapse, Grid, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';

const titleText = { fontFamily: "Inter", fontSize: "16px", fontWeight: "500" };
const headingText = { ...titleText, fontSize: "14px", fontWeight: "600", textAlign: "center" };
const subHeadingText = { ...titleText, fontWeight: "400", textAlign: "center" };
const subHeadingCapitalizeText = { ...titleText, fontWeight: "400", textTransform: "Capitalize" };
const subHeadingSmallText = { fontFamily: "Inter", fontSize: "14px", fontWeight: "400", textAlign: "center" };
const subHeadingSmallCapitalizeText = { ...subHeadingSmallText, textTransform: "Capitalize", textAlign: "left", fontWeight: "600" }

function getMineralFertilizerTitleHeader(intl) {
  return (
    <Grid container justifyContent="flex-end" sx={{ padding: "8px" }}>
      <Grid item lg={4} sm={4} md={4} xs={12}>
        <Typography style={headingText}>&nbsp;</Typography>
      </Grid>
      <Grid item lg={4} sm={4} md={4} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU_HEADING_1' })}</Typography>
      </Grid>
      <Grid item lg={4} sm={4} md={4} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU_HEADING_2' })}</Typography>
      </Grid>
    </Grid>
  )
}

function getMineralFertilizerHeader(intl) {
  return (
    <Grid container justifyContent="flex-end" sx={{ padding: "8px" }}>
      <Grid item lg={4} sm={4} md={4} xs={12}>
        <Typography style={headingText}>&nbsp;</Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'NITROGEN' })}</Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'PHOSPHORUS' })}</Typography>
      </Grid>
      <Grid item lg={2} sm={2} md={2} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'POTASSIUM' })}</Typography>
      </Grid>
    </Grid>
  )
}

function getMineralFertilizerNeed(fieldFertilizationItem, intl, expandedColumnNeed, handleExpandNeed, index) {
  let fieldNeedYieldData = null;
  let fieldNeedSoilData = null;
  let fieldNeedPreviousCropData = null;
  let fieldNeedCloverData = null;
  let fieldNeedStravRemovedData = null;
  if(fieldFertilizationItem.yieldEffect !== null) {
    fieldNeedYieldData = getFieldNeedData(
      fieldFertilizationItem.yieldEffect.yieldNitrogen, 
      fieldFertilizationItem.yieldEffect.yieldPhosphorus, 
      fieldFertilizationItem.yieldEffect.yieldPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_YIELD' })
    );
  }
  if(fieldFertilizationItem.soilParamEffect !== null) {
    fieldNeedSoilData = getFieldNeedData(
      fieldFertilizationItem.soilParamEffect.soilNitrogen, 
      fieldFertilizationItem.soilParamEffect.soilPhosphorus, 
      fieldFertilizationItem.soilParamEffect.soilPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_SOIL' })
    );
  }
  if(fieldFertilizationItem.previousCropEffect !== null) {
    fieldNeedPreviousCropData = getFieldNeedData(
      fieldFertilizationItem.previousCropEffect.previousCropNitrogen, 
      fieldFertilizationItem.previousCropEffect.previousCropPhosphorus, 
      fieldFertilizationItem.previousCropEffect.previousCropPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_PREVIOUS_CROP' })
    );
  }
  if(fieldFertilizationItem.cloverEffect !== null) {
    fieldNeedCloverData = getFieldNeedData(
      fieldFertilizationItem.cloverEffect.cloverNitrogen, 
      fieldFertilizationItem.cloverEffect.cloverPhosphorus, 
      fieldFertilizationItem.cloverEffect.cloverPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_CLOVER' })
    );
  }
  if(fieldFertilizationItem.stravRemovedEffect !== null) {
    fieldNeedStravRemovedData = getFieldNeedData(
      fieldFertilizationItem.stravRemovedEffect.stravRemovedNitrogen, 
      fieldFertilizationItem.stravRemovedEffect.stravRemovedPhosphorus, 
      fieldFertilizationItem.stravRemovedEffect.stravRemovedPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_REMOVAL_OF_STRAV' })
    );
  }
  let mineral_fertilizer_need = [];
  if (fieldFertilizationItem.needForField !== null) {
    mineral_fertilizer_need = (
      <List component="nav" onClick={(e) => handleExpandNeed(e, index)}>
        <Grid container>
          <Grid item lg={4} sm={4} md={4} xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={subHeadingCapitalizeText} sx={{width: '95%'}}>{intl.formatMessage({ id: 'NEED' })}</Typography>
              {(fieldNeedYieldData !== null || fieldNeedSoilData !== null || fieldNeedPreviousCropData !== null || fieldNeedCloverData !== null || fieldNeedStravRemovedData !== null) &&
              (expandedColumnNeed[index] ? <ArrowDropUp/> : <ArrowDropDown />)}
            </div>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.needForField.nitrogenNeed.toFixed(1)}{' '}
              {fieldFertilizationItem.needForField.nutrientUnitsNeed}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.needForField.phosphorusNeed.toFixed(1)}{' '}
              {fieldFertilizationItem.needForField.nutrientUnitsNeed}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.needForField.potassiumNeed.toFixed(1)}{' '}
              {fieldFertilizationItem.needForField.nutrientUnitsNeed}
            </Typography>
          </Grid>
        </Grid>        
        <Collapse in={expandedColumnNeed[index]} timeout="auto" unmountOnExit>
          {fieldNeedYieldData}
          {fieldNeedSoilData}
          {fieldNeedPreviousCropData}
          {fieldNeedCloverData}
          {fieldNeedStravRemovedData}
        </Collapse>
      </List>
    )
  }
  return mineral_fertilizer_need;
}

function getMineralFertilizerActual(fieldFertilizationItem, intl, expandedColumnActual, handleExpandActual, index) {
  let manureData = getManureDataActual(fieldFertilizationItem, intl);
  let mineralFertilizerData = getMineralFertilizerDataActual(fieldFertilizationItem);
  let mineral_fertilizer_actual = [];
  if (fieldFertilizationItem.addedForFieldActual !== null) {
    mineral_fertilizer_actual = (
      <List component="nav" onClick={(e) => handleExpandActual(e, index)}>
        <Grid container>
          <Grid item lg={4} sm={4} md={4} xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={subHeadingCapitalizeText} sx={{width: '95%'}}>{intl.formatMessage({ id: 'FP_ACTUAL' })}</Typography>
              {expandedColumnActual[index] ? <ArrowDropUp/> : <ArrowDropDown />}
            </div>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.addedForFieldActual.nitrogenAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForFieldActual.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.addedForFieldActual.phosphorusAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForFieldActual.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.addedForFieldActual.potassiumAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForFieldActual.nutrientUnitsAdded}
            </Typography>
          </Grid>
        </Grid>      
        <Collapse in={expandedColumnActual[index]} timeout="auto" unmountOnExit>
          {manureData}
          {mineralFertilizerData}
        </Collapse>
      </List>
    )
  }
  return mineral_fertilizer_actual;
}

function getMineralFertilizerAdded(fieldFertilizationItem, intl, expandedColumnAdded, handleExpandAdded, index) {
  let manureData = getManureData(fieldFertilizationItem, intl);
  let mineralFertilizerData = getMineralFertilizerData(fieldFertilizationItem);
  let mineral_fertilizer_added = [];
  if (fieldFertilizationItem.addedForField !== null) {
    mineral_fertilizer_added = (
      <List component="nav" onClick={(e) => handleExpandAdded(e, index)}>
        <Grid container>
          <Grid item lg={4} sm={4} md={4} xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={subHeadingCapitalizeText} sx={{width: '95%'}}>{intl.formatMessage({ id: 'ADDED' })}</Typography>
              {expandedColumnAdded[index] ? <ArrowDropUp/> : <ArrowDropDown />}
            </div>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.addedForField.nitrogenAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForField.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.addedForField.phosphorusAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForField.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={12}>
            <Typography style={subHeadingText}>
              {fieldFertilizationItem.addedForField.potassiumAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForField.nutrientUnitsAdded}
            </Typography>
          </Grid>
        </Grid>      
        <Collapse in={expandedColumnAdded[index]} timeout="auto" unmountOnExit>
          {manureData}
          {mineralFertilizerData}
        </Collapse>
      </List>
    )
  }
  return mineral_fertilizer_added;
}

function getMineralFertilizerDifference(fieldFertilizationItem, intl) {
  let mineral_fertilizer_difference = "";
  if (fieldFertilizationItem.shortfallForField !== null) {
    mineral_fertilizer_difference = (
      <Grid container justifyContent="flex-end" sx={{ padding: "8px" }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingCapitalizeText}>{intl.formatMessage({ id: 'DIFFERENCE' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingText}>
            {fieldFertilizationItem.shortfallForField.nitrogenShortfall.toFixed(1)}{' '}
            {fieldFertilizationItem.shortfallForField.nutrientUnitsShortfall}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingText}>
            {fieldFertilizationItem.shortfallForField.phosphorusShortfall.toFixed(1)}{' '}
            {fieldFertilizationItem.shortfallForField.nutrientUnitsShortfall}
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingText}>
            {fieldFertilizationItem.shortfallForField.potassiumShortfall.toFixed(1)}{' '}
            {fieldFertilizationItem.shortfallForField.nutrientUnitsShortfall}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  return mineral_fertilizer_difference;
}

function getMineralFertilizerDataActual(fieldFertilizationItem) {
  if (fieldFertilizationItem.mineralFertilizersForFieldActual?.length > 0) {
    return fieldFertilizationItem.mineralFertilizersForFieldActual.map(mineralFertilizerItem => (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>
            {mineralFertilizerItem.customName === null
            ? mineralFertilizerItem.originalName
            : mineralFertilizerItem.customName}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.nitrogen.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.phosphorus.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.potassium.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
      </Grid>
    ));
  }
}

function getMineralFertilizerData(fieldFertilizationItem) {
  if (fieldFertilizationItem.mineralFertilizersForField?.length > 0) {
    return fieldFertilizationItem.mineralFertilizersForField.map(mineralFertilizerItem => (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>
            {mineralFertilizerItem.customName === null
            ? mineralFertilizerItem.originalName
            : mineralFertilizerItem.customName}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.nitrogen.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.phosphorus.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.potassium.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
      </Grid>
    ));
  }
}

function getManureData(fieldFertilizationItem, intl) {
  let manureData = '';
  if (fieldFertilizationItem.manureForField !== null) {
    manureData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'MANURE' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.manureForField.manureNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.manureForField.manurePhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.manureForField.manurePotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return manureData;
}

function getManureDataActual(fieldFertilizationItem, intl) {
  let manureData = '';
  if (fieldFertilizationItem.manureForFieldActual !== null) {
    manureData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'MANURE' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.manureForFieldActual.manureNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.manureForFieldActual.manurePhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.manureForFieldActual.manurePotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return manureData;
}

function getFieldNeedData(nitrogen, phosphorus, potassium, title) {
  let data = null;
  data = (
    <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
      <Grid item lg={4} sm={4} md={4} xs={12}>
        <Typography style={subHeadingSmallCapitalizeText}>{title}</Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={subHeadingSmallText}>
          {nitrogen.toFixed(1)} kg/daa
        </Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={subHeadingSmallText}>
          {phosphorus.toFixed(1)} kg/daa
        </Typography>
      </Grid>
      <Grid item lg={2} sm={2} md={2} xs={12}>
        <Typography style={subHeadingSmallText}>
          {potassium.toFixed(1)} kg/daa
        </Typography>
      </Grid>
    </Grid>
  );
  return data;
}

/*function getFieldNeedSoilData(fieldFertilizationItem, intl) {
  let soilData = null;
  if (fieldFertilizationItem.soilParamEffect !== null) {
    soilData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'FERTILIZATION_NEED_SOIL' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.soilParamEffect.soilNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.soilParamEffect.soilPhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.soilParamEffect.soilPotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return soilData;
}
function getFieldNeedPreviousCropData(fieldFertilizationItem, intl) {
  let previousCropData = null;
  if (fieldFertilizationItem.previousCropEffect !== null) {
    previousCropData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'FERTILIZATION_NEED_PREVIOUS_CROP' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.previousCropEffect.previousCropNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.previousCropEffect.previousCropPhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.previousCropEffect.previousCropPotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return previousCropData;
}
function getFieldNeedCloverData(fieldFertilizationItem, intl) {
  let cloverData = null;
  if (fieldFertilizationItem.cloverEffect !== null) {
    cloverData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'FERTILIZATION_NEED_CLOVER' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.cloverEffect.cloverNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.cloverEffect.cloverPhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.cloverEffect.cloverPotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return cloverData;
}
function getFieldNeedStravRemovedData(fieldFertilizationItem, intl) {
  let stravRemovedData = null;
  if (fieldFertilizationItem.stravRemovedEffect !== null) {
    stravRemovedData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'FERTILIZATION_NEED_REMOVAL_OF_STRAV' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.stravRemovedEffect.stravRemovedNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.stravRemovedEffect.stravRemovedPhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {fieldFertilizationItem.stravRemovedEffect.stravRemovedPotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return stravRemovedData;
}*/

function getManureTotalContent(fieldFertilizationItem, manureStorageList) {
  if (fieldFertilizationItem.manureStorageDetails?.length > 0) {
    let manureDetails = "";
    return fieldFertilizationItem.manureStorageDetails.map(manureStorageItem => (
      //eslint-disable-next-line
      manureDetails = _.filter(manureStorageList, ['id', manureStorageItem.manureStorageId])[0],
      <Grid container justifyContent="flex-end" sx={{ padding: "8px" }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingCapitalizeText}>
            {manureDetails.name}
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingText}>
            {manureStorageItem.manureCustomQuantity === null
              ? manureStorageItem.manureOriginalQuantity.toFixed(1)
              : manureStorageItem.manureCustomQuantity.toFixed(1)}{' '}
            {manureStorageItem.manureQuantityUnits}
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingText}>
            {manureStorageItem.manureCustomTotalQuantity === null
              ? Math.round(manureStorageItem.manureOriginalTotalQuantity)
              : Math.round(manureStorageItem.manureCustomTotalQuantity)}{' '}
            {manureStorageItem.manureTotalQuantityUnits}
          </Typography>
        </Grid>
      </Grid>
    ));
  }
}

function getMineralFertilizerTotalCount(fieldFertilizationItem, intl) {
  if (fieldFertilizationItem.mineralFertilizersForField?.length > 0) {
    return fieldFertilizationItem.mineralFertilizersForField.map(mineralFertilizerItem => (
      <Grid container justifyContent="flex-end" sx={{ padding: "8px" }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingCapitalizeText}>
            {mineralFertilizerItem.customName === null ? mineralFertilizerItem.originalName : mineralFertilizerItem.customName}
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingText}>
            {mineralFertilizerItem.customQuantity === null
              ? mineralFertilizerItem.originalQuantity.toFixed(1)
              : mineralFertilizerItem.customQuantity.toFixed(1)}{' '}
            {mineralFertilizerItem.quantityUnits}
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingText}>
            {mineralFertilizerItem.customTotalQuantity === null
              ? Math.round(mineralFertilizerItem.originalTotalQuantity)
              : Math.round(mineralFertilizerItem.customTotalQuantity)}{' '}
            {mineralFertilizerItem.totalQuantityUnits}
          </Typography>
        </Grid>
      </Grid>
    ));
  }
}

function getFertilizerPlanforField(fieldFertilizationItem, intl, expandedColumnNeed, expandedColumnActual, expandedColumnAdded, handleExpandActual,
  handleExpandNeed, handleExpandAdded, index, manureStorageList) {
  return (
    <List
      sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0', width: '100%', textAlign: 'center' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton disableRipple sx={{ width: '8%', '&:hover': {backgroundColor: 'transparent'}, display: 'inline-flex' }}>
        <ListItemText style={titleText} sx={{ paddingRight: "50px" }} primary={intl.formatMessage({ id: 'TOTAL' })}/>{true ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div">
          <ListItemButton disableRipple sx={{ width: '100%', paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, '&:focused': {backgroundColor: 'transparent'} }}>
            <div className="fertilization-blue-header" style={{ background: "transparent", borderBottom: '1px solid #BEC8C9', paddingBottom: "20px" }}>
              <div className="fertilization-plan-content">
                <div className="fertilization-plan-grid-blue-border-mui">
                  <div>
                    {getMineralFertilizerTitleHeader(intl)}
                  </div>
                  <div>
                    {getManureTotalContent(fieldFertilizationItem, manureStorageList)}
                    {getMineralFertilizerTotalCount(fieldFertilizationItem, intl)}
                  </div>
                </div>
                <div style= {{ paddingLeft: "20px" }}>
                  <div>
                    <div>
                      {getMineralFertilizerHeader(intl)}
                    </div>
                    <div style={{ paddingLeft:'6px', paddingRight: '6px'}}>
                      {getMineralFertilizerNeed(fieldFertilizationItem, intl, expandedColumnNeed, handleExpandNeed, index)}
                    </div>
                    <div style={{ paddingLeft:'6px', paddingRight: '6px'}}>
                      {getMineralFertilizerActual(fieldFertilizationItem, intl, expandedColumnActual, handleExpandActual, index)}
                    </div>
                    <div style={{ paddingLeft:'6px', paddingRight: '6px'}}>
                      {getMineralFertilizerAdded(fieldFertilizationItem, intl, expandedColumnAdded, handleExpandAdded, index)}
                    </div>
                    <div>
                      {getMineralFertilizerDifference(fieldFertilizationItem, intl)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}

export default ({ fieldFertilizationItem, intl, expandedColumnNeed, expandedColumnActual, expandedColumnAdded, handleExpandActual,
  handleExpandNeed, handleExpandAdded, index, manureStorageList }) => 
  getFertilizerPlanforField(fieldFertilizationItem, intl, expandedColumnNeed, expandedColumnActual, expandedColumnAdded, handleExpandActual,
  handleExpandNeed, handleExpandAdded, index, manureStorageList);
