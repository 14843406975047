import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import SelectControl from 'components/forms/SelectBoxControl';
import TextAreaControl from 'components/forms/TextAreaControl';
import * as userActions from 'actions/Users';
import ProfileImage from 'components/ProfileImage';
import { COMPLETED } from 'constants/index';

class AssigneeNotes extends React.Component {
  componentDidMount() {
    const { farmUsers, userInfo, activeFarm, getUsers } = this.props;
    if (!farmUsers && activeFarm) {
      getUsers(activeFarm.farm.id, userInfo.data.idToken);
    }
  }

  componentDidUpdate(prevProps) {
    const { activeFarm, userInfo, getUsers } = this.props;
    if (prevProps.activeFarm !== activeFarm) {
      getUsers(activeFarm.farm.id, userInfo.data.idToken);
    }
  }

  // To fetch assignee options
  assigneeOptions = () => {
    const { farmUsers, profilePictures } = this.props;
    const { editTaskDetails } = this.props;
    if (farmUsers) {
      let returnValue = [];
      if(editTaskDetails && editTaskDetails.isGetTaskData && editTaskDetails.isGetTaskData.status === COMPLETED) {} else {
        returnValue.push({value: null, label: <FormattedMessage id="ALL" />});
      }
      farmUsers.forEach(data => {
        const userImage = _.find(profilePictures, ['userId', data.user.id]);

        returnValue.push({
          value: data.user.id,
          label: [
            <span className="user-name">
              {data.user.firstName} {data.user.lastName}
            </span>,
            <ProfileImage size="extra-small" imgUrl={userImage ? userImage.image : null} />
          ]

        });
      });
      return returnValue.map(data => ({
          value: data.value,
          label: data.label
      }));
    }
    return [];
  };

  render() {
    return (
      <div className="create-task-assignee-notes">
        <div className="assignee-notes-left-wrapper">
          <div className="assignee-notes-heading">
            <span className="black-dot" />
            <span>
              <FormattedMessage id="TASKS_AN" />
            </span>
          </div>
          <div className="assignee-wrapper">
            <Field
              styleName="select-box-common user-dropdown-normal"
              placeholder={<FormattedMessage id="ASSIGNEE" />}
              options={this.assigneeOptions()}
              component={SelectControl}
              name="assignee"
              controlId="assignee"
              label={<FormattedMessage id="ASSIGNEE" />}
              //disabled={editTaskObj && editTaskObj.status === COMPLETED}
            />
          </div>
        </div>
        <div className="vertical-line" />
        <div className="assignee-notes-right-wrapper">
          <Field
            name="notes"
            label={<FormattedMessage id="NOTE" />}
            component={TextAreaControl}
            stlyeClass="textarea-placeholder-size"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  farmUsers: state.farmUserDetails.userList.users,
  activeFarm: state.farmList.activeFarm,
  userInfo: state.userDetails,
  taskDetails: state.taskDetails,
  profilePictures: state.profileImage.userImage,
  rolePermissions: state.RolesDetails
});

function mapDispatchToProps(dispatch) {
  return {
    getUsers: (farmId, idToken) => dispatch(userActions.getUsers(farmId, idToken))
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssigneeNotes));
