import React from 'react';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import * as taskActions from 'actions/Tasks';
import SelectControl from 'components/forms/SelectBoxControlVehicle';
import Loader from 'components/Loader';
import CustomCalendar from 'containers/tasks/tasklist/CustomCalendar';
import {
  ADD_VEHICLE,
  ADD_EQUIPMENT,
  VEHICLES,
  TYPE4,
  APPROVEDFROM,
  APPROVEDTO
} from 'constants/index';
import { checkPermissions, AssetsDateFormate } from 'utils';
import { EquipmentTranslation, VehicleTranslation } from 'constants/DropdownAndTranslationOptions';
import TextBoxControl from 'components/forms/TextBoxControl';

const height = {
  mobile: '95%',
  tablet: '75%',
  web: '58%'
};

const width = {
  mobile: '90%',
  tablet: '80%',
  web: '30.5%'
};

const minWidth = {
  mobile: '90%',
  tablet: '80%',
  web: '20%'
};

class AddVehicleEquipment extends React.Component {
  state = {
    models: [],
    years: [],
    showDatePicker: false,
    chosenAppFromDate: '',
    chosenAppToDate: ''
  };

  componentDidMount() {
    this.initializeValues();
  }

  componentDidUpdate(prevProps) {
    const { taskDetails, dismissBox, nullifyCreateAssetEntitySuccess } = this.props;
    const { createAssetEntityIsSuccess } = taskDetails;
    if (createAssetEntityIsSuccess) {
      dismissBox();
      nullifyCreateAssetEntitySuccess();
      this.initializeValues();
    }
  }

  // To initialize values in redux form
  initializeValues = () => {
    const { initialize } = this.props;
    const values = {
      type: '',
      brand: '',
      model: '',
      year: ''
    };
    initialize(values);
  };

  // To dismiss the light box upon cancel
  dismissBox = e => {
    const { dismissBox, nullifyCreateAssetEntitySuccess } = this.props;
    dismissBox();
    this.initializeValues();
    nullifyCreateAssetEntitySuccess();
  };

  // To change the options for brand, model, year upon type change
  onTypeChange = option => {
    const { getAssetEntityDetails, userInfo, change, activeFarm } = this.props;
    change('brand', '');
    change('model', '');
    change('year', '');
    getAssetEntityDetails(option.value, activeFarm.farm.id, userInfo.data.idToken);
    this.setState({ models: [], years: [] });
  };

  // To get brand options
  getBrandOptions = () => {
    const { taskDetails } = this.props;
    const { assetDetails } = taskDetails;
    if (assetDetails) {
      return assetDetails.map(data => ({ value: data.brand, label: data.brand }));
    }
    return [];
  };

  // change handler for brand
  onBrandChange = option => {
    const {
      taskDetails: { assetResponse },
      change
    } = this.props;
    const filteredModels = assetResponse.filter(data => data.brand === option.label);
    this.setState({
      models: _.orderBy(
        _.uniqBy(filteredModels, 'model').map(data => ({ value: data.model, label: data.model })),
        [data => data.label.toLowerCase()],
        ['asc']
      ),
      years: []
    });
    change('model', '');
    change('year', '');
  };

  // change handler for model
  onModelChange = option => {
    const {
      taskDetails: { assetResponse },
      change,
      formValues
    } = this.props;
    const chosenBrand = formValues.brand.label;
    const filteredYears = assetResponse.filter(
      data => data.model === option.label && data.brand === chosenBrand
    );
    this.setState({
      years: _.orderBy(
        _.uniqBy(filteredYears, 'year').map(data => ({ value: data.year, label: data.year })),
        [data => data.label],
        ['asc']
      )
    });
    change('year', '');
  };

  // To get type name
  getTypeName = values => {
    const {
      props: {
        taskDetails: { vehicleList, equipmentList },
        assetType
      }
    } = this;
    if (assetType === VEHICLES) {
      return vehicleList.filter(item => item.typeId === values.type.value)[0];
    }
    return equipmentList.filter(item => item.typeId === values.type.value)[0];
  };

  /**
   * To show date picker on focussing harvest date field
   */
  onFocusShowDatePicker = action => {
    this.setState({ showDatePicker: true, action });
  };

  /**
   * To hide date picker
   */
  toggleCalendar = () => {
    this.setState({ showDatePicker: false });
  };

  /**
   * call back from custom calendar
   */
  chosenDetails = (date, action) => {
    const { change } = this.props;
    if (date) {
      if (action === APPROVEDFROM) {
        this.setState({ chosenAppFromDate: date });
        change('approvedfrom', date);
      } else if (action === APPROVEDTO) {
        this.setState({ chosenAppToDate: date });
        change('approvedto', date);
      }
    }
  };

  // To create an asset on submit
  createAsset = values => {
    const { activeFarm, createAssetEntity, userInfo, taskDetails, assetType } = this.props;
    const { assetDetails } = taskDetails;
    const selectedType = this.getTypeName(values);
    if (assetDetails) {
      const brandObj = {
        assetId: '',
        type: {
          typeId: selectedType ? selectedType.typeId : '',
          category: {
            categoryId: assetType === VEHICLES ? 'cat1' : 'cat2',
            categoryName: assetType === VEHICLES ? 'vehicle' : 'equipment'
          },
          typeName: selectedType ? selectedType.typeName : values.type.label
        },
        brand: values.brand.value || '',
        model: values.model.value || '',
        year: values.year.value || 0,
        fromDate: values.approvedfrom ? AssetsDateFormate(values.approvedfrom) : '',
        toDate: values.approvedto ? AssetsDateFormate(values.approvedto) : ''
      };
      createAssetEntity(brandObj, activeFarm.farm.id, userInfo.data.idToken, 'create');
    }
  };

  getAssetType = () => {
    const { type, assetType, intl } = this.props;
    if (assetType === VEHICLES) {
      return _.map(type, item => ({
        value: item.typeId,
        label: intl.formatMessage({ id: VehicleTranslation(item.typeName) })
      }));
    }
    return _.map(type, item => ({
      value: item.typeId,
      label: intl.formatMessage({ id: EquipmentTranslation(item.typeName) })
    }));
  };

  render() {
    const {
      show,
      title,
      handleSubmit,
      taskDetails,
      intl,
      activeFarm,
      rolePermissions,
      assetType,
      formValues,
      userInfo
    } = this.props;
    const {
      models,
      years,
      showDatePicker,
      action,
      chosenAppFromDate,
      chosenAppToDate
    } = this.state;
    const {
      createAssetEntityIsLoading,
      getAssetEntityIsLoading,
      createAssetEntityErrorMsg,
      createAssetEntityIsFailure
    } = taskDetails;
    const disableFields = !checkPermissions(
      assetType === VEHICLES ? ADD_VEHICLE : ADD_EQUIPMENT,
      activeFarm,
      rolePermissions
    );
    return (
      <ModalDialog
        show={show}
        closeIcon={<span />}
        onClose={() => {}}
        height={height}
        width={width}
        minWidth={minWidth}
      >
        <div className="light-box-container full-width">
          <div className="light-box-heading full-width">
            {title && intl.formatMessage({ id: title })}
          </div>
          <div className="light-box-content full-width">
            <div className="light-box-dropdown full-width">
              <Field
                styleName="select-box-common"
                placeholder={intl.formatMessage({ id: 'TYPE' })}
                options={this.getAssetType()}
                component={SelectControl}
                onChange={v => this.onTypeChange(v)}
                name="type"
                controlId="type"
                disabled={disableFields}
                isCreatable
                labelName={intl.formatMessage({ id: 'create' })}
              />
            </div>
            <div className="light-box-dropdown full-width">
              <Field
                styleName="select-box-common"
                placeholder={intl.formatMessage({ id: 'BRAND' })}
                options={this.getBrandOptions()}
                onChange={v => this.onBrandChange(v)}
                component={SelectControl}
                name="brand"
                controlId="brand"
                disabled={disableFields}
                defaultValue={formValues && formValues.brand}
                isCreatable
                labelName={intl.formatMessage({ id: 'create' })}
              />
            </div>
            <div className="light-box-dropdown model-year full-width">
              <div className="light-box-dropdown-model">
                <Field
                  styleName="select-box-common"
                  options={models}
                  onChange={v => this.onModelChange(v)}
                  component={SelectControl}
                  name="model"
                  controlId="model"
                  menuPlacement="top"
                  placeholder={intl.formatMessage({ id: 'MODEL' })}
                  disabled={disableFields}
                  defaultValue={formValues && formValues.model}
                  isCreatable
                  labelName={intl.formatMessage({ id: 'create' })}
                />
              </div>
              <div className="light-box-dropdown-year">
                <Field
                  styleName="select-box-common"
                  placeholder={intl.formatMessage({ id: 'YEAR' })}
                  options={years}
                  component={SelectControl}
                  name="year"
                  controlId="year"
                  menuPlacement="top"
                  disabled={disableFields}
                  defaultValue={formValues && formValues.year}
                  isCreatable
                  labelName={intl.formatMessage({ id: 'create' })}
                />
              </div>
            </div>
            {formValues && formValues.type && formValues.type.value === TYPE4.id && (
              <div className="full-width asset-approved-dates">
                <Field
                  name="approvedfrom"
                  label={intl.formatMessage({ id: 'APPROVEDFROM' })}
                  component={TextBoxControl}
                  type="text"
                  stlyeClass="approvedfromdate"
                  placeholdertext={intl.formatMessage({ id: 'ENTER_DATE' })}
                  onFocus={() => this.onFocusShowDatePicker('approvedfrom')}
                />
                <Field
                  name="approvedto"
                  label={intl.formatMessage({ id: 'APPROVEDTO' })}
                  component={TextBoxControl}
                  type="text"
                  stlyeClass="approvedtodate"
                  placeholdertext={intl.formatMessage({ id: 'ENTER_DATE' })}
                  onFocus={() => this.onFocusShowDatePicker('approvedto')}
                />
              </div>
            )}
            {showDatePicker && (
              <CustomCalendar
                toggleCalendar={this.toggleCalendar}
                sendDetails={this.chosenDetails}
                approvedDates
                approvedDateFrom={chosenAppFromDate}
                approvedDateTo={chosenAppToDate}
                lang={userInfo.data.preferredLanguage}
                action={action}
              />
            )}
          </div>
          <div className="create-asset-entity-error full-width">
            <p className="error">{createAssetEntityIsFailure ? createAssetEntityErrorMsg : ''}</p>
          </div>
          <div className="light-box-footer full-width">
            <FormattedMessage id="CANCEL">
              {CANCEL => (
                <ActionButton
                  multi
                  disabled={createAssetEntityIsLoading || getAssetEntityIsLoading}
                  onClick={this.dismissBox}
                >
                  {CANCEL}
                </ActionButton>
              )}
            </FormattedMessage>
            <FormattedMessage id="ADD">
              {ADD => (
                <ActionButton
                  onClick={handleSubmit(this.createAsset)}
                  disabled={createAssetEntityIsLoading || getAssetEntityIsLoading || disableFields}
                >
                  {ADD}
                </ActionButton>
              )}
            </FormattedMessage>
          </div>
        </div>
        {(createAssetEntityIsLoading || getAssetEntityIsLoading) && (
          <Loader styleName="task-loader" />
        )}
      </ModalDialog>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { intl } = props;
  if (!values.type) {
    errors.type = intl.formatMessage({ id: 'required' });
  }
  if (!values.model) {
    errors.model = intl.formatMessage({ id: 'required' });
  }
  if (!values.brand) {
    errors.brand = intl.formatMessage({ id: 'required' });
  }
  return errors;
}

const mapDispatchToProps = dispatch => ({
  getAssetEntityDetails: (typeId, farmId, token) =>
    dispatch(taskActions.getAssetEntityDetails(typeId, farmId, token)),
  createAssetEntity: (data, farmId, token, action) =>
    dispatch(taskActions.createAssetEntity(data, farmId, token, action)),
  nullifyCreateAssetEntitySuccess: () => dispatch(taskActions.nullifyCreateAssetEntitySuccess())
});

const mapStateToProps = state => ({
  userInfo: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  taskDetails: state.taskDetails,
  formValues: getFormValues('AddVehicleEquipmentForm')(state),
  rolePermissions: state.RolesDetails
});

export default injectIntl(
  reduxForm({
    form: 'AddVehicleEquipmentForm',
    validate
  })(connect(mapStateToProps, mapDispatchToProps)(AddVehicleEquipment))
);
