import * as React from 'react';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import Text from '@tine/lib-frontend-components/elements/typography/text';

export default ({ values }) => (
  <SectionContainer
    styling={{
      backgroundColor: 'history'
    }}
  >
    <div className="manure-storage-calc__data --list-content">
      {
        Object.entries(values).map(([key, value]) => {
        const valueArray = [];
        // eslint-disable-next-line
        {value != null && value.map((output) => {
          return valueArray.push(output.name);
        })}
        return (
          <div className="manure-storage__calculated-value" key={key}>
            <p className="manure-storage_calc-label">
              <Text size="extralarge">{key}</Text>
            </p>
            <p>
              <Emphasis>{valueArray.length > 0 ? valueArray.join(', ') : '-'}</Emphasis>
            </p>
          </div>
        );
      })}
    </div>
  </SectionContainer>
);
