/**
 * Loader Page.
 * required @props { StyleName }
 */

import React from 'react';
import LoadingView from '@tine/lib-frontend-components/components/animations/loading-view';

const LoaderPage = ({ styleName }) => (
  <div className={`loading-wrapper ${styleName}`}>
    <LoadingView loadingText="Loading..." />
  </div>
);

export default LoaderPage;
