import React from 'react';

const SelectedBaleCard = ({
    intl,
    index,
    selectedBaleList,
    onSelectedAnalysis,
    highlightAnalysis
  }) => (
  <li className={`storage-list-item full-width`} key='1'>
    <div className={`storage-list-item-selected-link ${selectedBaleList.analysisEventId ? 'style-cursor' : ''} ${index === highlightAnalysis ? 'set-background-color' : ''}`}
    onClick={() => { selectedBaleList.analysisEventId && onSelectedAnalysis(selectedBaleList.analysisEventId,index); }}
    >
      <div className="mediumWidth padding-top-div-small">
        <span className="label-number">{selectedBaleList.quantity}</span>
      </div>
      <div className="largeWidth">
        <span>
          <div>
            <span className="medium-font-span">{intl.formatMessage({ id: 'SELECTED_BALE_TITLE' })}</span>
            <span className="medium-font-span-bold">{selectedBaleList.fieldName}</span>
          </div>
          <div className="padding-top-div-extra-small">
            <span>
              <span className="small-font-span">{intl.formatMessage({ id: 'YEAR' })}:</span>
              <span className="small-font-span-bold">{selectedBaleList.year}</span>
            </span>
            {selectedBaleList.dryMatter &&
            <span>
              <span className="small-font-span">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_2' })}:</span>
              <span className="small-font-span-bold">{selectedBaleList.dryMatter}%</span>
            </span>}
            {selectedBaleList.rawProtein &&
            <span>
              <span className="small-font-span">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_3' })}:</span>
              <span className="small-font-span-bold">{selectedBaleList.rawProtein}g/kg TS</span>
            </span>}
          </div>
        </span>
      </div>
      <div className="smallWidth padding-top-div-extra-small">
        {selectedBaleList.analysisEventId &&
        <img src={`./images/bale_analysis.png`} className="bale-icon-size-link" alt="draw" />}
      </div>
    </div>
  </li>
);

export default SelectedBaleCard;
