import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import AutoplacementGridSection from '@tine/lib-frontend-components/components/alignment/autoplacement-grid-section';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import StorageColumn from 'mui-components/storages/balestorage/storagecard/StorageColumn';
import * as Bale from 'actions/Bale';
import AddBaleLink from 'mui-components/storages/balestorage/storagecard/AddBaleLink';
import SchedulerColumn from 'mui-components/storages/balestorage/storagecard/SchedulerColumn';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import * as BaleAnalysisActions from 'actions/BaleAnalysis';
import mixpanel from 'mixpanel-browser';
import { ENTER_BALE_STORAGE } from 'constants/MixPanel';
import * as SchedulerBaleActions from 'actions/SchedulerBale';
import Loader from 'components/Loader';

class BaleStorageList extends React.Component {
  componentDidMount = () => {
    const { history, activeFarm, baleList, getAnalysisStorage, getAllScheduledBales, userDetails } = this.props;
    const currentYear = new Date().getFullYear();
    if (!activeFarm) {
      history.push('/settings');
    }
    mixpanel.identify(userDetails.id);
    mixpanel.track(ENTER_BALE_STORAGE);
    baleList(activeFarm && activeFarm.farm.id, currentYear);
    getAnalysisStorage(activeFarm && activeFarm.farm.id);
    getAllScheduledBales(activeFarm && activeFarm.farm.id);
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, baleList, getAnalysisStorage } = this.props;
    const currentYear = new Date().getFullYear();
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storage');
      baleList(activeFarm.farm.id, currentYear);
      getAnalysisStorage(activeFarm.farm.id);
    }
  }

  render() {
    const { bales, intl, getAnalysisStorageList, activeFarm, hasSupportAdminRole } = this.props;
    const enableCreateButton = 
    activeFarm && activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    const { getStorageLoading } = getAnalysisStorageList;
    return (
      <SectionContainer>
        {getStorageLoading && <Loader styleName="fixed-loader" />}
        <div className="stats-activity balesFlexiGrid">
          <div className='text-header'>
            <span className='text-header-left'>
              {intl.formatMessage({ id: 'BALE_STORAGE_HEADER' })} ({bales.data && bales.data.quantity > 0 ? bales.data.quantity : 0} {intl.formatMessage({ id: 'BALE_OVERVIEW_TOTAL' })})
            </span>
            <span className='text-header-right'>
              <AddBaleLink 
                getAnalysisStorageListData = {getAnalysisStorageList.getStorageData}
              />
            </span>
          </div>
          {bales.data && 
            <AutoplacementGridSection className="stats-activity__grid">
              {bales.data && bales.data.harvests.length > 0 && 
              <StorageColumn
                title={intl.formatMessage({ id: 'BALE_OVERVIEW_HARVEST_TITLE' })}
                data={bales.data}
                column="harvest"
                intl={intl}
                enableCreateButton={enableCreateButton}
              />}
              {bales.data && bales.data.storages.length > 0 && 
              <StorageColumn
                title={intl.formatMessage({ id: 'BALE_OVERVIEW_STORAGE_TITLE' })}
                data={bales.data}
                column="bale"
                intl={intl}
                enableCreateButton={enableCreateButton}
              />}
              <SchedulerColumn />
            </AutoplacementGridSection>
          }
        </div>
      </SectionContainer>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  bales: state.bales,
  getAnalysisStorageList: state.baleAnalysis,
  userDetails: state.userDetails.data,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  baleList: (farmId, year) => dispatch(Bale.getBaleList(farmId, year)),
  getAnalysisStorage: (farmId) => dispatch(BaleAnalysisActions.getAllStorages(farmId)),
  getAllScheduledBales: (farmId) => dispatch(SchedulerBaleActions.getAllScheduledBales(farmId)),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(BaleStorageList)));
