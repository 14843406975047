import {
  FIELD_WEATHER_LOADING, 
  FIELD_WEATHER_SUCCESS, 
  FIELD_WEATHER_ERROR,
  OMD_GOALS_LOADING,
  OMD_GOALS_SUCCESS,
  OMD_GOALS_ERROR
} from 'constants/store/Fields';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  weatherData: null,
  omdHasError: false,
  omdIsLoading: false,
  omdErrorMessage: null,
  omdData: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FIELD_WEATHER_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case FIELD_WEATHER_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case FIELD_WEATHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        weatherData: action.payload
      };
    case OMD_GOALS_LOADING:
      return {
        ...state,
        omdHasError: false,
        omdIsLoading: true,
        omdErrorMessage: null
      };
    case OMD_GOALS_ERROR:
      return {
        ...state,
        omdHasError: true,
        omdIsLoading: false,
        omdErrorMessage: action.payload,
      };
    case OMD_GOALS_SUCCESS:
      return {
        ...state,
        omdIsLoading: false,
        omdErrorMessage: null,
        omdHasError: false,
        omdData: action.payload
      };
    default:
      return state;
  }
}
