import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as assetAction from 'actions/Assets';
import AddAsset from 'mui-components/settings/vehicles/AddAsset';
import AssetList from 'mui-components/settings/vehicles/AssetsLists';
import Loader from 'components/Loader';
import { VEHICLE, ADMIN, OWNER, ADVISOR } from 'constants/index';
import AddVehicleModal from 'mui-components/settings/vehicles/AddVehicleModal';

class Assets extends React.Component {
  componentDidMount() {
    const { getAssetsList, activeFarm } = this.props;
    this.clearMessages();
    if (activeFarm) {
      getAssetsList(VEHICLE, activeFarm.farm.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { activeFarm, getAssetsList } = this.props;
    if (activeFarm !== prevProps.activeFarm) {
      getAssetsList(VEHICLE, activeFarm.farm.id);
    }
  }

  componentWillUnmount() {
    this.clearMessages();
  }

  clearMessages = () => {
    const { clearAssetMessage } = this.props;
    clearAssetMessage();
  };

  render() {
    const { activeFarm, hasSupportAdminRole, assetsDetails, assetType, userInfo, vehicleProps, intl } = this.props;
    const { isAssetLoading, assets, isAssetListSuccess } = assetsDetails;
    const enableAssetButton = (activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data);
    return (
      <div className="asset-list-wrapper">
        {isAssetLoading && <Loader styleName="fixed-loader" />}
        {isAssetListSuccess && assets[assetType]?.length === 0 && (
          <AddAsset disabled={!enableAssetButton || isAssetLoading} category={assetType} userInfo={userInfo} vehicleProps={vehicleProps} />
        )}
        {assets[assetType]?.length > 0 && (
          <AssetList disabled={!enableAssetButton} assetType={assetType} userInfo={userInfo} vehicleProps={vehicleProps} />
        )}
        <AddVehicleModal
          intl={intl}
          vehicleProps={vehicleProps}
          assetType={assetType}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAssetsList: (category, farmId) => dispatch(assetAction.getFarmAssetList(category, farmId)),
  clearAssetMessage: () => dispatch(assetAction.clearAssetMessage())
});

const mapStateToProps = state => ({
  userInfo: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  rolePermissions: state.RolesDetails,
  assetsDetails: state.assetsDetails,
  hasSupportAdminRole: state.SupportAdminRole
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Assets));
