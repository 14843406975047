import { IS_OFFLINE, IS_ONLINE, SHOW_NETWORK_ERROR } from 'constants/index';

const initialState = {
  isOnline: null,
  show: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_ONLINE:
      return {
        isOnline: true,
        show: false
      };
    case IS_OFFLINE:
      return {
        isOnline: false,
        show: true
      };
    case SHOW_NETWORK_ERROR:
      return {
        isOnline: false,
        show: action.show
      };
    default:
      return state;
  }
};
