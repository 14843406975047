import * as React from 'react';
import { Dialog, DialogTitle, Chip, Box, Stack, Grid } from '@mui/material';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import { buttonStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';

const dialogTitleStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "24px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "left", color: "#191C1C" }
const dialogSubTitleStyle = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px", paddingTop: "0px"  }
const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", padding: "0px 30px" };
const scrollStyle = {
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'thin',
  scrollbarColor: '#6F7979 #E6E9E8'
}

const AddFarmModal = ({intl, onCreateFarm, closeNewFarm, farmNameValue, organizationalNumberValue, billingEmailValue, addressValue, postalCodeValue, regionValue, 
  farmNameHelperText, organizationalNumberHelperText, billingEmailText, addressHelperText, postalCodeHelperText, regionHelperText, handleChange, disableButton, handleSubmit, isAddFarmPage}) => (
  <div>    
    <Dialog onClose={() => {}} open={onCreateFarm} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }}}>
      <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'MANAGE_FARMS_DIALOG_TITLE' })}</DialogTitle>
      <Box component="div" style={scrollStyle}>
        <Box component="div" style={dialogSubTitleStyle}>
          {intl.formatMessage({ id: 'MANAGE_FARMS_DIALOG_CONTENT' })}
        </Box>
        <div className="manageFarmsLayoutDialogDiv">
          <div><img src="images/FarmLightGreen.png" alt="Farm" /></div>
          <Box style={inputStyle}>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="farmName"
                id="farmName"
                label={intl.formatMessage({ id: 'AF_FN' })}
                variant="outlined"
                showFullWidth="true"
                value={farmNameValue}
                helperText={farmNameHelperText}
                onChange={handleChange}
              />
            </Stack>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="organizationalNumber"
                id="organizationalNumber"
                label={intl.formatMessage({ id: 'AF_ORGANIZATIONALNUMBER' })}
                variant="outlined"
                showFullWidth="true"
                value={organizationalNumberValue}
                helperText={organizationalNumberHelperText}
                onChange={handleChange}
              />
            </Stack>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="billingEmail"
                id="billingEmail"
                label={intl.formatMessage({ id: 'BILLING_EMAIL' })}
                variant="outlined"
                showFullWidth="true"
                value={billingEmailValue}
                helperText={billingEmailText}
                onChange={handleChange}
              />
            </Stack>
            <Stack className='text-with-padding'>
              <MuiTextBox 
                name="address"
                id="address"
                label={intl.formatMessage({ id: 'AF_ADD' })}
                variant="outlined"
                showFullWidth="true"
                value={addressValue}
                helperText={addressHelperText}
                onChange={handleChange}
              />
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack>
                  <MuiTextBox 
                    name="postalCode"
                    id="postalCode"
                    label={intl.formatMessage({ id: 'AF_ZIP' })}
                    variant="outlined"
                    showFullWidth="true"
                    value={postalCodeValue}
                    helperText={postalCodeHelperText}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack>
                  <MuiTextBox 
                    name="region"
                    id="region"
                    label={intl.formatMessage({ id: 'AF_REG' })}
                    variant="outlined"
                    showFullWidth="true"
                    value={regionValue}
                    helperText={regionHelperText}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div style={{padding: "30px 24px", textAlign: "right"}}>
          <span>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={buttonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              onClick={() => closeNewFarm()}
              disableRipple
            />
            <Chip
              label={isAddFarmPage ? intl.formatMessage({ id: 'create' }) : intl.formatMessage({ id: 'SAVE' })}
              clickable
              className={`${disableButton ? 'disabled' : ''}`}
              style={buttonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              onClick={() => handleSubmit()}
              disableRipple
            />
          </span>
        </div>
      </Box>
    </Dialog>
  </div>
);

export default AddFarmModal;