import {
  ADD_GRASS_ANALYSIS_LOADING,
  ADD_GRASS_ANALYSIS_SUCCESS,
  ADD_GRASS_ANALYSIS_ERROR,
  GET_GRASS_ANALYSIS_LOADING,
  GET_GRASS_ANALYSIS_SUCCESS,
  GET_GRASS_ANALYSIS_ERROR,
  DELETE_GRASS_ANALYSIS_LOADING,
  DELETE_GRASS_ANALYSIS_SUCCESS,
  DELETE_GRASS_ANALYSIS_ERROR
} from 'constants/store/GrassAnalysis';

const initialState = {
  isLoading: false,
  isAddLoading: false,
  grassAnalysisList: null,
  listHasError: false,
  listHasSuccess: false,
  addHasError: false,
  addHasSuccess: false,
  errorMsg: null,
  deleteHasSuccess: false,
  deleteHasError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_GRASS_ANALYSIS_LOADING:
      return {
        ...state,
        isAddLoading: true,
        isLoading: false,
        listHasError: false,
        listHasSuccess: false,
        addHasError: false,
        addHasSuccess: false,
        deleteHasSuccess: false,
        deleteHasError: false,
        errorMsg: null
      };
    case GET_GRASS_ANALYSIS_LOADING:
    case DELETE_GRASS_ANALYSIS_LOADING:
      return {
        ...state,
        isAddLoading: false,
        isLoading: true,
        listHasError: false,
        listHasSuccess: false,
        addHasError: false,
        addHasSuccess: false,
        deleteHasSuccess: false,
        deleteHasError: false,
        errorMsg: null
      };
    case ADD_GRASS_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAddLoading: false,
        listHasError: false,
        listHasSuccess: false,
        addHasError: false,
        addHasSuccess: true,
        deleteHasSuccess: false,
        deleteHasError: false,
        errorMsg: null
      };
    case ADD_GRASS_ANALYSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        isAddLoading: false,
        listHasError: false,
        listHasSuccess: false,
        addHasError: true,
        addHasSuccess: false,
        deleteHasSuccess: false,
        deleteHasError: false,
        errorMsg: action.payload
      };
    case GET_GRASS_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAddLoading: false,
        listHasError: false,
        listHasSuccess: true,
        addHasError: false,
        addHasSuccess: false,
        deleteHasSuccess: false,
        deleteHasError: false,
        errorMsg: null,
        grassAnalysisList: action.payload
      };
    case GET_GRASS_ANALYSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        isAddLoading: false,
        listHasError: true,
        listHasSuccess: false,
        addHasError: false,
        addHasSuccess: false,
        deleteHasSuccess: false,
        deleteHasError: false,
        errorMsg: action.payload
      };
    case DELETE_GRASS_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAddLoading: false,
        listHasError: false,
        listHasSuccess: true,
        addHasError: false,
        addHasSuccess: false,
        deleteHasSuccess: true,
        deleteHasError: false,
        errorMsg: null
      };
    case DELETE_GRASS_ANALYSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        isAddLoading: false,
        listHasError: true,
        listHasSuccess: false,
        addHasError: false,
        addHasSuccess: false,
        deleteHasSuccess: false,
        deleteHasError: true,
        errorMsg: action.payload
      };
    default:
      return state;
  }
};