import * as React from 'react';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import AddIcon from '@tine/lib-frontend-components/elements/icons/add-icon/index';
import HarvestCard from 'mui-components/storages/balestorage/storagecard/HarvestCard';
import BaleStorageCard from 'mui-components/storages/balestorage/storagecard/BaleStorageCard';
import { Link } from 'react-router-dom';

export default ({
  title,
  data,
  column,
  intl,
  enableCreateButton
}) => (
  <FlexGridItem>
    <div className={`storage-box storage-box--users`}>
      <div className="storage-box__title storage-column-padding">
        <div className='floatLeft'>
          <Emphasis size="normal">{title}</Emphasis>
        </div>
      </div>
      <div className="vehicle-list-flex-container full-width">
        <div className="storage-list-details">
          <ul className="storage-list-wrapper">
            {column === "harvest" &&
            data.harvests.map((harvest, index) => (
              <HarvestCard
                key={index+1}
                harvest = {harvest}
                index = {index}
                harvestLength = {data.harvests.length}
                link = {`/storage/harvest/${harvest.harvest}`}
                intl = {intl}
              />
            ))}
            {column === "bale" &&
            data.storages.map((storage, index) => (
              <BaleStorageCard
                key={index+1}
                storage = {storage}
                index = {index}
                storageLength = {data.storages.length}
                link = {`/storage/bale/${storage.id}`}
                intl = {intl}
                hasBottom = {enableCreateButton}
              />
            ))}
            {column === "bale" && enableCreateButton &&
            <li className={`add-button-list-item`} key="4">
              <div className="add-harvest">
                <Link to="/storage/bale-storage/create">
                  <span className="pad-right">{intl.formatMessage({ id: 'CREATE_BALE_STORAGE' })}</span>
                  <div className="add-icon">
                    <AddIcon />
                  </div>
                </Link>
              </div>
            </li>}
          </ul>
        </div>
      </div>
    </div>
  </FlexGridItem>
);
