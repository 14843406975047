import React from 'react';
import Assets from 'mui-components/settings/equipments/Assets';
import { EQUIPMENT } from 'constants/index';

const ManageEquipments = (equipmentProps) => (
  <Assets
    assetType={EQUIPMENT}
    equipmentProps={equipmentProps}
  />
);

export default ManageEquipments;
