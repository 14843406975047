import React from 'react';
import { FormattedMessage } from 'react-intl';

// Task Sorting
export const TaskSortingOptions = [
  {
    value: 'Date created',
    label: 'DATE_CREATED',
    transLbl: <FormattedMessage id="DATE_CREATED" />
  },
  { value: 'field', label: <FormattedMessage id="FIELD" />, transLbl: <FormattedMessage id="FIELD" /> },
  { value: 'user', label: <FormattedMessage id="USER" /> },
  { value: 'activity', label: <FormattedMessage id="ACTIVITY" /> },
  { value: 'status', label: <FormattedMessage id="STATUS" /> }
];

// Task Sorting
export let TaskPeriodOptions = [{ value: null, label: <FormattedMessage id="ALL" /> }];
const previousYear = new Date().getFullYear() - 1;
const currentYear = new Date().getFullYear();
TaskPeriodOptions.push({ value: currentYear, label: 
  <div>
    <span>{currentYear}</span>
  </div>
});
TaskPeriodOptions.push({ value: previousYear, label: 
  <div>
    <span>{previousYear}</span>
  </div>
});
TaskPeriodOptions.push(
  {
    value: '-1',
    label: <FormattedMessage id="REPORTS_YESTERDAY" />
  },
  { value: '-7', label: <FormattedMessage id="REPORTS_LST7DAY" /> },
  { value: '-30', label: <FormattedMessage id="REPORTS_R_L30D" /> },
  { value: '-90', label: <FormattedMessage id="REPORTS_LST90DAY" /> },
  { value: '-180', label: <FormattedMessage id="REPORTS_LST180DAY" /> });

export let selectTaskPeriodOptions = [{ value: "", label: "ALL" }];
selectTaskPeriodOptions.push({ value: currentYear, label: currentYear });
selectTaskPeriodOptions.push({ value: previousYear, label: previousYear });
selectTaskPeriodOptions.push({ value: '-1', label: "REPORTS_YESTERDAY" },
  { value: '-7', label: "REPORTS_LST7DAY" },
  { value: '-30', label: "REPORTS_R_L30D" },
  { value: '-90', label: "REPORTS_LST90DAY" },
  { value: '-180', label: "REPORTS_LST180DAY" });

/**
 * Get the activity transaltion ID
 * @param {String} activity
 * @returns {string}
 */
export const ActivityTranslation = activity => {
  switch (activity.toLowerCase()) {
    case 'sowing':
      return 'SOWING';
    case 'ploughing':
      return 'PLOUGHING';
    case 'planting':
      return 'PLANTING';
    case 'rolling':
      return 'ROLLING';
    case 'mowing':
      return 'MOWING';
    case 'fertilization':
      return 'FERTILIZATION';
    case 'harrowing':
      return 'HARROWING';
    case 'precision chop silaging':
      return 'PRECISION_CHOP_SILAGING';
    case 'forage wagon harvesting':
      return 'FORAGE_WAGON_HARVESTING';
    case 'combi baling':
      return 'BALING_COMBI';
    case 'baling':
      return 'BALING';
    case 'treshing':
      return 'TRESHING';
    case 'aerate gras':
      return 'AERATE_GRAS';
    case 'raking':
      return 'RAKING';
    case 'bale wrapping':
      return 'BALE_WRAPPING';
    case 'transportation wagon':
      return 'TRANS_WAGONS';
    case 'transportation bales':
      return 'TRANS_BALES';
    case 'pesticide application':
      return 'PESTICIDE_APP';
    case 'combo fertilization and sowing':
      return 'COMBO_FERT_SOW';
    case 'subsurface drainage':
      return 'SUB_SURFACE_DRAINAGE';
    case 'grubbing':
      return 'GRUBBING';
    case 'stone picking':
      return 'STONE_PICKING';
    case 'dragging':
      return 'DRAGING';
    case 'pasture topping':
      return 'PASTURE_TOPPING';
    case 'disking':
      return 'DISKING';
    case 'self-loading wagon':
      return 'SELF_LOADING_WAGON';
    case 'other':
      return 'OTHER';
    case 'empty activity':
      return 'EMPTY_ACTIVITY';
    default:
      return activity;
  }
};

/**
 * Get the role transaltion id
 * @param {String} activity
 * @returns {string}
 */
export const RoleTranslation = role => {
  switch (role) {
    case 'role1':
      return 'ADMIN';
    case 'role2':
      return 'OWNER';
    case 'role3':
      return 'EMPLOYEE';
    case 'role4':
      return 'CONTRACTOR';
    case 'role5':
      return 'GUEST';
    default:
      return role;
  }
};

// Vehicle sorting
export const AssetSortingOptions = [
  {
    value: 'Date created',
    label: 'DATE_CREATED',
    transLbl: <FormattedMessage id="DATE_CREATED" />
  },
  { value: 'name', label: 'NAME' },
  { value: 'year', label: 'YEAR' }
];

/**
 * To translate equipments
 * @param {string} equipment
 * @returns {string}
 */
export const EquipmentTranslation = equipment => {
  if (equipment) {
    switch (equipment.toLowerCase()) {
      case 'bale mover':
        return 'BALE_MOVER';
      case 'bale packer':
        return 'BALE_PACKER';
      case 'combi baler':
        return 'COMBI_BALER';
      case 'combi seeder':
        return 'COMBI_SEEDER';
      case 'fertilization spreader':
        return 'FERTILIZATION_SPREADER';
      case 'forage wagon harvester':
        return 'FORAGE_WAGON_HARVESTER';
      case 'gras wagon':
        return 'GRAS_WAGON';
      case 'handling equipment':
        return 'HANDLING_EQUIPMENT';
      case 'harrow':
        return 'HARROW';
      case 'loader wagon':
        return 'LOADER_WAGON';
      case 'mower':
        return 'MOWER';
      case 'pasture topper':
        return 'PASTURE_TOPPE';
      case 'plough':
        return 'PLOUGH';
      case 'precision-chop forage harvester':
        return 'PRECISION_CHOP_HARVESTER';
      case 'rake':
        return 'RAKE';
      case 'roller':
        return 'ROLLER';
      case 'round baler':
        return 'ROUND_BALER';
      case 'seeder':
        return 'SEEDER';
      case 'slurry equipment':
        return 'SLURRY_EQUIPMENT';
      case 'sprayer':
        return 'SPRAYER';
      case 'stone picker':
        return 'STONE_PICKER';
      case 'subsoiler':
        return 'SUBSOILER';
      case 'tedder':
        return 'TEDDER';
      default:
        return equipment;
    }
  }
  return equipment;
};

/**
 * To translate vehicle
 * @param {string} vehicle
 * @returns {string}
 */
export const VehicleTranslation = vehicle => {
  if (vehicle) {
    switch (vehicle.toLowerCase()) {
      case 'tractor':
        return 'TRACTOR';
      default:
        return vehicle;
    }
  }
  return vehicle;
};

export const FertilizerTypeTranslation = value => {
  switch (value.toLowerCase()) {
    case 'manure':
      return 'MANURE';
    case 'liming':
      return 'LIMING';
    case 'foilar fertilizer':
      return 'FOILAR_FERTILIZER';
    case 'mineral fertilizer':
      return 'MINERALFERTILIZER_TASK';
    default:
      return value;
  }
};

export const ManureTypeTranslation = value => {
  switch (value.toLowerCase()) {
    case 'dairy cow w/ youngling':
      return 'DAIRY_COW';
    case 'suckling cow':
      return 'SUCKLING_COW';
    case 'cow':
      return 'COW';
    case 'poultry':
      return 'POULTRY';
    case 'pig':
      return 'PIG';
    case 'sheep':
      return 'SHEEP';
    case 'other':
      return 'OTHER';
    default:
      return value;
  }
};

export const SelectAllOption = {
  label: <FormattedMessage id="SELECT_ALL" />,
  value: '**'
};
