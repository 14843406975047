import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const DeleteIcon = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon">
      <path id="icon_2" fill-rule="evenodd" clip-rule="evenodd" d="M15.8594 3.66797V4.66797H20.8594V6.66797H19.8594V19.668C19.8594 20.768 18.9594 21.668 17.8594 21.668H7.85938C6.75937 21.668 5.85938 20.768 5.85938 19.668V6.66797H4.85938V4.66797H9.85938V3.66797H15.8594ZM7.85938 19.668H17.8594V6.66797H7.85938V19.668ZM9.85938 8.66797H11.8594V17.668H9.85938V8.66797ZM15.8594 8.66797H13.8594V17.668H15.8594V8.66797Z" fill="#BA1A1A"/>
    </g>
  </svg>,
  'Delete',
);

export const ErrorIcon = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
      <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M12.3594 2.1582C6.83938 2.1582 2.35938 6.6382 2.35938 12.1582C2.35938 17.6782 6.83938 22.1582 12.3594 22.1582C17.8794 22.1582 22.3594 17.6782 22.3594 12.1582C22.3594 6.6382 17.8794 2.1582 12.3594 2.1582ZM11.3594 17.1582V15.1582H13.3594V17.1582H11.3594ZM11.3594 7.1582V13.1582H13.3594V7.1582H11.3594Z" fill="#E5BA28"/>
    </g>
  </svg>,
  'Error',
);

export const ErrorGreenIcon = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
      <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M12.3594 2.1582C6.83938 2.1582 2.35938 6.6382 2.35938 12.1582C2.35938 17.6782 6.83938 22.1582 12.3594 22.1582C17.8794 22.1582 22.3594 17.6782 22.3594 12.1582C22.3594 6.6382 17.8794 2.1582 12.3594 2.1582ZM11.3594 17.1582V15.1582H13.3594V17.1582H11.3594ZM11.3594 7.1582V13.1582H13.3594V7.1582H11.3594Z" fill="#00696D"/>
    </g>
  </svg>,
  'Error',
);

export const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-4 -4 32 32"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  'Plus',
);

export const EditIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.06 3.59L20.41 4.94C21.2 5.72 21.2 6.99 20.41 7.77L7.18 21H3V16.82L13.4 6.41L16.23 3.59C17.01 2.81 18.28 2.81 19.06 3.59ZM5 19L6.41 19.06L16.23 9.23L14.82 7.82L5 17.64V19Z" fill="#00696D"/>
  </svg>,
  'Edit',
);

export const EditGreyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.06 3.59L20.41 4.94C21.2 5.72 21.2 6.99 20.41 7.77L7.18 21H3V16.82L13.4 6.41L16.23 3.59C17.01 2.81 18.28 2.81 19.06 3.59ZM5 19L6.41 19.06L16.23 9.23L14.82 7.82L5 17.64V19Z" fill="#3F4949"/>
  </svg>,
  'Edit',
);

export const CircleIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 6.51472 11.4853 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5Z" fill="#339999"/>
  </svg>,
  'Circle',
);
