import {
  API_ENDPOINT_V3,
  GET
} from 'constants/index';
import { 
  GET_TASK_LOADING,
  GET_TASK_SUCCESS_NULL,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE
} from 'constants/store/Tasks';

import { GetLanguageCode } from 'utils/index';
import apiRequest from 'utils/ApiRequest';

// Get the task details based on task Id
export let getTask = (farmId, taskId) => async (dispatch, getState) => {
  try {
    if(taskId === 'create') {
      dispatch({ type: GET_TASK_SUCCESS_NULL, payload: null });
    } else {
      dispatch({ type: GET_TASK_LOADING, payload: true });
      const { preferredLanguage } = getState().userDetails?.data;
      let taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/${taskId}?language=${GetLanguageCode(
        preferredLanguage
      )}`;
      await dispatch(
        apiRequest({
          url: taskUri,
          method: GET
        })
      )
      .then(response => {
        dispatch({ type: GET_TASK_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: GET_TASK_FAILURE, payload: error });
      });
    }
  } catch (error) {
    dispatch({ type: GET_TASK_FAILURE, payload: error });
  }
};
