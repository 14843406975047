/**
 * Registration form
 * User registration form control, will redirect once registration success
 * dispactch api data to the store userDetails
 * function:
 * handleSubmit - to submit the form,  @param {emailId, password}
 *
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import { userRegistration } from 'actions/Login';
import TextBoxControl from 'components/forms/TextBoxControl';
import { Namevalidation, ValidateEmail, PasswordValidation, PhoneValidation } from 'utils/index';
import { CODE_409, CODE_201 } from 'constants/index';
import Loader from 'components/Loader';
import { REGISTER_COMPLETE, WEB } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';
import pkceChallenge from 'pkce-challenge'
import { OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI, OAUTH_AUTH_URL, OAUTH_AUDIENCE_URL } from 'constants/OauthProperties';

class SignupForm extends Component {
  state = {
    isAgreed: true,
    hasError: false,
    isLoading: false,
    isSuccess: false,
    isTremsOpen: false,
    isNoThanksOpen: false,
    isFullTremsOpen: false,
    fromFullTC: false,
    errorMessage: ''
  };

  //  handling if user selects agree
  handleAgree = () => {
    this.setState({ isTremsOpen: false, isFullTremsOpen: false, fromFullTC: false });
  };

  // back to the full or light version of terms & condition from no thanks modal
  handleBack = () => {
    const { fromFullTC } = this.state;
    if (fromFullTC) {
      this.setState({ isFullTremsOpen: true, isNoThanksOpen: false });
    } else {
      this.setState({ isTremsOpen: true, isNoThanksOpen: false });
    }
  };

  // Modren browser wont allow to close the parent window so navigating back to home
  handleExit = () => {
    const { history } = this.props;
    history.push('/');
  };

  // to display no thanks modal
  handleNoThanks = () => {
    this.setState({ isNoThanksOpen: true, isTremsOpen: false, isFullTremsOpen: false });
  };

  /**
   * Handle farm submit
   */
  handleSubmit = values => {
    // call registration action
    const { isAgreed } = this.state;
    this.setState({ hasError: false });
    const { userRegistrations } = this.props;
    if (isAgreed) { 
      this.setState({ isLoading: true, isTremsOpen: false });
      userRegistrations(
        values.firstName,
        values.lastName,
        values.phone,
        values.emailId,
        values.password,
        response => {
          if (response.status === CODE_201 || (response && response.id && response.id !== null)) {
            mixpanel.identify(response.data.id);
            mixpanel.track(REGISTER_COMPLETE, {'Platform':WEB});
            this.setState({ isLoading: false, isSuccess: true });
            const uuid = uuidv4();
            const pkce = pkceChallenge(128);
            const code_challenge = pkce.code_challenge;
            const code_verifier = pkce.code_verifier;
            sessionStorage.setItem("codeVerifier", code_verifier);
            setTimeout(() => {
              window.location.href = `${OAUTH_AUTH_URL}/oauth2/auth?response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${OAUTH_REDIRECT_URI}/auth&scope=openid+offline&code_challenge_method=S256&audience=${OAUTH_AUDIENCE_URL}&state=${uuid}&code_challenge=${code_challenge}`;
            }, 1000);
          } else if (response && response.response && response.response.status === CODE_409) {
            this.setState({
              isLoading: false,
              hasError: true,
              errorMessage: <FormattedMessage id="USER_EXIST" />
            });
          } else {
            this.setState({ isLoading: false, isSuccess: true });
            const uuid = uuidv4();
            const pkce = pkceChallenge(128);
            const code_challenge = pkce.code_challenge;
            const code_verifier = pkce.code_verifier;
            sessionStorage.setItem("codeVerifier", code_verifier);
            setTimeout(() => {
              window.location.href = `${OAUTH_AUTH_URL}/oauth2/auth?response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${OAUTH_REDIRECT_URI}/auth&scope=openid+offline&code_challenge_method=S256&audience=${OAUTH_AUDIENCE_URL}&state=${uuid}&code_challenge=${code_challenge}`;
            }, 1000);
            /*this.setState({
              isLoading: false,
              hasError: true,
              errorMessage: <FormattedMessage id="failed" />
            });*/
          }
        }
      );
    } else {
      this.setState({
        hasError: true,
        errorMessage: <FormattedMessage id="SIGN_TERMS_READ" />
      });
    }
  };

  // to display full terms & condition
  tremsFullModal = () => {
    this.setState(prevState => ({
      isFullTremsOpen: !prevState.isFullTremsOpen,
      isTremsOpen: false,
      fromFullTC: true
    }));
  };

  // to display light version of modal
  tremsModal = () => {
    this.setState(prevState => ({ isTremsOpen: !prevState.isTremsOpen }));
  };

  // to display no thanks modal
  noThanksModal = () => {
    this.setState(prevState => ({ isNoThanksOpen: !prevState.isNoThanksOpen }));
  };

  // Agree the terms & condition
  agreeChange = e => {
    this.setState({
      isAgreed: e
    });
  };

  render() {
    const { handleSubmit, intl } = this.props;
    const { isLoading, isSuccess, hasError, errorMessage, isAgreed } = this.state;
    return (
      <>
        <div className="login-form full-width">
          {/*<div id="displayRegisterMessage" className="registerErrorMessage1">Vi bytter innloggingsløsning for Eana Skifte akkurat nå, vennligst prøv igjen senere i dag</div>*/}
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="full-width register-form">
              <h3 className="form-title">
                <FormattedMessage id="signUp" />
              </h3>
              <Field
                name="firstName"
                label={intl.formatMessage({ id: 'firstName' })}
                component={TextBoxControl}
                controlId="firstName"
                type="text"
              />
              <Field
                name="lastName"
                label={intl.formatMessage({ id: 'lastName' })}
                component={TextBoxControl}
                controlId="lastName"
                type="text"
              />
              <Field
                name="phone"
                label={intl.formatMessage({ id: 'PHONE' })}
                component={TextBoxControl}
                controlId="phone"
                type="text"
              />
              <Field
                name="emailId"
                label={intl.formatMessage({ id: 'email' })}
                component={TextBoxControl}
                controlId="emailId"
                type="text"
              />
            </div>
            <div className="full-width change-pwd-block sign-up">
              <Field
                name="password"
                label={intl.formatMessage({ id: 'newPassword' })}
                component={TextBoxControl}
                controlId="password"
                type="password"
              />
              <Field
                name="repeatPassword"
                label={intl.formatMessage({ id: 'repeatPassword' })}
                component={TextBoxControl}
                controlId="repeatPassword"
                type="password"
              />
              <p className="full-width top-align">
                <FormattedMessage id="pwdValidateRule1" />
              </p>
              <p className="full-width">
                <FormattedMessage id="pwdValidateRule2" />
              </p>
              <p className="full-width">
                <FormattedMessage id="pwdValidateRule3" />
              </p>
              <p className="full-width">
                <FormattedMessage id="pwdValidateRule4" />
              </p>
            </div>
            {/** uncomment if the term accept flow need
              <CheckboxControl 
                label={<FormattedMessage id="SIGN_TERMS" />} 
                checked={isAgreed} 
                onChange={this.agreeChange} 
                styleName="remember-checkbox" 
                controlId="rememberMe" 
              />
              <Link to="/registration" onClick={this.tremsModal} className="link read-terms">
                <FormattedMessage id="READ" />
              </Link>
            */}
            {hasError ? (
              <p className="error-text sign-in-error">
                {errorMessage === 'Invalid credentials' ? (
                  <FormattedMessage id="L_IC" />
                ) : (
                  errorMessage
                )}
              </p>
            ) : null}
            {isLoading || !isAgreed ? <Loader styleName="signup-loader" /> : null}
            <div className="btn-wapper">
              <ActionButton disabled={isLoading} onClick={() => handleSubmit(this.handleSubmit)}>
                {intl.formatMessage({ id: 'CREATE_USER' })}
              </ActionButton>
            </div>
            {isSuccess ? (
              <div className="sent-notify">
                <i className="success-indicator" />
                <span>
                  <FormattedMessage id="SUCCESS" />
                </span>
              </div>
            ) : null}
          </form>
        </div>
      </>
    );
  }
}
/**
 * Form validation
 * @param { values }
 */
function validate(values, props) {
  const errors = {};
  const { intl } = props;
  if (!values.password || !values.password.trim()) {
    errors.password = intl.formatMessage({ id: 'required' });
  }
  if (!values.repeatPassword || !values.repeatPassword.trim()) {
    errors.repeatPassword = intl.formatMessage({ id: 'required' });
  }
  if (values.phone && !PhoneValidation(values.phone)) {
    errors.phone = intl.formatMessage({ id: 'invalid' });
  }
  if (values.password && !PasswordValidation(values.password)) {
    errors.password = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.password && values.password.trim().length < 8) {
    errors.password = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.password && values.password.trim().length > 30) {
    errors.password = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.password !== values.repeatPassword) {
    errors.repeatPassword = intl.formatMessage({ id: 'PWD_MATCH' });
  }
  if (!values.emailId || !values.emailId.trim()) {
    errors.emailId = intl.formatMessage({ id: 'L_PEVE' });
  }
  if (!ValidateEmail(values.emailId)) {
    errors.emailId = intl.formatMessage({ id: 'L_PEVE' });
  }
  if (!values.firstName || !values.firstName.trim()) {
    errors.firstName = intl.formatMessage({ id: 'required' });
  }
  if (values.firstName) {
    const result = Namevalidation(values.firstName);
    if (!result.valid) {
      errors.firstName = intl.formatMessage({ id: result.message });
    }
  }
  if (!values.lastName || !values.lastName.trim()) {
    errors.lastName = intl.formatMessage({ id: 'required' });
  }
  if (values.lastName) {
    const result = Namevalidation(values.lastName);
    if (!result.valid) {
      errors.lastName = intl.formatMessage({ id: result.message });
    }
  }
  // If errors is empty, the form will submit
  return errors;
}

const mapDispatchToProps = dispatch => ({
  userRegistrations: (firstName, lastName, phone, emailId, password, callback) =>
    dispatch(userRegistration(firstName, lastName, phone, emailId, password, callback))
});

export default injectIntl(
  reduxForm({
    form: 'RegistrationForm',
    validate
  })(withRouter(connect(null, mapDispatchToProps)(SignupForm)))
);
