import { v4 as uuidv4 } from 'uuid';
import pkceChallenge from 'pkce-challenge'
import { OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI, OAUTH_AUTH_URL, OAUTH_AUDIENCE_URL } from 'constants/OauthProperties';

const searchParams = new URLSearchParams(document.location.search);

export default () => {
  const uuid = uuidv4();
  const pkce = pkceChallenge(128);
  const code_challenge = pkce.code_challenge;
  const code_verifier = pkce.code_verifier;
  if(searchParams.get('code') === null) {
    sessionStorage.setItem("codeVerifier", code_verifier);
    window.location.href = `${OAUTH_AUTH_URL}/oauth2/auth?response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${OAUTH_REDIRECT_URI}/auth&scope=openid+offline&code_challenge_method=S256&audience=${OAUTH_AUDIENCE_URL}&state=${uuid}&code_challenge=${code_challenge}`;
  }
};
