import React from 'react';
import { FormattedMessage } from 'react-intl';
import TermsAndCondition from 'containers/toc/TermsAndCondition';
import { PRIVACY_LINK } from 'constants/index';
import mixpanel from 'mixpanel-browser';
import { VIEW_TC } from 'constants/MixPanel';

const Footer = ({showTermsAndCondition, openTermsAndCondition, closeTermsAndCondition, userDetails}) => (
  <div className="footer">
    <div className="footer-privacy-policy">
      <div className="no-content" />
      <div className="privacy-policy">
        <span>
          <a href={PRIVACY_LINK} rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="FOOTER_PP" />
          </a>
        </span>
      </div>
      <div className="privacy-policy">
        <span className="span-cursor" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(VIEW_TC); window.open('https://eana.no/info/brukervilkar', '_blank').focus();}}>
            {/*Added the code for testing the build */}
            <FormattedMessage id="FOOTER_TOS" />
        </span>
      </div>
      <div className="footer-right-content">
        <span>
          <FormattedMessage id="FOOTER_BY" />
        </span>
        &nbsp;&nbsp;
        <span className="icon-mimiro-black" />
      </div>
    </div>
    <div className="footer-contact-details">
      <div className="no-content" />
      <div className="footer-contacts">
        <p className="footer-contacts-item">
          <FormattedMessage id="FOOTER_GIT" />
        </p>
        <p className="footer-contacts-item">
          <FormattedMessage id="FOOTER_NUM" />
        </p>
        <p className="footer-contacts-item">
          <a href="mailto:hei@eana.no?subject=Query">
            <FormattedMessage id="FOOTER_EID" />
          </a>
        </p>
      </div>
      <div className="footer-address">
        <p className="footer-address-item">
          <FormattedMessage id="FOOTER_ADDR" />
        </p>
        <p className="footer-address-item">
          <span>
            <FormattedMessage id="FOOTER_ASV" />
          </span>
          <br />
          <span>
            <FormattedMessage id="FOOTER_SKI" />
          </span>
          <br />
          <span>
            <FormattedMessage id="FOOTER_NRY" />
          </span>
          <br />
          <br />
          <span>
            <FormattedMessage id="FOOTER_ORG" />
          </span>
        </p>
      </div>
    </div>
    <TermsAndCondition
      showTermsAndCondition = {showTermsAndCondition}
      closeTermsAndCondition = {closeTermsAndCondition}
    />
  </div>
);

export default Footer;
