import _ from 'lodash';
import {
  GET_ACTIVITY_LIST,
  IS_GET_ACTIVITY_LIST_FAILURE,
  IS_GET_ACTIVITY_LIST_LOADING
} from 'constants/store/RolesAndActivity';

import { ACTIVITY_LIST } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/** Action get farm details */
const acitvityListHasError = (bool, error) => ({
  type: IS_GET_ACTIVITY_LIST_FAILURE,
  hasError: bool,
  errorMessage: error
});

const acitvityListIsLoading = bool => ({
  type: IS_GET_ACTIVITY_LIST_LOADING,
  isLoading: bool
});

export const acitvityListSuccess = data => ({
  type: GET_ACTIVITY_LIST,
  data
});

/**
 * to get activity list details
 */
export const fetchAcitvityList = farmId => async (dispatch, getState) => {
  dispatch(acitvityListIsLoading(true));
  const { preferredLanguage } = getState().userDetails?.data;
  let activityUri = `${ACTIVITY_LIST}?language=${GetLanguageCode(
    preferredLanguage
  )}`;
  await dispatch(
    apiRequest({
      url: activityUri,
      method: 'get'
    })
  )
    .then(response => {
      let activityList = response.data;
      if (activityList.length >= 1) {
        activityList = _.orderBy(activityList, [data => data.activityName.toLowerCase()], ['asc']);
      }
      dispatch(acitvityListSuccess(activityList));
    })
    .catch(error => {
      dispatch(acitvityListHasError(true, error));
    });
};
