import React, { Component } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import Select, { components } from 'react-select';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import { LogOutIcon } from '@tine/lib-frontend-components/elements/icons';

import LoaderPage from 'components/LoaderPage';
import navToggle from 'actions/NavToggle';
import { farmListSuccess, clearMessages } from 'actions/Farms';
import { doLogOut } from 'actions/Login';
import { ENTER_TASKS, ENTER_FIELD, ENTER_STATS, ENTER_SETTINGS, ENTER_FP_PAGE, VIEW_360 } from 'constants/MixPanel';
import { EANA_360 } from 'constants/OauthProperties';
import mixpanel from 'mixpanel-browser';

class NavigationBar extends Component {
  // handle farm select
  farmChange = item => {
    const { farmList, updateActiveFarm, clearFarmMessages } = this.props;
    const selecteItem = _.filter(farmList.data, ['farm.id', item.value]);
    localStorage.setItem( 'selectedFarmId', selecteItem[0].farm.id );
    localStorage.setItem( 'selectedFarmName', selecteItem[0].farm.name );
    updateActiveFarm(farmList.data, selecteItem[0]);
    clearFarmMessages();
  };

  filterOption = (option, inputValue) =>
    (option.label ? option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || [] : []).length > 0;

  // get farm option
  farmListOption = () => {
    const { farmList, hasSupportAdminRole } = this.props;
    const options = farmList.data && farmList.data.map(data => ({ value: data.farm.id, label: hasSupportAdminRole.data ? data.farm.nameAndOrgNum : data.farm.name }));
    if (farmList.data && farmList.data.length > 1) {
      const defaultOption = {
        value: farmList.activeFarm.farm.id,
        label: hasSupportAdminRole.data ? farmList.activeFarm.farm.nameAndOrgNum : farmList.activeFarm.farm.name
      };
      
      const DropdownIndicator = props => (
        // Have to pass all props to the react select
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.DropdownIndicator {...props}>
          <span className={props.selectProps.menuIsOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />
        </components.DropdownIndicator>
      );
      return (
        <Select
          options={options}
          value={defaultOption}
          onChange={v => this.farmChange(v)}
          className="farm-header-select"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator
          }}
          filterOption={this.filterOption}
        />
      );
    }
    return (
      <span title={options[0].label} className="farm-header-selected-text">
        {options[0].label}
      </span>
    );
  };

  // Hanlde sign out
  handleSignOut = () => {
    const { userDetails, loggingOut, farmList } = this.props;
    loggingOut(userDetails.data.accessToken, farmList.activeFarm?.farm.id)
  };

  // Toggle menu
  handleMenuToggle = () => {
    const { navigationToggle, navBarToggle } = this.props;
    navigationToggle(!navBarToggle.isOpen);
  };

  render() {
    const {
      intl,
      navBarToggle,
      farmList,
      history,
      hasValidationPermision,
      userDetails,
      hasSupportAdminRole
    } = this.props;
    const classToogle = navBarToggle.isOpen ? 'menu-bar toggle' : 'menu-bar';
    const navMenuClass = navBarToggle.isOpen ? 'btn-nav-menu menu-open' : 'btn-nav-menu';
    return (
      <>
        <button type="button" onClick={() => this.handleMenuToggle()} className={navMenuClass}>
          <span />
          <span />
          <span />
        </button>
        <div className={classToogle}>
          {userDetails.isLogoutLoading ? <LoaderPage /> : null}
          <h3 className="menu-title">
            <Link to="/managefields">{intl.formatMessage({ id: 'APP_NAME' })}</Link>
          </h3>
          {farmList.data &&
          farmList.data.length > 0 &&
          history.location.pathname !== '/settings/addfarm'
            ? this.farmListOption()
            : null}
          {farmList.hasError ? (
            <ErrorText size="small">{farmList.hasError}</ErrorText>
          ) : null}
          <nav className="nav-bar">
            <ul className="full-width">
              <li className="full-width">
                <NavLink to="/tasks" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_TASKS);}} activeClassName="active">
                  <i className="icon-tasks" />
                  {intl.formatMessage({ id: 'TASKS' })}
                </NavLink>
              </li>
              <li className="full-width">
                <NavLink to="/managefields" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_FIELD);}} activeClassName="active">
                  <i className="icon-field" />
                  {intl.formatMessage({ id: 'manageField' })}
                </NavLink>
              </li>
              <li className="full-width">
                  <NavLink to="/storage" activeClassName="active">
                    <div className="navigation-image-flex">
                      <div>
                        <img src="images/storage.png" width="24" height="22" alt="fertilization" />
                      </div>
                      <div className="navigation-image-flex-div">
                        {intl.formatMessage({ id: 'STORAGE' })}
                      </div>
                    </div>
                  </NavLink>
              </li>
              <li className="full-width">
                  <NavLink to="/grassanalysis" activeClassName="active">
                    <div className="navigation-image-flex">
                      <div>
                        <img src="images/grass-analysis.png" width="24" height="20" alt="grass-analysis" />
                      </div>
                      <div className="navigation-image-flex-div">
                        {intl.formatMessage({ id: 'GRASS_ANALYSIS' })}
                      </div>
                    </div>
                  </NavLink>
              </li>
              <li className="full-width">
                <a href={EANA_360} onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(VIEW_360);}} activeClassName="active">
                  <div className="navigation-image-flex">
                    <div>
                      <img src="images/icon-360.png" width="22" height="22" alt="Eana 360" />
                    </div>
                    <div className="navigation-image-flex-div">
                      {intl.formatMessage({ id: 'EANA_360' })}
                    </div>
                    <div className="navigation-image-flex-div-1">
                      <img src="images/icon-open_in_new.png" width="16" height="16" alt="Eana 360" />
                    </div>
                  </div>
                </a>
              </li>
              <li className="full-width" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_FP_PAGE); history.push('/managefields/fertilization');}}>
                <NavLink to="#" activeClassName="inactive">
                  <div className="navigation-image-flex">
                    <div>
                      <img src="images/fertilization.png" width="26" height="22" alt="fertilization" />
                    </div>
                    <div className="navigation-image-flex-div">
                      {intl.formatMessage({ id: 'FERTILIZATION_PLAN' })}
                    </div>
                  </div>
                </NavLink>
              </li>
              <li className="full-width">
                <NavLink to="/stats" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_STATS);}} activeClassName="active">
                  <i className="icon-statics" />
                  {intl.formatMessage({ id: 'STATS' })}
                </NavLink>
              </li>
              <li className="full-width">
                <NavLink to="/settings" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_SETTINGS);}} activeClassName="active">
                  <i className="icon-settings" />
                  {intl.formatMessage({ id: 'SETTINGS' })}
                </NavLink>
              </li>
              {hasValidationPermision.hasPermission || hasSupportAdminRole.data ? (
                <li className="full-width">
                  <NavLink to="/datavalidation" activeClassName="active">
                    <i className="icon-report-2" />
                    Data validation
                  </NavLink>
                </li>
              ) : null}
              <li className="full-width">
                <button
                  type="button"
                  disabled={userDetails.isLogoutLoading}
                  onClick={() => this.handleSignOut()}
                >
                  <LogOutIcon scale={0.75} />
                  <span>{intl.formatMessage({ id: 'logOut' })}</span>
                </button>
              </li>
            </ul>
          </nav>
          <div className="menu-logo">
            <div className="eana-app-image" />
            <div className="icon-eana-logo" />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  navBarToggle: state.NavToggle,
  farmList: state.farmList,
  hasValidationPermision: state.GpsData,
  userDetails: state.userDetails,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  navigationToggle: bool => dispatch(navToggle(bool)),
  updateActiveFarm: (data, activeform) => dispatch(farmListSuccess(data, activeform)),
  clearFarmMessages: () => dispatch(clearMessages()),
  loggingOut: (token, activeFarmId) => dispatch(doLogOut(token, activeFarmId))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar)));
