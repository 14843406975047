import { GET_GEO_LOCATION } from '../constants/index';

const initialState = {
  data: [{ lattitude: 59.911491, longitude: 10.757933 }]
};

export const GeoLocReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GEO_LOCATION:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

export default GeoLocReducer;
