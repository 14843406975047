import {
  GET_SCHEDULED_BALES_SUCCESS,
  GET_SCHEDULED_BALES_ERROR,
  GET_SCHEDULED_BALES_LOADING,
  GET_SCHEDULED_BALE_DETAILS_SUCCESS,
  GET_SCHEDULED_BALE_DETAILS_ERROR,
  GET_SCHEDULED_BALE_DETAILS_LOADING,
  SAVE_SCHEDULED_BALES_SUCCESS,
  SAVE_SCHEDULED_BALES_ERROR,
  SAVE_SCHEDULED_BALES_LOADING,
  GET_SCHEDULED_BALE_BY_ID_SUCCESS,
  GET_SCHEDULED_BALE_BY_ID_ERROR,
  GET_SCHEDULED_BALE_BY_ID_LOADING,
  UPDATE_SCHEDULED_BALES_SUCCESS,
  UPDATE_SCHEDULED_BALES_ERROR,
  UPDATE_SCHEDULED_BALES_LOADING
} from 'constants/store/Bale';
import { GET, POST, PUT } from 'constants/index';
import { FARM_V1 } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

export const getAllScheduledBales = (farmId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SCHEDULED_BALES_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/feed-events/schedules/overview`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_SCHEDULED_BALES_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_SCHEDULED_BALES_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_SCHEDULED_BALES_ERROR, payload: error });
  }
};

export const getScheduledBaleDetails = (farmId, year, harvest, storages) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SCHEDULED_BALE_DETAILS_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/details/storages?year=${year}&storages=${storages}&harvest=${harvest}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_SCHEDULED_BALE_DETAILS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_SCHEDULED_BALE_DETAILS_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_SCHEDULED_BALE_DETAILS_ERROR, payload: error });
  }
};

export const saveScheduledBaleDetails = (farmId, year, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVE_SCHEDULED_BALES_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/feed-events/schedules?year=${year}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: POST
      })
    )
    .then(response => {
      dispatch({ type: SAVE_SCHEDULED_BALES_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SAVE_SCHEDULED_BALES_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: SAVE_SCHEDULED_BALES_ERROR, payload: error });
  }
};

export const getScheduledBaleById = (farmId, scheduleId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SCHEDULED_BALE_BY_ID_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/feed-events/schedules/${scheduleId}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_SCHEDULED_BALE_BY_ID_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_SCHEDULED_BALE_BY_ID_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_SCHEDULED_BALE_BY_ID_ERROR, payload: error });
  }
};

export const updateScheduledBaleDetails = (farmId, year, data, scheduleId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_SCHEDULED_BALES_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/feed-events/schedules/${scheduleId}?year=${year}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: PUT
      })
    )
    .then(response => {
      dispatch({ type: UPDATE_SCHEDULED_BALES_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: UPDATE_SCHEDULED_BALES_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: UPDATE_SCHEDULED_BALES_ERROR, payload: error });
  }
};