import { FARM_V1 } from 'constants/ApiUrl';
import { MANURE_NAMES_LOADING, MANURE_NAMES_SUCCESS, MANURE_NAMES_FAILURE } from 'constants/store/Fertilization';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

const fetchManureNames = (farmId) => async (dispatch, getState) => {
  dispatch({ type: MANURE_NAMES_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${FARM_V1}/${farmId}/manurestoragenames?language=${GetLanguageCode(preferredLanguage)}`,
      method: GET
    })
  )
    .then(response => {
      dispatch({ type: MANURE_NAMES_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: MANURE_NAMES_FAILURE, payload: error });
    });
};

export default fetchManureNames;