import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import { regulationList, updateRegulationList } from 'actions/Regulations';
import Loader from 'components/Loader';
import _ from 'lodash';

class UserAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setInitialize: false,
      selectedFieldCheckbox: [],
      selectAllCheckbox: false,
      checkboxSelected: false,
      checkAllBoxSelected: false
    };
  }

  componentDidMount = () => {
    const { getUserRegulations, userDetails } = this.props;
    this.setState({
      setInitialize: false
    });
    getUserRegulations(userDetails.id);
  };

  componentDidUpdate() {
    const { history, activeFarm, userRegulations } = this.props;
    const { setInitialize } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if(!setInitialize && userRegulations && userRegulations.regulationList !== null) {
      let data = [];
      let keyPair = {};
      let checkAll = true;
      let checkOne = false;
      userRegulations.regulationList.map(regulationList => (
        //eslint-disable-next-line
        keyPair = {},
        keyPair['id'] = regulationList.id,
        keyPair['opted'] = regulationList.opted,
        data.push(keyPair),
        checkAll = regulationList.opted === false ? false : checkAll,
        checkOne = regulationList.opted === true ? true : checkOne
      ));
      this.setState({
        selectedFieldCheckbox: data,
        setInitialize: true,
        selectAllCheckbox: checkAll,
        checkboxSelected: checkOne
      });
    }
  }

  displayAgreement = () => {
    const { getUserRegulations, userDetails } = this.props;
    this.setState({
      setInitialize: false
    });
    getUserRegulations(userDetails.id);
    this.setState({
      show: true,
    });
  }

  closeShowAgreement = () => {
    const { userRegulations } = this.props;
    let checkOne = false;
    userRegulations.regulationList.map(regulationList => (
      checkOne = regulationList.opted === true ? true : checkOne
    ));
    this.setState({
      show: false,
      checkboxSelected: checkOne
    });
  }

  checkBoxChange = e => {
    const { selectedFieldCheckbox } = this.state;
    let data = [];
    let keyPair = {};
    let checkAll = true;
    let checkOne = false;
    selectedFieldCheckbox.map(regulation => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = regulation.id,
      keyPair['opted'] = (e === regulation.id) ? (regulation.opted === true ? false : true) : regulation.opted,
      data.push(keyPair)
    ));
    data.map(regulationList => (
      //eslint-disable-next-line
      checkAll = regulationList.opted === false ? false : checkAll,
      checkOne = regulationList.opted === true ? true : checkOne
    ));
    this.setState({
      selectedFieldCheckbox: data,
      selectAllCheckbox: checkAll,
      checkboxSelected: checkOne
    });
  }

  checkAllFields = () => {
    const { selectedFieldCheckbox, selectAllCheckbox } = this.state;
    let selectBox = true;
    let checkOne = false;
    if(selectAllCheckbox) {
      selectBox = false;
    }
    let data = [];
    let keyPair = {};
    selectedFieldCheckbox.map(regulation => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = regulation.id,
      keyPair['opted'] = selectAllCheckbox ? false : true,
      data.push(keyPair)
    ))
    if(selectBox) {
      checkOne = true;
    }
    this.setState({
      selectedFieldCheckbox: data,
      selectAllCheckbox: selectBox,
      checkboxSelected: checkOne
    });
  }

  saveUserAgreement = () => {
    const { updateUserRegulations, userDetails, display, updateAgreementError } = this.props;
    const { selectedFieldCheckbox } = this.state;
    if(display) {
      this.setState({
        checkAllBoxSelected: true
      });
      updateAgreementError(true);
    }
    let regulationArray = [];
    let keyPair = {};
    
    selectedFieldCheckbox.map(regulation => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['regulationId'] = regulation.id,
      keyPair['opted'] = regulation.opted,
      regulationArray.push(keyPair)
    ))
    let keyPair1 = {};
    keyPair1['regulations'] = regulationArray;
    keyPair1['userId'] = userDetails.id;
    updateUserRegulations(keyPair1);
    this.setState({
      show: false
    });
  };

  render() {
    const { intl, display, userRegulations } = this.props;
    const { show, selectedFieldCheckbox, selectAllCheckbox, checkboxSelected, checkAllBoxSelected } = this.state;
    return (
      <div>
        {userRegulations.isLoading && <Loader styleName="fixed-loader" />}
        {display &&
          <div>
            <span className="agreement-link agreement-float-left agreement-padding-right" onClick={() => this.displayAgreement()}>
              {intl.formatMessage({ id: 'PROFILE_REGULATIONS_LINK' })}
            </span>
            <div className="storage-arrow agreement-float-left agreement-padding-left">
              <span className="location-type-checkbox">
                <CheckboxButton
                  id="checkOne"
                  label=""
                  checked={checkboxSelected || checkAllBoxSelected}
                  labelText=""
                  stlyeClass="font-style-div"
                  onChange={() => null}
                />
              </span>
            </div>
          </div>
        }
        {!display &&
          <span className="agreement-link-user agreement-float-left agreement-padding-right" onClick={() => this.displayAgreement()}>
            {intl.formatMessage({ id: 'PROFILE_REGULATIONS_LINK' })}
          </span>
        }
        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '45%'}}
          width={{mobile: '90%', tablet: '55%', web: '45%'}}
          height={{mobile: '90%', tablet: '75%', web: '85%'}}
          show={show} 
          closeIcon=""
          onClose={() => {}}>
            {!userRegulations.isLoading &&
              <div className="storage-details">
                <div className="agreement-header">
                  {intl.formatMessage({ id: 'PROFILE_REGULATIONS_HEADER' })}
                </div>
                <div className="modal-storage-list-details">
                  <ul className="storage-list-wrapper">
                  <li className={`modal-storage-list-item full-width`} key={1}>
                      <div className="storage-details-no-width no-border-agreement">
                        <div className="storage-name modal-large-width">
                          <span title="" className="agreement-float">
                            <span className='medium-font-text vertical-align-super number-padding-right'>
                              {intl.formatMessage({ id: 'SELECT_ALL' })}
                            </span>
                          </span>
                        </div>
                        <div className="storage-arrow">
                          <span className="location-type-checkbox">
                            <CheckboxButton
                              id="checkAll"
                              label=""
                              checked={selectAllCheckbox}
                              labelText=""
                              stlyeClass="font-style-div"
                              onChange={() => this.checkAllFields()}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                    {userRegulations && userRegulations.regulationList && userRegulations.regulationList.map((regulationList, index) => (
                    <li className={`modal-storage-list-item full-width`} key={index}>
                      <div className="storage-details-agreement">
                        <div className="storage-name modal-large-width">
                          <span title="">
                            <span className='medium-font-text vertical-align-super number-padding-right'>
                              {regulationList.regulationName}
                            </span>
                          </span>
                        </div>
                        <div className="storage-arrow agreement-padding-top">
                          <span className="location-type-checkbox">
                            <CheckboxButton
                              id={regulationList.id}
                              label=""
                              checked={selectedFieldCheckbox.length > 0 ? (selectedFieldCheckbox.length > 0 && _.filter(selectedFieldCheckbox, ['id', regulationList.id])[0]).opted : false}
                              labelText=""
                              stlyeClass="font-style-div"
                              onChange={e => this.checkBoxChange(regulationList.id)}
                            />
                          </span>
                        </div>
                      </div>
                    </li>))}
                  </ul>
                </div>
                <div className="bale-button-style">
                  <span className="agreement-margin-right bale-list-save-button" onClick={() => this.saveUserAgreement()}>
                    {intl.formatMessage({ id: 'SAVE' })}
                  </span>
                  <span className="bale-list-cancel-button" onClick={() => this.closeShowAgreement()}>
                    {intl.formatMessage({ id: 'CANCEL' })}
                  </span>
                </div>
              </div>}
        </ModalDialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails.data,
  userRegulations: state.regulations
});

const mapDispatchToProps = dispatch => ({
  getUserRegulations: userId => dispatch(regulationList(userId)),
  updateUserRegulations: data => dispatch(updateRegulationList(data)),
});

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAgreement))
);
