import * as React from 'react';
import { Field } from 'redux-form';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import TextBoxControl from 'components/forms/TextBoxControl';
import SelectControl from 'components/forms/SelectBoxControl';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import { CrossIcon } from '@tine/lib-frontend-components/elements/icons';
import { getCurrentYears } from 'utils/index';
import ToggleButton from 'react-toggle-button';

const FertilizerModalData = ({ onCloseFertilizerPlan, onCreateFertilizer, onCreateTask, onUpdateTask, closeUpdateTask, updateFertilizerPlan, onCreateTaskConfirm,
  onCreateNewFertilizer, onConfirmDialog, confirmErrorMessage, handleSubmit, onChangeTextBox, onCreateValue, 
  keepExistingPlanModel, discardExistingPlanModel, createTaskModel, closeCreateTaskModel, handleSubmitModel, intl, goToTaskPage, fertilizationPlan, 
  mineralCalculationOn, toggleMineralCalculationOn, mineralCalculationOnUpdate, toggleMineralCalculationOnUpdate, displayErrorFields,
  getEmptyFieldFertilization, onCloseDisplayErrorFields }) => (
    <div>
      <ModalDialog
        minWidth={{mobile: '90%', tablet: '90%', web: '70%'}}
        width={{mobile: '90%', tablet: '90%', web: '70%'}}
        height={{mobile: '90%', tablet: '90%', web: '70%'}}
        show={displayErrorFields}
        closeIcon={
          <span className="close-modal">
            <CrossIcon scale={0.5} />
          </span>
        }
        onClose={onCloseDisplayErrorFields}>
          <div className="missing-field-attributes">{intl.formatMessage({ id: 'FIELD_MISSING_ATTRIBUTES' })}</div>
          {getEmptyFieldFertilization()}
      </ModalDialog>
      <ModalDialog
        minWidth={{mobile: '90%', tablet: '50%', web: '25%'}}
        width={{mobile: '90%', tablet: '50%', web: '25%'}}
        height={{mobile: '90%', tablet: '65%', web: '60%'}}
        show={onCreateFertilizer}
        closeIcon={
          <span className="close-modal">
            <CrossIcon scale={0.5} />
          </span>
        }
        onClose={onCloseFertilizerPlan}>
        <form onSubmit={handleSubmit}>
          <div className="text-align-center">
            <div className="fertilization-header-div">
              <span className='fertilization-header'>{intl.formatMessage({ id: 'NEW_FERTILIZATION_PLAN' })}</span>
            </div>
            <div>
              <div className='fertilizer-form-style'>
                <div className='mineralization-calculation-div-less-bottom'>
                  {intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_TITLE' })}
                </div>
                <div className='mineralization-calculation-div'>
                  <ToggleButton
                    inactiveLabel={intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_NO' })}
                    activeLabel={intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_YES' })}
                    colors={{
                      inactive: {
                        base: 'rgb(42, 60, 99)',
                        hover: 'rgb(42, 60, 99)',
                      }
                    }}
                    value={ mineralCalculationOn }
                    onToggle={toggleMineralCalculationOn}
                  />
                </div>
                <div>
                  <Field
                    name="planName"
                    label={intl.formatMessage({ id: 'FERTILIZATION_PLAN_NAME' })}
                    component={TextBoxControl}
                    controlId="planName"
                    type="text"
                  />
                </div>
                <div>
                  <Field
                    name="year"
                    label={intl.formatMessage({ id: 'CHOOSE_YEAR' })}
                    controlId="year"
                    component={SelectControl}
                    styleName="select-box-common"
                    options={getCurrentYears()}
                    placeholder=""
                  />
                </div>
                <div className="button-style">
                  <ActionButton onClick={handleSubmit}>{intl.formatMessage({ id: 'create' })}</ActionButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalDialog>
      <ModalDialog
        minWidth={{mobile: '90%', tablet: '50%', web: '25%'}}
        width={{mobile: '90%', tablet: '50%', web: '25%'}}
        height={{mobile: '90%', tablet: '55%', web: '54%'}}
        show={onCreateNewFertilizer}
        closeIcon={
          <span className="close-modal">
            <CrossIcon scale={0.5} />
          </span>
        }
        onClose={onCloseFertilizerPlan}>
          <div className="text-align-center">
            <div className="fertilization-header-div-high">
              <span className='fertilization-header'>
                <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" />
              </span>
            </div>
            <div>
              <Emphasis size='large' className="padding-top-alignment">
                {intl.formatMessage({ id: 'ACTIVE_FERTILIZER_PLAN' })}
              </Emphasis>              
            </div>
            <div>
              <Text>
                {intl.formatMessage({ id: 'ACTIVE_FERTILIZER_PLAN_ERROR' })}
              </Text>              
            </div>
            <div className='fertilizer-form-style'>
              <div>
                <Field
                  name="confirmCreate"
                  label={intl.formatMessage({ id: 'CREATE_CONTINUE' })}
                  component={TextBoxControl}
                  controlId="confirmCreate"
                  type="text"
                  onChange={v => onChangeTextBox(v)}
                  disableValid
                />
              </div>
              <div>
                <div className="button-style-yes">
                  <ActionButton disabled={!onCreateValue} onClick={handleSubmitModel}>{intl.formatMessage({ id: 'create' })}</ActionButton>
                </div>
                <div className="button-style-cancel">
                  <ActionButton multi onClick={onCloseFertilizerPlan}>{intl.formatMessage({ id: 'CANCEL' })}</ActionButton>
                </div>
              </div>
            </div>
          </div>
      </ModalDialog>
      <ModalDialog
        minWidth={{mobile: '90%', tablet: '40%', web: '22%'}}
        width={{mobile: '90%', tablet: '50%', web: '22%'}}
        height={{mobile: '90%', tablet: '45%', web: '40%'}}
        closeIcon=""
        onClose={() => {}}
        show={onConfirmDialog}>
          <div className="text-align-center">
            <div className="fertilization-header-div-high">
              <span className='fertilization-header'>
                <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" />
              </span>
            </div>
            <div>
              <Text>{confirmErrorMessage}</Text>              
            </div>
            <div className='fertilizer-form-style'>
              <div>
                <div className="button-style-yes">
                  <ActionButton onClick={keepExistingPlanModel}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                </div>
                <div className="button-style-cancel">
                  <ActionButton multi onClick={discardExistingPlanModel}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                </div>
              </div>
            </div>
          </div>
      </ModalDialog>
      <ModalDialog
        minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
        width={{mobile: '70%', tablet: '35%', web: '25%'}}
        height={{mobile: '45%', tablet: '45%', web: '40%'}}
        show={onCreateTask}
        closeIcon=""
        onClose={() => {}}>
        <div className="text-align-center">
          <div className="fertilization-header-div">
            <span className='fertilization-header'>{intl.formatMessage({ id: 'FERTILIZATION_CREATE_TASK_HEADER' })}</span>
          </div>
          <div>
            <div className='fertilizer-form-style'>
              <div>
                <Text>{intl.formatMessage({ id: 'FERTILIZATION_CREATE_TASK_DESCRIPTION' })}</Text>
              </div>
            </div>
            <div className='fertilizer-form-style-button'>
              <span className="button-style-padding-right">
                <ActionButton onClick={createTaskModel}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
              </span>
              <span>
                <ActionButton multi onClick={closeCreateTaskModel}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
      <ModalDialog
        minWidth={{mobile: '90%', tablet: '55%', web: '24%'}}
        width={{mobile: '90%', tablet: '55%', web: '24%'}}
        height={{mobile: '65%', tablet: '50%', web: '30%'}}
        show={onUpdateTask}
        closeIcon={
          <span className="close-modal">
            <CrossIcon scale={0.5} />
          </span>
        }
        onClose={() => closeUpdateTask()}>
          <div>
            <div className='text-align-center'>
              <Emphasis size="extralarge">{intl.formatMessage({ id: 'UPDATE_FERTILIZATION' })}</Emphasis>
            </div>
            <div>
              <div className='main-bale-storage-form-style padding-top-size'>
                <div className='mineralization-calculation-div-less-bottom'>
                  {intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_TITLE_1' })}
                </div>
                <div >
                  <ToggleButton
                    inactiveLabel={intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_NO' })}
                    activeLabel={intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_YES' })}
                    colors={{
                      inactive: {
                        base: 'rgb(42, 60, 99)',
                        hover: 'rgb(42, 60, 99)',
                      }
                    }}
                    value={mineralCalculationOnUpdate}
                    onToggle={toggleMineralCalculationOnUpdate}
                  />
                  </div>
                <div>
                  <div className="button-style-yes-fertilization">
                    <ActionButton onClick={() => updateFertilizerPlan(false)}>{intl.formatMessage({ id: 'update' })}</ActionButton>
                  </div>
                </div>
                {/*<div onClick={() => updateFertilizerPlan(false)} className="pointer-cursor">
                  <div className="padding-bottom-border">
                    <div className="padding-top-div lightbox-padding-bottom-div-small">
                      <span>
                        <span className='add-bale-header'>{intl.formatMessage({ id: 'UPDATE_FERTILIZATION_HEADING_1' })}</span>
                      </span>
                      <span className="storage-arrow">
                        <span className="icon-arrow-up-accordion add-bales-arrow-label" />
                      </span>
                    </div>
                    <div>
                      {intl.formatMessage({ id: 'UPDATE_FERTILIZATION_CONTENT_1' })}
                    </div>
                  </div>
                </div>
                <div>
                  <div onClick={() => updateFertilizerPlan(true)} className="pointer-cursor">
                    <div className="padding-top-div lightbox-padding-bottom-div-small">
                      <span>
                        <span className='add-bale-header'>{intl.formatMessage({ id: 'UPDATE_FERTILIZATION_HEADING_2' })}</span>
                      </span>
                      <span className="storage-arrow">
                        <span className="icon-arrow-up-accordion add-bales-arrow-label" />
                      </span>
                    </div>
                    <div>
                      {intl.formatMessage({ id: 'UPDATE_FERTILIZATION_CONTENT_2' })}
                    </div>
                  </div>
                  </div>*/}
              </div>
            </div>
          </div>
      </ModalDialog>
      <ModalDialog
        minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
        width={{mobile: '70%', tablet: '35%', web: '25%'}}
        height={{mobile: '45%', tablet: '45%', web: '40%'}}
        show={onCreateTaskConfirm}
        closeIcon=""
        onClose={() => {}}>
        <div className="text-align-center">
          <div className="fertilization-header-div">
            <span className='fertilization-header'>{intl.formatMessage({ id: 'FERTILIZATION_SUCCESS' })}</span>
          </div>
          <div>
            <div className='fertilizer-form-style'>
              <div>
                <Text>{fertilizationPlan.createTaskData !==null ? fertilizationPlan.createTaskData : ''}</Text>
              </div>
            </div>
            <div className='fertilizer-form-style-button'>
              <span className="button-style-padding-right">
                <ActionButton onClick={goToTaskPage}>{intl.formatMessage({ id: 'FERTILIZATION_SHOW_TASKS' })}</ActionButton>
              </span>
              <span>
                <ActionButton multi onClick={closeCreateTaskModel}>{intl.formatMessage({ id: 'FERTILIZATION_SHOW_CLOSE' })}</ActionButton>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
  </div>
);

export default FertilizerModalData;