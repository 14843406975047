import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { lowerCase } from 'lodash';
import _ from 'lodash';
Font.register({ family: 'openSans', fonts: [
  { src: 'fonts/Open_Sans/OpenSans-Regular.ttf' },
  { src: 'fonts/Open_Sans/OpenSans-Bold.ttf', fontWeight: 700 },
  { src: 'fonts/Open_Sans/OpenSans-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 700 },
  { src: 'fonts/Open_Sans/OpenSans-ExtraBold.ttf', fontWeight: 900 },
  { src: 'fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf', fontStyle: 'italic', fontWeight: 900 },
  { src: 'fonts/Open_Sans/OpenSans-Italic.ttf', fontStyle: 'italic' },
  { src: 'fonts/Open_Sans/OpenSans-Light.ttf', fontWeight: 300 },
  { src: 'fonts/Open_Sans/OpenSans-LightItalic.ttf', fontStyle: 'italic', fontWeight: 300 },
  { src: 'fonts/Open_Sans/OpenSans-SemiBold.ttf', fontWeight: 500 },
  { src: 'fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf', fontStyle: 'italic', fontWeight: 500 }
 ]});

/** Create styles **/
const styles = StyleSheet.create({
  page: { backgroundColor: '#ffffff', fontFamily: 'openSans', paddingBottom: 65 },
  pageMarginLeft: { marginLeft: 148, marginRight: 15 },
  pageMarginLight: { marginLeft: 15, marginRight: 15 },
  pageMargin: { marginLeft: 30, marginRight: 30 },
  flexRowDirection: { flexDirection: 'row' },
  flexHeading: { paddingLeft: 30, paddingRight: 30, flexGrow: 1 },
  leftHeading: { width: '82%' },
  rightHeading: { width: '18%' },
  boldFont: { fontWeight: 500 },
  extraBoldFont: { fontWeight: 700 },
  normalFont: { fontWeight: 300 },
  normalFontSize: { fontSize: 12 },
  heavyFontSize: { fontSize: 12, fontWeight: 400 },
  lightFontSize: { fontSize: 10 },
  fieldFont: { fontSize: 14, fontWeight: 500 },
  fieldSmallFont: { fontSize: 13, fontWeight: 400 },
  seasonFont: { fontSize: 14, fontWeight: 300 },
  paddingTopVeryLight: { paddingTop: 5 },
  paddingTopLight: { paddingTop: 10 },
  paddingTopMedium: { paddingTop: 20 },
  paddingRightLight: { paddingRight: 20 },
  marginLeftMedium: { marginLeft: 30 },
  marginRightMedium: { marginRight: 30 },
  marginBottomVeryHeavy: { marginBottom: 10 },
  marginTopHeavy: { marginTop: 60 },
  marginTopMedium: { marginTop: 20 },
  marginTopHigh: { marginTop: 30 },
  marginBottomMedium: { marginBottom: 20 },
  marginBottomHigh: { marginBottom: 30 },
  marginTopLow: { marginTop: 10 },
  marginTopVeryLow: { marginTop: 5 },
  marginTopVeryVeryLow: { marginTop: 2 },
  marginTopLowValue: { marginTop: 1 },
  marginTopBottom: { marginTop: 40, marginBottom: 40 },
  paddingTopSeason: { paddingTop: 10, paddingBottom: 10 },
  thickBorderLine: { borderBottomColor: '#f1f1f1', borderBottomWidth: 3 },
  thinBorderLine: { borderBottomColor: '#f1f1f1', borderBottomWidth: 1 },
  table: {
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderColor: '#c7c7c7',
    borderWidth: 1, 
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  allEqualPadding: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5
  },
  seasonTable: { display: "table", width: "auto", marginTop: 8, paddingLeft: 30, paddingRight: 30 }, 
  tableRow: { flexDirection: "row" }, 
  tableColField: {
    width: "34%", 
    borderStyle: "solid", 
    borderColor: '#c7c7c7',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableColBackgroundColor: { backgroundColor: '#cce8e8' },
  tableColPlanned: {
    width: "26%", 
    borderStyle: "solid", 
    borderColor: '#c7c7c7',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableColOthers: {
    width: "20%", 
    borderStyle: "solid", 
    borderColor: '#c7c7c7',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableColEqual: { width: "31%" },
  tableColEqualBig: { width: "38%" },
  tableColLight: { width: "26%" },
  tableColMedium: { width: "22%" },
  seasonColMedium: { width: "30%" },
  fieldColMedium: { width: "34%" },
  fieldColLow: { width: "32%" },
  tableColHeavy: { width: "34%" },
  tableColVeryLight: { width: "18%" },
  paddingRightStyle: { paddingRight: 35},
  tableCell: { fontSize: 10 },
  titleFontSize: { fontSize: 20 },
  pageHeader: { fontFamily: 'openSans' },
  pageTextAlignRight: { textAlign: 'right' },
  pageNumberStyle: { fontSize: 9 },
  pageTitle: { fontSize: 12, textAlign: 'left', fontWeight: 500 },
  textLowercase: { textTransform: lowerCase }
});

function getMineralFertilizer(seasonFertilization,intl) {
  if (seasonFertilization.mineralFertilizersForSeason?.length > 0) {
    const output = seasonFertilization.mineralFertilizersForSeason.map((mineralFertilizers) => (
      <View style={styles.tableRow}>
        <View style={styles.tableColField}>
          <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}>
          {mineralFertilizers.originalName}
          </Text>
        </View>
        <View style={styles.tableColPlanned}>
          <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}>
            {mineralFertilizers.originalQuantity.toFixed(1)} {mineralFertilizers.quantityUnits} ({Math.round(mineralFertilizers.originalTotalQuantity)} {mineralFertilizers.totalQuantityUnits})
          </Text>
        </View>
        <View style={styles.tableColOthers}>
          <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}></Text>
        </View>
        <View style={styles.tableColOthers}>
          <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}></Text>
        </View>
      </View>
    ));
    return output;
  }
}

function getSeason(fieldFertilizationItem,intl,seasonId) {
  if (fieldFertilizationItem.seasonFertilizerPlans?.length > 0) {
    let seasonFertilization = null;
    seasonFertilization = _.filter(fieldFertilizationItem.seasonFertilizerPlans, ['seasonId', seasonId])[0];
    if(seasonFertilization !== null && seasonFertilization !== undefined && (seasonFertilization.manureForSeasonLeft !== null || seasonFertilization.mineralFertilizersForSeason?.length > 0)) {
      return (<View style={[styles.table, styles.marginLeftMedium, styles.marginRightMedium, styles.marginBottomHigh]}>
        <View style={styles.tableRow}>
          <View style={styles.tableColField}>
            <Text style={[styles.tableCell, styles.boldFont, styles.lightFontSize, styles.allEqualPadding, styles.tableColBackgroundColor]}>
              {fieldFertilizationItem.fieldName}
            </Text>
          </View>
          <View style={styles.tableColPlanned}>
            <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding, styles.tableColBackgroundColor]}>
              {intl.formatMessage({ id: 'SEASON_PDF_PLANNED' })}
            </Text>
          </View>
          <View style={styles.tableColOthers}>
            <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding, styles.tableColBackgroundColor]}>
              {intl.formatMessage({ id: 'SEASON_PDF_UTKJORT' })}
            </Text>
          </View>
          <View style={styles.tableColOthers}>
            <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding, styles.tableColBackgroundColor]}>
              {intl.formatMessage({ id: 'SEASON_PDF_NOTAT' })}
            </Text>
          </View>
        </View>
        {seasonFertilization.manureForSeasonLeft !== null && seasonFertilization.manureForSeasonLeft.manureStorageName !== null &&
        <View style={styles.tableRow}>
          <View style={styles.tableColField}>
            <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}>
              {seasonFertilization.manureForSeasonLeft.manureStorageName}
            </Text>
          </View>
          <View style={styles.tableColPlanned}>
            <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}>
              {seasonFertilization.manureForSeasonLeft.manureOriginalQuantity.toFixed(1)} {seasonFertilization.manureForSeasonLeft.manureQuantityUnits} ({Math.round(seasonFertilization.manureForSeasonLeft.manureOriginalTotalQuantity)} {seasonFertilization.manureForSeasonLeft.manureTotalQuantityUnits})
            </Text>
          </View>
          <View style={styles.tableColOthers}>
            <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}></Text>
          </View>
          <View style={styles.tableColOthers}>
            <Text style={[styles.tableCell, styles.lightFontSize, styles.allEqualPadding]}></Text>
          </View>
        </View>}
        {getMineralFertilizer(seasonFertilization, intl)}
      </View>)
    } else {
      return null;
    }
  }
}
      

function getFieldFertilization(documentData,intl,seasonId) {
  if (documentData.fieldFertilizations?.length > 0) {
    const output = _.orderBy(documentData.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem) => (
      getSeason(fieldFertilizationItem,intl,seasonId)
    ));
    return output;
  }
}

function getPdfDocument(documentData, intl) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.flexRowDirection, styles.marginTopBottom]}>
          <View style={[styles.flexHeading, styles.marginTopVeryVeryLow, styles.extraBoldFont, styles.leftHeading]}>
            <Text style={[styles.titleFontSize]}>{documentData.versionName} - {intl.formatMessage({ id: 'CHOOSE_FERTILIZATION' })}</Text>
          </View>
          <View style={[styles.rightHeading, styles.paddingRightLight]}>
            <Image src="images/seasonPdfLogo.png" />
          </View>
        </View>
        {getFieldFertilization(documentData,intl,1)}
      </Page>
    </Document>
  );
}

export default ({ documentData, intl}) => getPdfDocument(documentData,intl);
