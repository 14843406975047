export const IS_LOGGED = 'IS_LOGGED';
export const LOGIN_IS_LOADING = 'LOGIN_IS_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_IS_LOADING = 'LOGOUT_IS_LOADING';
export const LOGOUT_HAS_ERROR = 'LOGOUT_HAS_ERROR';

export const PROFILE_UPDATE_FALIURE = 'PROFILE_UPDATE_FALIURE';
export const PROFILE_UPDATE_LOADING = 'PROFILE_UPDATE_LOADING';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';

// refresh token
export const IS_REFRESH_TOKEN_LOADING = 'IS_REFRESH_TOKEN_LOADING';
export const HAS_REFRESH_TOKEN_ERROR = 'HAS_REFRESH_TOKEN_ERROR';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';

// map token
export const GET_MAP_TOKEN = 'GET_MAP_TOKEN';
export const IS_MAP_TOKEN_LOADING = 'IS_MAP_TOKEN_LOADING';
export const MAP_TOKEN_FAILURE = 'MAP_TOKEN_FAILURE';

// AWS login
export const AWS_LOGIN_IS_LOADING = 'AWS_LOGIN_IS_LOADING';
export const AWS_LOGIN_SUCCESS = 'AWS_LOGIN_SUCCESS';
export const AWS_LOGIN_FAILURE = 'AWS_LOGIN_FAILURE';

// Profile Image
export const CLEAR_PROFILE_UPDATE = 'CLEAR_PROFILE_UPDATE';
export const GET_PROFILE_IMAGE_IS_LOADING = 'GET_PROFILE_IMAGE_IS_LOADING';
export const GET_PROFILE_IMAGE_SUCCESS = 'GET_PROFILE_IMAGE_SUCCESS';
export const GET_PROFILE_IMAGE_FAILURE = 'GET_PROFILE_IMAGE_FAILURE';
export const UPLOAD_PROFILE_IMAGE_IS_LOADING = 'UPLOAD_PROFILE_IMAGE_IS_LOADING';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE';

// Terms and conditions
export const IS_TERMS_CONDITION_LOADING = 'IS_TERMS_CONDITION_LOADING';
export const TERMS_CONDITION_FAILURE = 'TERMS_CONDITION_FAILURE';
export const TERMS_CHECK_SUCCESS = 'TERMS_CHECK_SUCCESS';
export const TERMS_CONDITION_SUCCESS = 'TERMS_CONDITION_SUCCESS';
export const TERMS_ACCEPT_SUCCESS = 'TERMS_ACCEPT_SUCCESS';
