export const MINERAL_STORAGE_LIST_LOADING = 'MINERAL_STORAGE_LIST_LOADING';
export const MINERAL_STORAGE_LIST_SUCCESS = 'MINERAL_STORAGE_LIST_SUCCESS';
export const MINERAL_STORAGE_LIST_ERROR = 'MINERAL_STORAGE_LIST_ERROR';

export const MINERAL_STORAGE_CREATE_LOADING = 'MINERAL_STORAGE_CREATE_LOADING';
export const MINERAL_STORAGE_CREATE_SUCCESS = 'MINERAL_STORAGE_CREATE_SUCCESS';
export const MINERAL_STORAGE_CREATE_ERROR = 'MINERAL_STORAGE_CREATE_ERROR';

export const MINERAL_STORAGE_DELETE_LOADING = 'MINERAL_STORAGE_DELETE_LOADING';
export const MINERAL_STORAGE_DELETE_SUCCESS = 'MINERAL_STORAGE_DELETE_SUCCESS';
export const MINERAL_STORAGE_DELETE_ERROR = 'MINERAL_STORAGE_DELETE_ERROR';