import {
  GET_SCHEDULED_BALES_SUCCESS,
  GET_SCHEDULED_BALES_ERROR,
  GET_SCHEDULED_BALES_LOADING,
  GET_SCHEDULED_BALE_DETAILS_SUCCESS,
  GET_SCHEDULED_BALE_DETAILS_ERROR,
  GET_SCHEDULED_BALE_DETAILS_LOADING,
  SAVE_SCHEDULED_BALES_SUCCESS,
  SAVE_SCHEDULED_BALES_ERROR,
  SAVE_SCHEDULED_BALES_LOADING,
  GET_SCHEDULED_BALE_BY_ID_SUCCESS,
  GET_SCHEDULED_BALE_BY_ID_ERROR,
  GET_SCHEDULED_BALE_BY_ID_LOADING,
  UPDATE_SCHEDULED_BALES_SUCCESS,
  UPDATE_SCHEDULED_BALES_ERROR,
  UPDATE_SCHEDULED_BALES_LOADING
} from 'constants/store/Bale';

const initialState = {
  baleSchedulerData: null,
  baleSchedulerError: false,
  baleSchedulerLoading: false,
  baleSchedulerErrorMessage: null,
  baleDetailsSchedulerData: null,
  baleDetailsSchedulerError: false,
  baleDetailsSchedulerLoading: false,
  baleDetailsSchedulerErrorMessage: null,
  saveSchedulerData: null,
  saveSchedulerError: false,
  saveSchedulerSuccess: false,
  saveSchedulerLoading: false,
  saveSchedulerErrorMessage: null,
  getBaleSchedulerData: null,
  getBaleSchedulerError: false,
  getBaleSchedulerLoading: false,
  getBaleSchedulerErrorMessage: null
};

export default function getAllScheduledBales(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_SCHEDULED_BALES_LOADING:
      return {
        ...state,
        baleSchedulerData: null,
        baleSchedulerError: false,
        baleSchedulerLoading: true,
        baleSchedulerErrorMessage: null
      };
    case GET_SCHEDULED_BALES_ERROR:
      return {
        ...state,
        baleSchedulerData: null,
        baleSchedulerError: true,
        baleSchedulerLoading: false,
        baleSchedulerErrorMessage: action.errorMessage
      };
    case GET_SCHEDULED_BALES_SUCCESS:
      return {
        ...state,
        baleSchedulerData: action.payload,
        baleSchedulerError: false,
        baleSchedulerLoading: false,
        baleSchedulerErrorMessage: null,
        saveSchedulerData: null,
        saveSchedulerError: false,
        saveSchedulerSuccess: false,
        saveSchedulerLoading: false,
        saveSchedulerErrorMessage: null
      };
    case GET_SCHEDULED_BALE_DETAILS_LOADING:
      return {
        ...state,
        baleDetailsSchedulerData: null,
        baleDetailsSchedulerError: false,
        baleDetailsSchedulerLoading: true,
        baleDetailsSchedulerErrorMessage: null
      };
    case GET_SCHEDULED_BALE_DETAILS_ERROR:
      return {
        ...state,
        baleDetailsSchedulerData: null,
        baleDetailsSchedulerError: true,
        baleDetailsSchedulerLoading: false,
        baleDetailsSchedulerErrorMessage: action.errorMessage
      };
    case GET_SCHEDULED_BALE_DETAILS_SUCCESS:
      return {
        ...state,
        baleDetailsSchedulerData: action.payload,
        baleDetailsSchedulerError: false,
        baleDetailsSchedulerLoading: false,
        baleDetailsSchedulerErrorMessage: null,
        saveSchedulerData: null,
        saveSchedulerError: false,
        saveSchedulerSuccess: false,
        saveSchedulerLoading: false,
        saveSchedulerErrorMessage: null
      };
    case SAVE_SCHEDULED_BALES_LOADING:
    case UPDATE_SCHEDULED_BALES_LOADING:
      return {
        ...state,
        saveSchedulerData: null,
        saveSchedulerError: false,
        saveSchedulerSuccess: false,
        saveSchedulerLoading: true,
        saveSchedulerErrorMessage: null
      };
    case SAVE_SCHEDULED_BALES_ERROR:
    case UPDATE_SCHEDULED_BALES_ERROR:
      return {
        ...state,
        saveSchedulerData: null,
        saveSchedulerError: true,
        saveSchedulerSuccess: false,
        saveSchedulerLoading: false,
        saveSchedulerErrorMessage: action.payload.data
      };
    case SAVE_SCHEDULED_BALES_SUCCESS:
    case UPDATE_SCHEDULED_BALES_SUCCESS:
      return {
        ...state,
        saveSchedulerData: action.payload,
        saveSchedulerError: false,
        saveSchedulerLoading: false,
        saveSchedulerSuccess: true,
        saveSchedulerErrorMessage: null
      };
    case GET_SCHEDULED_BALE_BY_ID_LOADING:
      return {
        ...state,
        getBaleSchedulerData: null,
        getBaleSchedulerError: false,
        getBaleSchedulerLoading: true,
        getBaleSchedulerErrorMessage: null
      };
    case GET_SCHEDULED_BALE_BY_ID_ERROR:
      return {
        ...state,
        getBaleSchedulerData: null,
        getBaleSchedulerError: true,
        getBaleSchedulerLoading: false,
        getBaleSchedulerErrorMessage: action.payload.data
      };
    case GET_SCHEDULED_BALE_BY_ID_SUCCESS:
      return {
        ...state,
        getBaleSchedulerData: action.payload,
        getBaleSchedulerError: false,
        getBaleSchedulerLoading: false,
        getBaleSchedulerErrorMessage: null
      };
    default:
      return state;
  }
}
