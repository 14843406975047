import { GET_STATS } from 'constants/ApiUrl';
import {
  IS_STATS_LOADING,
  STATS_HEADER_ERROR,
  STATS_HEADER,
  IS_STATS_ACTIVITIES_LOADING,
  STATS_ACTIVITIES_ERROR,
  STATS_ACTIVITIES
} from 'constants/store/Stats';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * to get stats header details
 * @param {string} farmId
 */
export const fetchStatsHeader = (farmId, yearSelection) => async dispatch => {
  dispatch({ type: IS_STATS_LOADING, payload: true });
  await dispatch(
    apiRequest({
      url: `${GET_STATS}${farmId}/components?year=${yearSelection}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch({ type: STATS_HEADER, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: STATS_HEADER_ERROR, payload: error });
    });
};

/**
 * to get stats activity details
 * @param {String} farmId
 */
export const fetchStatsActivities = (farmId, yearSelected) => async (dispatch, getState) => {
  dispatch({ type: IS_STATS_ACTIVITIES_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${GET_STATS}${farmId}/activities?year=${yearSelected}&language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch({ type: STATS_ACTIVITIES, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: STATS_ACTIVITIES_ERROR, payload: error });
    });
};
