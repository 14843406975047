/**
 * Help Text feedback component
 * User's inputs will posted to google form
 * on sumbit google throws error as response but value will be store in the google form.
 */

import * as React from 'react';
import axios from 'axios';
import { reduxForm, Field } from 'redux-form';
import { injectIntl } from 'react-intl';

import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import { BackIcon } from '@tine/lib-frontend-components/elements/icons';
import TextBoxControl from 'components/forms/TextBoxControl';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';

class FeedbackForm extends React.Component {
  state = {
    isSuccess: false
  };

  handleSubmit = e => {
    const url =
      'https://docs.google.com/forms/d/e/1FAIpQLSeWpEVUVw8Kl51mjAqHYc_6gYVt_DhojrTCaG5wBjMdDNYiIw/formResponse?';
    axios(
      `${url}entry.657380528=${e.trouble}&entry.610387546=${e.solution}&entry.724065957=${e.contact}&entry.328042035="Web App"&entry.421674194=${navigator.appVersion}`,
      {
        method: 'POST'
      }
    )
      .then(response => {
        this.setState({
          isSuccess: true
        });
      })
      .catch(error => {
        this.setState({
          isSuccess: true
        });
      });
  };

  render() {
    const { handleSubmit, intl, backToHelp, initialize } = this.props;
    const { isSuccess } = this.state;
    return (
      <div className="help-feedback">
        <button
          type="button"
          className="link btn-back"
          onClick={() => {
            backToHelp(false);
          }}
        >
          <BackIcon scale={0.35} />
          <span>{intl.formatMessage({ id: 'BACK_HELP' })}</span>
        </button>
        <div className="help-feedback__wrap">
          <h2 className="feedback-title">
            <EmphasisLight size="extralarge">
              {intl.formatMessage({ id: 'FEEDBACK_TITLE' })}
            </EmphasisLight>
          </h2>
          {!isSuccess && (
            <form className="full-width" onSubmit={handleSubmit(this.handleSubmit)}>
              <Field
                name="trouble"
                label={intl.formatMessage({ id: 'FB_QUESTION_1' })}
                component={TextBoxControl}
                controlId="trouble"
                type="text"
              />
              <Field
                name="solution"
                label={intl.formatMessage({ id: 'FB_QUESTION_2' })}
                component={TextBoxControl}
                controlId="solution"
                type="text"
              />
              <Field
                name="contact"
                label={intl.formatMessage({ id: 'FB_QUESTION_3' })}
                component={TextBoxControl}
                controlId="contact"
                type="text"
              />
              <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                {intl.formatMessage({ id: 'send' })}
              </ActionButton>
            </form>
          )}
          {isSuccess && (
            <div className="help-feedback__success">
              <div>
                <i className="success-indicator" />
              </div>

              <p>{intl.formatMessage({ id: 'FEEDBACK_SUCCESS' })}</p>
              <div>
                <button
                  type="button"
                  className="link btn-link"
                  onClick={() => {
                    this.setState({ isSuccess: false });
                    initialize({ trouble: '', solution: '', contact: '' });
                  }}
                >
                  {intl.formatMessage({ id: 'SUBMIT_ANOTHER' })}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(
  reduxForm({
    form: 'FeedbackForm'
  })(FeedbackForm)
);
