import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { lowerCase } from 'lodash';
import _ from 'lodash';
import Moment from 'moment';
Font.register({ family: 'openSans', fonts: [
  { src: 'fonts/Open_Sans/OpenSans-Regular.ttf' },
  { src: 'fonts/Open_Sans/OpenSans-Bold.ttf', fontWeight: 700 },
  { src: 'fonts/Open_Sans/OpenSans-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 700 },
  { src: 'fonts/Open_Sans/OpenSans-ExtraBold.ttf', fontWeight: 900 },
  { src: 'fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf', fontStyle: 'italic', fontWeight: 900 },
  { src: 'fonts/Open_Sans/OpenSans-Italic.ttf', fontStyle: 'italic' },
  { src: 'fonts/Open_Sans/OpenSans-Light.ttf', fontWeight: 300 },
  { src: 'fonts/Open_Sans/OpenSans-LightItalic.ttf', fontStyle: 'italic', fontWeight: 300 },
  { src: 'fonts/Open_Sans/OpenSans-SemiBold.ttf', fontWeight: 500 },
  { src: 'fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf', fontStyle: 'italic', fontWeight: 500 }
 ]});

/** Create styles **/
const styles = StyleSheet.create({
  page: { backgroundColor: '#ffffff', fontFamily: 'openSans', paddingBottom: 65 },
  pageMarginLeft: { marginLeft: 148, marginRight: 15 },
  pageMarginLight: { marginLeft: 15, marginRight: 15 },
  pageMargin: { marginLeft: 30, marginRight: 30 },
  flexRowDirection: { flexDirection: 'row' },
  flexHeading: { paddingLeft: 30, paddingRight: 30, flexGrow: 1 },
  leftHeading: { width: '82%' },
  rightHeading: { width: '18%' },
  boldFont: { fontWeight: 500 },
  extraBoldFont: { fontWeight: 700 },
  normalFont: { fontWeight: 300 },
  normalFontSize: { fontSize: 12 },
  heavyFontSize: { fontSize: 12, fontWeight: 400 },
  lightFontSize: { fontSize: 10 },
  fieldFont: { fontSize: 14, fontWeight: 500 },
  fieldSmallFont: { fontSize: 13, fontWeight: 400 },
  seasonFont: { fontSize: 14, fontWeight: 300 },
  paddingTopVeryLight: { paddingTop: 5 },
  paddingTopLight: { paddingTop: 10 },
  paddingTopMedium: { paddingTop: 20 },
  marginBottomVeryHeavy: { marginBottom: 10 },
  marginTopHeavy: { marginTop: 60 },
  marginTopMedium: { marginTop: 20 },
  marginTopHigh: { marginTop: 30 },
  marginBottomHigh: { marginBottom: 30 },
  marginTopLow: { marginTop: 10 },
  marginTopVeryLow: { marginTop: 5 },
  marginTopLowValue: { marginTop: 1 },
  paddingTopSeason: { paddingTop: 10, paddingBottom: 10 },
  thickBorderLine: { borderBottomColor: '#f1f1f1', borderBottomWidth: 3 },
  thinBorderLine: { borderBottomColor: '#f1f1f1', borderBottomWidth: 1 },
  table: { display: "table", width: "auto", marginTop: 8, paddingLeft: 30, paddingRight: 30 },
  seasonTable: { display: "table", width: "auto", marginTop: 8, paddingLeft: 30, paddingRight: 30 }, 
  tableRow: { flexDirection: "row" }, 
  tableCol: { width: "50%" },
  tableColEqual: { width: "31%" },
  tableColEqualBig: { width: "38%" },
  tableColLight: { width: "26%" },
  tableColMedium: { width: "22%" },
  seasonColMedium: { width: "30%" },
  fieldColMedium: { width: "34%" },
  fieldColLow: { width: "32%" },
  tableColHeavy: { width: "34%" },
  tableColVeryLight: { width: "18%" },
  paddingRightStyle: { paddingRight: 35},
  tableCell: { fontSize: 10 },
  pageHeader: { fontFamily: 'openSans' },
  pageTextAlignRight: { textAlign: 'right' },
  pageNumberStyle: { fontSize: 9 },
  pageTitle: { fontSize: 12, textAlign: 'left', fontWeight: 500 },
  textLowercase: { textTransform: lowerCase }
});

function getMineralFertilizerTotalCount(seasonFertilizationItem,intl) {
  if (seasonFertilizationItem.mineralFertilizersForSeason?.length > 0) {
    return seasonFertilizationItem.mineralFertilizersForSeason.map((mineralFertilizerItem, index) => (
      <View key={mineralFertilizerItem}>
        { 
          (seasonFertilizationItem.manureForSeason.manureCustomQuantity <= 0 && seasonFertilizationItem.manureForSeason.manureOriginalQuantity <= 0 && index === 0) ? 
            <View /> : 
            <View style={[styles.pageMarginLeft, styles.thinBorderLine, styles.marginTopVeryLow]} />
        }
        <View style={[styles.seasonTable]}>
          <View style={styles.tableRow}>
            { 
              (seasonFertilizationItem.manureForSeason.manureCustomQuantity <= 0 && seasonFertilizationItem.manureForSeason.manureOriginalQuantity <= 0 && index === 0) ? 
                <View style={styles.seasonColMedium}>
                  <Text style={[styles.tableCell, styles.seasonFont]}>{seasonFertilizationItem.season}</Text>
                </View> : 
                <View style={styles.seasonColMedium}></View>
            }
            <View style={styles.tableColHeavy}>
              <Text style={[styles.lightFontSize, styles.heavyFontSize, styles.marginTopLowValue]}>
                {mineralFertilizerItem.customName === null
                ? mineralFertilizerItem.originalName
                : mineralFertilizerItem.customName}
              </Text>
            </View>
            <View style={styles.tableColVeryLight}>
              <Text style={[styles.lightFontSize, styles.heavyFontSize, styles.marginTopLowValue]}>
                {mineralFertilizerItem.customQuantity === null
                ? mineralFertilizerItem.originalQuantity.toFixed(1)
                : mineralFertilizerItem.customQuantity.toFixed(1)} {mineralFertilizerItem.quantityUnits}
              </Text>
            </View>
            <View style={styles.tableColVeryLight}>
              <Text style={[styles.lightFontSize, styles.heavyFontSize, styles.marginTopLowValue]}>
                <Text style={styles.boldFont}>
                  {mineralFertilizerItem.customTotalQuantity === null
                  ? mineralFertilizerItem.originalTotalQuantity.toFixed(1)
                  : mineralFertilizerItem.customTotalQuantity.toFixed(1)} {mineralFertilizerItem.totalQuantityUnits}
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
    ));
  }
}

function getManureTotalCount(seasonFertilizationItem,intl) {
  if (seasonFertilizationItem.manureForSeason.manureCustomQuantity > 0 || seasonFertilizationItem.manureForSeason.manureOriginalQuantity > 0) {
    return (
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.seasonColMedium}>
            <Text style={[styles.tableCell, styles.seasonFont]}>{seasonFertilizationItem.season}</Text>
          </View>
          <View style={styles.tableColHeavy}>
            <Text style={[styles.tableCell, styles.heavyFontSize, styles.marginTopLowValue]}>
              {seasonFertilizationItem.manureForSeason.manureType}
            </Text>
          </View>
          <View style={styles.tableColVeryLight}>
            <Text style={[styles.tableCell, styles.heavyFontSize, styles.marginTopLowValue]}>
              {seasonFertilizationItem.manureForSeason.manureCustomQuantity === null
              ? seasonFertilizationItem.manureForSeason.manureOriginalQuantity.toFixed(1)
              : seasonFertilizationItem.manureForSeason.manureCustomQuantity.toFixed(1)} {seasonFertilizationItem.manureForSeason.manureQuantityUnits}
            </Text>
          </View>
          <View style={styles.tableColVeryLight}>
            <Text style={[styles.tableCell, styles.heavyFontSize, styles.marginTopLowValue]}>
              <Text style={styles.boldFont}>
                {seasonFertilizationItem.manureForSeason.manureCustomTotalQuantity === null
                  ? Math.round(seasonFertilizationItem.manureForSeason.manureOriginalTotalQuantity)
                  : Math.round(
                      seasonFertilizationItem.manureForSeason.manureCustomTotalQuantity
                    )}{' '}
                {seasonFertilizationItem.manureForSeason.manureTotalQuantityUnits} <Text style={styles.textLowercase}>{intl.formatMessage({ id: 'TOTAL' })}</Text>
              </Text>
            </Text>
          </View>
        </View>
      </View>
    )
  }
}

function getFieldFertilization(documentData,intl) {
  if (documentData.fieldFertilizations?.length > 0) {
    const output = _.orderBy(documentData.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem) => (
    //const output = documentData.fieldFertilizations.map((fieldFertilizationItem) => (
      <View key={fieldFertilizationItem.area}>
        <View style={[styles.pageMarginLight, styles.thickBorderLine, styles.marginTopHeavy]} />
        <View style={[styles.pageMargin, styles.paddingTopLight]}>
          <Text style={[styles.fieldFont]}>{fieldFertilizationItem.fieldName}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.fieldColLow}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'HEADER_AREA' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.area} {fieldFertilizationItem.areaUnits}</Text>
              </Text>
            </View>
            <View style={styles.fieldColLow}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'NUMBER_OF_HARVESTS' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.numberOfHarvests}</Text>
              </Text>
            </View>
            <View style={styles.fieldColMedium}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'NUMBER_OF_FERTILIZATIONS' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.numberOfFertilizations}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.fieldColLow}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'MANURE' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.manureApplied === true ? intl.formatMessage({ id: 'YES' }) : intl.formatMessage({ id: 'NO' })}</Text>
              </Text>
            </View>
            <View style={styles.fieldColLow}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'YIELD_GOAL' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.soilParam.yieldGoal} kg ts/daa</Text>
              </Text>
            </View>
            <View style={styles.fieldColMedium}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'FP_REPORT_CROP' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.soilParam.crop}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.fieldColLow}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'FP_REPORT_PREVIOUS_CROP' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.soilParam.previousCrop}</Text>
              </Text>
            </View>
            <View style={styles.fieldColMedium}>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'FIELD_ORGANIC_LABEL' })}: <Text style={styles.boldFont}> {fieldFertilizationItem.soilParam.goOrganic === true ? intl.formatMessage({ id: 'YES' }) : intl.formatMessage({ id: 'NO' })}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View>
              <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
                {intl.formatMessage({ id: 'NEED' })}: <Text style={styles.boldFont}> 
                  N - {fieldFertilizationItem.needForField.nitrogenNeed.toFixed(1)}{' '}
                  {fieldFertilizationItem.needForField.nutrientUnitsNeed}{", "}
                  P - {fieldFertilizationItem.needForField.phosphorusNeed.toFixed(1)}{' '}
                  {fieldFertilizationItem.needForField.nutrientUnitsNeed}{", "}
                  K - {fieldFertilizationItem.needForField.potassiumNeed.toFixed(1)}{' '}
                  {fieldFertilizationItem.needForField.nutrientUnitsNeed}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        {fieldFertilizationItem.seasonFertilizerPlans.map((seasonFertilizationItem, index) => (
          <View key={index}>
            <View style={[styles.pageMarginLight, styles.thinBorderLine, styles.marginTopLow]} />
            {getManureTotalCount(seasonFertilizationItem,intl)}
            {getMineralFertilizerTotalCount(seasonFertilizationItem,intl)}
          </View>
        ))}
      </View>
    ));
    return output;
  }
}

function getSoilParams(fieldFertilizationSoilParams, intl, soilParam, index) {
  const listSoilParams = [];
  if(soilParam === "source") {
    if(fieldFertilizationSoilParams.year !== 0  && fieldFertilizationSoilParams.year !== null) {
      listSoilParams.push({label: intl.formatMessage({ id: 'YEAR' }), value: fieldFertilizationSoilParams.year})
    }
  } else {
    if(fieldFertilizationSoilParams.sampleYear !== 0  && fieldFertilizationSoilParams.sampleYear !== null) {
      listSoilParams.push({label: intl.formatMessage({ id: 'YEAR' }), value: fieldFertilizationSoilParams.sampleYear})
    }
  } 
  if(fieldFertilizationSoilParams.soilType !== 0  && fieldFertilizationSoilParams.soilType !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_SOIL_TYPE' }), value: fieldFertilizationSoilParams.soilType})
  }
  if(fieldFertilizationSoilParams.moldClass !== 0  && fieldFertilizationSoilParams.moldClass !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_MOLD_CLASS' }), value: fieldFertilizationSoilParams.moldClass})
  }
  if(fieldFertilizationSoilParams.phosphorus !== 0  && fieldFertilizationSoilParams.phosphorus !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_PHOSPHORUS' }), value: fieldFertilizationSoilParams.phosphorus})
  }
  if(fieldFertilizationSoilParams.potassium !== 0  && fieldFertilizationSoilParams.potassium !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_POTASSIUM' }), value: fieldFertilizationSoilParams.potassium})
  }
  if(fieldFertilizationSoilParams.acidSolublePotassium !== 0  && fieldFertilizationSoilParams.acidSolublePotassium !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_ACID_SOLUBLE' }), value: fieldFertilizationSoilParams.acidSolublePotassium})
  }
  if(soilParam === "source") {
    if(fieldFertilizationSoilParams.pH !== 0  && fieldFertilizationSoilParams.pH !== null) {
      listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_SOIL_PH' }), value: fieldFertilizationSoilParams.pH})
    }
  } else {
    if(fieldFertilizationSoilParams.ph !== 0  && fieldFertilizationSoilParams.ph !== null) {
      listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_SOIL_PH' }), value: fieldFertilizationSoilParams.ph})
    }
  }
  if(fieldFertilizationSoilParams.glowLoss !== 0  && fieldFertilizationSoilParams.glowLoss !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_GLOW_LOSS' }), value: fieldFertilizationSoilParams.glowLoss})
  }
  if(fieldFertilizationSoilParams.clayClass !== 0  && fieldFertilizationSoilParams.clayClass !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_CLAY_CLASS' }), value: fieldFertilizationSoilParams.clayClass})
  }
  if(fieldFertilizationSoilParams.sodium !== 0  && fieldFertilizationSoilParams.sodium !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_SODIUM' }), value: fieldFertilizationSoilParams.sodium})
  }
  if(fieldFertilizationSoilParams.magnesium !== 0  && fieldFertilizationSoilParams.magnesium !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_MAGNESIUM' }), value: fieldFertilizationSoilParams.magnesium})
  }
  if(fieldFertilizationSoilParams.calsium !== 0  && fieldFertilizationSoilParams.calsium !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_CALSIUM' }), value: fieldFertilizationSoilParams.calsium})
  }
  if(fieldFertilizationSoilParams.mold !== 0  && fieldFertilizationSoilParams.mold !== null) {
    listSoilParams.push({label: intl.formatMessage({ id: 'FP_REPORT_MOLD_CONTENT' }), value: fieldFertilizationSoilParams.mold})
  }
  return (
    <View>
      {index > 0 && <View style={[styles.pageMarginLight, styles.thinBorderLine, styles.paddingTopSeason]} />}
      {listSoilParams.length > 0 &&
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {listSoilParams.length >= 1 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[0].label}: <Text style={styles.boldFont}> {listSoilParams[0].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 2 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[1].label}: <Text style={styles.boldFont}> {listSoilParams[1].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 3 &&
          <View style={styles.tableColEqualBig}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[2].label}: <Text style={styles.boldFont}> {listSoilParams[2].value}</Text>
            </Text>
          </View>}
        </View>
      </View>}
      {listSoilParams.length > 3 &&
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {listSoilParams.length >= 4 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[3].label}: <Text style={styles.boldFont}> {listSoilParams[3].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 5 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[4].label}: <Text style={styles.boldFont}> {listSoilParams[4].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 6 &&
          <View style={styles.tableColEqualBig}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[5].label}: <Text style={styles.boldFont}> {listSoilParams[5].value}</Text>
            </Text>
          </View>}
        </View>
      </View>}
      {listSoilParams.length > 6 &&
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {listSoilParams.length >= 7 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[6].label}: <Text style={styles.boldFont}> {listSoilParams[6].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 8 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[7].label}: <Text style={styles.boldFont}> {listSoilParams[7].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 9 &&
          <View style={styles.tableColEqualBig}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[8].label}: <Text style={styles.boldFont}> {listSoilParams[8].value}</Text>
            </Text>
          </View>}
        </View>
      </View>}
      {listSoilParams.length > 9 &&
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {listSoilParams.length >= 10 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[9].label}: <Text style={styles.boldFont}> {listSoilParams[9].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 11 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[10].label}: <Text style={styles.boldFont}> {listSoilParams[10].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 12 &&
          <View style={styles.tableColEqualBig}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[11].label}: <Text style={styles.boldFont}> {listSoilParams[11].value}</Text>
            </Text>
          </View>}
        </View>
      </View>}
      {listSoilParams.length > 12 &&
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {listSoilParams.length >= 13 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[12].label}: <Text style={styles.boldFont}> {listSoilParams[12].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 14 &&
          <View style={styles.tableColEqual}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[13].label}: <Text style={styles.boldFont}> {listSoilParams[13].value}</Text>
            </Text>
          </View>}
          {listSoilParams.length >= 15 &&
          <View style={styles.tableColEqualBig}>
            <Text style={[styles.tableCell, styles.normalFont, styles.lightFontSize, styles.paddingRightStyle]}>
              {listSoilParams[14].label}: <Text style={styles.boldFont}> {listSoilParams[14].value}</Text>
            </Text>
          </View>}
        </View>
      </View>}
    </View>)
}

function getFieldFertilizationForSoilParams(documentData,intl) {
  if (documentData.fieldFertilizations?.length > 0) {
    const output = _.orderBy(documentData.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem) => (
      <View key={fieldFertilizationItem.fieldName}>
        <View>
          <View style={[styles.pageMargin, styles.paddingTopLight]}>
            <Text style={[styles.fieldFont]}>{fieldFertilizationItem.fieldName}</Text>
          </View>
          {fieldFertilizationItem.soilParam.fieldSoilIds !== null && fieldFertilizationItem.soilParam.fieldSoilData.length > 0 &&
          <View style={[styles.pageMargin, styles.paddingTopLight]}>
            <Text style={[styles.fieldSmallFont]}>{intl.formatMessage({ id: 'FP_REPORT_AVERAGE' })}</Text>
          </View>}
          {getSoilParams(fieldFertilizationItem.soilParam, intl, "average", 0)}
          {fieldFertilizationItem.soilParam.fieldSoilIds !== null && fieldFertilizationItem.soilParam.fieldSoilData.length > 0 &&
          <View style={[styles.pageMargin, styles.paddingTopLight]}>
            <Text style={[styles.fieldSmallFont]}>{intl.formatMessage({ id: 'FP_REPORT_SOURCE' })}</Text>
          </View>}
          {fieldFertilizationItem.soilParam.fieldSoilIds !== null && fieldFertilizationItem.soilParam.fieldSoilData.length > 0 &&
          fieldFertilizationItem.soilParam.fieldSoilData.map((soilData,index) => (
            getSoilParams(soilData, intl, "source", index)
          ))}
        </View>
        <View style={[styles.pageMarginLight, styles.thinBorderLine, styles.paddingTopSeason]} />
      </View>
    ));
    return output;
  }
}

function getPdfDocument(documentData, intl) {
  let today = new Date();
  let date = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
  let pageName = intl.formatMessage({ id: 'PAGE' });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.flexRowDirection} fixed>
          <View render={({ pageNumber }) => (
            (pageNumber !== 1) && (
              <View style={[styles.flexHeading]}>
                <Text style={[styles.pageHeader, styles.pageTitle, styles.marginTopHigh]}>{documentData.planName}</Text>
                <Text style={[styles.pageHeader, styles.extraBoldFont, styles.pageTextAlignRight, styles.marginBottomHigh]}>Eana</Text>
              </View>
            )
          )} fixed />
          <View style={[styles.flexHeading]}>
            <Text style={[styles.pageHeader, styles.pageNumberStyle, styles.pageTextAlignRight, styles.marginTopHigh, styles.marginBottomHigh]} render={({ pageNumber, totalPages }) => (
             `${pageName} ${pageNumber}/${totalPages}`
            )} fixed />
          </View>
        </View>
        <View style={styles.flexRowDirection}>
          <View style={[styles.flexHeading, styles.extraBoldFont, styles.leftHeading]}>
            <Text>{documentData.planName}</Text>
            <Text style={[styles.normalFont, styles.paddingTopVeryLight, styles.lightFontSize]}>{intl.formatMessage({ id: 'PRINTED' })} {date}</Text>
          </View>
          <View style={[styles.flexHeading, styles.extraBoldFont, styles.rightHeading]}>
            <Text>Eana</Text>
            <Image style={styles.paddingTopLight} src="images/eana-logo.png" />
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell, styles.boldFont, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'FP_REPORT_FARM' })}: <Text style={styles.normalFont}> {documentData.farmName}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'FP_REPORT_MADE_BY' })}: <Text style={styles.normalFont}> {documentData.owner}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'FP_REPORT_CREATED' })}: <Text style={styles.normalFont}> {Moment(documentData.createdDate).format("DD.MM.yyyy")}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'FP_REPORT_UPDATED' })}: <Text style={styles.normalFont}> {Moment(documentData.lastUpdatedTime).format("DD.MM.yyyy")}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'FOOTER_ADDR' })}: <Text style={styles.normalFont}> {documentData.address}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'email' })}: <Text style={styles.normalFont}> {documentData.emailId}</Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell, styles.boldFont, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'ORGANIZATIONALNUMBER' })}: <Text style={styles.normalFont}> {documentData.organizationalNumber}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'MUNICIPALITYNO' })}: <Text style={styles.normalFont}> {documentData.municipalityNumber}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'FARMID' })}: <Text style={styles.normalFont}> {documentData.farmerId}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.boldFont, styles.paddingTopLight, styles.lightFontSize]}>
                {intl.formatMessage({ id: 'USAGEID' })}: <Text style={styles.normalFont}> {documentData.usageId}</Text>
              </Text>
            </View>
          </View>
        </View>        
        {getFieldFertilization(documentData,intl)}
        <View style={[styles.pageMarginLight, styles.thickBorderLine, styles.marginTopHeavy]} />
        {getFieldFertilizationForSoilParams(documentData,intl)}
      </Page>
    </Document>
  );
}

export default ({ documentData, intl }) => getPdfDocument(documentData,intl);
