import {
  HISTORICAL_LIST_LOADING, 
  HISTORICAL_LIST_SUCCESS, 
  HISTORICAL_LIST_ERROR
} from 'constants/store/Fields';

const initialState = {
  hasError: false,
  historicalListLoading: false,
  historicalListError: null,
  historicalListValue: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case HISTORICAL_LIST_LOADING:
      return {
        ...state,
        historicalListLoading: true,
        hasError: false,
        historicalListError: null
      };
    case HISTORICAL_LIST_ERROR:
      return {
        ...state,
        hasError: true,
        historicalListError: action.payload,
        historicalListLoading: false
      };
    case HISTORICAL_LIST_SUCCESS:
      return {
        ...state,
        historicalListLoading: false,
        historicalListError: null,
        hasError: false,
        historicalListValue: action.payload
      };
    default:
      return state;
  }
}
