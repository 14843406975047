export const GA_TRACKING_ID = 'G-J477C151QS';
const IS_GA_ENABLE = process.env.REACT_APP_API_GOOGLE_ANALYTICS === 'true';

export const GAConfig = url => {
  if (IS_GA_ENABLE) {
    window.gtag('js', new Date());
    window.gtag('config', GA_TRACKING_ID);
  }
};
/**
 * to send page path to GA
 * @param {string} url
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const pageview = url => {
  if (IS_GA_ENABLE) {
    window.gtag('config', GA_TRACKING_ID, {
      page_location: url
    });
  }
};

/**
 * to send GA events
 * @param {string} action
 * @param {string} category
 * @param {string} label
 * @param {string || Int} value
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const GAEvent = (action, category, label, value) => {
  if (IS_GA_ENABLE) {
    let eventData = {
      event_category: category,
      event_label: label
    };
    eventData = value ? { ...eventData, value } : eventData;
    window.gtag('event', action, eventData);
  }
};
