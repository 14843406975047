import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import { CheckMarkIcon, AlertIcon } from '@tine/lib-frontend-components/elements/icons';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';
import ConfirmDialog from '@tine/lib-frontend-components/components/dialogs/confirm-dialog';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import mixpanel from 'mixpanel-browser';
import { UPLOAD_SOIL_SAMPLE_RESULTS } from 'constants/MixPanel';
import * as SoilSamplesAction from 'actions/SoilSamples';
import { getSoilSampleDropdown } from 'utils/index';
import { CODE_409 } from 'constants/index';
import { FORCE_UPDATE } from 'constants/store/Fertilization';
import SelectControl from 'components/forms/SelectBoxControl';

class SampleUpload extends React.Component {
  state = {
    fileValue: null,
    uploadFileValue: null,
    selectDropdown: false,
    selectedFileFormat: null,
    selectedFileFormatError: false
  };

  onDrop = files => {
    const { uploadSamples, activeFarm } = this.props;
    const { selectDropdown, selectedFileFormat, fileValue } = this.state;
    if(!fileValue) {
      this.setState({ 
        fileValue: files,
        uploadFileValue: files
      });
    }
    if(!selectDropdown) {
      this.setState({
        selectDropdown: true
      });
    } else {
      if(!selectedFileFormat) {
        this.setState({
          selectedFileFormatError: true
        });
      } else {
        if (fileValue[0]?.name) {
          const formData = new FormData();
          formData.append('file', fileValue[0]);
          const { userDetails } = this.props;
          mixpanel.identify(userDetails.id);
          mixpanel.track(UPLOAD_SOIL_SAMPLE_RESULTS);
          uploadSamples(activeFarm.farm.id, formData, selectedFileFormat);
          this.setState({
            selectDropdown: false,
            fileValue: null
          });
        }
      }
    }
  };

  warningAction = action => {
    const { clearSoilUploadInfo } = this.props;
    if (action) {
      this.setState({
        selectDropdown: false
      })
      this.uploadFile();
    } else {
      clearSoilUploadInfo();
    }
  };

  uploadFile = () => {
    const { uploadSamples, activeFarm } = this.props;
    const { selectedFileFormat, uploadFileValue } = this.state;
    if(!selectedFileFormat) {
      this.setState({
        selectedFileFormatError: true
      });
    } else {
      if (uploadFileValue[0]?.name) {
        const formData = new FormData();
        formData.append('file', uploadFileValue[0]);
        const { userDetails } = this.props;
        mixpanel.identify(userDetails.id);
        mixpanel.track(UPLOAD_SOIL_SAMPLE_RESULTS);
        uploadSamples(activeFarm.farm.id, formData, selectedFileFormat, FORCE_UPDATE);
        this.setState({
          selectDropdown: false,
          fileValue: null
        });
      }
    }
  };

  onChangeDropdown = e => {
    this.setState({
      selectedFileFormat: e.value,
      selectedFileFormatError: false
    })
  }

  closeSelectDropdown = () => {
    this.setState({
      selectDropdown: false,
      selectedFileFormatError: false
    });
  }

  render() {
    const { labels, soilSamples, clearSoilUploadInfo, intl } = this.props;
    const { selectDropdown, selectedFileFormatError } = this.state;
    return (
      <>
        <Dropzone onDrop={this.onDrop} noClick multiple={false} /*accept="text"*/>
          {({ getRootProps, getInputProps, open, isDragReject, isDragActive }) => (
            <section className="container">
              <div
                {...getRootProps({
                  className: `dropzone ${isDragReject ? 'drag-reject' : ''} ${
                    isDragActive ? 'drag-active' : ''
                  }`
                })}
              >
                {!soilSamples.isUploading ? (
                  <>
                    <input {...getInputProps()} />
                    <p>{labels.dragAndUpload}</p>
                    <ActionButton onClick={open}>{labels.uploadFile}</ActionButton>
                  </>
                ) : (
                  <div className="progress-bar">
                    <div className="progress-bar-container">
                      <div
                        className="animate-progres"
                        style={{ width: `${soilSamples.uploadPercentage}%` }}
                      />
                    </div>
                    <p>
                      <MetaInformation size="small" italic>
                        {labels.uploading}
                      </MetaInformation>
                    </p>
                  </div>
                )}
              </div>
            </section>
          )}
        </Dropzone>
        <div className="upload-msg-box">
          {(soilSamples.uploadSucess ||
            (soilSamples.hasUploadError && soilSamples.uploadMsg?.status !== CODE_409)) && (
            <ModalDialog
              show={soilSamples.uploadSucess || soilSamples.hasUploadError}
              width={{ mobile: '300px', tablet: '300px', web: '360px' }}
              height={{ mobile: '300px', tablet: '300px', web: '300px' }}
              closeIcon=""
              onClose={() => {}}
            >
              <div
                className={`completed-upload ${soilSamples.hasUploadError ? 'upload-error' : ''}`}
              >
                {soilSamples.hasUploadError ? (
                  <AlertIcon scale={1.25} />
                ) : (
                  <CheckMarkIcon scale={1.25} />
                )}
                {/*<p>
                  <EmphasisLight size="large">
                    {soilSamples.hasUploadError ? labels.updateFailed : labels.uploaded}
                  </EmphasisLight>
                </p>*/}
                <p>
                  <Text>
                    {soilSamples.hasUploadError
                      ? soilSamples.uploadMsg?.data ? soilSamples.uploadMsg?.data : intl.formatMessage({ id: 'INVALID_SOIL_SAMPLE_FILE_FORMAT' })
                      : intl.formatMessage({ id: 'SOIL_SAMPLE_UPLOAD_TEXT' })}
                  </Text>
                </p>
                <ActionButton
                  tertiary
                  onClick={() => {
                    clearSoilUploadInfo();
                  }}
                >
                  {labels.ok}
                </ActionButton>
              </div>
            </ModalDialog>
          )}          
          {soilSamples.hasUploadError && soilSamples.uploadMsg?.status === CODE_409 ? (
            <div className="confirm-box-stye manure-change-warning">
              <ConfirmDialog
                title={intl.formatMessage({ id: 'SOIL_SAMPLE_WARNING_HEADER' })}
                message={soilSamples.uploadMsg.data}
                confirmText={labels.buttonOverwrite}
                onConfirm={() => this.warningAction(true)}
                cancelText={labels.buttonCancel}
                onCancel={() => this.warningAction(false)}
                warning
              />
            </div>
          ) : null}
          <ModalDialog
            show={selectDropdown}
            width={{ mobile: '300px', tablet: '380px', web: '380px' }}
            height={{ mobile: '220px', tablet: '300px', web: '300px' }}
            closeIcon=""
            onClose={() => {}}
          >
            <div>
              <div className="soil-sample-field">
                <Field
                  name="fileFormat"
                  label={intl.formatMessage({ id: 'SELECT_FILE_FORMAT' })}
                  controlId="fileFormat"
                  component={SelectControl}
                  styleName="select-box-common"
                  options={getSoilSampleDropdown()}
                  onChange={e => this.onChangeDropdown(e)}
                  placeholder=""
                />
              </div>
              <div className="soil-sample-button-style">
                {selectedFileFormatError && <ErrorText>{intl.formatMessage({ id: 'SELECT_FILE_FORMAT' })}</ErrorText>}
              </div>
              <div className={`${selectedFileFormatError ? 'soil-sample-button-style' : 'soil-sample-button-style-before'}`}>
                <span className="paddingRight">
                  <ActionButton onClick={() => this.onDrop()}>
                    {intl.formatMessage({ id: 'SOIL_SAMPLE_UPLOAD' })}
                  </ActionButton>
                </span>
                <span className="bale-list-cancel-button" onClick={() => this.closeSelectDropdown()}>
                  {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                </span>
              </div>
            </div>
          </ModalDialog>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  lblCommon: state.masterData.data[0]?.commonLabels,
  soilSamples: state.soilSamples,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  uploadSamples: (farmId, values, format, forceUpdate) =>
    dispatch(SoilSamplesAction.uploadSamples(farmId, values, format, forceUpdate)),
  clearSoilUploadInfo: () => dispatch(SoilSamplesAction.clearSoilUploadInfo())
});

export default injectIntl(
  reduxForm({
    form: 'SampleUploadForm'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SampleUpload)))
);
