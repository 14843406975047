import { FIELD_SOIL_SAMPLES_URL } from 'constants/ApiUrl';
import { 
  FIELD_SOIL_SAMPLES_GET_LOADING,
  FIELD_SOIL_SAMPLES_GET_SUCCESS,
  FIELD_SOIL_SAMPLES_GET_ERROR,
  FIELD_SOIL_SAMPLES_POST_LOADING,
  FIELD_SOIL_SAMPLES_POST_SUCCESS,
  FIELD_SOIL_SAMPLES_POST_ERROR,
  FIELD_SOIL_SAMPLES_PUT_LOADING,
  FIELD_SOIL_SAMPLES_PUT_SUCCESS,
  FIELD_SOIL_SAMPLES_PUT_ERROR,
  FIELD_SOIL_SAMPLES_DELETE_LOADING,
  FIELD_SOIL_SAMPLES_DELETE_SUCCESS,
  FIELD_SOIL_SAMPLES_DELETE_ERROR
} from 'constants/store/Fields';
import { GET, POST, PUT, DELETE } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Get the field soil samples
 */
export const getFieldSoilSamples = (farmId, fieldId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FIELD_SOIL_SAMPLES_GET_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FIELD_SOIL_SAMPLES_URL}${farmId}/fields/${fieldId}/soilparams?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: FIELD_SOIL_SAMPLES_GET_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: FIELD_SOIL_SAMPLES_GET_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: FIELD_SOIL_SAMPLES_GET_ERROR, payload: error });
  }
};

/**
 * Add the field soil samples 
 */
export const addFieldSoilSamples = (farmId, fieldId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: FIELD_SOIL_SAMPLES_POST_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FIELD_SOIL_SAMPLES_URL}${farmId}/fields/${fieldId}/soilparams?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: POST
      })
    )
    .then(response => {
      dispatch({ type: FIELD_SOIL_SAMPLES_POST_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: FIELD_SOIL_SAMPLES_POST_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: FIELD_SOIL_SAMPLES_POST_ERROR, payload: error });
  }
};

/**
 * Update the field soil samples 
 */
export const updateFieldSoilSamples = (farmId, fieldId, data, soilParamId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FIELD_SOIL_SAMPLES_PUT_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FIELD_SOIL_SAMPLES_URL}${farmId}/fields/${fieldId}/soilparams/${soilParamId}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: PUT
      })
    )
    .then(response => {
      dispatch({ type: FIELD_SOIL_SAMPLES_PUT_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: FIELD_SOIL_SAMPLES_PUT_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: FIELD_SOIL_SAMPLES_PUT_ERROR, payload: error });
  }
};

/**
 * Delete the field soil samples 
 */
export const deleteFieldSoilSamples = (farmId, fieldId, soilParamId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FIELD_SOIL_SAMPLES_DELETE_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FIELD_SOIL_SAMPLES_URL}${farmId}/fields/${fieldId}/soilparams/${soilParamId}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: DELETE
      })
    )
    .then(response => {
      dispatch({ type: FIELD_SOIL_SAMPLES_DELETE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: FIELD_SOIL_SAMPLES_DELETE_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: FIELD_SOIL_SAMPLES_DELETE_ERROR, payload: error });
  }
};