import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateTask from 'containers/tasks/tasklist/CreateTask';
import ManageTasks from 'containers/tasks/tasklist/ManageTasks';
import PageNotFound from 'components/PageNotFound';

const TaskList = () => (
  <Switch>
    <Route exact path="/tasks/tasklist/:id/:fieldId" component={CreateTask} />
    <Route exact path="/tasks/tasklist/:id" component={CreateTask} />
    <Route exact path="/tasks/tasklist/create" component={CreateTask} />
    <Route exact path="/tasks/tasklist" component={ManageTasks} />
    <Route exact path="/tasks" component={ManageTasks} />
    <Route component={PageNotFound} />
  </Switch>
);

export default TaskList;
