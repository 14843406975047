import {
  IS_STATS_LOADING,
  STATS_HEADER_ERROR,
  STATS_HEADER,
  IS_STATS_ACTIVITIES_LOADING,
  STATS_ACTIVITIES_ERROR,
  STATS_ACTIVITIES
} from 'constants/store/Stats';

const initialState = {
  activities: '',
  statsHeader: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_STATS_LOADING:
      return {
        ...state,
        statsHeader: { ...state.statsHeader, isLoading: action.payload, hasError: false }
      };
    case STATS_HEADER_ERROR:
      return {
        ...state,
        statsHeader: {
          data: null,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload
        }
      };
    case STATS_HEADER:
      return {
        ...state,
        statsHeader: {
          data: action.payload,
          isLoading: false,
          hasError: false,
          errorMessage: null
        }
      };
    case IS_STATS_ACTIVITIES_LOADING:
      return {
        ...state,
        activities: {
          ...state.activities,
          hasError: false,
          isLoading: true
        }
      };
    case STATS_ACTIVITIES_ERROR:
      return {
        ...state,
        activities: {
          data: null,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload
        }
      };
    case STATS_ACTIVITIES:
      return {
        ...state,
        activities: {
          data: action.payload,
          isLoading: false,
          hasError: false
        }
      };

    default:
      return state;
  }
};
