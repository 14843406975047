export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_ERROR = 'ADD_TASK_ERROR';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_ERROR = 'DELETE_TASK_ERROR';
export const CLEAR_TASK_MESSAGES = 'CLEAR_TASK_MESSAGES';
export const IS_TASK_LOADING = 'IS_TASK_LOADING';
export const GET_TASK_GPS = 'GET_TASK_GPS';
export const GET_TASK_GPS_ERROR = 'GET_TASK_GPS_ERROR';
export const GET_TASK_GPS_LOADING = 'GET_TASK_GPS_LOADING';
export const TASK_PERIOD_VALUE = 'TASK_PERIOD_VALUE';

export const SEASONS_LOADING = 'SEASONS_LOADING';
export const SEASONS_SUCCESS = 'SEASONS_SUCCESS';
export const SEASONS_FAILURE = 'SEASONS_FAILURE';

export const TASK_UPDATE_DURATION_LOADING = "TASK_UPDATE_DURATION_LOADING";
export const TASK_UPDATE_DURATION_SUCCESS = "TASK_UPDATE_DURATION_SUCCESS";
export const TASK_UPDATE_DURATION_FAILURE = "TASK_UPDATE_DURATION_FAILURE";
export const GET_TASK_LOADING = "GET_TASK_LOADING";
export const GET_TASK_SUCCESS_NULL = "GET_TASK_SUCCESS_NULL";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";

export const TASK_COMPLETED_LOADING = "TASK_COMPLETED_LOADING";
export const TASK_COMPLETED_SUCCESS = "TASK_COMPLETED_SUCCESS";
export const TASK_COMPLETED_FAILURE = "TASK_COMPLETED_FAILURE";

export const TASK_BATCH_LOADING = "TASK_BATCH_LOADING";
export const TASK_BATCH_SUCCESS = "TASK_BATCH_SUCCESS";
export const TASK_BATCH_FAILURE = "TASK_BATCH_FAILURE";