import { CROP_URL, PREVIOUS_CROP_URL } from 'constants/ApiUrl';
import { 
  CROP_LOADING, 
  CROP_SUCCESS, 
  CROP_ERROR,
  PREVIOUS_CROP_LOADING,
  PREVIOUS_CROP_SUCCESS, 
  PREVIOUS_CROP_ERROR
} from 'constants/store/Fields';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Get the field crops
 */
export const getFieldCrops = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CROP_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${CROP_URL}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: CROP_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: CROP_ERROR, payload: error });
      });
    } catch (error) {
      dispatch({ type: CROP_ERROR, payload: error });
    }
};

/**
 * Get the previous field crops
 */
export const getPreviousFieldCrops = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PREVIOUS_CROP_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${PREVIOUS_CROP_URL}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: PREVIOUS_CROP_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: PREVIOUS_CROP_ERROR, payload: error });
      });
    } catch (error) {
      dispatch({ type: PREVIOUS_CROP_ERROR, payload: error });
    }
};