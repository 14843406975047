/**
 * Loader Animation.
 * required @props { StyleName }
 */

import React from 'react';
import { CircularProgress } from '@mui/material'

const Loader = ({ styleName }) => (
  <div className={`${styleName}`}>
    <CircularProgress
      sx={{
        color: '#00696D', // Change to your desired color
        '&.MuiCircularProgress-colorPrimary': {
          color: '#00696D', // Change to your desired color for the primary variant
        },
        '&.MuiCircularProgress-circle': {
          strokeLinecap: 'round', // Example of customizing the circle
        },
        '&.MuiCircularProgress-thickness': {
          thickness: 20, // Change to your desired thickness
        },
      }}
    />
  </div>
);

export default Loader;
