import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorMessage from '@tine/lib-frontend-components/components/errors/error-message';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import { AlertIcon } from '@tine/lib-frontend-components/elements/icons';

import TextBoxControl from 'components/forms/TextBoxControl';
import { ValidateEmail } from 'utils/index';
import * as userActions from 'actions/Users';
import SelectControl from 'components/forms/SelectBoxControl';
import Loader from 'components/Loader';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';

class AddUser extends React.Component {
  componentDidMount() {
    const {
      initialize,
      getUserRoles
    } = this.props;
    const values = {
      emailId: ''
    };
    initialize(values);
      getUserRoles();
  }

  componentDidUpdate(prevProps) {
    const { history, activeFarm } = this.props;
    if (prevProps.activeFarm !== activeFarm) {
      history.push('/settings');
    }
  }

  /**
   * To extract a role based role chosen
   * @param {object} optionSelected
   * @returns {object}
   */
  extractRole = optionSelected => {
    const { farmUserDetails } = this.props;
    const { roles } = farmUserDetails.rolesList;
    return roles.filter(role => role.value === optionSelected.value)[0];
  };

  // submit handler on adding user
  handleSubmit = values => {
    const { addRole, activeFarm } = this.props;
    addRole(values, this.extractRole(values.role), activeFarm.farm.id);
  };

  // To get user roles with translation
  getRoles = () => {
    const { farmUserDetails } = this.props;
    const {
      rolesList: { roles }
    } = farmUserDetails;
    if (roles) {
      return roles.map(item => ({
        value: item.value,
        label: item.label
      }));
    }
    return [];
  };

  render() {
    const { handleSubmit, farmUserDetails, activeFarm, intl, hasSupportAdminRole } = this.props;
    const { isLoading, hasError, errorMsg } = farmUserDetails;
    if (!(activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data)) {
      return (
        <ErrorMessage
          errorHeader={<FormattedMessage id="SORRY" />}
          message={<FormattedMessage id="INVITE_USER_NO_PERMISSION" />}
          icon={
            <span style={{ transform: 'rotateX(180deg)' }}>
              <AlertIcon scale={1.5} />
            </span>
          }
        />
      );
    }
    return (
      <div className="add-user-wrapper">
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Field
            name="emailId"
            label={intl.formatMessage({ id: 'IV_EMAIL' })}
            component={TextBoxControl}
            controlId="emailId"
            type="text"
          />
          <Field
            name="role"
            controlId="role"
            label={intl.formatMessage({ id: 'MU_CR' })}
            disabled={false}
            styleName="select-box-common"
            component={SelectControl}
            placeholder=""
            options={this.getRoles()}
          />
          {isLoading ? <Loader styleName="add-user-loader" /> : null}
          {hasError && (
            <ErrorText>
              {intl.formatMessage({ id: errorMsg.status === 409 ? 'UAE' : 'COMMON_ERR_MSG' })}
            </ErrorText>
          )}
          <div className="add-user-footer">
            <FormattedMessage id="MU_SI">
              {MU_SI => (
                <ActionButton
                  secondary
                  disabled={isLoading}
                  onClick={handleSubmit(this.handleSubmit)}
                >
                  {MU_SI}
                </ActionButton>
              )}
            </FormattedMessage>
          </div>
        </form>
        {farmUserDetails.isSuccess ? <Redirect to="/settings" /> : null}
      </div>
    );
  }
}

function validate(values, props) {
  const { intl } = props;
  const errors = {};
  if (!values.emailId || !values.emailId.trim()) {
    errors.emailId = intl.formatMessage({ id: 'L_PEVE' });
  }
  if (!ValidateEmail(values.emailId)) {
    errors.emailId = intl.formatMessage({ id: 'L_PEVE' });
  }
  if (!values.role) {
    errors.role = intl.formatMessage({ id: 'required' });
  }
  return errors;
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  farmUserDetails: state.farmUserDetails,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDispatchToProps(dispatch) {
  return {
    addRole: (obj, role, farmId) => dispatch(userActions.addRole(obj, role, farmId)),
    getUserRoles: () => dispatch(userActions.getUserRoles())
  };
}

export default injectIntl(
  reduxForm({
    form: 'AddUser',
    validate
  })(connect(mapStateToProps, mapDispatchToProps)(AddUser))
);
