import { YIELD_PLAN_URL } from 'constants/ApiUrl';
import { 
  YIELD_CALCULATION_LOADING, 
  YIELD_CALCULATION_SUCCESS, 
  YIELD_CALCULATION_ERROR
} from 'constants/store/Fields';
import { POST } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Calculate the Yield Calculation
 */
export const YieldCalculation = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: YIELD_CALCULATION_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${YIELD_PLAN_URL}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: POST
      })
    )
      .then(response => {
        dispatch({ type: YIELD_CALCULATION_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: YIELD_CALCULATION_SUCCESS, payload: error });
      });
    } catch (error) {
      dispatch({ type: YIELD_CALCULATION_ERROR, payload: error });
    }
};