import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ToastMessage from '@tine/lib-frontend-components/components/notification/toast-message';
import { SHOW_NETWORK_ERROR } from 'constants/index';

const NetworkMessage = ({ networkStatus, intl }) => (
  <div
    className={`newtork-message ${
      networkStatus.isOnline !== null && networkStatus.show && !networkStatus.isOnline
        ? 'show-icon'
        : ''
    }`}
  >
    {networkStatus.isOnline !== null && !networkStatus.isOnline && networkStatus.show ? (
      <ToastMessage
        alert
        title={intl.formatMessage({ id: 'NETWORK_ERR_HEADING' })}
        message={intl.formatMessage({ id: 'NETWORK_ERR_BODY' })}
        showIcon
        onClick={() => {}}
      />
    ) : null}
  </div>
);
const mapStateToProps = state => ({
  networkStatus: state.networkStatus
});

const mapDispatchToProps = dispatch => ({
  checkNetworkStatus: () => dispatch({ type: SHOW_NETWORK_ERROR, show: false })
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NetworkMessage));
