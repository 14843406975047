import React from 'react';

class PageNotFound extends React.Component {
  goHome = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    return (
      <div className="notFoundWrapper">
        <h1>404</h1>
        <h4>We couldn&apos;t find the page!</h4>
        <button className="go-back-button" type="button" onClick={() => this.goHome()}>
          Go Back Home
        </button>
      </div>
    );
  }
}

export default PageNotFound;
