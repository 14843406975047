import {
  FIELD_SOIL_SAMPLES_GET_LOADING,
  FIELD_SOIL_SAMPLES_GET_SUCCESS,
  FIELD_SOIL_SAMPLES_GET_ERROR,
  FIELD_SOIL_SAMPLES_POST_LOADING,
  FIELD_SOIL_SAMPLES_POST_SUCCESS,
  FIELD_SOIL_SAMPLES_POST_ERROR,
  FIELD_SOIL_SAMPLES_PUT_LOADING,
  FIELD_SOIL_SAMPLES_PUT_SUCCESS,
  FIELD_SOIL_SAMPLES_PUT_ERROR,
  FIELD_SOIL_SAMPLES_DELETE_LOADING,
  FIELD_SOIL_SAMPLES_DELETE_SUCCESS,
  FIELD_SOIL_SAMPLES_DELETE_ERROR
} from 'constants/store/Fields';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  isSuccess: false,
  getFieldSoilSampleData: null,
  addFieldSoilSampleData: null,
  updateFieldSoilSampleData: null,
  deleteFieldSoilSampleData: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FIELD_SOIL_SAMPLES_GET_LOADING:
    case FIELD_SOIL_SAMPLES_POST_LOADING:  
    case FIELD_SOIL_SAMPLES_PUT_LOADING:
    case FIELD_SOIL_SAMPLES_DELETE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
        errorMessage: null
      };
    case FIELD_SOIL_SAMPLES_GET_ERROR:
      return {
        ...state,
        hasError: true,
        isSuccess: false,
        errorMessage: action.payload,
        isLoading: false
      };
    case FIELD_SOIL_SAMPLES_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        isSuccess: false,
        getFieldSoilSampleData: action.payload
      };
    case FIELD_SOIL_SAMPLES_POST_ERROR:
      return {
        ...state,
        hasError: true,
        isSuccess: false,
        errorMessage: action.payload,
        isLoading: false
      };
    case FIELD_SOIL_SAMPLES_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        errorMessage: null,
        hasError: false,
        addFieldSoilSampleData: action.payload
      };
    case FIELD_SOIL_SAMPLES_PUT_ERROR:
      return {
        ...state,
        hasError: true,
        isSuccess: false,
        errorMessage: action.payload,
        isLoading: false
      };
    case FIELD_SOIL_SAMPLES_PUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        isSuccess: true,
        hasError: false,
        updateFieldSoilSampleData: action.payload
      };
    case FIELD_SOIL_SAMPLES_DELETE_ERROR:
      return {
        ...state,
        hasError: true,
        isSuccess: false,
        errorMessage: action.payload,
        isLoading: false
      };
    case FIELD_SOIL_SAMPLES_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        errorMessage: null,
        hasError: false,
        deleteFieldSoilSampleData: action.payload
      };
    default:
      return state;
  }
}
