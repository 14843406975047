import {
  GET_SOUND_FILES_IS_LOADING,
  GET_SOUND_FILES_SUCCESS,
  GET_SOUND_FILES_FAILURE
} from 'constants/index';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null
};

export default function profileImageReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_SOUND_FILES_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
        errorMessage: null
      };
    case GET_SOUND_FILES_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case GET_SOUND_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.data
      };
    default:
      return state;
  }
}
