import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import TextBoxControl from 'components/forms/TextBoxControl';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import SelectControl from 'components/forms/SelectBoxControl';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import { getDropDownListValues, getDropDownValuesForYear } from 'utils/index';
import * as FieldActions from 'actions/Fields';
import Loader from 'components/Loader';
import mixpanel from 'mixpanel-browser';
import { SUB_BALE, SUB_BALE_COMPLETE } from 'constants/MixPanel';
import _ from 'lodash';

class SubBaleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteBales: false,
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: false,
      displayHarvest2: false,
      displayHarvest3: false,
      displayHarvest4: false,
      selectedBaleName: null,
      harvestFieldValues: [],
      selectedStorageId: null,
      quantityPlaceHolder: "",
      quantityMismatchError: false,
      showHarvestFieldDrop: false,
      noHarvestError: false,
      noSortFieldError: false,
      baleStorageError: false,
      noQuantityError: false,
      selectedYear: null,
      selectedYearDropdown: null,
      changedDisplayHarvest: false,
      selectedStorageList: [],
      userSelectedStorage: null,
    };
  }

  componentDidMount = () => {
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, getFeedEventListData } = this.props;
    const { showDeleteBales, changedDisplayHarvest } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storage');
    }
    if(showDeleteBales && !changedDisplayHarvest && getFeedEventListData.data && getFeedEventListData.data.length > 0) {
      this.fieldsChangeInitialize(null);
      this.setState({
        changedDisplayHarvest: true
      });
    }
  }

  fieldsChangeInitialize(yearSelected) {
    const { getFeedEventListData } = this.props;
    this.setState({
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: false,
      displayHarvest2: false,
      displayHarvest3: false,
      displayHarvest4: false
    });
    let yearData = [];
    let storageKeyPair = {};
    let storageDataKeyPair = [];
    getFeedEventListData.data.map(balesList => (
      //eslint-disable-next-line
      yearData.includes(balesList.year) ? '' : yearData.push(balesList.year)
    ));
    let selectedYear = _.filter(getFeedEventListData.data, ['year', yearSelected !== null ? yearSelected : yearData[0]])[0];
    selectedYear.balesByLocation.map(balesStorage => (
      //eslint-disable-next-line
      storageKeyPair = {},
      storageKeyPair['id'] = balesStorage.id,
      storageKeyPair['name'] = balesStorage.name,
      storageDataKeyPair.push(storageKeyPair)
    ));
    this.setState({
      selectedYear: yearData,
      selectedStorageList: storageDataKeyPair
    });
    if(yearSelected === null) {
      this.setState({
        selectedYearDropdown: { "label": yearData[0], "value": yearData[0]}
      });
    }
    selectedYear.balesByLocation.map(balesStorage => (
      balesStorage && balesStorage.balesByHarvest.map(getTaskHarvest => (
        //eslint-disable-next-line
        getTaskHarvest.harvest === 1 ? this.setState({ displayHarvest1: true }) : '',
        getTaskHarvest.harvest === 2 ? this.setState({ displayHarvest2: true }) : '',
        getTaskHarvest.harvest === 3 ? this.setState({ displayHarvest3: true }) : '',
        getTaskHarvest.harvest === 4 ? this.setState({ displayHarvest4: true }) : ''
      ))
    ))
  }

  onChangeYear = e => {
    this.getAddInitialize();
    this.setState({
      selectedYearDropdown: e,
      quantityPlaceHolder: ""
    });
    this.fieldsChangeInitialize(e.value);
  }

  handleSubmit = values => {
    const { showHarvest1, showHarvest2, showHarvest3, showHarvest4, showHarvestFieldDrop, quantityPlaceHolder, selectedYearDropdown } = this.state;
    const { activeFarm, deleteFeedEvent, userDetails } = this.props;
    this.setState({
      noHarvestError: false,
      noSortFieldError: false,
      quantityMismatchError: false,
      baleStorageError: false,
      noQuantityError: false
    });
    if (!values.baleName) {
      this.setState({
        baleStorageError: true
      });
      return;
    }
    if (values.baleName && !values.baleName.value) {
      this.setState({
        baleStorageError: true
      });
      return;
    }
    if(!showHarvest1 && !showHarvest2 && !showHarvest3 && !showHarvest4) {
      this.setState({
        noHarvestError: true
      });
      return;
    }
    let baleQuan = values.baleQuantity ? values.baleQuantity.replace(',','.') : '';
    if (!baleQuan || !baleQuan.trim()) {
      this.setState({
        noQuantityError: true
      });
      return;
    }
    if (baleQuan && isNaN(baleQuan)) {
      this.setState({
        noQuantityError: true
      });
      return;
    }
    if (baleQuan && parseFloat(baleQuan) <= 0) {
      this.setState({
        noQuantityError: true
      });
      return;
    }      
    if(parseFloat(baleQuan) > parseFloat(quantityPlaceHolder)) {
      this.setState({
        quantityMismatchError: true
      });
      return;
    }

    let baleHarvest = 1;
    if(showHarvest1) { baleHarvest = 1; }
    if(showHarvest2) { baleHarvest = 2; }
    if(showHarvest3) { baleHarvest = 3; }
    if(showHarvest4) { baleHarvest = 4; }

    let formData = {};
    formData["farmId"] = activeFarm.farm.id;
    formData["harvest"] = baleHarvest;
    formData["storageId"] = values.baleName.value;
    if(values.baleFieldName && values.baleFieldName.value && showHarvestFieldDrop) {
      formData["fieldId"] = values.baleFieldName.value;
    }
    formData["quantity"] = baleQuan;
    formData["year"] = selectedYearDropdown.value;
    mixpanel.identify(userDetails.id);
    mixpanel.track(SUB_BALE_COMPLETE);
    deleteFeedEvent(activeFarm.farm.id, formData);
  }

  openShowDeleteBales = () => {
    const { userDetails, activeFarm, getFeedEventList } = this.props;
    const { showDeleteBales } = this.state;
    if(!showDeleteBales) {
      getFeedEventList(activeFarm.farm.id);
      mixpanel.identify(userDetails.id);
      mixpanel.track(SUB_BALE);
      this.setState({
        showDeleteBales: true,
        changedDisplayHarvest: false
      });
    }
  }

  closeShowDeleteBales = () => {
    this.getAddInitialize();
    this.setState({
      showDeleteBales: false
    });
  }

  getAddInitialize = () => {
    const { initialize } = this.props;
    const formValues = {
      baleName: { label: '', value: '' },
      baleFieldName: { label: '', value: '' },
      baleQuantity: ''
    };
    this.setState({
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: true,
      displayHarvest2: true,
      displayHarvest3: true,
      displayHarvest4: true,
      selectedStorageId: null,
      harvestFieldValues: [],
      noHarvestError: false,
      noSortFieldError: false,
      quantityMismatchError: false,
      baleStorageError: false,
      noQuantityError: false,
      noAnalysisParameter: false,
      quantityPlaceHolder: "",
      showHarvestFieldDrop: false
    })
    initialize(formValues);
  };

  onChangeSelectedHarvest = e => {
    const { getFeedEventListData } = this.props;
    const { selectedYearDropdown } = this.state;
    this.setState({
      selectedStorageId: e.value,
      showHarvestFieldDrop: false,
      baleStorageError: false,
      selectedBaleName: e,
    });
    let selectedYear = _.filter(getFeedEventListData.data, ['year', selectedYearDropdown.value])[0];
    if(e.value) {
      let selectedStorage = _.filter(selectedYear.balesByLocation, ['id', e.value])[0];
      const quantity = selectedStorage.quantity.toString();
      this.setState({
        quantityPlaceHolder: quantity,
        userSelectedStorage: e
      });
      this.setState({
        showHarvest1: false,
        showHarvest2: false,
        showHarvest3: false,
        showHarvest4: false,
        displayHarvest1: false,
        displayHarvest2: false,
        displayHarvest3: false,
        displayHarvest4: false
      });
      let data = [];
      let keyPair = {};
      selectedStorage && selectedStorage.balesByHarvest && selectedStorage.balesByHarvest.map(baleHarvest => (
        //eslint-disable-next-line
        baleHarvest.harvest === 1 ? this.setState({ displayHarvest1: true }) : '',
        baleHarvest.harvest === 2 ? this.setState({ displayHarvest2: true }) : '',
        baleHarvest.harvest === 3 ? this.setState({ displayHarvest3: true }) : '',
        baleHarvest.harvest === 4 ? this.setState({ displayHarvest4: true }) : '',
        baleHarvest && baleHarvest.balesByField && baleHarvest.balesByField.map(balesField => (
          //eslint-disable-next-line
          keyPair = {},        
          keyPair['id'] = balesField.id,
          keyPair['name'] = balesField.name,
          data.push(keyPair)
        ))
      ));
      if(selectedStorage.type === "EXTERNAL" && selectedStorage.sortByField) {
        this.setState({
          showHarvestFieldDrop: true
        });
      }
      this.setState({
        harvestFieldValues: data
      });
    } else {
      this.setState({
        showHarvest1: false,
        showHarvest2: false,
        showHarvest3: false,
        showHarvest4: false,
        displayHarvest1: false,
        displayHarvest2: false,
        displayHarvest3: false,
        displayHarvest4: false,
        userSelectedStorage: null
      });
      selectedYear.balesByLocation.map(balesStorage => (
        balesStorage && balesStorage.balesByHarvest.map(getTaskHarvest => (
          //eslint-disable-next-line
          getTaskHarvest.harvest === 1 ? this.setState({ displayHarvest1: true }) : '',
          getTaskHarvest.harvest === 2 ? this.setState({ displayHarvest2: true }) : '',
          getTaskHarvest.harvest === 3 ? this.setState({ displayHarvest3: true }) : '',
          getTaskHarvest.harvest === 4 ? this.setState({ displayHarvest4: true }) : ''
        ))
      ))
    }
  }

  onSelectHarvest = (harvest) => {
    const { getFeedEventListData } = this.props;
    const { selectedYearDropdown, userSelectedStorage } = this.state;
    this.setState({
      noHarvestError: false
    });
    if(userSelectedStorage === null) {
      this.setState({
        noHarvestError: true
      });
      return;
    }
    let selectedYear = _.filter(getFeedEventListData.data, ['year', selectedYearDropdown.value])[0];
    let selectedStorage = _.filter(selectedYear.balesByLocation, ['id', userSelectedStorage.value])[0];
    let selectedHarvest = _.filter(selectedStorage.balesByHarvest, ['harvest', harvest])[0];
    let data = [];
    let keyPair = {};
    selectedHarvest && selectedHarvest.balesByField.map(balesField => (
      //eslint-disable-next-line
      keyPair = {},        
      keyPair['id'] = balesField.id,
      keyPair['name'] = balesField.name,
      data.push(keyPair)
    ))
    const quantity = selectedHarvest.quantity.toString();
    const { initialize } = this.props;
    const formValues = {
      year: selectedYearDropdown,
      baleName: userSelectedStorage,
      baleFieldName: { label: '', value: '' }
    };
    initialize(formValues);
    this.setState({
      harvestFieldValues: data,
      quantityPlaceHolder: quantity
    });
    this.setState({
      showHarvest1: harvest === 1 ? true : false,
      showHarvest2: harvest === 2 ? true : false,
      showHarvest3: harvest === 3 ? true : false,
      showHarvest4: harvest === 4 ? true : false
    });
  }

  onChangeSelectedField = e => {
    const { getFeedEventListData } = this.props;
    const { showHarvest1, showHarvest2, showHarvest3, showHarvest4, selectedYearDropdown, userSelectedStorage } = this.state;
    let selectedHarvest = 1;
    if(showHarvest1) {
      selectedHarvest = 1;
    } else if(showHarvest2) {
      selectedHarvest = 2;
    } else if(showHarvest3) {
      selectedHarvest = 3;
    } else if(showHarvest4) {
      selectedHarvest = 4;
    }

    let selectedYear = _.filter(getFeedEventListData.data, ['year', selectedYearDropdown.value])[0];
    let selectedStorage = _.filter(selectedYear.balesByLocation, ['id', userSelectedStorage.value])[0];
    let selectedHarvestValue = _.filter(selectedStorage.balesByHarvest, ['harvest', selectedHarvest])[0];
    if(e.value) {
      this.setState({
        noSortFieldError: false
      });      
      let selectedField = _.filter(selectedHarvestValue.balesByField, ['id', e.value])[0];
      const quantity = selectedField.quantity.toString();
      this.setState({
        quantityPlaceHolder: quantity
      });
    } else {
      const quantity = selectedHarvestValue.quantity.toString();
      this.setState({
        quantityPlaceHolder: quantity
      });
    }
  }
  
  render() {
    const { intl, getFeedEventListData, locations, getAnalysisStorageListData, handleSubmit } = this.props;
    const { showDeleteBales, showHarvest1, showHarvest2, showHarvest3, showHarvest4, displayHarvest1, displayHarvest2,
      displayHarvest3, displayHarvest4, harvestFieldValues, noHarvestError, noSortFieldError, baleStorageError, 
      showHarvestFieldDrop, noQuantityError, quantityMismatchError, quantityPlaceHolder, selectedYear, selectedYearDropdown,
      changedDisplayHarvest, selectedStorageList } = this.state;
    return (
      <div className={`add-harvest ${getAnalysisStorageListData && getAnalysisStorageListData.length <= 0 ? 'remove-cursor' : ''}`} 
        onClick={() => { getAnalysisStorageListData && getAnalysisStorageListData.length > 0 && this.openShowDeleteBales(); }}>
        <span className="pad-right">{intl.formatMessage({ id: 'BALE_OVERVIEW_SUBTRACT_BALES' })}</span>
        <div className="cross-icon">
          <img src={`./images/bale_remove.png`} className="bale-icon-size" alt="draw" />
        </div>
        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '25%'}}
          width={{mobile: '90%', tablet: '55%', web: '25%'}}
          height={{mobile: '90%', tablet: '75%', web: '75%'}}
          show={showDeleteBales}
          closeIcon=""
          onClose={() => {}}>
          {getFeedEventListData && getFeedEventListData.isLoading && <Loader styleName="fixed-loader" />}
          {getFeedEventListData.data && getFeedEventListData.data.length > 0 && changedDisplayHarvest && <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div>
              <div className='text-align-center'>
                <span className='fertilization-header'>{intl.formatMessage({ id: 'BALE_OVERVIEW_SUBTRACT_BALES' })}</span>
              </div>
              <div>
                <div className='bale-storage-form-style'>
                  <div>
                    <Field
                      name="year"
                      label={intl.formatMessage({ id: 'CHOOSE_YEAR_BALE_SUBTRACT' })}
                      controlId="year"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownValuesForYear(selectedYear)}
                      defaultValue={selectedYearDropdown}
                      onChange={e => this.onChangeYear(e)}
                      placeholder=""
                    />
                  </div>
                  <div className="bale-option">
                    {locations && <Field
                      name="baleName"
                      label={intl.formatMessage({ id: 'ADD_BALE_NAME_DROPDOWN' })}
                      controlId="baleName"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownListValues(selectedStorageList, false)}
                      onChange={e => this.onChangeSelectedHarvest(e)}
                      placeholder=""
                    />}
                  </div>
                  <div className="bale-option">
                    <div className="padding-bottom-div">
                      <EmphasisLight size="small">{intl.formatMessage({ id: 'ADD_BALE_HARVEST' })}</EmphasisLight>
                    </div>
                    <div>
                      <span className={`number-padding ${displayHarvest1 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest1 && this.onSelectHarvest(1); }}>
                        {showHarvest1 ?
                          <img src={`./images/h1_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest1 ?
                          <img src={`./images/h1.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h1_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest2 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest2 && this.onSelectHarvest(2); }}>
                        {showHarvest2 ?
                          <img src={`./images/h2_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest2 ?
                          <img src={`./images/h2.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h2_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest3 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest3 && this.onSelectHarvest(3); }}>
                        {showHarvest3 ?
                          <img src={`./images/h3_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest3 ?
                          <img src={`./images/h3.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h3_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest4 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest4 && this.onSelectHarvest(4); }}>
                        {showHarvest4 ?
                          <img src={`./images/h4_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest4 ?
                          <img src={`./images/h4.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h4_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                    </div>
                  </div>
                  {showDeleteBales && showHarvestFieldDrop && <div className="padding-top-div-large">
                  <Field
                    name="baleFieldName"
                    label={intl.formatMessage({ id: 'ADD_BALE_FIELDS' })}
                    controlId="baleFieldName"
                    component={SelectControl}
                    styleName="select-box-common"
                    options={getDropDownListValues(harvestFieldValues, true)}
                    onChange={e => this.onChangeSelectedField(e)}
                    placeholder=""
                  />
                  </div>}
                  <div className="padding-top-div-medium full-width">
                    <Field
                      name="baleQuantity"
                      label={intl.formatMessage({ id: 'ADD_BALE_QUANTITY' })}
                      component={TextBoxControl}
                      controlId="baleQuantity"
                      type="text"
                      units={`${intl.formatMessage({ id: 'ADD_BALE_QUANTITY_UNITS' })} ${quantityPlaceHolder ? '('+quantityPlaceHolder+')' : ''}`}
                    />
                  </div>
                  <div className="lightbox-field-padding-top-large">
                    <span className="paddingRight">
                      {baleStorageError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_1' })}</ErrorText>}
                      {noHarvestError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_2' })}</ErrorText>}
                      {noSortFieldError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_3' })}</ErrorText>}
                      {noQuantityError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_4' })}</ErrorText>}
                      {quantityMismatchError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_5' })}</ErrorText>}
                      {getFeedEventListData.saveSuccess && window.location.reload()}
                    </span>
                  </div>
                  <div className="bale-button-style">
                    <span className="paddingRight">
                      <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                        {intl.formatMessage({ id: 'SUB_BALES_SAVE_BUTTON' })}
                      </ActionButton>
                    </span>
                    <span className="bale-list-cancel-button" onClick={() => this.closeShowDeleteBales()}>
                      {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>}
          {((getFeedEventListData.data && getFeedEventListData.data.length <= 0) || !getFeedEventListData.data) && !getFeedEventListData.isLoading && 
          <div>
            <div className='text-align-center'>
              <Emphasis size="extralarge">{intl.formatMessage({ id: 'BALE_OVERVIEW_SUBTRACT_BALES' })}</Emphasis>
            </div>
            <div>
              <div className='main-bale-storage-form-style padding-top-div-extra-large'>
                <div className="align-center-span">
                  <Emphasis size="normal">{intl.formatMessage({ id: 'NO_DATA_FOUND' })}</Emphasis>
                </div>
              </div>
              <div className="padding-top-div-extra-large">
                <div className="bale-button-style">
                  <span className="bale-list-cancel-button" onClick={() => this.closeShowDeleteBales()}>
                    {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                  </span>
                </div>
              </div>
            </div>
          </div>}
        </ModalDialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  locations: state.baleFeedEvent.locationData,
  getFeedEventListData: state.baleFeedEvent,
  fieldList: state.fieldDetails.data,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
  addFeedEvent: (farmId, data) => dispatch(baleFeedEventActions.addFeedEvent(farmId, data)),
  getFeedEventList: (farmId) => dispatch(baleFeedEventActions.getFeedEventList(farmId)),
  fetchFieldList: (farmId) => dispatch(FieldActions.fetchFieldList(farmId)),
  deleteFeedEvent: (farmId, data) => dispatch(baleFeedEventActions.deleteFeedEvent(farmId, data))
});

export default injectIntl(
  reduxForm({
    form: 'SubBaleModalForm'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SubBaleModal)))
);
