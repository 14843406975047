import * as React from 'react';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';
import NavigationButton from '@tine/lib-frontend-components/components/buttons/navigation-button';

import { formatDate } from 'utils/index';

export default ({ labels, values, hasPermission }) => (
  <SectionContainer
    styling={{
      paddingBottom: 'lowest',
      paddingTop: 'lowest'
    }}
  >
    <div className="manure-item_header">
      <EmphasisLight size="normal">{values.name}</EmphasisLight>
      <div className="manure-item_link">
        <MetaInformation size="small">
          {labels.created}: {formatDate(new Date(values.createdTime))}
        </MetaInformation>
        <NavigationButton
          disabled={!hasPermission}
          tertiary
          route={`/storage/manure-storage/${values.id}`}
        >
          <>
            {labels.edit}
            <i className="icon-edit" />
          </>
        </NavigationButton>
      </div>
    </div>
  </SectionContainer>
);
