import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Stack, Box, Chip, Grid, Typography, Menu, MenuItem } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import * as fieldSoilSampleActions from 'actions/FieldSoilSamples';
import { getSoilTypes, getMoldClass } from 'utils/index';
import { isMobile } from 'react-device-detect';

class SoilSamplesListCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showDelete: false,
      anchorEl: false,
      selectedId: null,
      openItems: []
    };
  }
 
  componentDidMount = () => {
    const newOpenItems = [...this.state.openItems];
    newOpenItems[0] = true;
    this.setState({ openItems: newOpenItems });
  };

  handleListClick = (event, index) => {
    event.preventDefault();
    //this.setState({ [`open${index+1}`]: !this.state[`open${index+1}`] });
    const newOpenItems = [...this.state.openItems];
    // Toggle the state for the clicked item
    newOpenItems[index] = !newOpenItems[index];
    this.setState({ openItems: newOpenItems });
  };

  addNewSoilSample = (event, soilId) => {
    const { newParamId } = this.props;
    event.preventDefault();
    window.location.href=`/#/managefields/${newParamId}/soilsamples/${soilId}`;
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (event) => {
    if (this.state.anchorEl && !this.state.anchorEl.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  confirmDelete(event,selectedId) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
    this.setState({
      showDelete: true,
      selectedId: selectedId
    })
  }

  showDialog = action => {
    const { deleteSoilSample, activeFarm, newParamId } = this.props;
    const { selectedId } = this.state;
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
    if (action) {
      deleteSoilSample(activeFarm.farm.id, newParamId, selectedId);
    }
    this.setState({ showDelete: false });
  };

  render() {
    const { soilSamples, key, intl, index } = this.props;
    const { showDelete } = this.state;
    const titleCss = { color: '#191C1C', fontWeight: '400', fontSize: '16px', fontFamily: 'Inter' };
    const subTitleCss = { ...titleCss, color: '#3F4949', fontSize: '14px', paddingTop: '11px' };
    const subTitlePaddingCss = { ...subTitleCss, paddingTop: '3px' };
    const subHeadingTextCss = { color: '#191C1C', fontFamily:'Inter', fontWeight: '500px', fontSize: '12px' };
    const subTextCss = { ...subHeadingTextCss, fontSize: '16px' };
    const menuItemStyle = {
      backgroundColor: 'transparent',
      padding: '8px 16px',
      fontFamily: 'Inter',
      fontSize: '14px'
    }
    let soilTypeName = '';
    let moldClassName = '';
    if(soilSamples.soilType !== 0 || soilSamples.soilType !== '') {
      const soilType = getSoilTypes().filter(option => {
        return (option.value === soilSamples.soilType);
      });
      if(soilType !== '' && soilType !== undefined  && soilType.length > 0) {
        soilTypeName = soilType[0].label;
      }
    }
    if(soilSamples.moldClass !== 0 || soilSamples.moldClass !== '') {
      const moldClass = getMoldClass().filter(option => {
        return (option.value === soilSamples.moldClass);
      });
      if(moldClass !== '' && moldClass !== undefined && moldClass.length > 0) {
        moldClassName = moldClass[0].label;
      }
    }

    return (
      <Box sx={{ width: '100%', flexGrow: 1, paddingBottom: '15px' }}>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper', border: 1, borderRadius: '5px', borderColor: '#dddddd', paddingTop: '0', paddingBottom: '0' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton disableRipple onClick={(e) => this.handleListClick(e, index)}  key={key} 
            sx={{ bgcolor: '#f1f1f1', borderBottom: 1, borderColor: '#dddddd', borderTopRadius: '5px' }}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6}>
                <Typography variant="body2" style={subTitleCss}>Opprettet: {soilSamples.createdDate === null ? '' : (soilSamples.createdDate).replace(/-/g, '.')}</Typography>
              </Grid>
              <Grid item xs={12} md={12} sx={{ paddingTop: '8px' }}>
                <Stack direction="row" justifyContent="start">
                  <Box component='span' className={isMobile ? `grid-padding-dot-mobile` : `grid-padding-dot`}>
                    <Typography variant="body2" style={subTitlePaddingCss}>Sist redigert: {soilSamples.lastUpdatedDate === null ? '' : (soilSamples.lastUpdatedDate).replace(/-/g, '.')}</Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingTop: '8px' }}>
                <Stack direction="row" justifyContent="space-between">
                  <Chip
                    icon={<CreateOutlinedIcon fontSize='small' />}
                    label={<FormattedMessage id="EDIT" />}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={(e) => this.addNewSoilSample(e, soilSamples.id)}
                    sx ={{color: '#cc0000', borderColor: '#cc0000'}}
                    className='grid-padding'
                  />
                  <Box component='span' className='grid-padding-dot'>
                    <MoreVertIcon onClick={(e) => { e.stopPropagation(); this.handleClick(e); }}  sx={{ cursor: 'pointer' }} />
                    <Menu
                      id="basic-menu"
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={(e)=>this.confirmDelete(e,soilSamples.id)} style={menuItemStyle}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
                        }}
                      >
                        {intl.formatMessage({ id: 'SOIL_SAMPLES_DELETE_HEADING' })}
                      </MenuItem>
                    </Menu>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            {this.state.openItems[index] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={this.state.openItems[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ paddingTop: '20px', paddingBottom: '20px'}}>
              <ListItemButton disableRipple sx={{ cursor: 'default', '&:hover': { bgcolor: 'transparent' } }}>
                <Grid container justifyContent="flex-start" sx={{ flexWrap: 'wrap' }}>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="YEAR" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{soilSamples.year}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="FIELD_SOIL_TYPE" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{soilTypeName}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="FIELD_MOLD_CLASS" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{moldClassName}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="FIELD_PHOSPHORUS" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{(soilSamples.phosphorus !== 0 && soilSamples.phosphorus !== null) ? soilSamples.phosphorus : ''} mg/100g</Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
              <ListItemButton disableRipple sx={{ paddingTop: '30px', cursor: 'default', '&:hover': { bgcolor: 'transparent' } }}>
                <Grid container justifyContent="flex-start" sx={{ flexWrap: 'wrap' }}>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="FIELD_POTASSIUM" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{(soilSamples.potassium !== 0 && soilSamples.potassium !== null) ? soilSamples.potassium : ''} mg/100g</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="FIELD_ACID_SOLUBLE" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{(soilSamples.acidSolublePotassium !== 0 && soilSamples.acidSolublePotassium !== null) ? soilSamples.acidSolublePotassium : ''} mg/100g</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="PH_VALUE" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{(soilSamples.pH !==0 && soilSamples.pH !== null) ? soilSamples.pH : ''}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingRight: '2.5%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="FIELD_SOURCE" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>{(soilSamples.source !==0 && soilSamples.source !== null) ? soilSamples.source : ''}</Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
        {showDelete && (
          <ConfirmDialogBox
            heading={`${intl.formatMessage({ id: 'SOIL_SAMPLES_DELETE_HEADING' })}?`}
            body={intl.formatMessage({ id: 'SOIL_SAMPLES_DELETE_MESSAGE' })}
            confirmText="DELETE"
            cancelText="CANCEL"
            callback={action => this.showDialog(action)}
            warning
          />
        )}
      </Box>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
});

const mapDispatchToProps = dispatch => ({
  deleteSoilSample: (farmId, fieldId, soilId) => dispatch(fieldSoilSampleActions.deleteFieldSoilSamples(farmId, fieldId, soilId))
})

export default reduxForm({
  form: 'SoilSamplesListCardForm'
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SoilSamplesListCard)));
 