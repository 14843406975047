import React from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import RadioButton from '@tine/lib-frontend-components/components/inputs/radio-button';
import TextBoxControl from 'components/forms/TextBoxControl';
import ToggleButton from 'react-toggle-button';

class WaterJournalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id
    };
  }

  componentDidMount() {
    const { editTaskDetails } = this.props;
    const { paramId } = this.state;
    if (editTaskDetails && paramId !== 'create') {
      /*const { isGetTaskData } = editTaskDetails;
      if (isGetTaskData && isGetTaskData.dynamicData) {
        const dynamicDataObj = JSON.parse(isGetTaskData.dynamicData);
        if (dynamicDataObj.pestiside_type && dynamicDataObj.pestiside_type.length > 0) {
          let chosenPestAmountArr = [];
          chosenPestAmountArr = dynamicDataObj.pestiside_type.map((item, index) => ({
            pesticideType: item.name,
            pesticideAmount: item.amount,
            pesticideUnit: item.unit ? item.unit : "ml/daa",
            id: index
          }));
          this.setState({ pesticidesArr: chosenPestAmountArr });
        }
        if (dynamicDataObj.harvestDate) {
          this.setState({ chosenHarvestDate: dynamicDataObj.harvestDate });
        }
      }*/
    }
  }

  componentDidUpdate() {
  }

  render() {
    const { intl, waterEquipmentUsed, changeWaterEquipmentUsed, changeLessThan2PCSlope1, checkLessThan2PCSlope, 
      checkVegetatedBufferExists, changeVegetatedBufferExists, checkNozzlesProvideTargetMovement, changeNozzlesProvideTargetMovement,
      checkDamperSetForOptimalDistribution, changeDamperSetForOptimalDistribution, checkOneEndClosedToAvoidSurfaceWater, 
      changeOneEndClosedToAvoidSurfaceWater } = this.props;
    return (
      <div className="create-task-pesticide-journal">
        <div className="water-journal">
          <span className="water-journal__yes-no bold-font">{intl.formatMessage({ id: 'WATER_JOURNAL_EQUIPMENT_USED' })}</span>
          <span className="water-journal__yes-no">
            <RadioButton
              id="sprayerType1"
              onChange={() => changeWaterEquipmentUsed(true)}
              labelText={intl.formatMessage({ id: 'WATER_JOURNAL_EQUIPMENT_USED_OPTION1' })}
              disabled={false}
              name="sprayerType"
              size="small"
              checked={waterEquipmentUsed === true}
            />
          </span>
          <span className="water-journal__yes-no">
            <RadioButton
              id="sprayerType2"
              onChange={() => changeWaterEquipmentUsed(false)}
              labelText={intl.formatMessage({ id: 'WATER_JOURNAL_EQUIPMENT_USED_OPTION2' })}
              disabled={false}
              name="sprayerType"
              size="small"
              checked={waterEquipmentUsed === false}
            />
          </span>
        </div>
        {waterEquipmentUsed &&
        <div className="dynamic-wrapper1 full-width">
          <Field
            name="distanceToWaterBody"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DISTANCE_TO_WATER_BODY" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key="distanceToWaterBody"
          />
          <Field
            name="distanceToWaterWell"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DISTANCE_TO_WATER_WELL" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key="distanceToWaterWell"
          />
          <div className="water-journal-padding">
            <span className="water-journal__yes-no">{intl.formatMessage({ id: 'WATER_JOURNAL_PC_SLOPE' })}</span>
            <span className="water-journal__yes-no">
              <RadioButton
                id="lessThan2PCSlope1"
                onChange={() => changeLessThan2PCSlope1(true)}
                labelText={intl.formatMessage({ id: 'YES' })}
                disabled={false}
                name="lessThan2PCSlope"
                size="small"
                checked={checkLessThan2PCSlope === true}
              />
            </span>
            <span className="water-journal__yes-no">
              <RadioButton
                id="lessThan2PCSlope2"
                onChange={() => changeLessThan2PCSlope1(false)}
                labelText={intl.formatMessage({ id: 'NO' })}
                disabled={false}
                name="lessThan2PCSlope"
                size="small"
                checked={checkLessThan2PCSlope === false}
              />
            </span>
          </div>
          <Field
            name="reqdVegetatedBufferZone"
            label={intl.formatMessage({ id: "WATER_JOURNAL_BUFFER_ZONE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="reqdVegetatedBufferZone"
          />
          <Field
            name="nozzleName"
            label={intl.formatMessage({ id: "WATER_JOURNAL_NOZZLE_NAME" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="nozzleName"
          />
          <Field
            name="driftReduction"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DRIFT_REDUCTION" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_PERCENTAGE" })}
            key="driftReduction"
          />
          <Field
            name="maxDrivingSpeed"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DRIVING_SPEED" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_KM" })}
            key="maxDrivingSpeed"
          />
          <Field
            name="pressure"
            label={intl.formatMessage({ id: "WATER_JOURNAL_PRESSURE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_BAR" })}
            key="pressure"
          />
          <Field
            name="nozzleHeight"
            label={intl.formatMessage({ id: "WATER_JOURNAL_NOZZLE_HEIGHT" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_CM" })}
            key="nozzleHeight"
          />
        </div>}
        {!waterEquipmentUsed &&
        <div className="dynamic-wrapper1 full-width padding-bottom-zero">
          <Field
            name="distanceToWaterBody"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DISTANCE_TO_WATER_BODY" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key="distanceToWaterBody"
          />
          <Field
            name="distanceToWaterWell"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DISTANCE_TO_WATER_WELL" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key="distanceToWaterWell"
          />
          <div className="water-journal-padding">
            <div className='mineralization-calculation-div-less-bottom'>
              {intl.formatMessage({ id: "WATER_JOURNAL_BUFFER_EXISTS" })}
            </div>
            <div className='mineralization-calculation-div'>
              <ToggleButton
                inactiveLabel={intl.formatMessage({ id: 'NO' })}
                activeLabel={intl.formatMessage({ id: 'YES' })}
                colors={{
                  inactive: {
                    base: 'rgb(42, 60, 99)',
                    hover: 'rgb(42, 60, 99)',
                  }
                }}
                value={checkVegetatedBufferExists}
                onToggle={changeVegetatedBufferExists}
              />
            </div>
          </div>
        </div>}
        {!waterEquipmentUsed &&
        <div className="dynamic-wrapper1 full-width">
          {checkVegetatedBufferExists &&
          <Field
            name="locationAndDistance"
            label={intl.formatMessage({ id: "WATER_JOURNAL_LOCATION_DISTANCE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="locationAndDistance"
          />}
          <Field
            name="cultureBySpray"
            label={intl.formatMessage({ id: "WATER_JOURNAL_CULTURE_SPRAY" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key="cultureBySpray"
          />
          <Field
            name="fieldAge"
            label={intl.formatMessage({ id: "WATER_JOURNAL_FIELD_AGE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_AR" })}
            key="fieldAge"
          />
          <Field
            name="developmentStage"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DEVELOPMENT_STAGE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="developmentStage"
          />
          <Field
            name="reqdDistanceToSurfaceWater"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DISTANCE_SURFACE_WATER" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="reqdDistanceToSurfaceWater"
          />
          <Field
            name="reqdVegetatedBufferZone"
            label={intl.formatMessage({ id: "WATER_JOURNAL_VEGETATED_BUFFER_ZONE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="reqdVegetatedBufferZone"
          />
          <Field
            name="fogSprayerModel"
            label={intl.formatMessage({ id: "WATER_JOURNAL_FOG_SPRAYER" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="fogSprayerModel"
          />
          <Field
            name="nozzleName"
            label={intl.formatMessage({ id: "WATER_JOURNAL_NOZZLE_NAME" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="nozzleName"
          />
          <Field
            name="driftReduction"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DRIFT_REDUCTION" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_PERCENTAGE" })}
            key="driftReduction"
          />
          <Field
            name="maxDrivingSpeed"
            label={intl.formatMessage({ id: "WATER_JOURNAL_DRIVING_SPEED" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_KM" })}
            key="maxDrivingSpeed"
          />
          <Field
            name="pressure"
            label={intl.formatMessage({ id: "WATER_JOURNAL_PRESSURE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_BAR" })}
            key="pressure"
          />
          <Field
            name="amount"
            label={intl.formatMessage({ id: "WATER_JOURNAL_AMOUNT" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_LITER" })}
            key="amount"
          />
          <Field
            name="ptoSpeed"
            label={intl.formatMessage({ id: "WATER_JOURNAL_PTO_SPEED" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_OMDR" })}
            key="ptoSpeed"
          />
          <div className="water-journal-padding-new">
            <div className='mineralization-calculation-div-less-bottom'>
              {intl.formatMessage({ id: "WATER_JOURNAL_NOZZLES_PROVIDE" })}
            </div>
            <div className='mineralization-calculation-div'>
              <ToggleButton
                inactiveLabel={intl.formatMessage({ id: 'NO' })}
                activeLabel={intl.formatMessage({ id: 'YES' })}
                colors={{
                  inactive: {
                    base: 'rgb(42, 60, 99)',
                    hover: 'rgb(42, 60, 99)',
                  }
                }}
                value={checkNozzlesProvideTargetMovement}
                onToggle={changeNozzlesProvideTargetMovement}
              />
            </div>
          </div>
          <div className="water-journal-padding-new">
            <div className='mineralization-calculation-div-less-bottom'>
              {intl.formatMessage({ id: "WATER_JOURNAL_DAMPER_SET" })}
            </div>
            <div className='mineralization-calculation-div'>
              <ToggleButton
                inactiveLabel={intl.formatMessage({ id: 'NO' })}
                activeLabel={intl.formatMessage({ id: 'YES' })}
                colors={{
                  inactive: {
                    base: 'rgb(42, 60, 99)',
                    hover: 'rgb(42, 60, 99)',
                  }
                }}
                value={checkDamperSetForOptimalDistribution}
                onToggle={changeDamperSetForOptimalDistribution}
              />
            </div>
          </div>
          <div className="water-journal-padding-new">
            <div className='mineralization-calculation-div-less-bottom'>
              {intl.formatMessage({ id: "WATER_JOURNAL_ONE_END_CLOSED" })}
            </div>
            <div className='mineralization-calculation-div'>
              <ToggleButton
                inactiveLabel={intl.formatMessage({ id: 'NO' })}
                activeLabel={intl.formatMessage({ id: 'YES' })}
                colors={{
                  inactive: {
                    base: 'rgb(42, 60, 99)',
                    hover: 'rgb(42, 60, 99)',
                  }
                }}
                value={checkOneEndClosedToAvoidSurfaceWater}
                onToggle={changeOneEndClosedToAvoidSurfaceWater}
              />
            </div>
          </div>
          <Field
            name="sprayingTechnique"
            label={intl.formatMessage({ id: "WATER_JOURNAL_SPRAYING_TECHNIQUE" })}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key="sprayingTechnique"
          />
        </div>}
      </div>)
  }
}

const mapStateToProps = state => ({
  activityList: state.ActivityList,
  userInfo: state.userDetails,
  activitiesCommon: state.masterData.data[0]?.activities
});

const mapDispatchToProps = dispatch => ({
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WaterJournalForm));