import {
  CROP_LOADING,
  CROP_SUCCESS, 
  CROP_ERROR,
  PREVIOUS_CROP_LOADING,
  PREVIOUS_CROP_SUCCESS, 
  PREVIOUS_CROP_ERROR
} from 'constants/store/Fields';

const initialState = {
  cropHasError: false,
  isCropLoading: false,
  cropErrorMessage: null,
  cropList: null,
  previousCropHasError: false,
  isPreviousCropLoading: false,
  previousCropErrorMessage: null,
  previousCropList: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case CROP_LOADING:
      return {
        ...state,
        isCropLoading: true,
        cropHasError: false,
        cropErrorMessage: null
      };
    case CROP_ERROR:
      return {
        ...state,
        cropHasError: true,
        cropErrorMessage: action.payload,
        isCropLoading: false
      };
    case CROP_SUCCESS:
      return {
        ...state,
        isCropLoading: false,
        cropErrorMessage: null,
        cropHasError: false,
        cropList: action.payload
      };
    case PREVIOUS_CROP_LOADING:
      return {
        ...state,
        isPreviousCropLoading: true,
        previousCropHasError: false,
        previousCropErrorMessage: null
      };
    case PREVIOUS_CROP_ERROR:
      return {
        ...state,
        previousCropHasError: true,
        previousCropErrorMessage: action.payload,
        isPreviousCropLoading: false
      };
    case PREVIOUS_CROP_SUCCESS:
      return {
        ...state,
        isPreviousCropLoading: false,
        previousCropErrorMessage: null,
        previousCropHasError: false,
        previousCropList: action.payload
      };
    default:
      return state;
  }
}
