/**
 * Loader Page.
 * required @props { StyleName }
 */

import React from 'react';
import LoadingView from '@tine/lib-frontend-components/components/animations/loading-view';

const LoaderDynamic = ({ styleName, loadingText }) => (
  <div className={`loading-wrapper ${styleName}`}>
    <LoadingView loadingText={loadingText} />
  </div>
);

export default LoaderDynamic;
