/* eslint-disable react/jsx-props-no-spreading */
/**
 * Textarea Control
 * require @props {label, placeholdertext, errorMessage, controlId, type, maxLength}
 */
import React from 'react';

const TextAreaControl = ({
  input,
  label,
  maxLength,
  controlId,
  captionText,
  placeholdertext,
  stlyeClass,
  meta: { touched, error }
}) => {
  const ClassName = `form-control ${stlyeClass} ${touched && error ? 'error' : ''}`;
  return (
    <div className="form-group">
      <label htmlFor={controlId} className={captionText ? 'has-caption' : null}>
        {label}
      </label>
      {captionText ? <p className="label-caption">{captionText}</p> : null}
      <div className="input-note">
        <textarea
          {...input}
          maxLength={maxLength}
          className={ClassName}
          id={controlId}
          placeholder={placeholdertext}
        >
          {input}
        </textarea>
      </div>
      {touched && error && <span className="error-message"> {error} </span>}
    </div>
  );
};

export default TextAreaControl;
