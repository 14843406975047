import { isMobile } from 'react-device-detect';

/** TemperatureSum Colors */
export const TEMPERATURE_SUM_COLOR_LIST = ['#ff6929', '#18db18', '#23ade8', '#db36e0', '#4436e0', '#e03636', '#e8e531', '#085210',
'#3b0b59', '#c41088', '#adba20', '#10cc62', '#0b5345'];

/** MeanTemperature Colors */
export const MEAN_TEMPERATURE_COLOR_LIST = ['#8c2bc0', '#87947b', '#ffb300', '#9e2706', '#075e7a', '#41077a', '#135919', '#591413',
'#595613', '#59133d', '#3a5905', '#2e452f', '#1b2631'];

/** DailyRainfall Colors */
export const DAILY_RAINFALL_COLOR_LIST = ['#cc1039', '#5230c9', '#046609', '#ff9933', '#5ae08d', '#9ec938', '#d96c29', '#6b6d99',
'#1d1e38', '#453f11', '#3d173d', '#283593', '#af601a', '#8e44ad'];

export const RAPROTEIN_COLOR_LIST = ['#f58231', '#ffe119', '#bfef45', '#3cb44b', '#42d4f4', '#4363d8', '#911eb4', '#f032e6', '#e6194B', '#fabed4' ];

export const OMD_COLOR_LIST = ['#9A6324', '#808000', '#469990', '#000075', '#148F77', '#9A7D0A', '#2471A3', '#633974', '#52BE80', '#F4D03F' ];


/** TemperatureSum Y-Axis */
export const TEMPERATURE_SUM_YAXIS_DEFAULT = {
  show: false,
  min: 0,
  max: 3000,
  labels: {
    show: false
  },
  tickAmount: isMobile ? 7 : 25,
};

/** TemperatureSum Y-Axis Show */
export const TEMPERATURE_SUM_YAXIS_SHOW = {
  show: true,
  min: 0,
  max: 3000,
  labels: {
    show: false
  },
  tickAmount: isMobile ? 7 : 25,
};

/** MeanTemperature Y-Axis */
export const MEAN_TEMPERATURE_YAXIS_DEFAULT = {
  show: false,
  min: 0,
  max: 100,
  labels: {
    enabled: false
  },
  opposite: true,
  tickAmount: isMobile ? 7 : 25,
};

export const MEAN_TEMPERATURE_YAXIS_DEFAULT2 = {
  show: false,
  min: 0,
  max: 100,
  labels: {
    enabled: false,
    show: false
  },
  opposite: true,
  tickAmount: isMobile ? 7 : 25,
};

/** MeanTemperature Y-Axis */
export const RAPROTEIN_YAXIS_DEFAULT = {
  show: false,
  min: 0,
  max: 300,
  labels: {
    show: false
  },
  opposite: true,
  tickAmount: isMobile ? 7 : 25,
};

/** Temperature Title Stylesheet */
export const TEMPERATURE_TITLE_STYLE = {
  fontFamily: 'Inter',
  fontSize: isMobile ? '12px' : '18px',
  fontWeight: 400,
  color: '#333'
};

/** Chart values */
export const TEMPERATURE_SUM_MIN_VALUE = 0;
export const TEMPERATURE_SUM_MAX_VALUE = 3000;
export const MEAN_TEMPERATURE_MIN_VALUE = 0;
export const MEAN_TEMPERATURE_MAX_VALUE = 100;
export const RAPROTEIN_MIN_VALUE = 0;
export const RAPROTEIN_MAX_VALUE = 300;
export const OMD_MIN_VALUE = 0;
export const OMD_MAX_VALUE = 100;
export const YAXIS_TICK_AMOUNT = isMobile ? 7 : 20;
export const YAXIS_OMD_TICK_AMOUNT = isMobile ? 7 : 20;
export const YAXIS_RAPROTEIN_TICK_AMOUNT = isMobile ? 7 : 20;
export const XAXIS_TICK_AMOUNT = isMobile ? 7 : 25;
export const DISCRETE_COLOR = '#000d03';
export const DISCRETE_OMD_COLOR = '#fa880f';
export const OPTIONS_CHART = {
  height: isMobile ? '500px' :'400px',
  type: 'line',
  animations: {
    enabled: false,
  },
  toolbar: {
    show: isMobile ? true : true,
  },
  zoom: {
    enabled: isMobile ? true : true,
  }
};
export const OPTIONS_XAXIS_LABELS = {
  show: true,
  hideOverlappingLabels: false,
  style: {
      fontSize: isMobile ? '10px' : '13px',
      fontFamily: 'Inter'
  }
};
export const OPTIONS_XAXIS_TITLE_STYLE = {
  fontFamily: 'Inter',
  fontSize: isMobile ? '10px' : '18px',
  fontWeight: 400,
  color: '#333',
  paddingBottom: '20px'
};