import {
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
  GET_LOCATIONS_LOADING,
  GET_TASK_LOCATIONS_SUCCESS,
  GET_TASK_LOCATIONS_ERROR,
  GET_TASK_LOCATIONS_LOADING,
  GET_BALE_FEED_EVENT_SUCCESS,
  GET_BALE_FEED_EVENT_ERROR,
  GET_BALE_FEED_EVENT_LOADING,  
  ADD_BALE_FEED_EVENT_SUCCESS,
  ADD_BALE_FEED_EVENT_ERROR,
  SUB_BALE_FEED_EVENT_SUCCESS,
  SUB_BALE_FEED_EVENT_ERROR,
  ADD_TASK_BALE_SUCCESS,
  ADD_TASK_BALE_ERROR
} from 'constants/store/Bale';

const initialState = {
  data: null,
  hasError: false,
  isLoading: false,
  errorMessage: null,
  locationData: null,
  locationHasError: false,
  locationIsLoading: false,
  locationErrorMessage: null,
  taskLocationData: null,
  taskLocationHasError: false,
  taskLocationIsLoading: false,
  taskLocationErrorMessage: null,
  saveHasError: false,
  saveIsLoading: false,
  saveErrorMessage: null,
  saveSuccess: false,
  saveData: null,
};

export default function getBaleList(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_LOCATIONS_LOADING:
      return {
        ...state,
        locationData: null,
        locationHasError: false,
        locationIsLoading: true,
        locationErrorMessage: null
      };
    case GET_LOCATIONS_ERROR:
      return {
        ...state,
        locationData: null,
        locationHasError: true,
        locationIsLoading: false,
        locationErrorMessage: action.errorMessage,
      };
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationData: action.payload,
        locationHasError: false,
        locationIsLoading: false,
        locationErrorMessage: null,
      };
    case GET_TASK_LOCATIONS_LOADING:
      return {
        ...state,
        taskLocationData: null,
        taskLocationHasError: false,
        taskLocationIsLoading: true,
        taskLocationErrorMessage: null
      };
    case GET_TASK_LOCATIONS_ERROR:
      return {
        ...state,
        taskLocationData: null,
        taskLocationHasError: true,
        taskLocationIsLoading: false,
        taskLocationErrorMessage: action.errorMessage,
      };
    case GET_TASK_LOCATIONS_SUCCESS:
      return {
        ...state,
        taskLocationData: action.payload,
        taskLocationHasError: false,
        taskLocationIsLoading: false,
        taskLocationErrorMessage: null,
      };
    case GET_BALE_FEED_EVENT_LOADING:
      return {
        ...state,
        data: null,
        hasError: false,
        isLoading: true,
        errorMessage: null
      };
    case GET_BALE_FEED_EVENT_ERROR:
      return {
        ...state,
        data: null,
        hasError: true,
        isLoading: false,
        errorMessage: action.errorMessage
      };
    case GET_BALE_FEED_EVENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        hasError: false,
        isLoading: false,
        errorMessage: null
      };
    case ADD_BALE_FEED_EVENT_SUCCESS:
    case SUB_BALE_FEED_EVENT_SUCCESS:
    case ADD_TASK_BALE_SUCCESS:
      return {
        ...state,
        saveHasError: false,
        saveIsLoading: false,
        saveErrorMessage: null,
        saveSuccess: true,
        saveData: action.payload
      };
    case ADD_BALE_FEED_EVENT_ERROR:
    case SUB_BALE_FEED_EVENT_ERROR:
    case ADD_TASK_BALE_ERROR:
      return {
        ...state,
        saveHasError: true,
        saveIsLoading: false,
        saveErrorMessage: action.errorMessage,
        saveSuccess: false,
        saveData: null
      };
    default:
      return state;
  }
}
