import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import NavigationButton from '@tine/lib-frontend-components/components/buttons/navigation-button';
import TextBoxControl from 'components/forms/TextBoxControl';
import { ValidateEmail } from 'utils/index';
import { requestPassword } from 'actions/ForgotPassword';
import { CODE_404 } from 'constants/index';
import Loader from 'components/Loader';

class ForgotPassword extends Component {
  state = {
    isLoading: false,
    hasError: false,
    errorMsg: '',
    isDone: false
  };

  /**
   * Handle farm submit
   */
  handleSubmit = values => {
    const { requestingPassword, history } = this.props;
    this.setState({ isLoading: true, isDone: false, hasError: false });
    // call request password action
    requestingPassword(values.emailId, response => {
      if (response.status === 200) {
        this.setState({ isLoading: false, isDone: true });
        setTimeout(() => {
          history.push({
            pathname: '/forgotpassword/changepassword',
            state: { emailId: values.emailId }
          });
        }, 1000);
      } else if (response.response.status === CODE_404) {
        this.setState({
          isLoading: false,
          hasError: true,
          errorMsg: <FormattedMessage id="USER_NOT_FOUND" />
        });
      } else {
        this.setState({
          isLoading: false,
          hasError: true,
          errorMsg: response.response.data.message
        });
      }
    });
  };

  render() {
    const { handleSubmit, intl } = this.props;
    const { isDone, hasError, isLoading, errorMsg } = this.state;
    return (
      <div className="full-width forgot-password">
        <h3 className="form-title">
          <FormattedMessage id="forgotPwdTitle" />
        </h3>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className="change-password">
            <Field
              name="emailId"
              label={intl.formatMessage({ id: 'email' })}
              component={TextBoxControl}
              controlId="emailId"
              type="text"
            />
          </div>
          {isDone ? (
            <div className="sent-notify">
              <i className="success-indicator" />
              <span>
                <FormattedMessage id="mailSent" />
              </span>
            </div>
          ) : null}
          {hasError ? <p className="error-text">{errorMsg}</p> : null}
          {isLoading ? <Loader styleName="login-loader" /> : null}
          <div className="btn-wapper">
            <NavigationButton disabled={isLoading} route="/" multi>
              {intl.formatMessage({ id: 'cancel' })}
            </NavigationButton>
            <ActionButton disabled={isLoading} onClick={() => handleSubmit(this.handleSubmit)}>
              {intl.formatMessage({ id: 'send' })}
            </ActionButton>
          </div>
        </form>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { intl } = props;
  if (!values.emailId || !values.emailId.trim()) {
    errors.emailId = intl.formatMessage({ id: 'L_PEVE' });
  }
  if (!ValidateEmail(values.emailId)) {
    errors.emailId = intl.formatMessage({ id: 'L_PEVE' });
  }
  // If errors is empty, the form will submit
  return errors;
}

const mapDispatchToProps = dispatch => ({
  requestingPassword: (emailId, callback) => dispatch(requestPassword(emailId, callback))
});

export default injectIntl(
  reduxForm({
    form: 'ForgotPasswordForm',
    validate
  })(withRouter(connect(null, mapDispatchToProps)(ForgotPassword)))
);
