import {
  GET_PROFILE_IMAGE_IS_LOADING,
  GET_PROFILE_IMAGE_SUCCESS,
  GET_PROFILE_IMAGE_FAILURE,
  UPLOAD_PROFILE_IMAGE_IS_LOADING,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_FAILURE
} from 'constants/store/Auth';

import {
  IMG_UPLOAD_FOLDER,
  IMG_UPLOAD_REQUEST,
  GET_TASK_USER_PIC,
  IMG_DOWNLOAD_REQUEST
} from 'constants/index';

import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_PROFILE_IMG,
  GA_PROFILE_IMG_ERR,
  GA_CAT_ERR,
  GA_CAT_UPDATE,
  GA_PROFILE_LBL
} from 'constants/GoogleAnalytics';

const getImageSuccess = data => ({
  type: GET_PROFILE_IMAGE_SUCCESS,
  data
});

const getImageFaliure = (bool, error) => ({
  type: GET_PROFILE_IMAGE_FAILURE,
  hasError: bool,
  errorMessage: error
});

const getImageIsLoading = bool => ({
  type: GET_PROFILE_IMAGE_IS_LOADING,
  isLoading: bool
});

const uploadImageSuccess = data => ({
  type: UPLOAD_PROFILE_IMAGE_SUCCESS,
  data
});

const uploadImageFaliure = (bool, error) => ({
  type: UPLOAD_PROFILE_IMAGE_FAILURE,
  hasError: bool,
  errorMessage: error
});

const uploadImageIsLoading = bool => ({
  type: UPLOAD_PROFILE_IMAGE_IS_LOADING,
  isLoading: bool
});

const taskUserImage = data => ({
  type: GET_TASK_USER_PIC,
  payload: data
});

/**
 * To upload & download image to AWS
 * @param {awsLogin, type, userId, file}
 */
export default (awsLogin, type, userId, userType, file) => async dispatch => {
  try {
    const { AWS } = window;
    AWS.config.region = awsLogin.s3Region;
    AWS.config.update({
      accessKeyId: awsLogin.accessKey,
      secretAccessKey: awsLogin.secretKey,
      sessionToken: awsLogin.sessionToken
    });
    const bucket = new AWS.S3({ params: { Bucket: awsLogin.bucketName } });
    let params;
    if (type === IMG_UPLOAD_REQUEST) {
      dispatch(uploadImageIsLoading(true));
      params = {
        Bucket: awsLogin.bucketName,
        Key: IMG_UPLOAD_FOLDER + userId,
        ContentType: file.type,
        Body: file
      };
      await bucket.putObject(params, err => {
        if (err) {
          GAEvent(GA_PROFILE_IMG_ERR, GA_CAT_ERR, GA_PROFILE_LBL);
          dispatch(uploadImageFaliure(true, err));
        } else {
          GAEvent(GA_PROFILE_IMG, GA_CAT_UPDATE, GA_PROFILE_LBL);
          dispatch(uploadImageSuccess(true));
        }
      });
    }
    if (type === IMG_DOWNLOAD_REQUEST) {
      if (userType === 'userImage') {
        params = {
          Bucket: awsLogin.bucketName,
          Key: IMG_UPLOAD_FOLDER + userId
        };
        await bucket.getObject(params, (err, data) => {
          if (err) {
            dispatch(taskUserImage({ userId, image: null }));
          } else {
            const blob = new Blob([data.Body], { type: 'image/png' });
            let image;
            const reader = new FileReader();
            reader.onloadend = () => {
              image = reader.result;
              dispatch(taskUserImage({ userId, image }));
            };
            reader.readAsDataURL(blob);
          }
        });
      } else {
        dispatch(getImageIsLoading(true));
        params = {
          Bucket: awsLogin.bucketName,
          Key: IMG_UPLOAD_FOLDER + userId
        };
        await bucket.getObject(params, (err, data) => {
          if (err) {
            dispatch(getImageFaliure(true, err));
          } else {
            const blob = new Blob([data.Body], { type: 'image/png' });
            let image;
            const reader = new FileReader();
            reader.onloadend = () => {
              image = reader.result;
              dispatch(getImageSuccess(image));
            };
            reader.readAsDataURL(blob);
          }
        });
      }
    }
  } catch (error) {
    if (type === IMG_UPLOAD_REQUEST) {
      dispatch(uploadImageFaliure(true, error));
    } else {
      dispatch(getImageFaliure(true, error));
    }
  }
};
