import * as React from 'react';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';
import ExpandingList from '@tine/lib-frontend-components/components/accordion/expanding-list';

function getMineralFertilizerHeader(intl) {
  return [
    {
      rowId: 0,
      mainEntry: (
        <div className="fertilization-plan-grid --grid-padding-large --grid-layout">
          <div></div>
          <div>
            <EmphasisLight>{intl.formatMessage({ id: 'NITROGEN' })}</EmphasisLight>
          </div>
          <div>
            <EmphasisLight>{intl.formatMessage({ id: 'PHOSPHORUS' })}</EmphasisLight>
          </div>
          <div>
            <EmphasisLight>{intl.formatMessage({ id: 'POTASSIUM' })}</EmphasisLight>
          </div>
        </div>
      )
    }
  ];
}

function getMineralFertilizerNeed(fieldFertilizationItem, intl) {
  let mineral_fertilizer_need = [];
  if (fieldFertilizationItem.needForField !== null) {
    mineral_fertilizer_need = [
      {
        rowId: 1,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'NEED' })}:</div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.needForField.nitrogenNeed.toFixed(1)}{' '}
              {fieldFertilizationItem.needForField.nutrientUnitsNeed}
            </div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.needForField.phosphorusNeed.toFixed(1)}{' '}
              {fieldFertilizationItem.needForField.nutrientUnitsNeed}
            </div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.needForField.potassiumNeed.toFixed(1)}{' '}
              {fieldFertilizationItem.needForField.nutrientUnitsNeed}
            </div>
          </div>
        )
      }
    ];
  }
  return mineral_fertilizer_need;
}

function getMineralFertilizerAdded(fieldFertilizationItem, intl) {
  let manureData = getManureData(fieldFertilizationItem, intl);
  let mineralFertilizerData = getMineralFertilizerData(fieldFertilizationItem);
  let mineral_fertilizer_added_sub_entries = [];
  mineral_fertilizer_added_sub_entries = [
    <div>
      {manureData}
      {mineralFertilizerData}
    </div>
  ];
  let mineral_fertilizer_added = [];
  if (fieldFertilizationItem.addedForField !== null) {
    mineral_fertilizer_added = [
      {
        rowId: 2,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'ADDED' })}:</div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.addedForField.nitrogenAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForField.nutrientUnitsAdded}
            </div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.addedForField.phosphorusAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForField.nutrientUnitsAdded}
            </div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.addedForField.potassiumAdded.toFixed(1)}{' '}
              {fieldFertilizationItem.addedForField.nutrientUnitsAdded}
            </div>
          </div>
        ),
        additionalEntries: mineral_fertilizer_added_sub_entries
      }
    ];
  }
  return mineral_fertilizer_added;
}

function getMineralFertilizerDifference(fieldFertilizationItem, intl) {
  let mineral_fertilizer_difference = [];
  if (fieldFertilizationItem.shortfallForField !== null) {
    mineral_fertilizer_difference = [
      {
        rowId: 3,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'DIFFERENCE' })}:</div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.shortfallForField.nitrogenShortfall.toFixed(1)}{' '}
              {fieldFertilizationItem.shortfallForField.nutrientUnitsShortfall}
            </div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.shortfallForField.phosphorusShortfall.toFixed(1)}{' '}
              {fieldFertilizationItem.shortfallForField.nutrientUnitsShortfall}
            </div>
            <div className="fertilizer-text-normal">
              {fieldFertilizationItem.shortfallForField.potassiumShortfall.toFixed(1)}{' '}
              {fieldFertilizationItem.shortfallForField.nutrientUnitsShortfall}
            </div>
          </div>
        )
      }
    ];
  }
  return mineral_fertilizer_difference;
}

function getMineralFertilizerData(fieldFertilizationItem) {
  if (fieldFertilizationItem.mineralFertilizersForField?.length > 0) {
    return fieldFertilizationItem.mineralFertilizersForField.map(mineralFertilizerItem => (
      <div
        key={mineralFertilizerItem.nitrogen}
        className="fertilization-plan-grid --border-thin-blue --grid-padding-low --grid-layout"
      >
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {mineralFertilizerItem.customName === null
              ? mineralFertilizerItem.originalName
              : mineralFertilizerItem.customName}
          </EmphasisLight>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.nitrogen.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.phosphorus.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.potassium.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
      </div>
    ));
  }
}

function getManureData(fieldFertilizationItem, intl) {
  let manureData = '';
  if (fieldFertilizationItem.manureForField !== null) {
    manureData = (
      <div className="fertilization-plan-grid --border-thin-blue --grid-padding-low --grid-layout">
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {intl.formatMessage({ id: 'MANURE' })}
          </EmphasisLight>
        </div>
        <div>
          <MetaInformation size="small">
            {fieldFertilizationItem.manureForField.manureNitrogen.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {fieldFertilizationItem.manureForField.manurePhosphorus.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {fieldFertilizationItem.manureForField.manurePotassium.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
      </div>
    );
  }
  return manureData;
}

function getManureTotalContent(fieldFertilizationItem, intl) {
  let manureData = '';
  if (fieldFertilizationItem.manureForField !== null) {
    manureData = (
      <div className="fertilization-plan-grid --grid-padding-extralarge --grid-layout-menu-field">
        <div className="text-capitalize">
          <EmphasisLight>
            {fieldFertilizationItem.manureForField.manureName === null
            ? intl.formatMessage({ id: 'MANURE' })
            : fieldFertilizationItem.manureForField.manureName}
          </EmphasisLight>
        </div>
        <div className="fertilizer-text-normal --grid-padding-left">
          {fieldFertilizationItem.manureForField.manureCustomQuantity === null
            ? fieldFertilizationItem.manureForField.manureOriginalQuantity.toFixed(1)
            : fieldFertilizationItem.manureForField.manureCustomQuantity.toFixed(1)}{' '}
            {fieldFertilizationItem.manureForField.manureQuantityUnits === null
            ? "m^3/daa"
            : fieldFertilizationItem.manureForField.manureQuantityUnits}
        </div>
        <div className="--grid-padding-left">
          <EmphasisLight>
            {fieldFertilizationItem.manureForField.manureCustomTotalQuantity === null
              ? Math.round(fieldFertilizationItem.manureForField.manureOriginalTotalQuantity)
              : Math.round(fieldFertilizationItem.manureForField.manureCustomTotalQuantity)}{' '}
              {fieldFertilizationItem.manureForField.manureTotalQuantityUnits === null
            ? "m^3"
            : fieldFertilizationItem.manureForField.manureTotalQuantityUnits} <span className="text-lowercase">{intl.formatMessage({ id: 'TOTAL' })}</span>
          </EmphasisLight>
        </div>
      </div>
    );
  }
  return manureData;
}

function getMineralFertilizerTotalCount(fieldFertilizationItem, intl) {
  if (fieldFertilizationItem.mineralFertilizersForField?.length > 0) {
    return fieldFertilizationItem.mineralFertilizersForField.map(mineralFertilizerItem => (
      <div
        key={mineralFertilizerItem.nitrogen}
        className="fertilization-plan-grid --border-thin-blue --grid-content-padding-extralarge --grid-layout-menu-field"
      >
        <div className="text-capitalize">
          <EmphasisLight>
            {mineralFertilizerItem.customName === null
              ? mineralFertilizerItem.originalName
              : mineralFertilizerItem.customName}
          </EmphasisLight>
        </div>
        <div className="fertilizer-text-normal --grid-padding-left">
          {mineralFertilizerItem.customQuantity === null
            ? mineralFertilizerItem.originalQuantity.toFixed(1)
            : mineralFertilizerItem.customQuantity.toFixed(1)}{' '}
          {mineralFertilizerItem.quantityUnits}
        </div>
        <div className="--grid-padding-left">
          <EmphasisLight>
            {mineralFertilizerItem.customTotalQuantity === null
              ? Math.round(mineralFertilizerItem.originalTotalQuantity)
              : Math.round(mineralFertilizerItem.customTotalQuantity)}{' '}
            {mineralFertilizerItem.totalQuantityUnits} <span className="text-lowercase">{intl.formatMessage({ id: 'TOTAL' })}</span>
          </EmphasisLight>
        </div>
      </div>
    ));
  }
}

function getFertilizerPlanforField(fieldFertilizationItem, intl) {
  return (
    <div className="fertilization-blue-header">
      <div className="fertilization-plan-content">
        <div className="fertilization-plan-grid-blue-border">
          <div className="fertilizer-text-large text-capitalize">{intl.formatMessage({ id: 'TOTAL' })}</div>
          <div>
            <div className="--border-thick-blue">
              <ExpandingList list={getMineralFertilizerHeader(intl)} />
            </div>
            <div className="--border-thin-blue">
              <ExpandingList list={getMineralFertilizerNeed(fieldFertilizationItem, intl)} />
            </div>
            <div className="--border-thin-blue">
              <ExpandingList list={getMineralFertilizerAdded(fieldFertilizationItem, intl)} />
            </div>
            <div>
              <ExpandingList list={getMineralFertilizerDifference(fieldFertilizationItem, intl)} />
            </div>
          </div>
        </div>
        <div>
          <div>
            {getManureTotalContent(fieldFertilizationItem, intl)}
            {getMineralFertilizerTotalCount(fieldFertilizationItem, intl)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ({ fieldFertilizationItem, intl }) => getFertilizerPlanforField(fieldFertilizationItem, intl);
