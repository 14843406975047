import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';

import SelectControl from 'components/forms/SelectBoxControl';
import _ from 'lodash';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';

import ManureData from 'mui-components/fields/fieldtab/ManureDataForm';
import fetchManureNames from 'actions/ManureNames';
import * as fieldActions from 'actions/Fields';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import { getDropDownFieldsStatuses } from 'utils/index';
import * as YieldPlan from 'actions/YieldPlan';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import { format } from 'date-fns';
import * as HistoricalData from 'actions/HistoricalData';
class UpdateFieldsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manureApplied: false,
      springAdd: false,
      summerAdd: false,
      fallAdd: false,
      manureDataUpdated: false,
      showSoilSample: false,
      yieldCalculation: true,
      yieldGoalValue: "",
      harvestValue: "",
      showHarvest: false,
      defaultYieldValue: null,
      changedYieldValue: null,
      yieldSumValue: 0,
      yieldPercentValue: 0,
      showYieldPopup: false,
      showHarvestPopup: false,
      showYieldGoalPopup: false,
      showYieldGoalNullPopup: false,
      showHarvestNullPopup: false,
      manureError: false,
      manureAppliedError: false,
      acceptToSave: false,
      notAcceptToSave: false,
      acceptToSaveNull: false,
      yieldResponseError: false,
      fieldOptionApplied: null,
      cut1Date: null,
      cut2Date: null,
      cut3Date: null,
      cut4Date: null,
      disableGrain: false,
      stravRemovedApplied: null,
      setHistoricalData: false,
      cut1show: true,
      cut2show: false,
      cut3show: false,
      cut4show: false
    };
    this.cut1DateChange = this.cut1DateChange.bind(this);
    this.cut2DateChange = this.cut2DateChange.bind(this);
    this.cut3DateChange = this.cut3DateChange.bind(this);
    this.cut4DateChange = this.cut4DateChange.bind(this);
  }

  componentDidMount = () => {
    const { activeFarm, getManureNames, yieldPlan, fieldDetails } = this.props;
    const { isBatchError } = fieldDetails;
    if(isBatchError) {
      fieldDetails.errorMessage = null;
    }
    getManureNames(activeFarm.farm.id);
    this.setState({
      showHarvest: false
    });
    yieldPlan.yieldCalculation = null;
  };

  componentDidUpdate(prevProps) {
    const { yieldPlan, historicalData } = this.props;
    const { showHarvest, setHistoricalData } = this.state;
    if(!showHarvest && yieldPlan && yieldPlan.yieldCalculation !== null) {
      let sumValue = 0;
      let percentValue = 0;
      let defaultVal = [];
      let arrayValue = [];
      let defaultYieldValue = [];
      let keyPair1 = {};
      let keyPair2 = {};
      arrayValue.push(yieldPlan.yieldCalculation);
      if(yieldPlan.yieldCalculation.plannedYields?.length > 0) {
        yieldPlan.yieldCalculation.plannedYields.map((plannedYield) => (
          //eslint-disable-next-line
          sumValue = sumValue + plannedYield.yield,
          percentValue = percentValue + plannedYield.yieldPercent
        ))
        arrayValue.map((type) => (
          //eslint-disable-next-line
          keyPair1 = {},
          keyPair1["defaultHarvests"] = type.harvests,
          keyPair1["defaultPlannedYield"] = type.plannedYield,
          keyPair1["defaultTotalYield"] = type.totalYield,
          keyPair1["defaultTotalYieldPercent"] = type.totalYieldPercent,
          keyPair1["defaultYieldGoal"] = type.yieldGoal,
          keyPair1["defaultYieldResponse"] = type.yieldResponse,
          keyPair1 ? defaultYieldValue.push(keyPair1) : ''
        ))
        yieldPlan.yieldCalculation.plannedYields.map(yieldRes => (
          //eslint-disable-next-line
          keyPair2 = {},
          keyPair2["defaultYield"] = yieldRes.yield,
          keyPair2["defaultYieldPercent"] = yieldRes.yieldPercent,
          keyPair2 ? defaultVal.push(keyPair2): ''
        ));
      }
      if(defaultVal) {
        defaultYieldValue.push(defaultVal);
      }
      this.setState({ 
        showHarvest: true,
        changedYieldValue: yieldPlan.yieldCalculation,
        defaultYieldValue: defaultYieldValue,
        yieldSumValue: this.roundToTwoDecimal(sumValue),
        yieldPercentValue: this.roundToTwoDecimal(percentValue)
      });
    }
    if(historicalData && historicalData.historicalDataValue && !setHistoricalData) {
      if(historicalData.historicalDataValue.length > 0) {
        this.setState({
          cut1Date: historicalData.historicalDataValue[0] && historicalData.historicalDataValue[0] !== null ? new Date(historicalData.historicalDataValue[0]) : null,
          cut2Date: historicalData.historicalDataValue[1] && historicalData.historicalDataValue[1] !== null ? new Date(historicalData.historicalDataValue[1]) : null,
          cut3Date: historicalData.historicalDataValue[2] && historicalData.historicalDataValue[2] !== null ? new Date(historicalData.historicalDataValue[2]) : null,
          cut4Date: historicalData.historicalDataValue[3] && historicalData.historicalDataValue[3] !== null ? new Date(historicalData.historicalDataValue[3]) : null,
          setHistoricalData: true
        });
      } else {
        this.setState({
          cut1Date: null,
          cut2Date: null,
          cut3Date: null,
          cut4Date: null,
          setHistoricalData: true
        });
      }
    }
  }

  cut1DateChange(date) {
    this.setState({ cut1Date: date });
  }

  cut2DateChange(date) {
    this.setState({ cut2Date: date });
  }

  cut3DateChange(date) {
    this.setState({ cut3Date: date });
  }

  cut4DateChange(date) {
    this.setState({ cut4Date: date });
  }

  onStravRemovedChange = e => {
    const { change } = this.props;
    this.setState({
      stravRemovedApplied: e
    });
    change('stravRemovedApplied', e);
  };

  onCropChange = e => {
    const { change } = this.props;
    if(e.value === 5 || e.value === 14 || e.value === 17 || e.value === 18 || e.value === 19 || e.value === 23) {
      this.setState({
        disableGrain: true
      });
    } else {
      this.setState({
        disableGrain: false
      });
    }
    change('previousCrop', {value: "", label: ""});
  }

  roundToTwoDecimal = num => {    
    return Number(Math.round(num+'e2')+'e-2');
  }

  // To get the selected fields items from the list
  getSelectedFields = values => new Map([...values].filter(([k, v]) => v === true));

  /**
   * To get selected field object
   * @returns { Object }
   */
  getSelectedDetails = () => {
    const { fieldDetails, selectedField } = this.props;
    const selectedItem = this.getSelectedFields(selectedField);
    const selectedtaskObj = [];
    selectedItem.forEach((v, k) =>
      selectedtaskObj.push(_.filter(fieldDetails.data, ['fieldId', k])[0])
    );
    return selectedtaskObj;
  };

  getSelectedFieldNames = fields => {
    const fieldsName = fields.map(item => item.fieldName);
    return fieldsName.join(', ');
  };

  closeHarvestPopUp = () => {
    this.setState({ 
      showYieldPopup: false, 
      showHarvestPopup: false,
      showYieldGoalPopup: false,
      showHarvestNullPopup: false,
      showYieldGoalNullPopup: false,
      manureError: false,
      manureAppliedError: false,
      yieldResponseError: false
    });
  }

  closeYieldGoalPopUp = () => {
    this.setState({ 
      showYieldPopup: false, 
      showHarvestPopup: false,
      showYieldGoalPopup: false,
      showHarvestNullPopup: false,
      showYieldGoalNullPopup: false,
      manureError: false,
      manureAppliedError: false,
      yieldResponseError: false
    }); 
  }

  saveYieldPopUp = () => {
    const { handleSubmit } = this.props;
    this.setState({ acceptToSave: true,  manureError: false, manureAppliedError: false, yieldResponseError: false });
    handleSubmit(this.handleSubmit);
  }

  saveNullPopUp = () => {
    const { handleSubmit } = this.props;
    this.setState({ acceptToSaveNull: true,  manureError: false, manureAppliedError: false, yieldResponseError: false });
    handleSubmit(this.handleSubmit);
  }

  closeYieldPopUp = () => {
    const { handleSubmit } = this.props;
    this.setState({ notAcceptToSave: true,  manureError: false, manureAppliedError: false, yieldResponseError: false });
    handleSubmit(this.handleSubmit);
  }

  handleSubmit = values => {
    const { batchUpdate, activeFarm, yieldPlan, historicalData } = this.props;
    const { manureApplied, changedYieldValue, defaultYieldValue, acceptToSave, notAcceptToSave, acceptToSaveNull, 
      cut1Date, cut2Date, cut3Date, cut4Date, fieldOptionApplied, disableGrain, stravRemovedApplied } = this.state;
    const fieldValues = {};
    this.setState({ 
      showHarvestPopup: false, 
      showYieldGoalPopup: false, 
      showYieldPopup: false,
      showYieldGoalNullPopup: false,
      showHarvestNullPopup: false,
      manureError: false,
      manureAppliedError: false,
      yieldResponseError: false
    });
    const seletedFieldData = this.getSelectedDetails();
    fieldValues.fieldSet = seletedFieldData.map(item => item.fieldId);

    fieldValues.cropId = values.crop?.value || 0;
    fieldValues.method = values.method?.value || null;
    fieldValues.manureApplied = manureApplied;
    if(fieldOptionApplied !== null ) {
      fieldValues.goOrganic = fieldOptionApplied;
    }
    //fieldValues.spreadings = values.spreadings || '';
    if(disableGrain === true) {
      fieldValues.stravRemoved = stravRemovedApplied;
      fieldValues.grainPreviousCropId = values.previousCrop?.value || null;
      fieldValues.harvests = 1;
    } else {
      fieldValues.previousCropId = values.previousCrop?.value || 0;
      fieldValues.harvests = values.harvests?.value || '';
    }
    fieldValues.yieldGoal = values.yieldGoal || '';
    fieldValues.seedingYear = values.seedingYear?.value || '';
    fieldValues.clover = values.clover || '';
    const manureDetails = [];
    if (
      values.spring_manureStorage !== undefined &&
      values.spring_manureStorage.value !== null && 
      values.spring_manureStorage.value !== '' &&
      values.spring_manureMethod !== undefined &&
      values.spring_manureMethod.value !== null && 
      values.spring_manureMethod.value !== '' &&
      fieldValues && fieldValues.harvests >= 1) {
        manureDetails.push({
          seasonId: 1,
          manureStorageId: values.spring_manureStorage.value === '' ? null : values.spring_manureStorage.value,
          manureQuantity: values.spring_manureAmount === '' ? 0 : values.spring_manureAmount,
          method: values.spring_manureMethod.value === '' ? null : values.spring_manureMethod.value,
          manurePlanId: null
        });
    }
    if(disableGrain === false) {
        if (
        values.summer_manureStorage !== undefined &&
        values.summer_manureStorage.value !== null && 
        values.summer_manureStorage.value !== '' &&
        values.summer_manureMethod !== undefined &&
        values.summer_manureMethod.value !== null && 
        values.summer_manureMethod.value !== '' &&
        fieldValues && fieldValues.harvests >= 1) {
          manureDetails.push({
            seasonId: 2,
            manureStorageId: values.summer_manureStorage.value === '' ? null : values.summer_manureStorage.value,
            manureQuantity: values.summer_manureAmount === '' ? 0 : values.summer_manureAmount,
            method: values.summer_manureMethod.value === '' ? null : values.summer_manureMethod.value,
            manurePlanId: null
          });
        }
        if (
        values.fall_manureStorage !== undefined &&
        values.fall_manureStorage.value !== null && 
        values.fall_manureStorage.value !== '' &&
        values.fall_manureMethod !== undefined &&
        values.fall_manureMethod.value !== null && 
        values.fall_manureMethod.value !== '' &&
        fieldValues && fieldValues.harvests >= 2) {
          manureDetails.push({
            seasonId: 3,
            manureStorageId: values.fall_manureStorage.value === '' ? null : values.fall_manureStorage.value,
            manureQuantity: values.fall_manureAmount === '' ? 0 : values.fall_manureAmount,
            method: values.fall_manureMethod.value === '' ? null : values.fall_manureMethod.value,
            manurePlanId: null
          });
        }
    }
    if(manureDetails.length > 0) {
      fieldValues.manurePlans = manureDetails;
    }

   /* if(manureApplied === true && (manureDetails.length === 0)) {
      this.setState({ manureAppliedError: true });
      return;
    }

    if(manureApplied === true && (manureDetails.length > values.harvests?.value)) {
      this.setState({ manureError: true });
      return;
    }*/

    if(defaultYieldValue === null){
      fieldValues.yieldResponse = values.yieldResponse;
      fieldValues.plannedYields =  [];
    }

    /*if(defaultYieldValue && defaultYieldValue !== null && defaultYieldValue[0] !== null 
      && parseFloat(values.yieldGoal) !== defaultYieldValue[0].defaultPlannedYield && !acceptToSaveNull) {
      if(values.yieldGoal === "") {
        this.setState({showYieldGoalNullPopup: true});
      } else {
        this.setState({showYieldGoalPopup: true});
      }
      return;
    }*/

    /*(defaultYieldValue && defaultYieldValue !== null && defaultYieldValue[0] !== null
      && defaultYieldValue[0].defaultHarvests !== values.harvests?.value && !acceptToSaveNull) {
      if(values.harvests?.value === "") {
        this.setState({showHarvestNullPopup: true});
      } else {
        this.setState({showHarvestPopup: true});
      }
      return;
    }*/

    /*if(defaultYieldValue && defaultYieldValue[0].defaultTotalYield !== yieldSumValue && !acceptToSave && !notAcceptToSave) {
      this.setState({showYieldPopup: true});
      return;
    }*/
    if(!notAcceptToSave && !acceptToSave && !acceptToSaveNull) {
      if(disableGrain === false) {
        fieldValues.harvests = values.harvests?.value;
      } else {
        fieldValues.harvests = 1;
      }
      fieldValues.yieldGoal = values.yieldGoal ? parseFloat(this.roundToTwoDecimal(values.yieldGoal)) : '';
      if(disableGrain === false && defaultYieldValue && defaultYieldValue !== null) {
        fieldValues.yieldResponse = parseFloat(this.roundToTwoDecimal(changedYieldValue.yieldResponse));
        let plannedYields = [];
        let keyPair = {};
        changedYieldValue.plannedYields.map(planYield => (
          //eslint-disable-next-line
          keyPair = {},
          keyPair["yield"] = parseFloat(this.roundToTwoDecimal(planYield.yield)),
          keyPair["yieldPercent"] = parseFloat(this.roundToTwoDecimal(planYield.yieldPercent)),
          plannedYields.push(keyPair)
        ))
        fieldValues.plannedYields = plannedYields;
      }
    }

    if(acceptToSaveNull) {
      if(disableGrain === false) {
        fieldValues.harvests = values.harvests?.value;
      } else {
        fieldValues.harvests = 1;
      }
      fieldValues.yieldGoal = values.yieldGoal ? parseFloat(this.roundToTwoDecimal(values.yieldGoal)) : '';
      fieldValues.yieldResponse = null;
      fieldValues.plannedYields = [];
    }

    if(notAcceptToSave) {
      if(disableGrain === false && defaultYieldValue && defaultYieldValue !== null) {
        fieldValues.yieldResponse = parseFloat(this.roundToTwoDecimal(defaultYieldValue[0].defaultYieldResponse));
        let plannedYields = [];
        let keyPair = {};
        defaultYieldValue[1].map(planYield => (
          //eslint-disable-next-line
          keyPair = {},
          keyPair["yield"] = parseFloat(this.roundToTwoDecimal(planYield.defaultYield)),
          keyPair["yieldPercent"] = parseFloat(this.roundToTwoDecimal(planYield.defaultYieldPercent)),
          plannedYields.push(keyPair)
        ))
        fieldValues.plannedYields = plannedYields;
        fieldValues.harvests = defaultYieldValue[0].defaultHarvests;
        fieldValues.yieldGoal = parseFloat(this.roundToTwoDecimal(defaultYieldValue[0].defaultYieldGoal));
      }
    }

    if(acceptToSave) {
      if(disableGrain === false && changedYieldValue && changedYieldValue !== null) {
        fieldValues.yieldResponse = parseFloat(this.roundToTwoDecimal(changedYieldValue.yieldResponse));
        let plannedYields = [];
        let keyPair = {};
        changedYieldValue.plannedYields.map(planYield => (
          //eslint-disable-next-line
          keyPair = {},
          keyPair["yield"] = parseFloat(this.roundToTwoDecimal(planYield.yield)),
          keyPair["yieldPercent"] = parseFloat(this.roundToTwoDecimal(planYield.yieldPercent)),
          plannedYields.push(keyPair)
        ))
        fieldValues.plannedYields = plannedYields;
      }
      if(disableGrain === false) {
        fieldValues.harvests = values.harvests?.value;
      } else {
        fieldValues.harvests = 1;
      }
      fieldValues.yieldGoal = values.yieldGoal ? parseFloat(this.roundToTwoDecimal(values.yieldGoal)) : '';
    }

    if(fieldValues.yieldGoal === '' || fieldValues.yieldGoal === undefined) {
      fieldValues.yieldGoal = null;
    }
    if(fieldValues.yieldResponse === '' || fieldValues.yieldResponse === undefined) {
      fieldValues.yieldResponse = null;
    }
    if(historicalData) {
      historicalData.historicalDataValue = null;
    }

    /*if(fieldValues.yieldResponse && (fieldValues.yieldResponse === isNaN || fieldValues.yieldResponse < 1 || fieldValues.yieldResponse > 300)) {
      this.setState ({
        yieldResponseError: true
      });
      return;
    }*/

    this.setState({
      acceptToSave: false,
      notAcceptToSave: false
    });

    let cut1DateValue = null;
    let cut2DateValue = null;
    let cut3DateValue = null;
    let cut4DateValue = null;
    cut1DateValue = cut1Date !== null ? format(cut1Date, 'yyyy-MM-dd') : null;
    if(disableGrain === false) {
      cut2DateValue = cut2Date !== null ? format(cut2Date, 'yyyy-MM-dd') : null;
      cut3DateValue = cut3Date !== null ? format(cut3Date, 'yyyy-MM-dd') : null;
      cut4DateValue = cut4Date !== null ? format(cut4Date, 'yyyy-MM-dd') : null;
    }
    let cutDatesValue = [];
    if(cut4DateValue !== null) {
      cutDatesValue = [cut1DateValue,cut2DateValue,cut3DateValue,cut4DateValue];
    } else {
      if(cut3DateValue !== null) {
        cutDatesValue = [cut1DateValue,cut2DateValue,cut3DateValue];
      } else {
        if(cut2DateValue !== null) {
          cutDatesValue = [cut1DateValue,cut2DateValue];
        } else {
          if(cut1DateValue !== null) {
            cutDatesValue = [cut1DateValue];
          }
        }
      }
    }
    fieldValues.cutDates = cutDatesValue;
    if(disableGrain === true) {
      fieldValues.harvests = 1;
    }
    yieldPlan.yieldCalculation = null;
    batchUpdate(fieldValues, activeFarm.farm.id);
  };

  setYieldGoal = (value) => {
    const { yieldCalculation, yieldPlan } = this.props;
    const { harvestValue } = this.state;
    this.setState({yieldGoalValue: value.target.value, manureError: false, manureAppliedError: false, yieldResponseError: false});
    if(harvestValue !== "" && harvestValue !== null) {
      this.setState({ showHarvest: false });
      var data = {
        "yieldGoal": value.target.value,
        "harvests": harvestValue
      }
      yieldPlan.yieldCalculation = null;
      yieldCalculation(data);
    }
  };

  setHarvest = (value) => {
    const { yieldCalculation, yieldPlan } = this.props;
    const { yieldGoalValue } = this.state;
    this.setState({harvestValue: value.value,  manureError: false, manureAppliedError: false, yieldResponseError: false});
    if(value.value === 1) {
      this.setState({ 
        cut1show: true,
        cut2show: false,
        cut3show: false,
        cut4show: false
      });
    } else if(value.value === 2) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: false,
        cut4show: false
      });
    } else if(value.value === 3) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: true,
        cut4show: false
      });
    } else if(value.value === 4) {
      this.setState({ 
        cut1show: true,
        cut2show: true,
        cut3show: true,
        cut4show: true
      });
    } else {
      this.setState({ 
        cut1show: true,
        cut2show: false,
        cut3show: false,
        cut4show: false
      });
    }
    if(yieldGoalValue !== "" && yieldGoalValue !== null) {
      this.setState({ showHarvest: false });
      var data = {
        "yieldGoal": yieldGoalValue,
        "harvests": value.value
      }
      yieldPlan.yieldCalculation = null;
      yieldCalculation(data);
    }
  };

  changeYieldValueByCalculation = (event) => {
    let { defaultYieldValue } = this.state;
    const items = {
      ...this.state.changedYieldValue
    };
    const splitArray = event.target.name.split('_');
    if(splitArray[0] === "yieldResponse") {
      items["yieldResponse"] = event.target.value;
    }
    if(splitArray[0] === "yiedHarvest") {
      let eventValue = 0;
      if(event.target.value === null || event.target.value === "" ) {
        eventValue = 0;
      } else {
        eventValue = event.target.value;
      }
      items["plannedYields"][splitArray[1]]["yield"] = event.target.value;
      if(!isNaN(eventValue)) {
        let percentValue = (eventValue * defaultYieldValue[1][splitArray[1]]["defaultYieldPercent"])/defaultYieldValue[1][splitArray[1]]["defaultYield"];      
        items["plannedYields"][splitArray[1]]["yieldPercent"] = this.roundToTwoDecimal(percentValue);
        this.calculateYieldTotal();
      }
    }
    if(splitArray[0] === "yiedHarvestPercent") {
      let eventValue = 0;
      if(event.target.value === null || event.target.value === "" ) {
        eventValue = 0;
      } else {
        eventValue = event.target.value;
      }
      items["plannedYields"][splitArray[1]]["yieldPercent"] = event.target.value;
      if(!isNaN(eventValue)) {
        let harvestValue = (eventValue * defaultYieldValue[1][splitArray[1]]["defaultYield"])/defaultYieldValue[1][splitArray[1]]["defaultYieldPercent"];
        items["plannedYields"][splitArray[1]]["yield"] = this.roundToTwoDecimal(harvestValue);
        this.calculateYieldTotal();
      }
    }
    this.setState({ changedYieldValue: items,  manureError: false, manureAppliedError: false, yieldResponseError: false });
  }

  calculateYield = () => {
    this.setState({ showHarvest: false });
    const { yieldCalculation, yieldPlan } = this.props;
    const { yieldGoalValue, harvestValue } = this.state;
    var data = {
      "yieldGoal": yieldGoalValue,
      "harvests": harvestValue
    }
    yieldPlan.yieldCalculation = null;
    yieldCalculation(data);
  }

  calculateYieldTotal = () => {
    let { changedYieldValue } = this.state;
    let sumValue = 0;
    let percentValue = 0;
    let yieldVal = 0;
    let yieldPercentVal = 0;
    changedYieldValue.plannedYields.map(changeValue => (
      //eslint-disable-next-line
      yieldVal = 0,
      yieldPercentVal = 0,
      (isNaN(changeValue.yield) || changeValue.yield === "") ? yieldVal = 0 : yieldVal = changeValue.yield,
      (isNaN(changeValue.yieldPercent) || changeValue.yieldPercent === "") ? yieldPercentVal = 0 : yieldPercentVal = changeValue.yieldPercent,
      sumValue = parseFloat(sumValue) + parseFloat(yieldVal),
      percentValue = parseFloat(percentValue) + parseFloat(yieldPercentVal)
    ));
    this.setState({ yieldSumValue: this.roundToTwoDecimal(sumValue), yieldPercentValue: this.roundToTwoDecimal(percentValue), 
      manureError: false, manureAppliedError: false, yieldResponseError: false });
  }

  onManureOptionChange = e => {
    const { change } = this.props;
    this.setState({
      manureApplied: e
    });
    change('manureApplied', e);
  };

  onFieldOptionChange = e => {
    const { change } = this.props;
    this.setState({
      fieldOptionApplied: e
    });
    change('fieldOptionApplied', e);
  };

  resetSeason = season => {
    const { change } = this.props;
    if(season === 1){
      change('spring_manureMethod', {value: '', label: ''});
      change('spring_manureStorage', {value: '', label: ''});
      change('spring_manureAmount', '');
    }
    if(season === 2){
      change('summer_manureMethod', {value: '', label: ''});
      change('summer_manureStorage', {value: '', label: ''});
      change('summer_manureAmount', '');
    }
    if(season === 3){
      change('fall_manureMethod', {value: '', label: ''});
      change('fall_manureStorage', {value: '', label: ''});
      change('fall_manureAmount', '');
    } 
  }

  calculateHistoricalData = () => {
    const { historicalDataCalculation, historicalData, activeFarm, paramId } = this.props;
    historicalData.historicalDataValue = null;
    this.setState({ setHistoricalData: false });
    historicalDataCalculation(activeFarm.farm.id, paramId);
  }

  onManureDataChange = (manureData, event) => {
    const { change } = this.props;
    if (manureData === 'method') {
      change('spring_manureMethod', event);
      change('summer_manureMethod', event);
      change('fall_manureMethod', event);
    }
    if (manureData === 'storage') {
      change('spring_manureStorage', event);
      change('summer_manureStorage', event);
      change('fall_manureStorage', event);
    }
    /*if (manureData === 'spring') {
      change('spring_manureStorage', null);
      change('spring_manureAmount', null);
      this.setState({
        springAdd: event
      });
    }
    if (manureData === 'summer') {
      change('summer_manureStorage', null);
      change('summer_manureAmount', null);
      this.setState({
        summerAdd: event
      });
    }
    if (manureData === 'fall') {
      change('fall_manureStorage', null);
      change('fall_manureAmount', null);
      this.setState({
        fallAdd: event
      });
    }*/
  };

  render() {
    const {
      handleSubmit,
      fieldDetails,
      intl,
      activeFarm,
      pageContent,
      showUpdateField,
      toggleUpdateCloseForm,
      manureNames,
      seasons,
      fieldMethod,
      fieldMasterData,
      hasSupportAdminRole,
      paramId
    } = this.props;
    const { errorMessage, isLoading, isBatchError, isBatchSuccess } = fieldDetails;
    const { 
      manureApplied,
      springAdd,
      summerAdd,
      fallAdd,
      showSoilSample,
      yieldCalculation,
      yieldGoalValue,
      harvestValue,
      showHarvest,
      changedYieldValue,
      yieldSumValue,
      yieldPercentValue,
      defaultYieldValue,
      showYieldPopup,
      showHarvestPopup,
      showYieldGoalPopup,
      showHarvestNullPopup,
      showYieldGoalNullPopup,
      yieldResponseError,
      fieldOptionApplied,
      cut1Date,
      cut2Date,
      cut3Date,
      cut4Date,
      disableGrain,
      stravRemovedApplied
    } = this.state;
    const disableCreateFieldButton = activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    const lblPage = pageContent[0];
    const seletedFieldData = this.getSelectedDetails();
    //this.isBatchError = false;
    //this.isBatchSuccess = false;
    return (
      <div className="field-batch-update">
        <ModalDialog
          show={showUpdateField}
          //onClose={toggleUpdateForm}
          width={{
            mobile: '95%',
            tablet: '95%',
            web: '95%'
          }}
          height={{
            mobile: '95%',
            tablet: '95%',
            web: '95%'
          }}
          closeIcon={<span />}
        >
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="field-batch-header">
              <p>
                {intl.formatMessage({ id: 'FIELDS' })} :{' '}
                <EmphasisLight>{this.getSelectedFieldNames(seletedFieldData)}</EmphasisLight>
              </p>
              <Field
                name="crop"
                controlId="crop"
                label={lblPage.labels.crop}
                disabled={false}
                styleName="select-box-common padding-bottom-15px"
                component={SelectControl}
                options={getDropDownFieldsStatuses(fieldMasterData.cropList)}
                onChange={e => this.onCropChange(e)}
                placeholder=""
              />
            </div>
            <ManureData
              lblPage={lblPage}
              onManureOptionChange={this.onManureOptionChange}
              onManureDataChange={this.onManureDataChange}
              manureApplied={manureApplied}
              springAdd={springAdd}
              summerAdd={summerAdd}
              fallAdd={fallAdd}
              manureNames={manureNames}
              intl={intl}
              showSoilSample={showSoilSample}
              yieldCalculation={yieldCalculation}
              seasons={seasons}
              fieldMethod={fieldMethod}
              fieldMasterData={fieldMasterData}
              yieldGoalValue={yieldGoalValue}
              harvestValue={harvestValue}
              setYieldGoal={this.setYieldGoal}
              setHarvest={this.setHarvest}
              calculateYield={this.calculateYield}
              showHarvest={showHarvest}
              changedYieldValue={changedYieldValue}
              yieldSumValue={yieldSumValue}
              yieldPercentValue={yieldPercentValue}
              changeYieldValueByCalculation={this.changeYieldValueByCalculation}
              defaultYieldValue={defaultYieldValue}
              yieldResponseError={yieldResponseError}
              fieldOptionApplied={fieldOptionApplied}
              onFieldOptionChange={this.onFieldOptionChange}
              calculateHistoricalData={this.calculateHistoricalData}
              cut1Date={cut1Date}
              cut2Date={cut2Date}
              cut3Date={cut3Date}
              cut4Date={cut4Date}
              cut1DateChange={this.cut1DateChange}
              cut2DateChange={this.cut2DateChange}
              cut3DateChange={this.cut3DateChange}
              cut4DateChange={this.cut4DateChange}
              paramId={paramId}
              disableGrain={disableGrain}
              stravRemovedApplied={stravRemovedApplied}
              onStravRemovedChange={this.onStravRemovedChange}
              cut1show={this.state.cut1show}
              cut2show={this.state.cut2show}
              cut3show={this.state.cut3show}
              cut4show={this.state.cut4show}
              resetSeason={this.resetSeason}
            />
            {showYieldPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showYieldPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_SUM_MODAL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span className="button-style-padding-right">
                      <ActionButton onClick={() => this.saveYieldPopUp()}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                    </span>
                    <span>
                      <ActionButton multi onClick={() => this.closeYieldPopUp()}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showYieldGoalNullPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showYieldGoalNullPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_GOAL_NULL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span className="button-style-padding-right">
                      <ActionButton onClick={() => this.saveNullPopUp()}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                    </span>
                    <span>
                      <ActionButton multi onClick={() => this.closeYieldGoalPopUp()}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showHarvestNullPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showHarvestNullPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'HARVEST_NULL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span className="button-style-padding-right">
                      <ActionButton onClick={() => this.saveNullPopUp()}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                    </span>
                    <span>
                      <ActionButton multi onClick={() => this.closeYieldGoalPopUp()}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showHarvestPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showHarvestPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_HARVEST_MODAL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span>
                      <ActionButton multi onClick={() => this.closeHarvestPopUp()}>{intl.formatMessage({ id: 'OK' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            {showYieldGoalPopup && (
              <ModalDialog
              minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
              width={{mobile: '70%', tablet: '35%', web: '25%'}}
              height={{mobile: '45%', tablet: '45%', web: '40%'}}
              show={showYieldGoalPopup}
              closeIcon=""
              onClose={() => {}}>
              <div className="text-align-center">
                <div>
                  <div className='fertilizer-form-style'>
                    <div>
                      <Text>{intl.formatMessage({ id: 'YIELD_GOAL_MODAL_TEXT' })}</Text>
                    </div>
                  </div>
                  <div className='fertilizer-form-style-button'>
                    <span>
        
                    <ActionButton multi onClick={() => this.closeYieldGoalPopUp()}>{intl.formatMessage({ id: 'OK' })}</ActionButton>
                    </span>
                  </div>
                </div>
              </div>
            </ModalDialog>
            )}
            <div className="update-field-btn">
              {yieldResponseError && (
                <ErrorText>{intl.formatMessage({ id: 'YIELD_RESPONSE_ERROR' })}</ErrorText>
              )}
            </div>
            <div className="field-form-submit-wrap">
              {isBatchError ? <ErrorText>{errorMessage?.data}</ErrorText> : null}

              {isBatchSuccess ? <div className="updated-div"></div> : null}
            </div>
            <div className="paddingLeftFieldsRight">
              <div className="bale-button-style">
                <span className="bale-list-cancel-button paddingLeftFieldsRightPadding" onClick={toggleUpdateCloseForm}>
                  {intl.formatMessage({ id: 'CANCEL' })}
                </span>
                <span className="paddingLeftFields">
                  <ActionButton 
                  disabled={isLoading || !disableCreateFieldButton}
                  onClick={() => this.handleSubmit}>
                    {intl.formatMessage({ id: 'AEF_SB' })}
                  </ActionButton>
                </span>
              </div>
            </div>
          </form>
        </ModalDialog>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { intl } = props;

  if (values.yieldGoal && !(values.yieldGoal >= 0 && values.yieldGoal <= 9999)) {
    errors.yieldGoal = intl.formatMessage({ id: 'invalid' });
  }

  if (values.clover && !(values.clover >= 0 && values.clover <= 100)) {
    errors.clover = intl.formatMessage({ id: 'invalid' });
  }

  if (values.manureApplied === true) {
    if (!values.method) {
      errors.method = intl.formatMessage({ id: 'invalid' });
    }
  }

  return errors;
}

const mapStateToProps = state => ({
  fieldDetails: state.fieldDetails,
  activeFarm: state.farmList.activeFarm,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'fieldPage']),
  manureNames: state.manureNames,
  yieldPlan: state.yieldPlan,
  historicalData: state.historicalData,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDispatchToProps(dispatch) {
  return {
    batchUpdate: (data, farmId) => dispatch(fieldActions.fieldBatchUpdate(data, farmId)),
    getManureNames: farmId => dispatch(fetchManureNames(farmId)),
    yieldCalculation: (data) => dispatch(YieldPlan.YieldCalculation(data)),
    historicalDataCalculation: (farmId, fieldId) => dispatch(HistoricalData.getHistoricalData(farmId, fieldId))
  };
}

export default injectIntl(
  reduxForm({
    form: 'FieldsUpdateForm',
    validate
  })(connect(mapStateToProps, mapDispatchToProps)(UpdateFieldsForm))
);
