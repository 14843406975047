import axios from 'axios';
import _ from 'lodash';
import {
  GET_VEHICLE_LIST,
  GET_VEHICLE_LIST_FAILURE,
  API_ENDPOINT,
  API_ENDPOINT_V3,
  GET_EQUIPMENT_LIST,
  GET_EQUIPMENT_LIST_FAILURE,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  STORE_TASK_DETAILS,
  GET_ENTITY_DETAILS_SUCCESS,
  GET_ENTITY_DETAILS_FAILURE,
  CREATE_ASSET_ENTITY_SUCCESS,
  CREATE_ASSET_ENTITY_FAILURE,
  SUBMIT_TASK_IS_LOADING,
  NULLFIY_SUCCESS,
  GET_ALL_ASSETS,
  GET_ALL_ASSETS_FAILURE,
  CREATE_ASSET_ENTITY_IS_LOADING,
  NULLIFY_CREATE_ASSET_ENTITY_SUCCESS,
  EDIT_TASK_INFO,
  GET_ASSET_ENTITY_IS_LOADING,
  GET_TASK_LIST,
  IS_GET_TASK_LIST_FAILURE,
  IS_GET_TASK_LIST_LOADING,
  UPDATE_TASK_SUCCESS,
  TASK_UPDATE_FAILURE,
  TASK_SORT_BY_OPTION,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  IS_DELETE_TASK_LOADING,
  DELETE_ASSET_IS_LOADING,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  TASK_LIST_POSITION,
  ASSET_SORT_BY_OPTION,
  NULLIFY_ASSET_ENTITY_DETAILS,
  TASK_LIST_DATA_RESET,
  TASK_LIST_DATA_CLEAR,  
  PUT,
  POST,
  GET,
  DELETE,
  COMPLETED
} from 'constants/index';
import { TASK } from 'constants/ApiUrl';
import { 
  GET_TASK_GPS, 
  GET_TASK_GPS_ERROR, 
  GET_TASK_GPS_LOADING,
  TASK_UPDATE_DURATION_LOADING,
  TASK_UPDATE_DURATION_SUCCESS,
  TASK_UPDATE_DURATION_FAILURE,
  TASK_COMPLETED_LOADING,
  TASK_COMPLETED_SUCCESS,
  TASK_COMPLETED_FAILURE
} from 'constants/store/Tasks';

import { GetLanguageCode } from 'utils/index';
import apiRequest from 'utils/ApiRequest';
import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_ADD_TASK,
  GA_ADD_TASK_ERR,
  GA_UPDATE_TASK,
  GA_DELETE_TASK,
  GA_TASK_LBL,
  GA_CAT_ERR,
  GA_CAT_ADD,
  GA_CAT_UPDATE,
  GA_CAT_DETELE,
  GA_ADD_ASSET,
  GA_ADD_ASSET_ERR,
  GA_ASSET_LBL,
  GA_COMPLETE_TASK
} from 'constants/GoogleAnalytics';

// Task list error
const taskListHasError = (bool, error) => ({
  type: IS_GET_TASK_LIST_FAILURE,
  hasError: bool,
  errorMessage: error
});

// Task list loading
const taskListIsLoading = bool => ({
  type: IS_GET_TASK_LIST_LOADING,
  isLoading: bool
});

// Task list loading
export const deleteInfoClear = isDeleted => ({
  type: TASK_LIST_DATA_RESET,
  isDeleted
});

// Task list loading
export const clearTasks = data => ({
  type: TASK_LIST_DATA_CLEAR,
  data
});

// Task list success
export const taskListSuccess = (data, pageIndex) => ({
  type: GET_TASK_LIST,
  data,
  pageIndex
});

// Task update assignee success
const updateAssigneeSuccess = data => ({
  type: UPDATE_TASK_SUCCESS,
  data
});

// Task update assignee faliure
const updateAssigneeFailure = data => ({
  type: TASK_UPDATE_FAILURE,
  data
});

// Task list sort by
export const taskListSortByOption = option => ({
  type: TASK_SORT_BY_OPTION,
  option
});

// Task list sort by
export const taskListPosition = option => ({
  type: TASK_LIST_POSITION,
  option
});

// Task delete success
const deleteTaskSucess = (data, deletedTasks) => ({
  type: DELETE_TASK_SUCCESS,
  data,
  deletedTasks
});

// Task delete failure
const deleteTaskFailure = data => ({
  type: DELETE_TASK_FAILURE,
  data
});

// Task delete loading
const isDeleteTaskLoading = bool => ({
  type: IS_DELETE_TASK_LOADING,
  isLoading: bool
});

export const getVehiclesSuccess = data => ({
  type: GET_VEHICLE_LIST,
  payload: data
});

export const getVehiclesFailure = (bool, errorMsg) => ({
  type: GET_VEHICLE_LIST_FAILURE,
  payload: { bool, errorMsg }
});

export const getEquipmentSuccess = data => ({
  type: GET_EQUIPMENT_LIST,
  payload: data
});

export const getEquipmentFailure = (bool, errorMsg) => ({
  type: GET_EQUIPMENT_LIST_FAILURE,
  payload: { bool, errorMsg }
});

export const createTaskSuccess = data => ({
  type: CREATE_TASK_SUCCESS,
  payload: data
});

export const createTaskFailure = (bool, errorMsg) => ({
  type: CREATE_TASK_FAILURE,
  payload: { bool, errorMsg }
});

export const getEntityDetailsSuccess = data => ({
  type: GET_ENTITY_DETAILS_SUCCESS,
  payload: data
});

export const getEntityDetailsFailure = (bool, errorMsg) => ({
  type: GET_ENTITY_DETAILS_FAILURE,
  payload: { bool, errorMsg }
});

export const createAssetEntitySuccess = (data, actionTriggered) => ({
  type: CREATE_ASSET_ENTITY_SUCCESS,
  payload: { data, actionTriggered }
});

export const createAssetEntityFailure = (bool, errorMsg) => ({
  type: CREATE_ASSET_ENTITY_FAILURE,
  payload: { bool, errorMsg }
});

export const submitTaskIsLoading = bool => ({
  type: SUBMIT_TASK_IS_LOADING,
  payload: bool
});

export const nullifySuccessFlag = () => ({
  type: NULLFIY_SUCCESS,
  payload: null
});

export const getAllAssetsSuccess = (data, category) => ({
  type: GET_ALL_ASSETS,
  payload: { data, category }
});

export const getAllAssetsFailure = (bool, errorMsg) => ({
  type: GET_ALL_ASSETS_FAILURE,
  payload: { bool, errorMsg }
});

export const createAssetEntityIsLoading = bool => ({
  type: CREATE_ASSET_ENTITY_IS_LOADING,
  payload: bool
});

export const getAssetEntityIsLoading = bool => ({
  type: GET_ASSET_ENTITY_IS_LOADING,
  payload: bool
});

// To nullify success flag for CreateAssetEntitySuccess
export const nullifyCreateAssetEntitySuccess = () => ({
  type: NULLIFY_CREATE_ASSET_ENTITY_SUCCESS,
  payload: null
});

// To store edit task info
export const editTask = obj => ({
  type: EDIT_TASK_INFO,
  payload: obj
});

// To maintain a flag for loader on delete asset
export const isDeleteAssetLoading = bool => ({
  type: DELETE_ASSET_IS_LOADING,
  payload: bool
});

// Asset deletion success
export const deleteAssetSuccess = bool => ({
  type: DELETE_ASSET_SUCCESS,
  payload: bool
});

// Asset deletion failure
export const deleteAssetFailure = (bool, errorMsg) => ({
  type: DELETE_ASSET_FAILURE,
  payload: { bool, errorMsg }
});

// Asset list sort
export const assetListSortByOption = (option, assetType) => ({
  type: ASSET_SORT_BY_OPTION,
  payload: { option, assetType }
});

// To nullify asset details
export const nullifyAssetEntityDetails = () => ({
  type: NULLIFY_ASSET_ENTITY_DETAILS,
  payload: null
});

export const clearTaskObj = () => ({
  type: 'CLEAR_TASK_OBJ'
});

/**
 * task gps data action
 * @param {object} data
 */
const taskGps = data => ({
  type: GET_TASK_GPS,
  payload: data
});

// on get gps error
const taskGpsError = error => ({
  type: GET_TASK_GPS_ERROR,
  payload: error
});

// on get gps loading
const isTaskGpsLoading = bool => ({
  type: GET_TASK_GPS_LOADING,
  payload: bool
});

/**
 * to get task list details
 * @param {string} farmId
 * @param {string} sortBy
 * @param {int} offset
 * @param {int} numberOfTasks
 */
export const fetchTaskList = (farmId, sortBy, offset, numberOfTasks, days, 
  statuses, activityIds, fieldIds) => async (dispatch,getState) => {
  dispatch(clearTaskObj());
  const { preferredLanguage } = getState().userDetails?.data;
  let taskListUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/offsets?language=${GetLanguageCode(
    preferredLanguage
  )}`;
  taskListUri = sortBy ? `${taskListUri}&sortField=${sortBy}` : taskListUri;
  taskListUri = numberOfTasks ? `${taskListUri}&pageSize=${numberOfTasks}` : taskListUri;
  taskListUri = offset ? `${taskListUri}&offset=${offset}` : taskListUri;
  taskListUri = days ? `${taskListUri}&days=${days}` : taskListUri;
  taskListUri = statuses ? `${taskListUri}&statuses=${statuses}` : taskListUri;
  taskListUri = activityIds ? `${taskListUri}&activityIds=${activityIds}` : taskListUri;
  taskListUri = fieldIds ? `${taskListUri}&fieldIds=${fieldIds}` : taskListUri;
  dispatch(taskListIsLoading(true));
  await dispatch(
    apiRequest({
      url: taskListUri,
      method: GET
    })
  )
    .then(response => {
      dispatch(taskListSuccess(response.data, offset || 0));
    })
    .catch(error => {
      dispatch(taskListHasError(true, error));
    });
};

/**
 * To delete the tasks
 * @param {array} tasks
 */
export const deleteTasks = (tasks, farmId) => async (dispatch, getState) => {
  dispatch(isDeleteTaskLoading(true));
  const { preferredLanguage } = getState().userDetails?.data;
  let taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/${tasks.toString()}?language=${GetLanguageCode(
    preferredLanguage
  )}`;
  await dispatch(
    apiRequest({
      url: taskUri,
      method: DELETE
    })
  )
    .then(response => {
      GAEvent(GA_DELETE_TASK, GA_CAT_DETELE, GA_TASK_LBL);
      dispatch(deleteTaskSucess(response, tasks));
    })
    .catch(error => {
      dispatch(deleteTaskFailure(true, error));
    });
};

/**
 * Create and update the tasks
 * @param {object} data
 * @param {string} type
 */
export const createUpdateTask = (data, type, taskComplete, farmId, status, batch) => async (dispatch, getState) => {
  dispatch(type === 'assign' ? taskListIsLoading(true) : submitTaskIsLoading(true));
  const { preferredLanguage } = getState().userDetails?.data;
  let method = "";
  let taskUri = "";
  if(type === 'create') {
    if(taskComplete) {
      taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/fields/end?language=${GetLanguageCode(
        preferredLanguage
      )}`;
    } else {
      taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks?language=${GetLanguageCode(
        preferredLanguage
      )}`;
    }
    method = POST;
  } else {
    if(taskComplete) {
      if(batch) {
        taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/batch?language=${GetLanguageCode(
          preferredLanguage
        )}`;
        method = POST;
      } else {
        taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/fields/end?language=${GetLanguageCode(
          preferredLanguage
        )}`;
        method = POST;
      }
    } else {
      taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/fields/update?language=${GetLanguageCode(
        preferredLanguage
      )}`;
      method = PUT;
    }
  }
  await dispatch(
    apiRequest({
      url: taskUri,
      data,
      method
    })
  )
    .then(response => {
      if (type === 'assign') {
        GAEvent(GA_UPDATE_TASK, GA_CAT_UPDATE, GA_TASK_LBL);
        dispatch(updateAssigneeSuccess(response.data));
      } else {
        if (method === POST) {
          GAEvent(GA_ADD_TASK, GA_CAT_ADD, GA_TASK_LBL);
        } else {
          GAEvent(GA_UPDATE_TASK, GA_CAT_UPDATE, GA_TASK_LBL);
        }
        if(taskComplete) {
          if (data && data.status === COMPLETED) {
            GAEvent(GA_COMPLETE_TASK, GA_CAT_UPDATE, GA_TASK_LBL);
          }
        } else {
          if (data && data.status === COMPLETED) {
            GAEvent(GA_COMPLETE_TASK, GA_CAT_UPDATE, GA_TASK_LBL);
          }
        }
        dispatch(createTaskSuccess(response.data));
        dispatch(submitTaskIsLoading(false));
      }
    })
    .catch(error => {
      if (type === 'assign') {
        dispatch(updateAssigneeFailure(error));
      } else {
        if (method === POST) {
          GAEvent(GA_ADD_TASK_ERR, GA_CAT_ERR, GA_TASK_LBL);
        }
        if(taskComplete) {
          dispatch(createTaskFailure(true, error));
        } else {
          if(error && error.message) {
            dispatch(createTaskFailure(true, error.message));
          } else {
            dispatch(createTaskFailure(true, error));
          }
        }
        dispatch(submitTaskIsLoading(false));
      }
    });
};

/**
 * To get capured gps points of the seleted task
 * @param {string} taskId
 */
export const fetchTaskGpsPoints = taskId => async dispatch => {
  dispatch(isTaskGpsLoading(true));
  await dispatch(
    apiRequest({
      url: `${TASK}/${taskId}/gpslocations`,
      method: GET
    })
  )
    .then(response => {
      dispatch(taskGps(response.data));
    })
    .catch(error => {
      dispatch(taskGpsError(error));
    });
};

export const getVehicles = (token, farmId) => async dispatch => {
  try {
    await axios(`${API_ENDPOINT}assets/getAssetType/vehicle/${farmId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        let vehicleList = response.data;
        if (vehicleList.length >= 1) {
          vehicleList = _.orderBy(vehicleList, [data => data.typeName.toLowerCase()], ['asc']);
        }
        dispatch(getVehiclesSuccess(vehicleList));
      })
      .catch(error => {
        dispatch(getVehiclesFailure(true, error));
      });
  } catch (error) {
    dispatch(getVehiclesFailure(true, error));
  }
};

export const getEquipment = (token, farmId) => async dispatch => {
  try {
    await axios(`${API_ENDPOINT}assets/getAssetType/equipment/${farmId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        let equipmentList = response.data;
        if (equipmentList.length >= 1) {
          equipmentList = _.orderBy(equipmentList, [data => data.typeName.toLowerCase()], ['asc']);
        }
        dispatch(getEquipmentSuccess(equipmentList));
      })
      .catch(error => {
        dispatch(getEquipmentFailure(true, error));
      });
  } catch (error) {
    dispatch(getEquipmentFailure(true, error));
  }
};

export const savetaskInfo = obj => ({
  type: STORE_TASK_DETAILS,
  payload: obj
});

// To get Asset Entity Details for a given typeId
export const getAssetEntityDetails = (typeId, farmId, token) => async dispatch => {
  try {
    dispatch(getAssetEntityIsLoading(true));
    await axios(`${API_ENDPOINT}assets/getAssetEntity/${farmId}/${typeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        dispatch(getAssetEntityIsLoading(false));
        dispatch(getEntityDetailsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getAssetEntityIsLoading(false));
        dispatch(getEntityDetailsFailure(true, error));
      });
  } catch (error) {
    dispatch(getEntityDetailsFailure(true, error));
  }
};

// To create asset entity
export const createAssetEntity = (data, farmId, token, action) => async dispatch => {
  try {
    dispatch(createAssetEntityIsLoading(true));
    await axios(`${API_ENDPOINT}assets/${action === 'create' ? 'create' : 'update'}/${farmId}`, {
      method: action === 'create' ? 'post' : 'put',
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        GAEvent(GA_ADD_ASSET, GA_CAT_ADD, GA_ASSET_LBL);
        dispatch(createAssetEntitySuccess(response.data, action));
        dispatch(createAssetEntityIsLoading(false));
      })
      .catch(error => {
        GAEvent(GA_ADD_ASSET_ERR, GA_CAT_ERR, GA_ASSET_LBL);
        dispatch(createAssetEntityFailure(true, error.message));
        dispatch(createAssetEntityIsLoading(false));
      });
  } catch (error) {
    dispatch(createAssetEntityFailure(true, error.message));
  }
};

// To nullify the success flag on create task
export const nullifySuccess = () => dispatch => {
  dispatch(nullifySuccessFlag());
};

// To get all assets for vehicle and equipment
export const getAllAssets = (token, category, farmId) => async dispatch => {
  try {
    await axios(`${API_ENDPOINT}farms/${farmId}/allassets/${category}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        let allAssetsList = response.data;
        if (allAssetsList.length >= 1) {
          allAssetsList = _.orderBy(
            allAssetsList,
            [data => data.asset.model.toLowerCase()],
            ['asc']
          );
        }
        dispatch(getAllAssetsSuccess(allAssetsList, category));
      })
      .catch(error => {
        dispatch(getAllAssetsFailure(true, error));
      });
  } catch (error) {
    dispatch(getAllAssetsFailure(true, error));
  }
};

export const deleteAsset = (farmId, assetId, token) => async dispatch => {
  try {
    dispatch(isDeleteAssetLoading(true));
    await axios(`${API_ENDPOINT}farms/${farmId}/assets/${assetId}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        if (response.status === 204) {
          dispatch(deleteAssetSuccess(true));
        }
      })
      .catch(error => {
        dispatch(deleteAssetFailure(true, error));
      });
  } catch (error) {
    dispatch(deleteAssetFailure(true, error));
  }
};

export let taskUpdateDuration = (farmId, taskId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_UPDATE_DURATION_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/${taskId}?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: taskUri,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: TASK_UPDATE_DURATION_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: TASK_UPDATE_DURATION_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: TASK_UPDATE_DURATION_FAILURE, payload: error });
    }
};

export let selectedTaskCompleted = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_COMPLETED_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let taskUri = `${API_ENDPOINT_V3}farms/${farmId}/tasks/end/batch?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: taskUri,
        data,
        method: POST
      })
    )
      .then(response => {
        dispatch({ type: TASK_COMPLETED_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: TASK_COMPLETED_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: TASK_COMPLETED_FAILURE, payload: error });
    }
};
