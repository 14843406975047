import * as React from 'react';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';
import ExpandingList from '@tine/lib-frontend-components/components/accordion/expanding-list';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import { AddIcon } from '@tine/lib-frontend-components/elements/icons';

function getMineralFertilizerHeader(intl) {
  const mineral_fertilizer_heading = [
    {
      rowId: 0,
      mainEntry: (
        <div className="fertilization-plan-grid --grid-padding-large --grid-layout">
          <div></div>
          <div>
            <EmphasisLight>{intl.formatMessage({ id: 'NITROGEN' })}</EmphasisLight>
          </div>
          <div>
            <EmphasisLight>{intl.formatMessage({ id: 'PHOSPHORUS' })}</EmphasisLight>
          </div>
          <div>
            <EmphasisLight>{intl.formatMessage({ id: 'POTASSIUM' })}</EmphasisLight>
          </div>
        </div>
      )
    }
  ];
  return mineral_fertilizer_heading;
}

function getMineralFertilizerNeed(seasonFertilizationItem, intl) {
  let mineral_fertilizer_need = [];
  if (seasonFertilizationItem.needForSeason !== null) {
    mineral_fertilizer_need = [
      {
        rowId: 1,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'NEED' })}:</div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.needForSeason.nitrogenNeed.toFixed(1)}{' '}
              {seasonFertilizationItem.needForSeason.nutrientUnitsNeed}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.needForSeason.phosphorusNeed.toFixed(1)}{' '}
              {seasonFertilizationItem.needForSeason.nutrientUnitsNeed}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.needForSeason.potassiumNeed.toFixed(1)}{' '}
              {seasonFertilizationItem.needForSeason.nutrientUnitsNeed}
            </div>
          </div>
        )
      }
    ];
  }
  return mineral_fertilizer_need;
}

function getMineralFertilizerAdded(seasonFertilizationItem, intl) {
  let residualData = getMineralFertilizerResidual(seasonFertilizationItem, intl);
  let manureData = getManureDataActual(seasonFertilizationItem, intl);
  let mineralFertilizerData = getMineralFertilizerDataActual(seasonFertilizationItem);
  let mineral_fertilizer_added_sub_entries = [];
  mineral_fertilizer_added_sub_entries = [
    <div>
      {residualData}
      {manureData}
      {mineralFertilizerData}
    </div>
  ];
  let mineral_fertilizer_added = [];
  if (seasonFertilizationItem.addedForSeasonActual !== null) {
    mineral_fertilizer_added = [
      {
        rowId: 2,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'FERTILIZATION_ACTUAL_ADDED' })}:</div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.addedForSeasonActual.nitrogenAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeasonActual.nutrientUnitsAdded}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.addedForSeasonActual.phosphorusAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeasonActual.nutrientUnitsAdded}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.addedForSeasonActual.potassiumAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeasonActual.nutrientUnitsAdded}
            </div>
          </div>
        ),
        additionalEntries: mineral_fertilizer_added_sub_entries
      }
    ];
  }
  return mineral_fertilizer_added;
}

function getMineralFertilizerPlannedAdded(seasonFertilizationItem, intl) {
  let residualData = getMineralFertilizerResidual(seasonFertilizationItem, intl);
  let manureData = getManureData(seasonFertilizationItem, intl);
  let mineralFertilizerData = getMineralFertilizerData(seasonFertilizationItem);
  let mineral_fertilizer_added_sub_entries = [];
  mineral_fertilizer_added_sub_entries = [
    <div>
      {residualData}
      {manureData}
      {mineralFertilizerData}
    </div>
  ];
  let mineral_fertilizer_added = [];
  if (seasonFertilizationItem.addedForSeason !== null) {
    mineral_fertilizer_added = [
      {
        rowId: 2,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'FERTILIZATION_PLANNED_ADDED' })}:</div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.addedForSeason.nitrogenAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeason.nutrientUnitsAdded}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.addedForSeason.phosphorusAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeason.nutrientUnitsAdded}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.addedForSeason.potassiumAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeason.nutrientUnitsAdded}
            </div>
          </div>
        ),
        additionalEntries: mineral_fertilizer_added_sub_entries
      }
    ];
  }
  return mineral_fertilizer_added;
}

function getMineralFertilizerResidual(seasonFertilizationItem, intl) {
  let mineral_fertilizer_residual = '';
  if (seasonFertilizationItem.residualForSeason !== null) {
    mineral_fertilizer_residual = (
      <div className="fertilization-plan-grid --border-thin-blue --grid-padding-low --grid-layout">
        <div>
          <EmphasisLight size="small">
            {intl.formatMessage({ id: 'RESIDUAL' })}
          </EmphasisLight>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.residualForSeason.nitrogenResidual.toFixed(1)}{' '}
            {seasonFertilizationItem.residualForSeason.nutrientUnitsResidual}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.residualForSeason.phosphorusResidual.toFixed(1)}{' '}
            {seasonFertilizationItem.residualForSeason.nutrientUnitsResidual}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.residualForSeason.potassiumResidual.toFixed(1)}{' '}
            {seasonFertilizationItem.residualForSeason.nutrientUnitsResidual}
          </MetaInformation>
        </div>
      </div>
    );
  }
  return mineral_fertilizer_residual;
}

function getMineralFertilizerPlannedDifference(seasonFertilizationItem, intl) {
  let mineral_fertilizer_difference = [];
  if (seasonFertilizationItem.shortfallForSeason !== null) {
    mineral_fertilizer_difference = [
      {
        rowId: 4,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'DIFFERENCE' })}:</div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.shortfallForSeason.nitrogenShortfall.toFixed(1)}{' '}
              {seasonFertilizationItem.shortfallForSeason.nutrientUnitsShortfall}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.shortfallForSeason.phosphorusShortfall.toFixed(1)}{' '}
              {seasonFertilizationItem.shortfallForSeason.nutrientUnitsShortfall}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.shortfallForSeason.potassiumShortfall.toFixed(1)}{' '}
              {seasonFertilizationItem.shortfallForSeason.nutrientUnitsShortfall}
            </div>
          </div>
        )
      }
    ];
  }
  return mineral_fertilizer_difference;
}

function getMineralFertilizerDifference(seasonFertilizationItem, intl) {
  let mineral_fertilizer_difference = [];
  if (seasonFertilizationItem.shortfallForSeasonActual !== null) {
    mineral_fertilizer_difference = [
      {
        rowId: 4,
        mainEntry: (
          <div className="fertilization-plan-grid --grid-padding-low --grid-layout">
            <div className="fertilizer-text-normal">{intl.formatMessage({ id: 'DIFFERENCE' })}:</div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.shortfallForSeasonActual.nitrogenShortfall.toFixed(1)}{' '}
              {seasonFertilizationItem.shortfallForSeasonActual.nutrientUnitsShortfall}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.shortfallForSeasonActual.phosphorusShortfall.toFixed(1)}{' '}
              {seasonFertilizationItem.shortfallForSeasonActual.nutrientUnitsShortfall}
            </div>
            <div className="fertilizer-text-normal">
              {seasonFertilizationItem.shortfallForSeasonActual.potassiumShortfall.toFixed(1)}{' '}
              {seasonFertilizationItem.shortfallForSeasonActual.nutrientUnitsShortfall}
            </div>
          </div>
        )
      }
    ];
  }
  return mineral_fertilizer_difference;
}

function getMineralFertilizerData(seasonFertilizationItem) {
  if (seasonFertilizationItem.mineralFertilizersForSeason?.length > 0) {
    return seasonFertilizationItem.mineralFertilizersForSeason.map(mineralFertilizerItem => (
      <div
        key={mineralFertilizerItem.nitrogen}
        className="fertilization-plan-grid --border-thin-grey --grid-padding-low --grid-layout"
      >
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {mineralFertilizerItem.customName === null
              ? mineralFertilizerItem.originalName
              : mineralFertilizerItem.customName}
          </EmphasisLight>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.nitrogen.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.phosphorus.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.potassium.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
      </div>
    ));
  }
}

function getMineralFertilizerDataActual(seasonFertilizationItem) {
  if (seasonFertilizationItem.mineralFertilizersForSeasonActual?.length > 0) {
    return seasonFertilizationItem.mineralFertilizersForSeasonActual.map(mineralFertilizerItem => (
      <div
        key={mineralFertilizerItem.nitrogen}
        className="fertilization-plan-grid --border-thin-grey --grid-padding-low --grid-layout"
      >
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {mineralFertilizerItem.customName === null
              ? mineralFertilizerItem.originalName
              : mineralFertilizerItem.customName}
          </EmphasisLight>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.nitrogen.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.phosphorus.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {mineralFertilizerItem.potassium.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </MetaInformation>
        </div>
      </div>
    ));
  } else {
    return seasonFertilizationItem.mineralFertilizersForSeason.map(mineralFertilizerItem => (
      <div
        key={mineralFertilizerItem.originalName}
        className="fertilization-plan-grid --border-thin-grey --grid-padding-low --grid-layout"
      >
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {mineralFertilizerItem.customName === null
              ? mineralFertilizerItem.originalName
              : mineralFertilizerItem.customName}
          </EmphasisLight>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    ));
  }
}

function getManureData(seasonFertilizationItem, intl) {
  let manureData = '';
  if (seasonFertilizationItem.manureForSeason !== null) {
    manureData = (
      <div className="fertilization-plan-grid --border-thin-blue --grid-padding-low --grid-layout">
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {intl.formatMessage({ id: 'MANURE' })}
            {/*{seasonFertilizationItem.manureForSeason.manureName}*/}
          </EmphasisLight>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.manureForSeason.manureNitrogen.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.manureForSeason.manurePhosphorus.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.manureForSeason.manurePotassium.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
      </div>
    );
  }
  return manureData;
}

function getManureDataActual(seasonFertilizationItem, intl) {
  let manureData = '';
  if (seasonFertilizationItem.manureForSeasonActual !== null) {
    manureData = (
      <div className="fertilization-plan-grid --border-thin-blue --grid-padding-low --grid-layout">
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {intl.formatMessage({ id: 'MANURE' })}
            {/*{seasonFertilizationItem.manureForSeasonActual.manureName}*/}
          </EmphasisLight>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.manureForSeasonActual.manureNitrogen.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.manureForSeasonActual.manurePhosphorus.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
        <div>
          <MetaInformation size="small">
            {seasonFertilizationItem.manureForSeasonActual.manurePotassium.toFixed(1)} kg/daa
          </MetaInformation>
        </div>
      </div>
    );
  }
  return manureData;
}

function getManureTotalContent(
  fieldId,
  seasonFertilizationItem,
  updateSeasonManure,
  viewFertilizerPlan,
  isLoading,
  intl
) {
  let manureData = '';
  if (seasonFertilizationItem.manureForSeason !== null) {
    let manureTextName =
      seasonFertilizationItem.seasonId +
      '_' +
      fieldId +
      '_' +
      seasonFertilizationItem.manureForSeason.manureSeasonId;
    let budgetedTextValue = seasonFertilizationItem.consolidatedManureForSeason ? seasonFertilizationItem.consolidatedManureForSeason.budgetedQuantity : 0;
    let actualTextValue = seasonFertilizationItem.consolidatedManureForSeason ? seasonFertilizationItem.consolidatedManureForSeason.actualQuantity : 0;
    let manureCustomTextValue = seasonFertilizationItem.manureForSeason.manureCustomQuantity ? seasonFertilizationItem.manureForSeason.manureCustomQuantity.toFixed(1) : (seasonFertilizationItem.manureForSeason.manureOriginalQuantity ? seasonFertilizationItem.manureForSeason.manureOriginalQuantity.toFixed(1) : 0);
    manureData = (
      <div className="fertilization-plan-grid --grid-padding-extralarge --grid-layout-season-menu">
        <div className="fertilizer-align-center text-capitalize">
          {/*<EmphasisLight>{seasonFertilizationItem.manureForSeason.manureName}</EmphasisLight>*/}
          <EmphasisLight>{intl.formatMessage({ id: 'MANURE' })}</EmphasisLight>
        </div>
        <div className="fertilizer-text-normal padding-top-low-textbox">
          <div>
            <EmphasisLight>
              {actualTextValue === 0 ?
              <span>&nbsp;</span> :
              <span>
                {actualTextValue} {seasonFertilizationItem.consolidatedManureForSeason && seasonFertilizationItem.consolidatedManureForSeason.quantityUnits}
              </span>}
            </EmphasisLight>
          </div>
          {seasonFertilizationItem.consolidatedManureForSeason ?
          <div className="fertilizer-text-normal-light">
            {actualTextValue !== 0 ? Math.round(seasonFertilizationItem.consolidatedManureForSeason.actualTotalQuantity) : ''}{' '}
            {actualTextValue !== 0 ? seasonFertilizationItem.consolidatedManureForSeason.totalQuantityUnits : ''} 
            {actualTextValue !== 0 && <span className="text-lowercase">{intl.formatMessage({ id: 'TOTAL' })}</span>}
          </div>
           :
          <div className="fertilizer-text-normal-light">&nbsp;</div>
          }
        </div>
        {actualTextValue !== 0 ?
        <div>
          {seasonFertilizationItem.consolidatedManureForSeason.operator === "<" &&
          <div className="arrow-greater-green fetlization-menu-arrow fetlization-menu-arrow-padding">
            {seasonFertilizationItem.consolidatedManureForSeason.operator}</div>}
          {seasonFertilizationItem.consolidatedManureForSeason.operator === ">" &&
          <div className="arrow-lesser-red fetlization-menu-arrow fetlization-menu-arrow-padding">
            {seasonFertilizationItem.consolidatedManureForSeason.operator}</div>}
          {seasonFertilizationItem.consolidatedManureForSeason.operator === "=" &&
          <div className="arrow-equal-black fetlization-menu-arrow fetlization-menu-arrow-padding">
            {seasonFertilizationItem.consolidatedManureForSeason.operator}</div>}
          {(seasonFertilizationItem.consolidatedManureForSeason.operator === "" || 
          seasonFertilizationItem.consolidatedManureForSeason.operator === null) &&
          <div className="fetlization-menu-arrow fetlization-menu-arrow-padding"></div>}
        </div> :
        <div>&nbsp;</div>}
        <div className="fertilizer-text-normal">
          <div>
            <span>
              <input
                className="input-textbox"
                onChange={e => updateSeasonManure(e)}
                id={manureTextName}
                name={manureTextName}
                type="text"
                defaultValue={budgetedTextValue !== 0 ? budgetedTextValue : manureCustomTextValue}
                disabled={viewFertilizerPlan || isLoading}
              />
            </span>
            <span className="input-textbox-unit">
              {seasonFertilizationItem.consolidatedManureForSeason ? seasonFertilizationItem.consolidatedManureForSeason.quantityUnits : seasonFertilizationItem.manureForSeason.manureQuantityUnits}
            </span>
          </div>
          <div>
            <EmphasisLight size="small">
              {seasonFertilizationItem.consolidatedManureForSeason ? Math.round(seasonFertilizationItem.consolidatedManureForSeason.budgetedTotalQuantity) : Math.round(seasonFertilizationItem.manureForSeason.manureCustomTotalQuantity)}{' '}
              {seasonFertilizationItem.consolidatedManureForSeason ? seasonFertilizationItem.consolidatedManureForSeason.totalQuantityUnits : seasonFertilizationItem.manureForSeason.manureTotalQuantityUnits} <span className="text-lowercase">{intl.formatMessage({ id: 'TOTAL' })}</span>
            </EmphasisLight>
          </div>
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }
  return manureData;
}

function getManureCompleted(
  fieldId,
  updateCompleted,
  seasonFertilizationItem,
  viewFertilizerPlan,
  isLoading,
  intl
) {
  let manureData = '';
  let editValueId =
    seasonFertilizationItem.seasonId + '~~' + fieldId + '~~' + !seasonFertilizationItem.completed;
  manureData = (
    <div className="fertilization-plan-grid --grid-padding-extralarge-commenting-checkbox --grid-layout-menu-completed">
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div className="--grid-padding-left">
          <span className="location-type-checkbox-right location-type-checkbox-width">
            <CheckboxButton
              id={editValueId}
              label={editValueId}
              labelText=""
              stlyeClass="font-style-div"
              disabled={viewFertilizerPlan || isLoading}
              checked={seasonFertilizationItem.completed}
              onChange={e => updateCompleted(e)}
            />
          </span>
          <span className="location-type-checkbox-right padding-completed">
            {intl.formatMessage({ id: 'FERTILIZATION_COMPLETED_CHECKBOX' })}
          </span>
      </div>
    </div>
  );
  return manureData;
}

function getManureHeading(intl) {
  let manureData = '';
  manureData = (
    <div className="fertilization-plan-grid --grid-padding-extralarge-checkbox-heading --grid-layout-season-menu --border-thick-grey">
      <div className="text-capitalize">&nbsp;</div>
      <div className="--grid-item-left">
        {intl.formatMessage({ id: 'FERTILIZATION_COMPLETED_CHECKBOX_HEADING2' })}
      </div>
      <div>&nbsp;</div>
      <div className="--grid-item-left">
        {intl.formatMessage({ id: 'FERTILIZATION_COMPLETED_CHECKBOX_HEADING1' })}
      </div>
      <div className="text-capitalize">&nbsp;</div>
    </div>
  );
  return manureData;
}

function getMineralFertilizerTotalCount(
  fieldId,
  seasonFertilizationItem,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer
) {
  if (seasonFertilizationItem.consolidatedMineralFertilizersForSeason?.length > 0) {
    return (seasonFertilizationItem.consolidatedMineralFertilizersForSeason.map((mineralFertilizerItem, index) =>
      getMineralFertilizerTextBox(
        fieldId,
        seasonFertilizationItem,
        mineralFertilizerItem,
        updateMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        index,
        isLoading,
        intl,
        addingFertilizer
      )
    )
  )} else {
    return (seasonFertilizationItem.mineralFertilizersForSeason.map((mineralFertilizerItem, index) =>
      getMineralFertilizerManureTextBox(
        fieldId,
        seasonFertilizationItem,
        mineralFertilizerItem,
        updateMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        index,
        isLoading,
        intl,
        addingFertilizer
      )
    )
  )}
}

function getMineralFertilizerTextBox(
  fieldId,
  seasonFertilizationItem,
  mineralFertilizerItem,
  updateMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  index,
  isLoading,
  intl,
  addingFertilizer
) {
  let seasonFertilizerName = seasonFertilizationItem.seasonId + '_' + index;
  let editValueName =
    seasonFertilizationItem.seasonId +
    '~~' +
    fieldId +
    '~~' +
    (mineralFertilizerItem.name);
  let editValueId =
    seasonFertilizationItem.seasonId + '~~' + fieldId + '~~' + index;
  let actualQuantityValue = mineralFertilizerItem.actualQuantity === null ? 0 : mineralFertilizerItem.actualQuantity;
  return (
    <div
      key={seasonFertilizerName}
      className="fertilization-plan-grid --border-thin-grey --grid-content-padding-extralarge --grid-layout-season-menu"
    >
      <div className="fertilizer-align-center">
        <div>
          <span className="mineral-fertilizer-text-bold text-capitalize">
            {mineralFertilizerItem.name}
          </span>
        </div>
      </div>
      <div className="fertilizer-text-normal padding-top-low-textbox">
        {mineralFertilizerItem.actualQuantity === null || mineralFertilizerItem.actualQuantity === 0 ?
        <div>&nbsp;</div> :
        <div>
          <EmphasisLight>
            <span>
              {mineralFertilizerItem.actualQuantity === null ? 0 : mineralFertilizerItem.actualQuantity} {mineralFertilizerItem.quantityUnits}
            </span>
          </EmphasisLight>
        </div>}
        {Math.round(mineralFertilizerItem.actualTotalQuantity) === 0 ?
        <div>&nbsp;</div> :
        <div className="fertilizer-text-normal-light">
          {Math.round(mineralFertilizerItem.actualTotalQuantity)}{' '}
          {mineralFertilizerItem.totalQuantityUnits} <span className="text-lowercase">{intl.formatMessage({ id: 'TOTAL' })}</span>
        </div>}
      </div>
      {(actualQuantityValue !== 0 || actualQuantityValue !== 0.0) ?
        <div>
        {mineralFertilizerItem.operator === '<' &&
        <div className="arrow-greater-green fetlization-menu-arrow fetlization-menu-arrow-padding">
          {mineralFertilizerItem.operator}</div>}
        {mineralFertilizerItem.operator === '>' &&
        <div className="arrow-lesser-red fetlization-menu-arrow fetlization-menu-arrow-padding">
          {mineralFertilizerItem.operator}</div>}
        {(mineralFertilizerItem.operator === '' || 
        mineralFertilizerItem.operator === null || mineralFertilizerItem.operator === '=') &&
        <div className="fetlization-menu-arrow fetlization-menu-arrow-padding"></div>} 
        </div> : <div>&nbsp;</div>}
      {addingFertilizer[editValueId] !== 0 ?
      <div className="fertilizer-text-normal">
        <div>
          <span>
            <input
              className="input-textbox"
              onChange={e => updateSeasonFertilizer(e)}
              id={editValueId}
              name={editValueName}
              type="text"
              value={addingFertilizer[editValueId]}
              //defaultValue={mineralFertilizerItem.customQuantity === 0 ? mineralFertilizerItem.originalQuantity.toFixed(1) : mineralFertilizerItem.customQuantity.toFixed(1)}
              disabled={viewFertilizerPlan || isLoading}
            />
          </span>
          <span className="input-textbox-unit">{mineralFertilizerItem.quantityUnits}</span>
        </div>
        <div>
          <EmphasisLight size="small">
            {Math.round(mineralFertilizerItem.plannedTotalQuantity)}{' '}
            {mineralFertilizerItem.totalQuantityUnits} <span className="text-lowercase">{intl.formatMessage({ id: 'TOTAL' })}</span>
          </EmphasisLight>
        </div>
      </div> : <div className="fertilizer-text-normal">&nbsp;</div>} 
      {addingFertilizer[editValueId] !== 0 ?
      <div className="padding-top-size pad-right">
        {!viewFertilizerPlan && (
          <div>
            <span className="mineral-fertilizer-text-right fertilizer-padding-left-low">
              <i
                className="icon-edit mineral-fertilizer-edit-border"
                name={editValueName}
                onClick={e => updateMineralFertilizer(e)}
              ></i>
            </span>
            <span className="mineral-fertilizer-text-right">
              <i 
                className="icon-trash mineral-fertilizer-edit-border" 
                name={editValueName}
                onClick={e =>deleteMineralFertilizer(e)}
              ></i>
            </span>
          </div>
        )}
      </div> : <div className="padding-top-size pad-right">&nbsp;</div>}
    </div>);
}

function getMineralFertilizerManureTextBox(
  fieldId,
  seasonFertilizationItem,
  mineralFertilizerItem,
  updateMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  index,
  isLoading,
  intl,
  addingFertilizer
) {
  let seasonFertilizerName = seasonFertilizationItem.seasonId + '_' + index;
  let editValueName =
    seasonFertilizationItem.seasonId +
    '~~' +
    fieldId +
    '~~' +
    (mineralFertilizerItem.originalName);
  let editValueId =
    seasonFertilizationItem.seasonId + '~~' + fieldId + '~~' + index;
  return (
    <div
      key={seasonFertilizerName}
      className="fertilization-plan-grid --border-thin-grey --grid-content-padding-extralarge --grid-layout-season-menu"
    >
      <div className="fertilizer-align-center">
        <div>
          <span className="mineral-fertilizer-text-bold text-capitalize">
            {mineralFertilizerItem.originalName}
          </span>
        </div>
      </div>
      <div className="fertilizer-text-normal padding-top-low-textbox">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
      <div>&nbsp;</div>
      {addingFertilizer[editValueId] !== 0 ?
      <div className="fertilizer-text-normal">
        <div>
          <span>
            <input
              className="input-textbox"
              onChange={e => updateSeasonFertilizer(e)}
              id={editValueId}
              name={editValueName}
              type="text"
              value={addingFertilizer[editValueId]}
              //defaultValue={mineralFertilizerItem.customQuantity === 0 ? mineralFertilizerItem.originalQuantity.toFixed(1) : mineralFertilizerItem.customQuantity.toFixed(1)}
              disabled={viewFertilizerPlan || isLoading}
            />
          </span>
          <span className="input-textbox-unit">{mineralFertilizerItem.quantityUnits}</span>
        </div>
        <div>
          <EmphasisLight size="small">
            {Math.round(mineralFertilizerItem.customTotalQuantity)}{' '}
            {mineralFertilizerItem.totalQuantityUnits} <span className="text-lowercase">{intl.formatMessage({ id: 'TOTAL' })}</span>
          </EmphasisLight>
        </div>
      </div> : <div className="fertilizer-text-normal">&nbsp;</div>} 
      {addingFertilizer[editValueId] !== 0 ?
      <div className="padding-top-size pad-right">
        {!viewFertilizerPlan && (
          <div>
            <span className="mineral-fertilizer-text-right fertilizer-padding-left-low">
              <i
                className="icon-edit mineral-fertilizer-edit-border"
                name={editValueName}
                onClick={e => updateMineralFertilizer(e)}
              ></i>
            </span>
            <span className="mineral-fertilizer-text-right">
              <i 
                className="icon-trash mineral-fertilizer-edit-border" 
                name={editValueName}
                onClick={e =>deleteMineralFertilizer(e)}
              ></i>
            </span>
          </div>
        )}
      </div> : <div className="padding-top-size pad-right">&nbsp;</div>}
    </div>);
}

function getFertilizerPlanForSeason(
  fieldFertilizationItem,
  updateSeasonManure,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  updateCompleted
) {
  let fieldId = fieldFertilizationItem.fieldId;
  if (fieldFertilizationItem.seasonFertilizerPlans?.length > 0) {
    return fieldFertilizationItem.seasonFertilizerPlans.map((seasonFertilizationItem, index) => (
      <div key={index}>
        <div className="padding-top-alignment"></div>
        <div key={index} className="fertilization-grey-header">
          <div className="fertilization-plan-content">
            <div className="fertilization-plan-grid-grey-border">
              <div className="fertilizer-text-large text-capitalize">
                {seasonFertilizationItem.season}
              </div>
              <div>
                <div className="--border-thick-grey">
                  <ExpandingList list={getMineralFertilizerHeader(intl)} />
                </div>
                <div className="--border-thin-grey">
                  <ExpandingList list={getMineralFertilizerNeed(seasonFertilizationItem, intl)} />
                </div>
                <div className="--border-thin-grey">
                  <ExpandingList list={getMineralFertilizerPlannedAdded(seasonFertilizationItem, intl)} />
                </div>           
                <div>
                  <ExpandingList list={getMineralFertilizerPlannedDifference(seasonFertilizationItem, intl)} />
                </div>
                <div className="--border-thin-grey">
                  <ExpandingList list={getMineralFertilizerAdded(seasonFertilizationItem, intl)} />
                </div>           
                <div>
                  <ExpandingList list={getMineralFertilizerDifference(seasonFertilizationItem, intl)} />
                </div>
              </div>
            </div>
            <div>
              <div>
                {getManureCompleted(
                  fieldId,
                  updateCompleted,
                  seasonFertilizationItem,
                  viewFertilizerPlan,
                  isLoading,
                  intl
                )}
                {getManureHeading(intl)}
                {getManureTotalContent(
                  fieldId,
                  seasonFertilizationItem,
                  updateSeasonManure,
                  viewFertilizerPlan,
                  isLoading,
                  intl
                )}
                {getMineralFertilizerTotalCount(
                  fieldId,
                  seasonFertilizationItem,
                  updateMineralFertilizer,
                  addMineralFertilizer,
                  deleteMineralFertilizer,
                  updateSeasonFertilizer,
                  viewFertilizerPlan,
                  isLoading,
                  intl,
                  addingFertilizer
                )}
                <div className="--border-thin-grey">
                  <div className="add-mineral-fertilizer">
                    <div className="add-icon" onClick={() => addMineralFertilizer(seasonFertilizationItem.seasonId+'~~'+fieldId)}>
                      <AddIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
}

export default ({
  fieldFertilizationItem,
  updateSeasonManure,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  updateCompleted
}) =>
  getFertilizerPlanForSeason(
    fieldFertilizationItem,
    updateSeasonManure,
    updateMineralFertilizer,
    addMineralFertilizer,
    deleteMineralFertilizer,
    updateSeasonFertilizer,
    viewFertilizerPlan,
    isLoading,
    intl,
    addingFertilizer,
    updateCompleted
  );
