import {
  FIELD_LIST,
  FIELD_LIST_ERROR,
  FIELD_LIST1,
  FIELD_LIST_ERROR1,
  IS_FIELD_LOADING,
  ADD_UPDATE_FIELD,
  ADD_UPDATE_FIELD_ERROR,
  IMPORT_FIELD_SHAPE,
  IMPORT_FIELD_SHAPE_ERROR,
  DELETE_FIELD,
  DELETE_FIELD_ERROR,
  CLEAR_FIELD_MESSAGE,
  SAVE_FIELD_COORDINATES,
  UPDATE_FIELD_BATCH,
  UPDATE_FIELD_BATCH_ERROR,
  DELETE_FIELD_LIST_SUCCESS,
  DELETE_FIELD_LIST_FAILURE
} from 'constants/store/Fields';
import { GET_FARM_LIST } from 'constants/store/Farms';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isImportSuccess: false,
  hasError: false,
  hasDeleteError: false,
  hasAddUpdateError: false,
  errorMessage: null,
  isFieldDeleteSuccess: false,
  isFieldDeleteFailure: false,
  savedFieldCoordinates: { coOrds: [], boundaryDrawn: false, area: '' }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_FIELD_ERROR:
      return { ...state, isLoading: false, hasDeleteError: true, errorMessage: action.payload };
    case ADD_UPDATE_FIELD:
      return { ...state, isLoading: false, isSuccess: true };
    case DELETE_FIELD:
      return { ...state, isLoading: false, isSuccess: true };
    case IMPORT_FIELD_SHAPE:
      return { ...state, isLoading: false, isImportSuccess: true };
    case FIELD_LIST:
      return { ...state, isLoading: false, data: action.payload };
    case FIELD_LIST_ERROR:
      return { ...state, isLoading: false, hasError: true, errorMessage: action.payload };
    case FIELD_LIST1:
      return { ...state, isLoading: false, data1: action.payload };
    case FIELD_LIST_ERROR1:
      return { ...state, isLoading: false, hasError: true, errorMessage: action.payload };
    case IMPORT_FIELD_SHAPE_ERROR:
    case ADD_UPDATE_FIELD_ERROR:
      return { ...state, isLoading: false, hasAddUpdateError: true, errorMessage: action.payload };
    case IS_FIELD_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        isSuccess: false,
        hasError: false,
        hasDeleteError: false,
        hasAddUpdateError: false,
        errorMessage: 'null',
        isBatchSuccess: false,
        isBatchError: false
      };
    case CLEAR_FIELD_MESSAGE:
      return {
        ...state,
        isSuccess: false,
        hasError: false,
        hasDeleteError: false,
        hasAddUpdateError: false,
        errorMessage: 'null'
      };
    case SAVE_FIELD_COORDINATES:
      return { ...state, savedFieldCoordinates: action.payload };
    case GET_FARM_LIST:
      return initialState;
    case UPDATE_FIELD_BATCH:
      return {
        ...state,
        isLoading: false,
        isBatchSuccess: true,
        isBatchError: false,
        errorMessage: null
      };
    case UPDATE_FIELD_BATCH_ERROR:
      return {
        ...state,
        isLoading: false,
        isBatchSuccess: false,
        isBatchError: true,
        errorMessage: action.ErrMsg
      };
    case DELETE_FIELD_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFieldDeleteSuccess: true,
        isFieldDeleteFailure: false,
        errorMessage: null
      };
    case DELETE_FIELD_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFieldDeleteSuccess: false,
        isFieldDeleteFailure: true,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
