import * as React from 'react';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';

function getListHeader(fertilizerPlanList, createFertilizerTask, updateFertilizerPlanModal, editFertilizerPlan, generatePdfFertilizerPlan, index, intl) {
  let dataValue =
    fertilizerPlanList[0].planName +
    '~~' +
    fertilizerPlanList[0].year +
    '~~' +
    fertilizerPlanList[0].version;
  let editValue = 'edit~~' + dataValue;
  let viewValue = 'view~~' + dataValue;
  let updateValue = 'update~~' + dataValue + '~~' + fertilizerPlanList[0].mineralized;
  let createTask = 'task~~' + dataValue;
  let classNameValue = 'fertilizer-plan-list-padding';
  if (index === 0) {
    classNameValue = classNameValue + ' border-left-green';
  } else {
    classNameValue = classNameValue + ' border-left-grey';
  }
  return (
    <div className={classNameValue}>
      <div className="plan-list-edit">
        <div>
          <span>
            <EmphasisLight size="large">{fertilizerPlanList[0].planName}</EmphasisLight>
          </span>
          <span className="div-padding-left-high">
            <MetaInformation size="small">{fertilizerPlanList[0].summaryText}</MetaInformation>
          </span>
        </div>
        <div className="plan-list-edit-button">
            <span
              className="plan-list-edit-button-border margin-right-padding"
              onClick={e => createFertilizerTask(e)}
              name={createTask}
            >
              {intl.formatMessage({ id: 'FERTILIZATION_CREATE_TASK' })}
            </span>
            <span
              className="plan-list-edit-button-border margin-right-padding"
              onClick={e => updateFertilizerPlanModal(e)}
              name={updateValue}
            >
              {intl.formatMessage({ id: 'update' })}
            </span>
            <span
              className="plan-list-edit-button-border margin-right-padding"
              onClick={e => editFertilizerPlan(e)}
              name={editValue}
            >
              {intl.formatMessage({ id: 'EDIT' })} <i className="icon-edit fertilizer-padding-left-low" name={editValue} />
            </span>
            <span className="plan-list-edit-button-border margin-right-padding" 
              onClick={e => generatePdfFertilizerPlan(e)}
              name={viewValue}
            >
              {intl.formatMessage({ id: 'REPORTS_EXPORT' })}
            </span>
            <span
              className="plan-list-edit-button-border"
              onClick={e => editFertilizerPlan(e)}
              name={viewValue}
            >
              {intl.formatMessage({ id: 'VIEW' })}
            </span>
        </div>
      </div>
      <div className="fertilizer-plan-list-header-calc__data">
        <div className="fertilizer-plan-header__calculated-value text-lowercase">
          <p className="fertilizer-plan-header_calc-label">
            <EmphasisLight>
              {Math.round(fertilizerPlanList[0].manureAmount)} {fertilizerPlanList[0].manureUnit}
            </EmphasisLight>
            <span className="margin-right-padding"></span>
            <MetaInformation size="small">{intl.formatMessage({ id: 'MANURE' })}</MetaInformation>
          </p>
        </div>
        <div className="fertilizer-plan-header__calculated-value">
          <p className="fertilizer-plan-header_calc-label">
            <EmphasisLight>
              {Math.round(fertilizerPlanList[0].fertilizerAmount)}{' '}
              {fertilizerPlanList[0].fertilizerUnit}
            </EmphasisLight>
            <span className="margin-right-padding"></span>
            <MetaInformation size="small">
              {intl.formatMessage({ id: 'FERTILIZER_DIVIDED' })}
            </MetaInformation>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ({ fertilizerPlanList, createFertilizerTask, updateFertilizerPlanModal, editFertilizerPlan, generatePdfFertilizerPlan, index ,intl}) =>
  getListHeader(fertilizerPlanList, createFertilizerTask, updateFertilizerPlanModal, editFertilizerPlan, generatePdfFertilizerPlan, index, intl);
