import React from 'react';
import ConfirmDialog from '@tine/lib-frontend-components/components/dialogs/confirm-dialog';
import { injectIntl } from 'react-intl';

const ConfirmDialogBox = ({ heading, body, confirmText, cancelText, callback, warning, intl }) => (
  <div className="confirm-box-stye">
    <ConfirmDialog
      title={heading}
      message={body}
      confirmText={intl.formatMessage({ id: confirmText })}
      onConfirm={() => callback(true)}
      cancelText={intl.formatMessage({ id: cancelText })}
      onCancel={() => callback(false)}
      warning={warning}
    />
  </div>
);

export default injectIntl(ConfirmDialogBox);
