import {
  YIELD_CALCULATION_LOADING, 
  YIELD_CALCULATION_SUCCESS, 
  YIELD_CALCULATION_ERROR
} from 'constants/store/Fields';

const initialState = {
  hasError: false,
  yieldCalculationLoading: false,
  yieldCalculationError: null,
  yieldCalculation: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case YIELD_CALCULATION_LOADING:
      return {
        ...state,
        yieldCalculationLoading: true,
        hasError: false,
        yieldCalculationError: null
      };
    case YIELD_CALCULATION_ERROR:
      return {
        ...state,
        hasError: true,
        yieldCalculationError: action.payload,
        yieldCalculationLoading: false
      };
    case YIELD_CALCULATION_SUCCESS:
      return {
        ...state,
        yieldCalculationLoading: false,
        yieldCalculationError: null,
        hasError: false,
        yieldCalculation: action.payload
      };
    default:
      return state;
  }
}
