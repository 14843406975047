import React from 'react';
import { TextField } from '@mui/material'

const MuiTextBox = ({
  input,
  name,
  label,
  variant,
  id,
  value,
  onChange,
  rows
}) => {
  return (
    <TextField 
      {...input}
      name={name}
      id={id}
      label={label}
      variant={variant}
      value={value}
      onChange={onChange}
      rows={rows}
      multiline
      fullwidth
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#00696D"
          },
        },
        '& label.Mui-focused': {
          color: '#00696D',
          fontFamily: 'Inter'
        },
      }}
    />
  );
};
export default MuiTextBox;
