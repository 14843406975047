import React from 'react';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import FertilizerPlanSeasonPdfContent from 'mui-components/fields/fertilization/FertilizerPlanSeasonPdfContent3';

const generatePdfDocument = async (documentData,intl) => {
    const blob = await pdf(
        <FertilizerPlanSeasonPdfContent
            documentData={documentData}
            intl={intl}
        />
    ).toBlob();
    saveAs(blob, documentData.versionName + "-" + intl.formatMessage({ id: 'CHOOSE_SECOND_HARVEST' }) + ".pdf");
};

export default generatePdfDocument;