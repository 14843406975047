import {
  GET_BALES_SUCCESS,
  GET_BALES_ERROR,
  GET_BALES_LOADING,
  GET_BALE_MARKER_SUCCESS,
  GET_BALE_MARKER_ERROR,
  GET_BALE_MARKER_LOADING,
  ADD_BALE_MARKER_SUCCESS,
  ADD_BALE_MARKER_ERROR,
  /*ADD_BALE_MARKER_LOADING,*/
  UPDATE_BALE_MARKER_SUCCESS,
  UPDATE_BALE_MARKER_ERROR,
  DELETE_BALE_MARKER_SUCCESS,
  DELETE_BALE_MARKER_ERROR,
  /*UPDATE_BALE_MARKER_LOADING,*/
  SAVE_BALE_MARKER_COORDINATES 
} from 'constants/store/Bale';

const initialState = {
  data: null,
  hasError: false,
  isLoading: false,
  errorMessage: null,
  markerHasError: false,
  markerIsLoading: false,
  markerErrorMessage: null,
  markerSaveSuccess: false,
  markerDeleteSuccess: false,
  markerDeleteHasError: false,
  markerDeleteErrorMessage: null,
  markerData: null,
  savedBaleMarkerCoordinates: null
};

export default function getBaleList(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_BALES_LOADING:
      return {
        ...state,
        data: null,
        hasError: false,
        isLoading: true,
        errorMessage: null,
        markerHasError: false,
        markerIsLoading: false,
        markerErrorMessage: null,
        markerSaveSuccess: false,
        markerData: null
      };
    case GET_BALES_ERROR:
      return {
        ...state,
        data: null,
        hasError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
        markerHasError: false,
        markerIsLoading: false,
        markerErrorMessage: null,
        markerSaveSuccess: false,
        markerData: null
      };
    case GET_BALES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        hasError: false,
        isLoading: false,
        errorMessage: null,
        markerHasError: false,
        markerIsLoading: false,
        markerErrorMessage: null,
        markerSaveSuccess: false,
        markerData: null
      };
    case GET_BALE_MARKER_LOADING:
      return {
        ...state,
        markerHasError: false,
        markerIsLoading: true,
        markerErrorMessage: null,
        markerSaveSuccess: false,
        markerData: null,
        savedBaleMarkerCoordinates: null
      };
    case GET_BALE_MARKER_ERROR:
      return {
        ...state,
        markerHasError: true,
        markerIsLoading: false,
        markerErrorMessage: action.payload,
        markerSaveSuccess: false,
        markerData: null,
        savedBaleMarkerCoordinates: null
      };
    case GET_BALE_MARKER_SUCCESS:
      return {
        ...state,
        markerHasError: false,
        markerIsLoading: false,
        markerErrorMessage: null,
        markerSaveSuccess: false,
        markerData: action.payload,
        savedBaleMarkerCoordinates: null
      };
    case ADD_BALE_MARKER_SUCCESS:
    case UPDATE_BALE_MARKER_SUCCESS:
      return {
        ...state,
        markerHasError: false,
        markerIsLoading: false,
        markerErrorMessage: null,
        markerSaveSuccess: true,
        markerData: null,
        savedBaleMarkerCoordinates: null,
        markerDeleteHasError: false,
        markerDeleteErrorMessage: null,
      };
    case DELETE_BALE_MARKER_SUCCESS:
      return {
        ...state,
        markerHasError: false,
        markerIsLoading: false,
        markerErrorMessage: null,
        markerDeleteSuccess: true,
        markerData: null,
        savedBaleMarkerCoordinates: null,
        markerDeleteHasError: false,
        markerDeleteErrorMessage: null,
      };
    case ADD_BALE_MARKER_ERROR:
    case UPDATE_BALE_MARKER_ERROR:
      return {
        ...state,
        markerHasError: true,
        markerIsLoading: false,
        markerErrorMessage: action.payload.data,
        markerSaveSuccess: false,
        markerData: null,
        markerDeleteHasError: false,
        markerDeleteErrorMessage: null,
      };
    case DELETE_BALE_MARKER_ERROR:
      return {
        ...state,
        markerHasError: false,
        markerIsLoading: false,
        markerErrorMessage: null,
        markerSaveSuccess: false,
        markerData: null,
        markerDeleteHasError: true,
        markerDeleteErrorMessage: action.payload.data,
      };
    case SAVE_BALE_MARKER_COORDINATES:
      return {
        ...state,
        savedBaleMarkerCoordinates: action.payload
      };
    default:
      return state;
  }
}
