import { AWS_LOGIN_SUCCESS, AWS_LOGIN_FAILURE } from 'constants/store/Auth';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null
};

export default function loginReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case AWS_LOGIN_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case AWS_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.data
      };
    default:
      return state;
  }
}
