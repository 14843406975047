/**
 * Generic Error message component
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import ErrorMessage from '@tine/lib-frontend-components/components/errors/error-message';
import { FarmIcon } from '@tine/lib-frontend-components/elements/icons';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';

const CommonError = ({ intl }) => (
  <SectionContainer styling={{ paddingTop: 'high', paddingBottom: 'high' }}>
    <ErrorMessage
      errorHeader={intl.formatMessage({ id: 'COMMON_ERR_HEAD' })}
      title=""
      message={intl.formatMessage({ id: 'COMMON_ERR_TXT' })}
      icon={
        <span style={{ transform: 'rotateX(180deg)' }}>
          <FarmIcon scale={1.5} />
        </span>
      }
    />
  </SectionContainer>
);

export default injectIntl(CommonError);
