import {
  LOGIN_IS_LOADING,
  LOGIN_FAILURE,
  LOGOUT,
  IS_LOGGED,
  LOGOUT_HAS_ERROR,
  LOGOUT_IS_LOADING,
  IS_REFRESH_TOKEN_LOADING,
  HAS_REFRESH_TOKEN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  GET_MAP_TOKEN,
  IS_MAP_TOKEN_LOADING,
  CLEAR_PROFILE_UPDATE,
  PROFILE_UPDATE_LOADING,
  PROFILE_UPDATE_FALIURE,
  PROFILE_UPDATE_SUCCESS
} from 'constants/store/Auth';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  isLogoutLoading: false,
  logoutHasError: false,
  logoutSuccess: false,
  refreshTokenDetails: {
    isLoading: false,
    hasError: false,
    refreshToken: '',
    idToken: ''
  },
  profileUpdate: {
    isProfileUpdating: false,
    hasProfileUpdateErr: false,
    isUpdated: false
  },
  mapToken: null,
  mapTokenLoading: false
};

export default function loginReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case LOGIN_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
        errorMessage: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case LOGOUT:
      return {
        ...state,
        isLogoutLoading: false,
        logoutHasError: null,
        logoutSuccess: action.data,
        data: null
      };
    case LOGOUT_IS_LOADING:
      return {
        ...state,
        isLogoutLoading: true
      };
    case LOGOUT_HAS_ERROR:
      return {
        ...state,
        isLogoutLoading: false,
        logoutHasError: true,
        errorMessage: action.data,
        refreshTokenDetails: null,
        data: null
      };
    case IS_LOGGED:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.data,
        logoutSuccess: false,
        refreshTokenDetails: {
          ...state.refreshTokenDetails,
          refreshToken: action.data.refreshToken,
          idToken: action.data && action.data.refreshToken && action.data.refreshToken.accessToken
        }
      };
    case IS_REFRESH_TOKEN_LOADING:
      return {
        ...state,
        refreshTokenDetails: {
          ...state.refreshTokenDetails,
          isLoading: true,
          hasError: false
        }
      };
    case HAS_REFRESH_TOKEN_ERROR:
      return {
        ...state,
        refreshTokenDetails: {
          ...state.refreshTokenDetails,
          isLoading: false,
          hasError: true,
          idToken: '',
          errorMessage: action.errorMessage
        }
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshTokenDetails: {
          ...state.refreshTokenDetails,
          isLoading: false,
          hasError: false,
          idToken: action.refreshTokenDetails.accessToken
        }
      };
    case GET_MAP_TOKEN:
      return {
        ...state,
        mapToken: action.data.token === null ? 'no-data' : action.data.token,
        mapTokenLoading: false
      };
    case IS_MAP_TOKEN_LOADING:
      return {
        ...state,
        mapTokenLoading: action.isLoading
      };
    case PROFILE_UPDATE_LOADING:
      return {
        ...state,
        profileUpdate: {
          isProfileUpdating: action.payload,
          hasProfileUpdateErr: false,
          isUpdated: false
        }
      };
    case PROFILE_UPDATE_FALIURE:
      return {
        ...state,
        profileUpdate: {
          isProfileUpdating: false,
          hasProfileUpdateErr: action.payload,
          isUpdated: false,
          errorMessage: action.errorMessage
        }
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profileUpdate: {
          isProfileUpdating: false,
          hasProfileUpdateErr: false,
          isUpdated: action.payload
        }
      };
    case CLEAR_PROFILE_UPDATE:
      return {
        ...state,
        profileUpdate: {
          isProfileUpdating: false,
          hasProfileUpdateErr: false,
          isUpdated: false
        }
      };
    default:
      return state;
  }
}
