import {
  FIELD_METHOD_LOADING,
  FIELD_METHOD_SUCCESS,
  FIELD_METHOD_ERROR
} from 'constants/store/Fields';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  fieldMethodList: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FIELD_METHOD_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case FIELD_METHOD_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case FIELD_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        fieldMethodList: action.payload
      };
    default:
      return state;
  }
}
