import { FIELD_WEATHER, OMD_GOAL_URL } from 'constants/ApiUrl';
import { 
  FIELD_WEATHER_LOADING, 
  FIELD_WEATHER_SUCCESS, 
  FIELD_WEATHER_ERROR,
  OMD_GOALS_LOADING,
  OMD_GOALS_SUCCESS,
  OMD_GOALS_ERROR
} from 'constants/store/Fields';
import { POST, PUT } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Get the field weather data
 */
export const fieldTemperatureSum = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: FIELD_WEATHER_LOADING, payload: true });
    if(data === null) {
      dispatch({ type: FIELD_WEATHER_SUCCESS, payload: null });
      dispatch({ type: FIELD_WEATHER_ERROR, payload: null });
    }
    else {
      const { preferredLanguage } = getState().userDetails?.data;
      await dispatch(
        apiRequest({
          url: `${FIELD_WEATHER}${farmId}?language=${GetLanguageCode(
            preferredLanguage
          )}`,
          data,
          method: POST
        })
      )
      .then(response => {
        dispatch({ type: FIELD_WEATHER_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FIELD_WEATHER_ERROR, payload: error });
      });
    }
  } catch (error) {
    dispatch({ type: FIELD_WEATHER_ERROR, payload: error });
  }
};

export const updateOmdValues = (farmId, fieldId, data) => async (dispatch) => {
  try {
    dispatch({ type: OMD_GOALS_LOADING, payload: true });
    await dispatch(
      apiRequest({
        url: `${OMD_GOAL_URL}/${farmId}/fields/${fieldId}`,
        method: PUT,
        data
      })
    )
      .then(response => {
        dispatch({ type: OMD_GOALS_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: OMD_GOALS_SUCCESS, payload: error });
      });
    } catch (error) {
      dispatch({ type: OMD_GOALS_ERROR, payload: error });
    }
};