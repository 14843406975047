import {
  GET_BALE_ANALYSIS_STORAGE_SUCCESS,
  GET_BALE_ANALYSIS_STORAGE_ERROR,
  GET_BALE_ANALYSIS_STORAGE_LOADING,
  ADD_ANALYSIS_STORAGE_SUCCESS,
  ADD_ANALYSIS_STORAGE_ERROR,
  ADD_ANALYSIS_STORAGE_LOADING,
  GET_SELECTED_BALE_SUCCESS,
  GET_SELECTED_BALE_ERROR,
  GET_SELECTED_BALE_LOADING,
  GET_SELECTED_ANALYSIS_SUCCESS,
  GET_SELECTED_ANALYSIS_ERROR,
  GET_SELECTED_ANALYSIS_LOADING,
  UPDATE_ANALYSIS_STORAGE_SUCCESS,
  UPDATE_ANALYSIS_STORAGE_ERROR,
  UPDATE_ANALYSIS_STORAGE_LOADING
} from 'constants/store/Bale';

const initialState = {
  getStorageData: null,
  getStorageHasError: false,
  getStorageLoading: false,
  getStorageErrorMessage: null,
  getSelectedBaleData: null,
  getSelectedBaleHasError: false,
  getSelectedBaleLoading: false,
  getSelectedBaleErrorMessage: null,
  getSelectedAnalysisData: null,
  getSelectedAnalysisHasError: false,
  getSelectedAnalysisLoading: false,
  getSelectedAnalysisErrorMessage: null,
  saveAnalysisSuccess: false,
  saveAnalysisError: false,
  saveAnalysisErrorMessage: null,
  saveAnalysisLoading: false,
  updateAnalysisSuccess: false,
  updateAnalysisError: false,
  updateAnalysisErrorMessage: null,
  updateAnalysisLoading: false
};

export default function getBaleList(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_BALE_ANALYSIS_STORAGE_LOADING:
      return {
        ...state,
        getStorageData: null,
        getStorageHasError: false,
        getStorageLoading: true,
        getStorageErrorMessage: null
      };
    case GET_BALE_ANALYSIS_STORAGE_ERROR:
      return {
        ...state,
        getStorageData: null,
        getStorageHasError: true,
        getStorageLoading: false,
        getStorageErrorMessage: action.errorMessage
      };
    case GET_BALE_ANALYSIS_STORAGE_SUCCESS:
      return {
        ...state,
        getStorageData: action.payload,
        getStorageHasError: false,
        getStorageLoading: false,
        getStorageErrorMessage: action.errorMessage
      };
    case GET_SELECTED_BALE_LOADING:
      return {
        ...state,
        getSelectedBaleData: null,
        getSelectedBaleHasError: false,
        getSelectedBaleLoading: true,
        getSelectedBaleErrorMessage: null
      };
    case GET_SELECTED_BALE_ERROR:
      return {
        ...state,
        getSelectedBaleData: null,
        getSelectedBaleHasError: true,
        getSelectedBaleLoading: false,
        getSelectedBaleErrorMessage: action.errorMessage
      };
    case GET_SELECTED_BALE_SUCCESS:
      return {
        ...state,
        getSelectedBaleData: action.payload,
        getSelectedBaleHasError: false,
        getSelectedBaleLoading: false,
        getSelectedBaleErrorMessage: null
      };
    case GET_SELECTED_ANALYSIS_LOADING:
      return {
        ...state,
        getSelectedAnalysisData: null,
        getSelectedAnalysisHasError: false,
        getSelectedAnalysisLoading: true,
        getSelectedAnalysisErrorMessage: null
      };
    case GET_SELECTED_ANALYSIS_ERROR:
      return {
        ...state,
        getSelectedAnalysisData: null,
        getSelectedAnalysisHasError: true,
        getSelectedAnalysisLoading: false,
        getSelectedAnalysisErrorMessage: action.errorMessage
      };
    case GET_SELECTED_ANALYSIS_SUCCESS:
      return {
        ...state,
        getSelectedAnalysisData: action.payload,
        getSelectedAnalysisHasError: false,
        getSelectedAnalysisLoading: false,
        getSelectedAnalysisErrorMessage: null
      };
    case ADD_ANALYSIS_STORAGE_LOADING:
      return {
        ...state,
        saveAnalysisSuccess: false,
        saveAnalysisError: false,
        saveAnalysisErrorMessage: null,
        saveAnalysisLoading: true
      };
    case ADD_ANALYSIS_STORAGE_ERROR:
      return {
        ...state,
        saveAnalysisSuccess: false,
        saveAnalysisError: true,
        saveAnalysisErrorMessage: action.errorMessage,
        saveAnalysisLoading: false
      };
    case ADD_ANALYSIS_STORAGE_SUCCESS:
      return {
        ...state,
        saveAnalysisSuccess: true,
        saveAnalysisError: false,
        saveAnalysisErrorMessage: null,
        saveAnalysisLoading: false
      };
    case UPDATE_ANALYSIS_STORAGE_LOADING:
      return {
        ...state,
        updateAnalysisSuccess: false,
        updateAnalysisError: false,
        updateAnalysisErrorMessage: null,
        updateAnalysisLoading: true
      };
    case UPDATE_ANALYSIS_STORAGE_ERROR:
      return {
        ...state,
        updateAnalysisSuccess: false,
        updateAnalysisError: true,
        updateAnalysisErrorMessage: action.errorMessage,
        updateAnalysisLoading: false
      };
    case UPDATE_ANALYSIS_STORAGE_SUCCESS:
      return {
        ...state,
        updateAnalysisSuccess: true,
        updateAnalysisError: false,
        updateAnalysisErrorMessage: null,
        updateAnalysisLoading: false
      };
    default:
      return state;
  }
}
