/**
 * Profile Image.
 * required @props { Size, ImgUrl, loading}
 */

import React from 'react';
import SpinWrapper from '@tine/lib-frontend-components/components/animations/spin-wrapper';
import { SunIcon } from '@tine/lib-frontend-components/elements/icons';

const ProfileImage = ({ size, imgUrl, isLoading }) => (
  <div className={`profile-img ${size}`}>
    {imgUrl ? <img src={imgUrl} alt="profile" /> : null}
    {isLoading ? (
      <SpinWrapper>
        <SunIcon />
      </SpinWrapper>
    ) : null}
    {!imgUrl && !isLoading ? <span className="icon-profile" /> : null}
  </div>
);

export default ProfileImage;
