import { TASK_BATCH_URL } from 'constants/ApiUrl';
import { 
  TASK_BATCH_LOADING, 
  TASK_BATCH_SUCCESS, 
  TASK_BATCH_FAILURE
} from 'constants/store/Tasks';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Calculate the Yield Calculation
 */
export const getTaskBatch = (farmId, activityId, days) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_BATCH_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${TASK_BATCH_URL}/${farmId}/tasks/batch?activityId=${activityId}&days=${days}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: TASK_BATCH_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: TASK_BATCH_SUCCESS, payload: error });
      });
    } catch (error) {
      dispatch({ type: TASK_BATCH_FAILURE, payload: error });
    }
};