import * as React from 'react';
import { Dialog, DialogTitle, Chip, Box, Switch, Stack, Autocomplete, TextField, FormControlLabel } from '@mui/material';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import { getCurrentYears } from 'utils/index';
import { styled } from '@mui/material/styles';
import { buttonStyle, buttonFilledStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';

const dialogTitleStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "24px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "left", color: "#191C1C" }
const dialogTitleDialogStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "16px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "center", color: "#191C1C" }
const dialogSubTitleStyle = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px", paddingTop: "0px"  }
const dialogSubTitleToggleStyle = { ...dialogTitleStyle, fontSize: "16px", fontStyle: "normal", padding: "0px 32px" }
const dialogSubTitleCheckboxStyle = { ...dialogTitleStyle, fontSize: "16px", fontStyle: "normal", padding: "0px 30px", color: "#3F4949" }
const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", padding: "0px 30px" };
const dialogSubTitleStyle2 = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", paddingLeft: "30px", paddingRight: "30px", paddingTop: "40px"  }
const ToggleMF = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#6F7979',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#00696D' : '#00696D',
          opacity: 1,
          border: 0,
        },
        '& .MuiSwitch-thumb': {
          color: '#f1f1f1'
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #00696D',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#6F7979'
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 14,
      height: 14,
      marginTop: '3px',
      marginLeft: '3px',
      color: '#6F7979'
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      border: "3px solid #6F7979",
      backgroundColor: "#E6E9E8"
    },
  }));

const FertilizerCreateModal = ({ onCloseFertilizerPlan, onConfirmDialog, intl,  onChangePlanName, onChangePlanYear, disablePlanCreateButton,
  mineralFertilizer, handleSubmitModel, handleOnChangeMf, selectedOptions, handleSubmit, onCreateNewFertilizer, onCreateFertilizer,
  mineralCalculationOn, toggleMineralCalculationOn, onChangeTextBox, keepExistingPlanModel, discardExistingPlanModel, onCreateValue, confirmErrorMessage,
  showErrorMessageDialog }) => (
  <div>    
    <Dialog onClose={() => {}} open={onCreateFertilizer} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }}}>
      <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'CREATE_FERTILIZATION' })}</DialogTitle>
      <Box component="div" style={dialogSubTitleStyle}>
        {intl.formatMessage({ id: 'FERTILIZATION_CREATE_DIALOG_SUBTITLE' })}
      </Box>
      <Box style={inputStyle}>
        <Stack className='text-with-padding'>
          <MuiTextBox 
            name="createPlanName"
            id="createPlanName"
            label={intl.formatMessage({ id: 'NAME' })}
            variant="outlined"
            showFullWidth="true"
            required={true}
            onChange={onChangePlanName}
          />
        </Stack>
        <Stack className='text-with-padding'>
          <MuiSelectBox 
            name="createPlanYear"
            id="createPlanYear"
            label={intl.formatMessage({ id: 'YEAR' })}
            variant="outlined"
            required={true}
            onChange={onChangePlanYear}
            options={getCurrentYears()}
          />
        </Stack>
      </Box>
      <Box component="div" style={dialogSubTitleToggleStyle}>
        <FormControlLabel
          control={<ToggleMF sx={{ m: 1 }} checked={mineralCalculationOn} onChange={toggleMineralCalculationOn} />}
          label={intl.formatMessage({ id: 'FERTILIZATION_MF_TOGGLE' })}
        />
      </Box>
      <Box component="div" style={dialogSubTitleStyle2}>
        {intl.formatMessage({ id: 'FERTILIZATION_CREATE_DIALOG_SUBTITLE2' })}
      </Box>
      {}
      <Box style={dialogSubTitleCheckboxStyle}>
        {<Autocomplete
          multiple
          id="multiple-select-chip"
          options={mineralFertilizer.getMineralFertilizerList}
          onChange={handleOnChangeMf}
          value={selectedOptions}
          isOptionEqualToValue={(option, value) => option === value}
          renderInput={(params) => (
            <TextField 
              {...params}
              label={intl.formatMessage({ id: 'CT_MINERALFERTILIZER' })}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#00696D",
                    color: "#ffffff"
                  },
                },
                '& label.Mui-focused': {
                  color: '#00696D',
                  fontFamily: 'Inter'
                }
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
        />}
      </Box>
      <div style={{padding: "30px 24px", textAlign: "right"}}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={onCloseFertilizerPlan}
          />
          <Chip
            label={intl.formatMessage({ id: 'FERTILIZATION_CREATE_PLAN_BUTTON' })}
            clickable
            style={buttonFilledStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={disablePlanCreateButton}
            onClick={handleSubmit}
          />
        </span>
      </div>
    </Dialog>
    <Dialog onClose={() => {}} open={onCreateNewFertilizer} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }}}>
      <DialogTitle style={dialogTitleDialogStyle}>
        <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" /><br/>
        {intl.formatMessage({ id: 'ACTIVE_FERTILIZER_PLAN' })}
      </DialogTitle>
      <Box component="div" style={dialogSubTitleStyle}>
        {intl.formatMessage({ id: 'ACTIVE_FERTILIZER_PLAN_ERROR' })}
      </Box>
      <Box style={inputStyle}>
        <Stack className='text-with-padding'>
          <MuiTextBox 
            name="confirmCreate"
            id="confirmCreate"
            label={intl.formatMessage({ id: 'CREATE_CONTINUE' })}
            variant="outlined"
            showFullWidth="true"
            required={true}
            onChange={v => onChangeTextBox(v)}
          />
        </Stack>
      </Box>
      <div style={{padding: "0px 25px 25px 25px", textAlign: "right"}}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={onCloseFertilizerPlan}
          />
          <Chip
            label={intl.formatMessage({ id: 'create' })}
            clickable
            style={buttonFilledStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={!onCreateValue}
            onClick={handleSubmitModel}
          />
        </span>
      </div>
    </Dialog>
    <Dialog onClose={() => {}} open={showErrorMessageDialog} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }}}>
      <DialogTitle style={dialogTitleDialogStyle}>
        <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" /><br/>
        {intl.formatMessage({ id: 'FERTILIZATION_ALREADY_AVAILABLE' })}
      </DialogTitle>
      <div style={{padding: "15px 15px 25px 15px", textAlign: "center"}}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'OK' })}
            clickable
            style={buttonFilledStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={onCloseFertilizerPlan}
          />
        </span>
      </div>
    </Dialog>
    <Dialog open={onConfirmDialog} onClose={() => {}} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }}}>
      <DialogTitle style={dialogTitleDialogStyle}>
        <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" /><br/>
        {confirmErrorMessage}
      </DialogTitle>
      <div style={{padding: "15px", textAlign: "right"}}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'NO' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={discardExistingPlanModel}
          />
          <Chip
            label={intl.formatMessage({ id: 'YES' })}
            clickable
            style={buttonFilledStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={keepExistingPlanModel}
          />
        </span>
      </div>
    </Dialog>
  </div>
);

export default FertilizerCreateModal;