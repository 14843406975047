import {
  MINERAL_FERTILIZER_LIST_LOADING,
  MINERAL_FERTILIZER_LIST_SUCCESS,
  MINERAL_FERTILIZER_LIST_FAILURE,
  GET_MINERAL_FERTILIZER_LIST_LOADING,
  GET_MINERAL_FERTILIZER_LIST_SUCCESS,
  GET_MINERAL_FERTILIZER_LIST_FAILURE
} from 'constants/store/Fertilization';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  isSuccess: false,
  mineralFertilizerList: null,
  getMineralFertilizerList: null,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case MINERAL_FERTILIZER_LIST_LOADING:
    case GET_MINERAL_FERTILIZER_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case MINERAL_FERTILIZER_LIST_FAILURE:
    case GET_MINERAL_FERTILIZER_LIST_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case MINERAL_FERTILIZER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        mineralFertilizerList: action.payload
      };
    case GET_MINERAL_FERTILIZER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        isSuccess: true,
        getMineralFertilizerList: action.payload
      };
    default:
      return state;
  }
}
