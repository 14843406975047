import * as React from 'react';
import Paragraph from '@tine/lib-frontend-components/components/text-content/paragraph';
import Text from '@tine/lib-frontend-components/elements/typography/text';

export default ({ youtubeId, autoplay, title, caption, allowFullScreen, width, height }) => {
  const videoSrc = `https://www.youtube.com/embed/${youtubeId}?autoplay=${autoplay ? 1 : 0}`;
  return (
    <div
      className="yt-video"
      style={{
        width,
        height
      }}
    >
      <iframe
        style={{
          width: '100%',
          height: '90%'
        }}
        src={videoSrc}
        frameBorder="0"
        allowFullScreen={allowFullScreen}
        title={title}
      />
      <Paragraph>
        <Text size="small">{caption}</Text>
      </Paragraph>
    </div>
  );
};
