export const GET_BALES_SUCCESS = 'GET_BALES_SUCCESS';
export const GET_BALES_ERROR = 'GET_BALES_ERROR';
export const GET_BALES_LOADING = 'GET_BALES_LOADING';

export const GET_BALE_MARKER_SUCCESS = 'GET_BALE_MARKER_SUCCESS';
export const GET_BALE_MARKER_ERROR = 'GET_BALE_MARKER_ERROR';
export const GET_BALE_MARKER_LOADING = 'GET_BALE_MARKER_LOADING';

export const ADD_BALE_MARKER_SUCCESS = 'ADD_BALE_MARKER_SUCCESS';
export const ADD_BALE_MARKER_ERROR = 'ADD_BALE_MARKER_ERROR';
export const ADD_BALE_MARKER_LOADING = 'ADD_BALE_MARKER_LOADING';

export const UPDATE_BALE_MARKER_SUCCESS = 'UPDATE_BALE_MARKER_SUCCESS';
export const UPDATE_BALE_MARKER_ERROR = 'UPDATE_BALE_MARKER_ERROR';
export const UPDATE_BALE_MARKER_LOADING = 'UPDATE_BALE_MARKER_LOADING';

export const DELETE_BALE_MARKER_SUCCESS = 'DELETE_BALE_MARKER_SUCCESS';
export const DELETE_BALE_MARKER_ERROR = 'DELETE_BALE_MARKER_ERROR';
export const DELETE_BALE_MARKER_LOADING = 'DELETE_BALE_MARKER_LOADING';

export const SAVE_BALE_MARKER_COORDINATES = "SAVE_BALE_MARKER_COORDINATES";

export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';
export const GET_LOCATIONS_LOADING = 'GET_LOCATIONS_LOADING';

export const GET_TASK_LOCATIONS_SUCCESS = 'GET_TASK_LOCATIONS_SUCCESS';
export const GET_TASK_LOCATIONS_ERROR = 'GET_TASK_LOCATIONS_ERROR';
export const GET_TASK_LOCATIONS_LOADING = 'GET_TASK_LOCATIONS_LOADING';

export const GET_BALE_FEED_EVENT_SUCCESS = 'GET_BALE_FEED_EVENT_SUCCESS';
export const GET_BALE_FEED_EVENT_ERROR = 'GET_BALE_FEED_EVENT_ERROR';
export const GET_BALE_FEED_EVENT_LOADING = 'GET_BALE_FEED_EVENT_LOADING';

export const ADD_BALE_FEED_EVENT_SUCCESS = 'ADD_BALE_FEED_EVENT_SUCCESS';
export const ADD_BALE_FEED_EVENT_ERROR = 'ADD_BALE_FEED_EVENT_ERROR';
export const ADD_BALE_FEED_EVENT_LOADING = 'ADD_BALE_FEED_EVENT_LOADING';

export const SUB_BALE_FEED_EVENT_SUCCESS = 'SUB_BALE_FEED_EVENT_SUCCESS';
export const SUB_BALE_FEED_EVENT_ERROR = 'SUB_BALE_FEED_EVENT_ERROR';
export const SUB_BALE_FEED_EVENT_LOADING = 'SUB_BALE_FEED_EVENT_LOADING';

export const GET_BALE_ANALYSIS_STORAGE_SUCCESS = 'GET_BALE_ANALYSIS_STORAGE_SUCCESS';
export const GET_BALE_ANALYSIS_STORAGE_ERROR = 'GET_BALE_ANALYSIS_STORAGE_ERROR';
export const GET_BALE_ANALYSIS_STORAGE_LOADING = 'GET_BALE_ANALYSIS_STORAGE_LOADING';

export const ADD_ANALYSIS_STORAGE_SUCCESS = 'ADD_ANALYSIS_STORAGE_SUCCESS';
export const ADD_ANALYSIS_STORAGE_ERROR = 'ADD_ANALYSIS_STORAGE_ERROR';
export const ADD_ANALYSIS_STORAGE_LOADING = 'ADD_ANALYSIS_STORAGE_LOADING';

export const UPDATE_ANALYSIS_STORAGE_SUCCESS = 'UPDATE_ANALYSIS_STORAGE_SUCCESS';
export const UPDATE_ANALYSIS_STORAGE_ERROR = 'UPDATE_ANALYSIS_STORAGE_ERROR';
export const UPDATE_ANALYSIS_STORAGE_LOADING = 'UPDATE_ANALYSIS_STORAGE_LOADING';

export const ADD_TASK_BALE_SUCCESS = 'ADD_TASK_BALE_SUCCESS';
export const ADD_TASK_BALE_ERROR = 'ADD_TASK_BALE_ERROR';
export const ADD_TASK_BALE_LOADING = 'ADD_TASK_BALE_LOADING';

export const GET_SELECTED_BALE_SUCCESS = 'GET_SELECTED_BALE_SUCCESS';
export const GET_SELECTED_BALE_ERROR = 'GET_SELECTED_BALE_ERROR';
export const GET_SELECTED_BALE_LOADING = 'GET_SELECTED_BALE_LOADING';

export const GET_SELECTED_ANALYSIS_SUCCESS = 'GET_SELECTED_ANALYSIS_SUCCESS';
export const GET_SELECTED_ANALYSIS_ERROR = 'GET_SELECTED_ANALYSIS_ERROR';
export const GET_SELECTED_ANALYSIS_LOADING = 'GET_SELECTED_ANALYSIS_LOADING';

export const GET_SCHEDULED_BALES_SUCCESS = 'GET_SCHEDULED_BALES_SUCCESS';
export const GET_SCHEDULED_BALES_ERROR = 'GET_SCHEDULED_BALES_ERROR';
export const GET_SCHEDULED_BALES_LOADING = 'GET_SCHEDULED_BALES_LOADING';

export const GET_SCHEDULED_BALE_DETAILS_SUCCESS = 'GET_SCHEDULED_BALE_DETAILS_SUCCESS';
export const GET_SCHEDULED_BALE_DETAILS_ERROR = 'GET_SCHEDULED_BALE_DETAILS_ERROR';
export const GET_SCHEDULED_BALE_DETAILS_LOADING = 'GET_SCHEDULED_BALE_DETAILS_LOADING';

export const SAVE_SCHEDULED_BALES_SUCCESS = 'SAVE_SCHEDULED_BALES_SUCCESS';
export const SAVE_SCHEDULED_BALES_ERROR = 'SAVE_SCHEDULED_BALES_ERROR';
export const SAVE_SCHEDULED_BALES_LOADING = 'SAVE_SCHEDULED_BALES_LOADING';

export const GET_SCHEDULED_BALE_BY_ID_SUCCESS = 'GET_SCHEDULED_BALE_BY_ID_SUCCESS';
export const GET_SCHEDULED_BALE_BY_ID_ERROR = 'GET_SCHEDULED_BALE_BY_ID_ERROR';
export const GET_SCHEDULED_BALE_BY_ID_LOADING = 'GET_SCHEDULED_BALE_BY_ID_LOADING';

export const UPDATE_SCHEDULED_BALES_SUCCESS = 'UPDATE_SCHEDULED_BALES_SUCCESS';
export const UPDATE_SCHEDULED_BALES_ERROR = 'UPDATE_SCHEDULED_BALES_ERROR';
export const UPDATE_SCHEDULED_BALES_LOADING = 'UPDATE_SCHEDULED_BALES_LOADING';