import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import TrashcanIcon from '@tine/lib-frontend-components/elements/icons/trashcan-icon/index';
import { connect } from 'react-redux';
import _ from 'lodash';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';

import ProfileImage from 'components/ProfileImage';
import Selectbox from 'components/forms/Selectbox';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import Loader from 'components/Loader';
import { checkPermissions } from 'utils/index';
import * as userActions from 'actions/Users';
import { /*IMG_DOWNLOAD_REQUEST,*/ DELETE_USER, UPDATE_ROLE } from 'constants/index';
import requestProfileImg from 'actions/ProfilePicture';

class UserCard extends React.Component {
  state = {
    showDelete: false
  };

  /*componentDidMount() {
    const {
      userData: { user },
      AWSLoginDetails,
      fetchProfileImg
    } = this.props;
    if (user) {
      fetchProfileImg(AWSLoginDetails, IMG_DOWNLOAD_REQUEST, user.id, 'userImage');
    }
  }*/

  /**
   * To fetch profile image of user
   * @param {number} id
   * @returns {string}
   */
  fetchImage = id => {
    const { profilePictures } = this.props;
    if (profilePictures && profilePictures.length > 0) {
      const res = _.find(profilePictures, { userId: id });
      return res ? res.image : null;
    }
    return null;
  };

  // change handler for role
  onChangeRole = option => {
    const { updateUserRole, userInfo, activeFarm, userData, farmUserDetails } = this.props;
    const {
      rolesList: { roles }
    } = farmUserDetails;
    const updateUser = { ...userData };
    updateUser.role = {
      id: option.value,
      roleName: roles.filter(item => item.value === option.value)[0].label
    };
    updateUserRole(updateUser, activeFarm.farm.id, userInfo.data);
  };

  // To show/hide delete user prompt
  showDialog = action => {
    const {
      activeFarm,
      deleteUserRole,
      userInfo,
      userData: { user }
    } = this.props;
    if (action) {
      deleteUserRole(user.id, activeFarm.farm.id, userInfo.data);
    }
    this.setState(prevState => ({ showDelete: !prevState.showDelete }));
  };

  // To delete user
  deleteUser = () => {
    const { activeFarm, rolePermissions } = this.props;
    if (checkPermissions(DELETE_USER, activeFarm, rolePermissions)) {
      this.setState(prevState => ({ showDelete: !prevState.showDelete }));
    }
  };

  // To get user roles with translation
  getRoles = () => {
    const { farmUserDetails } = this.props;
    const {
      rolesList: { roles }
    } = farmUserDetails;
    if (roles) {
      return roles.map(item => ({
        value: item.value,
        label: item.label
      }));
    }
    return [];
  };

  render() {
    const {
      props: { userData, activeRole, activeFarm, rolePermissions, farmUserDetails, intl },
      state: { showDelete }
    } = this;
    const { updateUser } = farmUserDetails;
    const isLoading = updateUser?.updatingUsers.includes(userData.user.id);
    const disableAction = checkPermissions(DELETE_USER, activeFarm, rolePermissions);
    const disableUpdate = checkPermissions(UPDATE_ROLE, activeFarm, rolePermissions);
    return (
      <div className="user-card">
        <div className="user-card-detail">
          <div className="user-card-detail-wrapper">
            <div className="user-card-heading">
              <ProfileImage size="medium" imgUrl={this.fetchImage(userData.user.id)} />
              <div className="user-card-name">
                <p>
                  <span className="align-user-val">{userData.user.firstName}</span>
                  &nbsp;
                  <span>{userData.user.lastName}</span>
                </p>
              </div>
              <div className="user-card-delete">
                <div
                  className={`delete-icon ${!disableAction || isLoading ? 'disabled' : ''}`}
                  onClick={this.deleteUser}
                  onKeyPress={this.deleteUser}
                  role="button"
                  tabIndex={-1}
                >
                  <TrashcanIcon />
                </div>
              </div>
            </div>
            <div className="user-card-info">
              <p className="user-card-email">
                <span>
                  <FormattedMessage id="MU_EMAIL" />:
                </span>
                <span className="align-user-val">
                  <a href={`mailto:${userData.user.emailId}`} title={userData.user.emailId}>
                    {userData.user.emailId}
                  </a>
                </span>
              </p>
              <p className="user-card-last-active">
                <span>
                  <FormattedMessage id="MU_LA" />:
                </span>
                <span className="align-user-val">{userData.lastActiveDate}</span>
              </p>
              <p className="user-card-hours-worked">
                <span>
                  <FormattedMessage id="MU_HW" />
                  {` ${new Date().getFullYear()}`}:
                </span>
                <span className="align-user-val">{userData.hoursSpentOnTasks}</span>
              </p>
            </div>
            <div className="user-card-role">
              {activeRole && (
                <Selectbox
                  options={this.getRoles()}
                  disabled={!disableUpdate || isLoading}
                  value={activeRole}
                  onChange={v => this.onChangeRole(v)}
                  styleName="select-box-common"
                />
              )}
              {isLoading && <Loader styleName="user-loader" />}
              {updateUser?.usersUpdateError.includes(userData.user.id) && (
                <ErrorText>
                  {updateUser?.errorResponse?.status === 409 ? (
                    <FormattedMessage id="FARM_MUST_OWNER" />
                  ) : (
                    <FormattedMessage id="COMMON_ERR_MSG" />
                  )}
                </ErrorText>
              )}
            </div>
            {showDelete && (
              <ConfirmDialogBox
                heading={intl.formatMessage({ id: 'DELETE_CONFIRM_TITLE' })}
                body={`${intl.formatMessage({ id: 'DIALOG_BODY_USER' })} ${
                  userData.user.firstName
                } ${userData.user.lastName}?`}
                confirmText="DELETE"
                cancelText="CANCEL"
                callback={action => this.showDialog(action)}
                warning
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profilePictures: state.profileImage.userImage,
  activeFarm: state.farmList.activeFarm,
  rolePermissions: state.RolesDetails,
  userInfo: state.userDetails,
  farmUserDetails: state.farmUserDetails,
  /*AWSLoginDetails: state.AWSLogin.data*/
});

function mapDispatchToProps(dispatch) {
  return {
    updateUserRole: (updateObj, farmId, loggedInUserData) =>
      dispatch(userActions.updateUserRole(updateObj, farmId, loggedInUserData)),
    deleteUserRole: (userId, farmId, loggedInUserData) =>
      dispatch(userActions.deleteUserRole(userId, farmId, loggedInUserData)),
    fetchProfileImg: (awsLogin, type, userId, userType) =>
      dispatch(requestProfileImg(awsLogin, type, userId, userType))
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserCard));
