import * as React from 'react';
import _ from 'lodash';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import ExpandingContainer from '@tine/lib-frontend-components/components/accordion/expanding-container';
import FertilizerList from 'containers/fertilization/planner/FertilizerList';
import FertilizerListContent from 'containers/fertilization/planner/FertilizerListContent';

function getList(planList, createFertilizerTask, editFertilizerPlan, updateFertilizerPlanModal, generatePdfFertilizerPlan, intl) {
  let data = '';
  return _.orderBy(Object.entries(planList.fertilizerPlanMap), [fertilizerPlanMapList => fertilizerPlanMapList], ['desc']).map(([key, value], index) => {
    data = (
      <div key={key} className="padding-bottom-alignment">
        <div className="padding-bottom-low">
          <EmphasisLight size="large">{key}</EmphasisLight>
        </div>
        <ExpandingContainer
          collapsedContent={
            <FertilizerList
              fertilizerPlanList={value}
              createFertilizerTask={createFertilizerTask}
              editFertilizerPlan={editFertilizerPlan}
              updateFertilizerPlanModal={updateFertilizerPlanModal}
              generatePdfFertilizerPlan={generatePdfFertilizerPlan}
              index={index}
              intl={intl}
            />
          }
          expanded={index === 0}
          expandedContent={
            <FertilizerListContent
              fertilizerPlanList={value}
              editFertilizerPlan={editFertilizerPlan}
              generatePdfFertilizerPlan={generatePdfFertilizerPlan}
              index={index}
              intl={intl}
            />
          }
          withTimeline={false}
        />
      </div>
    );
    return data;
  });
}

export default ({ planList, createFertilizerTask, editFertilizerPlan, updateFertilizerPlanModal, generatePdfFertilizerPlan, intl }) => 
  getList(planList, createFertilizerTask, editFertilizerPlan, updateFertilizerPlanModal, generatePdfFertilizerPlan, intl);
