import * as React from 'react';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Typography, Grid, Stack } from '@mui/material'
 
class WeatherTab2 extends React.Component {
  render() {
    const gridContainerStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'      
    };
    const tableHeading = {
      padding: '20px',
      borderRight: '1px solid #BEC8C9',
      minWidth: '340px',
      width: '340px',
      textAlign: 'left'
    }
    const tableContent = {
      padding: '20px',
      minWidth: '140px',
      textAlign: 'left'
    }
    const textStyle = { fontFamily: "Inter", fontWeight: "400", fontSize: "16px", color: "#191C1C" }
    const { intl, fieldChart, chartYearChecked, harvestDataLength, harvest1State, harvest2State, 
      harvest3State, harvest4State } = this.props;
    let harvest1Value = [];
    let harvest2Value = [];
    let harvest3Value = [];
    let harvest4Value = [];
    let finalHarvestValue = [];
    // eslint-disable-next-line
    {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
      seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
      harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
        (
          harvest1State && seriesHarvestData.harvest === 1 && harvest1Value.push(seriesHarvestData),
          harvest2State && seriesHarvestData.harvest === 2 && harvest2Value.push(seriesHarvestData),
          harvest3State && seriesHarvestData.harvest === 3 && harvest3Value.push(seriesHarvestData),
          harvest4State && seriesHarvestData.harvest === 4 && harvest4Value.push(seriesHarvestData)
        )
    ))))}
    let harvestValue = [...harvest1Value, ...harvest2Value, ...harvest3Value, ...harvest4Value];
    finalHarvestValue = [...new Set(harvestValue)];
    return (
      <Box>
      {(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.temperatureSumChart !== null && harvestDataLength > 0 && finalHarvestValue.length > 0) ?
      <Stack>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_DATE' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.harvestDate.split("-")[2]}-{seriesHarvestData.harvestDate.split("-")[1]}-{seriesHarvestData.harvestDate.split("-")[0]}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_HARVEST' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.harvest}.
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_TEMPSUM' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesVal.data[seriesVal.harvestDateIndexes[index]]}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_TEMP_BTW_HARVEST' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesVal.tempSumDiff === undefined ? '' : seriesVal.tempSumDiff[index] === 0 ? '' : seriesVal.tempSumDiff[index]}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_TOTAL_HARVEST' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.totalHarvest} {seriesHarvestData.units === 'bales' && intl.formatMessage({ id: 'FIELD_WEATHER_BALES' })}{seriesHarvestData.units === 'wagon' && intl.formatMessage({ id: 'FIELD_WEATHER_WAGON' })}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_DRYMATTER' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.drymatter}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_RAW_PROTEIN' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.rawProtein}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_DIGESTIBILITY' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.digestibility}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_TOTAL_DRYMATTER' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.totalDrymatter}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_YIELD_GOAL' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.yieldGoal}
              </Typography>
            </Grid>
          ))))}
        </Grid>
        <Grid container style={gridContainerStyle} xs={12} sm={12}>
          <Grid item style={tableHeading}>
            <Typography style={textStyle}>{intl.formatMessage({ id: 'FIELD_WEATHER_DIFFERENCE' })}</Typography>
          </Grid>
          {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
          seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          ((seriesHarvestData.harvest === 1 && harvest1State) || (seriesHarvestData.harvest === 2 && harvest2State) || (seriesHarvestData.harvest === 3 && harvest3State) || (seriesHarvestData.harvest === 4 && harvest4State)) && 
            <Grid item style={tableContent}>
              <Typography style={textStyle}>
                {seriesHarvestData.difference}
              </Typography>
            </Grid>
          ))))}
        </Grid>
      </Stack>  : <Stack><Typography style={textStyle}>{intl.formatMessage({ id: 'WEATHER_TABLE_NO_CONTENT' })}</Typography></Stack>}
      </Box>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});

export default injectIntl(withRouter(connect(mapStateToProps)(WeatherTab2)));