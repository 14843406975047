export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
export const ADD_ASSET_ERROR = 'ADD_ASSET_ERROR';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_ERROR = 'DELETE_ASSET_ERROR';
export const ASSET_TYPE = 'ASSET_TYPE';
export const ASSET_TYPE_FAILURE = 'ASSET_TYPE_FAILURE';
export const ASSET_TYPE_ENTITY = 'ASSET_TYPE_ENTITY';
export const ASSET_TYPE_ENTITY_FAILURE = 'ASSET_TYPE_ENTITY_FAILURE';
export const CLEAR_ASSET_MESSAGES = 'CLEAR_ASSET_MESSAGES';
export const IS_ASSET_LOADING = 'IS_ASSET_LOADING';
export const ASSET_LIST = 'ASSET_LIST';
export const ASSET_LIST_ERROR = 'ASSET_LIST_ERROR';
export const ASSET_SORT_BY_OPTION = 'ASSET_SORT_BY_OPTION';
export const ASSET_ENTITY_LOADING = 'ASSET_ENTITY_LOADING';
