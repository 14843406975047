import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import AutoplacementGridSection from '@tine/lib-frontend-components/components/alignment/autoplacement-grid-section';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import AddIcon from '@tine/lib-frontend-components/elements/icons/add-icon/index';
import { connect } from 'react-redux';
import _ from 'lodash';

import UserCard from 'mui-components/settings/users/UserCard';
import * as userActions from 'actions/Users';
import Loader from 'components/Loader';
import CommonError from 'components/CommonErrorMessage';

import { ADMIN, OWNER, ADVISOR } from 'constants/index';

class ManageUserList extends Component {
  componentDidMount = () => {
    const {
      getUsers,
      getUserRoles,
      activeFarm,
      nullifyFarmUserMessage
    } = this.props;
    nullifyFarmUserMessage();
    if (activeFarm) {
      getUserRoles();
      getUsers(activeFarm.farm.id);
    }
  };

  componentDidUpdate = prevProps => {
    const { getUsers, activeFarm, nullifyFarmUserMessage } = this.props;
    if (activeFarm !== prevProps.activeFarm) {
      getUsers(activeFarm.farm.id);
      nullifyFarmUserMessage();
    }
  };

  /**
   * To extract a translated role based on role chosen
   * @param {object} item
   * @returns {object}
   */
  chosenRole = item => {
    const {
      farmUserDetails: { rolesList }
    } = this.props;
    const { roles } = rolesList;
    const userRole = roles.filter(option => option.value === item.role.id)[0];
    //userRole.label = item.role.roleName;
    return userRole;
  };

  render() {
    const {
      farmUserDetails: { rolesList, userList },
      activeFarm,
      hasSupportAdminRole
    } = this.props;
    const disableLink =
      activeFarm && (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data);
    const isLoading = rolesList.isGetRolesLoading || userList.isUsersLoading;
    return (
      <div className="manage-users-wrapper">
        {isLoading ? <Loader styleName="manage-users-loader" /> : null}
        <div className={`add-user ${!disableLink ? 'cursor-not-allowed' : ''}`}>
          <Link className={!disableLink ? 'pointer-events-none' : ''} to="/settings/users/adduser">
            <span className={`pad-right ${!disableLink ? 'disable' : ''}`}>
              <FormattedMessage id="MU_AU" />
            </span>
            <div className={`add-icon ${!disableLink ? 'disable' : ''}`}>
              <AddIcon />
            </div>
          </Link>
        </div>
        {rolesList.roles?.length > 0 && userList.users?.length > 0 ? (
          <div className="manage-users">
            <AutoplacementGridSection className="user-grid-wrapper">
              {_.map(userList.users, item => (
                <FlexGridItem key={item.user.id}>
                  <UserCard userData={item} activeRole={this.chosenRole(item)} />
                </FlexGridItem>
              ))}
            </AutoplacementGridSection>
          </div>
        ) : (
          !isLoading && (rolesList.isGetRolesFailure || userList.hasError) && <CommonError />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  farmUserDetails: state.farmUserDetails,
  activeFarm: state.farmList.activeFarm,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDispatchToProps(dispatch) {
  return {
    getUsers: farmId => dispatch(userActions.getUsers(farmId)),
    getUserRoles: () => dispatch(userActions.getUserRoles()),
    deleteUser: bool => dispatch(userActions.deleteUser(bool)),
    nullifyFarmUserMessage: () => dispatch(userActions.nullifyFarmUserMessage())
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageUserList));
