import {
  GET_TASK_LOADING,
  GET_TASK_SUCCESS_NULL,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE
} from 'constants/store/Tasks';

const initialState = {
  isLoading: false,
  isGetTaskFailure: false,
  isGetTaskSuccess: false,
  isGetTaskData: null,
};

export const GetTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK_LOADING:
      return {
        ...state,
        isLoading: true,
        isGetTaskFailure: false,
        isGetTaskSuccess: false,
      };
    case GET_TASK_SUCCESS_NULL:
        return {
          ...state,
          isLoading: false,
          isGetTaskFailure: false,
          isGetTaskSuccess: false,
          isGetTaskData: null
        };
    case GET_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isGetTaskFailure: false,
        isGetTaskSuccess: true,
        isGetTaskData: action.payload
      };
    case GET_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isGetTaskFailure: true,
        isGetTaskSuccess: false,
      };
    default:
      return { ...state };
  }
};

export default GetTaskReducer;
