import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import CreateTask from 'containers/tasks/tasklist/CreateTask';
/*import ManageFields from 'containers/fields/ManageFields';*/
import ManageFields from 'mui-components/fields/fieldtab/ManageFields';
//import EditFields from 'containers/fields/EditFields';
/*import EditField from 'containers/fields/EditFieldIndex';*/
import EditField from 'mui-components/fields/fieldtab/EditFieldIndex';
import AddUpdateSoilSample from 'mui-components/fields/fieldtab/soilsamples/AddUpdateSoilSample';
import ProfileSetting from 'containers/profile/ProfileSettings';
import UpdatePassword from 'containers/profile/UpdatePassword';
import PageNotFound from 'components/PageNotFound';
import Settings from 'mui-components/settings';
import Tasks from 'containers/tasks';
import Datavalidation from 'containers/datavalidation/Home';
import Login from 'containers/login/Login';
import Stats from 'mui-components/stats';
import FertilizationPlanner from 'containers/fertilization';
import FertilizerPlannerView from 'containers/fertilization/planner/FertilizerPlannerView';
import ManureForm from 'mui-components/storages/manurestorage/ManureForm';
//import ManureForm from 'containers/fertilization/manure/ManureForm';
import BaleStorageForm from 'mui-components/storages/balestorage/balestorage/BaleStorageForm';
import SelectedBaleList from 'mui-components/storages/balestorage/SelectedBaleList';
//import Storage from 'containers/storage';
import Storage from 'mui-components/storages';
import ReactGraph from 'containers/reactgraph';
import ApexChart from 'containers/apexchart';
import GrassAnalysis from 'containers/grassanalysis';
import Redirect from 'components/Redirect';

export default () => (
  <Switch>
    <Route path="/redirect" component={Redirect} />
    <PrivateRoute exact path="/stats" component={Stats} />
    <PrivateRoute exact path="/managefields/fertilization" component={ManageFields} />
    {/*<PrivateRoute exact path="/managefields/:id" component={EditFields} />*/}
    <PrivateRoute exact path="/managefields/:id/weather" component={EditField} />
    <PrivateRoute exact path="/managefields/:id/historical" component={EditField} />
    <PrivateRoute exact path="/managefields/:id/manure" component={EditField} />
    <PrivateRoute exact path="/managefields/:id/soilsamples/:soilId" component={AddUpdateSoilSample} />
    <PrivateRoute exact path="/managefields/:id/soilsamples" component={EditField} />
    <PrivateRoute exact path="/managefields/:id" component={EditField} />
    <PrivateRoute exact path="/managefields" component={ManageFields} />
    <PrivateRoute path="/settings" component={Settings} />
    <PrivateRoute exact path="/profilesettings" component={ProfileSetting} />
    <PrivateRoute exact path="/profilesettings/Updatepassword" component={UpdatePassword} />
    <PrivateRoute exact path="/datavalidation" component={Datavalidation} />
    <PrivateRoute exact path="/tasks/tasklist/:id/:fieldId" component={CreateTask} />
    <PrivateRoute exact path="/tasks/tasklist/:id" component={CreateTask} />
    <PrivateRoute exact path="/tasks/tasklist/create" component={CreateTask} />
    <PrivateRoute exact path="/tasks/batch" component={Tasks} />
    <PrivateRoute exact path="/tasks/tasklist" component={Tasks} />
    <PrivateRoute path="/tasks" component={Tasks} />
    <PrivateRoute exact path="/fertilization/manure-storage/:id" component={ManureForm} />
    <PrivateRoute exact path="/fertilization/manure-storage/create" component={ManureForm} />
    <PrivateRoute exact path="/fertilization/planner/:id" component={FertilizerPlannerView} />
    <PrivateRoute exact path="/fertilization/soil-samples" component={FertilizationPlanner} />
    <PrivateRoute path="/fertilization" component={FertilizationPlanner} />
    <PrivateRoute exact path="/storage/bale/:id" component={SelectedBaleList} />
    <PrivateRoute exact path="/storage/manure-storage" component={Storage} />
    <PrivateRoute exact path="/storage/manure-storage/:id" component={ManureForm} />
    <PrivateRoute exact path="/storage/manure-storage/create" component={ManureForm} />
    <PrivateRoute exact path="/storage/bale-storage/:id" component={BaleStorageForm} />
    <PrivateRoute exact path="/storage/bale-storage/create" component={BaleStorageForm} />
    <PrivateRoute path="/storage" component={Storage} />
    <PrivateRoute path="/graph" component={ReactGraph} />
    <PrivateRoute path="/apexchart" component={ApexChart} />
    <PrivateRoute path="/grassanalysis" component={GrassAnalysis} />
    <Route path="/" component={Login} />
    <PrivateRoute component={PageNotFound} />
  </Switch>
);
