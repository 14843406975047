import * as React from 'react';

import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import Text from '@tine/lib-frontend-components/elements/typography/text';

const FertilizerModalData = ({ intl, onCreateTaskSave, onCreateTaskSaveConfirm, closeCreateTaskModel, closeCreateTaskSaveModel, 
  createTaskModel, fertilizationPlan, goToTaskPage }) => (
    <div>
      <ModalDialog
        minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
        width={{mobile: '70%', tablet: '35%', web: '25%'}}
        height={{mobile: '45%', tablet: '45%', web: '40%'}}
        show={onCreateTaskSave}
        closeIcon=""
        onClose={() => {}}>
        <div className="text-align-center">
          <div className="fertilization-header-div">
            <span className='fertilization-header'>{intl.formatMessage({ id: 'FERTILIZATION_CREATE_TASK_HEADER' })}</span>
          </div>
          <div>
            <div className='fertilizer-form-style'>
              <div>
                <Text>{intl.formatMessage({ id: 'FERTILIZATION_CREATE_TASK_DESCRIPTION' })}</Text>
              </div>
            </div>
            <div className='fertilizer-form-style-button'>
              <span className="button-style-padding-right">
                <ActionButton onClick={createTaskModel}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
              </span>
              <span>
                <ActionButton multi onClick={closeCreateTaskSaveModel}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
      <ModalDialog
        minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
        width={{mobile: '70%', tablet: '35%', web: '25%'}}
        height={{mobile: '45%', tablet: '45%', web: '40%'}}
        show={onCreateTaskSaveConfirm}
        closeIcon=""
        onClose={() => {}}>
        <div className="text-align-center">
          <div className="fertilization-header-div">
            <span className='fertilization-header'>{intl.formatMessage({ id: 'FERTILIZATION_SUCCESS' })}</span>
          </div>
          <div>
            <div className='fertilizer-form-style'>
              <div>
                <Text>{fertilizationPlan.data && fertilizationPlan.data.convertFPToTasks !==null ? fertilizationPlan.data.convertFPToTasks : ''}</Text>
              </div>
            </div>
            <div className='fertilizer-form-style-button'>
              <span className="button-style-padding-right">
                <ActionButton onClick={goToTaskPage}>{intl.formatMessage({ id: 'FERTILIZATION_SHOW_TASKS' })}</ActionButton>
              </span>
              <span>
                <ActionButton multi onClick={closeCreateTaskModel}>{intl.formatMessage({ id: 'FERTILIZATION_SHOW_CLOSE' })}</ActionButton>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
  </div>
);

export default FertilizerModalData;