import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import AppContainer from '@tine/lib-frontend-components/components/alignment/app-container';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import Footer from 'components/Footer';
import EanaWebPageWrapper from '@tine/lib-frontend-components/components/eana/eana-web-page-wrapper';
import EanaWebBody from '@tine/lib-frontend-components/components/eana/eana-web-body';
import EanaWebGridLayout from '@tine/lib-frontend-components/components/eana/eana-web-grid-layout';
import NavigationBar from 'components/NavigationBar';
import NavigationBarMUI from 'mui-components/NavigationBarMUI';
import Loader from 'components/Loader';
import LoaderPage from 'components/LoaderPage';
import Header from 'containers/header';
import CommonError from 'components/CommonErrorMessage';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import { fetchRolePermistionList } from 'actions/RolesDetails';
import fetchMasterData from 'actions/MasterData';
import { fetchFarmList } from 'actions/Farms';
import { dataValidationPermission } from 'actions/GpsData';
import { fetchSupportAdminPermission } from 'actions/SupportAdminRole';
import { isMobile } from 'react-device-detect';
import { createSvgIcon } from '@mui/material/utils';
import { ENTER_TASKS, ENTER_FIELD } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

const DotIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 18C15.8137 18 18.5 15.3137 18.5 12C18.5 8.68629 15.8137 6 12.5 6C9.18629 6 6.5 8.68629 6.5 12C6.5 15.3137 9.18629 18 12.5 18Z" fill="#041F21"/>
  </svg>
);

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsAndCondition: false,
      fetchFarmListState: false
    };
    this.openTermsAndCondition = this.openTermsAndCondition.bind(this);
    this.closeTermsAndCondition = this.closeTermsAndCondition.bind(this);
  }
  componentDidMount = () => {
    const { getRolesDetails, userDetails, dataPermission, getMasterData, supportAdminPermission } = this.props;
    const { idToken } = userDetails.data;
    getRolesDetails(idToken);
    dataPermission(idToken);
    supportAdminPermission();
    getMasterData();
  };

  componentDidUpdate = () => {
    const { getFarmList, userDetails, hasSupportAdminRole, farmList } = this.props;
    const { lastaccessedFarm } = userDetails.data;
    const { fetchFarmListState } = this.state;
    let version = "v1";
    if(!fetchFarmListState && hasSupportAdminRole.data !== null) {
      if(hasSupportAdminRole.data) {
        version = "v2";
      }
      if(!farmList.data) {
        if(localStorage.getItem("selectedFarmId") === null &&  localStorage.getItem("selectedFarmName") === null) {
          getFarmList(lastaccessedFarm,version);
        } else {
          getFarmList(localStorage.getItem("selectedFarmId"),version);
        }
        this.setState({
          fetchFarmListState: true
        });
      }
    }
  }

  openTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: true
    });
  }

  closeTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: false
    });
  }

  render() {
    const { showTermsAndCondition } = this.state;
    const {
      props: { children, farmList, rolesDetails, userDetails, location, masterData, intl }
    } = this;
    const error = farmList.hasError || rolesDetails.hasError || masterData.hasError;
    const profileDisplayCheck = location && location.pathname.includes('profilesettings');
    const mobileHeading = {
      color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      textDecoration: 'none'
    }
    return (
      <AppContainer>
        <EanaWebBody>
          <EanaWebPageWrapper>
            {userDetails.isLogoutLoading ? <LoaderPage /> : null}
            <EanaWebGridLayout>
              <FlexGridItem>
                <NavigationBar />
              </FlexGridItem>
              <FlexGridItem>
                <div className="full-width app-content-wrapper">
                  {(farmList.data && rolesDetails.data && masterData.data) || profileDisplayCheck
                    ? children
                    : null}
                  {!farmList.data && !rolesDetails.data && !masterData.data && !error && (
                    <Loader styleName="layout-loader" />
                  )}
                  {error && !profileDisplayCheck && (
                    <div className={`full-width ${isMobile ? 'main-content-mobile' : 'main-content'}`}>
                      <Header
                        bcLink={{ to: '/tasks', text: intl.formatMessage({ id: 'TASKS' }) }}
                        bcTitle={intl.formatMessage({ id: 'HEADER_TASKS' })}
                      />
                      <CommonError />
                    </div>
                  )}
                  <Footer
                    showTermsAndCondition={showTermsAndCondition}
                    openTermsAndCondition={this.openTermsAndCondition}
                    closeTermsAndCondition={this.closeTermsAndCondition}
                    userDetails={userDetails}
                  />
                </div>
                {isMobile &&
                <Box sx={{ pb: 7 }}>
                  <Paper
                    sx={{
                      position: 'fixed', 
                      bottom: 0, 
                      left: 0, 
                      right: 0, 
                      backgroundColor: '#ffffff', 
                      zIndex: 10,
                      boxShadow: 3
                    }}
                  >
                    <BottomNavigation showLabels
                      sx={{
                        display: 'flex',
                        padding: '0px 8px',
                        alignItems: 'center',
                        gap: '8px',
                        height: '80px'
                      }}
                    >
                      <Link to="/managefields" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_FIELD);}} style={{ textDecoration: 'none' }}>
                        <BottomNavigationAction showLabel style={mobileHeading} label={intl.formatMessage({ id: 'MOBILE_HEADING_1' })} icon={<DotIcon />} />
                      </Link>
                      <Link to="/tasks" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_TASKS);}} style={{ textDecoration: 'none' }}>
                        <BottomNavigationAction showLabel style={mobileHeading} label={intl.formatMessage({ id: 'MOBILE_HEADING_2' })} icon={<DotIcon />} />
                      </Link>
                      <Link to="/grassanalysis">
                        <BottomNavigationAction showLabel style={mobileHeading} label={intl.formatMessage({ id: 'MOBILE_HEADING_3' })} icon={<DotIcon />} />
                      </Link>
                      <BottomNavigationAction showLabel style={mobileHeading} label={intl.formatMessage({ id: 'MOBILE_HEADING_4' })} icon={<DotIcon />} />
                    </BottomNavigation>
                  </Paper>
                </Box>}
              </FlexGridItem>
            </EanaWebGridLayout>
          </EanaWebPageWrapper>
        </EanaWebBody>
      </AppContainer>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  rolesDetails: state.RolesDetails,
  farmList: state.farmList,
  masterData: state.masterData,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getFarmList: (lastaccessedFarm, version) => dispatch(fetchFarmList(lastaccessedFarm, version)),
  dataPermission: token => dispatch(dataValidationPermission(token)),
  getRolesDetails: token => dispatch(fetchRolePermistionList(token)),
  getMasterData: () => dispatch(fetchMasterData()),
  supportAdminPermission: () => dispatch(fetchSupportAdminPermission())
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout)));
