import _ from 'lodash';
import {
  API_ENDPOINT,
  GET_ASSETS_TYPE,
  GET_ASSET_TYPE_ENTITY,
  CREATE_ASSET,
  UPDATE_ASSET
} from 'constants/ApiUrl';
import {
  ADD_ASSET_SUCCESS,
  ADD_ASSET_ERROR,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
  ASSET_TYPE,
  ASSET_TYPE_FAILURE,
  ASSET_TYPE_ENTITY,
  ASSET_TYPE_ENTITY_FAILURE,
  CLEAR_ASSET_MESSAGES,
  IS_ASSET_LOADING,
  ASSET_LIST_ERROR,
  ASSET_LIST,
  ASSET_SORT_BY_OPTION,
  ASSET_ENTITY_LOADING
} from 'constants/store/Assets';
import apiRequest from 'utils/ApiRequest';
import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_ADD_ASSET,
  GA_ADD_ASSET_ERR,
  GA_UPDATE_ASSET,
  GA_DELETE_ASSET,
  GA_ASSET_LBL,
  GA_CAT_ERR,
  GA_CAT_ADD,
  GA_CAT_UPDATE,
  GA_CAT_DETELE
} from 'constants/GoogleAnalytics';

const assetType = (category, data) => ({ type: ASSET_TYPE, category, payload: data });
const assetTypeError = (category, data) => ({ type: ASSET_TYPE_FAILURE, category, payload: data });
const assetTypeEntity = data => ({ type: ASSET_TYPE_ENTITY, payload: data });
const assetTypeEntityError = data => ({
  type: ASSET_TYPE_ENTITY_FAILURE,
  payload: data
});
const addAsset = data => ({ type: ADD_ASSET_SUCCESS, payload: data });
const addAssetError = data => ({ type: ADD_ASSET_ERROR, payload: data });
const removeAsset = assetId => ({ type: DELETE_ASSET_SUCCESS, payload: assetId });
const removeAssetError = data => ({
  type: DELETE_ASSET_ERROR,
  payload: data
});
const assetList = (category, data) => ({ type: ASSET_LIST, category, payload: data });
const assetListError = (category, data) => ({ type: ASSET_LIST_ERROR, category, payload: data });
const assetLoading = bool => ({ type: IS_ASSET_LOADING, payload: bool });
export const assertSortingOption = (category, option) => ({
  type: ASSET_SORT_BY_OPTION,
  payload: option,
  category
});
// To clear action messages of assets
export const clearAssetMessage = () => ({ type: CLEAR_ASSET_MESSAGES });

/**
 * to get asset type Vehicle or Equipment
 * @param {string} category
 * @param {string} farmId
 */
export const getAssetTypes = (category, farmId) => async dispatch => {
  dispatch(assetLoading(true));
  await dispatch(
    apiRequest({
      url: `${GET_ASSETS_TYPE}${category}/${farmId}`,
      method: 'get'
    })
  )
    .then(response => {
      let { data } = response;
      if (data.length >= 1) {
        data = _.orderBy(data, [type => type.typeName.toLowerCase()], ['asc']);
      }
      dispatch(assetType(category, data));
    })
    .catch(error => {
      dispatch(assetTypeError(category, error));
    });
};

/**
 * To get Asset Entity Details for a given typeId
 * @param {string} typeId
 * @param {string} farmId
 */
export const getAssetEntityDetails = (typeId, farmId) => async dispatch => {
  dispatch({ type: ASSET_ENTITY_LOADING, payload: true });
  await dispatch(
    apiRequest({
      url: `${GET_ASSET_TYPE_ENTITY}${farmId}/${typeId}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch(assetTypeEntity(response.data));
    })
    .catch(error => {
      dispatch(assetTypeEntityError(error));
    });
};

/**
 * To create asset for the farm
 * @param {object} data
 * @param {string} farmId
 */
export const createAsset = (data, farmId) => async dispatch => {
  dispatch(assetLoading(true));
  await dispatch(
    apiRequest({
      url: `${CREATE_ASSET}${farmId}`,
      data,
      method: 'post'
    })
  )
    .then(response => {
      GAEvent(GA_ADD_ASSET, GA_CAT_ADD, GA_ASSET_LBL);
      dispatch(addAsset(response.data));
    })
    .catch(error => {
      GAEvent(GA_ADD_ASSET_ERR, GA_CAT_ERR, GA_ASSET_LBL);
      dispatch(addAssetError(error));
    });
};

/**
 * To update asset of the farm
 * @param {object} data
 * @param {string} farmId
 */
export const updateAsset = (data, farmId) => async dispatch => {
  dispatch(assetLoading(true));
  await dispatch(
    apiRequest({
      url: `${UPDATE_ASSET}${farmId}`,
      data,
      method: 'put'
    })
  )
    .then(response => {
      GAEvent(GA_UPDATE_ASSET, GA_CAT_UPDATE, GA_ASSET_LBL);
      dispatch(addAsset(response.data));
    })
    .catch(error => {
      dispatch(addAssetError(error));
    });
};

/**
 * Delete asset of the farm
 * @param {string} farmId
 * @param {string} assetId
 */
export const deleteAsset = (farmId, assetId) => async dispatch => {
  dispatch(assetLoading(true));
  await dispatch(
    apiRequest({
      url: `${API_ENDPOINT}farms/${farmId}/assets/${assetId}`,
      method: 'delete'
    })
  )
    .then(response => {
      GAEvent(GA_DELETE_ASSET, GA_CAT_DETELE, GA_ASSET_LBL);
      dispatch(removeAsset(assetId));
    })
    .catch(error => {
      dispatch(removeAssetError(error));
    });
};

/**
 * To get list vehicle or equipment of farm by passing the category
 * @param {string} category
 * @param {string} farmId
 */
export const getFarmAssetList = (category, farmId) => async dispatch => {
  dispatch(clearAssetMessage());
  dispatch(assetLoading(true));
  await dispatch(
    apiRequest({
      url: `${API_ENDPOINT}farms/${farmId}/allassets/${category}`,
      method: 'get'
    })
  )
    .then(response => {
      let allAssetsList = response.data;
      if (allAssetsList.length >= 1) {
        allAssetsList = _.orderBy(allAssetsList, [data => data.asset.model.toLowerCase()], ['asc']);
      }
      dispatch(assetList(category, allAssetsList));
    })
    .catch(error => {
      dispatch(assetListError(category, error));
    });
};
