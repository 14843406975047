import CommonTranslation from 'translation/en/en.json';
import PesticideAgreement from 'translation/nb/PesticideAgreement.json';
import Stats from 'translation/en/stats';
import Feedback from 'translation/en/feedback';
import CreateFieldHelp from 'translation/en/createFieldHelp.json';

export default {
  ...CommonTranslation,
  ...PesticideAgreement,
  ...Stats,
  ...Feedback,
  ...CreateFieldHelp
};
