/**
 * To perfoem the manage user CURD operations
 * If any operation throws error will be stored in updateUser by user ID and will be cleared on loading / success
 */
import _ from 'lodash';
import {
  ADD_USER,
  ADD_USER_FAILURE,
  IS_ADD_USER_LOADING,
  IS_GET_ROLES_LOADING,
  GET_ROLES,
  GET_ROLES_FAILURE,
  GET_USERS,
  GET_USERS_FAILURE,
  NULLFIY_USER_MESSAGES,
  IS_USERS_LOADING,
  UPDATE_USER_ROLE,
  UPDATE_USER_FAILURE,
  IS_UPDATE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from 'constants/store/ManageUsers';
import { removeArrayElm } from 'utils';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMsg: null,
  isSuccess: false,
  updateUser: {
    usersUpdateError: [], // user id which errors on update / detele and will be cleared on loading / success
    updatingUsers: [] // has user id which updating / Deleting will be cleared the users on success / failure
  },
  rolesList: {
    isGetRolesLoading: false,
    isGetRolesFailure: false,
    getRolesFaliureMsg: ''
  },
  userList: {
    isUsersLoading: false,
    isUsershasError: false,
    userErrorMsg: ''
  }
};

export const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    // Roles information
    case IS_GET_ROLES_LOADING:
      return {
        ...state,
        rolesList: { ...state.rolesList, isGetRolesLoading: true, isGetRolesFailure: true }
      };
    case GET_ROLES:
      return {
        ...state,
        rolesList: { ...state.rolesList, roles: action.payload, isGetRolesLoading: false }
      };
    case GET_ROLES_FAILURE:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          getRolesFaliureMsg: action.payload.errorMsg,
          isGetRolesFailure: true,
          isGetRolesLoading: false
        }
      };
    // User list information
    case IS_ADD_USER_LOADING:
      return { ...state, hasError: false, isLoading: action.payload };
    case ADD_USER:
      return {
        ...state,
        isLoading: false,
        errorMsg: null,
        hasError: false,
        isSuccess: true,
        data: action.data
      };
    case ADD_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMsg: action.payload,
        isSuccess: false,
        isLoading: false
      };
    case GET_USERS:
      return {
        ...state,
        userList: {
          hasError: false,
          errorMsg: '',
          isUsersLoading: false,
          users: action.payload
        }
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        userList: {
          users: [],
          hasError: true,
          errorMsg: action.payload.errorMsg,
          isUsersLoading: false
        }
      };
    case IS_USERS_LOADING:
      return {
        ...state,
        userList: {
          hasError: false,
          errorMsg: '',
          isUsersLoading: true
        }
      };
    // Update users
    case UPDATE_USER_ROLE: {
      const userIndex = _.findIndex(state.userList.users, item => {
        return item.user.id === action.payload.user.id;
      });
      const { users } = state.userList;
      users[userIndex] = action.payload;
      return {
        ...state,
        userList: {
          hasError: false,
          errorMsg: '',
          isUsersLoading: false,
          users
        },
        updateUser: {
          usersUpdateError: removeArrayElm(
            state.updateUser.usersUpdateError,
            action.payload.user.id
          ),
          updatingUsers: removeArrayElm(state.updateUser.updatingUsers, action.payload.user.id)
        }
      };
    }
    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        updateUser: {
          usersUpdateError: [...state.updateUser.usersUpdateError, action.payload.id],
          updatingUsers: removeArrayElm(state.updateUser.updatingUsers, action.payload.id),
          errorResponse: action.payload.errResponse
        }
      };
    }

    case IS_UPDATE_USER_LOADING:
      return {
        ...state,
        updateUser: {
          usersUpdateError: removeArrayElm(state.updateUser.usersUpdateError, action.payload),
          updatingUsers: [...state.updateUser.updatingUsers, action.payload]
        }
      };
    // Delete users
    case DELETE_USER_SUCCESS:
      _.remove(state.userList.users, user => {
        return user.user.id === action.payload;
      });
      return {
        ...state,
        isUserRoleDelete: action.payload,
        updateUser: {
          usersUpdateError: removeArrayElm(state.updateUser.usersUpdateError, action.payload),
          updatingUsers: removeArrayElm(state.updateUser.updatingUsers, action.payload)
        }
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        updateUser: {
          usersUpdateError: [...state.updateUser.usersUpdateError, action.payload.id],
          updatingUsers: removeArrayElm(state.updateUser.updatingUsers, action.payload.id),
          errorMsg: action.payload.errorMsg,
          errorResponse: action.payload.errResponse
        }
      };
    // To remove all failure / success messages
    case NULLFIY_USER_MESSAGES:
      return {
        ...state,
        isSuccess: false,
        hasError: false,
        updateUser: {
          usersUpdateError: [],
          updatingUsers: [],
          errorMsg: ''
        }
      };
    default:
      return state;
  }
};

export default UsersReducer;
