import {
  ACT1,
  ACT2,
  ACT4,
  ACT5,
  ACT7,
  ACT8,
  ACT9,
  ACT10,
  ACT11,
  ACT12,
  ACT13,
  ACT14,
  ACT15,
  ACT16,
  ACT17,
  ACT18,
  ACT20,
  ACT21,
  ACT22,
  ACT23,
  ACT24,
  ACT25,
  ACT26,
  ACT27,
  ACT28,
  ACT29,
  PESTICIDE_MINERAL_FERTILIZER_EN,
  PESTICIDE_MINERAL_FERTILIZER_NB,
  PESTICIDE_FOILAR_FERTILIZER_EN,
  PESTICIDE_FOILAR_FERTILIZER_NB,
  PESTICIDE_LIMING_EN,
  PESTICIDE_LIMING_NB,
  PESTICIDE_MANURE_EN,
  PESTICIDE_MANURE_NB
} from 'constants/index';
import _ from 'lodash';

export const act1SowingUnitOptions = (activityCommon) => {
  let act1SowingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT1])[0].dropdowns[0].leaves.map(units => (
    act1SowingUnitOptionsArray.push({ label: units, value: units })
  ))
  return act1SowingUnitOptionsArray;
}

export const act9CutAmountOptions = (activityCommon) => {
  let act9CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[0].leaves.map(options => (
    act9CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act9CutAmountOptionsArray;
}

export const act9BalePressUnitOptions = (activityCommon) => {
  let act9BalePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[1].leaves.map(units => (
    act9BalePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return act9BalePressUnitOptionsArray;
}

export const act9BalePressEnsilationOptions = (activityCommon) => {
  let act9BalePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[2].leaves.map(units => (
    act9BalePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return act9BalePressEnsilationOptionsArray;
}

export const act11BalePressUnitOptions = (activityCommon) => {
  let act11BalePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT11])[0].dropdowns[1].leaves.map(units => (
    act11BalePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return act11BalePressUnitOptionsArray;
}

export const act11BalePressEnsilationOptions = (activityCommon) => {
  let act11BalePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT11])[0].dropdowns[2].leaves.map(units => (
    act11BalePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return act11BalePressEnsilationOptionsArray;
}

export const act11CutAmountOptions = (activityCommon) => {
  let act11CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT11])[0].dropdowns[0].leaves.map(options => (
    act11CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act11CutAmountOptionsArray;
}

export const  displayLocations = (locations) => {
  let locationsArray = [];
  locationsArray.push({ label: '', value: '' })
  locations.map(location => (
    locationsArray.push({ label: location.name, value: location.id })
  ))  
  return locationsArray;
}

export const mineralFertilizerOptions = (activityCommon) => {
  let mineralFertilizerOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
    elements.leaves.map(options => (
      mineralFertilizerOptionsArray.push({ label: options, value: options })
    ))
  ))
  return mineralFertilizerOptionsArray;
}

export const act20MineralFertilizerLimingUnitOptions = (activityCommon) => {
  let act20MineralFertilizerLimingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT20])[0].dropdowns[1].leaves.map(options => (
    act20MineralFertilizerLimingUnitOptionsArray.push({ label: options, value: options })
  ))
  return act20MineralFertilizerLimingUnitOptionsArray;
}

export const act20SowingUnitOptions = (activityCommon) => {
  let act20SowingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT20])[0].dropdowns[2].leaves.map(units => (
    act20SowingUnitOptionsArray.push({ label: units, value: units })
  ))
  return act20SowingUnitOptionsArray;
}

export const fertilizerTypeOptions = (activityCommon) => {
  let fertilizerTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map((options, index) => (
    fertilizerTypeOptionsArray.push({ label: options.element, value: (options.element === PESTICIDE_LIMING_EN || options.element === PESTICIDE_LIMING_NB) ? PESTICIDE_LIMING_EN :
      (options.element === PESTICIDE_FOILAR_FERTILIZER_EN || options.element === PESTICIDE_FOILAR_FERTILIZER_NB) ? PESTICIDE_FOILAR_FERTILIZER_EN :
      (options.element === PESTICIDE_MINERAL_FERTILIZER_EN || options.element === PESTICIDE_MINERAL_FERTILIZER_NB) ? PESTICIDE_MINERAL_FERTILIZER_EN :
      (options.element === PESTICIDE_MANURE_EN || options.element === PESTICIDE_MANURE_NB) ? PESTICIDE_MANURE_EN : options.element
   })
  ))
  return fertilizerTypeOptionsArray;
}

export function TemplateFormFields(actId, activityCommon, locations, fieldDetailsOptionsArray) {
  let fields = [];
  switch (actId) {
    case ACT1: /** Sowing */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          disabled: true
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_SEED_MIX',
          label: 'CT_SEEDMIX',
          name: 'seedMix',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'text',
          placeholder: 'AMOUNT',
          label: 'AMOUNT',
          name: 'amount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'unit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180 task-batch-right-border task-batch-border-top',
          options: act1SowingUnitOptions(activityCommon),
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT2: /** Ploughing */
    case ACT4: /** Rolling */
    case ACT8: /** Harrowing */
    case ACT21: /** Subsurface drainage */
    case ACT22: /** Grubbing */
    case ACT23: /** Stone picking */
    case ACT24: /** Dragging */
    case ACT25: /** Pasture topping */
    case ACT26: /** Disking */
    case ACT27: /** Other */
    case ACT29: /** Snow removal */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          disabled: true
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT5: /** Mowing */
    case ACT14: /** Aerate Gras */
    case ACT15: /** Raking */
    case ACT17: /** Transportation wagon */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          disabled: true
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-130 task-batch-right-border',
          valueClass: 'task-batch-padding-130 task-batch-right-border task-batch-border-top',
          options: act9CutAmountOptions(activityCommon)
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT7: /** Fertilization */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_FERTILIZERTYPE',
          label: 'CT_FERTILIZERTYPE',
          name: 'fertilizertype',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fertilizerTypeOptions(activityCommon)
        },
        {
          input_type: 'fertilizersubtype',
          placeholder: 'TYPE',
          label: 'TYPE',
          name: 'fertilizersubtype',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: []
        },
        {
          input_type: 'text',
          placeholder: 'AMOUNT',
          label: 'PLANNED_AMOUNT',
          name: 'fertilizerplanamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'fertilizerplanunit',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'fertilizerplanunit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          options: []
        },
        {
          input_type: 'text',
          placeholder: 'AMOUNT',
          label: 'ACTUAL_AMOUNT',
          name: 'fertilizeramount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'fertilizerunit',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'fertilizerunit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          options: []
        },
        {
          input_type: 'seasonDropdown',
          placeholder: 'CT_SEASON',
          label: 'CT_SEASON',
          name: 'fertilizerseason',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: []
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT9: /** Precision chop silaging */
    case ACT10: /** Forage wagon harvesting */
    case ACT28: /** Self-loading wagon */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-130 task-batch-right-border',
          valueClass: 'task-batch-padding-130 task-batch-right-border task-batch-border-top',
          options: act9CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'CT_ENTERCUTAMOUNT',
          label: 'AMOUNT',
          name: 'cutamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'cutunit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          options: act9BalePressUnitOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_TYPE',
          label: 'CT_ENSILATIONTYPE',
          name: 'ensilationtype',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_AMOUNT',
          label: 'AMOUNT',
          name: 'ensilationamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'ensilationunit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180 task-batch-right-border task-batch-border-top',
          options: act9BalePressEnsilationOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200-units task-batch-right-border task-batch-border-top',
          units: 'ADD_ANALYSIS_PARAMS_4_UNIT'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200-units task-batch-right-border task-batch-border-top',
          units: 'ADD_ANALYSIS_KG'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT11: /** Combi Baling */
    case ACT12: /** Baling */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-130 task-batch-right-border',
          valueClass: 'task-batch-padding-130 task-batch-right-border task-batch-border-top',
          options: act11CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'HARVEST_QUANTITY_PLACEHOLDER',
          label: 'HARVEST_QUANTITY',
          name: 'cutamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'HARVEST_LOCATION_LABEL',
          label: 'HARVEST_LOCATION_LABEL',
          name: 'locationId',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: displayLocations(locations)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_TYPE',
          label: 'CT_ENSILATIONTYPE',
          name: 'ensilationtype',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_AMOUNT',
          label: 'AMOUNT',
          name: 'ensilationamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'ensilationunit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180 task-batch-right-border task-batch-border-top',
          options: act11BalePressEnsilationOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'input-textbox-task-batch task-batch-text-align-left',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180-units task-batch-right-border task-batch-border-top',
          units: 'ADD_ANALYSIS_PARAMS_4_UNIT'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180-units task-batch-right-border task-batch-border-top',
          units: 'ADD_ANALYSIS_KG'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT13: /** Treshing */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_TOTAL_HARVEST',
          label: 'CT_TOTALHARVEST',
          name: 'totalharvest',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-230 task-batch-right-border',
          valueClass: 'task-batch-padding-230-units task-batch-right-border task-batch-border-top',
          units: 'TASK_KG_DAA'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WATER_PERCENT',
          label: 'CT_WATERPERCENTAGE',
          name: 'waterpercentage',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200-units task-batch-right-border task-batch-border-top',
          units: 'ADD_ANALYSIS_PARAMS_4_UNIT'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT16: /** Bale Wrapping */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-130 task-batch-right-border',
          valueClass: 'task-batch-padding-130 task-batch-right-border task-batch-border-top',
          options: act11CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'HARVEST_QUANTITY_PLACEHOLDER',
          label: 'HARVEST_QUANTITY',
          name: 'cutamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'HARVEST_LOCATION_LABEL',
          label: 'HARVEST_LOCATION_LABEL',
          name: 'locationId',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: displayLocations(locations)
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT18: /** Transportation bales */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-130 task-batch-right-border',
          valueClass: 'task-batch-padding-130 task-batch-right-border task-batch-border-top',
          options: act11CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'HARVEST_QUANTITY_PLACEHOLDER',
          label: 'HARVEST_QUANTITY',
          name: 'cutamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'HARVEST_LOCATION_LABEL',
          label: 'HARVEST_LOCATION_LABEL',
          name: 'locationId',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: displayLocations(locations)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180-units task-batch-right-border task-batch-border-top',
          units: 'ADD_ANALYSIS_PARAMS_4_UNIT'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180-units task-batch-right-border task-batch-border-top',
          units: 'ADD_ANALYSIS_KG'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    case ACT20: /** Combo fertilization and sowing */
      fields = [
        {
          input_type: 'fielddropdown',
          placeholder: 'TASK_BATCH_FIELD_NAME',
          label: 'TASK_BATCH_FIELD_NAME',
          name: 'fieldName',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[0]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_VEHICLE',
          label: 'TASK_BATCH_VEHICLE',
          name: 'vehicle',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[1]
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_EQUIPMENT',
          label: 'TASK_BATCH_EQUIPMENT',
          name: 'equipment',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[2]
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_START_TIME',
          name: 'startTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'date',
          placeholder: 'TASK_BATCH_TIME_PATTERN',
          label: 'TASK_BATCH_END_TIME',
          name: 'endTime',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-220 task-batch-right-border',
          valueClass: 'task-batch-padding-220 task-batch-right-border task-batch-border-top',
          disabled: false
        },
        {
          input_type: 'label',
          placeholder: 'TASK_BATCH_DURATION',
          label: 'TASK_BATCH_DURATION',
          name: 'duration',
          stlyeClass: 'input-content-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_WEATHER',
          label: 'TASK_BATCH_WEATHER',
          name: 'weather',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-200 task-batch-right-border',
          valueClass: 'task-batch-padding-200 task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[3]
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_MINERALFERTILIZER',
          label: 'CT_MINERALFERTILIZER',
          name: 'fertilizertype',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: mineralFertilizerOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_AMOUNT',
          label: 'AMOUNT',
          name: 'fertilizeramount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'mineralfertilizerunit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top',
          options: act20MineralFertilizerLimingUnitOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_SEED_MIX',
          label: 'CT_SEEDMIX',
          name: 'seedmix',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_AMOUNT',
          label: 'AMOUNT',
          name: 'sowingamount',
          stlyeClass: 'input-textbox-task-batch',
          headerClass: 'task-batch-padding-160 task-batch-right-border',
          valueClass: 'task-batch-padding-160 task-batch-right-border task-batch-border-top'
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'sowingunit',
          stlyeClass: 'select-box-common',
          headerClass: 'task-batch-padding-180 task-batch-right-border',
          valueClass: 'task-batch-padding-180 task-batch-right-border task-batch-border-top',
          options: act20SowingUnitOptions(activityCommon)
        },
        {
          input_type: 'dropdown',
          placeholder: 'TASK_BATCH_ASSIGNEE',
          label: 'TASK_BATCH_ASSIGNEE',
          name: 'assignee',
          stlyeClass: 'select-box-common user-dropdown-normal',
          headerClass: 'task-batch-padding task-batch-right-border',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top',
          options: fieldDetailsOptionsArray[4]
        },
        {
          input_type: 'textarea',
          placeholder: 'TASK_BATCH_NOTE',
          label: 'TASK_BATCH_NOTE',
          name: 'notes',
          stlyeClass: 'textareafield-placeholder-size',
          headerClass: 'task-batch-padding',
          valueClass: 'task-batch-padding task-batch-right-border task-batch-border-top'
        }
      ];
      break;
    default:
      fields = [];
  }
  return fields;
}