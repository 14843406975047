import axios from 'axios';
import { TERMS_CHECK, GET_TERMS, ACCEPT_TERM } from 'constants/ApiUrl';
import {
  IS_TERMS_CONDITION_LOADING,
  TERMS_CONDITION_FAILURE,
  TERMS_CONDITION_SUCCESS,
  TERMS_CHECK_SUCCESS,
  TERMS_ACCEPT_SUCCESS
} from 'constants/store/Auth';

/** Action get farm details */
const termsHasError = (bool, error) => ({
  type: TERMS_CONDITION_FAILURE,
  hasError: bool,
  errorMessage: error
});

const IsTermsLoading = bool => ({
  type: IS_TERMS_CONDITION_LOADING,
  isLoading: bool
});

const termsSuccess = data => ({
  type: TERMS_CONDITION_SUCCESS,
  data
});

const CheckSuccess = data => ({
  type: TERMS_CHECK_SUCCESS,
  data
});

const acceptTermsSuccess = data => ({
  type: TERMS_ACCEPT_SUCCESS,
  data
});

/**
 * Get terms and condition api
 * @param {token}
 */
export const termsCheck = token => async dispatch => {
  try {
    dispatch(IsTermsLoading(true));
    await axios(TERMS_CHECK, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        dispatch(CheckSuccess(response.data));
      })
      .catch(error => {
        dispatch(termsHasError(true, error));
      });
  } catch (error) {
    dispatch(termsHasError(true, error));
  }
};

/**
 * Get terms and condition api
 * @param {token}
 */
export const getTermsCondition = (token, language) => async dispatch => {
  try {
    dispatch(IsTermsLoading(true));
    await axios(`${GET_TERMS}${language}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        dispatch(termsSuccess(response.data));
      })
      .catch(error => {
        dispatch(termsHasError(true, error));
      });
  } catch (error) {
    dispatch(termsHasError(true, error));
  }
};

/**
 * Get terms and condition api
 * @param {token}
 */
export const acceptTermsCondition = (token, language, versionId) => async dispatch => {
  try {
    dispatch(IsTermsLoading(true));
    const data = { language, versionId };
    await axios(ACCEPT_TERM, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      data
    })
      .then(response => {
        dispatch(acceptTermsSuccess(response.data));
      })
      .catch(error => {
        dispatch(termsHasError(true, error));
      });
  } catch (error) {
    dispatch(termsHasError(true, error));
  }
};
