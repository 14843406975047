export const GET_FARM_LIST = 'GET_FARM_LIST';
export const IS_FARM_LIST_LOADING = 'IS_FARM_LIST_LOADING';
export const IS_FARM_LIST_FAILURE = 'IS_FARM_LIST_FAILURE';
export const ADD_FARM_FAILURE = 'ADD_FARM_FAILURE';
export const ADD_FARM_SUCCESS = 'ADD_FARM_SUCCESS';
export const UPDATE_FARM_SUCCESS = 'UPDATE_FARM_SUCCESS';
export const DELETE_FARM_SUCCESS = 'DELETE_FARM_SUCCESS';
export const DELETE_FARM_FAILURE = 'DELETE_FARM_FAILURE';
export const DELETE_FARM_CHECK_SUCCESS = 'DELETE_FARM_CHECK_SUCCESS';
export const DELETE_FARM_CHECK_FALIURE = 'DELETE_FARM_CHECK_FALIURE';
export const CLEAR_FARM_MESSAGES = 'CLEAR_FARM_MESSAGES';
