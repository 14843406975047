import * as React from 'react';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';

function filterListContent(fieldFertilizationItem, editFertilizerPlan, generatePdfFertilizerPlan, index, intl) {
  let data = '';
  let viewValue =
    'view~~' +
    fieldFertilizationItem.planName +
    '~~' +
    fieldFertilizationItem.year +
    '~~' +
    fieldFertilizationItem.version;
  if (index > 0) {
    let classNameData = 'fertilizer-plan-list-content-padding';
    if (index === 1) {
      classNameData = classNameData + ' padding-top-low';
    }
    data = (
      <div key={index} className={classNameData}>
        <div className="fertilizer-plan-list-content">
          <div className="fertilization-plan-list-content-item_header">
            <div>
              <span>
                <EmphasisLight size="normal">
                  V.{fieldFertilizationItem.version}
                </EmphasisLight>
              </span>
              <span className="div-padding-left-high">
                <MetaInformation size="small">{fieldFertilizationItem.summaryText}</MetaInformation>
              </span>
            </div>
            <div className="fertilization-plan-list-content-item_link">
              <div className="padding-right-class">
                <MetaInformation size="small">
                  {intl.formatMessage({ id: 'CREATED' })}: {fieldFertilizationItem.createdTime}
                </MetaInformation>
              </div>
              <div className="padding-right-class-low">
                <span className="plan-list-edit-button-border" 
                  onClick={e => generatePdfFertilizerPlan(e)}
                  name={viewValue}
                >
                  {intl.formatMessage({ id: 'REPORTS_EXPORT' })}
                </span>
              </div>
              <div className="padding-right-class-low">
                <span
                  className="plan-list-edit-button-border"
                  onClick={e => editFertilizerPlan(e)}
                  name={viewValue}
                >
                  {intl.formatMessage({ id: 'VIEW' })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return data;
}

function getArrayListContent(fertilizerPlanList, editFertilizerPlan, generatePdfFertilizerPlan, intl) {
  return fertilizerPlanList.map((fieldFertilizationItem, index) =>
    filterListContent(fieldFertilizationItem, editFertilizerPlan, generatePdfFertilizerPlan, index, intl)
  );
}

function getListContent(fertilizerPlanList, editFertilizerPlan, generatePdfFertilizerPlan, index, intl) {
  if (fertilizerPlanList?.length > 1) {
    let classNameValue = 'fertilizer-plan-list-content-background';
    if (index === 0) {
      classNameValue = classNameValue + ' border-left-green';
    } else {
      classNameValue = classNameValue + ' border-left-grey';
    }
    return (
      <div className={classNameValue}>
        {getArrayListContent(fertilizerPlanList, editFertilizerPlan, generatePdfFertilizerPlan, intl)}
      </div>
    );
  } else {
    return null;
  }
}

export default ({ fertilizerPlanList, editFertilizerPlan, generatePdfFertilizerPlan, index, intl }) =>
  getListContent(fertilizerPlanList, editFertilizerPlan, generatePdfFertilizerPlan, index, intl);
