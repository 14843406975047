import {
  EXPORT_FIELD_LOADING, 
  EXPORT_FIELD_SHAPE,
  EXPORT_FIELD_SHAPE_ERROR
} from 'constants/store/Fields';

const initialState = {
  hasExportError: false,
  isExportLoading: false,
  exportErrorMessage: null,
  exportSuccess: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case EXPORT_FIELD_LOADING:
      return {
        ...state,
        isExportLoading: true,
        hasExportError: false,
        exportErrorMessage: null
      };
    case EXPORT_FIELD_SHAPE_ERROR:
      return {
        ...state,
        hasExportError: true,
        exportErrorMessage: action.payload,
        isExportLoading: false
      };
    case EXPORT_FIELD_SHAPE:
      return {
        ...state,
        isExportLoading: false,
        exportErrorMessage: null,
        exportSuccess: action.payload
      };
    default:
      return state;
  }
}
