/**
 * Data validation index component has tab module to switch between the settings componenets
 */
import React, { Component } from 'react';
import _ from 'lodash';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import DataInfo from 'containers/datavalidation/DataInfo';
import { GetDayTimeDiff } from 'utils/index';
import { EanaMap } from 'utils/map';

class GPSData extends Component {
  constructor(props) {
    super(props);
    this.gpsMap = React.createRef();
    this.state = {
      expectedCount: 0,
      task: null,
      latLng: [],
      actualCount: 0
    };
  }

  componentDidMount = () => {
    this.getDetails();
  };

  componentDidUpdate(prevProps) {
    const { selectedTask } = this.props;
    if (prevProps.selectedTask !== selectedTask) {
      this.getDetails();
    }
  }

  getDetails = () => {
    const { tasks, selectedTask } = this.props;
    this.setState({ expectedCount: 0, actualCount: 0 });
    if (tasks.length > 0 && selectedTask) {
      const [infoData] = _.filter(tasks, ['task.id', selectedTask.value]);
      if (infoData.task.startTime && infoData.task.endTime) {
        const duration = GetDayTimeDiff(
          new Date(infoData.task.startTime),
          new Date(infoData.task.endTime)
        );
        this.setState({
          expectedCount: Math.round(duration.minutes * 2)
        });
      }
      let latLng = [];
      if (infoData.taskGPSLocationsBO && infoData.taskGPSLocationsBO.gpsPoints) {
        latLng = infoData.taskGPSLocationsBO.gpsPoints.map(data => [
          data.lattitude,
          data.longitude
        ]);
      }
      this.setState({
        task: infoData,
        latLng,
        actualCount: latLng.length
      });
      if (latLng.length > 0) {
        EanaMap.drawPolyine(this.gpsMap.current.id, latLng);
      }
    }
  };

  render() {
    const { selectedTask } = this.props;
    const { task, expectedCount, actualCount, latLng } = this.state;
    return (
      <SectionContainer
        styling={{
          paddingAllSides: 'none',
          paddingLeft: 'low',
          paddingRight: 'low'
        }}
      >
        <DataInfo
          taskDetails={task}
          selectedTask={selectedTask}
          expectedCount={expectedCount}
          actualCount={actualCount}
        />
        <div className="map-container">
          <SectionContainer
            styling={{
              paddingAllSides: 'none',
              paddingTop: 'low'
            }}
          >
            <p>{selectedTask && latLng.length > 0 ? null : 'No data to draw path'}</p>
            <div
              className={
                selectedTask && latLng.length > 0 ? 'map-data show-map' : 'map-data hide-map'
              }
            >
              <div id="gpsMap" ref={this.gpsMap} style={{ width: '100%', height: '500px' }} />
            </div>
          </SectionContainer>
        </div>
      </SectionContainer>
    );
  }
}

export default GPSData;
