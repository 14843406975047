import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AddIcon from '@tine/lib-frontend-components/elements/icons/add-icon/index';
import { Field } from 'redux-form';

import SelectControl from 'components/forms/SelectBoxControl';
import AddVehicleEquipment from 'containers/tasks/tasklist/AddVehicleEquipment';
import { connect } from 'react-redux';
import { fetchAcitvityList } from 'actions/ActivityList';
import { VEHICLES, EQUIPMENTS } from 'constants/index';
import * as fieldActions from 'actions/Fields';
import * as taskActions from 'actions/Tasks';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import { ActivityTranslation } from 'constants/DropdownAndTranslationOptions';

class FieldActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddMachineModal: false,
      activityList: props.activityList,
      activeFarmDetails: props.activeFarmDetails,
      vehicleList: props.taskDetails.vehicleList,
      equipmentList: props.taskDetails.equipmentList,
      allVehicles: props.taskDetails.allVehicles,
      allEquipments: props.taskDetails.allEquipments,
      title: '',
      selectedDropdown: 'vehicle',
      paramId: props.match.params.id,
      showPrompt: false,
      onChangeActivity: null
    };
  }

  componentDidMount() {
    const {
      getAcitvityList,
      userInfo,
      activeFarm,
      fetchFieldList,
      getVehicles,
      getEquipment,
      getAllAssets,
      taskDetails
    } = this.props;
    const { allEquipments, allVehicles } = taskDetails;
    const { activeFarmDetails, vehicleList, equipmentList } = this.state;
    if (activeFarm && !activeFarmDetails) {
      fetchFieldList(activeFarm.farm.id);
    }
    getAcitvityList(userInfo.data.idToken);
    if (activeFarm && !vehicleList) {
      getVehicles(userInfo.data.idToken, activeFarm.farm.id);
    }
    if (activeFarm && !equipmentList) {
      getEquipment(userInfo.data.idToken, activeFarm.farm.id);
    }
    if (activeFarm && !(allEquipments || allVehicles)) {
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.activityList !== nextProps.activityList ||
      prevState.activeFarmDetails !== nextProps.activeFarmDetails ||
      prevState.vehicleList !== nextProps.taskDetails.vehicleList ||
      prevState.equipmentList !== nextProps.taskDetails.equipmentList ||
      prevState.allVehicles !== nextProps.taskDetails.allVehicles ||
      prevState.allEquipments !== nextProps.taskDetails.allEquipments
    ) {
      return {
        activityList: nextProps.activityList,
        activeFarmDetails: nextProps.activeFarmDetails,
        vehicleList: nextProps.taskDetails.vehicleList,
        equipmentList: nextProps.taskDetails.equipmentList,
        allVehicles: nextProps.taskDetails.allVehicles,
        allEquipments: nextProps.taskDetails.allEquipments
      };
    }
    if (nextProps.activeFarm !== prevState.activeFarm && nextProps.activeFarm !== undefined) {
      return { activeFarm: nextProps.activeFarm };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      activeFarm,
      userInfo,
      fetchFieldList,
      getVehicles,
      getEquipment,
      getAcitvityList,
      taskDetails,
      getAllAssets
    } = this.props;
    const { createAssetEntityIsSuccess } = taskDetails;
    if (prevProps.activeFarm !== activeFarm) {
      fetchFieldList(activeFarm.farm.id);
      getAcitvityList(userInfo.data.idToken);
      getVehicles(userInfo.data.idToken, activeFarm.farm.id);
      getEquipment(userInfo.data.idToken, activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
    }
    if (createAssetEntityIsSuccess) {
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
    }
  }

  // callback function to dismiss lightbox
  dismissBox = () => {
    this.setState({ showAddMachineModal: false });
  };

  // To show lightbox
  showAddMachine = item => {
    this.setState({
      showAddMachineModal: true,
      title: item,
      selectedDropdown: item === 'CT_AV' ? 'vehicle' : 'equipment'
    });
  };

  // To fetch field options
  fieldOptions = () => {
    const { activeFarmDetails } = this.state;
    if (activeFarmDetails) {
      return activeFarmDetails.map(data => ({ value: data.fieldId, label: data.displayName }));
    }
    return [];
  };

  // To fetch activity options
  activityOptions = () => {
    const { activityList } = this.props;
    if (activityList && activityList.data) {
      return activityList.data.map(option => ({
        value: option.id,
        label: option.activityName
      }));
    }
    return [];
  };

  // To fetch all vehicle options
  vehicleOptions = () => {
    const { allVehicles } = this.state;
    if (allVehicles && allVehicles.length > 0) {
      return allVehicles.map(data => ({
        value: data.asset.assetId,
        label: `${data.asset.brand} ${data.asset.model}`
      }));
    }
    return [];
  };

  // To fetch all equipment options
  equipmentOptions = () => {
    const { allEquipments } = this.state;
    if (allEquipments && allEquipments.length > 0) {
      return allEquipments.map(data => ({
        value: data.asset.assetId,
        label: `${data.asset.brand} ${data.asset.model}`
      }));
    }
    return [];
  };

  // change handler for activity
  onActivityChange = option => {
    const { showDynamicSectionCallback } = this.props;
    const { paramId } = this.state;
    this.setState({ showPrompt: true });
    if(paramId === 'create') {
      showDynamicSectionCallback(option);
    }
    this.setState({onChangeActivity: option});
  };

  // change handler for field
  onFieldChange = option => {
    const { getFieldDetail } = this.props;
    getFieldDetail(option.value, 'old');
  };

  // To compute based on entered modal action
  showDialog = action => {
    const { change, showDynamicSectionCallback, editTaskDetails } = this.props;
    const { showPrompt, onChangeActivity } = this.state;
    let selectedActivityOption = {};
    if (editTaskDetails && editTaskDetails.isGetTaskData && editTaskDetails.isGetTaskData.activityId) {
      selectedActivityOption = {
        value: editTaskDetails.isGetTaskData.activityId,
        label: <FormattedMessage id={ActivityTranslation(editTaskDetails.isGetTaskData.activityName)} />
      };
      showDynamicSectionCallback(onChangeActivity);    
    }
    if (!action) {
      change('activity', selectedActivityOption);
      showDynamicSectionCallback(selectedActivityOption);
    }
    this.setState({ showPrompt: !showPrompt });
  };

  render() {
    const {
      showAddMachineModal,
      title,
      vehicleList,
      equipmentList,
      selectedDropdown,
      showPrompt,
      paramId
    } = this.state;
    const { intl } = this.props;
    const taskEditPromptMsg = `${intl.formatMessage({ id: 'ACT_EDIT_PROMPT' })}`;
    const AddVehicle = (
      <div
        className="add-vehicle"
        onClick={() => this.showAddMachine('CT_AV')}
        role="button"
        onKeyPress={() => this.showAddMachine('CT_AV')}
        tabIndex={-1}
      >
        <p>
          <FormattedMessage id="CT_ANV" />
        </p>
        <span>
          <AddIcon />
        </span>
      </div>
    );
    const AddEquipment = (
      <div
        className="add-vehicle"
        onClick={() => this.showAddMachine('CT_AE')}
        role="button"
        onKeyPress={() => this.showAddMachine('CT_AE')}
        tabIndex={-1}
      >
        <p>
          <FormattedMessage id="CT_ANE" />
        </p>
        <span>
          <AddIcon />
        </span>
      </div>
    );

    const addVehicleOptions = [
      {
        label: AddVehicle,
        options: this.vehicleOptions()
      }
    ];
    const addEquipmentOptions = [
      {
        label: AddEquipment,
        options: this.equipmentOptions()
      }
    ];
    //const isCreate = paramId === 'create';
    const isCreate = true;
    return (
      <div className="create-task-field-activity">
        <div className="field-activity-heading">
          <span className="black-dot" />
          <span>
            <FormattedMessage id="TASKS_FA" />
          </span>
        </div>
        <div className="field-activity-dropdowns">
          <div className="dropdowns">
            <Field
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'FIELD' })}
              options={this.fieldOptions()}
              component={SelectControl}
              name="field"
              label={intl.formatMessage({ id: 'FIELD' })}
              controlId="field"
              hasCheckbox={isCreate}
              isMulti={isCreate}
              allowSelectAll={isCreate}
              selectAllText={intl.formatMessage({ id: 'ALL_FIELDS' })}
            />
          </div>
          <div className="dropdowns">
            <Field
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'TASK' })}
              options={this.activityOptions()}
              onChange={v => this.onActivityChange(v)}
              component={SelectControl}
              name="activity"
              label={intl.formatMessage({ id: 'TASK' })}
              controlId="activity"
            />
          </div>
          <div className="dropdowns">
            <Field
              styleName="select-box-common vehicle"
              placeholder={intl.formatMessage({ id: 'VEHICLE' })}
              options={addVehicleOptions}
              component={SelectControl}
              name="vehicle"
              label={intl.formatMessage({ id: 'VEHICLE' })}
              controlId="vehicle"
              noOptionsMessage={() => AddVehicle}
            />
          </div>
          <div className="dropdowns">
            <Field
              styleName="select-box-common equipment"
              placeholder={intl.formatMessage({ id: 'EQUIPMENT' })}
              options={addEquipmentOptions}
              component={SelectControl}
              name="equipment"
              label={intl.formatMessage({ id: 'EQUIPMENT' })}
              controlId="equipment"
              noOptionsMessage={() => AddEquipment}
            />
          </div>
        </div>
        {showAddMachineModal ? (
          <AddVehicleEquipment
            dismissBox={this.dismissBox}
            show={showAddMachineModal}
            title={title}
            type={selectedDropdown === 'vehicle' ? vehicleList : equipmentList}
            assetType={selectedDropdown === 'vehicle' ? VEHICLES : EQUIPMENTS}
          />
        ) : null}
        {showPrompt && paramId !== 'create' && (
          <ConfirmDialogBox
            heading={intl.formatMessage({ id: 'ACT_EDIT_PROMPT_HEAD' })}
            body={taskEditPromptMsg}
            confirmText="ACT_EDIT_PROMPT_CONFIRM"
            cancelText="CANCEL"
            callback={action => this.showDialog(action)}
            warning={false}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAcitvityList: token => dispatch(fetchAcitvityList(token)),
  fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
  getVehicles: (token, id) => dispatch(taskActions.getVehicles(token, id)),
  getEquipment: (token, id) => dispatch(taskActions.getEquipment(token, id)),
  getAllAssets: (token, category, farmId) =>
    dispatch(taskActions.getAllAssets(token, category, farmId))
});

const mapStateToProps = state => ({
  activityList: state.ActivityList,
  userInfo: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  activeFarmDetails: state.fieldDetails.data,
  taskDetails: state.taskDetails
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FieldActivity));
