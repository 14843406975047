import * as React from 'react';
import { Field } from 'redux-form';

import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import TextBoxControl from 'components/forms/TextBoxControl';
import SelectControl from 'components/forms/SelectBoxControl';
import DatePicker from 'react-datepicker'; 
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import Loader from 'components/Loader';
import TrashcanIcon from '@tine/lib-frontend-components/elements/icons/trashcan-icon/index';
import IconButton from '@tine/lib-frontend-components/components/buttons/icon-button';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import { getDropDownValuesForYearValue } from 'utils/index';

const GrassAnalysisRegister = ({ showGrassAnalysisModal, grassAnalysis, handleSubmit, intl, fieldOptions, analysisTypeOptions, analysisTypeOptions2, harvestOptions,
  isCreate, onSelectHarvest, showHarvest1, showHarvest2, showHarvest3, showHarvest4, analysisDate, handleChange, 
  noHarvestError, noWeightParameter, closeGrassAnalysisModal, selectedQrCode, showDelete, showDialog, confirmDelete, selectedGrassAnalysis, 
  qrCodeDisabled, renderFeedmixAmountSection, showYieldAnalysis, showFeedMixAnalysis, showTbdAnalysis, handleAnalysisTypeChange, defaultValue,
  selectedYearDropdown, onChangeYear }) => (
    <div>
      <ModalDialog
        minWidth={{mobile: '90%', tablet: '75%', web: '55%'}}
        width={{mobile: '90%', tablet: '75%', web: '55%'}}
        height={{mobile: '90%', tablet: '75%', web: '80%'}}
        show={showGrassAnalysisModal}
        closeIcon=""
        onClose={() => {}}>
          {grassAnalysis && grassAnalysis.isLoading && <Loader styleName="fixed-loader" />}
          <div>
            <div className='text-align-center'>
              {selectedQrCode === "add" ?
              <span className='fertilization-header'>{intl.formatMessage({ id: 'ADD_GRASS_ANALYSIS' })}</span> :
              <span className='fertilization-header'>{intl.formatMessage({ id: 'EDIT_GRASS_ANALYSIS' })}</span>
              }
            </div>
            {selectedQrCode !== "add" && selectedQrCode !== null && <div className="action-menu__items_right">
              <span className={`btn-action btn-action--delete`}>
                <IconButton
                  disabled={false}
                  onClick={() => confirmDelete(selectedGrassAnalysis[0].qrCode) }
                >
                  <TrashcanIcon scale={1} />
                </IconButton>
              </span>
            </div>}
            <form onSubmit={handleSubmit}>
              <div>
                <div className='bale-storage-form-style'>
                  <div className="select-box-common padding-top-bottom">
                    <Field
                      styleName=""
                      placeholder={intl.formatMessage({ id: 'ANALYSIS_TYPE' })}
                      options={(selectedQrCode !== "add" && selectedQrCode !== null) ? analysisTypeOptions(selectedGrassAnalysis[0].qrCode) : analysisTypeOptions2(null)}
                      component={SelectControl}
                      defaultValue={defaultValue}
                      name="analysisType"
                      label={intl.formatMessage({ id: 'ANALYSIS_TYPE' })}
                      controlId="analysisType"
                      onChange={e => handleAnalysisTypeChange(e)}
                      disabled={selectedQrCode === null}
                    />
                  </div>
                  <div className="padding-top-bottom">
                    <Field
                      name="qrCode"
                      label={intl.formatMessage({ id: 'GRASS_ANALYSIS_QR_CODE' })}
                      controlId="qrCode"
                      component={TextBoxControl}
                      type="text"
                      disabled={qrCodeDisabled}
                    />
                  </div>
                  <div className="padding-top-bottom">
                    <div className="padding-top-css-text">
                      <EmphasisLight size="small">
                        {showTbdAnalysis ? intl.formatMessage({ id: 'GRASS_ANALYSIS_FIELD_ANALYSIS_DATE' }) : intl.formatMessage({ id: 'GRASS_ANALYSIS_FIELD_ANALYSIS_DATE' })}
                      </EmphasisLight>
                    </div>
                    <div>
                      <DatePicker  
                        selected={selectedQrCode !== null ? analysisDate : ''} 
                        onChange={handleChange}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select" 
                        name="analysisDate"  
                        dateFormat="yyyy-MM-dd"
                        fixedHeight
                        yearItemNumber={9}
                        disabled={selectedQrCode === null}
                      />
                    </div>
                  </div>
                  <div className="padding-top-bottom" style={{paddingTop: '18px'}}>
                    <Field
                      name="year"
                      label={intl.formatMessage({ id: 'CHOOSE_YEAR_YIELD' })}
                      controlId="year"
                      component={SelectControl}
                      styleName="select-box-common div-max-width"
                      options={getDropDownValuesForYearValue()}
                      style={{maxWidth: '205px'}}
                      defaultValue={selectedYearDropdown}
                      onChange={e => onChangeYear(e)}
                      placeholder=""
                      disabled={selectedQrCode === null}
                    />
                  </div>
                  <div className="padding-top-bottom">
                    <Field
                      name="name"
                      label={intl.formatMessage({ id: 'NAME' })}
                      controlId="name"
                      component={TextBoxControl}
                      type="text"
                      disabled={selectedQrCode === null}
                    />
                  </div>
                  {showYieldAnalysis && 
                  <div>
                    <div className="padding-top-css-text padding-top-bottom">
                      <div className="padding-bottom-div">
                        <EmphasisLight size="small">{intl.formatMessage({ id: 'GRASS_ANALYSIS_HARVEST' })}</EmphasisLight>
                      </div>
                      <div>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(1)}}>
                          {showHarvest1 ?
                            <img src={`./images/h1_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h1.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(2)}}>
                          {showHarvest2 ?
                            <img src={`./images/h2_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h2.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(3)}}>
                          {showHarvest3 ?
                            <img src={`./images/h3_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h3.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(4)}}>
                          {showHarvest4 ?
                            <img src={`./images/h4_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h4.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                      </div>
                    </div>
                    <div className="select-box-common padding-top-css-text padding-top-bottom">
                      <Field
                        styleName={selectedQrCode === null ? '' : `select-box-small select-box-small-margin`}
                        placeholder={intl.formatMessage({ id: 'GRASS_ANALYSIS_FIELD' })}
                        options={(selectedQrCode !== "add" && selectedQrCode !== null) ? fieldOptions(selectedGrassAnalysis[0].qrCode) : fieldOptions(null)}
                        component={SelectControl}
                        name="field"
                        label={intl.formatMessage({ id: 'GRASS_ANALYSIS_FIELD' })}
                        controlId="field"
                        hasCheckbox={isCreate}
                        isMulti={isCreate}
                        allowSelectAll={isCreate}
                        selectAllText={intl.formatMessage({ id: 'ALL_FIELDS' })}
                        disabled={selectedQrCode === null}
                      />
                    </div>
                    <div className="padding-top-bottom">
                      <Field
                        name="weight"
                        label={intl.formatMessage({ id: 'WEIGHT' })}
                        component={TextBoxControl}
                        controlId="weight"
                        type="text"
                        units={intl.formatMessage({ id: 'ADD_ANALYSIS_KG' })}
                        disabled={selectedQrCode === null}
                      />
                    </div>
                  </div>}
                  {showFeedMixAnalysis && <div>{renderFeedmixAmountSection()}</div>}
                  {showTbdAnalysis && 
                  <div>
                    <div className="padding-top-css-text padding-top-bottom">
                      <div className="padding-bottom-div">
                        <EmphasisLight size="small">{intl.formatMessage({ id: 'GRASS_ANALYSIS_TBD_HARVEST' })}</EmphasisLight>
                      </div>
                      <div>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(1)}}>
                          {showHarvest1 ?
                            <img src={`./images/h1_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h1.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(2)}}>
                          {showHarvest2 ?
                            <img src={`./images/h2_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h2.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(3)}}>
                          {showHarvest3 ?
                            <img src={`./images/h3_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h3.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                        <span className={`number-padding style-cursor`} 
                          onClick={selectedQrCode === null ? '' : () => {onSelectHarvest(4)}}>
                          {showHarvest4 ?
                            <img src={`./images/h4_blue.png`} className="bale-icon-size" alt="draw" /> :
                            <img src={`./images/h4.png`} className="bale-icon-size" alt="draw" />
                          }
                        </span>
                      </div>
                    </div>
                    <div className="select-box-common padding-top-css-text padding-top-bottom">
                      <Field
                        styleName={selectedQrCode === null ? '' : `select-box-small select-box-small-margin`}
                        placeholder={intl.formatMessage({ id: 'GRASS_ANALYSIS_FIELD' })}
                        options={(selectedQrCode !== "add" && selectedQrCode !== null) ? fieldOptions(selectedGrassAnalysis[0].qrCode) : fieldOptions(null)}
                        component={SelectControl}
                        name="field"
                        label={intl.formatMessage({ id: 'GRASS_ANALYSIS_FIELD' })}
                        controlId="field"
                        hasCheckbox={false}
                        isMulti={false}
                        allowSelectAll={false}
                        selectAllText={intl.formatMessage({ id: 'ALL_FIELDS' })}
                        disabled={selectedQrCode === null}
                      />
                    </div>
                  </div>}
                  <div className="lightbox-field-padding-top-large">
                    <span className="paddingRight">
                      {noHarvestError && <ErrorText>{intl.formatMessage({ id: 'GRASS_ANALYSIS_ERROR_1' })}</ErrorText>}
                      {noWeightParameter && <ErrorText>{intl.formatMessage({ id: 'GRASS_ANALYSIS_ERROR_2' })}</ErrorText>}
                      {grassAnalysis.addHasError && grassAnalysis.errorMsg.data.message && <ErrorText>{grassAnalysis.errorMsg.data.message}</ErrorText>}
                      {grassAnalysis.addHasError && !grassAnalysis.errorMsg.data.message && <ErrorText>{grassAnalysis.errorMsg.data}</ErrorText>}
                      {grassAnalysis.addHasSuccess && window.location.reload()}
                    </span>
                  </div>
                  <div className="bale-button-style">
                    {selectedQrCode !== null &&
                    <span className="paddingRight">
                      <ActionButton onClick={handleSubmit}>
                        {selectedQrCode === "add" ?
                        intl.formatMessage({ id: 'GRASS_ANALYSIS_SAVE_BUTTON' }) :
                        intl.formatMessage({ id: 'GRASS_ANALYSIS_UPDATE_BUTTON' })
                        }
                      </ActionButton>
                    </span>}
                    <span className="bale-list-cancel-button" onClick={() => closeGrassAnalysisModal()}>
                      {intl.formatMessage({ id: 'GRASS_ANALYSIS_CANCEL_BUTTON' })}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
      </ModalDialog>
      {showDelete && (
        <ConfirmDialogBox
          heading={`${intl.formatMessage({ id: 'GRASS_ANALYSIS_DELETE_HEADING' })}?`}
          body={intl.formatMessage({ id: 'GRASS_ANALYSIS_DELETE_MESSAGE' })}
          confirmText="DELETE"
          cancelText="CANCEL"
          callback={action => showDialog(action)}
          warning
        />
      )}
  </div>
);

export default GrassAnalysisRegister;