export const FIELD_LIST = 'FIELD_LIST';
export const FIELD_LIST_ERROR = 'FIELD_LIST_ERROR';
export const IS_FIELD_LOADING = 'IS_FIELD_LOADING';
export const ADD_UPDATE_FIELD = 'ADD_UPDATE_FIELD';
export const ADD_UPDATE_FIELD_ERROR = 'ADD_UPDATE_FIELD_ERROR';
export const DELETE_FIELD = 'DELETE_FIELD';
export const DELETE_FIELD_ERROR = 'DELETE_FIELD_ERROR';
export const CLEAR_FIELD_MESSAGE = 'CLEAR_FIELD_MESSAGE';
export const SAVE_FIELD_COORDINATES = 'SAVE_FIELD_COORDINATES';
export const UPDATE_FIELD_BATCH = 'UPDATE_FIELD_BATCH';
export const UPDATE_FIELD_BATCH_ERROR = 'UPDATE_FIELD_BATCH_ERROR';
export const DELETE_FIELD_LIST_SUCCESS = 'DELETE_FIELD_LIST_SUCCESS';
export const DELETE_FIELD_LIST_FAILURE = 'DELETE_FIELD_LIST_FAILURE';

export const FIELD_LIST1 = 'FIELD_LIST1';
export const FIELD_LIST_ERROR1 = 'FIELD_LIST_ERROR1';

export const FIELD_METHOD_LOADING = 'FIELD_METHOD_LOADING';
export const FIELD_METHOD_SUCCESS = 'FIELD_METHOD_SUCCESS';
export const FIELD_METHOD_ERROR = 'FIELD_METHOD_ERROR';

export const FIELD_WEATHER_LOADING = 'FIELD_WEATHER_LOADING';
export const FIELD_WEATHER_SUCCESS = 'FIELD_WEATHER_SUCCESS';
export const FIELD_WEATHER_ERROR = 'FIELD_WEATHER_ERROR';

export const CROP_LOADING = 'CROP_LOADING';
export const CROP_SUCCESS = 'CROP_SUCCESS';
export const CROP_ERROR = 'CROP_ERROR';

export const PREVIOUS_CROP_LOADING = 'PREVIOUS_CROP_LOADING';
export const PREVIOUS_CROP_SUCCESS = 'PREVIOUS_CROP_SUCCESS';
export const PREVIOUS_CROP_ERROR = 'PREVIOUS_CROP_ERROR';

export const IMPORT_FIELD_SHAPE = 'IMPORT_FIELD_SHAPE';
export const IMPORT_FIELD_SHAPE_ERROR = 'IMPORT_FIELD_SHAPE_ERROR';
export const EXPORT_FIELD_LOADING = 'EXPORT_FIELD_LOADING';
export const EXPORT_FIELD_SHAPE = 'EXPORT_FIELD_SHAPE';
export const EXPORT_FIELD_SHAPE_ERROR = 'EXPORT_FIELD_SHAPE_ERROR';

export const YIELD_CALCULATION_LOADING = 'YIELD_CALCULATION_LOADING';
export const YIELD_CALCULATION_SUCCESS = 'YIELD_CALCULATION_SUCCESS';
export const YIELD_CALCULATION_ERROR = 'YIELD_CALCULATION_ERROR';

export const HISTORICAL_DATA_LOADING = 'HISTORICAL_DATA_LOADING';
export const HISTORICAL_DATA_SUCCESS = 'HISTORICAL_DATA_SUCCESS';
export const HISTORICAL_DATA_ERROR = 'HISTORICAL_DATA_ERROR';

export const HISTORICAL_LIST_LOADING = 'HISTORICAL_LIST_LOADING';
export const HISTORICAL_LIST_SUCCESS = 'HISTORICAL_LIST_SUCCESS';
export const HISTORICAL_LIST_ERROR = 'HISTORICAL_LIST_ERROR';

export const FIELD_SOIL_SAMPLES_GET_LOADING = 'FIELD_SOIL_SAMPLES_GET_LOADING';
export const FIELD_SOIL_SAMPLES_GET_SUCCESS = 'FIELD_SOIL_SAMPLES_GET_SUCCESS';
export const FIELD_SOIL_SAMPLES_GET_ERROR = 'FIELD_SOIL_SAMPLES_GET_ERROR';
export const FIELD_SOIL_SAMPLES_POST_LOADING = 'FIELD_SOIL_SAMPLES_POST_LOADING';
export const FIELD_SOIL_SAMPLES_POST_SUCCESS = 'FIELD_SOIL_SAMPLES_POST_SUCCESS';
export const FIELD_SOIL_SAMPLES_POST_ERROR = 'FIELD_SOIL_SAMPLES_POST_ERROR';
export const FIELD_SOIL_SAMPLES_PUT_LOADING = 'FIELD_SOIL_SAMPLES_PUT_LOADING';
export const FIELD_SOIL_SAMPLES_PUT_SUCCESS = 'FIELD_SOIL_SAMPLES_PUT_SUCCESS';
export const FIELD_SOIL_SAMPLES_PUT_ERROR = 'FIELD_SOIL_SAMPLES_PUT_ERROR';
export const FIELD_SOIL_SAMPLES_DELETE_LOADING = 'FIELD_SOIL_SAMPLES_DELETE_LOADING';
export const FIELD_SOIL_SAMPLES_DELETE_SUCCESS = 'FIELD_SOIL_SAMPLES_DELETE_SUCCESS';
export const FIELD_SOIL_SAMPLES_DELETE_ERROR = 'FIELD_SOIL_SAMPLES_DELETE_ERROR';

export const OMD_GOALS_LOADING = 'OMD_GOALS_LOADING';
export const OMD_GOALS_SUCCESS = 'OMD_GOALS_SUCCESS';
export const OMD_GOALS_ERROR = 'OMD_GOALS_ERROR';
