import React from 'react';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import Numeral from '@tine/lib-frontend-components/elements/typography/numeral';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';

const DataInfo = ({ taskDetails, expectedCount, actualCount, selectedTask }) => (
  <div className="validation-info">
    <ul>
      <li>
        <span className="dv-lbl">
          <Text size="normal">Farm</Text>
        </span>
        <span className="dv-value">
          <Emphasis size="normal" italic>
            {taskDetails ? taskDetails.task.farm.name : '-'}
          </Emphasis>
        </span>
      </li>
      <li>
        <span className="dv-lbl">
          <Text size="normal">Field</Text>
        </span>
        <span className="dv-value">
          <Emphasis size="normal" italic>
            {taskDetails ? taskDetails.task.field.fieldName : '-'}
          </Emphasis>
        </span>
      </li>
      <li>
        <span className="dv-lbl">
          <Text size="normal">Task</Text>
        </span>
        <span className="dv-value">
          <Emphasis size="normal" italic>
            {selectedTask ? selectedTask.label : '-'}
          </Emphasis>
        </span>
      </li>
      <li>
        <span className="dv-lbl">
          <Text size="normal">Status</Text>
        </span>
        <span className="dv-value">
          <Emphasis size="normal" italic>
            {taskDetails ? taskDetails.task.status : '-'}
          </Emphasis>
        </span>
      </li>
      <li>
        <span className="dv-lbl">
          <Text size="normal">Start time</Text>
        </span>
        <span className="dv-value">
          <Emphasis size="normal" italic>
            {taskDetails && taskDetails.task.startTime
              ? `${taskDetails.task.startTime.substr(0, 10)} ${taskDetails.task.startTime.substr(
                  11,
                  5
                )}`
              : '-'}
          </Emphasis>
        </span>
      </li>
      <li>
        <span className="dv-lbl">
          <Text size="normal">End time</Text>
        </span>
        <span className="dv-value">
          <Emphasis size="normal" italic>
            {taskDetails && taskDetails.task.endTime
              ? `${taskDetails.task.endTime.substr(0, 10)} ${taskDetails.task.endTime.substr(
                  11,
                  5
                )}`
              : '-'}
          </Emphasis>
        </span>
      </li>
      <li>
        <span className="dv-lbl">
          <Text size="normal">Expected count</Text>
        </span>
        <span className="dv-value">
          <Numeral size="normal">{expectedCount}</Numeral>
          <Text size="extrasmall">*approx</Text>
        </span>
      </li>
      <li>
        <span className="dv-lbl">
          <Text size="normal">Actual count</Text>
        </span>
        <span className="dv-value">
          <Numeral size="normal">{actualCount}</Numeral>
        </span>
      </li>
    </ul>
  </div>
);

export default DataInfo;
