import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { List, ListItemButton, Chip, Grid, Typography, Collapse } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import { createSvgIcon } from '@mui/material/utils';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

const titleText = { fontFamily: "Inter", fontSize: "16px", fontWeight: "500" };
const headingText = { ...titleText, fontSize: "14px", fontWeight: "600", textAlign: "center" };
const headingTextLeftAlign = { ...titleText, fontSize: "14px", fontWeight: "600", textAlign: "left" };
const headingTextLeft = { ...titleText, fontWeight: "bold", textAlign: "left", paddingLeft: "15px" };
const headingTextRight = { ...titleText, fontWeight: "400", textAlign: "center", paddingRight: "15px" };
const subHeadingText = { ...titleText, fontWeight: "400", textAlign: "center" };
const subHeadingCapitalizeText = { ...titleText, fontWeight: "400", textTransform: "Capitalize" };
const subHeadingSmallText = { fontFamily: "Inter", fontSize: "14px", fontWeight: "400", textAlign: "center" };
const subHeadingSmallCapitalizeText = { ...subHeadingSmallText, textTransform: "Capitalize", textAlign: "left", fontWeight: "600" }
const subHeadingSmallTextLeft = { fontFamily: "Inter", fontSize: "14px", fontWeight: "400", textAlign: "left" };
const subHeadingSmallCapitalizeTextLeft = { ...subHeadingSmallTextLeft, textTransform: "Capitalize", textAlign: "left", fontWeight: "600" }
const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px', background: 'transparent' };

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-4 -4 32 32"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  'Plus',
);

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 580,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    borderRadius: '15px',
    padding: "15px"
  },
}));

function getMineralFertilizerHeader(intl) {
  return (
    <Grid container justifyContent="flex-end" sx={{ padding: "6px" }}>
      <Grid item lg={4} sm={4} md={4} xs={12}>
        <Typography style={headingText}>&nbsp;</Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'NITROGEN' })}</Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'PHOSPHORUS' })}</Typography>
      </Grid>
      <Grid item lg={2} sm={2} md={2} xs={12}>
        <Typography style={headingText}>{intl.formatMessage({ id: 'POTASSIUM' })}</Typography>
      </Grid>
    </Grid>
  )
}

function getMineralFertilizerNeed(seasonFertilizationItem, intl, expandedSeasonColumnNeed, handleExpandSeasonNeed, index) {
  let fieldNeedYieldData = null;
  let fieldNeedSoilData = null;
  let fieldNeedPreviousCropData = null;
  let fieldNeedCloverData = null;
  let fieldNeedStravRemovedData = null;
  if(seasonFertilizationItem.yieldEffect !== null) {
    fieldNeedYieldData = getSeasonNeedData(
      seasonFertilizationItem.yieldEffect.yieldNitrogen, 
      seasonFertilizationItem.yieldEffect.yieldPhosphorus, 
      seasonFertilizationItem.yieldEffect.yieldPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_YIELD' })
    );
  }
  if(seasonFertilizationItem.soilParamEffect !== null) {
    fieldNeedSoilData = getSeasonNeedData(
      seasonFertilizationItem.soilParamEffect.soilNitrogen, 
      seasonFertilizationItem.soilParamEffect.soilPhosphorus, 
      seasonFertilizationItem.soilParamEffect.soilPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_SOIL' })
    );
  }
  if(seasonFertilizationItem.previousCropEffect !== null) {
    fieldNeedPreviousCropData = getSeasonNeedData(
      seasonFertilizationItem.previousCropEffect.previousCropNitrogen, 
      seasonFertilizationItem.previousCropEffect.previousCropPhosphorus, 
      seasonFertilizationItem.previousCropEffect.previousCropPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_PREVIOUS_CROP' })
    );
  }
  if(seasonFertilizationItem.cloverEffect !== null) {
    fieldNeedCloverData = getSeasonNeedData(
      seasonFertilizationItem.cloverEffect.cloverNitrogen, 
      seasonFertilizationItem.cloverEffect.cloverPhosphorus, 
      seasonFertilizationItem.cloverEffect.cloverPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_CLOVER' })
    );
  }
  if(seasonFertilizationItem.stravRemovedEffect !== null) {
    fieldNeedStravRemovedData = getSeasonNeedData(
      seasonFertilizationItem.stravRemovedEffect.stravRemovedNitrogen, 
      seasonFertilizationItem.stravRemovedEffect.stravRemovedPhosphorus, 
      seasonFertilizationItem.stravRemovedEffect.stravRemovedPotassium, 
      intl.formatMessage({ id: 'FERTILIZATION_NEED_REMOVAL_OF_STRAV' })
    );
  }
  let mineral_fertilizer_need = [];
  if (seasonFertilizationItem.needForSeason !== null) {
    mineral_fertilizer_need = (
      <List component="nav" onClick={(e) => handleExpandSeasonNeed(e, index)}>
        <Grid container>
          <Grid item lg={4} sm={4} md={4} xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={subHeadingCapitalizeText} sx={{width: '95%'}}>{intl.formatMessage({ id: 'NEED' })}</Typography>
              {(fieldNeedYieldData !== null || fieldNeedSoilData !== null || fieldNeedPreviousCropData !== null || fieldNeedCloverData !== null || fieldNeedStravRemovedData !== null) &&
              (expandedSeasonColumnNeed[index] ? <ArrowDropUp/> : <ArrowDropDown />)}
            </div>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.needForSeason.nitrogenNeed.toFixed(1)}{' '}
              {seasonFertilizationItem.needForSeason.nutrientUnitsNeed}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.needForSeason.phosphorusNeed.toFixed(1)}{' '}
              {seasonFertilizationItem.needForSeason.nutrientUnitsNeed}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.needForSeason.potassiumNeed.toFixed(1)}{' '}
              {seasonFertilizationItem.needForSeason.nutrientUnitsNeed}
            </Typography>
          </Grid>
        </Grid>        
        <Collapse in={expandedSeasonColumnNeed[index]} timeout="auto" unmountOnExit>
          {fieldNeedYieldData}
          {fieldNeedSoilData}
          {fieldNeedPreviousCropData}
          {fieldNeedCloverData}
          {fieldNeedStravRemovedData}
        </Collapse>
      </List>
    )
  }
  return mineral_fertilizer_need;
}

function getSeasonNeedData(nitrogen, phosphorus, potassium, title) {
  let data = null;
  data = (
    <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
      <Grid item lg={4} sm={4} md={4} xs={12}>
        <Typography style={subHeadingSmallCapitalizeText}>{title}</Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={subHeadingSmallText}>
          {nitrogen.toFixed(1)} kg/daa
        </Typography>
      </Grid>
      <Grid item lg={3} sm={3} md={3} xs={12}>
        <Typography style={subHeadingSmallText}>
          {phosphorus.toFixed(1)} kg/daa
        </Typography>
      </Grid>
      <Grid item lg={2} sm={2} md={2} xs={12}>
        <Typography style={subHeadingSmallText}>
          {potassium.toFixed(1)} kg/daa
        </Typography>
      </Grid>
    </Grid>
  );
  return data;
}

function getMineralFertilizerAdded(seasonFertilizationItem, intl, expandedActualColumnAdded, handleExpandActualAdded, index) {
  let manureData = getManureDataActual(seasonFertilizationItem, intl);
  let mineralFertilizerData = getMineralFertilizerDataActual(seasonFertilizationItem);
  let mineral_fertilizer_added = "";
  if (seasonFertilizationItem.addedForSeasonActual !== null) {
    mineral_fertilizer_added = (
      <List component="nav" onClick={(e) => handleExpandActualAdded(e, index)}>
        <Grid container>
          <Grid item lg={4} sm={4} md={4} xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={subHeadingCapitalizeText} sx={{width: '95%'}}>{intl.formatMessage({ id: 'FP_ACTUAL' })}</Typography>
              {(manureData !== '' || mineralFertilizerData !== '') &&
              (expandedActualColumnAdded[index] ? <ArrowDropUp/> : <ArrowDropDown />)}
            </div>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.addedForSeasonActual.nitrogenAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeasonActual.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.addedForSeasonActual.phosphorusAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeasonActual.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.addedForSeasonActual.potassiumAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeasonActual.nutrientUnitsAdded}
            </Typography>
          </Grid>
        </Grid>        
        <Collapse in={expandedActualColumnAdded[index]} timeout="auto" unmountOnExit>
          {manureData}
          {mineralFertilizerData}
        </Collapse>
      </List>
    )
  }
  return mineral_fertilizer_added;
}

function getMineralFertilizerPlannedAdded(seasonFertilizationItem, intl, expandedSeasonColumnAdded, handleExpandSeasonAdded, index) {
  let residualData = getMineralFertilizerResidual(seasonFertilizationItem, intl);
  let manureData = getManureData(seasonFertilizationItem, intl);
  let mineralFertilizerData = getMineralFertilizerData(seasonFertilizationItem);
  let mineral_fertilizer_added = [];
  if (seasonFertilizationItem.addedForSeason !== null) {
    mineral_fertilizer_added = (
      <List component="nav" onClick={(e) => handleExpandSeasonAdded(e, index)}>
        <Grid container>
          <Grid item lg={4} sm={4} md={4} xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={subHeadingCapitalizeText} sx={{width: '95%'}}>{seasonFertilizationItem.planState}</Typography>
              {expandedSeasonColumnAdded[index] ? <ArrowDropUp/> : <ArrowDropDown />}
            </div>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.addedForSeason.nitrogenAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeason.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={3} md={3} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.addedForSeason.phosphorusAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeason.nutrientUnitsAdded}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={12}>
            <Typography style={subHeadingText}>
              {seasonFertilizationItem.addedForSeason.potassiumAdded.toFixed(1)}{' '}
              {seasonFertilizationItem.addedForSeason.nutrientUnitsAdded}
            </Typography>
          </Grid>
        </Grid>      
        <Collapse in={expandedSeasonColumnAdded[index]} timeout="auto" unmountOnExit>
          {residualData}
          {manureData}
          {mineralFertilizerData}
        </Collapse>
      </List>
    )
  }
  return mineral_fertilizer_added;
}

function getManureDataToolTip(seasonFertilizationItem) {
  let manureData = '';
  if (seasonFertilizationItem.budgetToolTip !== null) {
    manureData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeTextLeft}>{seasonFertilizationItem.budgetToolTip.manure.name}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallTextLeft}>
            {seasonFertilizationItem.budgetToolTip.manure.budgetedAmount.toFixed(1)} m^3/daa
          </Typography>
        </Grid>
        <Grid item lg={2.5} sm={2.5} md={2.5} xs={12}>
          <Typography style={subHeadingSmallTextLeft}>
            {seasonFertilizationItem.budgetToolTip.manure.actualAmount.toFixed(1)} m^3/daa
          </Typography>
        </Grid>
        <Grid item lg={2.5} sm={2.5} md={2.5} xs={12}>
          <Typography style={subHeadingSmallTextLeft}>
            {seasonFertilizationItem.budgetToolTip.manure.difference.toFixed(1)} m^3/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return manureData;
}

function getMineralFertilizerToolTip(seasonFertilizationItem) {
  if (seasonFertilizationItem.budgetToolTip !== null && seasonFertilizationItem.budgetToolTip.mfList?.length > 0) {
    return seasonFertilizationItem.budgetToolTip.mfList.map(mineralFertilizerItem => (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeTextLeft}>
            {mineralFertilizerItem.name}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallTextLeft}>
            {mineralFertilizerItem.budgetedAmount.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={2.5} sm={2.5} md={2.5} xs={12}>
          <Typography style={subHeadingSmallTextLeft}>
            {mineralFertilizerItem.actualAmount.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={2.5} sm={2.5} md={2.5} xs={12}>
          <Typography style={subHeadingSmallTextLeft}>
            {mineralFertilizerItem.difference.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    ));
  }
}

function getMineralFertilizerResidual(seasonFertilizationItem, intl) {
  let mineral_fertilizer_residual = '';
  if (seasonFertilizationItem.residualForSeason !== null) {
    mineral_fertilizer_residual = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'RESIDUAL' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.residualForSeason.nitrogenResidual.toFixed(1)}{' '}
            {seasonFertilizationItem.residualForSeason.nutrientUnitsResidual}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.residualForSeason.phosphorusResidual.toFixed(1)}{' '}
            {seasonFertilizationItem.residualForSeason.nutrientUnitsResidual}
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.residualForSeason.potassiumResidual.toFixed(1)}{' '}
            {seasonFertilizationItem.residualForSeason.nutrientUnitsResidual}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return mineral_fertilizer_residual;
}

function getMineralFertilizerPlannedDifference(seasonFertilizationItem, intl) {
  let mineral_fertilizer_difference = "";
  if (seasonFertilizationItem.shortfallForSeason !== null) {
    mineral_fertilizer_difference = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingCapitalizeText}>{intl.formatMessage({ id: 'DIFFERENCE' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingText}>
            {seasonFertilizationItem.shortfallForSeason.nitrogenShortfall.toFixed(1)}{' '}
            {seasonFertilizationItem.shortfallForSeason.nutrientUnitsShortfall}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingText}>
            {seasonFertilizationItem.shortfallForSeason.phosphorusShortfall.toFixed(1)}{' '}
            {seasonFertilizationItem.shortfallForSeason.nutrientUnitsShortfall}
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingText}>
            {seasonFertilizationItem.shortfallForSeason.potassiumShortfall.toFixed(1)}{' '}
            {seasonFertilizationItem.shortfallForSeason.nutrientUnitsShortfall}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return mineral_fertilizer_difference;
}

/*function getMineralFertilizerDifference(seasonFertilizationItem, intl) {
  let mineral_fertilizer_difference = "";
  if (seasonFertilizationItem.shortfallForSeasonActual !== null) {
    mineral_fertilizer_difference = (
      <Grid container justifyContent="flex-end" sx={{ padding: "6px" }}>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingCapitalizeText}>{intl.formatMessage({ id: 'DIFFERENCE' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingText}>
            {seasonFertilizationItem.shortfallForSeasonActual.nitrogenShortfall.toFixed(1)}{' '}
            {seasonFertilizationItem.shortfallForSeasonActual.nutrientUnitsShortfall}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingText}>
            {seasonFertilizationItem.shortfallForSeasonActual.phosphorusShortfall.toFixed(1)}{' '}
            {seasonFertilizationItem.shortfallForSeasonActual.nutrientUnitsShortfall}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingText}>
            {seasonFertilizationItem.shortfallForSeasonActual.potassiumShortfall.toFixed(1)}{' '}
            {seasonFertilizationItem.shortfallForSeasonActual.nutrientUnitsShortfall}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return mineral_fertilizer_difference;
}*/

function getMineralFertilizerData(seasonFertilizationItem) {
  if (seasonFertilizationItem.mineralFertilizersForSeason?.length > 0) {
    return seasonFertilizationItem.mineralFertilizersForSeason.map(mineralFertilizerItem => (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>
            {mineralFertilizerItem.customName === null ? mineralFertilizerItem.originalName : mineralFertilizerItem.customName}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.nitrogen.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.phosphorus.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.potassium.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
      </Grid>
    ));
  }
}

function getMineralFertilizerDataActual(seasonFertilizationItem) {
  if (seasonFertilizationItem.mineralFertilizersForSeasonActual?.length > 0) {
    return seasonFertilizationItem.mineralFertilizersForSeasonActual.map(mineralFertilizerItem => (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>
            {mineralFertilizerItem.customName === null ? mineralFertilizerItem.originalName : mineralFertilizerItem.customName}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.nitrogen.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.phosphorus.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {mineralFertilizerItem.potassium.toFixed(1)} {mineralFertilizerItem.nutrientUnits}
          </Typography>
        </Grid>
      </Grid>
    ));
  } /*else {
    return seasonFertilizationItem.mineralFertilizersForSeason.map(mineralFertilizerItem => (
      <div
        key={mineralFertilizerItem.originalName}
        className="fertilization-plan-grid --border-thin-grey --grid-padding-low --grid-layout"
      >
        <div className="text-capitalize">
          <EmphasisLight size="small">
            {mineralFertilizerItem.customName === null
              ? mineralFertilizerItem.originalName
              : mineralFertilizerItem.customName}
          </EmphasisLight>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    ));
  }*/
}

function getManureData(seasonFertilizationItem, intl) {
  let manureData = '';
  if (seasonFertilizationItem.manureForSeason !== null) {
    manureData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'MANURE' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.manureForSeason.manureNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.manureForSeason.manurePhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.manureForSeason.manurePotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return manureData;
}

function getManureDataActual(seasonFertilizationItem, intl) {
  let manureData = '';
  if (seasonFertilizationItem.manureForSeasonActual !== null) {
    manureData = (
      <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
        <Grid item lg={4} sm={4} md={4} xs={12}>
          <Typography style={subHeadingSmallCapitalizeText}>{intl.formatMessage({ id: 'MANURE' })}</Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.manureForSeasonActual.manureNitrogen.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} md={3} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.manureForSeasonActual.manurePhosphorus.toFixed(1)} kg/daa
          </Typography>
        </Grid>
        <Grid item lg={2} sm={2} md={2} xs={12}>
          <Typography style={subHeadingSmallText}>
            {seasonFertilizationItem.manureForSeasonActual.manurePotassium.toFixed(1)} kg/daa
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return manureData;
}

function getManureTotalContent(
  fieldId,
  seasonFertilizationItem,
  updateSeasonManure,
  viewFertilizerPlan,
  isLoading,
  intl,
  manureStorageList,
  updateManureStorageMenu
) {
  let manureData = '';
  if (seasonFertilizationItem.manureForSeasonLeft !== null) {
    let manureTextName =
      seasonFertilizationItem.seasonId +
      '_' +
      fieldId +
      '_' +
      seasonFertilizationItem.manureForSeasonLeft.manureSeasonId + 
      '_' +
      seasonFertilizationItem.manureForSeasonLeft.manureStorageId;    
    let budgetedTextValue = seasonFertilizationItem.consolidatedManureForSeason ? seasonFertilizationItem.consolidatedManureForSeason.budgetedQuantity : 0;
    let manureCustomTextValue = seasonFertilizationItem.manureForSeasonLeft.manureCustomQuantity ? seasonFertilizationItem.manureForSeasonLeft.manureCustomQuantity.toFixed(1) : (seasonFertilizationItem.manureForSeasonLeft.manureOriginalQuantity ? seasonFertilizationItem.manureForSeasonLeft.manureOriginalQuantity.toFixed(1) : 0);
    const currentManureSelection = _.filter(manureStorageList, ['value', seasonFertilizationItem.manureForSeasonLeft.manureStorageId]);
    let currentManureLabel = null;
    if(currentManureSelection && currentManureSelection.length > 0) {
      currentManureLabel = currentManureSelection[0].label;
    }
    let editManureValue = seasonFertilizationItem.seasonId+'~'+(budgetedTextValue !== 0 ? budgetedTextValue : manureCustomTextValue)+'~'+seasonFertilizationItem.manureForSeasonLeft.manureSeasonId+'~'+seasonFertilizationItem.manureForSeasonLeft.manureStorageId+'~'+fieldId+'~'+currentManureLabel;
    let disabled = false;
    let textBoxValue = budgetedTextValue !== 0 ? budgetedTextValue : manureCustomTextValue;
    if(textBoxValue === 0 && seasonFertilizationItem.manureForSeasonLeft.manureStorageId === null){
      disabled = true;
    }
    manureData = (
      <Grid container justifyContent="flex-end" sx={{ padding: "8px", width: "100%" }}>
        <Grid item md={1} sx={{ padding: "10px" }}>
          <Typography style={subHeadingCapitalizeText}>&nbsp;</Typography>
        </Grid>
        <Grid item md={7} sx={{ padding: '10px', maxWidth: '140px'}}>
          <MuiSelectBox 
            name={`${fieldId}_spring_manureMethod`}
            id={`${fieldId}_spring_manureMethod`}
            label=""
            value={seasonFertilizationItem.manureForSeasonLeft.manureStorageId !== null
              ? seasonFertilizationItem.manureForSeasonLeft.manureStorageId : null}
            options={manureStorageList}
            sx={{ backgroundColor: "#ffffff", maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            onChange={e => updateManureStorageMenu(e, editManureValue)}
            disabled={disabled}
          />
        </Grid>
        <Grid item md={4} sx={{ padding: '10px' }}>
          <Typography style={subHeadingText}>
            <MuiTextBox 
              onChange={e => updateSeasonManure(e)}
              id={manureTextName}
              name={manureTextName}
              label="m^3/daa"
              variant="outlined"
              defaultValue={budgetedTextValue !== 0 ? budgetedTextValue : manureCustomTextValue}
              showFullWidth="true"
              disabled={disabled}
            />
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return manureData;
}

function getPlanHeadingIcon(intl, seasonFertilizationItem) {
  let planData = '';
  planData = (
    <Grid container justifyContent="flex-end">
      {!seasonFertilizationItem.showHeaderBeforeMF ?
      <Grid item md={10}>
        <Typography style={headingTextLeft}>{seasonFertilizationItem.planState}</Typography>
      </Grid> : 
      <Grid item md={10}>
        <Typography style={headingTextLeft}>{intl.formatMessage({ id: 'FP_ACTUAL' })}</Typography>
      </Grid>}
      <Grid item md={2}>
        <Typography style={headingTextRight}>
        {seasonFertilizationItem.budgetToolTip !== null ?
          <HtmlTooltip
            title={
              <React.Fragment>
                <Grid container justifyContent="flex-end">
                  <Grid item lg={4} sm={4} md={4} xs={12}>
                    <Typography style={headingTextLeftAlign}>{intl.formatMessage({ id: 'FP_FERTILIZER' })}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} md={3} xs={12}>
                    <Typography style={headingTextLeftAlign}>{intl.formatMessage({ id: 'FP_BUDGETED' })}</Typography>
                  </Grid>
                  <Grid item lg={2.5} sm={2.5} md={2.5} xs={12}>
                    <Typography style={headingTextLeftAlign}>{intl.formatMessage({ id: 'FP_ACTUAL' })}</Typography>
                  </Grid>
                  <Grid item lg={2.5} sm={2.5} md={2.5} xs={12}>
                    <Typography style={headingTextLeftAlign}>{intl.formatMessage({ id: 'DIFFERENCE' })}</Typography>
                  </Grid>
                </Grid>
                {getManureDataToolTip(seasonFertilizationItem)}
                {getMineralFertilizerToolTip(seasonFertilizationItem)}
              </React.Fragment>
            }
          >
            <InfoIcon />
          </HtmlTooltip> : ""}
        </Typography>
      </Grid>
    </Grid>
  );
  return planData;
}

function getPlanHeadingNoIcon(intl, seasonFertilizationItem) {
  let planData = '';
  planData = (
    <Grid container justifyContent="flex-end" sx={{ padding: "6px" }}>
      <Grid item md={12}>
        <Typography style={headingTextLeft}>{seasonFertilizationItem.planState}</Typography>
      </Grid>
    </Grid>
  );
  return planData;
}

function getMineralFertilizerTotalCount(
  fieldId,
  seasonFertilizationItem,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList
) {
  if (seasonFertilizationItem.consolidatedMineralFertilizersForSeason?.length > 0) {
    return (seasonFertilizationItem.consolidatedMineralFertilizersForSeason.map((mineralFertilizerItem, index) =>
      getMineralFertilizerTextBox(
        fieldId,
        seasonFertilizationItem,
        mineralFertilizerItem,
        updateMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        index,
        isLoading,
        intl,
        addingFertilizer,
        addingFertilizerLeft,
        mineralFertilizerList
      )
    )
  )} else {
    return (seasonFertilizationItem.mineralFertilizersForSeason.map((mineralFertilizerItem, index) =>
      getMineralFertilizerManureTextBox(
        fieldId,
        seasonFertilizationItem,
        mineralFertilizerItem,
        updateMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        index,
        isLoading,
        intl,
        addingFertilizer,
        addingFertilizerLeft,
        mineralFertilizerList
      )
    )
  )}
}

function getMineralFertilizerTotalCountLeft(
  fieldId,
  seasonFertilizationItem,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList
) {
    return (seasonFertilizationItem.mineralFertilizersForSeasonActual && seasonFertilizationItem.mineralFertilizersForSeasonActual.map((mineralFertilizerItem, index) =>
      getMineralFertilizerManureTextBoxLeft(
        fieldId,
        seasonFertilizationItem,
        mineralFertilizerItem,
        updateMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        index,
        isLoading,
        intl,
        addingFertilizer,
        addingFertilizerLeft,
        mineralFertilizerList
      )
    )
  )
}

function getMineralFertilizerTextBox(
  fieldId,
  seasonFertilizationItem,
  mineralFertilizerItem,
  updateMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  index,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList
) {
  let editValueName =
    seasonFertilizationItem.seasonId +
    '~~' +
    fieldId +
    '~~' +
    (mineralFertilizerItem.name);
  let editValueId =
    seasonFertilizationItem.seasonId + '~~' + fieldId + '~~' + index;
  //let actualQuantityValue = mineralFertilizerItem.actualQuantity === null ? 0 : mineralFertilizerItem.actualQuantity;
  return (
    <Grid container justifyContent="flex-end" sx={{ padding: "6px" }}>
      <Grid item md={1} sx={{ padding: '10px' }}>
        <Typography style={subHeadingCapitalizeText} sx={{ paddingTop: '18px' }}>
          <CloseIcon onClick={e =>deleteMineralFertilizer(e, editValueName)} />
        </Typography>
      </Grid>
      <Grid item md={7} sx={{ padding: '10px', maxWidth: '140px' }}>
        <MuiSelectBox 
          name={`${fieldId}_manureMineralFertilizer`}
          id={`${fieldId}_manureMineralFertilizer`}
          label=""
          value={mineralFertilizerItem.name}
          options={mineralFertilizerList}
          sx={{ backgroundColor: "#ffffff", maxWidth: '140px'}}
          onChange={e => updateMineralFertilizer(e, editValueName)}
        />
      </Grid>
      {/*<Grid item md={3} sx={{ padding: '10px' }}>
        <Typography style={subHeadingText}>
          {mineralFertilizerItem.actualQuantity === null || mineralFertilizerItem.actualQuantity === 0 ?
          <MuiTextBox 
            name={`${fieldId}_${editValueId}`}
            id={`${fieldId}_${editValueId}`}
            label="kg/daa"
            variant="outlined"
            value=" "
            showFullWidth="true"
            disabled={true}
          /> :
          <MuiTextBox 
            name={`${fieldId}_${editValueId}`}
            id={`${fieldId}_${editValueId}`}
            label="kg/daa"
            variant="outlined"
            defaultValue={mineralFertilizerItem.actualQuantity}
            showFullWidth="true"
            disabled={true}
          /> }
        </Typography>
      </Grid>*/}
      <Grid item md={4} sx={{ padding: '10px' }}>
        <Typography style={subHeadingText}>
          <MuiTextBox 
            onChange={e => updateSeasonFertilizer(e)}
            id={editValueId}
            name={editValueName}
            label="kg/daa"
            variant="outlined"
            value={addingFertilizer[editValueId]}
            showFullWidth="true"
          />
        </Typography>
      </Grid>
    </Grid>);
}

function getMineralFertilizerManureTextBox(
  fieldId,
  seasonFertilizationItem,
  mineralFertilizerItem,
  updateMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  index,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList
) {
  let editValueName =
    seasonFertilizationItem.seasonId +
    '~~' +
    fieldId +
    '~~' +
    (mineralFertilizerItem.originalName);
  let editValueId =
    seasonFertilizationItem.seasonId + '~~' + fieldId + '~~' + index;
  return (
    <Grid container justifyContent="flex-end" sx={{ padding: "6px" }}>
      <Grid item md={1} sx={{ padding: '10px' }}>
        <Typography style={subHeadingCapitalizeText} sx={{ paddingTop: '18px' }}>
          <CloseIcon onClick={e =>deleteMineralFertilizer(e, editValueName)} />
        </Typography>
      </Grid>
      <Grid item md={7} sx={{ padding: '10px', maxWidth: '140px' }}>
        <MuiSelectBox 
          name={`${fieldId}_manureMineralFertilizer1`}
          id={`${fieldId}_manureMineralFertilizer1`}
          label=""
          value={mineralFertilizerItem.originalName}
          options={mineralFertilizerList}
          sx={{ backgroundColor: "#ffffff", maxWidth: '140px'}}
          onChange={e => updateMineralFertilizer(e, editValueName)}
        />
      </Grid>
      {/*<Grid item md={3} sx={{ padding: '10px' }}>
        <Typography style={subHeadingText}>
          <MuiTextBox 
            name={`${fieldId}_${editValueId}`}
            id={`${fieldId}_${editValueId}`}
            label="Kg/daa"
            variant="outlined"
            value=" "
            showFullWidth="true"
            disabled={true}
          />
        </Typography>
      </Grid>*/}
      <Grid item md={4} sx={{ padding: '10px' }}>
        <Typography style={subHeadingText}>
          <MuiTextBox 
            onChange={e => updateSeasonFertilizer(e)}
            id={editValueId}
            name={editValueName}
            label="Kg/daa"
            variant="outlined"
            value={addingFertilizer[editValueId]}
            showFullWidth="true"
          />
        </Typography>
      </Grid>
    </Grid>);
}

function getMineralFertilizerManureTextBoxLeft(
  fieldId,
  seasonFertilizationItem,
  mineralFertilizerItem,
  updateMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  index,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList
) {
  let editValueName =
    seasonFertilizationItem.seasonId +
    '~~' +
    fieldId +
    '~~' +
    (mineralFertilizerItem.originalName);
  let editValueId =
    seasonFertilizationItem.seasonId + '~~' + fieldId + '~~' + index;
  return (
    <Grid container justifyContent="flex-end" sx={{ padding: "6px" }}>
      <Grid item md={1} sx={{ padding: '10px' }}>
        <Typography style={subHeadingCapitalizeText} sx={{ paddingTop: '18px' }}>
          {/*<CloseIcon onClick={e =>deleteMineralFertilizer(e, editValueName)} />*/}
        </Typography>
      </Grid>
      <Grid item md={7} sx={{ padding: '10px', maxWidth: '140px' }}>
        <MuiSelectBox 
          name={`${fieldId}_manureMineralFertilizer1`}
          id={`${fieldId}_manureMineralFertilizer1`}
          label=""
          value={mineralFertilizerItem.originalName}
          options={mineralFertilizerList}
          sx={{ backgroundColor: "#ffffff", maxWidth: '140px'}}
          //onChange={e => updateMineralFertilizer(e, editValueName)}
        />
      </Grid>
      {/*<Grid item md={3} sx={{ padding: '10px' }}>
        <Typography style={subHeadingText}>
          <MuiTextBox 
            name={`${fieldId}_${editValueId}`}
            id={`${fieldId}_${editValueId}`}
            label="Kg/daa"
            variant="outlined"
            value=" "
            showFullWidth="true"
            disabled={true}
          />
        </Typography>
      </Grid>*/}
      <Grid item md={4} sx={{ padding: '10px' }}>
        <Typography style={subHeadingText}>
          <MuiTextBox 
            //onChange={e => updateSeasonFertilizer(e)}
            id={editValueId}
            name={editValueName}
            label="Kg/daa"
            variant="outlined"
            value={addingFertilizerLeft[editValueId]}
            showFullWidth="true"
            disabled={true}
          />
        </Typography>
      </Grid>
    </Grid>);
}

function aboveHeaderBothTrue(
  fieldId,
  seasonFertilizationItem,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList,
  updateSeasonManure,
  manureStorageList,
  updateManureStorageMenu
) {
  return (
    <div>
      {getManureTotalContent(
        fieldId,
        seasonFertilizationItem,
        updateSeasonManure,
        viewFertilizerPlan,
        isLoading,
        intl,
        manureStorageList,
        updateManureStorageMenu
      )}
      {getMineralFertilizerTotalCountLeft(
        fieldId,
        seasonFertilizationItem,
        updateMineralFertilizer,
        addMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        isLoading,
        intl,
        addingFertilizer,
        addingFertilizerLeft,
        mineralFertilizerList
      )}
      {getPlanHeadingNoIcon(intl, seasonFertilizationItem)}
    </div>
  );
}

function aboveHeaderActualManureTrue(
  fieldId,
  seasonFertilizationItem,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList,
  updateSeasonManure,
  manureStorageList,
  updateManureStorageMenu
) {
  return (
    <div>
      {getManureTotalContent(
        fieldId,
        seasonFertilizationItem,
        updateSeasonManure,
        viewFertilizerPlan,
        isLoading,
        intl,
        manureStorageList,
        updateManureStorageMenu
      )}
      {getPlanHeadingNoIcon(intl, seasonFertilizationItem)}
    </div>
  );
}

function aboveHeaderActualMFTrue(
  fieldId,
  seasonFertilizationItem,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList,
  updateSeasonManure,
  manureStorageList,
  updateManureStorageMenu
) {
  return (
    <div>
      {getMineralFertilizerTotalCountLeft(
        fieldId,
        seasonFertilizationItem,
        updateMineralFertilizer,
        addMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        isLoading,
        intl,
        addingFertilizer,
        addingFertilizerLeft,
        mineralFertilizerList
      )}
      {getPlanHeadingNoIcon(intl, seasonFertilizationItem)}
      {getManureTotalContent(
        fieldId,
        seasonFertilizationItem,
        updateSeasonManure,
        viewFertilizerPlan,
        isLoading,
        intl,
        manureStorageList,
        updateManureStorageMenu
      )}
    </div>
  );
}

function belowHeader(
  fieldId,
  seasonFertilizationItem,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  mineralFertilizerList,
  updateSeasonManure,
  manureStorageList,
  updateManureStorageMenu
) {
  return (
    <div>
      {getManureTotalContent(
        fieldId,
        seasonFertilizationItem,
        updateSeasonManure,
        viewFertilizerPlan,
        isLoading,
        intl,
        manureStorageList,
        updateManureStorageMenu
      )}
      {getMineralFertilizerTotalCountLeft(
        fieldId,
        seasonFertilizationItem,
        updateMineralFertilizer,
        addMineralFertilizer,
        deleteMineralFertilizer,
        updateSeasonFertilizer,
        viewFertilizerPlan,
        isLoading,
        intl,
        addingFertilizer,
        addingFertilizerLeft,
        mineralFertilizerList
      )}
    </div>
  );
}

function getFertilizerPlanForSeason(
    fieldFertilizationItem,
    updateSeasonManure,
    updateMineralFertilizer,
    addMineralFertilizer,
    deleteMineralFertilizer,
    updateSeasonFertilizer,
    viewFertilizerPlan,
    isLoading,
    intl,
    addingFertilizer,
    addingFertilizerLeft,
    updateCompleted,
    mineralFertilizerList,
    manureStorageList,
    expandedSeasonColumnNeed,
    expandedSeasonColumnAdded,
    handleExpandSeasonNeed,
    handleExpandSeasonAdded,
    expandedActualColumnAdded,
    handleExpandActualAdded,
    updateManureStorageMenu
) {
  let fieldId = fieldFertilizationItem.fieldId;
  let borderBottomStyle = '';
  let fertilizationLength = fieldFertilizationItem.seasonFertilizerPlans?.length-1;
  if (fieldFertilizationItem.seasonFertilizerPlans?.length > 0) {
    return fieldFertilizationItem.seasonFertilizerPlans.map((seasonFertilizationItem, index) => (
      //eslint-disable-next-line
      fertilizationLength > index ? borderBottomStyle = '1px solid #BEC8C9' : borderBottomStyle = '',
      <List
        sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0', width: '100%', textAlign: 'center' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton disableRipple sx={{ '&:hover': {backgroundColor: 'transparent'}, display: 'inline-flex' }}>
          <ListItemText style={titleText}>{seasonFertilizationItem.season}</ListItemText>
        </ListItemButton>
        <ListItemButton disableRipple sx={{ width: '100%', paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, '&:focused': {backgroundColor: 'transparent'} }}>
          <div key={index} className="fertilization-grey-header-mui" style={{ background: "transparent", borderBottom: `${borderBottomStyle}`, paddingBottom: "20px" }}>
            <div className="fertilization-plan-content">
              <div className="fertilization-plan-grid-blue-border-mui">
                <div>
                  {getPlanHeadingIcon(intl, seasonFertilizationItem)}
                  {seasonFertilizationItem.showHeaderBeforeMF ?
                    ((seasonFertilizationItem.actualManure && seasonFertilizationItem.actualMF) ?
                      aboveHeaderBothTrue(
                        fieldId,
                        seasonFertilizationItem,
                        updateMineralFertilizer,
                        addMineralFertilizer,
                        deleteMineralFertilizer,
                        updateSeasonFertilizer,
                        viewFertilizerPlan,
                        isLoading,
                        intl,
                        addingFertilizer,
                        addingFertilizerLeft,
                        mineralFertilizerList,
                        updateSeasonManure,
                        manureStorageList,
                        updateManureStorageMenu
                      ) : 
                    (seasonFertilizationItem.actualManure && !seasonFertilizationItem.actualMF) ?
                      aboveHeaderActualManureTrue(
                        fieldId,
                        seasonFertilizationItem,
                        updateMineralFertilizer,
                        addMineralFertilizer,
                        deleteMineralFertilizer,
                        updateSeasonFertilizer,
                        viewFertilizerPlan,
                        isLoading,
                        intl,
                        addingFertilizer,
                        addingFertilizerLeft,
                        mineralFertilizerList,
                        updateSeasonManure,
                        manureStorageList,
                        updateManureStorageMenu
                      ) : 
                    (!seasonFertilizationItem.actualManure && seasonFertilizationItem.actualMF) ?
                      aboveHeaderActualMFTrue(
                        fieldId,
                        seasonFertilizationItem,
                        updateMineralFertilizer,
                        addMineralFertilizer,
                        deleteMineralFertilizer,
                        updateSeasonFertilizer,
                        viewFertilizerPlan,
                        isLoading,
                        intl,
                        addingFertilizer,
                        addingFertilizerLeft,
                        mineralFertilizerList,
                        updateSeasonManure,
                        manureStorageList,
                        updateManureStorageMenu
                      ) : ''
                    )
                  :
                    belowHeader(
                      fieldId,
                      seasonFertilizationItem,
                      updateMineralFertilizer,
                      addMineralFertilizer,
                      deleteMineralFertilizer,
                      updateSeasonFertilizer,
                      viewFertilizerPlan,
                      isLoading,
                      intl,
                      addingFertilizer,
                      addingFertilizerLeft,
                      mineralFertilizerList,
                      updateSeasonManure,
                      manureStorageList,
                      updateManureStorageMenu
                    )
                  }
                  {getMineralFertilizerTotalCount(
                    fieldId,
                    seasonFertilizationItem,
                    updateMineralFertilizer,
                    addMineralFertilizer,
                    deleteMineralFertilizer,
                    updateSeasonFertilizer,
                    viewFertilizerPlan,
                    isLoading,
                    intl,
                    addingFertilizer,
                    addingFertilizerLeft,
                    mineralFertilizerList
                  )}
                  <Grid container justifyContent="flex-end" sx={{ width: "100%" }}>
                    <Grid item md={1} sx={{ padding: "10px" }}>
                      <Typography style={subHeadingCapitalizeText}>&nbsp;</Typography>
                    </Grid>
                    <Grid item md={11}>
                      <Chip
                        icon={<PlusIcon />}
                        label={intl.formatMessage({ id: 'ADD' })}
                        color="primary"
                        clickable
                        style={buttonStyle}
                        sx={{ backgroundColor: 'transparent',
                          '&:hover, &:focus': {
                            backgroundColor: 'transparent',
                          }
                        }}
                        onClick={() => addMineralFertilizer(seasonFertilizationItem.seasonId+'~~'+fieldId)}
                        disableRipple
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div style= {{ paddingLeft: "20px" }}>
                <div>
                  <div>
                    {getMineralFertilizerHeader(intl)}
                  </div>
                  <div style={{ paddingLeft:'6px', paddingRight: '6px'}}>
                    {getMineralFertilizerNeed(seasonFertilizationItem, intl, expandedSeasonColumnNeed, handleExpandSeasonNeed, index)}
                  </div>
                  <div style={{ paddingLeft:'6px', paddingRight: '6px'}}>
                    {getMineralFertilizerAdded(seasonFertilizationItem, intl, expandedActualColumnAdded, handleExpandActualAdded, index)}
                  </div>
                  <div style={{ paddingLeft:'6px', paddingRight: '6px'}}>
                    {getMineralFertilizerPlannedAdded(seasonFertilizationItem, intl, expandedSeasonColumnAdded, handleExpandSeasonAdded, index)}
                  </div>
                  <div style={{ paddingLeft:'6px', paddingRight: '6px'}}>
                    {getMineralFertilizerPlannedDifference(seasonFertilizationItem, intl)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ListItemButton>
      </List>
    ));
  }
}

export default ({
  fieldFertilizationItem,
  updateSeasonManure,
  updateMineralFertilizer,
  addMineralFertilizer,
  deleteMineralFertilizer,
  updateSeasonFertilizer,
  viewFertilizerPlan,
  isLoading,
  intl,
  addingFertilizer,
  addingFertilizerLeft,
  updateCompleted,
  mineralFertilizerList,
  manureStorageList,
  expandedSeasonColumnNeed,
  expandedSeasonColumnAdded,
  handleExpandSeasonNeed,
  handleExpandSeasonAdded,
  expandedActualColumnAdded,
  handleExpandActualAdded,
  updateManureStorageMenu
}) =>
  getFertilizerPlanForSeason(
    fieldFertilizationItem,
    updateSeasonManure,
    updateMineralFertilizer,
    addMineralFertilizer,
    deleteMineralFertilizer,
    updateSeasonFertilizer,
    viewFertilizerPlan,
    isLoading,
    intl,
    addingFertilizer,
    addingFertilizerLeft,
    updateCompleted,
    mineralFertilizerList,
    manureStorageList,
    expandedSeasonColumnNeed,
    expandedSeasonColumnAdded,
    handleExpandSeasonNeed,
    handleExpandSeasonAdded,
    expandedActualColumnAdded,
    handleExpandActualAdded,
    updateManureStorageMenu
  );
