import {
  HISTORICAL_DATA_LOADING, 
  HISTORICAL_DATA_SUCCESS, 
  HISTORICAL_DATA_ERROR
} from 'constants/store/Fields';

const initialState = {
  hasError: false,
  historicalDataLoading: false,
  historicalDataError: null,
  historicalDataValue: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case HISTORICAL_DATA_LOADING:
      return {
        ...state,
        historicalDataLoading: true,
        hasError: false,
        historicalDataError: null
      };
    case HISTORICAL_DATA_ERROR:
      return {
        ...state,
        hasError: true,
        historicalDataError: action.payload,
        historicalDataLoading: false
      };
    case HISTORICAL_DATA_SUCCESS:
      return {
        ...state,
        historicalDataLoading: false,
        historicalDataError: null,
        hasError: false,
        historicalDataValue: action.payload
      };
    default:
      return state;
  }
}
