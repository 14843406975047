import {
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
  GET_LOCATIONS_LOADING,
  GET_TASK_LOCATIONS_SUCCESS,
  GET_TASK_LOCATIONS_ERROR,
  GET_TASK_LOCATIONS_LOADING,
  GET_BALE_FEED_EVENT_SUCCESS,
  GET_BALE_FEED_EVENT_ERROR,
  GET_BALE_FEED_EVENT_LOADING,  
  ADD_BALE_FEED_EVENT_SUCCESS,
  ADD_BALE_FEED_EVENT_ERROR,
  ADD_BALE_FEED_EVENT_LOADING,
  SUB_BALE_FEED_EVENT_SUCCESS,
  SUB_BALE_FEED_EVENT_ERROR,
  SUB_BALE_FEED_EVENT_LOADING,
  ADD_TASK_BALE_SUCCESS,
  ADD_TASK_BALE_ERROR,
  ADD_TASK_BALE_LOADING
} from 'constants/store/Bale';
import { POST, GET, PUT } from 'constants/index';
import { FARM_V1 } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

export const getLocations = (farmId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LOCATIONS_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/balestorages`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_LOCATIONS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_LOCATIONS_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_LOCATIONS_ERROR, payload: error });
  }
};

export const getTaskLocations = (farmId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TASK_LOCATIONS_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/po-events/tasks`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_TASK_LOCATIONS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_TASK_LOCATIONS_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_TASK_LOCATIONS_ERROR, payload: error });
  }
};

export const getFeedEventList = (farmId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_BALE_FEED_EVENT_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/feed-events/storages`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: GET_BALE_FEED_EVENT_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_BALE_FEED_EVENT_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: GET_BALE_FEED_EVENT_ERROR, payload: error });
  }
};

export const addFeedEvent = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_BALE_FEED_EVENT_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/po-events`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: POST,
        data
      })
    )
    .then(response => {
      dispatch({ type: ADD_BALE_FEED_EVENT_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: ADD_BALE_FEED_EVENT_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: ADD_BALE_FEED_EVENT_ERROR, payload: error });
  }
};

export const deleteFeedEvent = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUB_BALE_FEED_EVENT_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/feed-events`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: POST,
        data
      })
    )
    .then(response => {
      dispatch({ type: SUB_BALE_FEED_EVENT_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SUB_BALE_FEED_EVENT_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: SUB_BALE_FEED_EVENT_ERROR, payload: error });
  }
};

export const addBaleTask = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_TASK_BALE_LOADING, payload: true });
    const apiUrl = `${FARM_V1}/${farmId}/bales/po-events`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: PUT,
        data
      })
    )
    .then(response => {
      dispatch({ type: ADD_TASK_BALE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: ADD_TASK_BALE_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: ADD_TASK_BALE_ERROR, payload: error });
  }
};