import React from "react";
import Chart from "react-apexcharts";

var data1 = [12,21,26,32,35,47,50,55,59,62,65,70,99,103,110,130,160,175,180,185];
var data2 = [8,12,24,34,35,45,56,67,78,89,90,112,123,124,135,145,156,178,190,200];
var data3 = [6,23,25,45,67,89,90,110,112,134,156,178,null,null,null,null,null,null,null,null];

var series = [
  {name: "2019", data: data1}, {name: "2020", data: data2}, {name: "2021", data: data3}
];

var options = {
  chart: {
    height: 550,
    type: 'line',
    zoom: {
      enabled: false
    },
    animations: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: '2017. Temperature and feed quality. Lodviken',
    align: 'center'
  },
  labels: ['02-Jan', '21-Jan', '12-Feb', '24-Feb', '10-Mar', '21-Mar', '01-Apr', '05-Apr','25-Apr','06-May',
          '23-May', '16-Jun', '22-Jun', '14-Jul', '10-Aug', '21-Aug', '09-Sep', '05-Oct','21-Nov','15-Dec'],
  xaxis: {
  }
}; 

const ApexChart = () => (
  <Chart
    options={options}
    series={series}
    type="line"
    width="750"
  />
);

export default ApexChart;
