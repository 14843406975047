import axios from 'axios';
import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_REPORTS_LBL,
  GA_REPORT_DOWNLOAD,
  GA_CAT_ERR,
  GA_REPORT_DOWNLOAD_ERR
} from 'constants/GoogleAnalytics';
import { API_URI, DOWNLOAD_REPORT } from 'constants/ApiUrl';
import { IS_EXPORT_LOADING, EXPORT_SUCCESS, EXPORT_ERR } from '../constants/store/Reports';
import { GetLanguageCode } from 'utils/index';

/* export const reportsHasError = (isError, errorMsg, bool) => ({
  type: REPORTS_DOWNLOAD_FAILURE,
  payload: { isError, errorMsg, bool }
});

export const reportDownloadSuccess = (successMsg, bool) => ({
  type: REPORTS_DOWNLOAD_SUCCESS,
  payload: { successMsg, bool }
}); */

export default (activeFarm, days, statuses, activityIds, fieldIds, sortField) => {
  return (dispatch, getState) => {
    dispatch({ type: IS_EXPORT_LOADING });
    const { idToken } = getState().userDetails?.data;
    const { preferredLanguage } = getState().userDetails?.data;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: idToken
        },
        responseType: 'arraybuffer'
      };
      let url = `${API_URI}${DOWNLOAD_REPORT}${activeFarm.farm.id}/download-tasks?language=${GetLanguageCode(
        preferredLanguage
      )}`;
      url = days ? `${url}&days=${days}` : url;
      url = statuses.length > 0 ? `${url}&statuses=${statuses}` : url;
      url = activityIds.length > 0 ? `${url}&activityIds=${activityIds}` : url;
      url = fieldIds.length > 0 ? `${url}&fieldIds=${fieldIds}` : url;
      url = (sortField && sortField !== 'Date created') ? `${url}&sortField=${sortField}` : url;
      axios
        .get(url, config)
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          GAEvent(GA_REPORT_DOWNLOAD, GA_REPORTS_LBL, GA_REPORTS_LBL);
          if (window.navigator.msSaveBlob) {
            // IE
            window.navigator.msSaveOrOpenBlob(blob, 'TaskList-Details.xlsx');
            dispatch({ type: EXPORT_SUCCESS });
          } else {
            link.target = '_blank';
            link.classList.add('downloadReportLink');
            link.href = objectUrl;
            const date = new Date();
            link.download = `${
              activeFarm.farm.name
            }-${date.toLocaleDateString()}-${new Date().toLocaleTimeString()}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch({ type: EXPORT_SUCCESS });
            // dispatch(reportDownloadSuccess('Report downloaded successfully.', false));
          }
        })
        .catch(error => {
          GAEvent(GA_REPORT_DOWNLOAD_ERR, GA_CAT_ERR, GA_REPORTS_LBL);
          // dispatch(reportsHasError(true, error.message, false));
          dispatch({ type: EXPORT_ERR, errorMsg: error.message });
        });
    } catch (error) {
      GAEvent(GA_REPORT_DOWNLOAD_ERR, GA_CAT_ERR, GA_REPORTS_LBL);
      dispatch({ type: EXPORT_ERR, errorMsg: error.message });
    }
  };
};
