import React from 'react';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';

const SelectedAnalysisCard = ({
    intl,
    selectedAnalysisList
  }) => (
  <li className={`storage-list-item full-width`} key='1'>
    <div className={`storage-list-item-selected-link`}>
      <div className="analysis-column">
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_1' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.energy ? selectedAnalysisList.analysisParams.energy : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_1_UNIT' })}</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'WEIGHT' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.weight ? selectedAnalysisList.analysisParams.weight : ' - '}
            </span>
            <span> kg</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_2' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.dryMatter ? selectedAnalysisList.analysisParams.dryMatter : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_2_UNIT' })}</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_3' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.rawProtein ? selectedAnalysisList.analysisParams.rawProtein : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_3_UNIT' })}</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_4' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.digestibility ? selectedAnalysisList.analysisParams.digestibility : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_4_UNIT' })}</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_5' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.aat ? selectedAnalysisList.analysisParams.aat : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_5_UNIT' })}</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_6' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.pbv ? selectedAnalysisList.analysisParams.pbv : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_6_UNIT' })}</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_7' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.ndf ? selectedAnalysisList.analysisParams.ndf : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_7_UNIT' })}</span>
          </span>
        </div>
        <div>
          <span className="left-label">{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_8' })}</span>
          <span className="right-label">
            <span className="label-number">
              {selectedAnalysisList.analysisParams && selectedAnalysisList.analysisParams.ash ? selectedAnalysisList.analysisParams.ash : ' - '}
            </span>
            <span> {intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_8_UNIT' })}</span>
          </span>
        </div>
        <div className="display-inline-block">
          <div className="padding-top-div padding-bottom-div">
            <Emphasis size="normal">{intl.formatMessage({ id: 'SELECTED_ANALYSIS_SUB_TITLE' })}</Emphasis>
          </div>
          {selectedAnalysisList && selectedAnalysisList.fieldStorageDetails &&
            selectedAnalysisList.fieldStorageDetails.map((storageDetails, index) => (
            <div className="display-fields-array" key={index}>
              <span>{storageDetails.fieldName} </span>
              {storageDetails.quantity &&
              <span>                
                ({storageDetails.quantity} {intl.formatMessage({ id: 'SELECTED_ANALYSIS_HARVEST_FIELD' })} {storageDetails.storageName})
              </span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  </li>
);

export default SelectedAnalysisCard;
