import {
  ADD_ASSET_SUCCESS,
  ADD_ASSET_ERROR,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
  ASSET_TYPE,
  ASSET_TYPE_FAILURE,
  ASSET_TYPE_ENTITY,
  ASSET_TYPE_ENTITY_FAILURE,
  CLEAR_ASSET_MESSAGES,
  IS_ASSET_LOADING,
  ASSET_LIST_ERROR,
  ASSET_LIST,
  ASSET_SORT_BY_OPTION,
  ASSET_ENTITY_LOADING
} from 'constants/store/Assets';
import { VEHICLE, EQUIPMENT } from 'constants/index';
import { AssetSortingOptions } from 'constants/DropdownAndTranslationOptions';

const initialState = {
  isAssetLoading: false,
  isAssetSuccess: false,
  hasAssetError: false,
  isAssetListSuccess: false,
  errorMessage: null,
  isTypeEntityError: false,
  assets: {
    equipment: [],
    vehicle: []
  },
  assetType: {
    equipment: [],
    vehicle: []
  },
  typeEntity: [],
  isEntityLoading: false,
  vehicleSortBy: { value: AssetSortingOptions[0].value, label: AssetSortingOptions[0].transLbl },
  equipmentSortBy: {
    value: AssetSortingOptions[0].value,
    label: AssetSortingOptions[0].transLbl
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ASSET_SUCCESS:
    case DELETE_ASSET_SUCCESS:
      return {
        ...state,
        isAssetListSuccess: false,
        isAssetLoading: false,
        isAssetSuccess: true,
        hasAssetError: false,
        errorMessage: null
      };
    case ASSET_TYPE:
      return {
        ...state,
        isAssetLoading: false,
        isAssetSuccess: false,
        hasAssetError: false,
        errorMessage: null,
        assetType: {
          equipment: action.category === EQUIPMENT ? action.payload : state.assets.equipment,
          vehicle: action.category === VEHICLE ? action.payload : state.assets.vehicle
        },
        typeEntity: []
      };
    case ASSET_ENTITY_LOADING:
      return { ...state, isEntityLoading: action.payload };
    case ASSET_TYPE_ENTITY:
      return {
        ...state,
        isEntityLoading: false,
        typeEntity: action.payload
      };
    case ASSET_LIST:
      return {
        ...state,
        isAssetLoading: false,
        isAssetSuccess: false,
        hasAssetError: false,
        isAssetListSuccess: true,
        errorMessage: null,
        assets: {
          equipment: action.category === EQUIPMENT ? action.payload : state.assets.equipment,
          vehicle: action.category === VEHICLE ? action.payload : state.assets.vehicle
        }
      };
    case ADD_ASSET_ERROR:
    case DELETE_ASSET_ERROR:
    case ASSET_LIST_ERROR:
      return {
        ...state,
        isAssetLoading: false,
        isAssetSuccess: false,
        isAssetListSuccess: false,
        hasAssetError: true,
        errorMessage: action.payload
      };
    case ASSET_TYPE_FAILURE:
      return {
        ...state,
        isAssetLoading: false,
        isAssetSuccess: false,
        hasAssetError: true,
        errorMessage: action.payload,
        assetType: {
          equipment: [],
          vehicle: []
        },
        typeEntity: []
      };
    case ASSET_TYPE_ENTITY_FAILURE:
      return {
        ...state,
        isAssetLoading: false,
        isAssetSuccess: false,
        hasAssetError: false,
        isTypeEntityError: true,
        errorMessage: action.payload,
        typeEntity: []
      };
    case CLEAR_ASSET_MESSAGES:
      return {
        ...state,
        isAssetSuccess: false,
        hasAssetError: false,
        isAssetListSuccess: false,
        errorMessage: 'null'
      };
    case IS_ASSET_LOADING:
      return { ...state, isAssetLoading: action.payload, isAssetListSuccess: false };
    case ASSET_SORT_BY_OPTION:
      return {
        ...state,
        vehicleSortBy: action.category === VEHICLE ? action.payload : state.vehicleSortBy,
        equipmentSortBy: action.category === EQUIPMENT ? action.payload : state.equipmentSortBy
      };
    default:
      return state;
  }
};
