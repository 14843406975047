import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography, Box, Grid, Menu, MenuItem } from '@mui/material';
import { DeleteIcon, EditGreyIcon } from 'components/mui-forms/MuiIcons';

class AssetCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  checkBoxChange = (e) => {
    const { handleCheckbox } = this.props;
    handleCheckbox(e);
  };

  handleClick = (event) => {
    event.stopPropagation(); // Prevent event from triggering the Link's onClick
    this.setState({ anchorEl: event.currentTarget });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleOutsideClick = (event) => {
    if (this.state.anchorEl && !this.state.anchorEl.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  handleDelete = (event, fieldId) => {
    event.preventDefault();
    event.stopPropagation();
    const { updateState } = this.props;
    this.setState({ anchorEl: null });
    updateState(true, fieldId);
  };

  handleEdit = (event) => {
    const { equipmentProps, machine } = this.props;
    event.preventDefault();
    event.stopPropagation();
    equipmentProps.onOpenEquipmentDialog(machine.assetAssignmentId);
  };

  render() {
    const { intl, machine, onOpenDeleteDialog, enableAssetButton } = this.props;
    const gridContainerStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    };

    const titleStyle = {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      letterSpacing: '0.5px',
    };

    return (
      <li
        className={`list-item full-width ${!enableAssetButton ? 'cursor-not-allowed' : ''}`}
        key={machine.assetAssignmentId}
      >
        <Link
          className={`settings-list-item-link ${!enableAssetButton ? 'pointer-events-none' : ''}`}
          to="#"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation(); // Prevents Link's default behavior
            this.handleEdit(e);
          }}
        >
          <Grid container style={gridContainerStyle}>
            <Grid item flex="10">
              <Typography style={{ ...titleStyle, color: '#3F4949', fontSize: '12px', fontWeight: 500, lineHeight: '16px' }}>
                {machine.asset.brand}
              </Typography>
              <Typography style={{ ...titleStyle, color: '#191C1C', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                {machine.asset.model.toUpperCase() === 'OTHER' ? intl.formatMessage({ id: 'EQUIPMENT_OTHER' }) : machine.asset.model}
              </Typography>
            </Grid>
            <Grid item flex="1" style={{ textAlign: 'right' }}>
              <Typography style={{ ...titleStyle, color: '#3F4949', fontSize: '12px', fontWeight: 500, lineHeight: '16px' }}>
                {machine.asset.year > 0 ? machine.asset.year : ''}
              </Typography>
            </Grid>
            <Grid item flex="1" style={{ textAlign: 'right', paddingRight: '20px', paddingTop: '8px' }}>
              <Box component="span">
                <MoreVertIcon onClick={this.handleClick} />
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{ width: '200px' }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent propagation to avoid Link click
                      this.handleEdit(e);
                    }}
                    style={{
                      backgroundColor: 'transparent',
                      padding: '8px 12px',
                      width: '160px',
                      display: 'flex',
                      height: '50px',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = '#f1f1f1';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <span style={{ width: '96%' }}>{intl.formatMessage({ id: 'EDIT' })}</span>
                      <span style={{ textAlign: 'end', float: 'right' }}>
                        <EditGreyIcon />
                      </span>
                    </div>
                  </MenuItem>
                  {enableAssetButton && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent propagation to avoid Link click
                        onOpenDeleteDialog(machine.assetAssignmentId);
                      }}
                      style={{
                        color: '#BA1A1A',
                        backgroundColor: 'transparent',
                        padding: '8px 12px',
                        width: '160px',
                        display: 'flex',
                        height: '50px',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#f1f1f1';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <span style={{ width: '96%' }}>{intl.formatMessage({ id: 'DELETE' })}</span>
                        <span style={{ textAlign: 'end', float: 'right' }}>
                          <DeleteIcon />
                        </span>
                      </div>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Link>
      </li>
    );
  }
}

export default injectIntl(AssetCard);