import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import { connect } from 'react-redux';
import {
  COMPLETED,
  ONGOING,
  PAUSED,
  ACT1,
  ACT7,
  ACT9,
  ACT10,
  ACT11,
  ACT12,
  ACT13,
  ACT16,
  ACT18,
  ACT20,
  ACT28
} from 'constants/index';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ProfileImage from 'components/ProfileImage';
import { SecondsTohoursMinutes, GetValuesFromDate, ParseISOString, PadString } from 'utils/index';
import { isMobile } from 'react-device-detect';
import { Grid } from '@mui/material'

class HistoricalListCard extends Component {

  getAmount = () => {
    const { historical } = this.props;
    if (historical.dynamicData) {
      const amountUnit = JSON.parse(historical.dynamicData);
      switch (amountUnit.activity_id) {
        case ACT9:
        case ACT10:
        case ACT16:
        case ACT28:
          return {
            amount: amountUnit.cut_amount ? amountUnit.cut_amount : null,
            unit: amountUnit.cut_unit
          };
        case ACT11:
        case ACT12:
        case ACT18:
          return {
            amount: amountUnit.cut_amount ? amountUnit.cut_amount : null,
            unit: <FormattedMessage id="TASK_DYNAMIC_HARVEST_UNIT" />
          };
        case ACT20:
          return {
            amount: amountUnit.combo_ferti_amount ? amountUnit.combo_ferti_amount : null,
            unit: amountUnit.combo_ferti_unit
          };
        case ACT13:
          return {
            amount: amountUnit.total_harvest_text ? amountUnit.total_harvest_text : null,
            unit: 'kg/daa'
          };
        case ACT1:
        case ACT7:
          return { amount: amountUnit.amount ? amountUnit.amount : null, unit: amountUnit.unit };
        default:
          return null;
      }
    }
    return null;
  };

  getEndTime = () => {
    const { historical } = this.props;
    if (historical.endTime) {
      const tempEndDateTime = historical.endTime
        ? GetValuesFromDate(new Date(ParseISOString(historical.endTime)))
        : { date: '', time: '' };
      return tempEndDateTime.date;
    }
    return null;
  };

  getDurationFormate = () => {
    const { historical } = this.props;
    if (historical.netDuration) {
      const hrsMin = SecondsTohoursMinutes(historical.netDuration);
      return `${PadString(hrsMin.hours)} hrs ${PadString(hrsMin.minutes)} min`;
    }
    return null;
  };

  render() {
    const { historical, disable, profilePictures, selectedFieldId } = this.props;
    const userImage = historical.assigneeId
      ? _.find(profilePictures, ['userId', historical.assigneeId])
      : null;
    const status = historical.status ? historical.status.toUpperCase() : null;
    const amountAndUnit = this.getAmount();
    const isDisabled = disable && 'disable';
    return (
      <li
        className={'task-list-item full-width'}
        key={historical.id}
      >
        <Link
          className={
            status && status === COMPLETED
              ? `task-link-field green-bdr ${isDisabled}`
              : `task-link-field ${isDisabled}`
          }
          to={`/tasks/tasklist/${historical.id}/${selectedFieldId}`}
        >
          <div className="task-details">
            <div className="task-assignee">
              <span className="task-name ellipsis-text">
                {status && (status === ONGOING || status === PAUSED) ? (
                  <i className="icon-record" />
                ) : null}
                <Emphasis size="large">
                  {historical.activityId ? (
                    historical.activityName
                  ) : (
                    <FormattedMessage id="NOT_DEFINED" />
                  )}
                  &nbsp;{historical.fertilizerType !== null ? `(${historical.fertilizerType})` : ''}
                </Emphasis>
              </span>
              <span className="assignee-name">
                <ProfileImage size="extra-small" imgUrl={userImage ? userImage.image : null} />
                <span className="profile-name ellipsis-text">
                  {historical.assigneeId
                    ? `${historical.assigneeFirstName} ${historical.assigneeLastName}`
                    : null}
                </span>
              </span>
            </div>
            <Grid container>
              <Grid item xs="6">
                <div className="task-status-field task-right-border status">
                  <span className="item-lbl">
                    <FormattedMessage id="STATUS" />:
                  </span>
                  <span className="item-value ellipsis-text">
                    {historical.status ? (
                      <FormattedMessage id={status} />
                    ) : (
                      null
                    )}
                  </span>
                </div>
              </Grid>
              <Grid item xs="6">
                <div className="task-status-field status">
                  <span className="item-lbl">
                    <FormattedMessage id="COMPLETED" />:
                  </span>
                  <span className="item-value ">
                    {historical.endTime ? (
                      this.getEndTime()
                    ) : (
                      <span className="item-value">--</span>
                    )}
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs="6">
                <div className="task-status-field task-right-border status">
                  <span className="item-lbl">
                    <FormattedMessage id="DURATION" />:
                  </span>
                  <span className="item-value ">
                    {historical.status === COMPLETED ? this.getDurationFormate() : null}
                  </span>
                </div>
              </Grid>
              <Grid item xs="6">
                <div className="task-status-field amount">
                  <span className="item-lbl">
                    <FormattedMessage id="AMOUNT" />:
                  </span>
                  {amountAndUnit && amountAndUnit.amount ? (
                    <span
                      title={`${amountAndUnit.amount} ${amountAndUnit.unit}`}
                      className="item-value ellipsis-text"
                    >
                      {amountAndUnit.amount} {amountAndUnit.unit}
                    </span>
                  ) : (
                    <span className="item-value">--</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Link>
      </li>
    );
  }
}

const mapStateToProps = state => ({
  profilePictures: state.profileImage.userImage
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalListCard);

