import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ManureStorageList from 'mui-components/storages/manurestorage/ManureStorageList';
import PageNotFound from 'components/PageNotFound';

const ManageUsers = () => (
  <Switch>
    <Route path="/storage/manure-storage" component={ManureStorageList} />
    <Route path="/storage" component={ManureStorageList} />
    <Route component={PageNotFound} />
  </Switch>
);

export default ManageUsers;
