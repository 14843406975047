import { FERTILIZATION_PLAN } from 'constants/ApiUrl';
import { 
  MINERAL_FERTILIZER_LIST_LOADING, 
  MINERAL_FERTILIZER_LIST_SUCCESS, 
  MINERAL_FERTILIZER_LIST_FAILURE,
  GET_MINERAL_FERTILIZER_LIST_LOADING, 
  GET_MINERAL_FERTILIZER_LIST_SUCCESS, 
  GET_MINERAL_FERTILIZER_LIST_FAILURE
} from 'constants/store/Fertilization';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Get all the mineral fertilizer
 */
export const mineralFertilizerList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MINERAL_FERTILIZER_LIST_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}mineralfertilizers?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: MINERAL_FERTILIZER_LIST_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: MINERAL_FERTILIZER_LIST_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: MINERAL_FERTILIZER_LIST_FAILURE, payload: error });
    }
};

/**
 * Get all the mineral fertilizer
 */
export const getMineralFertilizerList = (farmId,year) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_MINERAL_FERTILIZER_LIST_LOADING, payload: true });
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/preferred-mineralfertilizers?year=${year}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: GET_MINERAL_FERTILIZER_LIST_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: GET_MINERAL_FERTILIZER_LIST_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: GET_MINERAL_FERTILIZER_LIST_FAILURE, payload: error });
    }
};