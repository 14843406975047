import * as React from 'react';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import Numeral from '@tine/lib-frontend-components/elements/typography/numeral';
import { Chip } from '@mui/material';
import Selectbox from 'components/forms/Selectbox';
import { buttonRoundStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import { PlusIcon } from 'components/mui-forms/MuiIcons';
import mixpanel from 'mixpanel-browser';

export default ({
  title,
  route,
  routeText,
  totalAmount,
  value,
  totalHarvestAmount,
  harvestValue,
  valueUnit,
  description,
  filterOption,
  fieldFilterOption,
  harvestFilterOption,
  onFilterChange,
  onFieldFilterChange,
  onHarvestFilterChange,
  filterValue,
  fieldFilterValue,
  harvestFilterValue,
  isLoading,
  totalHours,
  totalMinutes,
  valueUnit1,
  valueUnit2,
  history,
  totalDuration,
  mixPanelDetails,
  userDetails
}) => (
    <div className={`kpi-box${value || totalAmount || (fieldFilterOption && fieldFilterOption.length > 0) || (harvestFilterOption && harvestFilterOption.length > 0) ? ' kpi-box--active' : ''}`}>
      <div className={`kpi-box__title ${filterOption ? ' kpi-box__title--has-filter' : ''}`}>
        <EmphasisLight size="small">{title}</EmphasisLight>
        {(totalAmount || value) && filterOption ? (
          <Selectbox
            styleName="select-box-common"
            value={filterValue}
            placeholder=""
            options={filterOption}
            onChange={onFilterChange}
            disabled={isLoading}
          />
        ) : (
          ''
        )}
      </div>
      {(fieldFilterOption || harvestFilterOption) && fieldFilterOption.length > 0 && harvestFilterOption.length > 0 ? (
      <div className={`kpi-box__title`}>
        {fieldFilterOption.length > 0 ? (
          <Selectbox
            styleName="select-box-field"
            value={fieldFilterValue}
            placeholder=""
            options={fieldFilterOption}
            onChange={onFieldFilterChange}
            disabled={isLoading}
          />
        ) : (
          ''
        )}
        {harvestFilterOption.length > 0 ? (
          <Selectbox
            styleName="select-box-field"
            value={harvestFilterValue}
            placeholder=""
            options={harvestFilterOption}
            onChange={onHarvestFilterChange}
            disabled={isLoading}
          />
        ) : (
          ''
        )}
      </div> ) : ''}
      {totalAmount || value ? (
        totalDuration > 0 ?
        (<div className="kpi-info">
          <div className="kpi-info__text">
            <Text size="normal">{description}</Text>
          </div>
          <div className="kpi-info__no_1">
            <Numeral size="large">&nbsp;{totalMinutes}</Numeral>
            <Numeral size="normal">{valueUnit2}</Numeral>
            <div className="float-left-css-kpi">
              <Numeral size="large">{totalHours}</Numeral>
              <Numeral size="normal">{valueUnit1}</Numeral>
            </div>
          </div>
        </div>) :
        (<div className="kpi-info">
          <div className="kpi-info__text">
            <Text size="normal">{description}</Text>
          </div>
          <div className="kpi-info__no">
            <Numeral size="large">{value}</Numeral>
            <Numeral size="normal">{valueUnit}</Numeral>
          </div>
        </div>)
      ) : ((fieldFilterOption || harvestFilterOption) && fieldFilterOption.length > 0 && harvestFilterOption.length > 0 ? 
        <div className="kpi-info">
          <div className="kpi-info__text">
            <Text size="normal">{description}</Text>
          </div>
          <div className="kpi-info__no">
            <Numeral size="large">{harvestValue}</Numeral>
            <Numeral size="normal">{valueUnit}</Numeral>
          </div>
        </div> : 
        <div className="kpi-add">
          <div className="kpi-add__text">
            <Text size="normal">{description}</Text>
          </div>
          <div style={{paddingTop: '10px', cursor: 'pointer' }}>
            {route && (
              mixpanel ?
              (<Chip
                icon={<PlusIcon />}
                label={routeText}
                color="primary"
                variant="outlined"
                clickable
                style={buttonRoundStyle}
                onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(mixPanelDetails); history.push(route)}}
              />) :
              (<Chip
                icon={<PlusIcon />}
                label={routeText}
                color="primary"
                variant="outlined"
                clickable
                style={buttonRoundStyle}
                onClick={() => {history.push(route)}}
              />)
            )}
          </div>
        </div>
      )}
    </div>
);
