import React, { Component } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import en from 'react-intl/locale-data/en';
import nn from 'react-intl/locale-data/nn';
import nb from 'react-intl/locale-data/nb';
import nnTranslation from 'translation/nn/index';
import enTranslation from 'translation/en/index';
import nbTranslation from 'translation/nb/index';
import { GetLocalData, GetLanguageCode, GetSessionData, BrowserLanguage } from 'utils';
import { LOCAL_KEY_USER } from 'constants/index';

addLocaleData([...en, ...nn, ...nb]);

class IntlProviderWrapper extends Component {
  constructor(...args) {
    super(...args);

    // based on the local data language will render
    const user = GetLocalData(LOCAL_KEY_USER) || GetSessionData(LOCAL_KEY_USER);
    if (user && user.preferredLanguage) {
      const langCode = GetLanguageCode(user.preferredLanguage);
      this.state = {
        locale: langCode,
        messages: this.getMessage(langCode)
      };
    } else {
      const langKeyMessage = this.getLanguageKeyMessage(BrowserLanguage());
      this.state = {
        locale: langKeyMessage.locale,
        messages: langKeyMessage.messages
      };
    }
  }

  getMessage = langCode => {
    switch (langCode) {
      case 'en':
        return enTranslation;
      case 'nn':
        return nnTranslation;
      case 'nb':
        return nbTranslation;
      default:
        return nbTranslation;
    }
  };

  // to get language key & message
  getLanguageKeyMessage = lang => {
    let langMessage;
    switch (lang) {
      case 'en':
        langMessage = { locale: 'en', messages: enTranslation };
        break;
      case 'nn':
        langMessage = { locale: 'nn', messages: nnTranslation };
        break;
      case 'nb':
        langMessage = { locale: 'nb', messages: nbTranslation };
        break;
      default:
        langMessage = { locale: 'nb', messages: nbTranslation };
    }
    return langMessage;
  };

  render() {
    const { children, userDetails } = this.props;
    let { locale, messages } = this.state;
    if (userDetails && userDetails.preferredLanguage) {
      locale = GetLanguageCode(userDetails.preferredLanguage);
      messages = this.getMessage(locale);
    }
    return (
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="nb"
        textComponent={React.Fragment}
      >
        {children}
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({ userDetails: state.userDetails.data });
export default connect(mapStateToProps)(IntlProviderWrapper);
