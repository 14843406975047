import { 
  EXPORT_FIELD_LOADING, 
  EXPORT_FIELD_SHAPE,
  EXPORT_FIELD_SHAPE_ERROR
} from 'constants/store/Fields';
import { GET } from 'constants/index';
import { FIELD_V1 } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';


/**
 * Export the field shape
 * @param {string} farmId
 * @param {string} farmName
 */
 export const exportShape = (farmId, farmName) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXPORT_FIELD_LOADING, payload: true });
    const apiUrl = `${FIELD_V1}${farmId}/fields/export?fileName=${farmName}`;
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${apiUrl}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: EXPORT_FIELD_SHAPE, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: EXPORT_FIELD_SHAPE_ERROR, payload: error });
    });
  } catch (error) {
    dispatch({ type: EXPORT_FIELD_SHAPE_ERROR, payload: error });
  }
};