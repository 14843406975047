import { USERS, USERS_V2 } from 'constants/ApiUrl';
import { fetchFarmList } from 'actions/Farms';
import apiRequest from 'utils/ApiRequest';
import { API_ENDPOINT } from 'constants/index';
import { GetLanguageCode } from 'utils/index';
import {
  ADD_USER,
  ADD_USER_FAILURE,
  GET_ROLES,
  GET_ROLES_FAILURE,
  IS_GET_ROLES_LOADING,
  GET_USERS,
  GET_USERS_FAILURE,
  IS_ADD_USER_LOADING,
  NULLFIY_USER_MESSAGES,
  IS_USERS_LOADING,
  UPDATE_USER_ROLE,
  UPDATE_USER_FAILURE,
  IS_UPDATE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from 'constants/store/ManageUsers';
import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_ADD_USER,
  GA_ADD_USER_ERR,
  GA_UPDATE_USER,
  GA_DELETE_USER,
  GA_USER_LBL,
  GA_CAT_ERR,
  GA_CAT_ADD,
  GA_CAT_UPDATE,
  GA_CAT_DETELE
} from 'constants/GoogleAnalytics';

export const addNewRoleSuccess = data => ({
  type: ADD_USER,
  payload: data
});

export const addNewRoleFailure = errorMsg => ({
  type: ADD_USER_FAILURE,
  payload: errorMsg
});

export const isLoading = bool => ({
  type: IS_ADD_USER_LOADING,
  payload: bool
});

export const isGetRolesLoading = bool => ({
  type: IS_GET_ROLES_LOADING,
  payload: bool
});

export const getAllRolesSuccess = data => ({
  type: GET_ROLES,
  payload: data
});

export const getAllRolesFailure = (isError, errorMsg) => ({
  type: GET_ROLES_FAILURE,
  payload: { isError, errorMsg }
});

export const getAllUsers = data => ({
  type: GET_USERS,
  payload: data
});

export const getAllUsersFailure = (isError, errorMsg) => ({
  type: GET_USERS_FAILURE,
  payload: { isError, errorMsg }
});

export const isUsersLoading = bool => ({
  type: IS_USERS_LOADING,
  payload: bool
});

export const updateUser = user => ({
  type: UPDATE_USER_ROLE,
  payload: user
});

export const updateUserFailure = (id, errResponse) => ({
  type: UPDATE_USER_FAILURE,
  payload: { id, errResponse }
});

export const userIsUpdating = id => ({
  type: IS_UPDATE_USER_LOADING,
  payload: id
});

// remove all the success and failure message
export const nullifyFarmUserMessage = () => ({ type: NULLFIY_USER_MESSAGES, action: true });

export const deleteUser = userId => ({
  type: DELETE_USER_SUCCESS,
  payload: userId
});

export const deleteUserFailure = (id, errResponse) => ({
  type: DELETE_USER_FAILURE,
  payload: { id, errResponse }
});

// To add a user with a role for a given farm
export const addRole = (valuesObj, roleSelected, farmId) => async dispatch => {
  const roleObj = {
    role: {
      id: roleSelected.value,
      roleName: roleSelected.label.toUpperCase()
    },
    user: {
      emailId: valuesObj.emailId
    }
  };
  dispatch(isLoading(true));
  await dispatch(
    apiRequest({
      url: `${USERS}/${farmId}`,
      data: roleObj,
      method: 'post'
    })
  )
    .then(response => {
      GAEvent(GA_ADD_USER, GA_CAT_ADD, GA_USER_LBL);
      dispatch(addNewRoleSuccess('User invited successfully.'));
    })
    .catch(error => {
      GAEvent(GA_ADD_USER_ERR, GA_CAT_ERR, GA_USER_LBL);
      dispatch(addNewRoleFailure(error));
    });
};

// To fetch all possible user roles
export const getUserRoles = () => async (dispatch, getState) => {
  dispatch(nullifyFarmUserMessage(true));
  dispatch(isGetRolesLoading(true));
  const { preferredLanguage } = getState().userDetails?.data;
    let roleUri = `${API_ENDPOINT}roles?language=${GetLanguageCode(
      preferredLanguage
    )}`;
  await dispatch(
    apiRequest({
      url: roleUri,
      method: 'get'
    })
  )
    .then(response => {
      const rolesObj = [...response.data];
      const newRolesObj = rolesObj.map(({ roleName: label, id: value, ...rest }) => ({
        label,
        value,
        ...rest
      }));
      dispatch(getAllRolesSuccess(newRolesObj));
    })
    .catch(error => {
      dispatch(getAllRolesFailure(true, error));
    });
};

// To fetch all users for a given farm
export const getUsers = farmId => async dispatch => {
  dispatch(isUsersLoading(true));
  await dispatch(
    apiRequest({
      url: `${USERS}/${farmId}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch(getAllUsers(response.data));
    })
    .catch(error => {
      dispatch(getAllUsersFailure(true, error));
    });
};

// To update user role
export const updateUserRole = (user, farmId, loggedInUserData) => async dispatch => {
  dispatch(userIsUpdating(user.user.id));
  await dispatch(
    apiRequest({
      url: `${USERS_V2}/${farmId}`,
      method: 'put',
      data: user
    })
  )
    .then(response => {
      GAEvent(GA_UPDATE_USER, GA_CAT_UPDATE, GA_USER_LBL);
      dispatch(updateUser(user));
      if (loggedInUserData.id === user.user.id) {
        dispatch(fetchFarmList(farmId));
      }
    })
    .catch(error => {
      dispatch(updateUserFailure(user.user.id, error));
    });
};

// To delete user role for given farm
export const deleteUserRole = (userId, farmId, loggedInUserData) => async dispatch => {
  dispatch(userIsUpdating(userId));
  await dispatch(
    apiRequest({
      url: `${USERS_V2}/${farmId}/${userId}`,
      method: 'delete'
    })
  )
    .then(response => {
      GAEvent(GA_DELETE_USER, GA_CAT_DETELE, GA_USER_LBL);
      dispatch(deleteUser(userId));
      if (loggedInUserData.id === userId) {
        dispatch(fetchFarmList());
      }
    })
    .catch(error => {
      dispatch(deleteUserFailure(userId, error));
    });
};
