import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import NavigationButton from '@tine/lib-frontend-components/components/buttons/navigation-button';
import TextBoxControl from 'components/forms/TextBoxControl';
import { PasswordValidation } from 'utils/index';
import { changePassword } from 'actions/ForgotPassword';
import { CODE_422 } from 'constants/index';
import Loader from 'components/Loader';

class ChangePassword extends Component {
  state = {
    isLoading: false,
    hasError: false,
    errorMsg: '',
    isDone: false
  };

  componentDidMount = () => {
    const { history, location } = this.props;
    if (!_.has(location.state, 'emailId')) {
      history.push('/forgotpassword');
    }
  };

  /**
   * Handle farm submit
   */
  handleSubmit = values => {
    const { resetPassword, history, location } = this.props;
    this.setState({ isLoading: true, isDone: false, hasError: false });
    // call change password action
    resetPassword(location.state.emailId, values.tempPassword, values.newPassword, response => {
      if (response.status === 200) {
        this.setState({ isLoading: false, isDone: true });
        setTimeout(() => {
          history.push({ pathname: '/' });
        }, 1000);
      } else if (response.response && response.response.status === CODE_422) {
        this.setState({
          isLoading: false,
          hasError: true,
          errorMsg: <FormattedMessage id="INVALID_TEMP_PWD" />
        });
      } else {
        this.setState({
          isLoading: false,
          hasError: true,
          errorMsg: <FormattedMessage id="COMMON_ERR_TXT" />
        });
      }
    });
  };

  render() {
    const { handleSubmit, intl } = this.props;
    const { isDone, hasError, isLoading, errorMsg } = this.state;
    return (
      <div className="full-width forgot-password">
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <h3 className="full-width form-title pwd-align">
            <FormattedMessage id="changePwdTitle" />
          </h3>
          <div className="full-width pwd-align">
            <Field
              name="tempPassword"
              label={intl.formatMessage({ id: 'temporarypassword' })}
              component={TextBoxControl}
              controlId="tempPassword"
              type="password"
            />
          </div>
          <div className="full-width change-pwd-block">
            <Field
              name="newPassword"
              label={intl.formatMessage({ id: 'newPassword' })}
              component={TextBoxControl}
              controlId="newPassword"
              type="password"
            />
            <Field
              name="repeatPassword"
              label={intl.formatMessage({ id: 'repeatPassword' })}
              component={TextBoxControl}
              controlId="repeatPassword"
              type="password"
            />
            <p className="top-align">
              <FormattedMessage id="pwdValidateRule1" />
            </p>
            <p>
              <FormattedMessage id="pwdValidateRule2" />
            </p>
            <p>
              <FormattedMessage id="pwdValidateRule3" />
            </p>
            <p>
              <FormattedMessage id="pwdValidateRule4" />
            </p>
          </div>
          {isDone ? (
            <div className="sent-notify">
              <i className="success-indicator" />
              <span>
                <FormattedMessage id="passwordUpdated" />
              </span>
            </div>
          ) : null}
          {hasError ? <p className="error-text">{errorMsg}</p> : null}
          {isLoading ? <Loader styleName="login-loader" /> : null}
          <div className="btn-wapper">
            <NavigationButton disabled={isLoading} route="/" multi>
              {intl.formatMessage({ id: 'cancel' })}
            </NavigationButton>
            <ActionButton disabled={isLoading} onClick={() => handleSubmit(this.handleSubmit)}>
              {intl.formatMessage({ id: 'create' })}
            </ActionButton>
          </div>
        </form>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { intl } = props;
  if (!values.newPassword || !values.newPassword.trim()) {
    errors.newPassword = intl.formatMessage({ id: 'required' });
  }
  if (!values.tempPassword || !values.tempPassword.trim()) {
    errors.tempPassword = intl.formatMessage({ id: 'required' });
  }
  if (!values.repeatPassword || !values.repeatPassword.trim()) {
    errors.repeatPassword = intl.formatMessage({ id: 'required' });
  }
  if (values.newPassword && !PasswordValidation(values.newPassword)) {
    errors.newPassword = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.newPassword && values.newPassword.trim().length > 30) {
    errors.newPassword = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.newPassword !== values.repeatPassword) {
    errors.repeatPassword = intl.formatMessage({ id: 'PWD_MATCH' });
  }
  // If errors is empty, the form will submit
  return errors;
}

const mapDispatchToProps = dispatch => ({
  resetPassword: (emailId, tempPassword, newPassword, callback) =>
    dispatch(changePassword(emailId, tempPassword, newPassword, callback))
});

export default injectIntl(
  reduxForm({
    form: 'ChangePasswordForm',
    validate
  })(withRouter(connect(null, mapDispatchToProps)(ChangePassword)))
);
