import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter, Redirect } from 'react-router-dom';
import _ from 'lodash';

import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import IconButton from '@tine/lib-frontend-components/components/buttons/icon-button';
import { TrashcanIcon } from '@tine/lib-frontend-components/elements/icons';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ConfirmDialog from '@tine/lib-frontend-components/components/dialogs/confirm-dialog';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';

import Header from 'mui-components/header';
import TextBoxControl from 'components/forms/TextBoxControl';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import Loader from 'components/Loader';
import SelectControl from 'components/forms/SelectBoxControl';
import * as ManureAction from 'actions/ManureStorage';
import { CheckInteger, DecimalValidation } from 'utils/index';
import { REDIRECT, OWNER, ADMIN, ADVISOR } from 'constants/index';

class ManureStorageForm extends React.Component {
  state = {
    inEditMode: false,
    showDeleteWarning: false,
    showChangeWarning: false,
    acceptChanges: false,
    isTypeChange: false,
    dialogValue: null,
    showDeleteErrorMessage: false
  };

  componentDidMount = () => {
    const { fetchManureType, manureStorage } = this.props;
    const { manureType } = manureStorage;
    if (!manureType?.length > 0) {
      fetchManureType();
    }
    this.getInitialize();
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, manureStorage } = this.props;
    const { manureList } = manureStorage;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storage/manure-storage/');
    }
    if (manureList !== prevProps.manureStorage.manureList) {
      this.getInitialize();
    }
  }

  getInitialize = () => {
    const { match, manureStorage, initialize, history } = this.props;
    const { manureList } = manureStorage;
    const manureId = (manureStorage.manureNewId && manureStorage.manureNewId !== null) ? manureStorage.manureNewId : match.params.id;

    if (manureId !== 'create') {
      const manureItem = _.filter(manureList, ['id', manureId])[0];
      if (manureItem) {
        const formValues = {
          id: manureItem.id,
          name: manureItem.name,
          type: this.getType(manureItem.type),
          yearlyProduction: manureItem.yearlyProduction,
          storageCapacity: manureItem.storageCapacity,
          dryMatter: manureItem.dryMatter,
          nitrogen: manureItem.nitrogen,
          ammonium: manureItem.ammonium,
          phosphorus: manureItem.phosphorus,
          potassium: manureItem.potassium,
          sulfur: manureItem.sulfur
        };
        initialize(formValues);
      } else {
        history.push('/storage/manure-storage/');
      }
    }
  };

  getType = manureValue => {
    const {
      manureStorage: { manureType }
    } = this.props;
    if (manureValue) {
      const typeName = _.filter(manureType, ['id', manureValue])[0];
      return { value: typeName.id, label: typeName.type };
    }
    return '';
  };

  handleSubmit = values => {
    const { activeFarm, createManure, match, updateManure, manureStorage } = this.props;
    const manureId = (manureStorage.manureNewId && manureStorage.manureNewId !== null) ? manureStorage.manureNewId : match.params.id;
    const manureValues = { ...values };
    manureValues.type = values.type?.value;
    if(values.nitrogen) {
      manureValues.nitrogen = parseFloat(values.nitrogen.toString().replace(',', '.'));
    }
    if(values.ammonium) {
      manureValues.ammonium = parseFloat(values.ammonium.toString().replace(',', '.'));
    }
    if(values.phosphorus) {
      manureValues.phosphorus = parseFloat(values.phosphorus.toString().replace(',', '.'));
    }
    if(values.potassium) {
      manureValues.potassium = parseFloat(values.potassium.toString().replace(',', '.'));
    }
    if(values.sulfur) {
      manureValues.sulfur = parseFloat(values.sulfur.toString().replace(',', '.'));
    }
    manureValues.id = manureId;
    if (manureId !== 'create') {
        updateManure(activeFarm.farm.id, manureValues, REDIRECT);
    } else {
      createManure(activeFarm.farm.id, manureValues);
    }
  };

  // to delete Manure
  deleteAction = action => {
    const { activeFarm, match, deleteManureList, manureStorage } = this.props;
    if (action) {
      const manureId = (manureStorage.manureNewId && manureStorage.manureNewId !== null) ? manureStorage.manureNewId : match.params.id;
      deleteManureList(activeFarm.farm.id, manureId);
      this.setState(prevState => ({
        showDeleteWarning: !prevState.showDeleteWarning
      }));
      this.setState({ showDeleteErrorMessage: true });
    } else {
      this.setState(prevState => ({
        showDeleteWarning: !prevState.showDeleteWarning
      }));
    }
  };

  getManureOption = manureType => {
    if (manureType?.length > 0) {
      return manureType.map(item => {
        return { label: item.type, value: item.id };
      });
    }
    return [];
  };

  onDryMatterChange = _.debounce(e =>this.onTypeDryMatterChange(e), 1500);

  onTypeDryMatterChange = e => {
    const { match } = this.props;
    const manureId = match.params.id;
    if (manureId !== 'create') {
      let targetValue = null;
      if (e.value) {
        this.setState({
          isTypeChange: true,
          dialogValue: 'type'
        });
          const timeOut = setTimeout(() => this.setState({
            showChangeWarning: true
          }), 1000);
          return () => clearTimeout(timeOut);
      }
      if (e.target?.name === 'dryMatter') {
        targetValue = parseFloat(e.target.value.toString().replace(',', '.'));
      }
      if (e.target?.name === 'dryMatter' && targetValue > 0 && targetValue < 101) {
        this.setState({
          isTypeChange: false,
          dialogValue: 'drymatter'
        });
          const timeOut = setTimeout(() => this.setState({
            showChangeWarning: true
          }), 1500);
          return () => clearTimeout(timeOut);
      }
    }
  };

  updateOnChange = changedValue => {
    const { activeFarm, manureStoregeForm, updateManure } = this.props;
    const manureValues = { ...manureStoregeForm.values };
    if (changedValue?.value) {
      manureValues.type = changedValue.value;
    } else if (changedValue) {
      changedValue = changedValue.toString().replace(',', '.');
      manureValues.type = manureStoregeForm.values.type?.value;
      manureValues.dryMatter = changedValue;
    } else {
      manureValues.type = manureStoregeForm.values.type?.value;
      manureValues.dryMatter = manureStoregeForm.values.dryMatter?parseFloat(manureStoregeForm.values.dryMatter.toString().replace(',', '.')):'';
    }
    const timeOut = setTimeout(() => updateManure(activeFarm.farm.id, manureValues), 1500);
    return () => clearTimeout(timeOut);
  };

  //  action from confirmation
  warningAction = action => {
    const { manureStoregeForm, change } = this.props;
    const { isTypeChange } = this.state;

    if (action) {
      this.setState(prevState => ({
        showChangeWarning: !prevState.showChangeWarning,
        acceptChanges: false
      }));
      this.updateOnChange();
    } else {
      if (isTypeChange) {
        change('type', manureStoregeForm.initial.type);
      } else {
        change('dryMatter', manureStoregeForm.initial.dryMatter);
      }
      this.setState(prevState => ({
        showChangeWarning: !prevState.showChangeWarning,
        acceptChanges: false
      }));
    }
  };

  // go to manure page
  goToManurePage = () => {
    const { history } = this.props;
    history.push('/storage/manure-storage/');
  }

  render() {
    const {
      handleSubmit,
      pageContent: { labels },
      match,
      intl,
      manureStorage,
      manureStoregeForm,
      activeFarm,
      hasSupportAdminRole
    } = this.props;
    const { inEditMode, showDeleteWarning, showChangeWarning, dialogValue, showDeleteErrorMessage } = this.state;
    const manureId = match.params.id;
    const isCreate = manureId === 'create';
    const { isLoading, manureType, submitSuccess, hasError, errorMsg, errorPattern } = manureStorage;
    const formValue = manureStoregeForm !== undefined ? manureStoregeForm.values : null;
    const customizedDelString = `${intl.formatMessage({ id: 'ASSET_DELETE_TEXT_MSG' })}`;
    const hasPermission = activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    return (
      <div className="manure-storage-form">
        <Header
          bcLink={{ to: '/storage/manure-storage', text: intl.formatMessage({ id: 'STORAGE' }) }}
          bcTitle={
            isCreate ? intl.formatMessage({ id: 'create' }) : manureStoregeForm?.values?.name
          }
        />
        <SectionContainer
          styling={{
            paddingTop: 'low',
            paddingBottom: 'lowest'
          }}
        >
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className={`manure-form__name ${isCreate ? 'create' : ''}`}>
              {!isCreate && (
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ showDeleteWarning: true });
                  }}
                >
                  <span className="btn-icon__text">{labels.delete}</span>
                  <TrashcanIcon />
                </IconButton>
              )}
              <Field
                name="name"
                label={labels.name}
                component={TextBoxControl}
                controlId="name"
                type="text"
                stlyeClass="manure-storage__name"
                placeholder="Name of the manure storage"
                disabled={isLoading}
              />
            </div>
            <SectionContainer
              styling={{
                paddingLeft: 'none',
                paddingRight: 'none',
                paddingTop: 'low',
                paddingBottom: 'lowest'
              }}
            >
              <div className="manure-storage__fields">
                <Field
                  name="type"
                  controlId="type"
                  label={labels.manureType}
                  styleName="select-box-common"
                  component={SelectControl}
                  options={this.getManureOption(manureType)}
                  disabled={isLoading || !hasPermission}
                  defaultValue={formValue?.type ? formValue.type : null}
                  onChange={e => this.onTypeDryMatterChange(e)}
                  placeholder={labels.manureType}
                />
                <Field
                  name="yearlyProduction"
                  label={intl.formatMessage({ id: 'MANURE_STORAGE_YEARLY_PRODUCTION' })}
                  component={TextBoxControl}
                  controlId="yearlyProduction"
                  type="text"
                  stlyeClass="manure-storage__name"
                  units={`${labels.storageUnit}`}
                  disabled={isLoading}
                />
                <Field
                  name="storageCapacity"
                  label={`${labels.storageCapacity}:`}
                  component={TextBoxControl}
                  controlId="storageCapacity"
                  type="text"
                  stlyeClass="manure-storage__name"
                  units={`${labels.storageUnit}`}
                  disabled={isLoading}
                />
                <Field
                  name="dryMatter"
                  label={`${labels.dryMatter}:`}
                  component={TextBoxControl}
                  controlId="dryMatter"
                  type="text"
                  stlyeClass="manure-storage__name"
                  units={labels.dryMatterUnit}
                  disabled={isLoading || !hasPermission}
                  onChange={event => this.onDryMatterChange(event)}
                />
              </div>
            </SectionContainer>
            {!isCreate && (
              <div>
                <div className="divider" />
                <SectionContainer
                  styling={{
                    paddingLeft: 'none',
                    paddingRight: 'none',
                    paddingTop: 'low',
                    paddingBottom: 'lowest'
                  }}
                >
                  <div className="manure-storage-calc__heading">
                    <div>
                      <p>
                        <EmphasisLight size="small">{labels.statement1}</EmphasisLight>
                      </p>
                      <p>
                        <MetaInformation size="small">{labels.statement2}</MetaInformation>
                      </p>
                    </div>
                    <ActionButton
                      tertiary
                      onClick={e => {
                        e.preventDefault();
                        this.setState(prevState => ({ inEditMode: !prevState.inEditMode }));
                      }}
                    >
                      {inEditMode ? intl.formatMessage({ id: 'DONE' }) : labels.edit}

                      <i className={inEditMode ? 'icon-close' : 'icon-edit'} />
                    </ActionButton>
                  </div>
                  {!inEditMode && (
                    <div className="manure-storage-calc__data">
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.nitrogen}: </MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.nitrogen} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.ammmonium}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.ammonium} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.phosphorus}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.phosphorus} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.potassium}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.potassium} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.sulphur}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.sulfur} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                    </div>
                  )}
                  {inEditMode && (
                    <div className="manure-storage-calc__data --edit">
                      <Field
                        name="nitrogen"
                        label={labels.nitrogen}
                        component={TextBoxControl}
                        controlId="nitrogen"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="ammonium"
                        label={labels.ammmonium}
                        component={TextBoxControl}
                        controlId="ammonium"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="phosphorus"
                        label={labels.phosphorus}
                        component={TextBoxControl}
                        controlId="phosphorus"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="potassium"
                        label={labels.potassium}
                        component={TextBoxControl}
                        controlId="potassium"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="sulfur"
                        label={labels.sulphur}
                        component={TextBoxControl}
                        controlId="sulfur"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                    </div>
                  )}
                </SectionContainer>
              </div>
            )}
            <div className="manure-error">{hasError && errorPattern !== 'delete' && <ErrorText>{errorMsg.data}</ErrorText>}</div>
            <div className="manure-storage__submit">
              <ActionButton
                disabled={!hasPermission}
                onClick={() => handleSubmit(this.handleSubmit)}
              >
                {labels.save}
              </ActionButton>
            </div>
          </form>
          {isLoading && <Loader styleName="fixed-loader" />}
          {submitSuccess && <Redirect to="/storage/manure-storage/" />}
        </SectionContainer>
        {showDeleteWarning && (
          <ConfirmDialogBox
            heading={intl.formatMessage({ id: 'DELETE_CONFIRM_TITLE' })}
            body={customizedDelString}
            confirmText="DELETE"
            cancelText="cancel"
            callback={e => this.deleteAction(e)}
            warning
          />
        )}
        {showChangeWarning && (
          <div className="confirm-box-stye manure-change-warning">
            <ConfirmDialog
              title={dialogValue === 'type' ? labels.warningMessageHeaderMT : labels.warningMessageHeader}
              message={dialogValue === 'type' ? labels.warningMessageBodyMT : labels.warningMessageBody}
              confirmText={dialogValue === 'type' ? labels.buttonYesMT : labels.buttonYes}
              onConfirm={() => this.warningAction(true)}
              cancelText={dialogValue === 'type' ? labels.buttonNoMT : labels.buttonNo}
              onCancel={() => this.warningAction(false)}
              warning={false}
            />
          </div>
        )}
        {showDeleteErrorMessage && hasError && (
          <div className="confirm-box-stye manure-change-warning">
            <ConfirmDialog
              title=''
              message={errorMsg.data}
              confirmText={intl.formatMessage({ id: 'OK' })}
              onConfirm={() => this.goToManurePage()}
              warning={true}
            />
          </div>
        )}
      </div>
    );
  }
}
const validate = (values, props) => {
  const errors = {};
  const { intl } = props;
  if (!values.name || !values.name.trim()) {
    errors.name = intl.formatMessage({ id: 'AFN_RQ' });
  }
  if (!values.type) {
    errors.type = intl.formatMessage({ id: 'required' });
  }

  if (values.storageCapacity && !CheckInteger(values.storageCapacity)) {
    errors.storageCapacity = intl.formatMessage({ id: 'invalid' });
  }

  if (!values.dryMatter) {
    errors.dryMatter = intl.formatMessage({ id: 'required' });
  }

  if (values.dryMatter) {
    if (values.dryMatter > 100) {
      errors.dryMatter = intl.formatMessage({ id: 'invalid' });
    }
    if (!CheckInteger(values.dryMatter)) {
      errors.dryMatter = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.nitrogen) {
    const result = DecimalValidation(values.nitrogen);
    if (!result.valid) {
      errors.nitrogen = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.ammonium) {
    const result = DecimalValidation(values.ammonium);
    if (!result.valid) {
      errors.ammonium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.phosphorus) {
    const result = DecimalValidation(values.phosphorus);
    if (!result.valid) {
      errors.phosphorus = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.potassium) {
    const result = DecimalValidation(values.potassium);
    if (!result.valid) {
      errors.potassium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.sulfur) {
    const result = DecimalValidation(values.sulfur);
    if (!result.valid) {
      errors.sulfur = intl.formatMessage({ id: 'invalid' });
    }
  }

  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  manureStoregeForm: state.form.ManureStorageForm,
  manureStorage: state.manureStorage,
  lblCommon: state.masterData.data[0]?.commonLabels,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'manureStoragePage'])[0],
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  fetchManureType: () => dispatch(ManureAction.fetchManureType()),
  createManure: (farmId, values) => dispatch(ManureAction.createManure(farmId, values)),
  deleteManureList: (farmId, id) => dispatch(ManureAction.deleteManureList(farmId, id)),
  updateManure: (farmId, values, redirect) =>
    dispatch(ManureAction.UpdateManure(farmId, values, redirect))
});

export default injectIntl(
  reduxForm({
    form: 'ManureStorageForm',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ManureStorageForm)))
);
