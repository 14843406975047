import CommonTranslation from 'translation/nn/nn.json';
import PesticideAgreement from 'translation/nn/PesticideAgreement.json';
import Stats from 'translation/nn/stats';
import Feedback from 'translation/nn/feedback';
import CreateFieldHelp from 'translation/nn/createFieldHelp.json';

export default {
  ...CommonTranslation,
  ...PesticideAgreement,
  ...Stats,
  ...Feedback,
  ...CreateFieldHelp
};
