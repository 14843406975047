import { 
  TASK_BATCH_LOADING, 
  TASK_BATCH_SUCCESS, 
  TASK_BATCH_FAILURE
} from 'constants/store/Tasks';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  taskList: null
};

export default function taskBatch(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case TASK_BATCH_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case TASK_BATCH_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case TASK_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        taskList: action.payload
      };
    default:
      return state;
  }
}
