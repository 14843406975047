import React from 'react';

const BaleStorageColumnCard = ({
    storage,
    index,
    storageLength,
    intl,
    hasBottom,
    onSelectedBale,
    paramId,
    highlightStorage
  }) => (
  <li className={`storage-list-item full-width`} 
    key={index+1}>
    <div
      className={`storage-list-item-link ${index === storageLength-1 ? hasBottom ? '' : 'border-bottom-style' : ''} 
      ${highlightStorage === storage.id ? 'set-background-color' : ''}`}
      onClick={() => onSelectedBale(storage.id, paramId)}
    >
      <div className="storage-details">
        <div className="storage-name large-width">
          <span title="slatt">
            <span className=''>
              <img src={`./images/bale_storage.png`} className="bale-icon-size" alt="draw" />
            </span>
            <span className='medium-font-text vertical-align-super number-padding-left'>{storage.name}</span>
          </span>
        </div>
        <div className="storage-number">
          <span className="label-number">
            {storage.quantity}
          </span>
        </div>
        <div className="storage-arrow">
          <span className="icon-arrow-up-accordion add-storage-arrow-label" />
        </div>
      </div>
    </div>
  </li>
);

export default BaleStorageColumnCard;
