import React from 'react';
import Calendar from '@tine/lib-frontend-components/components/calendar/calendar';
import { GetDateObj, AppendZeroToDayAndMonth } from 'utils';
import { FormattedMessage } from 'react-intl';
import { ENGLISH, APPROVEDFROM, APPROVEDTO } from 'constants/index';

class CustomCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: '',
      min: ''
    };
  }

  componentDidMount() {
    const { startTime, endTime, action } = this.props;
    if (action === 'Start') {
      this.setState({ hour: startTime.substr(0, 2), min: startTime.substr(3, 2) });
    } else if (action === 'End') {
      this.setState({ hour: endTime.substr(0, 2), min: endTime.substr(3, 2) });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /* setting up a reference for the target object */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /* change handler for mins and hours inputs */
  handleChange = event => {
    const isValid = this.validator(
      event.currentTarget.getAttribute('custom_id'),
      event.target.value
    );
    if (isValid) {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  /** To validate the mins and hours entered
   * @param {string, string} type, valueEntered
   * @returns {boolean}
   *
   */
  validator = (type, valueEntered) => {
    if (valueEntered.length > 0 && valueEntered.length <= 2) {
      if (type === 'hours') {
        return valueEntered.match(/^([0-1]?[0-9]?|[2][0-3]?)$/g);
      }
      if (type === 'minutes') {
        return valueEntered.match(/^([0-5]?[0-9]|59|null)$/g);
      }
    } else if (valueEntered === '') {
      return true;
    } else {
      return false;
    }
    return true;
  };

  /* change handler for selecting a date on calendar */
  changeDate = value => {
    const chosenDate = value;
    const { action } = this.props;
    if (action === 'Start') {
      this.setState({
        sDate: [
          AppendZeroToDayAndMonth(chosenDate.getDate()),
          AppendZeroToDayAndMonth(chosenDate.getMonth() + 1),
          chosenDate.getFullYear()
        ].join('.')
      });
    } else if (action === 'End') {
      this.setState({
        eDate: [
          AppendZeroToDayAndMonth(chosenDate.getDate()),
          AppendZeroToDayAndMonth(chosenDate.getMonth() + 1),
          chosenDate.getFullYear()
        ].join('.')
      });
    } else if (action === APPROVEDFROM) {
      this.setState({
        approvedFromDate: [
          AppendZeroToDayAndMonth(chosenDate.getDate()),
          AppendZeroToDayAndMonth(chosenDate.getMonth() + 1),
          chosenDate.getFullYear()
        ].join('.')
      });
    } else if (action === APPROVEDTO) {
      this.setState({
        approvedToDate: [
          AppendZeroToDayAndMonth(chosenDate.getDate()),
          AppendZeroToDayAndMonth(chosenDate.getMonth() + 1),
          chosenDate.getFullYear()
        ].join('.')
      });
    } else {
      this.setState({
        chosenHarvestDate: [
          AppendZeroToDayAndMonth(chosenDate.getDate()),
          AppendZeroToDayAndMonth(chosenDate.getMonth() + 1),
          chosenDate.getFullYear()
        ].join('/')
      });
    }
  };

  /* To handle calendar box, when clicked outside */
  handleClickOutside = event => {
    const { toggleCalendar, sendDetails, harvestDate, approvedDates, action } = this.props;
    const {
      sDate,
      eDate,
      hour,
      min,
      chosenHarvestDate,
      approvedFromDate,
      approvedToDate
    } = this.state;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      toggleCalendar();
      if (harvestDate && chosenHarvestDate) {
        sendDetails(chosenHarvestDate);
      } else if (approvedDates && action === APPROVEDFROM) {
        sendDetails(approvedFromDate, action);
      } else if (approvedDates && action === APPROVEDTO) {
        sendDetails(approvedToDate, action);
      } else {
        sendDetails(sDate, eDate, hour || '00', min || '00');
      }
    }
  };

  /** To fetch and show date entered
   * @param {}
   * @returns {string}
   *
   */
  fetchDate = () => {
    const { startDate, endDate, action } = this.props;
    const { sDate, eDate } = this.state;
    if (sDate && sDate !== startDate) {
      return sDate;
    }
    if (eDate && eDate !== endDate) {
      return eDate;
    }
    if (action === 'Start' && startDate) {
      return startDate;
    }
    if (action === 'End' && endDate) {
      return endDate;
    }
    return '00.00.0000';
  };

  /** To fetch and show time entered
   * @param {string} event
   * @returns {string}
   *
   */
  fetchTime = event => {
    const { startTime, endTime, action } = this.props;
    const { hour, min } = this.state;
    if (action === 'Start' && event === 'hours') {
      if (hour) {
        return hour === '00' ? '' : hour;
      }
      if (hour === '') {
        return '';
      }
      if (startTime) {
        return startTime.substr(0, 2);
      }
    }
    if (action === 'Start' && event === 'minutes') {
      if (min) {
        return min === '00' ? '' : min;
      }
      if (min === '') {
        return '';
      }
      if (startTime) {
        return startTime.substr(3, 2);
      }
    }
    if (action === 'End' && event === 'hours') {
      if (hour) {
        return hour === '00' ? '' : hour;
      }
      if (hour === '') {
        return '';
      }
      if (endTime) {
        return endTime.substr(0, 2);
      }
    }
    if (action === 'End' && event === 'minutes') {
      if (min) {
        return min === '00' ? '' : min;
      }
      if (min === '') {
        return '';
      }
      if (endTime) {
        return endTime.substr(3, 2);
      }
    }
    return '00';
  };

  /* To populate date in calendar */
  getValue = () => {
    const {
      action,
      startDate,
      endDate,
      harvestDate,
      harvestDateSelected,
      approvedDateFrom,
      approvedDateTo,
      approvedDates
    } = this.props;
    let day;
    let month;
    let year;
    if (action === 'Start' && startDate) {
      [day, month, year] = startDate.split('.');
    } else if (action === 'End' && endDate) {
      [day, month, year] = endDate.split('.');
    } else if (harvestDate && harvestDateSelected) {
      [day, month, year] = harvestDateSelected.split('/');
    } else if (approvedDates && approvedDateFrom && action === APPROVEDFROM) {
      [day, month, year] = approvedDateFrom.split('.');
    } else if (approvedDates && approvedDateTo && action === APPROVEDTO) {
      [day, month, year] = approvedDateTo.split('.');
    } else {
      return new Date();
    }
    return new Date(year, month - 1, day);
  };

  /* validation for  minDate in calendar */
  minDate = () => {
    const { action, startDate, endDate, approvedDateTo, approvedDateFrom } = this.props;
    if (action === 'Start' || action === APPROVEDFROM) {
      if (endDate || approvedDateTo) {
        return new Date(1970, 0, 1);
      }
    }
    if (action === 'End' || action === APPROVEDTO) {
      if (startDate || approvedDateFrom) {
        const date = GetDateObj(startDate || approvedDateFrom);
        date.setDate(date.getDate());
        return date;
      }
    }
    return null;
  };

  /* validation for  maxDate in calendar */
  maxDate = () => {
    const { action, endDate, approvedDateTo } = this.props;
    if (action === 'Start' || action === APPROVEDFROM) {
      if (endDate || approvedDateTo) {
        const date = GetDateObj(endDate || approvedDateTo);
        date.setDate(date.getDate());
        return date;
      }
    }
    if (action === APPROVEDTO || action === APPROVEDFROM) {
      return null;
    }
    return new Date();
  };

  render() {
    const { action, harvestDate, lang, approvedDates } = this.props;
    return (
      <div className="calendar-container" ref={this.setWrapperRef}>
        <div className="calendar-input">
          <Calendar
            locale={lang === ENGLISH ? 'en-US' : 'nb-NO'}
            onChange={value => this.changeDate(value)}
            value={this.getValue()}
            maxDate={harvestDate ? null : this.maxDate()}
            minDate={harvestDate ? null : this.minDate()}
          />
        </div>
        {!harvestDate && !approvedDates && (
          <div className="light-box">
            <span>{this.fetchDate()}</span>
            <div className="horizantalBar" />
            <span>
              <FormattedMessage id={`${action === 'Start' ? 'STARTTIME' : 'ENDTIME'}`} />:
            </span>
            <div className="inputBox">
              <input
                type="text"
                className="inputText"
                name="hour"
                value={this.fetchTime('hours')}
                custom_id="hours"
                placeholder="00"
                onChange={this.handleChange}
              />
              <p className="dateColon">:</p>
              <input
                type="text"
                className="inputText"
                name="min"
                value={this.fetchTime('minutes')}
                custom_id="minutes"
                placeholder="00"
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CustomCalendar;
