import {
  ACT1,
  ACT5,
  ACT7,
  ACT9,
  ACT10,
  ACT11,
  ACT12,
  ACT13,
  ACT14,
  ACT15,
  ACT16,
  ACT17,
  ACT18,
  ACT19,
  ACT20,
  ACT28,
  PESTICIDE_MINERAL_FERTILIZER_EN,
  PESTICIDE_MINERAL_FERTILIZER_NB,
  PESTICIDE_FOILAR_FERTILIZER_EN,
  PESTICIDE_FOILAR_FERTILIZER_NB,
  PESTICIDE_LIMING_EN,
  PESTICIDE_LIMING_NB,
  PESTICIDE_MANURE_EN,
  PESTICIDE_MANURE_NB,
  MANURE_COW_EN,
  MANURE_DIARY_COW_EN,
  MANURE_SUCKLING_COW_EN,
  MANURE_OTHER_EN,
  MANURE_PIG_EN,
  MANURE_POULTRY_EN,
  MANURE_SHEEP_EN,
  MANURE_COW_NN,
  MANURE_DIARY_COW_NN,
  MANURE_SUCKLING_COW_NN,
  MANURE_OTHER_NN,
  MANURE_PIG_NN,
  MANURE_POULTRY_NN,
  MANURE_SHEEP_NN,
  MANURE_COW_NB,
  MANURE_DIARY_COW_NB,
  MANURE_SUCKLING_COW_NB,
  MANURE_OTHER_NB,
  MANURE_PIG_NB,
  MANURE_POULTRY_NB,
  MANURE_SHEEP_NB
} from 'constants/index';
import _ from 'lodash';

export const  displayLocations = (locations) => {
  let locationsArray = [];
  locationsArray.push({ label: '', value: '' })
  locations.map(location => (
    locationsArray.push({ label: location.name, value: location.id })
  ))  
  return locationsArray;
}

export const sowingUnitOptions = (activityCommon) => {
  let sowingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT1])[0].dropdowns[0].leaves.map(units => (
    sowingUnitOptionsArray.push({ label: units, value: units })
  ))
  return sowingUnitOptionsArray;
}

export const act1SowingUnitOptions = (activityCommon) => {
  let act1SowingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT1])[0].dropdowns[0].leaves.map(units => (
    act1SowingUnitOptionsArray.push({ label: units, value: units })
  ))
  return act1SowingUnitOptionsArray;
}

export const act20SowingUnitOptions = (activityCommon) => {
  let act20SowingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT20])[0].dropdowns[2].leaves.map(units => (
    act20SowingUnitOptionsArray.push({ label: units, value: units })
  ))
  return act20SowingUnitOptionsArray;
}

export const balePressUnitOptions = (activityCommon) => {
  let balePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[1].leaves.map(units => (
    balePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return balePressUnitOptionsArray;
}

export const act9BalePressUnitOptions = (activityCommon) => {
  let act9BalePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[1].leaves.map(units => (
    act9BalePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return act9BalePressUnitOptionsArray;
}

export const act10BalePressUnitOptions = (activityCommon) => {
  let act10BalePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT10])[0].dropdowns[1].leaves.map(units => (
    act10BalePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return act10BalePressUnitOptionsArray;
}

export const act11BalePressUnitOptions = (activityCommon) => {
  let act11BalePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT11])[0].dropdowns[1].leaves.map(units => (
    act11BalePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return act11BalePressUnitOptionsArray;
}

export const act12BalePressUnitOptions = (activityCommon) => {
  let act12BalePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT12])[0].dropdowns[1].leaves.map(units => (
    act12BalePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return act12BalePressUnitOptionsArray;
}

export const act28BalePressUnitOptions = (activityCommon) => {
  let act28BalePressUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT28])[0].dropdowns[1].leaves.map(units => (
    act28BalePressUnitOptionsArray.push({ label: units, value: units })
  ))
  return act28BalePressUnitOptionsArray;
}

export const balePressEnsilationOptions = (activityCommon) => {
  let balePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[2].leaves.map(units => (
    balePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return balePressEnsilationOptionsArray;
}

export const act9BalePressEnsilationOptions = (activityCommon) => {
  let act9BalePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[2].leaves.map(units => (
    act9BalePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return act9BalePressEnsilationOptionsArray;
}

export const act10BalePressEnsilationOptions = (activityCommon) => {
  let act10BalePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT10])[0].dropdowns[2].leaves.map(units => (
    act10BalePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return act10BalePressEnsilationOptionsArray;
}

export const act11BalePressEnsilationOptions = (activityCommon) => {
  let act11BalePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT11])[0].dropdowns[2].leaves.map(units => (
    act11BalePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return act11BalePressEnsilationOptionsArray;
}

export const act12BalePressEnsilationOptions = (activityCommon) => {
  let act12BalePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT12])[0].dropdowns[2].leaves.map(units => (
    act12BalePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return act12BalePressEnsilationOptionsArray;
}

export const act28BalePressEnsilationOptions = (activityCommon) => {
  let act28BalePressEnsilationOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT28])[0].dropdowns[2].leaves.map(units => (
    act28BalePressEnsilationOptionsArray.push({ label: units, value: units })
  ))
  return act28BalePressEnsilationOptionsArray;
}

// Fertilization dropdowns
export const fertilizerTypeOptions = (activityCommon) => {
  let fertilizerTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map((options, index) => (
    fertilizerTypeOptionsArray.push({ label: options.element, value: (options.element === PESTICIDE_LIMING_EN || options.element === PESTICIDE_LIMING_NB) ? PESTICIDE_LIMING_EN :
      (options.element === PESTICIDE_FOILAR_FERTILIZER_EN || options.element === PESTICIDE_FOILAR_FERTILIZER_NB) ? PESTICIDE_FOILAR_FERTILIZER_EN :
      (options.element === PESTICIDE_MINERAL_FERTILIZER_EN || options.element === PESTICIDE_MINERAL_FERTILIZER_NB) ? PESTICIDE_MINERAL_FERTILIZER_EN :
      (options.element === PESTICIDE_MANURE_EN || options.element === PESTICIDE_MANURE_NB) ? PESTICIDE_MANURE_EN : options.element
   })
  ))
  return fertilizerTypeOptionsArray;
}

// Manure dropdowns
export const manureOptions = (activityCommon) => {
  let manureOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_MANURE_EN || elements.element === PESTICIDE_MANURE_NB) &&
    elements.leaves.map(options => (
      manureOptionsArray.push({ label: options, value: (options === MANURE_COW_EN || options === MANURE_COW_NN || options === MANURE_COW_NB) ? MANURE_COW_EN :
        (options === MANURE_DIARY_COW_EN || options === MANURE_DIARY_COW_NN || options === MANURE_DIARY_COW_NB) ? MANURE_DIARY_COW_EN :
        (options === MANURE_SUCKLING_COW_EN || options === MANURE_SUCKLING_COW_NN || options === MANURE_SUCKLING_COW_NB) ? MANURE_SUCKLING_COW_EN :
        (options === MANURE_OTHER_EN || options === MANURE_OTHER_NN || options === MANURE_OTHER_NB) ? MANURE_OTHER_EN :
        (options === MANURE_PIG_EN || options === MANURE_PIG_NN || options === MANURE_PIG_NB) ? MANURE_PIG_EN :
        (options === MANURE_POULTRY_EN || options === MANURE_POULTRY_NN || options === MANURE_POULTRY_NB) ? MANURE_POULTRY_EN :
        (options === MANURE_SHEEP_EN || options === MANURE_SHEEP_NN || options === MANURE_SHEEP_NB) ? MANURE_SHEEP_EN : 
        options
     })
    ))
  ))
  return manureOptionsArray;
}

export const fertilizerSubTypeLimingOptions = (activityCommon) => {
  let fertilizerSubTypeLimingOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB) &&
    elements.leaves.map(options => (
      fertilizerSubTypeLimingOptionsArray.push({ label: options, value: options })
    ))
  ))
  return fertilizerSubTypeLimingOptionsArray;
}

export const fertilizerSubTypeFoilerDataOptions = (activityCommon) => {
  let fertilizerSubTypeFoilerDataOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_FOILAR_FERTILIZER_EN || elements.element === PESTICIDE_FOILAR_FERTILIZER_NB) &&
    elements.leaves.map(options => (
      fertilizerSubTypeFoilerDataOptionsArray.push({ label: options, value: options })
    ))
  ))
  return fertilizerSubTypeFoilerDataOptionsArray;
}

export const fertilizerSubTypeManourOptions = (activityCommon) => {
  let fertilizerSubTypeManourOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements[0].leaves.map(options => (
    fertilizerSubTypeManourOptionsArray.push({ label: options, value: options })
  ))
  return fertilizerSubTypeManourOptionsArray;
}

export const mineralFertilizerOptions = (activityCommon) => {
  let mineralFertilizerOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
    elements.leaves.map(options => (
      mineralFertilizerOptionsArray.push({ label: options, value: options })
    ))
  ))
  return mineralFertilizerOptionsArray;
}

export const limingUnitOptions = (activityCommon) => {
  let limingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB) &&
    elements.units.plannedAmountUnit.map(options => (
      limingUnitOptionsArray.push({ label: options, value: options })
    ))
  ))
  return limingUnitOptionsArray;
}

export const mineralFertilizerUnitOptions = (activityCommon) => {
  let mineralFertilizerUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
    elements.units.plannedAmountUnit.map(options => (
      mineralFertilizerUnitOptionsArray.push({ label: options, value: options })
    ))
  ))
  return mineralFertilizerUnitOptionsArray;
}

export const mineralFertilizerLimingUnitOptions = (activityCommon) => {
  let mineralFertilizerLimingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB || 
      elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
    elements.units.plannedAmountUnit.map(options => (
      mineralFertilizerLimingUnitOptionsArray.push({ label: options, value: options })
    ))
  ))
  return mineralFertilizerLimingUnitOptionsArray;
}

export const act20MineralFertilizerLimingUnitOptions = (activityCommon) => {
  let act20MineralFertilizerLimingUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT20])[0].dropdowns[1].leaves.map(options => (
    act20MineralFertilizerLimingUnitOptionsArray.push({ label: options, value: options })
  ))
  return act20MineralFertilizerLimingUnitOptionsArray;
}

export const manourUnitOptions = (activityCommon) => {
  let manourUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_MANURE_EN || elements.element === PESTICIDE_MANURE_NB) &&
    elements.units.plannedAmountUnit.map(options => (
      manourUnitOptionsArray.push({ label: options, value: options })
    ))
  ))
  return manourUnitOptionsArray;
}

export const foilerFertilizerUnitOptions = (activityCommon) => {
  let foilerFertilizerUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
    (elements.element === PESTICIDE_FOILAR_FERTILIZER_EN || elements.element === PESTICIDE_FOILAR_FERTILIZER_NB) &&
    elements.units.plannedAmountUnit.map(options => (
      foilerFertilizerUnitOptionsArray.push({ label: options, value: options })
    ))
  ))
  return foilerFertilizerUnitOptionsArray;
}

export const act18CutAmountOptions = (activityCommon) => {
  let act18CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT18])[0].dropdowns[0].leaves.map(options => (
    act18CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act18CutAmountOptionsArray;
}

export const act17CutAmountOptions = (activityCommon) => {
  let act17CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT17])[0].dropdowns[0].leaves.map(options => (
    act17CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act17CutAmountOptionsArray;
}

export const act16CutAmountOptions = (activityCommon) => {
  let act16CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT16])[0].dropdowns[0].leaves.map(options => (
    act16CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act16CutAmountOptionsArray;
}

export const act15CutAmountOptions = (activityCommon) => {
  let act15CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT15])[0].dropdowns[0].leaves.map(options => (
    act15CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act15CutAmountOptionsArray;
}

export const act14CutAmountOptions = (activityCommon) => {
  let act14CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT14])[0].dropdowns[0].leaves.map(options => (
    act14CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act14CutAmountOptionsArray;
}

export const act5CutAmountOptions = (activityCommon) => {
  let act5CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT5])[0].dropdowns[0].leaves.map(options => (
    act5CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act5CutAmountOptionsArray;
}

export const act9CutAmountOptions = (activityCommon) => {
  let act9CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT9])[0].dropdowns[0].leaves.map(options => (
    act9CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act9CutAmountOptionsArray;
}

export const act10CutAmountOptions = (activityCommon) => {
  let act10CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT10])[0].dropdowns[0].leaves.map(options => (
    act10CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act10CutAmountOptionsArray;
}

export const act11CutAmountOptions = (activityCommon) => {
  let act11CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT11])[0].dropdowns[0].leaves.map(options => (
    act11CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act11CutAmountOptionsArray;
}

export const act12CutAmountOptions = (activityCommon) => {
  let act12CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT12])[0].dropdowns[0].leaves.map(options => (
    act12CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act12CutAmountOptionsArray;
}

export const act28CutAmountOptions = (activityCommon) => {
  let act28CutAmountOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT28])[0].dropdowns[0].leaves.map(options => (
    act28CutAmountOptionsArray.push({ label: options, value: options })
  ))
  return act28CutAmountOptionsArray;
}

// Pesitcide application
export const pesticideTypeOptions = (activityCommon) => {
  let pesticideTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[0].leaves.map(options => (
    pesticideTypeOptionsArray.push({ label: options, value: options })
  ))
  return pesticideTypeOptionsArray;
}

export const pesticideUnitOptions = () => {
  let pesticideUnitOptionsArray = [];
  pesticideUnitOptionsArray.push({ label: "l/daa", value: "l/daa" });
  pesticideUnitOptionsArray.push({ label: "g/daa", value: "g/daa" });
  pesticideUnitOptionsArray.push({ label: "kg/daa", value: "kg/daa" });
  pesticideUnitOptionsArray.push({ label: "ml/daa", value: "ml/daa" });
  return pesticideUnitOptionsArray;
}

export const waterUnitOptions = (activityCommon) => {
  let waterUnitOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[1].leaves.map(options => (
    waterUnitOptionsArray.push({ label: options, value: options })
  ))
  return waterUnitOptionsArray;
}

export const pestOptions = (activityCommon) => {
  let pestOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements.map(options => (
    pestOptionsArray.push({ label: options.element, value: options.element })
  ))
  return pestOptionsArray;
}

export const pestUgrasSubTypeOptions = (activityCommon) => {
  let pestUgrasSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[5].leaves.map(options => (
    pestUgrasSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestUgrasSubTypeOptionsArray;
}

export const pestPotetsykdommerSubTypeOptions = (activityCommon) => {
  let pestPotetsykdommerSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[2].leaves.map(options => (
    pestPotetsykdommerSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestPotetsykdommerSubTypeOptionsArray;
}

export const pestSoppSubTypeOptions = (activityCommon) => {
  let pestSoppSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[4].leaves.map(options => (
    pestSoppSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestSoppSubTypeOptionsArray;
}

export const pestSkadedyrSubTypeOptions = (activityCommon) => {
  let pestSkadedyrSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[3].leaves.map(options => (
    pestSkadedyrSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestSkadedyrSubTypeOptionsArray;
}

export const pestMangelsykdommerSubTypeOptions = (activityCommon) => {
  let pestMangelsykdommerSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[1].leaves.map(options => (
    pestMangelsykdommerSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestMangelsykdommerSubTypeOptionsArray;
}

export const pestVekstavslutningSubTypeOptions = (activityCommon) => {
  let pestVekstavslutningSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[6].leaves.map(options => (
    pestVekstavslutningSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestVekstavslutningSubTypeOptionsArray;
}

export const pestVekstreguleringSubTypeOptions = (activityCommon) => {
  let pestVekstreguleringSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[7].leaves.map(options => (
    pestVekstreguleringSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestVekstreguleringSubTypeOptionsArray;
}

export const pestBeisingSubTypeOptions = (activityCommon) => {
  let pestBeisingSubTypeOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[2].elements[0].leaves.map(options => (
    pestBeisingSubTypeOptionsArray.push({ label: options, value: options })
  ))
  return pestBeisingSubTypeOptionsArray;
}

export const growthStageOptions = (activityCommon) => {
  let growthStageOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements.map(options => (
    growthStageOptionsArray.push({ label: options.element, value: options.element })
  ))
  return growthStageOptionsArray;
}

export const growthStageSubTypeKornOptions = (activityCommon) => {
  let growthStageSubTypeKornOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements.map(options => (
    growthStageSubTypeKornOptionsArray.push({ label: options.element, value: options.element })
  ))
  return growthStageSubTypeKornOptionsArray;
}

export const growthStageSubSubTypeSpiringOptions = (activityCommon) => {
  let growthStageSubSubTypeSpiringOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[7].leaves.map(options => (
    growthStageSubSubTypeSpiringOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeSpiringOptionsArray;
}

export const growthStageSubSubTypeBladutviklingOptions = (activityCommon) => {
  let growthStageSubSubTypeBladutviklingOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[1].leaves.map(options => (
    growthStageSubSubTypeBladutviklingOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeBladutviklingOptionsArray;
}

export const growthStageSubSubTypeBuskingOptions = (activityCommon) => {
  let growthStageSubSubTypeBuskingOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[3].leaves.map(options => (
    growthStageSubSubTypeBuskingOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeBuskingOptionsArray;
}

export const growthStageSubSubTypeStrekkingOptions = (activityCommon) => {
  let growthStageSubSubTypeStrekkingOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[8].leaves.map(options => (
    growthStageSubSubTypeStrekkingOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeStrekkingOptionsArray;
}

export const growthStageSubSubTypeAksOptions = (activityCommon) => {
  let growthStageSubSubTypeAksOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[0].leaves.map(options => (
    growthStageSubSubTypeAksOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeAksOptionsArray;
}

export const growthStageSubSubTypeSkytingOptions = (activityCommon) => {
  let growthStageSubSubTypeSkytingOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[6].leaves.map(options => (
    growthStageSubSubTypeSkytingOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeSkytingOptionsArray;
}

export const growthStageSubSubTypeBlomstringOptions = (activityCommon) => {
  let growthStageSubSubTypeBlomstringOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[2].leaves.map(options => (
    growthStageSubSubTypeBlomstringOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeBlomstringOptionsArray;
}

export const growthStageSubSubTypeFrkjerneOptions = (activityCommon) => {
  let growthStageSubSubTypeFrkjerneOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[4].leaves.map(options => (
    growthStageSubSubTypeFrkjerneOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeFrkjerneOptionsArray;
}

export const growthStageSubSubTypeModningOptions = (activityCommon) => {
  let growthStageSubSubTypeModningOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[5].leaves.map(options => (
    growthStageSubSubTypeModningOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeModningOptionsArray;
}

export const growthStageSubSubTypeVisningOptions = (activityCommon) => {
  let growthStageSubSubTypeVisningOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[4].elements[9].leaves.map(options => (
    growthStageSubSubTypeVisningOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubSubTypeVisningOptionsArray;
}

export const growthStageSubTypeOljeveksterOptions = (activityCommon) => {
  let growthStageSubTypeOljeveksterOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[5].leaves.map(options => (
    growthStageSubTypeOljeveksterOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubTypeOljeveksterOptionsArray;
}

export const growthStageSubTypeErterOptions = (activityCommon) => {
  let growthStageSubTypeErterOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[1].leaves.map(options => (
    growthStageSubTypeErterOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubTypeErterOptionsArray;
}

export const growthStageSubTypePotetOptions = (activityCommon) => {
  let growthStageSubTypePotetOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[6].leaves.map(options => (
    growthStageSubTypePotetOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubTypePotetOptionsArray;
}

export const growthStageSubTypeJordbrOptions = (activityCommon) => {
  let growthStageSubTypeJordbrOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[3].leaves.map(options => (
    growthStageSubTypeJordbrOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubTypeJordbrOptionsArray;
}

export const growthStageSubTypeEplerOptions = (activityCommon) => {
  let growthStageSubTypeEplerOptionsArray = [];
  _.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[0].leaves.map(options => (
    growthStageSubTypeEplerOptionsArray.push({ label: options, value: options })
  ))
  return growthStageSubTypeEplerOptionsArray;
}

export const growthStageSubTypeIkkeAngittOptions = (activityCommon) => {
  let growthStageSubTypeIkkeAngittOptionsArray = [];
  /*_.filter(activityCommon, ['activityId', ACT19])[0].dropdowns[3].elements[2].leaves.map(options => (
    growthStageSubTypeIkkeAngittOptionsArray.push({ label: options, value: options })
  ))*/
  return growthStageSubTypeIkkeAngittOptionsArray;
}



/**
 * dynamic fields data based on activity
 *
 */
export function DynamicTemplateFields(actId, activityCommon, locations) {
  let fields = [];
  switch (actId) {
    case ACT1: // Sowing
      fields = [
        {
          input_type: 'text',
          placeholder: 'ENTER_SEED_MIX',
          label: 'CT_SEEDMIX',
          name: 'seedmix',
          stlyeClass: 'seedmix'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_AMOUNT',
          label: 'AMOUNT',
          name: 'sowingamount',
          stlyeClass: 'amount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'CT_UNIT',
          label: 'CT_UNIT',
          name: 'sowingunit',
          stlyeClass: 'unit',
          options: act1SowingUnitOptions(activityCommon)
        }
      ];
      break;
    case ACT13: // Treshing
      fields = [
        {
          input_type: 'text',
          placeholder: 'ENTER_TOTAL_HARVEST',
          label: 'CT_TOTALHARVEST',
          name: 'totalharvest',
          stlyeClass: 'totalharvest',
          units: 'Kg/daa'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WATER_PERCENT',
          label: 'CT_WATERPERCENTAGE',
          name: 'waterpercentage',
          stlyeClass: 'waterpercentage',
          units: '%'
        }
      ];
      break;
    case ACT18: // Transportation(bales)
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act18CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'HARVEST_QUANTITY_PLACEHOLDER',
          label: 'HARVEST_QUANTITY',
          name: 'cutamount',
          stlyeClass: 'cutamount',
          units: 'TASK_HARVEST_UNIT'
        },
        {
          input_type: 'dropdown',
          placeholder: 'HARVEST_LOCATION_LABEL',
          label: 'HARVEST_LOCATION_LABEL',
          name: 'locationId',
          stlyeClass: 'cutunit',
          options: displayLocations(locations)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'drymatter',
          units: '%'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'weight',
          units: 'kg'
        }
      ];
      break;
    case ACT17: // Transportation(wagons)
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act17CutAmountOptions(activityCommon)
        }
      ];
      break;
    case ACT16: // Bale packing
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act16CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'HARVEST_QUANTITY_PLACEHOLDER',
          label: 'HARVEST_QUANTITY',
          name: 'cutamount',
          stlyeClass: 'cutamount',
          units: 'TASK_HARVEST_UNIT'
        },
        {
          input_type: 'dropdown',
          placeholder: 'HARVEST_LOCATION_LABEL',
          label: 'HARVEST_LOCATION_LABEL',
          name: 'locationId',
          stlyeClass: 'cutunit',
          options: displayLocations(locations)
        },
      ];
      break;
    case ACT15: // Raking
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act15CutAmountOptions(activityCommon)
        }
      ];
      break;
    case ACT14: // Aerate gras
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act14CutAmountOptions(activityCommon)
        }
      ];
      break;
    case ACT5: // Mowing
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act5CutAmountOptions(activityCommon)
        }
      ];
      break;
    case ACT20: // Combo fertilization and sowing
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_MINERALFERTILIZER',
          label: 'CT_MINERALFERTILIZER',
          name: 'fertilizertype',
          stlyeClass: 'fertilizertype',
          options: mineralFertilizerOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_AMOUNT',
          label: 'AMOUNT',
          name: 'fertilizeramount',
          stlyeClass: 'fertilizeramount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'mineralfertilizerunit',
          stlyeClass: 'fertilizerunit',
          options: act20MineralFertilizerLimingUnitOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_SEED_MIX',
          label: 'CT_SEEDMIX',
          name: 'seedmix',
          stlyeClass: 'seedmix'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_AMOUNT',
          label: 'AMOUNT',
          name: 'sowingamount',
          stlyeClass: 'sowingamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'sowingunit',
          stlyeClass: 'sowingunit',
          options: act20SowingUnitOptions(activityCommon)
        }
      ];
      break;
    case ACT7: // Fertilization
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'Enter Fertilizer Type',
          label: 'CT_FERTILIZERTYPE',
          name: 'fertilizertype',
          stlyeClass: 'fertilizertype',
          options: fertilizerTypeOptions(activityCommon)
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter Type',
          label: 'TYPE',
          name: 'fertilizersubtype',
          stlyeClass: 'fertilizersubtype',
          options: []
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_PLANNED_AMOUNT',
          label: 'PLANNED_AMOUNT',
          name: 'fertilizerplanamount',
          stlyeClass: 'fertilizerplanamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'fertilizerplanunit',
          stlyeClass: 'fertilizerplanunit',
          options: []
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ACTUAL_AMOUNT',
          label: 'ACTUAL_AMOUNT',
          name: 'fertilizeramount',
          stlyeClass: 'fertilizeramount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'fertilizerunit',
          stlyeClass: 'fertilizerunit',
          options: []
        },
        {
          input_type: 'seasonDropdown',
          name: 'fertilizerseason',
          placeholder: 'Select season',
          label: 'CT_SEASON',
        }
      ];
      break;
    case ACT12: // Bale pressing
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act12CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'HARVEST_QUANTITY_PLACEHOLDER',
          label: 'HARVEST_QUANTITY',
          name: 'cutamount',
          stlyeClass: 'cutamount',
          units: 'TASK_HARVEST_UNIT'
        },
        {
          input_type: 'dropdown',
          placeholder: 'HARVEST_LOCATION_LABEL',
          label: 'HARVEST_LOCATION_LABEL',
          name: 'locationId',
          stlyeClass: 'cutunit',
          options: displayLocations(locations)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_TYPE',
          label: 'CT_ENSILATIONTYPE',
          name: 'ensilationtype',
          stlyeClass: 'ensilationtype'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_AMOUNT',
          label: 'AMOUNT',
          name: 'ensilationamount',
          stlyeClass: 'ensilationamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'ensilationunit',
          stlyeClass: 'ensilationunit',
          options: act12BalePressEnsilationOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'drymatter',
          units: '%'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'weight',
          units: 'kg'
        }
      ];
      break;
    case ACT11: // Baling combi
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act11CutAmountOptions(activityCommon)          
        },
        {
          input_type: 'text',
          placeholder: 'HARVEST_QUANTITY_PLACEHOLDER',
          label: 'HARVEST_QUANTITY',
          name: 'cutamount',
          stlyeClass: 'cutamount',
          units: 'TASK_HARVEST_UNIT'
        },
        {
          input_type: 'dropdown',
          placeholder: 'HARVEST_LOCATION_LABEL',
          label: 'HARVEST_LOCATION_LABEL',
          name: 'locationId',
          stlyeClass: 'cutunit',
          options: displayLocations(locations)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_TYPE',
          label: 'CT_ENSILATIONTYPE',
          name: 'ensilationtype',
          stlyeClass: 'ensilationtype'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_AMOUNT',
          label: 'AMOUNT',
          name: 'ensilationamount',
          stlyeClass: 'ensilationamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'ensilationunit',
          stlyeClass: 'ensilationunit',
          options: act11BalePressEnsilationOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'drymatter',
          units: '%'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'weight',
          units: 'kg'
        }
      ];
      break;
    case ACT10: // Forage wagon harvesting
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act10CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'CT_ENTERCUTAMOUNT',
          label: 'AMOUNT',
          name: 'cutamount',
          stlyeClass: 'cutamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'cutunit',
          stlyeClass: 'cutunit',
          options: act10BalePressUnitOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_TYPE',
          label: 'CT_ENSILATIONTYPE',
          name: 'ensilationtype',
          stlyeClass: 'ensilationtype'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_AMOUNT',
          label: 'AMOUNT',
          name: 'ensilationamount',
          stlyeClass: 'ensilationamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'ensilationunit',
          stlyeClass: 'ensilationunit',
          options: act10BalePressEnsilationOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'drymatter',
          units: '%'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'weight',
          units: 'kg'
        }
      ];
      break;
    case ACT9: // Precision chop silaging
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act9CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'CT_ENTERCUTAMOUNT',
          label: 'AMOUNT',
          name: 'cutamount',
          stlyeClass: 'cutamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'cutunit',
          stlyeClass: 'cutunit',
          options: act9BalePressUnitOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_TYPE',
          label: 'CT_ENSILATIONTYPE',
          name: 'ensilationtype',
          stlyeClass: 'ensilationtype'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_AMOUNT',
          label: 'AMOUNT',
          name: 'ensilationamount',
          stlyeClass: 'ensilationamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'ensilationunit',
          stlyeClass: 'ensilationunit',
          options: act9BalePressEnsilationOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'drymatter',
          units: '%'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'weight',
          units: 'kg'
        }
      ];
      break;
    case ACT28: // Self-loading wagon
      fields = [
        {
          input_type: 'dropdown',
          placeholder: 'CT_HARVEST',
          label: 'CT_HARVEST',
          name: 'cut',
          stlyeClass: 'cut',
          options: act28CutAmountOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'CT_ENTERCUTAMOUNT',
          label: 'AMOUNT',
          name: 'cutamount',
          stlyeClass: 'cutamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'cutunit',
          stlyeClass: 'cutunit',
          options: act28BalePressUnitOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_TYPE',
          label: 'CT_ENSILATIONTYPE',
          name: 'ensilationtype',
          stlyeClass: 'ensilationtype'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_ENSILATION_AMOUNT',
          label: 'AMOUNT',
          name: 'ensilationamount',
          stlyeClass: 'ensilationamount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter unit',
          label: 'CT_UNIT',
          name: 'ensilationunit',
          stlyeClass: 'ensilationunit',
          options: act28BalePressEnsilationOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_DRY_MATTER',
          label: 'DRY_MATTER',
          name: 'drymatter',
          stlyeClass: 'drymatter',
          units: '%'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_WEIGHT',
          label: 'WEIGHT',
          name: 'weight',
          stlyeClass: 'weight',
          units: 'kg'
        }
      ];
      break;
    case ACT19: // Pesticide application
      fields = [
        {
          input_type: 'text',
          placeholder: 'ENTER_WATER_AMOUNT',
          label: 'CT_WATERAMOUNT',
          name: 'wateramount',
          stlyeClass: 'wateramount'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Enter water unit',
          label: 'CT_WATERUNIT',
          name: 'waterunit',
          stlyeClass: 'waterunit',
          options: waterUnitOptions(activityCommon)
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_CROP',
          label: 'CT_CR',
          name: 'crop',
          stlyeClass: 'crop'
        },
        {
          input_type: 'text',
          placeholder: 'ENTER_REASON',
          label: 'CT_RFPA',
          name: 'reason',
          stlyeClass: 'reason'
        },
        {
          input_type: 'dropdown',
          placeholder: 'Select Pest',
          label: 'CT_PEST',
          name: 'pestdata',
          stlyeClass: 'pestdata',
          options: pestOptions(activityCommon)
        },
        {
          input_type: 'dropdown',
          placeholder: 'Select sub Pest',
          label: 'CT_PESTSUBTYPES',
          name: 'pestsubdata',
          stlyeClass: 'pestsubdata',
          options: [],
          isMulti: true
        },
        {
          input_type: 'dropdown',
          placeholder: 'Select Growth Stage',
          label: 'CT_GS',
          name: 'growthstage',
          stlyeClass: 'growthstage',
          options: growthStageOptions(activityCommon)
        },
        {
          input_type: 'dropdown',
          placeholder: 'Select growth stage',
          label: 'CT_GSSUB',
          name: 'growthstagesub',
          stlyeClass: 'growthstagesub',
          options: []
        },
        {
          input_type: 'dropdown',
          placeholder: 'Select growth stage',
          label: 'CT_GSSUBTYPE',
          name: 'growthstagesubsubtype',
          stlyeClass: 'growthstagesubsubtype',
          options: []
        },
        {
          input_type: 'slider',
          label: 'CT_EOA',
          stlyeClass: 'effectofapp'
        },
        {
          input_type: 'date',
          placeholder: 'ENTER_DATE',
          label: 'CT_FVHD',
          name: 'harvestdate',
          stlyeClass: 'fvhd'
        },
        /*{
          input_type: 'text',
          placeholder: 'ENTER_DISTANCE',
          label: 'CT_PM',
          name: 'measuressteams',
          stlyeClass: 'measuressteams',
          units: 'meter'
        }*/
        {
          input_type: 'toggle',
          placeholder: 'WATER_JOURNAL_ADD',
          label: 'WATER_JOURNAL_ADD',
          name: 'waterJournalAdded',
          stlyeClass: 'waterJournalAdded',
        }
      ];
      break;
    default:
      fields = [];
  }
  return fields;
}

export const DynamicPayloadAttributesObj = id => {
  let attributesObj = {};
  switch (id) {
    case ACT1: // Sowing
      attributesObj = {
        activity_id: '',
        activity_name: '',
        mix_text: 'seedmix',
        amount: 'sowingamount',
        unit: 'sowingunit'
      };
      break;
    case ACT13: // Treshing
      attributesObj = {
        activity_id: '',
        activity_name: '',
        total_harvest_text: 'totalharvest',
        water_percent: 'waterpercentage'
      };
      break;
    case ACT17: // Transportation(wagons)
    case ACT15: // Raking
    case ACT14: // Aerate gras
    case ACT5: // Mowing
      attributesObj = {
        activity_id: '',
        activity_name: '',
        cut_text: 'cut'
      };
      break;
    case ACT18: // Transportation(bales)
      attributesObj = {
        activity_id: '',
        activity_name: '',
        cut_text: 'cut',
        cut_amount: 'cutamount',
        cut_unit: '',
        locationId: 'locationId',
        drymatter: 'drymatter',
        weight: 'weight'
      };
      break;
    case ACT16: // Bale packing
      attributesObj = {
        activity_id: '',
        activity_name: '',
        cut_text: 'cut',
        cut_amount: 'cutamount',
        cut_unit: '',
        locationId: 'locationId'
      };
      break;
    case ACT20: // Combo fertilization and sowing
      attributesObj = {
        activity_id: '',
        activity_name: '',
        mineralFerti_type: 'fertilizertype',
        combo_ferti_amount: 'fertilizeramount',
        combo_ferti_unit: 'mineralfertilizerunit',
        sowing_type_text: 'seedmix',
        combo_sowing_amount: 'sowingamount',
        combo_sowing_unit: 'sowingunit'
      };
      break;
    case ACT7: // Fertilization
      attributesObj = {
        activity_id: '',
        activity_name: '',
        type: 'fertilizertype',
        sub_type: 'fertilizersubtype',
        amount: 'fertilizeramount',
        unit: 'fertilizerunit',
        plan_amount: 'fertilizerplanamount',
        plan_unit: 'fertilizerplanunit',
        season: 'fertilizerseason'
      };
      break;
    case ACT10: // Forage wagon harvesting
    case ACT9: // Precision chop silaging
    case ACT28: // Self-loading wagon
      attributesObj = {
        activity_id: '',
        activity_name: '',
        cut_text: 'cut',
        cut_amount: 'cutamount',
        cut_unit: 'cutunit',
        ensilation_text: 'ensilationtype',
        ensilation_amount: 'ensilationamount',
        ensilation_unit: 'ensilationunit',
        drymatter: 'drymatter',
        weight: 'weight'
      };
      break;
    case ACT12: // Bale pressing
    case ACT11: // Baling combi
      attributesObj = {
        activity_id: '',
        activity_name: '',
        cut_text: 'cut',
        cut_amount: 'cutamount',
        cut_unit: '',
        locationId: 'locationId',
        ensilation_text: 'ensilationtype',
        ensilation_amount: 'ensilationamount',
        ensilation_unit: 'ensilationunit',
        drymatter: 'drymatter',
        weight: 'weight'
      };
      break;
    case ACT19: // Pesticide application
      attributesObj = {
        activity_id: '',
        activity_name: '',
        pestiside_type: '',
        water_amount: 'wateramount',
        unit: '',
        water_unit: 'waterunit',
        crop: 'crop',
        reason: 'reason',
        pest_data: 'pestdata',
        pest_sub_list: 'pestsubdata',
        growthStage: 'growthstage',
        growthStage_sub: 'growthstagesub',
        growthStage_sub_subtype: 'growthstagesubsubtype',
        harvestDate: 'harvestdate',
        //measures_steams: 'measuressteams'
        water_journal: 'waterjournal'
      };
      break;
    default:
      attributesObj = {};
      break;
  }
  return attributesObj;
};