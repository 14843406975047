import * as React from 'react';
import { Grid, Typography, Box, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const textStyle = { fontFamily: "Inter", fontWeight: "400", fontSize: "16px", color: "#191C1C" }
const textSmallStyle = { ...textStyle, fontSize: "14px", color: "#3F4949" }
const normalText = { ...textSmallStyle, fontSize: "12px", fontWeight: "600" }
const gridContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
};
const gridTitleItemStyle = { flex: 3, paddingLeft: 5, paddingRight: 5 };
const gridStyle = { paddingLeft: 12, paddingRight: 12, paddingTop: 13 };
const gridItemStyle = { ...gridStyle, borderRight: '1px solid #ccc' };
const lastBeforeGridItemStyle = { flex: 1, paddingLeft: 10, paddingTop: 10 };
const lastGridItemStyle = { ...lastBeforeGridItemStyle, flex: '0.1 0 auto' };
const menuItemStyle = {
  backgroundColor: 'transparent',
  padding: '8px 16px',
  fontFamily: 'Inter',
  fontSize: '14px'
};

export default ({ fieldFertilizationItem, intl, anchorEl, handleMenuClick, handleMenuClose, displayGrid, onClickOpenInsufficientItemsLink, redirectFieldLink, selectedMenuFieldId }) => (
  <Grid container style={gridContainerStyle} xs={12} sm={12}>
    <Grid item style={gridTitleItemStyle}>
      <Typography style={textStyle}>{fieldFertilizationItem.fieldName}</Typography>
      {displayGrid && <Typography style={textSmallStyle}>{fieldFertilizationItem.area.toFixed(1)} {fieldFertilizationItem.areaUnits}</Typography>}
    </Grid>
    {displayGrid && <Grid item style={gridItemStyle}>
      <Typography style={normalText}>
        {intl.formatMessage({ id: 'YIELD_GOAL' })}:&nbsp;
        {fieldFertilizationItem.soilParam.yieldGoal} {(fieldFertilizationItem.soilParam.cropId === 5 || fieldFertilizationItem.soilParam.cropId === 14 || 
        fieldFertilizationItem.soilParam.cropId === 17 || fieldFertilizationItem.soilParam.cropId === 18 || fieldFertilizationItem.soilParam.cropId === 19 || 
        fieldFertilizationItem.soilParam.cropId === 23) ? intl.formatMessage({ id: 'YIELD_UNIT' }) : 'kg ts/daa'}
      </Typography>
    </Grid>}
    {displayGrid && <Grid item style={gridItemStyle}>
      <Typography style={normalText}>
        {intl.formatMessage({ id: 'NUMBER_OF_HARVESTS' })}:&nbsp;
        {fieldFertilizationItem.numberOfHarvests}
      </Typography>
    </Grid>}
    {displayGrid && <Grid item style={gridItemStyle}>
      <Typography style={normalText}>
        {intl.formatMessage({ id: 'MANURE' })}:&nbsp;
        {fieldFertilizationItem.soilParam.manureApplied === true ? intl.formatMessage({ id: 'YES' }) : intl.formatMessage({ id: 'NO' })}
      </Typography>
    </Grid>}
    {displayGrid && <Grid item style={gridItemStyle}>
      <Typography style={normalText}>
        {intl.formatMessage({ id: 'NUMBER_OF_FERTILIZATIONS' })}:&nbsp;
        {fieldFertilizationItem.numberOfFertilizations}
      </Typography>
    </Grid>}
    {displayGrid && <Grid item style={gridItemStyle}>
      <Typography style={normalText}>
        {intl.formatMessage({ id: 'CT_CR' })}:&nbsp;
        {fieldFertilizationItem.soilParam.crop}
      </Typography>
    </Grid>}
    {displayGrid && <Grid item style={gridStyle}>
      <Typography style={normalText}>
        {intl.formatMessage({ id: 'FIELD_FERTILIZATION_PH' })}:&nbsp;
        {fieldFertilizationItem.soilParam.ph}
      </Typography>
    </Grid>}
    <Grid item xs flex='1' style={lastGridItemStyle}>
      <Box component='span' className='grid-padding-dot'>
        <MoreVertIcon onClick={(e)=>handleMenuClick(e, fieldFertilizationItem.fieldId)} sx={{ cursor: 'pointer' }} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(e)=>handleMenuClose(e)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => onClickOpenInsufficientItemsLink(selectedMenuFieldId)}
            style={menuItemStyle}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
          >
            {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU7' })}
          </MenuItem>
          {/*<MenuItem onClick={() => {this.setState(prevState => ({ anchorE2: false }));}}
            style={menuItemStyle}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
          >
            {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU8' })}
          </MenuItem>*/}
          <MenuItem onClick={redirectFieldLink}
            style={menuItemStyle}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#f1f1f1'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
          >
            {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU9' })}
          </MenuItem>
        </Menu>
      </Box>
    </Grid>
  </Grid>
);
