import * as React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import { FormattedMessage } from 'react-intl';
import { Stack, Grid, List, ListItemButton, Link, Collapse, IconButton, Typography, Chip } from '@mui/material'
import { getDropDownMethodNew, getDropDownValuesNew } from 'utils/index';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import { createSvgIcon } from '@mui/material/utils';
import { isMobile } from 'react-device-detect';

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-4 -4 32 32"
    strokeWidth={1.5}
    stroke="#00696D"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  'Plus',
);

const StorageComponent = ({ handleClickStorage, intl, open4, openDialogWindow, manureMethod, manureMethodChange,
  manureStorage, manureStorageChange, summer_manureMethod, summer_manureStorage, summer_manureAmount, 
  spring_manureMethod, spring_manureStorage, spring_manureAmount, fall_manureMethod, fall_manureStorage,
  fall_manureAmount,handleChange, lblPage, manureNames, fieldMethod, harvestValue, disableGrain, seasons, resetSeason}) => (
  <List
    sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0' }}
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    <ListItemButton disableRipple sx={{ '&:hover': {backgroundColor: 'transparent'} }} onClick={handleClickStorage}>
      <span><ListItemText primary="Gjødsling" /></span>
      <span>{open4 ? <ExpandLess /> : <ExpandMore />}</span>
    </ListItemButton>
    <Collapse in={open4} timeout="auto" unmountOnExit>
      <List component="div" className='list-grid-box'>
        <Stack>
          <Link underline="none" sx={{display: 'flex', alignItems: 'center', color: '#00696D'}}>
            <IconButton onClick={openDialogWindow} sx={{ '&:hover': { backgroundColor: 'transparent' }, '&:selected': { backgroundColor: 'transparent' } }}>
              <PlusIcon />
            </IconButton>
            <Typography onClick={openDialogWindow} variant="body1" sx={{cursor: 'pointer'}}>
              {intl.formatMessage({ id: 'MANURE_STORAGE_DIALOG_SUBHEADING' })}
            </Typography>
          </Link>
        </Stack>
        <ListItemButton disableRipple sx={{ paddingTop: 2, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={12} xs={12} sm={12}>
              <Stack>{intl.formatMessage({ id: 'MANURE_APPLIED_1' })}</Stack>
            </Grid>
          </Grid>
        </ListItemButton>
        <ListItemButton disableRipple sx={{ paddingTop: 2, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={4} xs={12} sm={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="manureMethod"
                  id="manureMethod"
                  label={lblPage.labels.method}
                  variant="outlined"
                  value={manureMethod}
                  options={getDropDownMethodNew(fieldMethod)}
                  onChange={manureMethodChange}
                />
              </Stack>
            </Grid>
            <Grid item md={4} xs={12} sm={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="manureStorage"
                  id="manureStorage"
                  label={lblPage.labels.manureStorage}
                  variant="outlined"
                  value={manureStorage}
                  options={getDropDownValuesNew(manureNames)}
                  onChange={manureStorageChange}
                />
              </Stack>
            </Grid>
            {!isMobile && <Grid item md={4}></Grid>}
          </Grid>
        </ListItemButton>
        {harvestValue >= 1 &&
        <ListItemButton disableRipple sx={{ paddingTop: 2, paddingBottom: 2, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={12} sm={12} xs={12}>
              <Stack>{disableGrain === false ? seasons.seasonsList[0].season : intl.formatMessage({ id: 'GRAIN_METHOD_FERTILIZATION' })}:</Stack>
            </Grid>
          </Grid>
        </ListItemButton>}
        {harvestValue >= 1 &&
        <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={4} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="spring_manureMethod"
                  id="spring_manureMethod"
                  label={lblPage.labels.method}
                  variant="outlined"
                  value={spring_manureMethod}
                  options={getDropDownMethodNew(fieldMethod)}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: spring_manureMethod }}
                />
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="spring_manureStorage"
                  id="spring_manureStorage"
                  label={lblPage.labels.manureStorage}
                  variant="outlined"
                  value={spring_manureStorage}
                  options={getDropDownValuesNew(manureNames)}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: spring_manureStorage }}
                />
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiTextBox 
                  name="spring_manureAmount"
                  id="spring_manureAmount"
                  label={lblPage.labels.manureAmount}
                  variant="outlined"
                  value={spring_manureAmount}
                  onChange={handleChange}
                  showFullWidth="true"
                />
              </Stack>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Stack className='text-with-padding-small' sx ={{paddingTop: '10px', display: 'block'}}>
                {((spring_manureMethod !== null && spring_manureMethod !== '') || (spring_manureStorage !== null && spring_manureStorage !== '') || (spring_manureAmount !== null && spring_manureAmount !== '')) ?
                <Chip
                  label={<FormattedMessage id="FILTER_RESET_EMPTY" />}
                  color="primary"
                  clickable
                  onClick={() => resetSeason(1)}
                  sx ={{ backgroundColor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}
                  className='grid-padding-reset'
                /> : ''}
              </Stack>
            </Grid>
          </Grid>
        </ListItemButton>}
        {disableGrain === false  && harvestValue >= 1 &&
        <ListItemButton disableRipple sx={{ paddingTop: 2, paddingBottom: 2, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={12} sm={12} xs={12}>
              <Stack>{seasons.seasonsList[1].season}:</Stack>
            </Grid>
          </Grid>
        </ListItemButton>}
        {disableGrain === false  && harvestValue >= 1 &&
        <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={4} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="summer_manureMethod"
                  id="summer_manureMethod"
                  label={lblPage.labels.method}
                  variant="outlined"
                  value={summer_manureMethod}
                  options={getDropDownMethodNew(fieldMethod)}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: summer_manureMethod }}
                />
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="summer_manureStorage"
                  id="summer_manureStorage"
                  label={lblPage.labels.manureStorage}
                  variant="outlined"
                  value={summer_manureStorage}
                  options={getDropDownValuesNew(manureNames)}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: summer_manureStorage }}
                />
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiTextBox 
                  name="summer_manureAmount"
                  id="summer_manureAmount"
                  label={lblPage.labels.manureAmount}
                  variant="outlined"
                  value={summer_manureAmount}
                  onChange={handleChange}
                  showFullWidth="true"
                />
              </Stack>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx ={{paddingTop: '10px', display: 'block', paddingBottom: isMobile ? '18px' : ''}}>
                {((summer_manureMethod !== null && summer_manureMethod !== '') || (summer_manureStorage !== null && summer_manureStorage !== '') || (summer_manureAmount !== null && summer_manureAmount !== '')) ?
                <Chip
                  label={<FormattedMessage id="FILTER_RESET_EMPTY" />}
                  color="primary"
                  clickable
                  onClick={() => resetSeason(2)}
                  sx={{ backgroundColor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}
                  className='grid-padding-reset'
                /> : ''}
              </Stack>
            </Grid>
          </Grid>
        </ListItemButton>}
        {disableGrain === false  && harvestValue >= 2 &&
        <ListItemButton disableRipple sx={{ paddingTop: 2, paddingBottom: 2, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={12} sm={12} xs={12}>
              <Stack>{seasons.seasonsList[2].season}:</Stack>
            </Grid>
          </Grid>
        </ListItemButton>
        }
        {disableGrain === false && harvestValue >= 2 &&
        <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={4} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="fall_manureMethod"
                  id="fall_manureMethod"
                  label={lblPage.labels.method}
                  variant="outlined"
                  value={fall_manureMethod}
                  options={getDropDownMethodNew(fieldMethod)}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: fall_manureMethod }}
                />
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiSelectBox 
                  name="fall_manureStorage"
                  id="fall_manureStorage"
                  label={lblPage.labels.manureStorage}
                  variant="outlined"
                  value={fall_manureStorage}
                  options={getDropDownValuesNew(manureNames)}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: fall_manureStorage }}
                />
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx={{ paddingBottom: isMobile ? '18px' : ''}}>
                <MuiTextBox 
                  name="fall_manureAmount"
                  id="fall_manureAmount"
                  label={lblPage.labels.manureAmount}
                  variant="outlined"
                  value={fall_manureAmount}
                  onChange={handleChange}
                  showFullWidth="true"
                />
              </Stack>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Stack className={!isMobile ? 'text-with-padding-small' : ''} sx ={{paddingTop: '10px', display: 'block', paddingBottom: isMobile ? '18px' : ''}}>
                {((fall_manureMethod !== null && fall_manureMethod !== '') || (fall_manureStorage !== null && fall_manureStorage !== '') || (fall_manureAmount !== null && fall_manureAmount !== '')) ?
                <Chip
                  label={<FormattedMessage id="FILTER_RESET_EMPTY" />}
                  color="primary"
                  clickable
                  onClick={() => resetSeason(3)}
                  sx ={{ backgroundColor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}
                  className='grid-padding-reset'
                /> : ''}
              </Stack>
            </Grid>
          </Grid>
        </ListItemButton>}
      </List>
    </Collapse>
  </List>
);

export default StorageComponent;