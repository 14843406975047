/**
 * Component: Help Text
 * It is wrapper component for the Help text content and feedback component
 * @props { HTMLElements } children
 * @props { showHelp } Boolen
 * @props { showHelpClose } function
 * @props { FeedbackBtnTxt } string
 */

import * as React from 'react';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import { CrossIcon } from '@tine/lib-frontend-components/elements/icons';

import Feedback from 'components/Feedback';

export default ({ children, showHelp, showHelpClose, FeedbackBtnTxt }) => {
  const [isShowFeedback, showFeedback] = React.useState(false);

  return (
    <div className="help-text">
      <ModalDialog
        show={showHelp}
        onClose={() => showHelpClose()}
        width={{
          mobile: '80%',
          tablet: '60%',
          web: '50%'
        }}
        height={{
          mobile: 'auto',
          tablet: 'auto',
          web: isShowFeedback ? 'auto' : '80%'
        }}
        closeIcon={
          <span className="help-close">
            <CrossIcon scale={0.7} />
          </span>
        }
      >
        <>
          {!isShowFeedback && (
            <>
              {children}
              <div className="feedback-btn">
                <ActionButton secondary onClick={() => showFeedback(true)}>
                  {FeedbackBtnTxt}
                </ActionButton>
              </div>
            </>
          )}
          {isShowFeedback && <Feedback backToHelp={showFeedback} />}
        </>
      </ModalDialog>
    </div>
  );
};
