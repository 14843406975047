/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'containers/layouts/AppLayout';
import { OAUTH_REDIRECT_URI } from 'constants/OauthProperties';
import { GetSessionData, GetLocalData  } from 'utils';
import { LOCAL_KEY_USER } from 'constants/index';

const PrivateRoute = ({ component: Component, userDetails, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      userDetails ? (
        <AppLayout>
          <Component {...props} />
        </AppLayout>
      ) : (
        window.location.href.includes('registration') ? <Redirect to={{ pathname: '/', state: { from: props.location } }} /> :
        ((OAUTH_REDIRECT_URI !== window.location.href && !GetSessionData(LOCAL_KEY_USER) && !GetLocalData(LOCAL_KEY_USER)) ? <Redirect to="/redirect" /> :
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />)
      )
    }
  />
);
const mapStateToProps = state => ({
  userDetails: state.userDetails.data
});

export default connect(mapStateToProps)(PrivateRoute);
