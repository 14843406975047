/* eslint-disable react/prop-types */
/**
 * Textbox Control
 * require @props {label, placeholdertext, errorMessage, controlId}
 */
import React, { Component } from 'react';

class CheckboxControl extends Component {
  toggleCheck = (isChecked, name) => {
    const { onChange } = this.props;
    onChange(!isChecked, name);
  };

  render() {
    const { label, styleName, controlId, checked } = this.props;
    return (
      <div
        onClick={() => {
          this.toggleCheck(checked, controlId);
        }}
        onKeyPress={this.toggleCheck}
        role="button"
        tabIndex="0"
        className={`checkbox-control ${styleName}`}
      >
        <input type="checkbox" id={controlId} checked={checked} onChange={() => {}} />
        <span />
        {label ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <label
            onClick={() => {
              this.toggleCheck(checked, controlId);
            }}
            className="checkbox-label"
            htmlFor={controlId}
            role="presentation"
          >
            {label}
          </label>
        ) : null}
      </div>
    );
  }
}

export default CheckboxControl;
