import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import Numeral from '@tine/lib-frontend-components/elements/typography/numeral';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';

import SelectControl from 'components/forms/SelectBoxControl';
import * as SoilSamplesAction from 'actions/SoilSamples';
import mixpanel from 'mixpanel-browser';
import { VIEW_SOIL_SAMPLE_RESULTS, MAP_SOIL_SAMPLE_RESULTS } from 'constants/MixPanel';
import * as fieldActions from 'actions/Fields';

class SoilSampleList extends React.Component {
  componentDidMount = () => {
    const { match, history, activeFarm, getSamplesDetail, fetchFieldList, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(VIEW_SOIL_SAMPLE_RESULTS);
    const sampleDate = match.params.date;
    const year = match.params.year;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (sampleDate && year) {
      fetchFieldList(activeFarm.farm.id);
      getSamplesDetail(activeFarm.farm.id, sampleDate, year);
    }
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/fertilization/soil-samples');
    }
  }

  getHeaderInfo = () => {
    const {
      pageContent: { labels },
      soilSamples: { sampleDetails }
    } = this.props;
    if (sampleDetails) {
      return (
        <div className="sample-info">
          <p>
            <Emphasis size="extralarge">{sampleDetails.fileName}</Emphasis>
          </p>
          <p>
            <Text size="small">
              {labels.sampleDate} {sampleDetails.fileSampleDate}{/*formatDate(new Date(sampleDetails.fileSampleDate))*/}
            </Text>
          </p>
        </div>
      );
    }
    return null;
  };

  getFields = () => {
    const { fields } = this.props;
    if (fields.data) {
      return fields.data.map(data => ({ value: data.fieldId, label: data.displayName }));
    }
    return [];
  };

  onSelectChange = (selectedItem, field) => {
    const { UpdateSamplesDetail, activeFarm, userDetails } = this.props;
    const updatResult = JSON.parse(selectedItem);
    updatResult.fieldId = field.value;
    mixpanel.identify(userDetails.id);
    mixpanel.track(MAP_SOIL_SAMPLE_RESULTS);
    UpdateSamplesDetail(activeFarm.farm.id, [updatResult]);
  };

  selectFieldCheckbox = (seletedItem,e) => {
    const fieldIdArray = [];
    e.map(event => {
      return fieldIdArray.push(event.value);
    });

    const { UpdateSamplesDetail, activeFarm, userDetails } = this.props;
    const updatResult = JSON.parse(seletedItem);
    updatResult.fieldIds = fieldIdArray;
    mixpanel.identify(userDetails.id);
    mixpanel.track(MAP_SOIL_SAMPLE_RESULTS);
    UpdateSamplesDetail(activeFarm.farm.id, updatResult);
  }

  renderSampleList = () => {
    const {
      soilSamples: { sampleDetails },
      pageContent: { labels }
    } = this.props;
    if (sampleDetails) {
      const { soilTestResultsForFile } = sampleDetails;
      if (soilTestResultsForFile?.length > 0) {
        const fields = this.getFields();
        return soilTestResultsForFile.map(item => {
          const [listValues] = Object.entries(item.sampleResultsMap).map(([key, value]) => {
            return { key, value };
          });
          let defaultField = [];
          if (listValues.value[0] && listValues.value[0].fields) {
            listValues.value[0].fields.map((output) => {
              return defaultField.push({"value": output.id, "label": output.name});
            });
          }
          return (
            <div className="sample-list-container" key={listValues.key}>
              <div className="soil-info-list">{this.getSampleInfoList(listValues.value[0].sampleParams)}</div>
              <div className="field-update">
                <div className="sample-no">
                  <EmphasisLight>{labels.sampleNo}</EmphasisLight>
                  <Numeral size="large">{item.sequence}</Numeral>
                </div>
                <div className="sample-date">
                  <MetaInformation size="small">
                    {labels.date}{' '}
                    {item.sampleDate /*&&
                      formatDate(new Date(SoilSampleDateFormate(item.sampleDate)))*/}
                  </MetaInformation>
                </div>
                <div className="dropdowns">
                <Field
                    styleName="select-box-small"
                    placeholder={labels.chooseField}
                    options={fields}
                    component={SelectControl}
                    name={item.sequence}
                    label={labels.chooseField}
                    controlId={item.sequence}
                    hasCheckbox={true}
                    isMulti={true}
                    defaultValue={defaultField}
                    onChange={value => {
                      this.selectFieldCheckbox(listValues.key, value);
                    }}
                  />
                </div>
              </div>
            </div>
            
          );
        });
      }
    }
    return null;
  };

  getSampleInfoList = values => {
    return values.map((item,index) => (
      <div key={index}className="soil-info-list__item">
        <MetaInformation size="small">{item.name}: </MetaInformation>
        <EmphasisLight>{item.value}</EmphasisLight>
      </div>
    ));
  };

  render() {
    const { intl, history } = this.props;
    return (
      <div className="soil-sample-details">
        <SectionContainer
          styling={{
            paddingTop: 'low'
          }}
        >
          <form>
          {/*soilSamples.isLoading && <Loader styleName="fixed-loader" />*/}
          {this.getHeaderInfo()}
          {this.renderSampleList()}
          <div className="fixed-button">
            <div className="mineral-fertilizer-text-left">
              <span className="padding-right-heavy">
                <ActionButton
                  onClick={() => {
                    history.push('/fertilization/soil-samples/');
                  }}
                >
                  {intl.formatMessage({ id: 'DONE' })}
                </ActionButton>
              </span>
            </div>
          </div>
          </form>
        </SectionContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  lblCommon: state.masterData.data[0]?.commonLabels,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'soilSamplesPage'])[0],
  soilSamples: state.soilSamples,
  fields: state.fieldDetails,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
  getSamplesDetail: (farmId, sampleDate, year) =>
    dispatch(SoilSamplesAction.getSamplesDetail(farmId, sampleDate, year)),
  UpdateSamplesDetail: (farmId, data) =>
    dispatch(SoilSamplesAction.UpdateSamplesDetail(farmId, data))
});

export default injectIntl(
  reduxForm({
    form: 'SoilSampleList'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SoilSampleList)))
);
//export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SoilSampleList)));
