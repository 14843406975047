import * as React from 'react';

import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import Numeral from '@tine/lib-frontend-components/elements/typography/numeral';

const FertilizerManure = ({ fertilizationPlan, intl }) => (
  <SectionContainer
    styling={{
      paddingTop: 'low'
    }}
    className="fertilization-header-text"
  >
    <div className="fertilization-blue-header">
      <div className="fertilization-header-text">
        <span className="text-heading-font">{intl.formatMessage({ id: 'MANURE' })}</span>
      </div>
      <SectionContainer
        styling={{ backgroundColor: 'white', paddingAllSides: 'none' }}
        className="fertilization-content"
      >
        <div className="manure-grid-list">
          <div className="flex-corner">
            <div>
              <Numeral size="normal">
                {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && Math.round(fertilizationPlan.data.manurePlanSummary.manureQuantity)}{' '}
                {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && fertilizationPlan.data.manurePlanSummary.manureUnits}
              </Numeral>
            </div>
            <div>{intl.formatMessage({ id: 'PLANNED' })}</div>
          </div>
          <div className="inline-flex-border">
            <div></div>
          </div>
          <div className="flex-corner">
            <div>
              <Numeral size="normal">
                {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && Math.round(fertilizationPlan.data.manurePlanSummary.storageCapacity)}{' '}
                {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && fertilizationPlan.data.manurePlanSummary.storageCapacityUnits}
              </Numeral>
            </div>
            <div>{intl.formatMessage({ id: 'STORAGE_CAPACITY' })}</div>
          </div>
          <div className="inline-flex-border">
            <div></div>
          </div>
          <div className="flex-corner">
            <div>
              <Numeral size="large">
                {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && Math.round(fertilizationPlan.data.manurePlanSummary.capacityDifference)}{' '}
                {fertilizationPlan.data && fertilizationPlan.data.manurePlanSummary && fertilizationPlan.data.manurePlanSummary.capacityDifferenceUnits}
              </Numeral>
            </div>
            <div>{intl.formatMessage({ id: 'DIFFERENCE' })}</div>
          </div>
        </div>
      </SectionContainer>
    </div>
  </SectionContainer>
);

export default FertilizerManure;