/**
 * To capture page view in google analytics
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { pageview } from 'utils/GoogleAnalytics';
import { VEHICLE, EQUIPMENT, TASKS, MANAGEFIELDS } from 'constants/index';

class GAPageView extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    this.unlisten = history.listen(location => {
      const path = location.pathname;
      if (
        path.includes(`${EQUIPMENT}/`) ||
        path.includes(`${TASKS}/`) ||
        path.includes(`${VEHICLE}/`) ||
        path.includes(`${MANAGEFIELDS}/`)
      ) {
        const urlPath = path.split('/');
        const pathId = urlPath.pop();
        if (pathId !== 'new' && pathId !== 'create') {
          pageview(`${urlPath.join('/')}/update`);
        } else {
          pageview(location.pathname);
        }
      } else {
        pageview(location.pathname);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return null;
  }
}

export default withRouter(GAPageView);
