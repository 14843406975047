import React from 'react';
import { Autocomplete, TextField, CircularProgress, InputAdornment } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';  // Import the dropdown arrow icon

const MuiCreatableSelectBox = ({ 
  options, 
  inputValue, 
  loading, 
  handleInputChange, 
  handleChange,
  label,
  labelName,
  value,
  disabled
}) => {
  return (
    <Autocomplete
      freeSolo
      options={options}
      value={value}
      disabled={disabled}
      getOptionLabel={(option) => {
        // Convert the option to string for display
        return typeof option === 'string' ? option : String(option.label);
      }}
      inputValue={inputValue}
      onInputChange={(event, newValue) => {
        // Handle the "Create" option differently depending on the language
        if (typeof newValue === 'string' && (newValue.startsWith('Create "') || newValue.startsWith('Opprett "'))) {
          const actualValue = newValue.slice(newValue.indexOf('"') + 1, -1); // Extract the actual value
          handleInputChange(event, actualValue);
        } else {
          handleInputChange(event, newValue);
        }
      }}
      onChange={handleChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#00696D"
          }
        },
        '& label.Mui-focused': {
          color: '#00696D',
          fontFamily: 'Inter'
        },
        display: 'flex'
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              border: 'none',
            },
          },
        },
      }}
      inputProps={{ style: { color: 'red' } }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                {/* Add the dropdown triangle icon */}
                <InputAdornment position="end">
                  <ArrowDropDownIcon style={{ cursor: 'pointer' }} />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) => {
          const label = typeof option.label === 'string' || typeof option.label === 'number' 
            ? String(option.label).toLowerCase() 
            : '';

          return label.includes(params.inputValue.toLowerCase());
        });

        if (
          params.inputValue !== '' && 
          !options.some(option => String(option.label).toLowerCase() === params.inputValue.toLowerCase())
        ) {
          filtered.push({ label: `${labelName} "${params.inputValue}"`, value: params.inputValue });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
};

export default MuiCreatableSelectBox;