import {
  GET_GPS_FARM_DATA,
  IS_GPS_FARM_DATA_FAILURE,
  IS_GPS_FARM_DATA_LOADING,
  DATA_PERMISSION_LOADING,
  DATA_PERMISSION_SUCCESS,
  DATA_PERMISSION_ERROR
} from 'constants/index';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  isPermissionLoading: false,
  dataPermissionHasError: false,
  permissionErrorMessage: null,
  hasPermission: null
};

export default function GPSDataReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case IS_GPS_FARM_DATA_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
        errorMessage: null
      };
    case IS_GPS_FARM_DATA_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case GET_GPS_FARM_DATA:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        farmList: action.data
      };
    case DATA_PERMISSION_LOADING:
      return {
        ...state,
        isPermissionLoading: action.isPermissionLoading
      };
    case DATA_PERMISSION_SUCCESS:
      return {
        ...state,
        isPermissionLoading: false,
        hasPermission: action.permissionData,
        dataPermissionHasError: false
      };
    case DATA_PERMISSION_ERROR:
      return {
        ...state,
        isPermissionLoading: false,
        dataPermissionHasError: true,
        permissionErrorMessage: action.permissionErrorMessage
      };
    default:
      return state;
  }
}
