import { SOIL_SAMPLES_NEW } from 'constants/ApiUrl';
import {
  UPLOAD_SOIL_SAMPLE_PROGRESS,
  UPLOAD_SOIL_SAMPLE_ERROR,
  UPLOAD_SOIL_SAMPLE_LOADING,
  UPLOAD_SOIL_SAMPLE_SUCESS,
  SOIL_SAMPLE_LIST_ERROR,
  SOIL_SAMPLE_LIST,
  SOIL_SAMPLE_LOADING,
  CLEAR_SOIL_UPLOAD,
  SOIL_SAMPLE_DETAILS,
  SOIL_SAMPLE_DETAILS_ERROR,
  SOIL_SAMPLE_UPDATE,
  SOIL_SAMPLE_UPDATE_ERROR,
  FORCE_UPDATE
} from 'constants/store/Fertilization';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';
import { PUT, POST, GET } from 'constants/index';

/**
 * to clear upload info
 */

export const clearSoilUploadInfo = () => dispatch => {
  dispatch({ type: CLEAR_SOIL_UPLOAD });
};

/**
 * to upload soil samples
 *
 */
export const uploadSamples = (farmId, data, format, force) => async (dispatch, getState) => {
  dispatch({ type: UPLOAD_SOIL_SAMPLE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  let url = `${SOIL_SAMPLES_NEW}${farmId}/upload-results?format=${format}&language=${GetLanguageCode(preferredLanguage)}`;
  let method = POST;
  if (force === FORCE_UPDATE) {
    method = PUT
  }
  await dispatch(
    apiRequest({
      url,
      method: method,
      data,
      onUploadProgress: progressEvent => {
        dispatch({
          type: UPLOAD_SOIL_SAMPLE_PROGRESS,
          payload: Math.round((progressEvent.loaded * 100) / progressEvent.total)
        });
      }
    })
  )
    .then(response => {
      dispatch({ type: UPLOAD_SOIL_SAMPLE_SUCESS, payload: response.data });
      dispatch(getSoilSampleList(farmId));
    })
    .catch(error => {
      dispatch({ type: UPLOAD_SOIL_SAMPLE_ERROR, payload: error });
    });
};

/**
 * to get sample list
 * @param {String} farmId
 */
export const getSoilSampleList = farmId => async (dispatch, getState) => {
  dispatch({ type: SOIL_SAMPLE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${SOIL_SAMPLES_NEW}${farmId}/overview?language=${GetLanguageCode(preferredLanguage)}`,
      method: GET
    })
  )
    .then(response => {
      dispatch({ type: SOIL_SAMPLE_LIST, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SOIL_SAMPLE_LIST_ERROR, payload: error });
    });
};

/**
 * to soil sample details
 * @param {String} farmId
 * @param {String} fileName
 */
export const getSamplesDetail = (farmId, sampleDate, year) => async (dispatch, getState) => {
  dispatch({ type: SOIL_SAMPLE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${SOIL_SAMPLES_NEW}${farmId}/results?sampleDate=${sampleDate}&year=${year}&language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: GET
    })
  )
    .then(response => {
      dispatch({ type: SOIL_SAMPLE_DETAILS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SOIL_SAMPLE_DETAILS_ERROR, payload: error });
    });
};

/**
 * to soil sample details
 * @param {String} farmId
 * @param {String} fileName
 */
export const UpdateSamplesDetail = (farmId, data) => async (dispatch, getState) => {
  dispatch({ type: SOIL_SAMPLE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${SOIL_SAMPLES_NEW}${farmId}/results?language=${GetLanguageCode(preferredLanguage)}`,
      method: PUT,
      data
    })
  )
    .then(response => {
      dispatch({ type: SOIL_SAMPLE_UPDATE, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SOIL_SAMPLE_UPDATE_ERROR, payload: error });
    });
};
