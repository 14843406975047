import React, { Component } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import Select, { components } from 'react-select';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';

import LoaderPage from 'components/LoaderPage';
import navToggle from 'actions/NavToggle';
import { farmListSuccess, clearMessages } from 'actions/Farms';
import { doLogOut } from 'actions/Login';
import { ENTER_TASKS, ENTER_FIELD, ENTER_STATS, ENTER_SETTINGS, ENTER_FP_PAGE, VIEW_360 } from 'constants/MixPanel';
import { EANA_360 } from 'constants/OauthProperties';
import mixpanel from 'mixpanel-browser';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
  Typography,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  Home,
  Settings,
  Info,
  Build,
  Help,
} from '@mui/icons-material';

const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <mask id="mask0_4418_55922" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_4418_55922)">
      <path d="M4 21V9L12 3L20 9V21H14V14H10V21H4Z" fill="#3F4949"/>
    </g>
  </svg>
);

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu1: true,
      menu2: true
    };
  }

  handleToggle = menu => {
    let { menu1, menu2 } = this.state;
    if(menu === 'menu1') {
      this.setState({
        menu1: !menu1
      });
    } else {
      this.setState({
        menu2: !menu2
      });
    }
  };

  // handle farm select
  farmChange = item => {
    const { farmList, updateActiveFarm, clearFarmMessages } = this.props;
    const selecteItem = _.filter(farmList.data, ['farm.id', item.value]);
    localStorage.setItem( 'selectedFarmId', selecteItem[0].farm.id );
    localStorage.setItem( 'selectedFarmName', selecteItem[0].farm.name );
    updateActiveFarm(farmList.data, selecteItem[0]);
    clearFarmMessages();
  };

  filterOption = (option, inputValue) =>
    (option.label ? option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || [] : []).length > 0;

  // get farm option
  farmListOption = () => {
    const { farmList, hasSupportAdminRole } = this.props;
    const options = farmList.data && farmList.data.map(data => ({ value: data.farm.id, label: hasSupportAdminRole.data ? data.farm.nameAndOrgNum : data.farm.name }));
    if (farmList.data && farmList.data.length > 1) {
      const defaultOption = {
        value: farmList.activeFarm.farm.id,
        label: hasSupportAdminRole.data ? farmList.activeFarm.farm.nameAndOrgNum : farmList.activeFarm.farm.name
      };
      
      const DropdownIndicator = props => (
        // Have to pass all props to the react select
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.DropdownIndicator {...props}>
          <span className={props.selectProps.menuIsOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />
        </components.DropdownIndicator>
      );
      return (
        <Select
          options={options}
          value={defaultOption}
          onChange={v => this.farmChange(v)}
          className="farm-header-select"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator
          }}
          filterOption={this.filterOption}
        />
      );
    }
    return (
      <span title={options[0].label} className="farm-header-selected-text">
        {options[0].label}
      </span>
    );
  };

  // Hanlde sign out
  handleSignOut = () => {
    const { userDetails, loggingOut, farmList } = this.props;
    loggingOut(userDetails.data.accessToken, farmList.activeFarm?.farm.id)
  };

  // Toggle menu
  handleMenuToggle = () => {
    const { navigationToggle, navBarToggle } = this.props;
    navigationToggle(!navBarToggle.isOpen);
  };

  render() {
    const {
      intl,
      navBarToggle,
      farmList,
      history,
      hasValidationPermision,
      userDetails,
      hasSupportAdminRole
    } = this.props;
    const classToogle = navBarToggle.isOpen ? 'menu-bar toggle' : 'menu-bar';
    const navMenuClass = navBarToggle.isOpen ? 'btn-nav-menu menu-open' : 'btn-nav-menu';
    const menuStyle = {
      color: "var(--M3-sys-light-on-secondary-container, #041F21)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      paddingTop: "10px",
      paddingBottom: "10px"
    }
    return (
      <>
        <button type="button" onClick={() => this.handleMenuToggle()} className={navMenuClass}>
          <span />
          <span />
          <span />
        </button>
        <div className={classToogle}>
          {userDetails.isLogoutLoading ? <LoaderPage /> : null}
          <h3 className="menu-title">
            <Link to="/managefields">{intl.formatMessage({ id: 'APP_NAME' })}</Link>
          </h3>
          {farmList.data &&
          farmList.data.length > 0 &&
          history.location.pathname !== '/settings/addfarm'
            ? this.farmListOption()
            : null}
          {farmList.hasError ? (
            <ErrorText size="small">{farmList.hasError}</ErrorText>
          ) : null}
          <Drawer
            variant="permanent"
            anchor="left"
            sx={{
              width: 240,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
              },
            }}
          >
            <button type="button" onClick={() => this.handleMenuToggle()} className={navMenuClass}>
              <span />
              <span />
              <span />
            </button>
            {userDetails.isLogoutLoading ? <LoaderPage /> : null}
            <h3 className="menu-title">
              <Link to="/managefields">{intl.formatMessage({ id: 'APP_NAME' })}</Link>
            </h3>
            {farmList.data &&
            farmList.data.length > 0 &&
            history.location.pathname !== '/settings/addfarm'
              ? this.farmListOption()
              : null}
            {farmList.hasError ? (
              <ErrorText size="small">{farmList.hasError}</ErrorText>
            ) : null}
            <List style={{ paddingTop: '50px' }}>
              {/* Menu 1 */}
              <ListItem onClick={() => this.handleToggle('menu1')}
                sx={{ 
                  paddingTop: "10px", 
                  paddingBottom: "10px", 
                  display: "flex", 
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#CCE8E9"
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '40px !important', marginRight: 2 }}>
                  <img src="images/Home-mui.png" alt="Icon" />
                </ListItemIcon>
                <Typography style={menuStyle}>
                  {intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_1' })}
                </Typography>
                {this.state.menu1 ? <ExpandLess sx={{ marginLeft: 'auto' }} /> : <ExpandMore sx={{ marginLeft: 'auto' }} />}
              </ListItem>
              <Collapse in={this.state.menu1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/stats" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_STATS);}} style={{ textDecoration: 'none' }}>
                    <ListItem sx={{ pl: 9, "&:hover": { backgroundColor: "#CCE8E9" } }}>
                      <Typography style={menuStyle}>{intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_2' })}</Typography>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>

              {/* Menu 2 */}
              <ListItem onClick={() => this.handleToggle('menu2')}
                sx={{ 
                  paddingTop: "10px", 
                  paddingBottom: "10px", 
                  display: "flex", 
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#CCE8E9"
                  }
                }}
              >
                <ListItemIcon sx={{minWidth: '40px !important', marginRight: 2}}>
                  <img src="images/Field-mui.png" />
                </ListItemIcon>
                <Typography style={menuStyle}>
                  {intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_3' })}
                </Typography>
                {this.state.menu2 ? <ExpandLess sx={{ marginLeft: 'auto' }} /> : <ExpandMore sx={{ marginLeft: 'auto' }} />}
              </ListItem>
              <Collapse in={this.state.menu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 9, "&:hover": { backgroundColor: "#CCE8E9" } }}>
                    <Typography style={menuStyle}>{intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_4' })}</Typography>
                  </ListItem>
                  <Link to="/managefields" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_FIELD);}} style={{ textDecoration: 'none' }}>
                    <ListItem sx={{ pl: 9, "&:hover": { backgroundColor: "#CCE8E9" } }}>
                      <Typography style={menuStyle}>{intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_5' })}</Typography>
                    </ListItem>
                  </Link>
                  <Link to="#" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_FP_PAGE); history.push('/managefields/fertilization');}} style={{ textDecoration: 'none' }}>
                    <ListItem sx={{ pl: 9, "&:hover": { backgroundColor: "#CCE8E9" } }}>
                      <Typography style={menuStyle}>{intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_6' })}</Typography>
                    </ListItem>
                  </Link>
                  <ListItem sx={{ pl: 9, "&:hover": { backgroundColor: "#CCE8E9" } }}>
                    <Typography style={menuStyle}>{intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_7' })}</Typography>
                  </ListItem>
                </List>
              </Collapse>

              {/* Menu 3 */}
              <Link to="/tasks" onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_TASKS);}} style={{ textDecoration: 'none' }}>
                <ListItem
                  sx={{ 
                    paddingTop: "10px", 
                    paddingBottom: "10px", 
                    display: "flex", 
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#CCE8E9"
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '40px !important', marginRight: 2 }}>
                    <img src="images/ThirdIcon-mui.png" alt="Icon" />
                  </ListItemIcon>
                  <Typography style={menuStyle}>
                    {intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_8' })}
                  </Typography>
                </ListItem>
              </Link>

              {/* Menu 4 */}
              <Link to="/storage" style={{ textDecoration: 'none' }}>
                <ListItem
                  sx={{ 
                    paddingTop: "10px", 
                    paddingBottom: "10px", 
                    display: "flex", 
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#CCE8E9"
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '40px !important', marginRight: 2 }}>
                    <img src="images/FourthIcon-mui.png" alt="Icon" />
                  </ListItemIcon>
                  <Typography style={menuStyle}>
                    {intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_9' })}
                  </Typography>
                </ListItem>
              </Link>
              {/* Menu 5 */}
              <Link to="/grassanalysis" style={{ textDecoration: 'none' }}>
                <ListItem
                  sx={{ 
                    paddingTop: "10px", 
                    paddingBottom: "10px", 
                    display: "flex", 
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#CCE8E9"
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '40px !important', marginRight: 2 }}>
                    <img src="images/FifthIcon-mui.png" alt="Icon" />
                  </ListItemIcon>
                  <Typography style={menuStyle}>
                    {intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_10' })}
                  </Typography>
                </ListItem>
              </Link>
            </List>
            <div className="menu-logo">
              <div className="eana-app-image" />
              <div className="icon-eana-logo" />
            </div>
          </Drawer>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  navBarToggle: state.NavToggle,
  farmList: state.farmList,
  hasValidationPermision: state.GpsData,
  userDetails: state.userDetails,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  navigationToggle: bool => dispatch(navToggle(bool)),
  updateActiveFarm: (data, activeform) => dispatch(farmListSuccess(data, activeform)),
  clearFarmMessages: () => dispatch(clearMessages()),
  loggingOut: (token, activeFarmId) => dispatch(doLogOut(token, activeFarmId))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar)));
