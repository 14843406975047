import { MINERAL_FERTILIZER_STORAGE } from 'constants/ApiUrl';
import {
  MINERAL_STORAGE_LIST_LOADING,
  MINERAL_STORAGE_LIST_SUCCESS,
  MINERAL_STORAGE_LIST_ERROR,
  MINERAL_STORAGE_CREATE_LOADING,
  MINERAL_STORAGE_CREATE_SUCCESS,
  MINERAL_STORAGE_CREATE_ERROR,
  MINERAL_STORAGE_DELETE_LOADING,
  MINERAL_STORAGE_DELETE_SUCCESS,
  MINERAL_STORAGE_DELETE_ERROR
} from 'constants/store/Storages';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';
import { GET, POST, PUT, DELETE } from 'constants/index';

export const fetchMineralStorageList = farmId => async (dispatch, getState) => {
  dispatch({ type: MINERAL_STORAGE_LIST_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${MINERAL_FERTILIZER_STORAGE}/${farmId}/mf-storages?language=${GetLanguageCode(preferredLanguage)}`,
      method: GET
    })
  )
    .then(response => {
      dispatch({ type: MINERAL_STORAGE_LIST_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: MINERAL_STORAGE_LIST_ERROR, payload: error });
    });
};

export const createMineralStorage = (farmId, data) => async (dispatch, getState) => {
  dispatch({ type: MINERAL_STORAGE_CREATE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${MINERAL_FERTILIZER_STORAGE}/${farmId}/mf-storages?language=${GetLanguageCode(preferredLanguage)}`,
      method: POST,
      data
    })
  )
    .then(response => {
      dispatch({ type: MINERAL_STORAGE_CREATE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: MINERAL_STORAGE_CREATE_ERROR, payload: error });
    });
};

export const UpdateMineralStorage = (farmId, data, storageId) => async (dispatch, getState) => {
  dispatch({ type: MINERAL_STORAGE_CREATE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${MINERAL_FERTILIZER_STORAGE}/${farmId}/mf-storages/${storageId}?language=${GetLanguageCode(preferredLanguage)}`,
      method: PUT,
      data
    })
  )
    .then(response => {
      dispatch({ type: MINERAL_STORAGE_CREATE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: MINERAL_STORAGE_CREATE_ERROR, payload: error });
    });
};

export const deleteMineralStorage = (farmId, storageId) => async (dispatch, getState) => {
  dispatch({ type: MINERAL_STORAGE_DELETE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${MINERAL_FERTILIZER_STORAGE}/${farmId}/mf-storages/${storageId}?language=${GetLanguageCode(preferredLanguage)}`,
      method: DELETE
    })
  )
    .then(response => {
      dispatch({ type: MINERAL_STORAGE_DELETE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: MINERAL_STORAGE_DELETE_ERROR, payload: error, error: 'delete' });
    });
};