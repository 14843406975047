import React from 'react';
import { Field } from 'redux-form';
import SelectControl from 'components/forms/SelectBoxControl';
import { getDropDownFieldsStatuses, getDropDownActivities } from 'utils/index';

function getTaskFilter(displayMultiple, handleFilterFieldChange, handleFilterStatusChange, 
  handleFilterTaskChange, intl, taskListFields, taskListStatuses, taskListActivities, resetFilter, defaultField,
  defaultStatus, defaultTask) {
    return (
      <div className="full-width">
        <div className="task-filter-content">
          <div className="items-container">
            <div className="item">
              <div className="dropdowns">
                <Field
                  styleName="select-box-small"
                  placeholder={intl.formatMessage({ id: 'ALL_FIELDS' })}
                  component={SelectControl}
                  options={getDropDownFieldsStatuses(taskListFields)}
                  name="field_filter"
                  controlId="field_filter"
                  hasCheckbox={displayMultiple}
                  defaultValue={defaultField}
                  controlledValue={defaultField}
                  isMulti={displayMultiple}
                  onChange={handleFilterFieldChange}
                />
              </div>
            </div>
            <div className="item">
              <div className="dropdowns">
                <Field
                  styleName="select-box-small"
                  placeholder={intl.formatMessage({ id: 'ALL_STATUS' })}
                  component={SelectControl}
                  options={getDropDownFieldsStatuses(taskListStatuses)}
                  name="field_status"
                  controlId="field_status"
                  hasCheckbox={displayMultiple}
                  defaultValue={defaultStatus}
                  controlledValue={defaultStatus}
                  isMulti={displayMultiple}
                  onChange={handleFilterStatusChange}
                />
              </div>
            </div>
            <div className="item">
              <div className="dropdowns">
                <Field
                  styleName="select-box-small"
                  placeholder={intl.formatMessage({ id: 'ALL_TASKS' })}
                  component={SelectControl}
                  options={getDropDownActivities(taskListActivities)}
                  name="field_task"
                  controlId="field_task"
                  hasCheckbox={displayMultiple}
                  defaultValue={defaultTask}
                  controlledValue={defaultTask}
                  isMulti={displayMultiple}
                  onChange={handleFilterTaskChange}
                />
              </div>
            </div>
            <div className="filter-reset-padding">
              <span className="plan-list-edit-button-border" onClick={resetFilter} name="reset">
                {intl.formatMessage({ id: 'FILTER_RESET' })}
              </span>
            </div>
          </div>          
        </div>
      </div>
    );
}

export default ({ displayMultiple, handleFilterFieldChange, handleFilterStatusChange, 
  handleFilterTaskChange, intl, taskListFields, taskListStatuses, taskListActivities, resetFilter, defaultField,
  defaultStatus, defaultTask }) => 
  getTaskFilter(displayMultiple, handleFilterFieldChange, handleFilterStatusChange, handleFilterTaskChange, 
    intl, taskListFields, taskListStatuses, taskListActivities, resetFilter, defaultField,
    defaultStatus, defaultTask);