import _ from 'lodash';
import {
  GET_FARM_LIST,
  IS_FARM_LIST_FAILURE,
  IS_FARM_LIST_LOADING,
  ADD_FARM_FAILURE,
  ADD_FARM_SUCCESS,
  UPDATE_FARM_SUCCESS,
  DELETE_FARM_SUCCESS,
  DELETE_FARM_FAILURE,
  CLEAR_FARM_MESSAGES
} from 'constants/store/Farms';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  hasUpdateError: false,
  hasDeleteError: false,
  isSuccess: false,
  isUpdateSuccess: false,
  data: null
};

export default function FarmListReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case IS_FARM_LIST_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
        hasDeleteError: false,
        hasUpdateError: false,
        isSuccess: false,
        isUpdateSuccess: false,
        errorMessage: null
      };
    case IS_FARM_LIST_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    case GET_FARM_LIST:
      return {
        ...state,
        data: action.data,
        activeFarm: action.activeFarm,
        hasError: false,
        hasDeleteError: false,
        hasUpdateError: false,
        errorMessage: null,
        isLoading: false
      };
    case ADD_FARM_FAILURE:
      return {
        ...state,
        hasError: false,
        hasDeleteError: false,
        hasUpdateError: true,
        errorMessage: action.error,
        isLoading: false,
        isSuccess: false,
        isUpdateSuccess: false,
      };
    case ADD_FARM_SUCCESS: {
      return { ...state, isLoading: false, hasUpdateError: false, isSuccess: true, isUpdateSuccess: false };
    }
    case UPDATE_FARM_SUCCESS: {
      const farmIndex = _.findIndex(state.data, item => {
        return item.farm.id === action.data.id;
      });
      const farm = [...state.data];
      const { activeFarm } = state;
      if (farmIndex > -1) {
        farm[farmIndex].farm = action.data;
        activeFarm.farm = action.data;
      }
      return {
        ...state,
        hasError: false,
        hasDeleteError: false,
        hasUpdateError: false,
        errorMessage: null,
        isLoading: false,
        isSuccess: false,
        isUpdateSuccess: true,
        activeFarm,
        data: farm
      };
    }
    case DELETE_FARM_SUCCESS:
      _.remove(state.data, item => {
        return item.farm.id === action.id;
      });
      return {
        ...state,
        hasError: false,
        hasDeleteError: false,
        hasUpdateError: false,
        errorMessage: null,
        isLoading: false,
        activeFarm: state.data.length > 0 && state.data[0],
        data: state.data
      };
    case DELETE_FARM_FAILURE:
      return {
        ...state,
        hasError: false,
        hasDeleteError: true,
        hasUpdateError: false,
        errorMessage: action.error,
        isLoading: false
      };
    case CLEAR_FARM_MESSAGES:
      return {
        ...state,
        hasError: false,
        hasDeleteError: false,
        hasUpdateError: false,
        errorMessage: null,
        isSuccess: false,
        isUpdateSuccess: false
      };
    default:
      return state;
  }
}
