import axios from 'axios';
import {
  GET_GPS_FARM_DATA,
  IS_GPS_FARM_DATA_FAILURE,
  IS_GPS_FARM_DATA_LOADING,
  API_ENDPOINT,
  DATA_PERMISSION_LOADING,
  DATA_PERMISSION_SUCCESS,
  DATA_PERMISSION_ERROR
} from 'constants/index';

/** Action get farm details */
const gpsFarmDataHasError = (bool, error) => ({
  type: IS_GPS_FARM_DATA_FAILURE,
  hasError: bool,
  errorMessage: error
});

const gpsFarmDataIsLoading = bool => ({
  type: IS_GPS_FARM_DATA_LOADING,
  isLoading: bool
});

export const gpsFarmDataSuccess = data => ({
  type: GET_GPS_FARM_DATA,
  data
});

const dataValidationPermissionLoading = bool => ({
  type: DATA_PERMISSION_LOADING,
  isPermissionLoading: bool
});

const dataValidationPermissionSuccess = data => ({
  type: DATA_PERMISSION_SUCCESS,
  permissionData: data
});

const dataValidationPermissionError = (bool, error) => ({
  type: DATA_PERMISSION_ERROR,
  dataPermissionHasError: bool,
  permissionErrorMessage: error
});

/**
 * fetch Farm List dispatch farm list api details
 * @param {token}
 */
export const fetchGpsFarmList = token => async dispatch => {
  try {
    dispatch(gpsFarmDataIsLoading(true));
    await axios(`${API_ENDPOINT}farms`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        const farmList = response.data;
        dispatch(gpsFarmDataSuccess(farmList));
      })
      .catch(error => {
        dispatch(gpsFarmDataHasError(true, error));
      });
  } catch (error) {
    dispatch(gpsFarmDataHasError(true, error));
  }
};

/**
 * permission check dispatch api details
 * @param {token}
 */
export const dataValidationPermission = token => async dispatch => {
  try {
    dispatch(dataValidationPermissionLoading(true));
    await axios(`${API_ENDPOINT}userroles/check/userrole/verifier`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(response => {
        dispatch(dataValidationPermissionSuccess(response.data));
      })
      .catch(error => {
        dispatch(dataValidationPermissionError(true, error));
      });
  } catch (error) {
    dispatch(dataValidationPermissionError(true, error));
  }
};
