import { HISTORICAL_DATA_URL } from 'constants/ApiUrl';
import { 
  HISTORICAL_DATA_LOADING, 
  HISTORICAL_DATA_SUCCESS, 
  HISTORICAL_DATA_ERROR
} from 'constants/store/Fields';
import { GET } from 'constants/index';
import apiRequest from 'utils/ApiRequest';

/**
 * Get the historical data
 */
export const getHistoricalData = (farmId,fieldId) => async (dispatch) => {
  try {
    dispatch({ type: HISTORICAL_DATA_LOADING, payload: true });
    await dispatch(
      apiRequest({
        url: `${HISTORICAL_DATA_URL}/${farmId}/fields/${fieldId}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: HISTORICAL_DATA_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: HISTORICAL_DATA_SUCCESS, payload: error });
      });
    } catch (error) {
      dispatch({ type: HISTORICAL_DATA_ERROR, payload: error });
    }
};