import {
  FERTILIZATION_PLAN_CHECK_LOADING,
  FERTILIZATION_PLAN_CHECK_SUCCESS,
  FERTILIZATION_PLAN_CHECK_FAILURE,
  FERTILIZATION_PLAN_LIST_LOADING,
  FERTILIZATION_PLAN_LIST_SUCCESS,
  FERTILIZATION_PLAN_LIST_FAILURE,
  FERTILIZATION_PLAN_CREATE_LOADING,
  FERTILIZATION_PLAN_CREATE_SUCCESS,
  FERTILIZATION_PLAN_CREATE_FAILURE,
  FERTILIZATION_PLAN_GET_CREATED_LOADING,
  FERTILIZATION_PLAN_GET_CREATED_SUCCESS,
  FERTILIZATION_PLAN_GET_CREATED_FAILURE,
  FERTILIZATION_PLAN_UPDATE_LOADING,
  FERTILIZATION_PLAN_UPDATE_SUCCESS,
  FERTILIZATION_PLAN_UPDATE_FAILURE,
  FERTILIZATION_PLAN_SAVE_LOADING,
  FERTILIZATION_PLAN_SAVE_SUCCESS,
  FERTILIZATION_PLAN_SAVE_FAILURE,
  FERTILIZATION_PLAN_EDIT_LOADING,
  FERTILIZATION_PLAN_EDIT_SUCCESS,
  FERTILIZATION_PLAN_EDIT_FAILURE,
  FERTILIZATION_PLAN_REPORT_LOADING,
  FERTILIZATION_PLAN_REPORT_SUCCESS,
  FERTILIZATION_PLAN_REPORT_FAILURE,
  MINERAL_FERTILIZER_UPDATE_LOADING,
  MINERAL_FERTILIZER_UPDATE_SUCCESS,
  MINERAL_FERTILIZER_UPDATE_FAILURE,
  MINERAL_FERTILIZER_DELETE_LOADING,
  MINERAL_FERTILIZER_DELETE_SUCCESS,
  MINERAL_FERTILIZER_DELETE_FAILURE,
  MINERAL_FERTILIZER_ADD_LOADING,
  MINERAL_FERTILIZER_ADD_SUCCESS,
  MINERAL_FERTILIZER_ADD_FAILURE,
  FERTILIZATION_RECALCULATE_TRANSACTION_LOADING,
  FERTILIZATION_RECALCULATE_TRANSACTION_SUCCESS,
  FERTILIZATION_RECALCULATE_TRANSACTION_FAILURE,
  CREATE_TASK_FERTILIZATION_PLAN_LOADING,
  CREATE_TASK_FERTILIZATION_PLAN_SUCCESS,
  CREATE_TASK_FERTILIZATION_PLAN_FAILURE,
  FERTILIZATION_COMPLETED_LOADING,
  FERTILIZATION_COMPLETED_SUCCESS,
  FERTILIZATION_COMPLETED_FAILURE,
  MINERAL_FERTILIZER_UPDATE_MENU_LOADING,
  MANURE_STORAGE_UPDATE_MENU_LOADING,
  MINERAL_FERTILIZER_UPDATE_MENU_FAILURE,
  MANURE_STORAGE_UPDATE_MENU_FAILURE,
  MINERAL_FERTILIZER_UPDATE_MENU_SUCCESS,
  MANURE_STORAGE_UPDATE_MENU_SUCCESS,
  UPDATE_VERSION_NAME_LOADING,
  UPDATE_VERSION_NAME_SUCCESS,
  UPDATE_VERSION_NAME_FAILURE,
  DELETE_VERSION_PLAN_LOADING,
  DELETE_VERSION_PLAN_SUCCESS,
  DELETE_VERSION_PLAN_FAILURE,
  FETCH_FERTILIZATION_PLAN_LIST_LOADING,
  FETCH_FERTILIZATION_PLAN_LIST_SUCCESS,
  FETCH_FERTILIZATION_PLAN_LIST_FAILURE
} from 'constants/store/Fertilization';

const initialState = {
  hasError: false,
  hasSaveError: false,
  isLoading: false,
  isCreateLoading: false,
  errorMessage: null,
  updateErrorMessage: null,
  saveErrorMessage: null,
  data: null,
  createData: null,
  creatingData: null,
  fertilizerPlanAvailable: false,
  isSuccess: false,
  isCreateSuccess: false,
  updateSuccess: false,
  saveSuccess: false,
  planList: null,
  isListLoading: false,
  checkData: null,
  isReportLoading: false,
  reportData: null,
  isReportSuccess: false,
  hasReportError: false,
  reportErrorMessage: null,
  createTaskLoading: false,
  createTaskData: null,
  createTaskError: false,
  isCreatePlanSuccess: false,
  versionNameChangeLoading: false,
  versionNameChangeSuccess: null,
  versionNameChangeFailure: null,
  deleteVersionPlanLoading: false,
  deleteVersionPlanSuccess: null,
  deleteVersionPlanFailure: null,
  fetchPlanListLoading: false,
  fetchPlanListSuccess: null,
  fetchPlanListFailure: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FERTILIZATION_PLAN_CHECK_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null,
        fertilizerPlanAvailable: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_CHECK_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false,
        fertilizerPlanAvailable: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_CHECK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        createData: action.payload,
        fertilizerPlanAvailable: true,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_CREATE_LOADING:
    case FERTILIZATION_RECALCULATE_TRANSACTION_LOADING:
      return {
        ...state,
        isCreateLoading: true,
        hasError: false,
        errorMessage: null,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_CREATE_FAILURE:
    case FERTILIZATION_RECALCULATE_TRANSACTION_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isCreateLoading: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_CREATE_SUCCESS:
      if(action.payload !== "" && action.payload !== null && action.payload.farmId !== null) {
        return {
          ...state,
          isCreateLoading: false,
          errorMessage: null,
          hasError: false,
          creatingData: action.payload,
          data: action.payload,
          isCreateSuccess: true,
          isCreatePlanSuccess: true,
          versionNameChangeFailure: null
        };
      } else {
        return {
          ...state,
          isCreateLoading: false,
          errorMessage: null,
          hasError: false,
          creatingData: action.payload,
          isCreateSuccess: true,
          isCreatePlanSuccess: true,
          versionNameChangeFailure: null
        };
      }
    case FERTILIZATION_RECALCULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
        errorMessage: null,
        hasError: false,
        creatingData: action.payload,
        isCreateSuccess: true,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_EDIT_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null,
        hasSaveError: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_EDIT_FAILURE:
    case FERTILIZATION_PLAN_GET_CREATED_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false,
        hasSaveError: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.payload,
        hasSaveError: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_GET_CREATED_LOADING:
      return {
        ...state,
        isLoading: true,
        hasSaveError: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_GET_CREATED_SUCCESS:
      if(action.payload !== "" && action.payload !== null && action.payload.farmId !== null) {
        return {
          ...state,
          isLoading: false,
          errorMessage: null,
          hasError: false,
          data: action.payload,
          isSuccess: true,
          hasSaveError: false,
          versionNameChangeFailure: null
        };
      } else {
        return {
          ...state,
          isLoading: false,
          errorMessage: null,
          hasError: false,
          data: action.payload,
          hasSaveError: false,
          versionNameChangeFailure: null
        };
      }
    case FERTILIZATION_PLAN_REPORT_LOADING:
      return {
        ...state,
        isReportLoading: true,
        hasReportError: false,
        reportErrorMessage: null,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_REPORT_FAILURE:
      return {
        ...state,
        hasReportError: true,
        reportErrorMessage: action.payload,
        isReportLoading: false,
        reportData: null,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_REPORT_SUCCESS:
      return {
        ...state,
        isReportLoading: false,
        reportErrorMessage: null,
        hasReportError: false,
        reportData: action.payload,
        isReportSuccess: true,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_LIST_LOADING:
      return {
        ...state,
        isListLoading: true,
        isCreateSuccess: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_LIST_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isListLoading: false,
        isCreateSuccess: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_LIST_SUCCESS:
      return {
        ...state,
        isListLoading: false,
        errorMessage: null,
        hasError: false,
        planList: action.payload,
        isCreateSuccess: false,
        versionNameChangeFailure: null
      };
    case FETCH_FERTILIZATION_PLAN_LIST_LOADING:
      return {
        ...state,
        fetchPlanListLoading: true,
        fetchPlanListSuccess: null,
        fetchPlanListFailure: null
      };
    case FETCH_FERTILIZATION_PLAN_LIST_FAILURE:
      return {
        ...state,
        fetchPlanListLoading: false,
        fetchPlanListSuccess: null,
        fetchPlanListFailure: action.payload
      };
    case FETCH_FERTILIZATION_PLAN_LIST_SUCCESS:
      return {
        ...state,
        fetchPlanListLoading: false,
        fetchPlanListSuccess: action.payload,
        fetchPlanListFailure: null
      };
    case FERTILIZATION_PLAN_UPDATE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        hasSaveError: false,
        updateErrorMessage: null,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_UPDATE_FAILURE:
      return {
        ...state,
        hasError: true,
        hasSaveError: false,
        updateErrorMessage: action.payload,
        isLoading: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateErrorMessage: null,
        hasError: false,
        hasSaveError: false,
        data: action.payload,
        updateSuccess: true,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_SAVE_LOADING:
      return {
        ...state,
        isLoading: true,
        saveErrorMessage: null,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_SAVE_FAILURE:
      return {
        ...state,
        hasSaveError: true,
        saveErrorMessage: action.payload,
        isLoading: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_PLAN_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveErrorMessage: null,
        data: action.payload,
        saveSuccess: true,
        hasSaveError: false,
        versionNameChangeFailure: null
      };
    case MINERAL_FERTILIZER_UPDATE_LOADING:
    case MINERAL_FERTILIZER_DELETE_LOADING:
    case MINERAL_FERTILIZER_ADD_LOADING:
    case MINERAL_FERTILIZER_UPDATE_MENU_LOADING:
    case MANURE_STORAGE_UPDATE_MENU_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        hasSaveError: false,
        versionNameChangeFailure: null
      };
    case MINERAL_FERTILIZER_UPDATE_FAILURE:
    case MINERAL_FERTILIZER_DELETE_FAILURE:
    case MINERAL_FERTILIZER_ADD_FAILURE:
    case MINERAL_FERTILIZER_UPDATE_MENU_FAILURE:
    case MANURE_STORAGE_UPDATE_MENU_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false,
        hasSaveError: false,
        versionNameChangeFailure: null
      };
    case MINERAL_FERTILIZER_UPDATE_SUCCESS:
    case MINERAL_FERTILIZER_DELETE_SUCCESS:
    case MINERAL_FERTILIZER_ADD_SUCCESS:
    case MINERAL_FERTILIZER_UPDATE_MENU_SUCCESS:
    case MANURE_STORAGE_UPDATE_MENU_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        hasSaveError: false,
        data: action.payload,
        versionNameChangeFailure: null
      };
    case CREATE_TASK_FERTILIZATION_PLAN_LOADING:
      return {
        ...state,
        createTaskLoading: true,
        createTaskError: false,
        versionNameChangeFailure: null
      };
    case CREATE_TASK_FERTILIZATION_PLAN_FAILURE:
      return {
        ...state,
        createTaskLoading: false,
        createTaskError: true,
        createTaskData: action.payload,
        versionNameChangeFailure: null
      };
    case CREATE_TASK_FERTILIZATION_PLAN_SUCCESS:
      return {
        ...state,
        createTaskLoading: false,
        createTaskError: false,
        createTaskData: action.payload,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_COMPLETED_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        hasError: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_COMPLETED_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false,
        versionNameChangeFailure: null
      };
    case FERTILIZATION_COMPLETED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        data: action.payload,
        versionNameChangeFailure: null
      };
    case UPDATE_VERSION_NAME_LOADING:
      return {
        ...state,
        versionNameChangeLoading: true,
        versionNameChangeSuccess: null,
        versionNameChangeFailure: null
      };
    case UPDATE_VERSION_NAME_FAILURE:
      return {
        ...state,
        versionNameChangeLoading: false,
        versionNameChangeSuccess: null,
        versionNameChangeFailure: action.payload
      };
    case UPDATE_VERSION_NAME_SUCCESS:
      return {
        ...state,
        versionNameChangeLoading: false,
        versionNameChangeSuccess: true,
        versionNameChangeFailure: null
      };
    case DELETE_VERSION_PLAN_LOADING:
      return {
        ...state,
        deleteVersionPlanLoading: true,
        deleteVersionPlanSuccess: null,
        deleteVersionPlanFailure: null
      };
    case DELETE_VERSION_PLAN_FAILURE:
      return {
        ...state,
        deleteVersionPlanLoading: false,
        deleteVersionPlanSuccess: null,
        deleteVersionPlanFailure: action.payload
      };
    case DELETE_VERSION_PLAN_SUCCESS:
      return {
        ...state,
        deleteVersionPlanLoading: false,
        deleteVersionPlanSuccess: action.payload,
        deleteVersionPlanFailure: null
      };
    default:
      return state;
  }
};
