import {
  SUPPORT_ADMIN_ROLE_SUCCESS,
  SUPPORT_ADMIN_ROLE_FAILURE
} from 'constants/store/RolesAndActivity';

import { API_ENDPOINT } from 'constants/index';
import apiRequest from 'utils/ApiRequest';

/** Action get roles details */
const supportAdminHasError = (bool, error) => ({
  type: SUPPORT_ADMIN_ROLE_FAILURE,
  hasError: bool,
  errorMessage: error
});

export const supportAdminSuccess = data => ({
  type: SUPPORT_ADMIN_ROLE_SUCCESS,
  data
});

/**
 * to get roles details
 */

export const fetchSupportAdminPermission = () => async dispatch => {
  await dispatch(
    apiRequest({
      url: `${API_ENDPOINT}userroles/check/userrole/support_admin`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch(supportAdminSuccess(response.data));
    })
    .catch(error => {
      dispatch(supportAdminHasError(true, error));
    });
};
