import { FERTILIZATION_PLAN } from 'constants/ApiUrl';
import { 
  FERTILIZATION_PLAN_CHECK_LOADING, 
  FERTILIZATION_PLAN_CHECK_SUCCESS, 
  FERTILIZATION_PLAN_CHECK_FAILURE,
  FERTILIZATION_PLAN_LIST_LOADING,
  FERTILIZATION_PLAN_LIST_SUCCESS,
  FERTILIZATION_PLAN_LIST_FAILURE,
  FERTILIZATION_PLAN_CREATE_LOADING,
  FERTILIZATION_PLAN_CREATE_SUCCESS,
  FERTILIZATION_PLAN_CREATE_FAILURE,
  FERTILIZATION_PLAN_GET_CREATED_LOADING,
  FERTILIZATION_PLAN_GET_CREATED_SUCCESS,
  FERTILIZATION_PLAN_GET_CREATED_FAILURE,
  FERTILIZATION_PLAN_UPDATE_LOADING,
  FERTILIZATION_PLAN_UPDATE_SUCCESS,
  FERTILIZATION_PLAN_UPDATE_FAILURE,
  FERTILIZATION_PLAN_SAVE_LOADING,
  FERTILIZATION_PLAN_SAVE_SUCCESS,
  FERTILIZATION_PLAN_SAVE_FAILURE,
  FERTILIZATION_PLAN_EDIT_LOADING,
  FERTILIZATION_PLAN_EDIT_SUCCESS,
  FERTILIZATION_PLAN_EDIT_FAILURE,
  FERTILIZATION_PLAN_REPORT_LOADING,
  FERTILIZATION_PLAN_REPORT_SUCCESS,
  FERTILIZATION_PLAN_REPORT_FAILURE,
  MINERAL_FERTILIZER_UPDATE_LOADING, 
  MINERAL_FERTILIZER_UPDATE_SUCCESS,
  MINERAL_FERTILIZER_UPDATE_FAILURE,
  MINERAL_FERTILIZER_DELETE_LOADING, 
  MINERAL_FERTILIZER_DELETE_SUCCESS, 
  MINERAL_FERTILIZER_DELETE_FAILURE,
  MINERAL_FERTILIZER_ADD_LOADING, 
  MINERAL_FERTILIZER_ADD_SUCCESS, 
  MINERAL_FERTILIZER_ADD_FAILURE,
  FERTILIZATION_RECALCULATE_TRANSACTION_LOADING,
  FERTILIZATION_RECALCULATE_TRANSACTION_SUCCESS,
  FERTILIZATION_RECALCULATE_TRANSACTION_FAILURE,
  CREATE_TASK_FERTILIZATION_PLAN_LOADING,
  CREATE_TASK_FERTILIZATION_PLAN_SUCCESS,
  CREATE_TASK_FERTILIZATION_PLAN_FAILURE,
  FERTILIZATION_COMPLETED_LOADING,
  FERTILIZATION_COMPLETED_SUCCESS,
  FERTILIZATION_COMPLETED_FAILURE,
  MINERAL_FERTILIZER_UPDATE_MENU_LOADING,
  MINERAL_FERTILIZER_UPDATE_MENU_SUCCESS,
  MINERAL_FERTILIZER_UPDATE_MENU_FAILURE,
  MANURE_STORAGE_UPDATE_MENU_LOADING,
  MANURE_STORAGE_UPDATE_MENU_SUCCESS,
  MANURE_STORAGE_UPDATE_MENU_FAILURE,
  UPDATE_VERSION_NAME_LOADING,
  UPDATE_VERSION_NAME_SUCCESS,
  UPDATE_VERSION_NAME_FAILURE,
  DELETE_VERSION_PLAN_LOADING,
  DELETE_VERSION_PLAN_SUCCESS,
  DELETE_VERSION_PLAN_FAILURE,
  FETCH_FERTILIZATION_PLAN_LIST_LOADING,
  FETCH_FERTILIZATION_PLAN_LIST_SUCCESS,
  FETCH_FERTILIZATION_PLAN_LIST_FAILURE
} from 'constants/store/Fertilization';
import { GET, POST, PUT, DELETE } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * To check the availability of fertilization plan
 * @param {*} farmId 
 * @param {*} year 
 */
export const checkFertilizationPlanAvailability = (farmId, year) => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_CHECK_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans/check-availability?year=${year}&language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_PLAN_CHECK_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_PLAN_CHECK_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FERTILIZATION_PLAN_CHECK_FAILURE, payload: error });
    }
};

/**
 * To get all the available fertilization plan
 * @param {*} farmId 
 */
export const fertilizationPlanList = farmId => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_LIST_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_PLAN_LIST_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_PLAN_LIST_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FERTILIZATION_PLAN_LIST_FAILURE, payload: error });
    }
};

/**
 * To create a new fertilization plan
 * @param {*} farmId 
 * @param {*} data 
 */
export let createFertilizationPlan = (farmId, deletePlan, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_CREATE_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let createPlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    createPlanUri = deletePlan ? `${createPlanUri}&deleteDraftPlan=${deletePlan}` : createPlanUri;
    await dispatch(
      apiRequest({
        url: createPlanUri,
        data,
        method: POST
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_PLAN_CREATE_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_PLAN_CREATE_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FERTILIZATION_PLAN_CREATE_FAILURE, payload: error });
    }
};

export let getCreatedFertilizationPlan = (farmId, transactionId, timerValue) => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_GET_CREATED_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let getcreatedPlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/draftplans/transactions/${transactionId}?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: getcreatedPlanUri,
        method: GET
      })
    )
      .then(response => {
        if(timerValue === null) {
          dispatch({ type: FERTILIZATION_PLAN_GET_CREATED_SUCCESS, payload: response.data });
        } else {
          response.data = "timerError";
          dispatch({ type: FERTILIZATION_PLAN_GET_CREATED_SUCCESS, payload: response.data });
        }
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_PLAN_GET_CREATED_FAILURE, payload: error });
      });
  } catch (error) {
    dispatch({ type: FERTILIZATION_PLAN_GET_CREATED_FAILURE, payload: error });
  }
};

/**
 * Update the fertilization plan
 * @param {*} farmId 
 * @param {*} data 
 */
export let updateFertilizationPlan = (updateField, farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_UPDATE_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let updatePlanUri = ``;
    if (updateField === 'manure') {
      updatePlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/seasons/manures?language=${GetLanguageCode(
        preferredLanguage
      )}`;
    }
    if (updateField === 'season') {
      updatePlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/seasons/mineral-fertilizers/quantities?language=${GetLanguageCode(
        preferredLanguage
      )}`;
    }
    await dispatch(
      apiRequest({
        url: updatePlanUri,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_PLAN_UPDATE_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_PLAN_UPDATE_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FERTILIZATION_PLAN_UPDATE_FAILURE, payload: error });
    }
};

/**
 * Save the fertilization plan
 * @param {*} farmId 
 * @param {*} data 
 */
export let saveFertilizationPlan = (farmId, data, createTask) => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_SAVE_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let savePlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans?language=${GetLanguageCode(
      preferredLanguage
    )}&createTasks=${createTask}`;
    await dispatch(
      apiRequest({
        url: savePlanUri,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_PLAN_SAVE_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_PLAN_SAVE_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FERTILIZATION_PLAN_SAVE_FAILURE, payload: error });
    }
};

/**
 * Edit the fertilization plan
 * @param {*} farmId
 * @param {*} planname
 * @param {*} year
 * @param {*} version
 */
export let editFertilizationPlan = (farmId, planName, year, version) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_EDIT_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let editPlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/years/${year}/versions/${version}?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: editPlanUri,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_PLAN_EDIT_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_PLAN_EDIT_FAILURE, payload: error });
      });
  } catch (error) {
    dispatch({ type: FERTILIZATION_PLAN_EDIT_FAILURE, payload: error });
  }
};

/**
 * Fertilization plan Report
 * @param {*} farmId
 * @param {*} planname
 * @param {*} year
 * @param {*} version
 */
 export let fertilizationPlanReport = (farmId, planName, year, version) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FERTILIZATION_PLAN_REPORT_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let reportPlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/years/${year}/versions/${version}?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: reportPlanUri,
        method: GET
      })
    )
    .then(response => {
      dispatch({ type: FERTILIZATION_PLAN_REPORT_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: FERTILIZATION_PLAN_REPORT_FAILURE, payload: error });
    });
  } catch (error) {
    dispatch({ type: FERTILIZATION_PLAN_REPORT_FAILURE, payload: error });
  }
};

/**
 * Update the mineral fertilizer
 * @param {*} farmId 
 * @param {*} data 
 */
export const updateMineralFertilizer = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: MINERAL_FERTILIZER_UPDATE_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans/seasons/mineral-fertilizers?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: MINERAL_FERTILIZER_UPDATE_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: MINERAL_FERTILIZER_UPDATE_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: MINERAL_FERTILIZER_UPDATE_FAILURE, payload: error });
    }
};

/**
 * delete the mineral fertilizer
 * @param {*} farmId 
 * @param {*} data 
 */
 export const deleteMineralFertilizer = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: MINERAL_FERTILIZER_DELETE_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans/seasons/mineral-fertilizers?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: DELETE
      })
    )
      .then(response => {
        dispatch({ type: MINERAL_FERTILIZER_DELETE_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: MINERAL_FERTILIZER_DELETE_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: MINERAL_FERTILIZER_DELETE_FAILURE, payload: error });
    }
};

/**
 * add the mineral fertilizer
 * @param {*} farmId 
 * @param {*} data 
 */
 export const addMineralFertilizer = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: MINERAL_FERTILIZER_ADD_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans/seasons/mineral-fertilizers?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        data,
        method: POST
      })
    )
      .then(response => {
        dispatch({ type: MINERAL_FERTILIZER_ADD_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: MINERAL_FERTILIZER_ADD_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: MINERAL_FERTILIZER_ADD_FAILURE, payload: error });
    }
};

/**
 * To recalculate fertilization plan
 * @param {*} farmId 
 * @param {*} data 
 */
 export let recalculateFertilizationPlan = (farmId, data, actual) => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_RECALCULATE_TRANSACTION_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let planUri = "";
    if(actual) {
      planUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/recalculation/actuals?language=${GetLanguageCode(
        preferredLanguage
      )}`;
    } else {
      planUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/recalculation?language=${GetLanguageCode(
        preferredLanguage
      )}`;
    }    
    await dispatch(
      apiRequest({
        url: planUri,
        data,
        method: POST
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_RECALCULATE_TRANSACTION_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_RECALCULATE_TRANSACTION_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FERTILIZATION_RECALCULATE_TRANSACTION_FAILURE, payload: error });
    }
};

/**
 * To create task in fertilization plan
 * @param {*} farmId 
 * @param {*} year 
 */
 export let createTaskFertilizationPlan = (farmId, year) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_TASK_FERTILIZATION_PLAN_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let planUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/tasks?year=${year}&language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: planUri,
        method: POST
      })
    )
      .then(response => {
        dispatch({ type: CREATE_TASK_FERTILIZATION_PLAN_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: CREATE_TASK_FERTILIZATION_PLAN_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: CREATE_TASK_FERTILIZATION_PLAN_FAILURE, payload: error });
    }
};

export let clickOnCompleted = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: FERTILIZATION_COMPLETED_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let savePlanUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/seasons/status?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: savePlanUri,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: FERTILIZATION_COMPLETED_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FERTILIZATION_COMPLETED_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FERTILIZATION_COMPLETED_FAILURE, payload: error });
    }
};

/**
 * Update the mineral fertilizer
 * @param {*} farmId 
 * @param {*} data 
 */
export const updateMineralFertilizerMenu = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: MINERAL_FERTILIZER_UPDATE_MENU_LOADING, payload: true });
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans/mineral-fertilizers`,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: MINERAL_FERTILIZER_UPDATE_MENU_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: MINERAL_FERTILIZER_UPDATE_MENU_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: MINERAL_FERTILIZER_UPDATE_MENU_FAILURE, payload: error });
    }
};

/**
 * Update the manure storage
 * @param {*} farmId 
 * @param {*} data 
 */
export const updateManureStorageMenu = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: MANURE_STORAGE_UPDATE_MENU_LOADING, payload: true });
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans/seasons/manureStorages`,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: MANURE_STORAGE_UPDATE_MENU_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: MANURE_STORAGE_UPDATE_MENU_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: MANURE_STORAGE_UPDATE_MENU_FAILURE, payload: error });
    }
};

export const updateVersionName = (farmId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_VERSION_NAME_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let updateUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans/versionName?language=${GetLanguageCode(
      preferredLanguage
    )}`;
    await dispatch(
      apiRequest({
        url: updateUri,
        data,
        method: PUT
      })
    )
      .then(response => {
        dispatch({ type: UPDATE_VERSION_NAME_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: UPDATE_VERSION_NAME_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: UPDATE_VERSION_NAME_FAILURE, payload: error });
    }
};

export const deleteVersionPlan = (farmId, year, version) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_VERSION_PLAN_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    let deleteUri = "";
    if(version === null) {
      deleteUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans?year=${year}&language=${GetLanguageCode(
        preferredLanguage
      )}`;
    } else {
      deleteUri = `${FERTILIZATION_PLAN}farms/${farmId}/plans?year=${year}&version=${version}&language=${GetLanguageCode(
        preferredLanguage
      )}`;
    }
    
    await dispatch(
      apiRequest({
        url: deleteUri,
        method: DELETE
      })
    )
      .then(response => {
        dispatch({ type: DELETE_VERSION_PLAN_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: DELETE_VERSION_PLAN_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: DELETE_VERSION_PLAN_FAILURE, payload: error });
    }
};

/**
 * To get all the available fertilization plan
 * @param {*} farmId 
 */
export const fetchPlanVersionList = farmId => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_FERTILIZATION_PLAN_LIST_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${FERTILIZATION_PLAN}farms/${farmId}/plans?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: FETCH_FERTILIZATION_PLAN_LIST_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: FETCH_FERTILIZATION_PLAN_LIST_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: FETCH_FERTILIZATION_PLAN_LIST_FAILURE, payload: error });
    }
};
